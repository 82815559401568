import { apiRequest } from './index';

function topicList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/content/helpcenter/admin/topics',
		params,
	};

	return apiRequest(req);
}

function topicInfo(topicID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/helpcenter/topics/${topicID}`,
		params,
	};

	return apiRequest(req);
}

function topicAdd(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/helpcenter/topics',
		data,
		params,
	};

	return apiRequest(req);
}

function topicUpdate(topicID, data, params = {}) {

	const req = {
		method: 'PUT',
		url   : `/content/helpcenter/topics/${topicID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function topicDelete(topicID, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/content/helpcenter/topics/${topicID}`,
		params,
	};

	return apiRequest(req);
}

export const helpCenterAPI = {
	topicList,
	topicInfo,
	topicAdd,
	topicUpdate,
	topicDelete,
};
