import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';
import { cloneDeep } from 'lodash';
import { EMessageStatus } from '../../../../containers/CustomerManagement/UserInfo/SendSMS/types';

const initState = new Map({

	list: [],

	baseData: {
		subject: '',
		message: '',
		time   : null,
		date   : null,
		userID : null,
	},

	template: {
		content: '',
		name   : '',
		slug   : '',
		status : '1',
	},

	filter: {
		totalCount  : 0,
		page        : 1,
		itemsPerPage: 100, // this does not change.
		hasMore     : false,
		statusIDs   : [EMessageStatus.SUCCESS],
	},

	UI: {
		loading         : false,
		modalVisible    : false,
		modalCalendar   : false,
		modalTemplate   : false,
		addTemplate     : false,
		templateEditMode: false,
		modalWidth      : '40%',
	},
});

export default function userSMSReducer(state = initState, action) {

	switch (action.type) {

		case actions.USER_SEND_SMS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.USER_SEND_SMS_LIST_UPDATE: {
			const old = cloneDeep(state.get('list'));
			const data = old.concat(action.data);
			return state.set('list', data);
		}

		case actions.USER_SEND_SMS_UI_REFRESH: {
			const UI  = action.data;
			const target = state.get('UI');
			const result = fill(UI, target, true);
			return state.set('UI', result);
		}
		case actions.USER_SEND_SMS_FILTER_REFRESH: {
			const filter  = action.data;
			const target = state.get('filter');
			const result = fill(filter, target, true);
			return state.set('filter', result);
		}

		case actions.USER_SEND_SMS_NEW_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}
		case actions.USER_SEND_SMS_TEMPLATE_REFRESH: {
			const target = state.get('template');
			const result = fill(action.data, target, true);
			return state.set('template', result);
		}

		case actions.BASE_DATA_RESET: {
			const initBase = initState.get('baseData');
			return  state.set('baseData', initBase);
		}

		default:
			return state;

	}

}
