import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list: [],

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	UI: {
		loading: false,
	},
});

export default function loginReducer(state = initState, action) {

	switch (action.type) {

		case actions.USER_LOGINS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.USER_LOGINS_LIST_PAGINATION_REFRESH: {
			const target = state.get('pagination');
			const result = fill(action.data, target);
			return state.set('pagination', result);
		}

		case actions.USER_LOGINS_LIST_SET_VALUE_UI: {
			const { valueName, value } = action.data;
			const UI                   = new Map(state.get('UI')).toObject();
			UI[valueName]              = value;

			return state.set('UI', UI);
		}

		default:
			return state;
	}
}
