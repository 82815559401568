import { apiRequest } from './index';

function sidebarSettingsLoad(userID, params = {}) {

	const req = {
		method       : 'GET',
		url          : '/content/sidebar',
		withoutLangID: true,
		params,
	};
	return apiRequest(req);
}

function sidebarSettingsSave(userID, settings, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/sidebar',
		data  : settings,
		params,
	};

	return apiRequest(req);
}

export const sidebarAPI = {
	sidebarSettingsLoad,
	sidebarSettingsSave,
};
