import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IResponsibleAdapted, IResponsibleEntities, IResponsibleInitState, IResponsibleUI } from './types';
import { RootState } from '../../../store';
import { fill } from '../../../../helpers/utils';

const initialState: IResponsibleInitState = {
	list    : [],
	entities: {},
	UI      : {
		loading: false,
	},
};
export const ResponsibleGambling = createSlice({
	name    : 'responsible',
	initialState,
	reducers: {
		listRefresh: (state: Draft<IResponsibleInitState>, action: PayloadAction<IResponsibleAdapted[]>) => {
			state.list  = action.payload;
		},

		entitiesRefresh: (state: Draft<IResponsibleInitState>, action: PayloadAction<IResponsibleEntities>) => {

			state.entities  = action.payload;
		},
		updateEntities: (state: Draft<IResponsibleInitState>, action: PayloadAction<{ data: IResponsibleAdapted; id: number }>) => {
			const { id, data } = action.payload;
			const cloned = state.entities;
			cloned[id]  = data;
			state.entities = cloned;
		},

		uiRefresh: (state: Draft<IResponsibleInitState>, action: PayloadAction<Partial<IResponsibleUI>>) => {
			const target = state.UI;
			state.UI  = fill(action.payload, target, true);
		},
	},
});


export default ResponsibleGambling.reducer;

export const responsibleReducer = {
	...ResponsibleGambling.actions,
};

export const selectResponsible = (state: RootState) => state.Users.UserInfo.ResponsibleGambling;
