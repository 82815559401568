import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import toInteger from 'lodash/toInteger';
import values from 'lodash/values';

import actions from './actions';
import { actions as partnerActions } from '../../partners/modal/paymentMethods/actions';

import { websitesAPI } from '../../../../helpers/api/websites';
import { isID } from '../../../../helpers/utils';
import { showError, showSuccess } from '../../../../helpers/notifications';

import {
	adaptNamesData,
	adaptPaymentDescriptions,
	adaptPaymentLimits,
	adaptPayments,
	prepareCurrencyData,
	preparePaymentDescriptions,
	preparePaymentLimits,
	preparePayments,
} from './utils';
import { getWebsiteID, restorePartnerID } from '../../../../helpers/utility';
import { partnerAPI } from '../../../../helpers/api/partner';

import { logger } from '../../../../helpers/logger';
import { createError } from '../../../../helpers/sockets/socket';
import { deriveLanguagesList } from '../../../../selectors/websites';
import { paymentsAPI } from '../../../../helpers/api/payments';
import { cloneDeep } from 'lodash';
import {
	getParams,
	prepareNames,
	preparePayment,
	preparePaymentCurrencyData,
} from '../../../settings/payments/payment/utils';
import { adaptPaymentsList } from '../../../settings/payments/list/utils';


const prefix = 'partner.websites.website';

const messages = {
	errorPaymentsReload           : `${prefix}.errorPaymentsReload`,
	errorPaymentsSave             : `${prefix}.errorPaymentsSave`,
	errorPaymentsAdd              : `${prefix}.errorPaymentsAdd`,
	errorPaymentsUpdate           : `${prefix}.errorPaymentsUpdate`,
	errorPaymentsDelete           : `${prefix}.errorPaymentsDelete`,
	errorPaymentsReorder          : `${prefix}.errorPaymentsReorder`,
	errorPaymentLimitsReload      : `${prefix}.errorPaymentLimitsReload`,
	errorPaymentDescriptionsReload: `${prefix}.errorPaymentDescriptionsReload`,
	errorPaymentLimitsSave        : `${prefix}.errorPaymentLimitsSave`,
	errorCurrencyReload           : 'settings.payments.payment.errorCurrencyReload',

	successPaymentsSave           : `${prefix}.successPaymentsSave`,
	successPaymentsDelete         : `${prefix}.successPaymentsDelete`,
	successPaymentsReorder        : `${prefix}.successPaymentsReorder`,
	successPaymentLimitsSave      : `${prefix}.successPaymentLimitsSave`,
	successPaymentDescriptionsSave: `${prefix}.successPaymentDescriptionsSave`,
};


function getStoreData(state) {
	const { App, Partner: { Websites } } = state;
	const { Website, Payments } = Websites;

	return {
		baseData     : Website.get('baseData'),
		casino       : Website.get('casino'),
		changedFields: Website.get('changedFields'),
		mailingList  : Website.get('mailingList'),
		seoData      : Website.get('seoData'),
		UI           : Website.get('UI'),

		payments           : Payments.get('payments'),
		paymentLimits      : Payments.get('paymentLimits'),
		paymentDescriptions: Payments.get('paymentDescriptions'),
		paymentUI          : Payments.get('UI'),
		paymentBaseData    : Payments.get('baseMainData'),
		namesData          : cloneDeep(Payments.get('namesData')),
		currencyMainData   : cloneDeep(Payments.get('currencyMainData')),

		// currencyList       : entitiesToEnumList(Settings.CurrencyModule.get('entities')),
		languageList       : deriveLanguagesList(state),
		contentLanguageList: App.get('websiteAttachedLanguages'),
		currencyData       : Websites.Payments.get('currencyMainData'),
	};
}


function* paymentsReload() {

	yield takeEvery(actions.WEBSITE_PAYMENTS_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { contentLanguageList, namesData: reStoreNamesData, paymentUI } = yield select(getStoreData);
		const { websiteID } = action.data;
		const partnerID       = restorePartnerID();
		const params = { website_id: websiteID };
		let payments = {};
		let partnerPayments = [];
		try {
			const [resPayments, resPartnerPayments] = yield all(
				[call(websitesAPI.paymentsList, websiteID, params),
					call(partnerAPI.getPartnerPayments, partnerID, params)]
			);
			if (resPayments && resPayments.status === 200 && resPartnerPayments && resPartnerPayments.status === 200) {
				const [firstLang] = contentLanguageList;
				const {
					entities,
					namesData,
					isNamesChanges,
					tabsNames,
					langIDs,
					// paymentsIDs,
					isCurrencyChanges,
					isBaseDataChanges,
					isMainRowDataChanges,
				} = adaptPayments(
					resPayments.data.data,
					firstLang?.id,
					paymentUI,
					reStoreNamesData
				);
				payments          = entities;
				partnerPayments   = resPartnerPayments.data.data;
				yield put(actions.uiRefresh({ loading: false }));
				yield put(actions.namesDataRefresh(namesData));
				yield put(actions.uiRefresh({ isNamesChanges, tabsNames, langIDs, isCurrencyChanges, isBaseDataChanges, isMainRowDataChanges }));

			}
		} catch (error) {
			showError(messages.errorPaymentsReload, error);

			logger.log(error);
		}
		yield put(actions.paymentsRefresh(payments));
		yield put(partnerActions.partnerPaymentsRefresh(partnerPayments));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* paymentsListReloadByNames() {
	yield takeEvery(actions.WEBSITE_PAYMENTS_LIST_BASE_MAIN_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const websiteID = action.data || getWebsiteID();
		let entities = {};

		try {
			const res = yield call(paymentsAPI.paymentsList, websiteID);
			if (res && res.status === 200) {
				entities = adaptPaymentsList(res.data.data);
			}
		} catch (error) {
			showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.paymentsListBaseRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* currencyByPaymentMainDataReload() {

	yield takeEvery(actions.WEBSITE_PAYMENTS_CURRENCY_BY_PAYMENT_MAIN_DATA_RELOAD, function* ({ websiteID, websitePaymentID, paymentID }) {
		yield put(actions.uiRefresh({ loading: true }));
		const { currencyData } = yield select(getStoreData);
		let currencyMainData = {};
		try {
			const res = yield call(websitesAPI.paymentCurrencyListNew, websiteID, paymentID);
			if (res && res.status === 200) {
				currencyMainData = prepareCurrencyData(res.data.data, websitePaymentID);
			}
		} catch (error) {
			showError(messages.errorCurrencyReload, error);
			logger.log(error);
		}
		yield put(actions.currencyDataRefresh({ ...currencyData, ...currencyMainData }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* paymentLimitsReload() {

	yield takeEvery(actions.WEBSITE_PAYMENT_LIMITS_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websitePaymentID } = action.data;
		const { UI } = yield select(getStoreData);
		const params = { website_id: UI.websiteID };
		let limits = [];
		try {
			const res = yield call(websitesAPI.paymentLimitsList, websitePaymentID, params);
			if (res && res.status === 200) {
				limits = adaptPaymentLimits(res.data.data);
			}
		} catch (error) {
			showError(messages.errorPaymentLimitsReload, error);
			logger.log(error);
		}

		yield put(actions.paymentLimitsRefresh(websitePaymentID, limits));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* paymentDescriptionsReload() {

	yield takeEvery(actions.WEBSITE_PAYMENT_DESCRIPTION_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websitePaymentID } = action.data;
		const { UI } = yield select(getStoreData);
		const params = {
			lang_id   : UI.langID,
			website_id: UI.websiteID,
		};
		let descriptions = [];
		try {
			const res = yield call(websitesAPI.paymentDescriptionsList, websitePaymentID, params);
			if (res && res.status === 200) {
				descriptions = adaptPaymentDescriptions(res.data.data);
				yield put(actions.paymentDescriptionRefresh(websitePaymentID, descriptions));
			}
		} catch (error) {
			showError(messages.errorPaymentDescriptionsReload, error);
			logger.log(error);
		}

		yield put(actions.paymentDescriptionRefresh(websitePaymentID, descriptions));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* paymentSave() {

	yield takeEvery(actions.WEBSITE_PAYMENT_SAVE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { payments, paymentLimits, paymentDescriptions, UI, paymentUI, namesData, currencyMainData, paymentBaseData } = yield select(getStoreData);
		const { websiteID } = UI;
		const { paymentID } = action.data;

		const paymentItem						= payments[paymentID];
		const currentPaymentLimits				= paymentLimits[paymentID];
		const currentPaymentDescriptions		= paymentDescriptions[paymentID];
		const currentPaymentIsBaseDataChanged	= paymentUI.isBaseDataChanges[paymentID];
		const currentPaymentIsCurrencyChanged	= paymentUI.isCurrencyChanges[paymentID];
		const currentPaymentIsNamesChanged		= paymentUI.isNamesChanges[paymentID];

		const currentBaseData					= paymentBaseData[paymentID];
		const currentPaymentCurrencyData		= currencyMainData[paymentID];
		const currentPaymentNamesData			= namesData[paymentID];

		const editMode			= isID(paymentID);
		let websitePaymentID	= toInteger(paymentID);
		const paymentsIDs		= Object.keys(namesData);

		try {
			// base data
			websitePaymentID = yield call(
				makePaymentSave,
				paymentItem,
				websiteID,
				editMode,
				currentPaymentIsNamesChanged,
				currentPaymentNamesData,
				currentPaymentIsCurrencyChanged,
				currentPaymentCurrencyData,
				paymentsIDs,
				currentPaymentIsBaseDataChanged,
				currentBaseData
			);

			if (websitePaymentID) {

				// payment limits
				const preparedLimits = preparePaymentLimits(currentPaymentLimits, websitePaymentID);
				const paymentLimitsUpdateResponse = yield call(websitesAPI.paymentLimitsUpdate, websitePaymentID, preparedLimits);
				if (!paymentLimitsUpdateResponse || (paymentLimitsUpdateResponse && paymentLimitsUpdateResponse.status !== 200)) {
					throw createError();
				}

				// payment descriptions
				const { langID } = UI;
				const preparedDescriptions = preparePaymentDescriptions(currentPaymentDescriptions, websitePaymentID, langID);
				const paymentDescriptionsUpdateResponse = yield call(websitesAPI.paymentDescriptionsUpdate, websitePaymentID, preparedDescriptions);

				if (!paymentDescriptionsUpdateResponse || (paymentDescriptionsUpdateResponse && paymentDescriptionsUpdateResponse.status !== 200)) {
					throw createError();
				}

				yield put(actions.paymentsReload(websiteID));
				yield put(actions.paymentsListReloadByNames(websiteID));
				showSuccess(messages.successPaymentsSave);
			} else {
				throw createError();
			}
		} catch (error) {
			showError(messages.errorPaymentsSave, error);
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* makePaymentSave(paymentItem, websiteID, editMode, isNamesChanged, namesData, isCurrencyChanged, currencyData, paymentsIDs, isBaseDataChanged, baseData) {
	const preparedData = preparePayments(paymentItem, websiteID);
	let websitePaymentID = toInteger(paymentItem.id);
	try {
		if (editMode) {
			const res = yield call(websitesAPI.paymentUpdate, websiteID, paymentItem.paymentID, preparedData);
			const params = getParams({ websiteID });

			// Base Data Main Tab
			if (isBaseDataChanged) {
				const preparedBaseData = preparePayment(baseData, null, websitePaymentID);
				yield call(paymentsAPI.websitePaymentUpdate, websitePaymentID, preparedBaseData, params);
			}

			// Currency Main Tab
			if (isCurrencyChanged) {
				const paywayData = preparePaymentCurrencyData(currencyData);

				yield call(websitesAPI.paymentCurrencyListUpdate, websiteID, paymentItem.paymentID, paywayData);
				yield put(actions.websitePaymentCurrencyReload(paymentsIDs, websiteID));
			}

			// Names
			if (isNamesChanged) {
				const preparedData = prepareNames(namesData, paymentItem.paymentID);
				yield call(paymentsAPI.paymentNamesUpdate, paymentItem.paymentID, preparedData, params);
				yield put(actions.namesDataReload(websitePaymentID, paymentItem.paymentID));
			}

			if (res && res.status === 200) {
				return websitePaymentID;
			}
		} else {
			const res = yield call(websitesAPI.paymentAdd, websiteID, preparedData);
			if (res && res.status === 200) {
				websitePaymentID = toInteger(res.data.data.id);
				return websitePaymentID;
			}
		}
	} catch (error) {
		const errorMessage = editMode ? messages.errorPaymentsUpdate : messages.errorPaymentsAdd;
		showError(errorMessage, error);
		return null;
	}

}

function* paymentsReorder() {

	yield takeEvery(actions.WEBSITE_PAYMENTS_REORDER, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { payments, UI } = yield select(getStoreData);
		const { websiteID } = UI;
		const paymentsList = values(payments);

		// some payments can be not saved yet
		const notSaved = paymentsList.filter(item => !isID(item.id));
		if (notSaved.length > 0) {
			yield call(makePaymentMultiSave, notSaved, websiteID, false);
		}

		// let's update the rest payments
		const toReorder = paymentsList.reduce((resultList, paymentItem) => {
			if (!isID(paymentItem.id)) {
				return resultList;
			}

			const preparedItem = preparePayments(paymentItem, websiteID);
			resultList.push(preparedItem);

			return resultList;
		}, []);

		try {
			yield call(websitesAPI.paymentsListUpdate, websiteID, toReorder);
			showSuccess(messages.successPaymentsReorder);

		} catch (error) {
			showError(messages.errorPaymentsReorder, error);
		}

		yield put(actions.paymentsReload(websiteID));
	});
}

function* paymentDelete() {

	yield takeEvery(actions.WEBSITE_PAYMENT_DELETE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { UI } = yield select(getStoreData);
		const { websiteID } = UI;
		const { paymentID } = action.data;

		try {
			const res = yield call(websitesAPI.paymentDelete, websiteID, paymentID);
			if (res && res.status === 200) {
				showSuccess(messages.successPaymentsDelete);
			}

			yield put(actions.paymentsReload(websiteID));
			yield put(actions.paymentsListBaseRefresh({}));
		} catch (error) {
			showError(messages.errorPaymentsDelete, error);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* namesDataReload() {

	yield takeEvery(actions.WEBSITE_PAYMENT_NAMES_DATA_RELOAD, function* (action) {
		const { contentLanguageList, UI, namesData } = yield select(getStoreData);

		yield put(actions.uiRefresh({ loading: true }));
		const { paymentID, websitePaymentID } = action.data;
		const params = { website_id: UI.websiteID };

		try {
			const res = yield call(paymentsAPI.paymentNamesList, paymentID, params);
			if (res && res.status === 200) {
				namesData[websitePaymentID] = adaptNamesData(res.data.data, contentLanguageList);
			}
		} catch (error) {
			yield put(actions.uiRefresh({ loading: false }));
			showError(messages.errorNamesReload, error);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
		yield put(actions.namesDataRefresh(cloneDeep(namesData)));
	});
}


function* makePaymentMultiSave(paymentsList, websiteID, editMode) {

	const queries = paymentsList.reduce((res, paymentItem) => {
		res.push(call(makePaymentSave, paymentItem, websiteID, editMode));
		return res;
	}, []);

	try {
		yield all(queries);

	} catch (error) {
		showError(messages.errorPaymentsSave, error);
	}
}

export default function* websiteModalPaymentsSaga() {
	yield all([
		fork(paymentsReload),
		fork(paymentsListReloadByNames),
		fork(paymentLimitsReload),
		fork(paymentDescriptionsReload),
		fork(paymentsReorder),
		fork(paymentSave),
		fork(paymentDelete),
		fork(namesDataReload),
		fork(currencyByPaymentMainDataReload),
	]);
}
