import { Map } from 'immutable';
import { actions } from './actions';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import { defaultValuesPartnersFilter, initFilterTypes } from './utils';

const initState = new Map({

	filter: createFilter(filtersURL.partnersList, initFilterTypes),

	UI: {
		loading: false,
	},
});


export default (state = initState, { type, data }) => {
	switch (type) {
		case actions.PARTNER_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(data, target, true);
			return state.set('UI', result);
		}
		case actions.FILTER_UI_REFRESH: {
			const target = state.get('filter');
			const result = fill(data, target, true);
			return state.set('filter', result);
		}
		case actions.FILTER_UI_RESET: {
			return state.set('filter', defaultValuesPartnersFilter);
		}

		default:
			return state;
	}
};
