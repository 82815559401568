import { makeActionCreator } from '../../../helpers';

const prefix = 'CATEGORY__MARKET_SETTINGS';

const TYPES = {
	DATA_RELOAD        : `${prefix}_DATA_RELOAD`,
	DATA_REFRESH       : `${prefix}_DATA_REFRESH`,
	SAVING_DATA_REFRESH: `${prefix}SAVING_DATA_REFRESH`,
	MODAL_UI_REFRESH   : `${prefix}_MODAL_UI_REFRESH`,
	DATA_RESET         : `${prefix}_DATA_RESET`,
	DATA_SAVE_CLOSE    : `${prefix}_DATA_SAVE_CLOSE`,
	PAGINATION_REFRESH : `${prefix}_PAGINATION_REFRESH`,
};

const actions = {
	...TYPES,

	dataReload       : makeActionCreator(TYPES.DATA_RELOAD, 'sportID', 'categoryID'),
	dataRefresh      : makeActionCreator(TYPES.DATA_REFRESH, 'settingsList'),
	savingDataRefresh: makeActionCreator(TYPES.SAVING_DATA_REFRESH, 'savingData'),
	modalUIRefresh   : makeActionCreator(TYPES.MODAL_UI_REFRESH, 'uiData'),
	dataReset        : makeActionCreator(TYPES.DATA_RESET),
	dataSaveClose    : makeActionCreator(TYPES.DATA_SAVE_CLOSE),
	paginationRefresh: makeActionCreator(TYPES.PAGINATION_REFRESH, 'data'),
};

export default actions;
