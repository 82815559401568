import { combineReducers } from 'redux';
import MatchesOfDay from './matchesOfDay/reducer';
import GameGrid from './gameGrid/reducer';
import SpecialOffers from './specialOffers/reducer';
import SportsPriorities from './sportsPriorities/reducer';
import Upcoming from './upcoming/reducer';


export default combineReducers({
	MatchesOfDay,
	GameGrid,
	SpecialOffers,
	SportsPriorities,
	Upcoming,
});
