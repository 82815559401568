import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData         : {},
	revisionsEntities: {},

	UI: {
		pageID       : null,
		pageName     : null,
		editMode     : false,
		loading      : false,
		langID       : 1,
		isDataChanged: false,
		revisionID   : null,
	},
});

export default function infoPageReducer(state = initState, action) {

	switch (action.type) {

		case actions.INFO_PAGES_PAGE_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}

		case actions.INFO_PAGES_PAGE_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.INFO_PAGES_REVISIONS_LIST_REFRESH: {
			return state.set('revisionsEntities', action.data);
		}

		case actions.INFO_PAGES_PAGE_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
