import React from 'react';

export const DepositsIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_155_19" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_155_19)">
				<path fillRule="evenodd" clipRule="evenodd" d="M20.3692 9.93092V15.6058L20.3695 20.5742V22.4104C20.3695 22.4247 20.3683 22.4385 20.3674 22.4525C20.3657 22.9577 19.8889 23.028 19.6506 23L14.3681 22.9998H11.1759L11.1715 23H9.04082C8.71529 23 8.45146 22.7362 8.45146 22.4106L8.45123 16.0739V12.9376V6.71751H6.48423C5.6658 6.71751 5 6.05171 5 5.23328V2.484C5 1.6658 5.6658 1 6.48423 1H22.5154C23.334 1 24 1.6658 24 2.484V5.23328C24 6.05171 23.334 6.71751 22.5154 6.71751H20.3695V9.92355L20.3692 9.93092ZM6.48423 5.53878C6.31571 5.53878 6.17873 5.4018 6.17873 5.23328V2.484C6.17873 2.31571 6.31571 2.17873 6.48423 2.17873H22.5154C22.6841 2.17873 22.8213 2.31571 22.8213 2.484V5.23328C22.8213 5.4018 22.6841 5.53878 22.5154 5.53878H20.3695V4.67914H21.3836C21.7091 4.67914 21.973 4.41531 21.973 4.08978C21.973 3.76425 21.7091 3.50042 21.3836 3.50042H7.61645C7.29092 3.50042 7.02709 3.76425 7.02709 4.08978C7.02709 4.41531 7.29092 4.67914 7.61645 4.67914H8.45123V5.53878H6.48423ZM9.63018 5.60209V6.12815V9.66524L9.62995 11.3429V14.1962V16.0736L9.63018 19.7555C9.63018 20.7883 9.63018 21.3047 9.88837 21.5629C10.1466 21.821 10.6629 21.821 11.6957 21.821H14.3681H18.2296H19.1887L19.1884 15.7988C19.1884 15.7822 19.1891 15.7658 19.1905 15.7495V11.3804V9.72717V5.5404C18.9421 5.22522 18.6398 4.93653 18.2887 4.67937H10.5801C10.2057 4.95356 9.88734 5.26275 9.63018 5.60209Z" fill="#323332" />
				<path d="M3.54332 11.1833L3.54271 11.1839L0.187869 14.4496C-0.063454 14.6943 -0.0625188 15.09 0.190062 15.3335C0.442611 15.577 0.851095 15.5761 1.10245 15.3314L3.35484 13.1388L3.35484 20.375C3.35484 20.7202 3.64368 21 4 21C4.35632 21 4.64516 20.7202 4.64516 20.375L4.64516 13.1388L6.89755 15.3314C7.14891 15.5761 7.55739 15.577 7.80994 15.3335C8.06255 15.09 8.06342 14.6942 7.81213 14.4496L4.45729 11.1839L4.45668 11.1833C4.20448 10.9385 3.79468 10.9393 3.54332 11.1833Z" fill="#323332" />
				<path d="M14.5 11.3219C14.9596 11.3219 15.3333 11.6687 15.3333 12.0957C15.3333 12.5234 15.7059 12.8696 16.1667 12.8696C16.6272 12.8696 17 12.5234 17 12.0957C17 11.0914 16.3056 10.2422 15.3422 9.91941V8.77381C15.3422 8.346 14.9694 8 14.5089 8C14.0484 8 13.6756 8.346 13.6756 8.77381V9.91437C12.7031 10.2324 12 11.0852 12 12.0957C12 13.3758 13.1213 14.4172 14.5 14.4172C14.9596 14.4172 15.3333 14.764 15.3333 15.191C15.3333 15.6178 14.9596 15.9648 14.5 15.9648C14.0401 15.9648 13.6667 15.6178 13.6667 15.191C13.6667 14.7632 13.2938 14.4172 12.8333 14.4172C12.3726 14.4172 12 14.7632 12 15.191C12 16.2014 12.7031 17.0542 13.6756 17.3724V18.0595C13.6756 18.4871 14.0484 18.8333 14.5089 18.8333C14.9694 18.8333 15.3422 18.4871 15.3422 18.0595V17.3671C16.3056 17.0443 17 16.1953 17 15.1908C17 13.9108 15.8785 12.8694 14.5 12.8694C14.0401 12.8694 13.6667 12.5226 13.6667 12.0955C13.6667 11.6687 14.0401 11.3219 14.5 11.3219Z" fill="#323332" />
			</g>
		</svg>


	);
};
