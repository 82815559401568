import { all, fork } from 'redux-saga/effects';
import partnerListSaga from './list/saga';
import modalSaga from './modal/saga';
import filterSaga from './filter/saga';

export default function* () {
	yield all([
		fork(partnerListSaga),
		fork(filterSaga),
		fork(modalSaga),
	]);
}
