import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import { adaptDeposit, compareDepositWithFilter } from './utils';

function getStoreData({ AppTabs, RTM }) {

	return {
		isRTMDeposits     : Boolean( find(AppTabs.get('tabs'), { id: 'rtm/deposits' }) ),
		filter            : RTM.Deposits.get('filter'),
		pagination        : RTM.Deposits.get('pagination'),
		depositIDs        : RTM.Deposits.get('depositIDs'),
		entities          : RTM.Deposits.get('entities'),
		newDepositIDs     : RTM.Deposits.get('newDepositIDs'),
		newDepositEntities: RTM.Deposits.get('newDepositEntities'),
		depositTotals     : RTM.Deposits.get('depositTotals'),
	};
}

function* onNotifyCreateDeposit() {

	yield takeEvery(actions.RTM_DEPOSITS_SOCKET_NOTIFY_CREATE_DEPOSIT, function* (action) {

		const rawDeposit = action.data;
		const depositID = toInteger(rawDeposit.id);
		if (!depositID) {
			return;
		}

		const storeData = yield select(getStoreData);
		const { isRTMDeposits, filter, pagination } = storeData;

		const depositIDs         = cloneDeep(storeData.depositIDs);
		const entities           = cloneDeep(storeData.entities);
		const newDepositIDs      = cloneDeep(storeData.newDepositIDs);
		const newDepositEntities = cloneDeep(storeData.newDepositEntities);
		const depositTotals      = cloneDeep(storeData.depositTotals);

		const adaptedDeposit     = adaptDeposit(rawDeposit);

		newDepositEntities[depositID] = adaptedDeposit;
		newDepositIDs.unshift(depositID);

		if (isRTMDeposits && pagination.currentPage === 1) {
			const hasMatches = compareDepositWithFilter(adaptedDeposit, filter);
			if (hasMatches) {
				entities[depositID] = adaptedDeposit;
				depositIDs.unshift(depositID);
			}
		}

		yield put(actions.dataRefresh(depositIDs, entities, depositTotals));
		yield put(actions.newDepositListRefresh(newDepositIDs, newDepositEntities));

		let toSubscribeIDs = cloneDeep(depositIDs);
		toSubscribeIDs.push(depositID);
		toSubscribeIDs = uniq(toSubscribeIDs);

		yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}

function* onNotifyUpdateDeposit() {

	yield takeEvery(actions.RTM_DEPOSITS_SOCKET_NOTIFY_UPDATE_DEPOSIT, function* (action) {

		const rawDeposit = action.data;
		const depositID = toInteger(rawDeposit.id);
		if (!depositID) {
			return;
		}

		const storeData     = yield select(getStoreData);

		const depositIDs    = cloneDeep(storeData.depositIDs);
		const entities      = cloneDeep(storeData.entities);
		const depositTotals = cloneDeep(storeData.depositTotals);

		const depositEntity = entities[depositID];
		if (!depositEntity) {
			return;
		}

		const adaptedDeposit = adaptDeposit(rawDeposit);
		entities[depositID] = adaptedDeposit;

		yield put(actions.dataRefresh(depositIDs, entities, depositTotals));
	});
}

export default function* rtmDepositsExternalSaga() {
	yield all([
		fork(onNotifyCreateDeposit),
		fork(onNotifyUpdateDeposit),
	]);
}
