import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { countryActions } from './actions';
import { countryAPI } from '../../../helpers/api/country';
import notifications from '../../../helpers/notifications';
import { convertToEntities } from './utils';
import { logger } from '../../../helpers/logger';

const prefix = 'countries';

const messages = {
	errorCountriesLoad: `${prefix}.errorCountriesLoad`,
};

function* countryLoad() {
	yield takeEvery(countryActions.GET_COUNTRY, function* () {
		try {
			const res = yield call(countryAPI.getCountries);
			if (res.status === 200) {
				const { data } = res.data;
				const entitiesCountry = convertToEntities(data);
				yield put(countryActions.countryListCase(data));
				yield put(countryActions.countryEntitiesCase(entitiesCountry));
			}
		} catch (e) {
			notifications.showError(messages.errorCountriesLoad, e);
			logger.log(e);
		}

	});
}

export default function* countrySaga() {
	yield all([
		fork(countryLoad),
	]);
}
