const prefix = 'USER_LIMITS_PAYMENTS';

const actions = {

	USER_LIMITS_PAYMENTS_DEPOSIT_RELOAD   : `${prefix}_DEPOSIT_RELOAD`,
	USER_LIMITS_PAYMENTS_WITHDRAWAL_RELOAD: `${prefix}_WITHDRAWAL_RELOAD`,

	USER_LIMITS_PAYMENTS_DEPOSIT_REFRESH   : `${prefix}_DEPOSIT_REFRESH`,
	USER_LIMITS_PAYMENTS_WITHDRAWAL_REFRESH: `${prefix}_WITHDRAWAL_REFRESH`,

	USER_LIMITS_PAYMENTS_BASEDATA_REFRESH       : `${prefix}_BASEDATA_REFRESH`,
	USER_LIMITS_PAYMENTS_WITHDRAWAL_DATA_REFRESH: `${prefix}_WITHDRAWAL_DATA_REFRESH`,


	USER_LIMITS_PAYMENTS_DEPOSIT_UI_REFRESH   : `${prefix}_DEPOSIT_UI_REFRESH`,
	USER_LIMITS_PAYMENTS_WITHDRAWAL_UI_REFRESH: `${prefix}_WITHDRAWAL_UI_REFRESH`,

	PAYMENT_DEPOSIT_SAVE   : `${prefix}_DEPOSIT_SAVE`,
	PAYMENT_WITHDRAWAL_SAVE: `${prefix}_WITHDRAWAL_SAVE`,

	USER_LIMITS_PAYMENTS_RESET: `${prefix}_RESET`,

	depositListReload: data => ({
		type: actions.USER_LIMITS_PAYMENTS_DEPOSIT_RELOAD,
		data,
	}),

	withdrawalListReload: data => ({
		type: actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_RELOAD,
		data,
	}),

	depositListRefresh: data => ({
		type: actions.USER_LIMITS_PAYMENTS_DEPOSIT_REFRESH,
		data,
	}),

	withdrawalListRefresh: data => ({
		type: actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_REFRESH,
		data,
	}),

	baseDataRefresh: data => ({
		type: actions.USER_LIMITS_PAYMENTS_BASEDATA_REFRESH,
		data,
	}),

	withdrawalDataRefresh: data => ({
		type: actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_DATA_REFRESH,
		data,
	}),

	depositSave: (paymentID, userID) => ({
		type: actions.PAYMENT_DEPOSIT_SAVE,
		data: {
			paymentID,
			userID,
		},
	}),

	withdrawalSave: (paymentID, userID) => ({
		type: actions.PAYMENT_WITHDRAWAL_SAVE,
		data: {
			paymentID,
			userID,
		},
	}),

	depositUiRefresh: data => ({
		type: actions.USER_LIMITS_PAYMENTS_DEPOSIT_UI_REFRESH,
		data,
	}),

	withdrawalUiRefresh: data => ({
		type: actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_UI_REFRESH,
		data,
	}),

	dataReset: () => ({
		type: actions.USER_LIMITS_PAYMENTS_RESET,
	}),

};

export default actions;
