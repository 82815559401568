import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { isSuperAdmin } from '../../../helpers/permissions/utils';
import { deriveWebsitesList } from '../../../selectors/app';

import FormSelect from '../../FormControls/FormSelect';

import websitesActions from '../../../redux/partner/websites/list/actions';

import { iconWebsite, storeWebsiteID } from '../../../helpers/utility';
import { Wrapper } from './WebsiteSelect.style';
import { LoadingOutlined } from '@ant-design/icons';

class WebsiteSelect extends Component {

	static propTypes = {
		websiteID        : PropTypes.number,
		readOnly         : PropTypes.bool,
		noAutocomplete   : PropTypes.bool,
		onChange         : PropTypes.func,
		onAutoComplete   : PropTypes.func,
		usePopupContainer: PropTypes.bool,
		partnerLoading   : PropTypes.bool,
		// Redux
		websitesList     : PropTypes.array,
	};

	static defaultProps = {
		websiteID        : null,
		readOnly         : true,
		noAutocomplete   : false,
		partnerLoading   : false,
		websitesList     : [],
		onChange         : () => {},
		onAutoComplete   : () => {},
		usePopupContainer: true,
	};

	constructor(props) {
		super(props);
		this.timeout          = null;
		this.makeOptionsList  = this.makeOptionsList.bind(this);
		this.onChange         = this.onChange.bind(this);
	}

	componentDidMount() {
		const { websitesList, noAutocomplete, onAutoComplete } = this.props;
		if (!isArray(websitesList) || isEmpty(websitesList)) {
			// listReload();
		} else {
			const website = websitesList[0];
			if (!noAutocomplete) {
				onAutoComplete(toInteger(website.id));
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { websitesList, noAutocomplete, onAutoComplete } = this.props;
		const prevWebsiteList = prevProps.websitesList;
		if (isEqual(websitesList, prevWebsiteList)) {
			return;
		}

		if (isArray(websitesList) && !isEmpty(websitesList)) {
			const website = websitesList[0];
			if (!noAutocomplete) {
				onAutoComplete(toInteger(website.id));
			}
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	// Service --------------------------------------------------------------------------------------
	makeOptionsList() {
		const { websiteID, websitesList, partnerLoading } = this.props;

		websitesList.forEach(item => {
			if (!item.logo) {
				item.logo = iconWebsite;
			}
		});

		if (partnerLoading) {
			return [{
				id  : websiteID,
				name: (<LoadingOutlined />),
			}];
		}

		if (!websiteID) {
			return [{
				id  : websiteID,
				name: 'No Website',
			}];
		}

		if (isArray(websitesList) && !isEmpty(websitesList)) {
			return websitesList;
		}

		return [{
			id  : websiteID,
			name: (<LoadingOutlined />),
		}];
	}

	// Events ---------------------------------------------------------------------------------------
	onChange(websiteID) {
		const { onChange } = this.props;
		storeWebsiteID(websiteID);
		onChange(toInteger(websiteID));
	}

	// Renders --------------------------------------------------------------------------------------

	render() {
		const { websiteID, readOnly, usePopupContainer } = this.props;
		const optionsList = this.makeOptionsList();
		const hasArrow = optionsList.length > 1;
		return (
			<Wrapper hasArrow={hasArrow}>
				<FormSelect
					labelWidth={0}
					value={websiteID}
					disabled={readOnly}
					optionsList={optionsList}
					onChange={this.onChange}
					usePopupContainer = {usePopupContainer}
				/>
			</Wrapper>
		);
	}
}

function mapStateToProps(state) {
	const superAdmin     = isSuperAdmin();
	const websitesList   = deriveWebsitesList(state);
	const { partnerLoading } = state.Staff.Person.get('UI');
	const websiteCountDisabled = websitesList.length === 1;

	return {
		partnerLoading,
		websitesList,
		readOnly: (!superAdmin && websiteCountDisabled) || partnerLoading,
	};
}

export default connect(mapStateToProps, {
	listReload: websitesActions.listReload,
})(WebsiteSelect);
