import { Navigate } from 'react-router';
import { Routes } from '../../routes/routesData';

export const DistributeRoute = () => {
	const token = localStorage.getItem('id_token');

	if (token) {
		return <Navigate to={Routes.dashboard}/>;
	}

	return <Navigate to={Routes.signin} />;
};
