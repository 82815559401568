import { apiRequest } from './index';

// Security Questions -----------------------------------------------------------------------------

function questionsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/secretQuestions',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function questionAdd(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/secretQuestions',
		data,
		params,
	};

	return apiRequest(req);
}

function questionUpdate(questionID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/secretQuestions/${questionID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function questionDelete(questionID) {

	const req = {
		method: 'DELETE',
		url   : `/secretQuestions/${questionID}`,
	};

	return apiRequest(req);
}

// Security Whitelist -----------------------

function domainsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/whitelistdomains',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function domainAdd(domain, params = {}) {

	const req = {
		method: 'POST',
		url   : '/whitelistdomains',
		data  : {
			domain,
		},
		params,
	};

	return apiRequest(req);
}

function domainUpdate(domainID, domain, params = {}) {

	const req = {
		method: 'PUT',
		url   : `/whitelistdomains/${domainID}`,
		data  : {
			domain,
		},
		params,
	};

	return apiRequest(req);
}

function domainDelete(domainID) {

	const req = {
		method: 'DELETE',
		url   : `/whitelistdomains/${domainID}`,
	};

	return apiRequest(req);
}

export const securityAPI = {
	questionsList,
	questionAdd,
	questionUpdate,
	questionDelete,
	domainsList,
	domainAdd,
	domainUpdate,
	domainDelete,
};
