import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { emailTemplatesAPI } from '../../../../../helpers/api/emailTemplates';
import notifications from '../../../../../helpers/notifications';

import { adaptTemplatesList } from './utils';
import { logger } from '../../../../../helpers/logger';

const prefix = 'notifcenter.emailTemplates';

const messages = {
	errorListLoad    : `${prefix}.errorListLoad`,
	errorItemDelete  : `${prefix}.errorItemDelete`,
	successItemDelete: `${prefix}.successItemDelete`,
};


function* listReload() {
	yield takeEvery(actions.EMAIL_TEMPLATES_LIST_RELOAD, function* () {
		let entities = {};

		try {
			const res = yield call(emailTemplatesAPI.templatesList);
			if (res && res.status === 200) {
				entities = adaptTemplatesList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));
	});
}

export default function* emailTemplatesSaga() {
	yield all([
		fork(listReload),
	]);
}
