import { combineReducers } from 'redux';

import Header from './header/reducer';
import Footer from './footer/reducer';
import Articles from './articles/reducer';

export default combineReducers({
	Header,
	Footer,
	Articles,

});
