import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../tables/actions';

import { resultsAPI } from '../../../../helpers/api/results';
import notifications from '../../../../helpers/notifications';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';

import { getListParams, adaptResultsList } from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { logger } from '../../../../helpers/logger';

const prefix = 'participants.results.list';

const messages = {
	errorListLoad : `${prefix}.errorListLoad`,
	errorGetTable : `${prefix}.errorGetTable`,
	errorPostTable: `${prefix}.errorPostTable`,
};

const tableType = TABLE_TYPES.results;

function getStoreData(state) {
	const { Sport: { Results }, Tables } = state;

	return {
		filter    : Results.Filter.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.RESULTS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let listIDs    = [];
		let entities   = {};
		let totalCount = 0;
		try {
			const response = yield call(resultsAPI.resultsList, params);
			if (response && response.status === 200) {
				const result = adaptResultsList(response.data.data);
				listIDs      = result.listIDs;  // eslint-disable-line prefer-destructuring
				entities     = result.entities; // eslint-disable-line prefer-destructuring
				totalCount   = getHeadersTotalCount(response.headers) || listIDs.length;
			}

		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(listIDs, entities));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

export default function* resultsListSaga() {
	yield all([
		fork(listReload),
	]);
}
