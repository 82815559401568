import orderBy from 'lodash/orderBy';
import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { createEntities } from '../../../utility';

export const fields = {
	ID                : 'id',
	expireDate        : 'expire_date',
	givenDate         : 'given_date',
	bonusTypeID       : 'bonus_type_id',
	balance           : 'balance',
	wager             : 'wager',
	initialBalance    : 'initial_balance',
	maxBonus          : 'maximum_bonus',
	stakeLeftToConvert: 'stake_left_to_convert',
	stakeToConvert    : 'stake_to_convert',
	statusID          : 'status_id',
	period            : 'period',
	comment           : 'comment',
	maxPayoutAmount   : 'max_payout_amount',
	maxPayoutPercent  : 'max_payout_percent', // 0-100 number
};

const listAdapter = createListAdapter();

export function getListParams(pagination, filter) {

	const builder = new ParamsBuilder();

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	filter.status && builder.addValue('status_id', filter.status);

	return builder.biuldParams(filter);
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptCasinoBonusesList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = orderBy(listAdapter.adaptList(rawData), ['ID'], ['desc']);

	return createEntities(adaptedData, 'ID');
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'ID', fields.ID);
	adapter.addField(rules.id, 'bonusTypeID', fields.bonusTypeID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.dateTime, 'expireDate', fields.expireDate);
	adapter.addField(rules.dateTime, 'givenDate', fields.givenDate);
	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.number, 'initialBalance', fields.initialBalance);
	adapter.addField(rules.number, 'stakeLeftToConvert', fields.stakeLeftToConvert);
	adapter.addField(rules.number, 'stakeToConvert', fields.stakeToConvert);
	adapter.addField(rules.number, 'maxBonus', fields.maxBonus);
	adapter.addField(rules.number, 'wager', fields.wager);
	adapter.addField(rules.string, 'comment', fields.comment);
	adapter.addField(rules.numberOrNull,  'maxPayoutAmount', fields.maxPayoutAmount);
	adapter.addField(rules.numberOrNull,  'maxPayoutPercent', fields.maxPayoutPercent);

	return adapter;
}
