const actions = {

	CASINO_DATA_RESET       : 'CASINO_DATA_RESET',
	CASINO_BASE_DATA_RELOAD : 'CASINO_BASE_DATA_RELOAD',
	CASINO_BASE_DATA_REFRESH: 'CASINO_BASE_DATA_REFRESH',

	CASINO_AUTH_DATA_RELOAD : 'CASINO_AUTH_DATA_RELOAD',
	CASINO_AUTH_DATA_REFRESH: 'CASINO_AUTH_DATA_REFRESH',

	CASINO_NAMES_DATA_RELOAD : 'CASINO_NAMES_DATA_RELOAD',
	CASINO_NAMES_DATA_REFRESH: 'CASINO_NAMES_DATA_REFRESH',

	CASINO_SAVE      : 'CASINO_SAVE',
	CASINO_UI_REFRESH: 'CASINO_UI_REFRESH',

	CASINO_SEO_DATA_REFRESH: 'CASINO_SEO_DATA_REFRESH',
	CASINO_SEO_DATA_RELOAD	: 'CASINO_SEO_DATA_RELOAD',
	CASINO_SEO_DATA_SAVE 		: 'CASINO_SEO_DATA_SAVE',

	dataReset: () => ({
		type: actions.CASINO_DATA_RESET,
	}),
	baseDataReload: (casinoID, forced = false) => ({
		type: actions.CASINO_BASE_DATA_RELOAD,
		data: {
			casinoID,
			forced,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.CASINO_BASE_DATA_REFRESH,
		data,
	}),

	authDataReload: casinoID => ({
		type: actions.CASINO_AUTH_DATA_RELOAD,
		data: {
			casinoID,
		},
	}),

	namesDataReload: casinoID => ({
		type: actions.CASINO_NAMES_DATA_RELOAD,
		data: {
			casinoID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.CASINO_NAMES_DATA_REFRESH,
		data,
	}),

	casinoSave: closeModal => ({
		type: actions.CASINO_SAVE,
		closeModal,
	}),
	uiRefresh: data => ({
		type: actions.CASINO_UI_REFRESH,
		data,
	}),
	seoDataReload: casinoGameID => ({
		type: actions.CASINO_SEO_DATA_RELOAD,
		data: {
			casinoGameID,
		},
	}),

	seoDataSave: casinoGameID => ({
		type: actions.CASINO_SEO_DATA_SAVE,
		data: {
			casinoGameID,
		},
	}),

	seoDataRefresh: data => ({
		type: actions.CASINO_SEO_DATA_REFRESH,
		data,
	}),
};

export default actions;
