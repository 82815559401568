import EntityAdapter from '../../../helpers/entityAdapter';

const fields = {
	id       : 'id',
	logoUrl  : 'logo_url',
	name     : 'name',
	websiteID: 'website_id',
	providers: 'providers',

	integratorID: 'integrator_id',
	providerID  : 'provider_id',
	checked     : 'checked',
	status      : 'status',

};

const partnerIntegratorListAdapter = createListAdapter();
const partnerProviderListAdapter   = createProvidersAdapter();


export const adaptIntegratorsList = (data = []) => {
	const adaptedData = partnerIntegratorListAdapter.adaptList(data);

	const adaptedIntegrators = adaptedData.map(item => {
		item.checked = false;
		// item.gameCount = 117; // TODO change this to real value after BE done
		if (item[fields.providers] && item[fields.providers].length) {
			const adaptedProviders = partnerProviderListAdapter.adaptList(item[fields.providers]);
			return {
				...item,
				indeterminate     : true,
				[fields.providers]: adaptedProviders,
			};
		}
		return item;
	});

	return adaptedIntegrators || [];
};


function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'logoUrl', fields.logoUrl);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.arrayObject, 'providers', fields.providers);
	adapter.addField(rules.string, 'status', fields.status);

	return adapter;
}


function createProvidersAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'integratorID', fields.integratorID);
	adapter.addField(rules.id, 'providerID', fields.providerID);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.bool, 'checked', fields.checked);

	return adapter;
}
