import isArray from 'lodash/isArray';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../utility';

export const fields = {
	id      : 'id',
	subject : 'subject',
	content : 'content',
	langID  : 'lang_id',
	actionID: 'action_id',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptTemplatesList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return createEntities(adaptedData);
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'actionID', fields.actionID);

	adapter.addField(rules.string, 'subject', fields.subject);
	adapter.addField(rules.string, 'content', fields.content);

	return adapter;
}
