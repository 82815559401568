import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	entities    : {},
	mainEntities: {},

	UI: {
		loading: false,
	},
});

export default function paymentsListReducer(state = initState, action) {
	switch (action.type) {
		case actions.PAYMENTS_LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.PAYMENTS_MAIN_LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('mainEntities', entities);
		}

		case actions.PAYMENTS_LIST_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
