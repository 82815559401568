import { combineReducers } from 'redux';

import List from './list/reducer';
import Filter from './filter/reducer';
import FilterRoles from './filterRoles/reducer';
import Person from './person/reducer';
import Roles from './roles/reducer';
import Permissions from './permissions/reducer';

export default combineReducers({
	List,
	Filter,
	FilterRoles,
	Person,
	Roles,
	Permissions,
});
