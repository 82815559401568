import { apiRequest } from './index';

function rtmWithdrawalsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/rtm/withdrawals',
		withoutLangID: true,
		params,
	};

	return apiRequest(req );
}

function rtmWithdrawalInfo(withdrawalID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/rtm/withdrawals/${withdrawalID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function withdrawApprove(transactionID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/withdraw/approve/${transactionID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function rtmCSVLoad(params = {}) {

	const req = {
		method: 'GET',
		url   : '/rtm/csv/withdrawals',
		params,
	};

	return apiRequest(req);
}

function rtmCSVRecalculate(params = {}) {

	const req = {
		method: 'GET',
		url   : '/rtm/csv/recalculate/withdrawals',
		params,
	};

	return apiRequest(req);
}

export const withdrawalsAPI = {
	rtmWithdrawalsList,
	rtmWithdrawalInfo,
	withdrawApprove,

	rtmCSVLoad,
	rtmCSVRecalculate,
};
