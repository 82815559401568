import React from 'react';
import IntlMessages from '../../components/utility/intlMessages';

export const lang = {
	profile        : <IntlMessages id = "profile.topbar.profile" />,
	logout         : <IntlMessages id = "profile.topbar.logout" />,
	modalTitle     : <IntlMessages id = "profile.modal.modalTitle" />,
	avatar         : <IntlMessages id = "profile.modal.avatar" />,
	userData       : <IntlMessages id = "profile.modal.userData" />,
	userName       : <IntlMessages id = "profile.modal.userName" />,
	email          : <IntlMessages id = "profile.modal.email" />,
	changePassword : <IntlMessages id = "profile.modal.changePassword" />,
	confirmPassword: <IntlMessages id = "profile.modal.confirmPassword" />,
	showUserPage   : <IntlMessages id = "profile.modal.showUserPage" />,
	currentPassword: <IntlMessages id = "profile.modal.currentPassword" />,
	currency       : <IntlMessages id = "common.currency" />,
};
