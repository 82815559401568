import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import tableActions from '../../tables/actions';

import { getListParams } from './utils';
import { adaptDepositList } from '../deposits/utils';

import notifications from '../../../helpers/notifications';
import { depositsAPI } from '../../../helpers/api/deposits';
import { getHeadersTotalCount } from '../../../helpers/utils';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { logger } from '../../../helpers/logger';

const prefix = 'rtm.userDeposits';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
};
const tableType = TABLE_TYPES.depositProfile;

function getStoreData(state) {
	const { RTM, Tables } = state;

	return {
		filter    : RTM.UserDeposits.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.RTM_USER_DEPOSITS_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let depositIDs    = [];
		let entities      = {};
		let depositTotals = {};
		let totalCount    = 0;
		try {
			const response = yield call(depositsAPI.rtmDepositsList, params);
			if (response && response.status === 200) {
				const result  = adaptDepositList(response.data.data);
				depositIDs    = result.depositIDs;    // eslint-disable-line prefer-destructuring
				entities      = result.entities;      // eslint-disable-line prefer-destructuring
				depositTotals = result.depositTotals; // eslint-disable-line prefer-destructuring
				totalCount    = getHeadersTotalCount(response.headers) || depositIDs.length;
			}

		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			logger.log(error);
		}

		yield put(actions.dataRefresh(depositIDs, entities, depositTotals));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.RTM_USER_DEPOSITS_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

export default function* rtmUserDepositsSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
	]);
}
