import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { paymentsAPI } from '../../../../../helpers/api/payments';
import notifications from '../../../../../helpers/notifications';

import { adaptPaywaysList } from './utils';
import { logger } from '../../../../../helpers/logger';
import { cloneDeep } from 'lodash';

const prefix = 'settings.payways';

const messages = {
	errorListLoad      : `${prefix}.errorListLoad`,
	errorApiListRefresh: `${prefix}.errorApiListRefresh`,
};

function getStoreData(state) {
	const { Partner: { Websites : { Website, Payways }  } } = state;
	
	return {
		website_id: Website.get('UI').websiteID,
		entities  : cloneDeep(Payways.Payways.get('entities')),
	};
}

function* listReload() {

	yield takeEvery(actions.PAYMENTS_PAYWAYS_LIST_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { website_id, entities } = yield select(getStoreData);
		const { websitePaymentID, paymentID } = action.data;
		const params = { website_id };

		try {
			const res = yield call(paymentsAPI.paywaysList, paymentID, params);
			if (res && res.status === 200) {
				entities[websitePaymentID] = adaptPaywaysList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* apiListRefresh() {

	yield takeEvery(actions.PAYMENTS_PAYWAYS_API_REFRESH, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { paymentID } = action.data;
		try {
			const res = yield call(paymentsAPI.paywaysListRefresh, paymentID);
			if (res && res.status === 200) {
				yield put(actions.listReload(paymentID));
			}
		} catch (error) {
			notifications.showError(messages.errorApiListRefresh, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* paywaysListSaga() {
	yield all([
		fork(listReload),
		fork(apiListRefresh),
	]);
}
