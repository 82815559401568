export const SELECTIONS_COUNT_TYPES = {
	standard: 1,
	minMax  : 2,
};

export const MODAL_TABS = {
	details      : 'details',
	common       : 'common',
	selections   : 'selections',
	settings     : 'settings',
	dynamicLimits: 'dynamicLimits',
	suspensions  : 'suspensions',
	mappings     : 'mappings',
	notes        : 'notes',
	names        : 'names',
	descriptions : 'descriptions',
	logs         : 'logs',
	resultRules  : 'resultRules',
};
