import EntityAdapter from '../../../../helpers/entityAdapter';


export const fields = {
	id           : 'id',
	websiteID    : 'website_id',
	priority     : 'priority',
	name         : 'name',
	sender       : 'sender',
	url          : 'url',
	phonePrefixes: 'telephone_number_prefixes',
	statusID     : 'status',
	default      : 'default',
	smsGatewayId : 'sms_gateway_id',
	defaultSender: 'default_sender',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptSmsGateweysList(rawData = []) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);
	return adaptedData.map(gateway => {
		return {
			...gateway,
			defaultSender: !gateway.sender,
		};
	});
}
// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'smsGatewayId', fields.smsGatewayId);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.number, 'priority', fields.priority);
	adapter.addField(rules.number, 'statusID', fields.statusID);

	adapter.addField(rules.bool, 'default', fields.default);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'sender', fields.sender);
	adapter.addField(rules.string, 'url', fields.url);
	adapter.addField(rules.bool, 'defaultSender', fields.defaultSender);

	adapter.addField(rules.arrayString, 'phonePrefixes', fields.phonePrefixes);

	return adapter;
}
