import { apiRequest } from './index';

function playersOverallReq(params = {}) {
	const req = {
		method: 'GET',
		url   : '/dashboard/players/stats',
		params,
	};

	return apiRequest(req);
}
function playersDepositReq(params = {}) {
	const req = {
		method: 'GET',
		url   : '/dashboard/deposit/stats',
		params,
	};

	return apiRequest(req);
}

function betsByProviderList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/dashboard/sport/provider/stats',
		params,
	};

	return apiRequest(req);
}
function placedBetsReq(params = {}) {
	const req = {
		method: 'GET',
		url   : '/dashboard/bets/stats',
		params,
	};

	return apiRequest(req);
}
function playersWithdrawalsReq(params = {}) {
	const req = {
		method: 'GET',
		url   : '/dashboard/withdrawal/stats',
		params,
	};

	return apiRequest(req);
}

function dashboardMarketingChartReq(params = {}) {
	const req = {
		method: 'GET',
		url   : '/dashboard/marketing',
		params,
	};

	return apiRequest(req);
}
function dashboardUsersChartReq(params = {}) {
	const req = {
		method: 'GET',
		url   : '/dashboard/online',
		params,
	};

	return apiRequest(req);
}

export const dashboardAPI = {
	playersOverallReq,
	placedBetsReq,
	betsByProviderList,
	playersDepositReq,
	playersWithdrawalsReq,
	dashboardMarketingChartReq,
	dashboardUsersChartReq,
};
