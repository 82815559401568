import { apiRequest } from './index';

function reportsListByIntegrator(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/casinoReports/listByIntegrator',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

export const reportsListByIntegratorAPI = {
	reportsListByIntegrator,
};
