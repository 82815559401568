import { Map } from 'immutable';
import actions from './actions';
import { createFilter, fill, filtersURL } from '../../../../../helpers/utils';
import { initFilterTypes } from './utils';

const initialState = new Map({
	entities: [],

	filter: createFilter(filtersURL.blockedIPs, initFilterTypes),

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},
	UI: { loading: false },
});

export default function blockedIpsReducer(state = initialState, { type, data }) {
	switch (type) {

		case actions.BLOCKED_IPS_LIST_REFRESH: {
			const { entities } = data;
			return state.set('entities', entities);
		}

		case actions.BLOCKED_IPS_LIST_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(data, target, true);
			return state.set('filter', result);
		}

		case actions.BLOCKED_IPS_LIST_PAGINATION_REFRESH: {
			const target = state.get('pagination');
			const result = fill(data, target);
			return state.set('pagination', result);
		}

		case actions.BLOCKED_IPS_LIST_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(data, target);
			return state.set('UI', result);
		}
		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
