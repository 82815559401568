import { combineReducers } from 'redux';

import List from './list/reducer';
import Filter from './filter/reducer';
import Market from './market/reducer';
import Mapping from './mapping/reducer';
import Confirm from './confirmCancel/reducer';

import Groups from './groups/reducer';
// import Ladders from '../settings/ladders/reducer';
import DisplayTypes from './displayTypes/reducer';

import History from './history/reducer';

export default combineReducers({
	List,
	Filter,
	Market,
	Mapping,
	Confirm,
	Groups,
	// Ladders,
	DisplayTypes,
	History,
});
