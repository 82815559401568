import EntityAdapter from '../../../../../helpers/entityAdapter';


const fields = {
	limitationReason: 'limitation_reason',
	maxStake        : 'max_stake',
	maxPayout       : 'max_payout',
	maxActiveStake  : 'max_active_stake',
	maxActivePayout : 'max_active_payout',
};

export function adaptSportLimits(rawServerData) {
	const adapter = sportLimitAdapter();
	return adapter.adapt(rawServerData);
}

export const prepareSportLimits = (data) => {
	const adapter = sportLimitAdapter();
	const resp = adapter.prepare(data);
	return resp;
};


function sportLimitAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'limitationReason', fields.limitationReason);

	adapter.addField(rules.numberOrNull, 'maxStake', fields.maxStake);
	adapter.addField(rules.numberOrNull, 'maxPayout', fields.maxPayout);
	adapter.addField(rules.numberOrNull, 'maxActiveStake', fields.maxActiveStake);
	adapter.addField(rules.numberOrNull, 'maxActivePayout', fields.maxActivePayout);
	return adapter;
}
