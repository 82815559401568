
const prefix = 'WEBSITE_INNER_PAYMENT';

const actions = {
	WEBSITE_PAYMENTS_DATA_RELOAD            : `${prefix}S_DATA_RELOAD`,
	WEBSITE_PAYMENTS_DATA_REFRESH           : `${prefix}S_DATA_REFRESH`,
	WEBSITE_PAYMENTS_LIST_NAMES_DATA_REFRESH: `${prefix}S_LIST_NAMES_DATA_REFRESH`,
	WEBSITE_PAYMENTS_REORDER                : `${prefix}S_REORDER`,

	WEBSITE_PAYMENT_LIMITS_DATA_RELOAD : `${prefix}S_LIMITS_DATA_RELOAD`,
	WEBSITE_PAYMENT_LIMITS_DATA_REFRESH: `${prefix}_LIMITS_DATA_REFRESH`,

	WEBSITE_PAYMENTS_LIST_BASE_MAIN_DATA_RELOAD : `${prefix}S_LIST_BASE_MAIN_DATA_RELOAD`,
	WEBSITE_PAYMENTS_LIST_BASE_MAIN_DATA_REFRESH: `${prefix}S_LIST_BASE_MAIN_DATA_REFRESH`,

	WEBSITE_PAYMENTS_CURRENCY_MAIN_DATA_RELOAD           : `${prefix}S_CURRENCY_MAIN_DATA_RELOAD`,
	WEBSITE_PAYMENTS_CURRENCY_BY_PAYMENT_MAIN_DATA_RELOAD: `${prefix}WEBSITE_PAYMENTS_CURRENCY_BY_PAYMENT_MAIN_DATA_RELOAD`,
	WEBSITE_PAYMENTS_CURRENCY_MAIN_DATA_REFRESH          : `${prefix}S_CURRENCY_MAIN_DATA_REFRESH`,

	WEBSITE_PAYMENT_DESCRIPTION_DATA_RELOAD : `${prefix}_DESCRIPTION_DATA_RELOAD`,
	WEBSITE_PAYMENT_DESCRIPTION_DATA_REFRESH: `${prefix}_DESCRIPTION_DATA_REFRESH`,

	WEBSITE_PAYMENT_NAMES_DATA_RELOAD : `${prefix}_NAMES_DATA_RELOAD`,
	WEBSITE_PAYMENT_NAMES_DATA_REFRESH: `${prefix}_NAMES_DATA_REFRESH`,

	WEBSITE_PAYMENT_UI_REFRESH: `${prefix}_UI_REFRESH`,
	WEBSITE_PAYMENT_SAVE      : `${prefix}_SAVE`,
	WEBSITE_PAYMENT_DELETE    : `${prefix}_DELETE`,
	WEBSITE_PAYMENTS_RESET    : `${prefix}_RESET`,

	paymentsReload: websiteID => ({
		type: actions.WEBSITE_PAYMENTS_DATA_RELOAD,
		data: {
			websiteID,
		},
	}),

	paymentsListReloadByNames: (data) => ({
		type: actions.WEBSITE_PAYMENTS_LIST_BASE_MAIN_DATA_RELOAD,
		data,
	}),

	paymentsListBaseRefresh: entities => ({
		type: actions.WEBSITE_PAYMENTS_LIST_BASE_MAIN_DATA_REFRESH,
		data: {
			entities,
		},
	}),

	websitePaymentCurrencyReload: (paymentsIDs, websiteID) => ({
		type: actions.WEBSITE_PAYMENTS_CURRENCY_MAIN_DATA_RELOAD,
		paymentsIDs,
		websiteID,
	}),

	websitePaymentCurrencyByIdReload: (paymentID, websiteID, websitePaymentID) => ({
		type: actions.WEBSITE_PAYMENTS_CURRENCY_BY_PAYMENT_MAIN_DATA_RELOAD,
		paymentID,
		websiteID,
		websitePaymentID,
	}),

	currencyDataRefresh: data => ({
		type: actions.WEBSITE_PAYMENTS_CURRENCY_MAIN_DATA_REFRESH,
		data,
	}),

	paymentsRefresh: data => ({
		type: actions.WEBSITE_PAYMENTS_DATA_REFRESH,
		data,
	}),

	paymentsListNamesRefresh: data => ({
		type: actions.WEBSITE_PAYMENTS_LIST_NAMES_DATA_REFRESH,
		data,
	}),

	paymentsReorder: () => ({
		type: actions.WEBSITE_PAYMENTS_REORDER,
	}),

	paymentLimitsReload: websitePaymentID => ({
		type: actions.WEBSITE_PAYMENT_LIMITS_DATA_RELOAD,
		data: {
			websitePaymentID,
		},
	}),

	paymentLimitsRefresh: (websitePaymentID, limitsData) => ({
		type: actions.WEBSITE_PAYMENT_LIMITS_DATA_REFRESH,
		data: {
			websitePaymentID,
			limitsData,
		},
	}),

	paymentDescriptionReload: websitePaymentID => ({
		type: actions.WEBSITE_PAYMENT_DESCRIPTION_DATA_RELOAD,
		data: {
			websitePaymentID,
		},
	}),

	paymentDescriptionRefresh: (websitePaymentID, data) => ({
		type: actions.WEBSITE_PAYMENT_DESCRIPTION_DATA_REFRESH,
		data: {
			websitePaymentID,
			data,
		},
	}),

	namesDataReload: (websitePaymentID, paymentID) => ({
		type: actions.WEBSITE_PAYMENT_NAMES_DATA_RELOAD,
		data: {
			websitePaymentID,
			paymentID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.WEBSITE_PAYMENT_NAMES_DATA_REFRESH,
		data,
	}),

	paymentSave: paymentID => ({
		type: actions.WEBSITE_PAYMENT_SAVE,
		data: {
			paymentID,
		},
	}),

	paymentDelete: paymentID => ({
		type: actions.WEBSITE_PAYMENT_DELETE,
		data: {
			paymentID,
		},
	}),

	uiRefresh: data => ({
		type: actions.WEBSITE_PAYMENT_UI_REFRESH,
		data,
	}),

	paymentData: () => ({
		type: actions.WEBSITE_PAYMENTS_RESET,
	}),
};

export default actions;
