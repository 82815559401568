import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import QueryToFilterValidaion from '../../../../../helpers/queryToFilterValidaion';

const { isValidID, isValidString, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	userId   : isValidID,
	ip       : isValidString,
	email    : isValidString,
	dateRange: isValidDateRange,
};

export function getListParamsDelete(params) {
	const builder = new ParamsBuilder();

	builder.addValue('website_id', params.websiteID);

	return builder.biuldParams({});

}
