
const prefix = 'WEBSITE_MODAL_SMS_GATEWAYS_';

const actions = {
	WEBSITE_MODAL_SMS_GATEWAYS_RELOAD     : `${prefix}RELOAD`,
	WEBSITE_MODAL_SMS_GATEWAYS_UI_REFRESH : `${prefix}UI_REFRESH`,
	WEBSITE_MODAL_SMS_GATEWAYS_REFRESH    : `${prefix}REFRESH`,
	WEBSITE_MODAL_SMS_GATEWAYS_REORDERING : `${prefix}REORDERING`,
	WEBSITE_MODAL_SMS_GATEWAYS_SAVE       : `${prefix}SAVE`,
	WEBSITE_MODAL_SMS_GATEWAYS_DELETE_ITEM: `${prefix}DELETE_ITEM`,
	RESET                                 : `${prefix}RESET`,

	listReload: websiteID => ({
		type: actions.WEBSITE_MODAL_SMS_GATEWAYS_RELOAD,
		data: {
			websiteID,
		},
	}),

	onSave: (id, websiteID) => ({
		type: actions.WEBSITE_MODAL_SMS_GATEWAYS_SAVE,
		data: { id, websiteID },
	}),

	onDelete: (id, websiteID) => ({
		type: actions.WEBSITE_MODAL_SMS_GATEWAYS_DELETE_ITEM,
		data: { id, websiteID },
	}),

	uiRefresh: data => ({
		type: actions.WEBSITE_MODAL_SMS_GATEWAYS_UI_REFRESH,
		data,
	}),

	listRefresh: data => ({
		type: actions.WEBSITE_MODAL_SMS_GATEWAYS_REFRESH,
		data,
	}),

	smsGatewaysReordering: websiteID => ({
		type: actions.WEBSITE_MODAL_SMS_GATEWAYS_REORDERING,
		data: { websiteID },
	}),

	resetData: () => ({
		type: actions.RESET,
	}),
};

export default actions;
