import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import notifications from '../../../../helpers/notifications';
import { historyAPI } from '../../../../helpers/api/history';
import { MARKET_LOG_MODELS } from '../../../../helpers/commonConstants';

import actions from './actions';
import { adaptData, adaptAllData } from './utils';
import { logger } from '../../../../helpers/logger';

const prefix = 'sport.markets.history';

const messages = {
	loadData: `${prefix}.loadData`,
};

function* dataReload() {
	yield takeEvery(actions.MARKET_HISTORY_MODEL_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		let data = [];
		const { marketID, logModel } = action.data;

		if (logModel === MARKET_LOG_MODELS.all) {
			data = yield allDataReload(marketID);
		} else {
			data = yield modelDataReload(marketID, logModel);
		}

		yield put(actions.dataRefresh(data, logModel));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* modelDataReload(marketID, logModel) {
	let data = [];
	try {
		const response = yield call(
			historyAPI.historyMarketLogs,
			marketID,
			logModel
		);
		if (response && response.status === 200) {
			data = adaptData(response.data.data, logModel);
		}
	} catch (error) {
		notifications.showError(messages.loadData, error);
		logger.log(error);
	}

	return data;
}

function* allDataReload(marketID) {
	const market = yield modelDataReload(marketID, MARKET_LOG_MODELS.market);
	const settings = yield modelDataReload(marketID, MARKET_LOG_MODELS.settings);
	const cms = yield modelDataReload(marketID, MARKET_LOG_MODELS.cms);
	const dynamicLimits = yield modelDataReload(
		marketID,
		MARKET_LOG_MODELS.dynamicLimits
	);
	const names = yield modelDataReload(marketID, MARKET_LOG_MODELS.names);
	const orders = yield modelDataReload(marketID, MARKET_LOG_MODELS.orders);

	const allData = adaptAllData({
		market,
		settings,
		cms,
		dynamicLimits,
		names,
		orders,
	});

	return allData;
}

export default function* marketHistorySaga() {
	yield all([fork(dataReload)]);
}
