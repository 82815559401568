const actions = {

	CATEGORY_TREE_RELOAD             : 'CATEGORY_TREE_RELOAD',
	CATEGORY_TREE_REFRESH            : 'CATEGORY_TREE_REFRESH',
	CATEGORY_TREE_COUNTRY_LIST_RELOAD: 'CATEGORY_TREE_COUNTRY_LIST_RELOAD',
	CATEGORY_TREE_LEAGUE_LIST_RELOAD : 'CATEGORY_TREE_LEAGUE_LIST_RELOAD',
	CATEGORY_TREE_EVENT_LIST_RELOAD  : 'CATEGORY_TREE_EVENT_LIST_RELOAD',
	CATEGORY_TREE_MARKET_LIST_RELOAD : 'CATEGORY_TREE_MARKET_LIST_RELOAD',

	CATEGORY_TREE_SET_SELECTED     : 'CATEGORY_TREE_SET_SELECTED',
	CATEGORY_TREE_SET_EXPANDED_KEYS: 'CATEGORY_TREE_SET_EXPANDED_KEYS',
	CATEGORY_TREE_SET_SHOW_HIDDEN  : 'CATEGORY_TREE_SET_SHOW_HIDDEN',
	CATEGORY_TREE_SET_SHOW_INACTIVE: 'CATEGORY_TREE_SET_SHOW_INACTIVE',

	CATEGORY_TREE_SET_DISPLAY_STATUS: 'CATEGORY_TREE_SET_DISPLAY_STATUS',

	CATEGORY_TREE_REORDERED_CATEGORIES_REFRESH: 'CATEGORY_TREE_REORDERED_CATEGORIES_REFRESH',
	CATEGORY_TREE_ORDERS_UI_REFRESH           : 'CATEGORY_TREE_ORDERS_UI_REFRESH',
	CATEGORY_TREE_ORDERS_SAVE                 : 'CATEGORY_TREE_ORDERS_SAVE',

	categoryTreeReload: (channelID) => ({
		type: actions.CATEGORY_TREE_RELOAD,
		data: {
			channelID,
		},
	}),
	categoryTreeRefresh: tree => ({
		type: actions.CATEGORY_TREE_REFRESH,
		data: tree,
	}),
	countryListReload: (sportID, parentNodePos, typeID, cb) => ({
		type: actions.CATEGORY_TREE_COUNTRY_LIST_RELOAD,
		data: {
			sportID,
			parentNodePos,
			typeID,
			cb,
		},
	}),
	leagueListReload: (countryID, parentNodePos, typeID, cb) => ({
		type: actions.CATEGORY_TREE_LEAGUE_LIST_RELOAD,
		data: {
			countryID,
			parentNodePos,
			typeID,
			cb,
		},
	}),
	eventListReload: (leagueID, parentNodePos, cb) => ({
		type: actions.CATEGORY_TREE_EVENT_LIST_RELOAD,
		data: {
			leagueID,
			parentNodePos,
			cb,
		},
	}),
	marketListReload: (eventID, parentNodePos, cb) => ({
		type: actions.CATEGORY_TREE_MARKET_LIST_RELOAD,
		data: {
			eventID,
			parentNodePos,
			cb,
		},
	}),

	setSelected: data => ({
		type: actions.CATEGORY_TREE_SET_SELECTED,
		data,
	}),
	setExpandedKeys: keys => ({
		type: actions.CATEGORY_TREE_SET_EXPANDED_KEYS,
		data: keys,
	}),
	setShowHidden: showHidden => ({
		type: actions.CATEGORY_TREE_SET_SHOW_HIDDEN,
		data: showHidden,
	}),
	setShowInactive: showInactive => ({
		type: actions.CATEGORY_TREE_SET_SHOW_INACTIVE,
		data: showInactive,
	}),
	setDisplayStatus: (categoryID, displayStatusID, nodePos) => ({
		type: actions.CATEGORY_TREE_SET_DISPLAY_STATUS,
		data: {
			categoryID,
			displayStatusID,
			nodePos,
		},
	}),

	reorderedCategoriesRefresh: reorderedCategories => ({
		type: actions.CATEGORY_TREE_REORDERED_CATEGORIES_REFRESH,
		data: {
			reorderedCategories,
		},
	}),
	ordersUIRefresh: ordersUI => ({
		type: actions.CATEGORY_TREE_ORDERS_UI_REFRESH,
		data: {
			ordersUI,
		},
	}),
	ordersSave: () => ({
		type: actions.CATEGORY_TREE_ORDERS_SAVE,
	}),
};
export default actions;
