import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import toNumber from 'lodash/toNumber';
import {
	CHANNEL_TYPES,
	DURATIONS,
	DYNAMIC_LIMITS_TIMES,
} from '../../../../helpers/commonConstants';
import { channelTypeList } from '../../../../helpers/commonEnums';
import EntityAdapter from '../../../../helpers/entityAdapter';
import {
	getTimeOfMilliseconds,
	getMillisecondsOfTime,
} from '../../../../helpers/utility';
import { isID } from '../../../../helpers/utils';

const fields = {
	id                       : 'id',
	statusID                 : 'status_id',
	sportID                  : 'category_id',
	scopeID                  : 'scope_id',
	statisticTypeID          : 'statistic_type_id',
	tradingModeID            : 'trading_mode',
	resultSourceID           : 'result_source_id',
	tradingStatusID          : 'trading_status_id',
	// bool
	multiWinner              : 'multi_winner',
	useProviderOdds          : 'use_provider_odds',
	voidOnEventCancel        : 'void_on_event_cancel',
	autoCreate               : 'auto_create',
	allowMixedMultiplies     : 'allow_mixed_multiplies',
	sportDefault             : 'sport_default',
	// number
	ladderFrom               : 'ladder_from',
	ladderTo                 : 'ladder_to',
	winningCount             : 'winning_count',
	timeFrom                 : 'time_from',
	timeTo                   : 'time_to',
	selectionsMin            : 'selections_min',
	selectionsMax            : 'selections_max',
	selectionsFixed          : 'selections_fixed',
	combinationMin           : 'combination_min',
	combinationMax           : 'combination_max',
	// string
	code                     : 'code',
	// array
	alert                    : 'alert',
	notes                    : 'notes',
	// array ID
	groupsIDs                : 'groups',
	restrictedMarketsIDs     : 'restricted_markets',
	displayTypeIDs           : 'display_type',
	restrictionGroupsIDs     : 'restriction_groups',
	// mix
	suspensionPartly         : 'suspension_partly',
	suspensionBeforeStart    : 'suspension_before_start',
	suspensionAfterScopeStart: 'suspension_after_scope_start',
	suspensionBeforeScopeEnd : 'suspension_before_scope_end',
	suspensionAnyTeamScope   : 'suspension_any_team_scope',
	suspensionBothTeamScope  : 'suspension_both_team_scope',
	suspensionMinOdd         : 'suspension_min_odd',
	suspensionMaxOdd         : 'suspension_max_odd',

	userWonLimit        : 'user_won_limit',
	userWonLimitTypeID  : 'user_won_limit_value_type',
	userWonLimitOverride: 'override_user_won_limit',

	userStakeLimit        : 'user_stake_limit',
	userStakeLimitTypeID  : 'user_stake_limit_value_type',
	userStakeLimitOverride: 'override_user_stake_limit',

	// names
	marketID : 'market_id',
	langID   : 'lang_id',
	name     : 'name',
	altName1 : 'alt_name_1',
	altName2 : 'alt_name_2',
	channelID: 'channel_id',
	orderID  : 'order_id',

	// Mappings
	providerName      : 'provider_name',
	providerMarketName: 'provider_market_name',
	feedMarketID      : 'feed_market_id',
	feedName          : 'feed_name',
	eventID           : 'feed_event_id',

	// CMS
	description1 : 'description_1',
	description2 : 'description_2',
	// dynamic limits
	minutesBefore: 'minutes_before',
	limit        : 'limit',

	// settings
	stakeLimitParent  : 'bet_stake_limit_parent',
	stakeLimit        : 'bet_stake_limit',
	stakeLimitTypeID  : 'bet_stake_limit_value_type',
	stakeLimitOverride: 'override_stake_limit',

	wonLimitParent  : 'bet_won_limit_parent',
	wonLimit        : 'bet_won_limit',
	wonLimitTypeID  : 'bet_won_limit_value_type',
	wonLimitOverride: 'override_won_limit',

	betDelay: 'bet_delay',

	acceptBetsBeforeStart         : 'accept_bets_before_start',
	acceptBetsBeforeStartTimeValue: 'accept_bets_before_start_time_value',
	acceptBetsBeforeStartTimeID   : 'acceptBetsBeforeStartTimeID',

	overask             : 'overask',
	overaskStakeLimitMin: 'overask_stake_min_limit',
	overaskStakeLimitMax: 'overask_stake_max_limit',
	overaskWonLimitMin  : 'overask_won_min_limit',
	overaskWonLimitMax  : 'overask_won_max_limit',
};

export function adaptBaseData(rawData = {}) {
	const adapter = getBaseDataAdapter();
	const result = adapter.adapt(rawData);
	// additional fields
	result.id = isID(rawData.id) ? toInteger(rawData.id) : null;

	result.selectionsCountModeID = toInteger(result.selectionsMax) || toInteger(result.selectionsMin) ? 2 : 1;

	const defaultTimeID   = 1; // minutes
	const exactComparison = true;
	const suspensions = {
		beforeStart    : getTimeOfMilliseconds(result.suspensionBeforeStart, exactComparison),
		afterScopeStart: getTimeOfMilliseconds(result.suspensionAfterScopeStart, exactComparison),
		beforeScopeEnd : getTimeOfMilliseconds(result.suspensionBeforeScopeEnd, exactComparison, defaultTimeID),
	};

	result.suspensionBeforeStartTimeValue     = suspensions.beforeStart.count;
	result.suspensionBeforeStartTimeID        = suspensions.beforeStart.id || defaultTimeID;
	result.suspensionAfterScopeStartTimeValue = suspensions.afterScopeStart.count;
	result.suspensionAfterScopeStartTimeID    = suspensions.afterScopeStart.id || defaultTimeID;
	result.suspensionBeforeScopeEndTimeValue  = suspensions.beforeScopeEnd.count;
	result.suspensionBeforeScopeEndTimeID     = suspensions.beforeScopeEnd.id || defaultTimeID;

	result.gameClockID = result.suspensionBeforeScopeEnd ? 2 : 1;
	return result;
}

export function prepareBaseData(rawData, marketID = null) {
	const adapter = getBaseDataAdapter();
	const result = adapter.prepare(rawData);
	// additional fields
	if (isID(marketID)) {
		result.id = toInteger(marketID);
	}

	// Selections
	let selectionsFixed = null;
	let selectionsMin = null;
	let selectionsMax = null;

	if (toInteger(rawData.selectionsCountModeID) === 1) {
		selectionsFixed = rawData.selectionsFixed; // eslint-disable-line prefer-destructuring
	} else {
		selectionsMin = rawData.selectionsMin; // eslint-disable-line prefer-destructuring
		selectionsMax = rawData.selectionsMax; // eslint-disable-line prefer-destructuring
	}

	result[fields.selectionsFixed] = selectionsFixed;
	result[fields.selectionsMin] = selectionsMin;
	result[fields.selectionsMax] = selectionsMax;

	// Suspensions
	let suspensionAfterScopeStart = 0;
	let suspensionBeforeScopeEnd = 0;

	if (toInteger(rawData.gameClockID) === 1) {
		suspensionAfterScopeStart = getMillisecondsOfTime(
			rawData.suspensionAfterScopeStartTimeValue,
			rawData.suspensionAfterScopeStartTimeID
		);
	} else {
		suspensionBeforeScopeEnd = getMillisecondsOfTime(
			rawData.suspensionBeforeScopeEndTimeValue,
			rawData.suspensionBeforeScopeEndTimeID
		);
	}

	result[fields.suspensionBeforeStart] = getMillisecondsOfTime(
		rawData.suspensionBeforeStartTimeValue,
		rawData.suspensionBeforeStartTimeID
	);
	result[fields.suspensionAfterScopeStart] = suspensionAfterScopeStart;
	result[fields.suspensionBeforeScopeEnd] = suspensionBeforeScopeEnd;

	return result;
}

export function adaptNamesData(rawData = [], defaultName = null) {
	const result = [];

	channelTypeList.forEach(channelItem => {
		const channelID   = channelItem.id;
		const channelName = channelItem.name;
		const namesItem   = rawData.find(item => toInteger(item[fields.channelID]) === channelID) || {};

		const record = {
			channelID,
			channel : channelName,
			name    : namesItem[fields.name],
			altName1: namesItem[fields.altName1],
			altName2: namesItem[fields.altName2],
		};

		if (channelID === CHANNEL_TYPES.backend && !record.name && defaultName) {
			record.name = defaultName;
		}

		result.push(record);
	});

	return result;
}

export function prepareNamesData(rawData = [], marketID, langID) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = [];

	rawData.forEach(namesItem => {
		if (!namesItem.name) {
			return;
		}

		result.push({
			[fields.channelID]: namesItem.channelID,
			[fields.name]     : namesItem.name,
			[fields.altName1] : namesItem.altName1,
			[fields.altName2] : namesItem.altName2,
			[fields.marketID] : marketID,
			[fields.langID]   : langID,
		});
	});

	return result;
}

export function adaptOrdersData(rawData = []) {
	const result = [];

	channelTypeList.forEach(channelItem => {
		const channelID  = channelItem.id;
		const ordersItem = rawData.find(item => toInteger(item[fields.channelID]) === channelID) || {};

		result.push({
			channelID,
			orderID: ordersItem[fields.orderID],
		});
	});

	return result;
}

export function prepareOrdersData(rawData = [], marketID) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = [];

	rawData.forEach(oredersItem => {
		if (!isNumber(oredersItem.orderID)) {
			return;
		}

		result.push({
			[fields.channelID]: oredersItem.channelID,
			[fields.orderID]  : oredersItem.orderID,
			[fields.marketID] : marketID,
		});
	});

	return result;
}

export function adaptCMSData(serverData = [], marketID = null) {
	let rawData = cloneDeep(serverData);

	const result = [];

	if (isEmpty(rawData)) {
		rawData = [];
	}
	if (isPlainObject(rawData)) {
		rawData = [rawData];
	}

	channelTypeList.forEach(channelItem => {
		const channelID = channelItem.id;
		const cmsItem =      rawData.find(item => toInteger(item[fields.channelID]) === channelID)
			|| {};

		result.push({
			channelID,
			marketID,
			description1: cmsItem[fields.description1],
			description2: cmsItem[fields.description2],
		});
	});

	return result;
}

export function prepareCMSData(rawData = [], marketID = null) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = rawData.map(item => {
		return {
			[fields.channelID]   : item.channelID,
			[fields.marketID]    : marketID,
			[fields.description1]: item.description1,
			[fields.description2]: item.description2,
		};
	});

	return result;
}

export function adaptDynamiLimits(rawData) {
	let limits = cloneDeep(rawData);
	if (!isArray(limits)) {
		limits = [];
	}

	const adaptedLimits = [];

	const dayDuration  = DURATIONS.minutesInDay;
	const hourDuration = DURATIONS.minutesInHour;

	limits.forEach(item => {
		const newItem = {
			id   : item.id,
			limit: item[fields.limit],
		};
		const minutes = toInteger(item[fields.minutesBefore]);

		const daysCount   = minutes / dayDuration;
		const hoursCount  = minutes / hourDuration;

		let timeTypeID    = DYNAMIC_LIMITS_TIMES.minutes;
		let minutesBefore = minutes;

		// days
		if (daysCount >= 1 && Math.round(daysCount) === daysCount) {
			timeTypeID    = DYNAMIC_LIMITS_TIMES.days;
			minutesBefore = daysCount;

			// hours
		} else if (hoursCount >= 1 && Math.round(hoursCount) === hoursCount) {
			timeTypeID    = DYNAMIC_LIMITS_TIMES.hours;
			minutesBefore = hoursCount;
		}

		newItem.timeTypeID    = timeTypeID;
		newItem.minutesBefore = minutesBefore;

		adaptedLimits.push(newItem);
	});

	return adaptedLimits;
}

export function prepareDynamicLimits(limits, marketID) {
	if (!isArray(limits)) {
		return [];
	}

	const dayDuration  = DURATIONS.minutesInDay;
	const hourDuration = DURATIONS.minutesInHour;

	const preparedLimits = limits.map(item => {
		const newItem = {
			[fields.limit]   : toNumber(item.limit),
			[fields.marketID]: marketID,
		};

		if (isID(item.id)) {
			newItem.id = toInteger(item.id);
		}

		const minutes    = toInteger(item.minutesBefore);
		const timeTypeID = toInteger(item.timeTypeID);

		let minutesBefore = minutes;
		if (timeTypeID === DYNAMIC_LIMITS_TIMES.hours) {
			minutesBefore = minutes * hourDuration;
		} else if (timeTypeID === DYNAMIC_LIMITS_TIMES.days) {
			minutesBefore = minutes * dayDuration;
		}

		newItem[fields.minutesBefore] = minutesBefore;

		return newItem;
	});

	return preparedLimits;
}

export function adaptSettingsData(rawServerData) {
	const adapter = getSettingsDataAdapter();
	return adapter.adapt(rawServerData);
}

export function prepareSettingsData(settings) {
	if (isEmpty(settings)) {
		return [];
	}

	const adapter = getSettingsDataAdapter();
	return adapter.prepare(settings);
}

export function adaptRulesList(rawSelections = [], rawRules = [], marketID = null) {
	if (!isArray(rawSelections)) {
		return [];
	}
	const selectionsAdapter = createSelectionsAdapter();
	const rulesAdapter = createRulesAdapter();

	const selectionsList = rawSelections.map(item => {
		return selectionsAdapter.adapt(item);
	});
	const rulesList = rawRules.map(item => {
		return rulesAdapter.adapt(item);
	});

	const result = [];

	selectionsList.forEach(selection => {
		const selectionID = selection.id;
		const adaptedRuleItem = {
			id      : selectionID,
			name    : selection.name,
			marketID: toInteger(marketID),
			rule    : '',
		};

		const ruleItem = rulesList.find(item => item.id === selectionID) || {};
		adaptedRuleItem.rule = ruleItem.rule || '';

		result.push(adaptedRuleItem);
	});

	return result;
}

export function prepareRulesList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	const adapter = createRulesAdapter();
	adapter.addExcludeField('id');

	const result = rawData.map(item => {
		const newItem = adapter.prepare(item);
		if (isID(item.id)) {
			newItem.id = toInteger(item.id);
		}

		return newItem;
	});

	return result;
}

function createSelectionsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

function createRulesAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'marketID', fields.marketID);

	adapter.addField(rules.string, 'rule', fields.rule);

	return adapter;
}

function getBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'scopeID', fields.scopeID);
	adapter.addField(rules.id, 'statisticTypeID', fields.statisticTypeID);
	adapter.addField(rules.id, 'tradingModeID', fields.tradingModeID);
	adapter.addField(rules.id, 'resultSourceID', fields.resultSourceID);
	adapter.addField(rules.id, 'tradingStatusID', fields.tradingStatusID);
	adapter.addField(rules.id, 'voidOnEventCancel', fields.voidOnEventCancel);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'userWonLimitTypeID', fields.userWonLimitTypeID);
	adapter.addField(rules.id, 'userStakeLimitTypeID', fields.userStakeLimitTypeID);

	adapter.addField(rules.bool, 'multiWinner', fields.multiWinner);
	adapter.addField(rules.bool, 'useProviderOdds', fields.useProviderOdds);
	adapter.addField(rules.bool, 'autoCreate', fields.autoCreate);
	adapter.addField(rules.bool, 'allowMixedMultiplies', fields.allowMixedMultiplies);
	adapter.addField(rules.bool, 'sportDefault', fields.sportDefault);
	adapter.addField(rules.bool, 'suspensionPartly', fields.suspensionPartly);
	adapter.addField(rules.bool, 'userWonLimitOverride', fields.userWonLimitOverride);
	adapter.addField(rules.bool, 'userStakeLimitOverride', fields.userStakeLimitOverride);

	adapter.addField(rules.positiveNumber, 'ladderFrom', fields.ladderFrom);
	adapter.addField(rules.positiveNumber, 'ladderTo', fields.ladderTo);
	adapter.addField(rules.positiveNumber, 'winningCount', fields.winningCount);
	adapter.addField(rules.positiveNumber, 'timeFrom', fields.timeFrom);
	adapter.addField(rules.positiveNumber, 'timeTo', fields.timeTo);
	adapter.addField(rules.positiveNumber, 'combinationMin', fields.combinationMin);
	adapter.addField(rules.positiveNumber, 'combinationMax', fields.combinationMax);
	adapter.addField(rules.positiveNumber, 'suspensionBeforeStart', fields.suspensionBeforeStart);
	adapter.addField(rules.positiveNumber, 'suspensionAfterScopeStart', fields.suspensionAfterScopeStart);
	adapter.addField(rules.positiveNumber, 'suspensionBeforeScopeEnd', fields.suspensionBeforeScopeEnd);
	adapter.addField(rules.positiveNumber, 'suspensionAnyTeamScope', fields.suspensionAnyTeamScope);
	adapter.addField(rules.positiveNumber, 'suspensionBothTeamScope', fields.suspensionBothTeamScope);
	adapter.addField(rules.positiveNumber, 'suspensionMinOdd', fields.suspensionMinOdd);
	adapter.addField(rules.positiveNumber, 'suspensionMaxOdd', fields.suspensionMaxOdd);
	adapter.addField(rules.positiveNumber, 'userWonLimit', fields.userWonLimit);
	adapter.addField(rules.positiveNumber, 'userStakeLimit', fields.userStakeLimit);

	adapter.addField(rules.intOrNull, 'selectionsMin', fields.selectionsMin);
	adapter.addField(rules.intOrNull, 'selectionsMax', fields.selectionsMax);
	adapter.addField(rules.intOrNull, 'selectionsFixed', fields.selectionsFixed);

	adapter.addField(rules.string, 'code', fields.code);

	adapter.addField(rules.arrayNumber, 'alert', fields.alert);
	adapter.addField(rules.arrayString, 'notes', fields.notes);

	adapter.addField(rules.arrayID, 'groupsIDs', fields.groupsIDs);
	adapter.addField(rules.arrayID, 'restrictedMarketsIDs', fields.restrictedMarketsIDs);
	adapter.addField(rules.arrayID, 'displayTypeIDs', fields.displayTypeIDs);
	adapter.addField(rules.arrayID, 'restrictionGroupsIDs', fields.restrictionGroupsIDs);

	// add fields for mappings
	adapter.addField(rules.string, 'providerName',       fields.providerName);
	adapter.addField(rules.string, 'providerMarketName', fields.providerMarketName);
	adapter.addField(rules.string, 'feedMarketName',     fields.feedMarketName);
	adapter.addField(rules.id,     'feedMarketID',       fields.feedMarketID);
	adapter.addField(rules.string, 'feedName',           fields.feedName);
	adapter.addField(rules.id,     'eventID',            fields.eventID);

	return adapter;
}

function getSettingsDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'tradingModeID', fields.tradingModeID);

	adapter.addField(rules.id, 'stakeLimitTypeID', fields.stakeLimitTypeID);
	adapter.addField(rules.positiveNumber, 'stakeLimit', fields.stakeLimit);
	adapter.addField(rules.positiveNumber, 'stakeLimitParent', fields.stakeLimitParent);
	adapter.addField(rules.bool, 'stakeLimitOverride', fields.stakeLimitOverride);

	adapter.addField(rules.id, 'wonLimitTypeID', fields.wonLimitTypeID);
	adapter.addField(rules.positiveNumber, 'wonLimit', fields.wonLimit);
	adapter.addField(rules.positiveNumber, 'wonLimitParent', fields.wonLimitParent);
	adapter.addField(rules.bool, 'wonLimitOverride', fields.wonLimitOverride);

	adapter.addField(rules.positiveNumber, 'betDelay', fields.betDelay);
	adapter.addField(rules.positiveNumber, 'acceptBetsBeforeStart', fields.acceptBetsBeforeStart);

	adapter.addField(rules.bool, 'overask', fields.overask);
	adapter.addField(rules.positiveNumber, 'overaskStakeLimitMin', fields.overaskStakeLimitMin);
	adapter.addField(rules.positiveNumber, 'overaskStakeLimitMax', fields.overaskStakeLimitMax);
	adapter.addField(rules.positiveNumber, 'overaskWonLimitMin', fields.overaskWonLimitMin);
	adapter.addField(rules.positiveNumber, 'overaskWonLimitMax', fields.overaskWonLimitMax);

	return adapter;
}
