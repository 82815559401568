import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router';
import { routes } from './routes/routesData.tsx';
import { getCurrentAppLocale } from './locale';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import { themeConfig } from './config';
import { useSelector } from 'react-redux';
import DashAppHolder from './dashAppStyle';
import themes from './config/themes';


const DashApp = () => {
	const appLocale = getCurrentAppLocale();
	const language = useSelector(state  => state.LanguageSwitcher.get('language'));
	const [currentAppLocale, setCurrentAppLocale] = useState(appLocale);

	useEffect(() => {
		setCurrentAppLocale(appLocale);
	}, [language]);

	return (
		<ConfigProvider locale={currentAppLocale.antd}>
			<IntlProvider
				key={currentAppLocale.locale || 'en'}
				locale={currentAppLocale.locale || 'en'}
				messages={currentAppLocale.messages}
			>
				<ThemeProvider theme={themes[themeConfig.theme]}>
					<DashAppHolder>
						<RouterProvider router={routes} />
					</DashAppHolder>
				</ThemeProvider>
			</IntlProvider>
		</ConfigProvider>
	);
};


export default DashApp;
