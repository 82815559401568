import isArray from 'lodash/isArray';

export function isInSystemSelections(selection, systemSelections) {
	if (!isArray(systemSelections)) return false;

	const systemItem = systemSelections.find(item => {
		return String(item.id) === String(selection.system_selection_id);
	});
	return Boolean(systemItem);
}
