import { makeActionCreator } from '../../../helpers';

const prefix = 'PUSH_NOTIFICATION';

const TYPES = {
	DATA_RESET       : `${prefix}_DATA_RESET`,
	BASE_DATA_REFRESH: `${prefix}_BASE_DATA_REFRESH`,
	SEND_TO_USER     : `${prefix}_SEND_TO_USER`,
	SEND_TO_GROUP    : `${prefix}_SEND_TO_GROUP`,
	UI_REFRESH       : `${prefix}_UI_REFRESH`,
};

const actions = {
	...TYPES,

	dataReset      : makeActionCreator(TYPES.DATA_RESET),
	baseDataRefresh: makeActionCreator(TYPES.BASE_DATA_REFRESH, 'baseData'),
	sendToUser     : makeActionCreator(TYPES.SEND_TO_USER),
	sendToGroup    : makeActionCreator(TYPES.SEND_TO_GROUP),
	uiRefresh      : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
};

export default actions;
