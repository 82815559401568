import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	scriptsList: [],

	UI: {
		loading: false,
	},
});

export default function websiteScriptsReducer(state = initState, action) {

	switch (action.type) {

		case actions.WEBSITE_SCRIPTS_REFRESH: {
			const { scriptsList } = action.data;
			return state.set('scriptsList', scriptsList);
		}

		case actions.WEBSITE_SCRIPTS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}
		case actions.WEBSITE_SCRIPTS_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
