import { all, takeEvery, put, select, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { showError } from '../../../helpers/notifications';
import { dashboardAPI } from '../../../helpers/api/dashboard';
import { adaptPlacedBetsDataReload, getChannelType } from './utils';

const prefix = 'dashboard.placedBets';

const messages = {
	errorPlacedBets: `${prefix}.errorPlacedBets`,
};


function getStoreData({ Dashboard }) {
	return {
		date: Dashboard.Common.get('period'),
	};
}
function* placedBetsReload() {
	yield takeEvery(actions.DASHBOARD_PLACED_BETS_DATA_RELOAD, function* () {
		let dataToStore = [];
		const storeData = yield select(getStoreData);
		const params = {
			start_date: storeData.date.from.split(' ')[0],
			end_date  : storeData.date.to.split(' ')[0],
		};
		try {
			const response = yield call(dashboardAPI.placedBetsReq, params);
			if (response && response.status === 200) {
				dataToStore = adaptPlacedBetsDataReload(response.data.data);
			}
		} catch (error) {
			showError(messages.errorPlacedBets, error);
		}
		getChannelType(dataToStore);
		yield put(actions.placedBetsDataRefresh(dataToStore));
	});
}


export default function* placedBetsSaga() {
	yield all([
		fork(placedBetsReload),
	]);
}
