import { Map } from 'immutable';
import { ROLE_TYPE } from '../../../containers/StaffManagement/modals/Role/utils';
import actions from './actions';
import { fill } from '../../../helpers/utils';

const initState = new Map({
	permissionsData    : [],
	permissionsKeyList : [],
	selectedPermissions: [],
	UI                 : {
		canChange: true,
		loading  : false,
		visible  : false,
		isChanged: false,
		roleType : ROLE_TYPE.platform,
	},
});

const permissionsReducer = (state = initState, { type, data }) => {
	switch (type) {
		case actions.PERMISSIONS_DATA_REFRESH: {
			return state.set('permissionsData', data);
		}

		case actions.PERMISSIONS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(data, target);
			return state.set('UI', result);
		}
		case actions.PERMISSIONS_KEY_REFRESH: {
			return state.set('permissionsKeyList', data);
		}
		case actions.SELECTED_PERMISSIONS_KEY_REFRESH: {
			return state.set('selectedPermissions', data);
		}

		case actions.DATA_RESET: {
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default permissionsReducer;
