import { combineReducers } from 'redux';

import List from './list/reducer';
import Filter from './filter/reducer';
import Result from './result/reducer';
import Statistics from './statistics/reducer';
import ResultHistory from './resultHistory/reducer';

export default combineReducers({
	List,
	Filter,
	Result,
	Statistics,
	ResultHistory,
});
