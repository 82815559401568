import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	innerList: [],
	outerList: [],

	UI: {
		visible           : false,
		loading           : false,
		closeModal        : false,
		sportID           : null,
		participantID     : null,
		participantName   : '',
		selectedProviderID: null,
		isChanged         : false,
	},
});

export default function participantsMappingReducer(state = initState, action) {

	switch (action.type) {

		case actions.INNER_LIST_REFRESH: {
			const { innerList } = action.data;
			return state.set('innerList', innerList);
		}

		case actions.OUTER_LIST_REFRESH: {
			const { outerList } = action.data;
			return state.set('outerList', outerList);
		}

		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}

		case actions.DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
