import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	permissionsList: [],
	rolesList      : [],

	roleUI: {
		roleID     : 0,
		name       : '',
		permissions: [],
		canChange  : true,
		loading    : false,
		visible    : false,
		closeModal : false,
		isChanged  : false,
		editMode   : false,
		logout     : false,
	},
});

export default function rolesReducer(state = initState, action) {
	switch (action.type) {
		case actions.AVAILABLE_PERMISSIONS_LIST_REFRESH: {
			const { permissionsList } = action.data;
			return state.set('permissionsList', permissionsList);
		}

		case actions.AVAILABLE_ROLES_LIST_REFRESH: {
			const { rolesList } = action.data;
			return state.set('rolesList', rolesList);
		}

		case actions.AVAILABLE_ROLES_ROLE_UI_REFRESH: {
			const target = state.get('roleUI');
			const result = fill(action.data, target, true);
			return state.set('roleUI', result);
		}

		case actions.AVAILABLE_ROLES_ROLE_UI_RESET: {
			const roleUI = initState.get('roleUI');
			return state.set('roleUI', roleUI);
		}

		default:
			return state;
	}
}
