const actions = {

	SECURITY_QUESTIONS_LIST_RELOAD : 'SECURITY_QUESTIONS_LIST_RELOAD',
	SECURITY_QUESTIONS_LIST_REFRESH: 'SECURITY_QUESTIONS_LIST_REFRESH',

	SECURITY_QUESTIONS_LIST_SAVE_ITEM  : 'SECURITY_QUESTIONS_LIST_SAVE_ITEM',
	SECURITY_QUESTIONS_LIST_DELETE_ITEM: 'SECURITY_QUESTIONS_LIST_DELETE_PAGE',

	SECURITY_QUESTIONS_LIST_UI_REFRESH: 'SECURITY_QUESTIONS_LIST_UI_REFRESH',
	SECURITY_QUESTIONS_LIST_UI_RESET  : 'SECURITY_QUESTIONS_LIST_UI_RESET',

	listReload: () => ({
		type: actions.SECURITY_QUESTIONS_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.SECURITY_QUESTIONS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	saveQuestion: questionID => ({
		type: actions.SECURITY_QUESTIONS_LIST_SAVE_ITEM,
		data: {
			questionID,
		},
	}),
	deleteQuestion: questionID => ({
		type: actions.SECURITY_QUESTIONS_LIST_DELETE_ITEM,
		data: {
			questionID,
		},
	}),
	uiRefresh: data => ({
		type: actions.SECURITY_QUESTIONS_LIST_UI_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.SECURITY_QUESTIONS_LIST_UI_RESET,
	}),
};

export default actions;
