import { all, put, fork, call, select, takeEvery } from 'redux-saga/effects';
import notifications from '../../../helpers/notifications';
import actions from './actions';
import { dashboardAPI } from '../../../helpers/api/dashboard';
import { createCommonParams } from '../utils';
import { createListParams, adaptWidgetData, adaptTotal } from './utils';

const prefix = 'dashboard.betsByProvider';

const messages = {
	errorLoadData: `${prefix}.errorLoadData`,
	chartNoData  : `${prefix}.chartNoData`,
};

function getStoreData({  Sport: { Categories }, Dashboard, App }) {

	return {
		widgetData  : Dashboard.BetsByProvider.get('widgetData'),
		providerID  : Categories.Category.get('sourceID'),
		partnersList: Dashboard.Common.get('partnersList'),
		period      : Dashboard.Common.get('period'),
		websiteID   : App.get('websiteID'),
	};
}

function* dataReload() {

	yield takeEvery(actions.DASHBOARD_BETS_BY_PROVIDER_DATA_RELOAD, function* () {

		yield put(actions.dataUIRefresh({ loading: true }));
		const { partnersList, period, websiteID, widgetData } = yield select(getStoreData);
		const commonParams = createCommonParams(period, websiteID);
		const listParams = createListParams(partnersList);
		const params = {
			...commonParams,
			...listParams,
		};

		try {
			const res = yield call(dashboardAPI.betsByProviderList, params);
			if (res && res.status === 200) {
				widgetData.data  = adaptWidgetData(res.data.data.data);
				widgetData.total = adaptTotal(res.data.data.total);
			}

		} catch (error) {
			notifications.showError(messages.errorLoadData, error);
		}

		yield put(actions.dataRefresh(widgetData));
		yield put(actions.dataUIRefresh({ loading: false }));
	});
}

export default function* betsByProvidersSaga() {
	yield all([
		fork(dataReload),
	]);
}
