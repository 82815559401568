import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { actions } from './actions';
import { showError, showSuccess } from '../../../../../../helpers/notifications';
import { usersAPI } from '../../../../../../helpers/api/users';
import { adaptGames } from './utils';
import { casinoAPI } from '../../../../../../helpers/api/casino';
import { prepareGamesData } from '../customProvider/utils';
import { LIMITS_CASINO_OPTIONS_TYPES } from '../../../../../../constants/users';
import {
	restoreWebsite,
} from '../../../../../../helpers/utility';
import { logger } from '../../../../../../helpers/logger';

const prefix = 'users.limits.searchResults';

const messages = {
	listReload    : `${prefix}.listReload`,
	dataSaveFailed: `${prefix}.dataSaveFailed`,
	dataSaved     : `${prefix}.dataSaved`,
};


const websiteID = restoreWebsite();

const getStore = ({ Users }) => {
	const { associativeList, list } = Users.UserInfo.UserLimitsAndRestrictions.Casino.GamesSearch.get('baseData');
	const { searchValue }           = Users.UserInfo.UserLimitsAndRestrictions.Casino.RestrictOptionSearch.get('searchToolbar');
	return {
		associativeList,
		list,
		searchValue,
	};
};

function* gamesSearchReload() {
	yield takeEvery(actions.RESTRICT_BY_GAMES_RELOAD, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));

		const { searchValue } = yield select(getStore);

		const params = {
			name   : searchValue,
			unlimit: true,
		};

		try {
			const res = yield all([
				call(casinoAPI.gamesList, websiteID, params),
				call(usersAPI.userLimitsCasinoSelect, data, params),
			]);

			const successStatus = res.every(item => item && item.status === 200);

			const [gamesResponse, gamesLimitsResponse] = res;
			if (successStatus) {
				const {
					list,
					associativeList,
				} = adaptGames(gamesResponse.data.data, gamesLimitsResponse.data.data, data);

				yield put(actions.gamesListRefresh({ list, associativeList }));
			}
			yield put(actions.uiRefresh({ loading: false }));

		} catch (e) {
			showError(messages.listReload, e);
			yield put(actions.uiRefresh({ loading: false }));
			showError(messages.listReload);
			logger.log(e);
		}
	});
}


function* dataSave() {
	yield takeEvery(actions.RESTRICT_BY_GAMES_SAVE, function* ({ data }) {
		const typeID                                 = LIMITS_CASINO_OPTIONS_TYPES.game;
		data.typeID                                  = typeID;
		const { gameID, userID }                     = data;
		const { associativeList, list } = yield select(getStore);
		const row                                    = associativeList[gameID];

		const clonedData = cloneDeep(list);
		clonedData.forEach(item => {
			if (item.gameID === gameID) {
				item.loading = true;
			}
		});

		yield put(actions.gamesListRefresh({ list: clonedData }));
		const preparedData = prepareGamesData(row);


		try {

			const res = yield call(usersAPI.userLimitsCasinoUpdate, preparedData, { userID, type: typeID });

			if (res && res.status === 200) {
				showSuccess(messages.dataSaved);
			}

			yield put(actions.uiRefresh({ loading: false }));

		} catch (e) {
			showError(messages.dataSaveFailed);
			yield put(actions.uiRefresh({ loading: false }));
			logger.log(e);
		}

		yield put(actions.dataReload(data));

	});
}


export default function* restrictByGamesSearchSaga() {
	yield all([
		fork(gamesSearchReload),
		fork(dataSave),
	]);
}
