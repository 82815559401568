const actions = {

	BANNER_GROUPS_LIST_RELOAD : 'BANNER_GROUPS_LIST_RELOAD',
	BANNER_GROUPS_LIST_REFRESH: 'BANNER_GROUPS_LIST_REFRESH',

	BANNER_GROUPS_LIST_DELETE_ITEM: 'BANNER_GROUPS_LIST_DELETE_ITEM',
	BANNER_GROUPS_UI_REFRESH   			: 'BANNER_GROUPS_UI_REFRESH',
	listReload                    : () => ({
		type: actions.BANNER_GROUPS_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.BANNER_GROUPS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	uiRefresh: data => ({
		type: actions.BANNER_GROUPS_UI_REFRESH,
		data,
	}),
	deleteItem: groupID => ({
		type: actions.BANNER_GROUPS_LIST_DELETE_ITEM,
		data: {
			groupID,
		},
	}),
};

export default actions;
