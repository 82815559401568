const actions = {

	INFO_PAGES_LIST_RELOAD	: 'INFO_PAGES_LIST_RELOAD',
	INFO_PAGES_LIST_REFRESH: 'INFO_PAGES_LIST_REFRESH',

	INFO_PAGES_LIST_DELETE_PAGE: 'INFO_PAGES_LIST_DELETE_PAGE',

	INFO_PAGES_LIST_CHANGE_WEBSITE: 'INFO_PAGES_LIST_CHANGE_WEBSITE',
	INFO_PAGES_LIST_UI_REFRESH 			: 'INFO_PAGES_LIST_UI_REFRESH',

	listReload: () => ({
		type: actions.INFO_PAGES_LIST_RELOAD,
	}),
	listRefresh: (listIDs, entities) => ({
		type: actions.INFO_PAGES_LIST_REFRESH,
		data: {
			listIDs,
			entities,
		},
	}),
	deletePage: pageID => ({
		type: actions.INFO_PAGES_LIST_DELETE_PAGE,
		data: {
			pageID,
		},
	}),
	changeWebsite: websiteID => ({
		type: actions.INFO_PAGES_LIST_CHANGE_WEBSITE,
		data: {
			websiteID,
		},
	}),
	uiRefresh: data => ({
		type: actions.INFO_PAGES_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
