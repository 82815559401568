import PropTypes from 'prop-types';

export const defaultPropsIcons = {
	color  : '#ffffffa6',
	width  : '16',
	height : '16',
	viewBox: '0 0 16 16',
};

export const propTypesIcons = {
	color  : PropTypes.string,
	width  : PropTypes.string,
	height : PropTypes.string,
	viewBox: PropTypes.string,
};
