import { all, fork } from 'redux-saga/effects';

import questionsSaga from './questions/saga';
import whitelistSaga from './whitelist/saga';

export default function* websitesSaga() {
	yield all([
		fork(questionsSaga),
		fork(whitelistSaga),
	]);
}
