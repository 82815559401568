const prefix = 'USER_SEND_SMS';

const actions = {

	USER_SEND_SMS_LIST_RELOAD : `${prefix}_LIST_RELOAD`,
	SAVE_MESSAGE              : `${prefix}_SAVE_MESSAGE`,
	USER_SEND_SMS_LIST_REFRESH: `${prefix}_LIST_REFRESH`,
	USER_SEND_SMS_LIST_UPDATE : `${prefix}_LIST_UPDATE`,

	USER_SEND_SMS_UI_REFRESH    : `${prefix}_UI_REFRESH`,
	USER_SEND_SMS_FILTER_REFRESH: `${prefix}_FILTER_REFRESH`,

	USER_SEND_SMS_NEW_REFRESH     : `${prefix}_NEW_REFRESH`,
	USER_SEND_SMS_TEMPLATE_REFRESH: `${prefix}_TEMPLATE_REFRESH`,
	BASE_DATA_RESET               : `${prefix}BASE_DATA_RESET`,

	messageListReload: (data) => ({
		type: actions.USER_SEND_SMS_LIST_RELOAD,
		data,
	}),

	saveUserMessage: (modalVisible) => ({
		type: actions.SAVE_MESSAGE,
		data: {
			modalVisible,
		},
	}),

	messageListRefresh: data => ({
		type: actions.USER_SEND_SMS_LIST_REFRESH,
		data,
	}),

	messageListUpdate: data => ({
		type: actions.USER_SEND_SMS_LIST_UPDATE,
		data,
	}),

	uiRefresh: data => ({
		type: actions.USER_SEND_SMS_UI_REFRESH,
		data,
	}),

	filterRefresh: data => ({
		type: actions.USER_SEND_SMS_FILTER_REFRESH,
		data,
	}),

	newMessageRefresh: data => ({
		type: actions.USER_SEND_SMS_NEW_REFRESH,
		data,
	}),

	newTemplateRefresh: data => ({
		type: actions.USER_SEND_SMS_TEMPLATE_REFRESH,
		data,
	}),

	baseDataReset: data => ({
		type: actions.BASE_DATA_RESET,
		data,
	}),

};

export default actions;
