import { call, fork, takeEvery, all, put, select } from 'redux-saga/effects';
import { ROLE_TYPE } from '../../../containers/StaffManagement/modals/Role/utils';
import actions from './actions';
import { permissionsAPI } from '../../../helpers/api/permissions';
import { showError } from '../../../helpers/notifications';
import { generateObjectFromPermissions } from './utils';
import { logger } from '../../../helpers/logger';

const prefix = 'staff.permissions';

const messages = {
	errorLoadPermissions: `${prefix}.errorLoadPermissions`,
};
const getStoreData = state => {
	const { Staff } = state;
	const { permissions } = Staff.Roles.get('roleUI');
	return {
		permissions,
	};
};

function* getPermissionsData() {
	yield takeEvery(actions.PERMISSIONS_DATA_RELOAD, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));
		const { roleType } = data;
		const { permissions } = yield select(getStoreData);
		const params = {
			partner: roleType === ROLE_TYPE.partner,
		};
		try {
			const res = yield call(permissionsAPI.permissions, params);
			if (res && res.status === 200) {
				const tree = generateObjectFromPermissions(res.data.data, permissions || []);
				yield put(actions.permissionsKeysRefresh(res.data.data));
				yield put(actions.dataRefresh(tree));
				yield put(actions.uiRefresh({ loading: false }));
			}
		} catch (e) {
			logger.log('error :', e);
			showError(messages.errorLoadPermissions);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}


export default function* () {
	yield all([fork(getPermissionsData)]);
}
