import React from 'react';
import PropTypes from 'prop-types';

import UserLink from '../../../../containers/AppTabsManager/components/UserLink';
import Formatter from '../../../../helpers/formatter';
import {
	ListItem,
	ListItemHeader,
	ListItemDate,
	ListItemBody,
} from '../NotificationBadge.style';

const ItemWithdrawal = ({ id, itemIdByType, date, amount, currencyCode, onClick, userID, userName, className }) => {

	const name = `ID: ${id}, amount: ${Formatter.sum(amount)} ${currencyCode}`;
	const info = `Customer: ${userName}`;

	const onWithdrawClick = () => {
		onClick(userID, id, null, null, itemIdByType);
	};
	return (
		<UserLink userID={userID} type="normal">
			<ListItem
				onClick={onWithdrawClick}
				className={className}
			>
				<ListItemHeader>
					{name}
					<ListItemDate>{Formatter.dateTime(date)}</ListItemDate>
				</ListItemHeader>
				<ListItemBody>{info}</ListItemBody>
			</ListItem>
		</UserLink>
	);
};

ItemWithdrawal.propTypes = {
	id          : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	date        : PropTypes.string,
	amount      : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	currencyCode: PropTypes.string,
	userID      : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	itemIdByType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	userName    : PropTypes.string,
	className   : PropTypes.string,
	onClick     : PropTypes.func,
};

ItemWithdrawal.defaultProps = {
	id          : '',
	date        : '',
	amount      : '',
	currencyCode: '',
	userID      : '',
	userName    : '',
	className   : '',
	onClick     : () => {},
};

export default ItemWithdrawal;
