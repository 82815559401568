import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import toInteger from 'lodash/toInteger';
import { isID } from '../helpers/utils';

// Filter
const usersFilter         = (state) => state.Users.Filter.get('filter');
const dataSourceUserName  = (state) => state.Users.Filter.get('dataSourceUserName');
const dataSourceUserEmail = (state) => state.Users.Filter.get('dataSourceUserEmail');
const dataSourceFirstName = (state) => state.Users.Filter.get('dataSourceFirstName');
const dataSourceLastName  = (state) => state.Users.Filter.get('dataSourceLastName');
const riskGroupsList      = (state) => state.Users.Filter.get('riskGroupsList');

// User Data
const baseData     = (state) => state.Users.User.get('baseData');
const adminData    = (state) => state.Users.User.get('adminData');
const userUI       = (state) => state.Users.User.get('UI');
const sportLimitUI = (state) => state.Users.UserInfo.UserLimitsAndRestrictions.Sport.get('UI');
const currencyList = (state) => state.Users.User.get('currencyList');
const usersList    = (state) => state.Users.List.get('list');
const usersLimitsCasino = (state) => state.Users.UserInfo.UserLimitsAndRestrictions.Casino;
const customProvider = (state) => deriveUsersLimitsCasino(state).CustomProvider;
const customProviderUI = (state) => deriveUsersLimitsCasino(state).CustomProvider.get('UI');
const usersLimitsCasinoGame = (state) => deriveUsersLimitsCasino(state).Game;
const usersRestrictOptionSearch = (state) => deriveUsersLimitsCasino(state).RestrictOptionSearch;
const intProvGameLimits = (state) => deriveUsersCasinoGame(state).get('intProvGamLimits');
const customProviderList = (state) => deriveCustomProvider(state).get('customProviderList');
const customProviderBaseData = (state) => deriveCustomProvider(state).get('baseData');

// User details
const documentsEntities = (state, props) => {
	const entities = state.Users.Documents.get('entities');
	if (!isID(props.userID)) {
		return entities;
	}
	return values(entities)
		.filter(doc => doc.userID === props.userID)
		.reduce((res, doc) => {
			res[doc.docID] = doc;
			return res;
		}, {});
};
const adjustmentsList            = (state) => state.Users.BalanceAdjustments.get('list');
const rolesList                  = (state) => state.Staff.Roles.get('rolesList');
const partnerEntities            = (state) => state.App.get('partnerEntity');
const documentUI                 = (state) => state.Users.Documents.get('UI');
const responsibleGamblingLogList = (state) => state.Users.RresponsibleGambling.get('historyLogs');

// Documnet ui -----------------------------------------------------------------------------------------
export const deriveDocumentUI = createSelector(
	[documentUI],
	(documentUI) => documentUI
);

// Filter -----------------------------------------------------------------------------------------
export const deriveUserFilter = createSelector(
	[usersFilter],
	(filter) => filter
);

export const deriveSourceUserName = createSelector(
	[dataSourceUserName],
	(dataSource) => dataSource
);

export const deriveSourceUserEmail = createSelector(
	[dataSourceUserEmail],
	(dataSource) => dataSource
);

export const deriveSourceFirstName = createSelector(
	[dataSourceFirstName],
	(dataSource) => dataSource
);

export const deriveSourceLastName = createSelector(
	[dataSourceLastName],
	(dataSource) => dataSource
);

export const deriveRiskGroupsList = createSelector(
	[riskGroupsList],
	(riskGroupsList) => riskGroupsList
);

// User Data --------------------------------------------------------------------------------------
export const deriveBaseData = createSelector(
	[baseData],
	(baseData) => baseData
);

export const deriveAdminData = createSelector(
	[adminData],
	(adminData) => adminData
);

export const deriveUserUI = createSelector(
	[userUI],
	(userUI) => userUI
);

export const deriveSportLimitUI = createSelector(
	[sportLimitUI],
	(UI) => UI
);

export const deriveUserCurrencyList = createSelector(
	[currencyList],
	(currencyList) => currencyList
);

export const deriveUsersList = createSelector(
	[usersList],
	(userList) => userList
);

// Documents --------------------------------------------------------------------------------------
export const deriveDocumentsEntities = createSelector(
	[documentsEntities],
	(documentsEntities) => documentsEntities
);


export const deriveDocumentsList = createSelector(
	[documentsEntities],
	(documentsEntities) => orderBy( values(documentsEntities), ['statusID', 'created', 'typeID'], ['asc', 'desc', 'asc'])
);

// Responsible Gambling ---------------------------------------------------------------------------
export const  deriveResponsibleGamblingLogList = createSelector(
	[responsibleGamblingLogList],
	(responsibleGamblingLogList) => responsibleGamblingLogList
);

// Roles ------------------------------------------------------------------------------------------
export const deriveRolesList = createSelector(
	[rolesList],
	(rolesList) => rolesList
);

// Balance Adjustments ----------------------------------------------------------------------------
export const deriveAdjustmentsList = createSelector(
	[adjustmentsList],
	(adjustmentsList) => adjustmentsList
);

export const deriveRolesEntities = createSelector(
	[deriveRolesList],
	(rolesList) => rolesList.reduce((res, item) => {
		const id = toInteger(item.id);
		res[id] = item;
		return res;
	}, {})
);

export const derivePartnerEntities = createSelector(
	[partnerEntities],
	(partnerEntities) => partnerEntities
);


export const deriveWebsiteEntities = createSelector(
	[partnerEntities],
	(partnerEntities) => values(partnerEntities).reduce((acc, partner) => {
		partner.websites.forEach(website => {
			acc[website.id] = website;
		});
		return acc;
	}, {})
);

export const deriveUsersLimitsCasino = createSelector(
	[usersLimitsCasino],
	(usersLimitsCasino) => usersLimitsCasino
);

export const deriveCustomProvider = createSelector(
	[customProvider],
	(customProvider) => customProvider
);

export const deriveCustomProviderUI = createSelector(
	[customProviderUI],
	(customProviderUI) => customProviderUI
);

export const deriveCustomProviderList = createSelector(
	[customProviderList],
	(customProviderList) => customProviderList
);

export const deriveCustomProviderBaseData = createSelector(
	[customProviderBaseData],
	(customProviderBaseData) => customProviderBaseData
);

export const deriveUsersCasinoGame = createSelector(
	[usersLimitsCasinoGame],
	(usersCasinoGame) => usersCasinoGame
);

export const deriveIntProvGameLimits = createSelector(
	[intProvGameLimits],
	(intProvGamLimits) => intProvGamLimits
);

export const deriveIntProvGameLimitsList = createSelector(
	[deriveIntProvGameLimits],
	(entities) => orderBy( values(entities), ['id'], ['desc'] )
);

export const deriveUsersRestrictOptionSearch = createSelector(
	[usersRestrictOptionSearch],
	(usersRestrictOptionSearch) => usersRestrictOptionSearch
);
