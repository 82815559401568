import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import filterSaga from './filter/saga';
import filterRoleSaga from './filterRoles/saga';
import personSaga from './person/saga';
import rolesSaga from './roles/saga';

export default function* usersRootSaga() {
	yield all([
		fork(listSaga),
		fork(filterSaga),
		fork(filterRoleSaga),
		fork(personSaga),
		fork(rolesSaga),
	]);
}
