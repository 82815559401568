const actions = {

	PROFILE_BASE_DATA_RELOAD    : 'PROFILE_BASE_DATA_RELOAD',
	PROFILE_BASE_DATA_REFRESH   : 'PROFILE_BASE_DATA_REFRESH',
	PROFILE_DATA_SAVE           : 'PROFILE_DATA_SAVE',
	PROFILE_UI_REFRESH          : 'PROFILE_UI_REFRESH',
	PROFILE_DATA_RESET          : 'PROFILE_DATA_RESET',
	PROFILE_PASSWORD_CHANGE     : 'PROFILE_PASSWORD_CHANGE',
	PROFILE_CURRENCY_LIST_RELOAD: 'PROFILE_CURRENCY_LIST_RELOAD',

	baseDataReload: userID => ({
		type: actions.PROFILE_BASE_DATA_RELOAD,
		data: {
			userID,
		},
	}),

	baseDataRefresh: baseData => ({
		type: actions.PROFILE_BASE_DATA_REFRESH,
		data: {
			baseData,
		},
	}),

	dataSave: () => ({
		type: actions.PROFILE_DATA_SAVE,
	}),

	passwordChange: () => ({
		type: actions.PROFILE_PASSWORD_CHANGE,
	}),

	profileCurrencyListReload: data => ({
		type: actions.PROFILE_CURRENCY_LIST_RELOAD,
		data,
	}),

	uiRefresh: uiData => ({
		type: actions.PROFILE_UI_REFRESH,
		data: {
			uiData,
		},
	}),

	dataReset: () => ({
		type: actions.PROFILE_DATA_RESET,
	}),
};

export default actions;
