import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import casinoSaga from './casino/saga';
import customProvidersSaga from './customProviders/saga';
import customProviderSaga from './customProvider/saga';
import gamesSaga from './games/saga';
import gameSaga from './game/saga';
import gameCategoriesSaga from './gameCategories/saga';
import gameCategorySaga from './gameCategory/saga';
import providersSaga from './providers/saga';
import tagsSaga from './tags/saga';
import integratorsSaga from './integrators/saga';
import casinoGamesBulkSaga from './gamesBulk/saga';

export default function* casinosSaga() {
	yield all([
		fork(listSaga),
		fork(casinoSaga),
		fork(customProvidersSaga),
		fork(customProviderSaga),
		fork(gamesSaga),
		fork(gameSaga),
		fork(gameCategoriesSaga),
		fork(gameCategorySaga),
		fork(providersSaga),
		fork(tagsSaga),
		fork(integratorsSaga),
		fork(casinoGamesBulkSaga),
	]);
}
