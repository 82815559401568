import { apiRequest } from './index';

// Customizable Tables ----------------------------------------------------------------------------

function customizableTablesSettingsLoad(userID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/profile/adminRtmSettings/${userID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function customizableTablesSettingsSave(userID, settings, params = {}) {

	const req = {
		method: 'POST',
		url   : `/profile/adminRtmSettings/${userID}`,
		data  : settings,
		params,
	};

	return apiRequest(req);
}

// Menu Menus ----------------------------------------------------------------------------------

function contentMenuLoad(menuTypeID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/menu/${menuTypeID}`,
		params,
	};

	return apiRequest(req);
}

function contentMenuColumnInfo(columnID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/menuColumn/${columnID}`,
		params,
	};

	return apiRequest(req);
}

function contentMenuColumnAdd(columnData, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/menuColumn/',
		data  : columnData,
		params,
	};

	return apiRequest(req);
}

function contentMenuColumnUpdate(columnID, columnData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/menuColumn/${columnID}`,
		data  : columnData,
		params,
	};

	return apiRequest(req);
}

function contentMenuColumnsOrderUpdate(columnsList, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/menuColumns/',
		data  : columnsList,
		params,
	};

	return apiRequest(req);
}

function contentMenuItemInfo(itemID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/menuColumnItem/${itemID}`,
		params,
	};

	return apiRequest(req);
}

function contentMenuItemAdd(itemData, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/menuColumnItem/',
		data  : itemData,
		params,
	};

	return apiRequest(req);
}

function contentMenuItemUpdate(itemID, itemData, params = {}) {

	const req = {
		method       : 'POST',
		url          : `/content/menuColumnItem/${itemID}`,
		withoutLangID: true,
		data         : itemData,
		params,
	};

	return apiRequest(req);
}

function contentMenuItemsOrderUpdate(itemsList, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/menuColumnItems/',
		data  : itemsList,
		params,
	};

	return apiRequest(req);
}

function contentMenuRevisionsList(menuTypeID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/menu/${menuTypeID}/revisions`,
		params,
	};

	return apiRequest(req);
}

function contentMenuRevisionAdd(menuTypeID, contentMenu, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/menu/${menuTypeID}/revisions`,
		data  : contentMenu,
		params,
	};

	return apiRequest(req);
}

function contentMenuRevisionApply(menuTypeID, revisionID) {

	const req = {
		method: 'POST',
		url   : `/content/menu/${menuTypeID}/revisions/${revisionID}`,
	};

	return apiRequest(req);
}

//-------------------------------------------------------------------------------

function gamesDefaultGridList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/casinoGame/orders',
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
}

function gamesDefaultGridUpdate(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/casinoGame/orders',
		data,
		params,
	};

	return apiRequest(req);
}

export const contentAPI = {
	customizableTablesSettingsLoad,
	customizableTablesSettingsSave,

	contentMenuLoad,
	contentMenuColumnInfo,
	contentMenuColumnAdd,
	contentMenuColumnUpdate,
	contentMenuColumnsOrderUpdate,

	contentMenuItemInfo,
	contentMenuItemAdd,
	contentMenuItemUpdate,
	contentMenuItemsOrderUpdate,

	contentMenuRevisionsList,
	contentMenuRevisionAdd,
	contentMenuRevisionApply,

	gamesDefaultGridList,
	gamesDefaultGridUpdate,
};
