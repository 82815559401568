import { apiRequest } from './index';

export const fetchMatchesApi = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/content/matchOfTheDay',
		params,
	};

	return apiRequest(req);
};

export const addMatchApi = (match, params = {}) => {
	const req = {
		method: 'POST',
		url   : '/content/matchOfTheDay',
		data  : match,
		params,
	};

	return apiRequest(req);
};

export const updateMatchApi = (id, match, params = {}) => {
	const req = {
		method: 'POST',
		url   : `/content/matchOfTheDay/${id}`,
		data  : match,
		params,
	};

	return apiRequest(req);
};

export const removeMatchApi = (id, params = {}) => {
	const req = {
		method: 'DELETE',
		url   : `/content/matchOfTheDay/${id}`,
		params,
	};

	return apiRequest(req);
};
