export const WEBSITE_INTEGRATION_TABS = {
	mailingService: 0,
	cdn           : 1,
	crms          : 2,
	affiliates    : 3,
};

export const WEBSITE_INTEGRATION_NAMES = {
	[WEBSITE_INTEGRATION_TABS.mailingService]: 'website_mailingService',
	[WEBSITE_INTEGRATION_TABS.cdn]           : 'website_cdn',
	[WEBSITE_INTEGRATION_TABS.crms]          : 'website_crms',
	[WEBSITE_INTEGRATION_TABS.affiliates]    : 'website_affiliates',
};

export const websiteDomainTypes = {
	main     : 0,
	redirect : 1,
	different: 2,
};
