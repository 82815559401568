import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

	.label {
		padding-right: 16px;
	}

	.control {
      width: 120px;
      position: relative;
	}
  
  .ant-select-search__field {
      caret-color: transparent;
    }
`;
