const actions = {

	CATEGORY_HISTORY_MODEL_DATA_RELOAD : 'CATEGORY_HISTORY_MODEL_DATA_RELOAD',
	CATEGORY_HISTORY_MODEL_DATA_REFRESH: 'CATEGORY_HISTORY_MODEL_DATA_REFRESH',
	CATEGORY_HISTORY_UI_REFRESH        : 'CATEGORY_HISTORY_UI_REFRESH',

	dataReload: (categoryID, logModel) => ({
		type: actions.CATEGORY_HISTORY_MODEL_DATA_RELOAD,
		data: {
			categoryID,
			logModel,
		},
	}),
	dataRefresh: (logData, logModel) => ({
		type: actions.CATEGORY_HISTORY_MODEL_DATA_REFRESH,
		data: {
			logData,
			logModel,
		},
	}),
	uiRefresh: data => ({
		type: actions.CATEGORY_HISTORY_UI_REFRESH,
		data,
	}),
};

export default actions;
