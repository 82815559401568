import { call, put, select, takeEvery, fork, all } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import notifications, { showError } from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';
import { RootState } from '../../../../store';
import { usersAPI } from '../../../../../helpers/api/users';
import { deriveTablePagination } from '../../../../../selectors/tables';
import { TABLE_TYPES } from '../../../../../constants/tableTypes';
import { IActionsOpenModal, IActionsReportDataReload } from './types';
import { userAllTransactionsActions } from './actions';
import { actions } from './slice';
import { adaptUserAllTransactionList, availableFields, createReportParams, getListParams } from './utils';
import { downloadReport, getHeadersTotalCount } from '../../../../../helpers/utils';
import tableActions from '../../../../tables/actions';
import { transactionsAPI } from '../../../../../helpers/api/transactions';

const tableType = TABLE_TYPES.userAllTransactions;

function getStoreData(state:RootState) {
	const { Tables, AppTabs, Users } = state;
	const { User, UserInfo } = Users;
	const { id: userID } = User.get('baseData');
	return {
		userID,
		filter      : UserInfo.UserAllTransactions.userAllTransaction.userAllTransactionsFilter,
		entities    : UserInfo.UserAllTransactions.userAllTransaction.userAllTransactionsEntities,
		activeTabID : AppTabs.get('activeTabID'),
		sorting     : Tables.get(tableType).sorting,
		reportFields: UserInfo.UserAllTransactions.userAllTransaction.reportFields,
		//eslint-disable-next-line
		// @ts-ignore
		pagination  : deriveTablePagination( tableType, state ),
	};
}

const messages = {
	errorListReload: '',
	errorDataLoad  : '',
};

function* listReload() {

	yield takeEvery(userAllTransactionsActions.USER_ALL_TRANSACTION_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { userID, filter, sorting, pagination } = yield select(getStoreData);
		const filterCopy =  cloneDeep(filter);
		const params = getListParams(filterCopy, sorting, pagination);
		let totalCount = 0;
		try {
			const response = yield call(usersAPI.getAllTransactionsList, params, userID);
			if (response && response.status === 200) {
				const { list, entities } = adaptUserAllTransactionList(response.data.data);
				yield put(actions.setUserAllTransactionsData(list));
				yield put(actions.setUserAllTransactionsEntities(entities));
				totalCount = getHeadersTotalCount(response.headers);
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));

		} catch (error) {
			notifications.showError(messages.errorListReload);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* openModal() {
	yield takeEvery(userAllTransactionsActions.OPEN_MODAL, function*({ id }:IActionsOpenModal) {
		yield put(actions.uiRefresh({ visibleModal: true }));
		const { entities } = yield select(getStoreData);
		const modalData = entities[id] && JSON.parse(entities[id].data.data);

		yield put(actions.setUserAllTransactionsModalData(modalData));
	});
}


function* reportDataReload() {

	yield takeEvery(userAllTransactionsActions.USER_ALL_TRANSACTIONS_REPORT_DATA_RELOAD, function* ({ userID }: IActionsReportDataReload) {

		yield put(actions.uiRefresh({ reportLoading: true }));
		// yield put(actions.dataRefresh(''));

		const { filter, sorting, reportFields } = yield select(getStoreData);
		const params = createReportParams(filter, sorting, reportFields, availableFields);
		params.user_id = userID;
		try {
			const res = yield call(transactionsAPI.reportsListByCSV, params);
			if (res) {
				downloadReport(res);
			}

		} catch (error) {
			showError(messages.errorDataLoad);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ reportLoading: false }));
	});
}

export function* allTransactions() {
	yield all([
		fork(listReload),
		fork(openModal),
		fork(reportDataReload),
	]);
}