import EntityAdapter from '../../../../helpers/entityAdapter';
import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';
import { isID } from '../../../../helpers/utils';

const { isValidID, isValidString } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	sportID: isValidID,
	name   : isValidString,
};

export const defaultValuesDisplayTypes = {
	sportID: 150592,
	name   : '',
};

const fields = {
	id           : 'id',
	name         : 'name',
	langID       : 'lang_id',
	orderID      : 'order_id',
	sportID      : 'sport_id',
	displayTypeID: 'display_type_id',
	names        : 'names',
};

const displayTypesAdapter = createDisplayTypeAdapter();
const displayTypesNamesAdapter = createDisplayTypeNamesAdapter();
// Adapting ---------------------------------------------------------------------------------------
export function adaptList(rawData = []) {
	displayTypesAdapter.clearExcludes();
	const adaptedData = displayTypesAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.names = displayTypesNamesAdapter.adaptList(item.names);
	});
	return adaptedData;
}

// Preparing --------------------------------------------------------------------------------------
export function prepareType(rawData = {}, sportID = null) {
	displayTypesAdapter.clearExcludes();
	displayTypesAdapter.addExcludeField('id');
	displayTypesAdapter.addExcludeField('langID');
	displayTypesAdapter.addExcludeField('sportID');
	displayTypesAdapter.addExcludeField('name');
	displayTypesNamesAdapter.addExcludeField('id');
	displayTypesNamesAdapter.addExcludeField('displayTypeID');
	const preparedData = displayTypesAdapter.prepare(rawData);
	preparedData.names = displayTypesNamesAdapter.prepareList(preparedData.names);
	if (isID(rawData.id)) {
		preparedData.id = rawData.id;
	}
	if (isID(rawData.sportID)) {
		preparedData[fields.sportID] = rawData.sportID;
	}
	if (!preparedData[fields.sportID]) {
		preparedData[fields.sportID] = sportID;
	}

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
function createDisplayTypeAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.arrayObject, 'names', fields.names);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

function createDisplayTypeNamesAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'displayTypeID', fields.displayTypeID);
	adapter.addField(rules.number, 'langID', fields.langID);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}
