import { all, fork } from 'redux-saga/effects';

import bettingRulesSaga from './bettingRules/saga';
import laddersSaga from './ladders/saga';


export default function* rootSaga() {
	yield all([
		fork(bettingRulesSaga),
		fork(laddersSaga),
	]);
}
