import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { user_circle as userCircle } from 'react-icons-kit/ikons/user_circle';

import Formatter from '../../../../helpers/formatter';
import {
	ListItem,
	ListItemHeader,
	ListItemDate,
	ListItemBody,
	AvatarWrap,
	Avatar,
} from '../NotificationBadge.style';

const ItemMessages = ({ id, date, typeID, userName, body, avatar, onClick, className }) => {

	const matches  = userName.match(/\b(\w)/g);
	const splitLet = matches ? matches.join('').split('', 2) : null;

	let image = (<Icon icon={userCircle} size={35} />);
	if (avatar) {
		image = (<img src={avatar} alt="avatar" />);
	} else if (splitLet) {
		image = (<span>{splitLet}</span>);
	}

	return (
		<ListItem className={`clickable ${className}`} onClick={() => onClick(typeID, id)}>
			<AvatarWrap>
				<Avatar>{image}</Avatar>
				<div>
					<ListItemHeader>
						{userName}
						<ListItemDate>{Formatter.dateTime(date)}</ListItemDate>
					</ListItemHeader>
					<ListItemBody dangerouslySetInnerHTML={{ __html: body }} />
				</div>
			</AvatarWrap>
		</ListItem>
	);
};

ItemMessages.propTypes = {
	id       : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	date     : PropTypes.string,
	userName : PropTypes.string,
	avatar   : PropTypes.string,
	body     : PropTypes.string,
	className: PropTypes.string,
	onClick  : PropTypes.func,
	typeID   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ItemMessages.defaultProps = {
	id       : '',
	typeID   : '',
	date     : '',
	userName : '',
	avatar   : '',
	body     : '',
	className: '',
	onClick  : () => {},
};

export default ItemMessages;
