import EntityAdapter from '../../../../../helpers/entityAdapter';
import { isID } from '../../../../../helpers/utils';

const fields = {
	id            : 'id',
	eventID       : 'event_id',
	eventMarketID : 'event_market_id',
	eventStartTime: 'event_start_time',
	eventName     : 'event_name',
	sportName     : 'sport_name',
	leagueName    : 'league_name',
	countryName   : 'country_name',
	imgBG         : 'img_bg',
	imgMD         : 'img_md',
	imgSM         : 'img_sm',
};

const matchAdapter = createMatchAdapter();

// Adapting ---------------------------------------------------------------------------------------
export function adaptMatchesList(rawList = []) {
	matchAdapter.clearExcludes();

	const adaptedList = matchAdapter.adaptList(rawList);
	adaptedList.forEach(item => {
		item.category = makeCategoryName(item);
	});

	return adaptedList;
}

export function adaptMatch(rawObject = {}) {
	matchAdapter.clearExcludes();

	const adaptedItem    = matchAdapter.adapt(rawObject);
	adaptedItem.category = makeCategoryName(adaptedItem);

	return adaptedItem;
}

// Preparing --------------------------------------------------------------------------------------
export function prepareMatch(rawData = {}, onlyIDs = false, websiteID) {

	if (onlyIDs) {
		return {
			event_id       : rawData.eventID,
			event_market_id: rawData.eventMarketID,
			img_bg         : rawData.imgBG,
			img_md         : rawData.imgMD,
			img_sm         : rawData.imgSM,
			// website_id      : websiteID,
		};
	}

	matchAdapter.clearExcludes();
	matchAdapter.addExcludeField('id');

	const preparedData = matchAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = rawData.id;
	}
	preparedData.website_id = +websiteID;
	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
function createMatchAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'eventID', fields.eventID);
	adapter.addField(rules.id, 'eventMarketID', fields.eventMarketID);
	adapter.addField(rules.dateTime, 'eventStartTime', fields.eventStartTime);
	adapter.addField(rules.string, 'eventName', fields.eventName);
	adapter.addField(rules.string, 'sportName', fields.sportName);
	adapter.addField(rules.string, 'countryName', fields.countryName);
	adapter.addField(rules.string, 'leagueName', fields.leagueName);
	adapter.addField(rules.string, 'imgBG', fields.imgBG);
	adapter.addField(rules.string, 'imgMD', fields.imgMD);
	adapter.addField(rules.string, 'imgSM', fields.imgSM);

	return adapter;
}

// Service ----------------------------------------------------------------------------------------
function makeCategoryName(item) {
	let result = `${item.sportName} / ${item.countryName}`;
	if (item.leagueName) {
		result += ` / ${item.leagueName}`;
	}

	return result;
}
