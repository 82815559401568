import { Tree } from 'antd';
import styled from 'styled-components';


export const TreeCheckable = styled(Tree)`
      li {
          .ant-tree-node-content-wrapper {
            width: 85%;
            padding: 0 !important;
              .ant-tree-title > span {
                display: flex;
                justify-content: space-between;
                span {
                  padding: 0 5px;
                  position: relative;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  &:first-child {
                    flex: 0 0 60%;
                    max-width: 60%;
                  }
                  &:last-child:not(:first-child) {
                    flex: 0 0 40%;
                    max-width: 40%;
                    span:first-child {
                      font-size: 11px;
                      color: #c1c1c1;
                      font-weight: 500;
                    }
                    &:last-child {
                      font-size: 11px;
                    }
                    }
                  }
                    
                }
           
            }
          }
`;


export const PartnerTitle = styled.span`
  position: relative;

  &:before {
    inset-inline-start: 50%;
    width: ${({ atLeastOneWebsite, everyWebsite }) => {
		return atLeastOneWebsite && !everyWebsite ? '8px' : '12px';
	}};
      height: ${({ atLeastOneWebsite, everyWebsite }) => {
		return atLeastOneWebsite && !everyWebsite ? '8px' : '12px';
	}};
    transform: translate(-50%,-50%) scale(1);
    opacity: 1;
    content: '';
    position: absolute;
    top: 7px;
    left: -20px;
    margin-right: 9px;
    border-radius: ${({ everyWebsite, partnerSelected }) => {
		return !partnerSelected && everyWebsite ? '4px' : '2px';
	}};
    background-color: ${({ atLeastOneWebsite, everyWebsite, partnerSelected }) => {
		return !partnerSelected && atLeastOneWebsite && !everyWebsite ? '#1677ff' : 'none';
	}};
    border: ${({ everyWebsite, partnerSelected }) => {
		return !partnerSelected && everyWebsite ? '2px solid #1677ff' : 'none';
	}};
}`;


