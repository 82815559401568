import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { transactionsAPI } from '../../../helpers/api/transactions';
import { showError } from '../../../helpers/notifications';
import { TRANSACTION_TYPES } from '../../../helpers/commonConstants';

import { adaptTransactionsList } from './utils';
import { logger } from '../../../helpers/logger';

const prefix = 'users.adjustment';

const messages = {
	errorListLoad: `${prefix}.errorListLoad`,
};


function getStoreData({ Users }) {

	return {
		pagination: Users.BalanceAdjustments.get('pagination'),
	};
}

function* listReload() {

	yield takeEvery(actions.BALANCE_ADJUSTMENTS_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { pagination } = yield select(getStoreData);
		const { userID } = action.data;

		const params = {
			user_id: userID,
			type_id: TRANSACTION_TYPES.balanceAdjustment,
			page   : pagination.currentPage,
			limit  : pagination.itemsPerPage,
		};

		let list       = [];
		let totalCount = 0;
		try {
			const res = yield call(transactionsAPI.transactionsList, params);
			if (res && res.status === 200) {
				const result = adaptTransactionsList(res.data.data, res.headers);
				list       = result.list;       // eslint-disable-line prefer-destructuring
				totalCount = result.totalCount; // eslint-disable-line prefer-destructuring
			}

		} catch (error) {
			showError(messages.errorListLoad, error);
			logger.log(error);
		}
		yield put(actions.listRefresh(list));
		yield put(actions.paginationRefresh({ totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* userBalanceAdjustmentsSaga() {
	yield all([
		fork(listReload),
	]);
}
