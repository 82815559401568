import { apiRequest } from './index';

// Permissions ---------------------------------------------------------------------------------------

function permissions(params) {

	const req = {
		method: 'GET',
		url   : '/permissions',
		params,
	};

	return apiRequest(req);
}

export const permissionsAPI = {
	permissions,
};
