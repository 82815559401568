import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import orderBy from 'lodash/orderBy';
import commonActions from '../../../common/actions';
import actions from './actions';

import { showError, showSuccess } from '../../../../helpers/notifications';
import { categoriesAPI } from '../../../../helpers/api/categories';
import { eventsAPI } from '../../../../helpers/api/events';
import {
	adaptCategoryList,
	adaptEventsList,
	adaptMarketsList,
	appendSubNodes,
	setDisplayStatusID,
	prepareOrdersList,
} from './utils';
import { logger } from '../../../../helpers/logger';

const prefix = 'sport.tree';

const messages = {
	errorSportList      : `${prefix}.errorSportList`,
	errorCountryList    : `${prefix}.errorCountryList`,
	errorLeagueList     : `${prefix}.errorLeagueList`,
	errorSubLeagueList  : `${prefix}.errorSubLeagueList`,
	errorEventList      : `${prefix}.errorEventList`,
	errorMarketList     : `${prefix}.errorMarketList`,
	errorDisplayStatus  : `${prefix}.errorDisplayStatus`,
	errorOrdersSave     : `${prefix}.errorOrdersSave`,
	successDisplayStatus: `${prefix}.successDisplayStatus`,
	successOrderSave    : `${prefix}.successOrderSave`,
};
function getStoreData({ Sport: { Categories }, App }) {
	const { Tree } = Categories;
	const ordersUI = Tree.get('ordersUI');

	return {
		showHidden         : Tree.get('showHidden'),
		showInactive       : Tree.get('showInactive'),
		treeDataSource     : Tree.get('treeDataSource'),
		reorderedCategories: Tree.get('reorderedCategories'),
		channelID          : ordersUI.channelID,
		websiteID          : App.get('websiteID'),
	};
}

function* categoryTreeReload() {

	yield takeEvery(actions.CATEGORY_TREE_RELOAD, function* (action) {
		const { websiteID, showInactive } = yield select(getStoreData);
		const { channelID } = action.data;
		const params = {
			channel_id              : channelID,
			website_id              : websiteID,
			include_empty_categories: showInactive,
			attach_events           : true,
		};

		let categoryTree = [];
		try {
			const res = yield call(categoriesAPI.getSports, params);
			if (res && res.status === 200) {
				categoryTree = adaptCategoryList(res.data.data);
			}

		} catch (error) {
			showError(messages.errorSportList, error);
			logger.log(error);
		}

		yield put(actions.categoryTreeRefresh(categoryTree));
	});
}

function* countryListReload() {

	yield takeEvery(actions.CATEGORY_TREE_COUNTRY_LIST_RELOAD, function* (action) {
		const { sportID, parentNodePos, typeID, cb } = action.data;
		const { treeDataSource, showHidden, showInactive, channelID, websiteID } = yield select(getStoreData);

		let countryList = [];
		const params = {
			parent_id               : sportID,
			model_type              : 1,
			show_deleted            : showHidden,
			channel_id              : channelID,
			website_id              : websiteID,
			include_empty_categories: showInactive,
			type_id                 : typeID + 1,
			attach_events           : true,
		};

		try {
			const response = yield call(categoriesAPI.getCategories, params);
			if (response && response.status === 200) {
				countryList = adaptCategoryList(response.data.data);
			}
		} catch (error) {
			showError(messages.errorCountryList, error);
			logger.log(error);
		}

		if (typeof cb === 'function') {
			cb();
		}

		const orderedData = orderBy(treeDataSource, 'eventsCount', 'desc');
		const newTreeDataSource = appendSubNodes(
			orderedData,
			countryList,
			parentNodePos
		);

		yield put(actions.categoryTreeRefresh(newTreeDataSource));
	});
}

function* leagueListReload() {

	yield takeEvery(actions.CATEGORY_TREE_LEAGUE_LIST_RELOAD, function* (action) {
		const { countryID, parentNodePos, typeID, cb } = action.data;
		const { treeDataSource, showHidden, channelID, websiteID, showInactive } = yield select(getStoreData);

		let leaguesList = [];
		const params = {
			parent_id               : countryID,
			model_type              : 1,
			show_deleted            : showHidden,
			channel_id              : channelID,
			website_id              : websiteID,
			include_empty_categories: showInactive,
			type_id                 : typeID + 1,
			attach_events           : true,
		};

		try {
			const response = yield call(categoriesAPI.getCategories, params);
			if (response && response.status === 200) {
				leaguesList = adaptCategoryList(response.data.data);
			}
		} catch (error) {
			showError(messages.errorLeagueList, error);
			logger.log(error);
		}

		if (typeof cb === 'function') {
			cb();
		}

		const newTreeDataSource = appendSubNodes(
			treeDataSource,
			leaguesList,
			parentNodePos
		);

		yield put(actions.categoryTreeRefresh(newTreeDataSource));
	});
}

function* eventListReload() {

	yield takeEvery(actions.CATEGORY_TREE_EVENT_LIST_RELOAD, function* (action) {

		const { leagueID, parentNodePos, cb } = action.data;
		const { treeDataSource, showHidden, channelID, websiteID } = yield select(getStoreData);

		let eventsList = [];
		const paramsSubLeagues = {
			parent_id   : leagueID,
			model_type  : 1,
			show_deleted: showHidden,
			channel_id  : channelID,
			website_id  : websiteID,
		};
		const paramsEvents = {
			league_id: leagueID,
		};

		try {
			// subleagues
			const resSubLeagues = yield call(
				categoriesAPI.getCategories,
				paramsSubLeagues
			);
			if (resSubLeagues && resSubLeagues.status === 200) {
				const subLeaguesList = adaptCategoryList(resSubLeagues.data.data);
				eventsList = eventsList.concat(subLeaguesList);
			}

			// events
			const resEvents = yield call(eventsAPI.eventList, paramsEvents);
			if (resEvents && resEvents.status === 200) {
				const adaptedList = adaptEventsList(resEvents.data.data, leagueID);
				eventsList = eventsList.concat(adaptedList);
			}
		} catch (error) {
			showError(messages.errorEventList, error);
			logger.log(error);
		}

		if (typeof cb === 'function') {
			cb();
		}

		const newTreeDataSource = appendSubNodes(
			treeDataSource,
			eventsList,
			parentNodePos
		);

		yield put(actions.categoryTreeRefresh(newTreeDataSource));
	});
}

function* marketListReload() {

	yield takeEvery(actions.CATEGORY_TREE_MARKET_LIST_RELOAD, function* (action) {

		const { eventID, parentNodePos, cb } = action.data;
		const { treeDataSource } = yield select(getStoreData);

		let marketList = [];
		try {
			const response = yield call(eventsAPI.eventMarketList, eventID);
			if (response && response.status === 200) {
				marketList = adaptMarketsList(response.data.data, eventID);
			}
		} catch (error) {
			showError(messages.errorMarketList, error);
			logger.log(error);
		}

		if (typeof cb === 'function') {
			cb();
		}

		const newTreeDataSource = appendSubNodes(
			treeDataSource,
			marketList,
			parentNodePos
		);

		yield put(actions.categoryTreeRefresh(newTreeDataSource));
	});
}

function* setDisplayStatus() {

	yield takeEvery(actions.CATEGORY_TREE_SET_DISPLAY_STATUS, function* (action) {

		const { categoryID, displayStatusID, nodePos } = action.data;
		const { treeDataSource } = yield select(getStoreData);

		const params = { displayStatusID };
		try {
			const response = yield call(
				categoriesAPI.updateCategoryStatus,
				categoryID,
				params
			);
			if (response && response.status === 200) {
				showSuccess(messages.successDisplayStatus);
				const result = setDisplayStatusID(
					treeDataSource,
					nodePos,
					displayStatusID
				);
				yield put(actions.categoryTreeRefresh(result.treeDataSource));
				yield put(actions.setSelected({ node: result.node }));
				yield put(commonActions.commonSportListReload());
			}
		} catch (error) {
			showError(messages.errorDisplayStatus, error);
			logger.log(error);
		}
	});
}

function* ordersSave() {

	yield takeEvery(actions.CATEGORY_TREE_ORDERS_SAVE, function* () {

		const { channelID, websiteID, reorderedCategories } = yield select(getStoreData);
		const preparedData = yield call(prepareOrdersList, reorderedCategories, channelID, websiteID);
		const params = { website_id: websiteID };
		try {
			const res = yield call(categoriesAPI.categoryOrdersUpdateWithChannel, preparedData, params);
			if (res && res.status === 200) {
				showSuccess(messages.successOrderSave);
				yield put(actions.reorderedCategoriesRefresh([]));
				yield put(actions.ordersUIRefresh({ isChanged: false }));
			}

		} catch (error) {
			showError(messages.errorOrdersSave, error);
			logger.log(error);
		}
	});
}

export default function* categoriesSaga() {
	yield all([
		fork(categoryTreeReload),
		fork(countryListReload),
		fork(leagueListReload),
		fork(eventListReload),
		fork(marketListReload),
		fork(setDisplayStatus),
		fork(ordersSave),
	]);
}
