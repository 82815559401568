export enum mediaValidationTypes {
	jpg = 'jpg',
	jpeg = 'jpeg',
	png = 'png',
	svg = 'svg',
	webp = 'webp',
}

export const mediaValidationTypesList: mediaValidationTypes[] = [
	mediaValidationTypes.jpg,
	mediaValidationTypes.jpeg,
	mediaValidationTypes.png,
	mediaValidationTypes.svg,
	mediaValidationTypes.webp,
];
