import EntityAdapter from '../../../helpers/entityAdapter';
import { createEntities } from '../../utility';
import cloneDeep from 'lodash/cloneDeep';

export const fields = {
	id            : 'id',
	name          : 'name',
	orderNumber   : 'order_number',
	logoURL       : 'logo_url',
	gameIDs       : 'game_ids',
	langID        : 'lang_id',
	subCategoryID : 'sub_category_id',
	alias         : 'alias',
	seoKeywords   : 'seo_keywords',
	seoTitle      : 'seo_title',
	seoDescription: 'seo_description',
	OGDescription : 'og_description',
	OGImage       : 'og_image',
	OGTitle       : 'og_title',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptCustomProvidersList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return createEntities(adaptedData);

}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'orderNumber', fields.orderNumber);
	adapter.addField(rules.arrayNumber, 'gameIDs', fields.gameIDs);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'logoURL', fields.logoURL);

	return adapter;
}


export function updateCustomProvidersEntities(entities = {}, baseData, id, loading) {
	const clonedEntities = cloneDeep(entities);
	const item = baseData || clonedEntities[id];

	if (item) {
		clonedEntities[id] = {
			...item,
			loading,
		};

		return clonedEntities;
	}

	return entities;
}
