import { call, put, all, takeEvery, select, fork } from 'redux-saga/effects';
import dayjs from 'dayjs';
import actions from './actions';
import offerActions from '../offer/actions';
import notification from '../../../../../helpers/notifications';
import offersApi from '../../../../../helpers/api/offers';

const getOfferInfo = ({ CMS, App, Sport: { Events } }) => {
	return {
		sportId     : CMS.LandingPage.SpecialOffers.List.get('sportId'),
		date        : CMS.LandingPage.SpecialOffers.Offer.get('date'),
		selections  : CMS.LandingPage.SpecialOffers.Offer.get('selections'),
		regularPrice: CMS.LandingPage.SpecialOffers.Offer.get('regularPrice'),
		customPrice : CMS.LandingPage.SpecialOffers.Offer.get('customPrice'),
		events      : Events.List.get('list'),
		websiteID   : App.get('websiteID'),
	};
};

const prefix = 'landingPage.specialOffer';

const messages = {
	errorOfferLoad    : `${prefix}.loading.offers.failed`,
	successAddOffer   : `${prefix}.offer.added.success`,
	errorAddOffer     : `${prefix}.adding.offer.failed`,
	successOfferUpdate: `${prefix}.offer.updated.success`,
	errorOfferUpdate  : `${prefix}.updating.offer.failed`,
	successOfferRemove: `${prefix}.offer.removed.success`,
	errorRemoveOffer  : `${prefix}.removing.offer.failed`,

};

function* fetchOffersSaga() {
	yield takeEvery(actions.OFFERS_FETCH_OFFERS, function* ({ data }) {
		const { sportID } = data;
		try {
			const response = yield call(offersApi.fetchOffers, {
				sportID,
			});
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.fetchOffersSuccess(list));
			}
		} catch (e) {
			notification.showError(messages.errorOfferLoad);
		}
	});
}

function* addOfferSaga() {
	yield takeEvery(actions.OFFERS_ADD_OFFER, function* () {
		try {
			const { selections, sportId, regularPrice, customPrice, websiteID, events } = yield select(getOfferInfo);
			const params = { website_id: websiteID };
			const response = yield call(offersApi.addOffer, {
				sportId,
				regularPrice,
				customPrice,
				date : dayjs().toString(),
				items: selections.map(selection => {
					const event = events.find(e => e.id === selection.event_id);
					return {
						sport_id          : event.sportID,
						country_id        : event.countryID,
						league_id         : event.leagueID,
						event_id          : selection.event_id,
						event_market_id   : selection.event_market_id,
						event_selection_id: selection.id,
						market_id         : selection.market_id,
						selection_id      : selection.selection_id,
						odd               : selection.odd,
						trading_mode      : selection.trading_mode,
					};
				}),
			}, params);
			if (response && response.status === 200) {
				notification.showSuccess(messages.successAddOffer);
				const fetchedOffer = response.data.data;
				yield put(actions.addOfferSuccess(fetchedOffer));
				yield put(offerActions.resetOffer());
				yield put(offerActions.changeEventQuery(''));
			}
		} catch (e) {
			notification.showError(messages.errorAddOffer);
		}
	});
}

function* updateOfferSaga() {
	yield takeEvery(actions.OFFERS_UPDATE_OFFER, function* () {
		try {
			const { selections, ...offerInfo } = yield select(getOfferInfo);
			const { id } = yield select(({ CMS }) => CMS.LandingPage.SpecialOffers.List.get('activeOffer'));
			const { events } = offerInfo;
			const response = yield call(offersApi.updateOffer, id, {
				...offerInfo,
				date : dayjs().toString(),
				items: selections.map(selection => {
					const  event = events.find(item => +item.id === +selection.event_id);
					return {
						event_id          : selection.event_id,
						event_market_id   : selection.event_market_id,
						event_selection_id: selection.id,
						sport_id          : event.sportID,
						country_id        : event.countryID,
						league_id         : event.leagueID,
						market_id         : selection.market_id,
						selection_id      : selection.selection_id,
					};
				}),
			});
			if (response && response.status === 200) {
				notification.showSuccess(messages.successOfferUpdate);
				const fetchedOffer = response.data.data;
				yield put(actions.updateOfferSuccess(fetchedOffer));
			}
		} catch (e) {
			notification.showError(messages.errorOfferUpdate);
		}
	});
}

function* removeOfferSaga() {
	yield takeEvery(actions.OFFERS_REMOVE_OFFER, function* ({ data }) {
		try {
			const response = yield call(offersApi.removeOffer, data);
			if (response && response.status === 200) {
				notification.showSuccess(messages.successOfferRemove);
				yield put(actions.removeOfferSuccess(data));
			}
		} catch (e) {
			notification.showError(messages.errorRemoveOffer);
		}
	});
}

export default function* addingOfferSaga() {
	yield all([
		fork(fetchOffersSaga),
		fork(removeOfferSaga),
		fork(addOfferSaga),
		fork(updateOfferSaga),
	]);
}
