import { apiRequest } from './index';

function getCountries(data, params = {}) {

	const req = {
		method: 'GET',
		url   : '/country',
		params,
	};
	return apiRequest(req);
}

export const countryAPI = {
	getCountries,
};
