import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import ruleSaga from './rule/saga';

export default function* bettingRulesSaga() {
	yield all([
		fork(listSaga),
		fork(ruleSaga),
	]);
}
