import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  &.ant-modal {
    position: absolute;

    .ant-modal-header {
      min-height: 55px;
    }
  }
  &.DataModal {
    overflow:hidden;
  }
  &.DataModal .ant-modal-content{
    height: 100%;
    padding-bottom: 60px;
  }
  &.DataModal .ant-modal-body {
    overflow-x: auto;
    height: 100%;
  }

  button[type="submit"] {
    display: none;
  }
`;
