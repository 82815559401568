import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import listActions from '../list/actions';

import { helpCenterAPI } from '../../../../../helpers/api/helpCenter';
import notifications from '../../../../../helpers/notifications';

import {
	prepareNames,
	prepareQuestions,
} from './utils';
import { createNamesAdapter, createQuestionsAdapter } from '../list/utils';
import { cloneDeep } from 'lodash';

const prefix = 'pages.helpcenter.modal';

const messages = {
	errorTopicReload  : `${prefix}.errorTopicReload`,
	errorTopicSave    : `${prefix}.errorTopicSave`,
	successTopicSave  : `${prefix}.successTopicSave`,
	successTopicUpdate: `${prefix}.successTopicUpdate`,
};


function getStoreData({ CMS, App }) {
	const { Pages: { HelpCenter } } = CMS;
	return {
		names    : HelpCenter.Topic.get('names'),
		questions: HelpCenter.Topic.get('questions'),
		UI       : HelpCenter.Topic.get('UI'),
		websiteID: App.get('websiteID'),
		entities : cloneDeep(HelpCenter.List.get('entities')),
	};
}

function* topicSave() {
	yield takeEvery(actions.TOPIC_SAVE, function* (action) {
		yield put(actions.uiRefresh({ loadEntity: true }));
		
		const { questions, names, UI, websiteID, entities } = yield select(getStoreData);
		const { visible } = action.data;
		const { editMode, topicID } = UI;
		
		const preparedNames = prepareNames(names);
		const preparedQuestions = prepareQuestions(questions);
		const preparedData = {
			website_id: websiteID,
			names     : preparedNames,
			questions : preparedQuestions,
		};
		try {
			if (editMode) {
				const res = yield call(
					helpCenterAPI.topicUpdate,
					topicID,
					preparedData
				);
				if (res && res.status === 200) {
					const { names, questions } = res.data.data;
					const namesAdapter = createNamesAdapter();
					const questionsAdapter = createQuestionsAdapter();

					const adaptedNames = namesAdapter.adaptList(names);
					const adaptedQuestions = questionsAdapter.adaptList(questions);

					entities[topicID].names = adaptedNames;
					entities[topicID].questions = adaptedQuestions;
					notifications.showSuccess(messages.successTopicSave);
					
					yield put(listActions.listRefresh(entities));
					yield put(actions.uiRefresh({ visible }));

				}
			} else {
				const res = yield call(helpCenterAPI.topicAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successTopicSave);
					yield put(actions.dataReset());
					yield put(listActions.listReload());
				}
			}
		} catch (error) {
			notifications.showError(messages.errorTopicSave, error);
		}
		yield put(actions.uiRefresh({ loadEntity: false }));
	});
}


export default function* topicSaga() {
	yield all([
		fork(topicSave),
	]);
}
