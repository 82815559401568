import { apiRequest } from './index';

function rtmBetslipsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/rtm/betslips',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function rtmBetslipsBetInfo(betID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/rtm/betslips/${betID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function betslipsBetUpdate(betID, betData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/betslips/${betID}`,
		data  : betData,
		params,
	};

	return apiRequest(req);
}

function betslipsBetDetailsUpdate(betID, detailsData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/betslipDetails/${betID}`,
		data  : detailsData,
		params,
	};

	return apiRequest(req);
}

function betslipsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/betslips',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function rtmCSVLoad(params = {}) {

	const req = {
		method: 'GET',
		url   : '/rtm/csv/betslips',
		params,
	};

	return apiRequest(req);
}

function rtmCSVRecalculate(params = {}) {

	const req = {
		method: 'GET',
		url   : '/rtm/csv/recalculate/betslips',
		params,
	};

	return apiRequest(req);
}

function voidBets(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/betslip/void',
		data,
		params,
	};

	return apiRequest(req);
}

export const betsAPI = {
	rtmBetslipsList,
	rtmBetslipsBetInfo,
	betslipsBetUpdate,
	betslipsBetDetailsUpdate,
	betslipsList,

	rtmCSVLoad,
	rtmCSVRecalculate,

	voidBets,
};
