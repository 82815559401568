const actions = {

	SELECTION_HISTORY_MODEL_DATA_RELOAD : 'SELECTION_HISTORY_MODEL_DATA_RELOAD',
	SELECTION_HISTORY_MODEL_DATA_REFRESH: 'SELECTION_HISTORY_MODEL_DATA_REFRESH',
	SELECTION_HISTORY_UI_REFRESH        : 'SELECTION_HISTORY_UI_REFRESH',

	dataReload: (selectionID, logModel) => ({
		type: actions.SELECTION_HISTORY_MODEL_DATA_RELOAD,
		data: {
			selectionID,
			logModel,
		},
	}),
	dataRefresh: (logData, logModel) => ({
		type: actions.SELECTION_HISTORY_MODEL_DATA_REFRESH,
		data: {
			logData,
			logModel,
		},
	}),
	uiRefresh: data => ({
		type: actions.SELECTION_HISTORY_UI_REFRESH,
		data,
	}),
};

export default actions;
