import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import actions from './actions';
import { defaultValuesTransactionFilter, initFilterTypes } from './utils';
import { defaultReportFields } from '../../../containers/Reports/Transactions/utils';

const initState = new Map({

	transactionIDs: [],
	entities      : {},
	reportFields  : defaultReportFields,

	filter: createFilter(filtersURL.transactions, initFilterTypes),

	UI: {
		loading      : false,
		visible      : false,
		modalLoading : false,
		reportLoading: false,
	},
	websiteList: [],

	dataModalInner: {},
});

export default function transactionsReducer(state = initState, action) {
	switch (action.type) {

		case actions.TRANSACTIONS_DATA_REFRESH: {
			const { transactionIDs, entities } = action.data;
			return state
				.set('transactionIDs', transactionIDs)
				.set('entities', entities);
		}

		case actions.TRANSACTIONS_NEW_TRANSACTION_LIST_REFRESH: {
			const { newTransactionIDs, newTransactionEntities } = action.data;
			return state
				.set('newTransactionIDs', newTransactionIDs)
				.set('newTransactionEntities', newTransactionEntities);
		}

		case actions.TRANSACTIONS_FILTER_RESET: {
			return state
				.set('filter', action.data);
		}

		case actions.TRANSACTIONS_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.TRANSACTIONS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.TRANSACTIONS_WEBSITES_LIST_REFRESH: {
			return state.set('websiteList', action.data);
		}

		case actions.TRANSACTIONS_DATA_MODAL_REFRESH: {
			return state.set('dataModalInner', action.data);
		}
		case actions.FILTER_RESET: {
			return state.set('filter', defaultValuesTransactionFilter);
		}

		case actions.RESET_TRANSACTIONS: {
			return  initState;
		}

		case actions.TRANSACTIONS_REPORT_DATA_FIELDS_REFRESH: {
			return state.set('reportFields', action.reportFields);
		}

		default:
			return state;
	}
}
