import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import notifications from '../../../../helpers/notifications';
import { categoriesAPI } from '../../../../helpers/api/categories';

import actions from './actions';
import { adaptTypeList, prepareTypeData } from './utils';
import { logger } from '../../../../helpers/logger';


const prefix = 'sport.regulationtypes';

const messages = {
	loadList     : `${prefix}.loadList`,
	successUpdate: `${prefix}.successUpdate`,
	errorUpdate  : `${prefix}.errorUpdate`,
	successDelete: `${prefix}.successDelete`,
	errorDelete  : `${prefix}.errorDelete`,
};

function getStoreData({ Sport: { Categories } }) {

	return {
		typeList: Categories.RegulationTypes.get('typeList'),
		modalUI : Categories.RegulationTypes.get('modalUI'),
	};
}

function* dataReload() {

	yield takeEvery(actions.CATEGORY_REGULATION_TYPES_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { categoryID } = action.data;

		let typeList = [];
		try {
			const res = yield call(categoriesAPI.regulationTypesList, categoryID);
			if (res && res.status === 200) {
				typeList = adaptTypeList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.loadList);
			logger.log(error);
		}

		yield put(actions.dataRefresh(typeList));
		yield put(actions.modalUIRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

function* dataSaveClose() {
	yield takeEvery(actions.CATEGORY_REGULATION_TYPES_DATA_SAVE_CLOSE, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { typeData } = action.data;
		const data = prepareTypeData(typeData);
		const { modalUI } = yield select(getStoreData);
		const { closeModal } = modalUI;
		try {
			const res = yield call(categoriesAPI.regulationTypeAdd, data);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successUpdate);
			}
			yield put(actions.modalUIRefresh({ isChanged: false }));

		} catch (error) {
			notifications.showError(messages.errorUpdate);
		}
		yield put(
			actions.modalUIRefresh({
				loading: false,
				visible: closeModal,
			})
		);
	});
}

function* dataDelete() {

	yield takeEvery(actions.CATEGORY_REGULATION_TYPES_DATA_DELETE, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { typeID } = action.data;
		const { modalUI } = yield select(getStoreData);
		const { categoryID } = modalUI;

		try {
			const res = yield call(categoriesAPI.regulationTypeDelete, typeID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successDelete);
			}
		} catch (error) {
			notifications.showError(messages.errorDelete, error);
			logger.dir(error);
		}

		yield put(actions.dataReload(categoryID));
		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

export default function* regulationTypesSaga() {
	yield all([
		fork(dataReload),
		fork(dataSaveClose),
		fork(dataDelete),
	]);
}
