import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash';
import { getNotificationTypeName } from '../redux/notifications/utils';

const count             = (state) => state.Notifications.get('count');
const bets              = (state) => state.Notifications.get('bets');
const casino            = (state) => state.Notifications.get('casino');
const deposits          = (state) => state.Notifications.get('deposits');
const withdrawal        = (state) => state.Notifications.get('withdrawal');
const documents         = (state) => state.Notifications.get('documents');
const riskAlertLow      = (state) => state.Notifications.get('riskAlertLow');
const riskAlertMedium   = (state) => state.Notifications.get('riskAlertMedium');
const riskAlertHigh     = (state) => state.Notifications.get('riskAlertHigh');
const messages          = (state) => state.Notifications.get('messages');
const page              = (state) => state.Notifications.get('page');
const hasMore           = (state) => state.Notifications.get('hasMore');
const UI                = (state) => state.Notifications.get('UI');

// UI
export const deriveNotifBadgeUI = createSelector(
	[UI],
	(UI) => UI
);

// Count ------------------------------------------------------------------------------------------
export const deriveCount = createSelector(
	[count],
	(count) => count
);

export const riskCount = createSelector(
	[deriveCount],
	(count) => {
		let riskCount = 0;
		const cloneCount = cloneDeep(count);
		for (const [key, value] of Object.entries(cloneCount)) {
			if (key.startsWith('riskAlert')) {
				riskCount += value;
			}
		}
		return riskCount;
	}
);

export const deriveAllNotificationsCount = createSelector(
	[deriveCount],
	(count) => Object.values(count).reduce((res, num) => {
		res += num;
		return res;
	}, 0)
);

export const deriveCountByType = typeID => {
	return createSelector(
		[deriveCount],
		(count) => {
			const key = getNotificationTypeName(typeID);
			return count[key];
		}
	);
};

// Complex ----------------------------------------------------------------------------------------
export const deriveNotificationsList = (typeID) => {
	return createSelector(
		[bets, casino, deposits, withdrawal, documents, messages, riskAlertLow, riskAlertMedium, riskAlertHigh],
		(bets, casino, deposits, withdrawal, documents, messages, riskAlertLow, riskAlertMedium, riskAlertHigh) => {
			const notifications = {
				bets,
				casino,
				deposits,
				withdrawal,
				documents,
				messages,
				riskAlertLow,
				riskAlertMedium,
				riskAlertHigh,
			};
			const sectionName = getNotificationTypeName(typeID);
			return notifications[sectionName];
		}
	);
};

// Page -------------------------------------------------------------------------------------------
export const derivePage = createSelector(
	[page],
	(page) => page
);

export const derivePageByType = typeID => {
	return createSelector(
		[derivePage],
		(page) => {
			const key = getNotificationTypeName(typeID);
			return page[key];
		}
	);
};

// Has More ---------------------------------------------------------------------------------------
export const deriveHasMore = createSelector(
	[hasMore],
	(hasMore) => hasMore
);

export const deriveHasMoreByType = typeID => {
	return createSelector(
		[deriveHasMore],
		(hasMore) => {
			const key = getNotificationTypeName(typeID);
			return hasMore[key];
		}
	);
};

export const selectNotificationCount = (state => {
	const notificationCount = state.Notifications.get('count');
	return  Object.values(notificationCount).reduce((res, num) => {
		res += num;
		return res;
	}, 0);
});
