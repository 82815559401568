import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import notifications from '../../../../helpers/notifications';
import { liveStatisticTypesAPI } from '../../../../helpers/api/liveStatisticTypes';
import { liveStatisticTypesActions } from '../listLST/actions';
import { liveStatisticTypesModalActions } from './actions';
import {
	adaptLST,
	adaptNames,
	adaptVisibility,
	prepareData,
	prepareDataEdit,
	prepareNames,
	prepareVisibilityStatus,
	updateLiveStatisticItem,
} from './utils';


const messages = {
	errorSave: 'sport.liveStatistic.save.error',
	errorLoad: 'sport.liveStatistic.load.modal.error',
};

function getStoreData({ Sport, App }) {

	return {
		baseData        : Sport.LiveStatisticTypes.Modal.get('baseData'),
		names           : Sport.LiveStatisticTypes.Modal.get('names'),
		visibilityStatus: Sport.LiveStatisticTypes.Modal.get('visibility'),
		editMode        : Sport.LiveStatisticTypes.List.get('UI').editMode,
		entities        : Sport.LiveStatisticTypes.List.get('entities'),
		list            : Sport.LiveStatisticTypes.List.get('list'),
		sportID         : Sport.LiveStatisticTypes.Filter.get('filter').sportID,
		websiteID       : App.get('websiteID'),
	};

}


function* saveData() {
	yield takeEvery(liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_SAVE, function* (action) {
		yield put(liveStatisticTypesModalActions.uiRefresh({ loading: true }));
		const closeModal = action.data && action.data.closeModal;
		const { baseData, websiteID, editMode, names, visibilityStatus, entities } = yield select(getStoreData);
		const data = prepareData(baseData, websiteID);
		const dataEdit = prepareDataEdit(baseData);
		const params = {
			website_id: websiteID,
		};
		const dataNames = prepareNames(names);
		const dataVisibilityStatus = prepareVisibilityStatus(visibilityStatus);
		try {
			let res;
			if (editMode) {
				res = yield call(liveStatisticTypesAPI.editLST, dataEdit, baseData.id);
			} else {
				res = yield call(liveStatisticTypesAPI.saveLST, data);
			}
			if (res.status === 200) {
				const idLST = res.data.data.id;
				const adapted = adaptLST(res.data.data);
				const updatedEntities = { ...entities, [idLST]: adapted };
				const updatedBaseData = updateLiveStatisticItem(adapted, baseData);

				yield put(liveStatisticTypesModalActions.listReloadCase(updatedBaseData));
				yield put(liveStatisticTypesActions.entitiesReloadCase(updatedEntities));
				yield call(liveStatisticTypesAPI.createAndEditNames, dataNames, idLST, params);
				yield call(liveStatisticTypesAPI.createAndEditVisibility, dataVisibilityStatus, idLST, params);

				if (closeModal) {
					yield put(liveStatisticTypesActions.uiRefresh({ visibleModal: false }));
					yield put(liveStatisticTypesModalActions.clearModal());
				}
			}
		} catch (error) {
			notifications.showError(messages.errorSave);
		}
		yield put(liveStatisticTypesModalActions.uiRefresh({ loading: false, isChanged: false }));
	});
}

function* modalData() {
	yield takeEvery(liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_GET_MODAL_DATA, function* (action) {
		const { entities } = yield select(getStoreData);
		const { data: id } = action;

		try {
			yield put(liveStatisticTypesModalActions.listReloadCase(entities[id]));
			const res = yield call(liveStatisticTypesAPI.getNames, id);
			const resVisibility = yield call(liveStatisticTypesAPI.getVisibility, id);
			const adaptNamesData = adaptNames(res.data.data);
			const adaptVisibilityData = adaptVisibility(resVisibility.data.data);
			yield put(liveStatisticTypesModalActions.namesReloadCase(adaptNamesData));
			yield put(liveStatisticTypesModalActions.visibilityReloadCase(adaptVisibilityData));
		} catch (error) {
			notifications.showError(messages.errorLoad);
		}

	});
}

export default function* modalLSTSaga() {
	yield all([
		fork(saveData),
		fork(modalData),
	]);
}
