import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import notifications from '../../../helpers/notifications';
import { historyAPI } from '../../../helpers/api/history';
import { USER_LOG_MODELS } from '../../../helpers/commonConstants';

import actions from './actions';
import { adaptData, adaptAllData } from './utils';
import { logger } from '../../../helpers/logger';

const prefix = 'users.history';

const messages = {
	loadData: `${prefix}.loadData`,
};

function* dataReload() {

	yield takeEvery(actions.USER_HISTORY_MODEL_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		let data = [];
		const { userID, logModel } = action.data;

		if (logModel === USER_LOG_MODELS.all) {
			data = yield allDataReload(userID);
		} else if (logModel === USER_LOG_MODELS.user){
			data = yield modelDataReload(userID, logModel);
		} else if (logModel === USER_LOG_MODELS.gambling) {
			data = yield modelDataReload(userID, logModel);
		}

		yield put(actions.dataRefresh(data, logModel));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* modelDataReload(userID, logModel) {
	let data = [];

	try {
		let response;

		if (logModel === USER_LOG_MODELS.gambling) {
			const params = { model_id: userID };
			response = yield call(historyAPI.historyGamblingLogs, params);
		} else {
			response = yield call(historyAPI.historyUserLogs, userID, logModel);
		}

		if (response && response.status === 200) {
			data = adaptData(response.data.data, logModel);
		}
	} catch (error) {
		notifications.showError(messages.loadData, error);
		logger.log(error);
	}

	return data;
}

function* allDataReload(userID) {

	const user = yield modelDataReload(userID, USER_LOG_MODELS.user);

	const allData = adaptAllData({
		user,
	});

	return allData;
}

export default function* userHistorySaga() {
	yield all([
		fork(dataReload),
	]);
}
