const actions = {

	DASHBOARD_PLAYERS_OVERALL_DATA_REFRESH: 'DASHBOARD_PLAYERS_OVERALL_DATA_REFRESH',

	overallDataRefresh: data => ({
		type: actions.DASHBOARD_PLAYERS_OVERALL_DATA_REFRESH,
		data,
	}),

};

export default actions;
