import { combineReducers } from 'redux';

import List from './list/reducer';
import Selection from './selection/reducer';
import History from './history/reducer';

export default combineReducers({
	List,
	Selection,
	History,
});
