import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  
  .anticon.anticon-spin.anticon-loading {
  	font-size: 13px;
  }  
  .ant-select-arrow {
    display: ${({ hasArrow }) => hasArrow ? 'flex' : 'none'};
  }
`;
