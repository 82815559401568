import { all, fork } from 'redux-saga/effects';

import notificationSaga from './notification/saga';
import usersListSaga from './usersList/saga';

export default function* pushNotificationsRootSaga() {
	yield all([
		fork(notificationSaga),
		fork(usersListSaga),
	]);
}
