import { Map } from 'immutable';
import actions from './actions';

const initialState = Map({
	offers     : [],
	sportId    : null,
	activeOffer: null,
});

export default (state = initialState, { type, data }) => {
	switch (type) {
		case actions.OFFERS_FETCH_OFFERS_SUCCESS:
			return state.set('offers', data);
		case actions.OFFERS_ADD_OFFER_SUCCESS:
			return state.update('offers', offers => offers.concat(data));
		case actions.OFFERS_REMOVE_OFFER:
			return state.update('offers', offers => offers.filter(({ id }) => id !== data));
		case actions.OFFERS_SET_SPORT_ID:
			return state.set('sportId', data);
		case actions.OFFERS_UPDATE_OFFER_SUCCESS:
			return state.update('offers', offers => {
				const updatedOffers = [...offers];
				const index = updatedOffers.findIndex(({ id }) => id === data.id);
				updatedOffers[index] = data;
				return updatedOffers;
			});
		case actions.OFFERS_SET_ACTIVE_OFFER:
			return state.set(
				'activeOffer',
				state.get('offers').find(({ id }) => id === data)
			);
		default:
			return state;
	}
};
