import { Map } from 'immutable';
import uniq from 'lodash/uniq';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	betIDs  : [],
	entities: {},

	countryList: [],
	leagueList : [],
	eventList  : [],

	loadedCountryParentIDs: [],
	loadedLeagueParentIDs : [],
	loadedEventsParentIDs : [],

	filter: {
		userID: 0,
	},

	UI: {
		loading: false,
	},
});

export default function rtmUserBetsReducer(state = initState, action) {

	switch (action.type) {

		case actions.DATA_REFRESH: {
			const { betIDs, entities } = action.data;
			return state
				.set('betIDs', betIDs)
				.set('entities', entities);
		}

		case actions.FILTER_REFRESH: {
			const { filter } = action.data;
			const target = state.get('filter');
			const result = fill(filter, target, true);

			return state.set('filter', result);
		}

		case actions.FILTER_RESET: {
			const filter = initState.get('filter');
			return state.set('filter', filter);
		}

		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);

			return state.set('UI', result);
		}

		case actions.COUNTRY_LIST_REFRESH: {
			const { countryList } = action.data;
			return state.set('countryList', countryList);
		}

		case actions.LEAGUE_LIST_REFRESH: {
			const { leagueList } = action.data;
			return state.set('leagueList', leagueList);
		}

		case actions.EVENT_LIST_REFRESH: {
			const { eventList } = action.data;
			return state.set('eventList', eventList);
		}

		case actions.LOADED_COUNTRY_PARENT_ID_REFRESH: {
			const { sportID } = action.data;
			const list = state.get('loadedCountryParentIDs');
			list.push(sportID);

			return state.set('loadedCountryParentIDs', uniq(list));
		}

		case actions.LOADED_LEAGUE_PARENT_ID_REFRESH: {
			const { sportID } = action.data;
			const list = state.get('loadedLeagueParentIDs');
			list.push(sportID);

			return state.set('loadedLeagueParentIDs', uniq(list));
		}

		case actions.LOADED_EVENT_PARENT_ID_REFRESH: {
			const { leagueID } = action.data;
			const list = state.get('loadedEventsParentIDs');
			list.push(leagueID);

			return state.set('loadedEventsParentIDs', uniq(list));
		}

		default:
			return state;
	}
}
