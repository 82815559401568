const actions = {

	BALANCE_ADJUSTMENTS_LIST_RELOAD            : 'BALANCE_ADJUSTMENTS_LIST_RELOAD',
	BALANCE_ADJUSTMENTS_LIST_REFRESH           : 'BALANCE_ADJUSTMENTS_LIST_REFRESH',
	BALANCE_ADJUSTMENTS_LIST_UI_REFRESH        : 'BALANCE_ADJUSTMENTS_LIST_UI_REFRESH',
	BALANCE_ADJUSTMENTS_LIST_PAGINATION_REFRESH: 'BALANCE_ADJUSTMENTS_LIST_PAGINATION_REFRESH',

	listReload: (userID) => ({
		type: actions.BALANCE_ADJUSTMENTS_LIST_RELOAD,
		data: {
			userID,
		},
	}),
	listRefresh: list => ({
		type: actions.BALANCE_ADJUSTMENTS_LIST_REFRESH,
		data: {
			list,
		},
	}),
	uiRefresh: data => ({
		type: actions.BALANCE_ADJUSTMENTS_LIST_UI_REFRESH,
		data,
	}),
	paginationRefresh: data => ({
		type: actions.BALANCE_ADJUSTMENTS_LIST_PAGINATION_REFRESH,
		data,
	}),
};

export default actions;
