import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../utility';

export const fields = {
	id            : 'id',
	websititeID   : 'website_id',
	langID        : 'lang_id',
	name          : 'name',
	title         : 'title',
	answer        : 'answer',
	topicID       : 'topic_id',
	negativeRating: 'negative_rating',
	neutralRating : 'neutral_rating',
	positiveRating: 'positive_rating',
};

const listAdapter = createListAdapter();
const namesAdapter = createNamesAdapter();
const questionsAdapter = createQuestionsAdapter();

export function getListParams(pagination, sorting = null, websiteID) {

	const builder = new ParamsBuilder();

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('website_id', websiteID);

	builder.addValue('limit', pagination.itemsPerPage);
	builder.addValue('helpCenter', pagination.currenthelpCenter);
	builder.addValue('page', pagination.currentPage);

	const params = builder.biuldParams({});

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptTopicsList(rawData = [], langID) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.names = namesAdapter.adaptList(item.names);
		item.questions = questionsAdapter.adaptList(item.questions);
		const dafauldNames = item.names.find(item => item.langID === langID);
		item.name = dafauldNames ? dafauldNames.name : '';
	});
	const entities = createEntities(adaptedData);
	const listIDs = Object.keys(entities);

	const result = {
		listIDs,
		entities,
	};

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.arrayObject, 'names', fields.names);
	adapter.addField(rules.arrayObject, 'questions', fields.questions);
	return adapter;
}

export function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'topicID', fields.topicID);
	adapter.addField(rules.string, 'name', fields.name);
	return adapter;
}

export function createQuestionsAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'topicID', fields.topicID);
	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.number, 'negativeRating', fields.negativeRating);
	adapter.addField(rules.number, 'neutralRating', fields.neutralRating);
	adapter.addField(rules.number, 'positiveRating', fields.positiveRating);
	adapter.addField(rules.string, 'answer', fields.answer);
	return adapter;
}
