export const getItem = (idArr, listArr) => {
	const res = [];
	for (let i = 0; i < idArr.length; i++) {
		res.push(listArr.filter(item => item.id === idArr[i])[0]);
	}
	return res;
};


export const fieldNames = {
	STATUS         : 'STATUS',
	CATEGORY       : 'CATEGORY',
	CUSTOM_PROVIDER: 'CUSTOM_PROVIDER',
	TAGS           : 'TAGS',
	CURRENCY       : 'CURRENCY',
	COUNTRY        : 'COUNTRY',
};

export const showTooltipCount = 3;
