import ParamsBuilder from '../../helpers/paramsBuilder';

const fields = {
	from     : 'start_date',
	to       : 'end_date',
	websiteID: 'website_id',
};

export function createCommonParams(period, websiteID) {

	const filter = {
		...period,
		websiteID,
	};

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addField(rules.isID, 'websiteID', fields.websiteID);
	builder.addField(rules.isDate, 'from', fields.from);
	builder.addField(rules.isDate, 'to', fields.to);

	const params = builder.biuldParams(filter);

	return params;
}
