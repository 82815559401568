import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { casinoAPI } from '../../../helpers/api/casino';
import { showError, showSuccess } from '../../../helpers/notifications';

import { adaptGameCategoryList } from './utils';
import { logger } from '../../../helpers/logger';


const messages = {
	errorListLoad    : 'casino.gameCategories.error.list.load',
	errorItemDelete  : 'casino.gameCategories.error.item.delete',
	successItemDelete: 'casino.gameCategories.success.item.delete',
};

function getStoreData(state) {
	const { App, LanguageSwitcher, Casino } = state;
	const websiteID = App.get('websiteID');
	const langID = LanguageSwitcher.get('language').dataBaseLangID;
	const allCheckedCategories = Casino.GameCategories.get('allCheckedCategories');
	const oldDataChecked = Casino.GameCategories.get('oldDataChecked');

	return {
		websiteID,
		langID,
		allCheckedCategories,
		oldDataChecked,
	};
}

function* listReload() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		let entities = {};
		try {
			const res = yield call(casinoAPI.gameCategoryList);
			if (res && res.status === 200) {
				entities = adaptGameCategoryList(res.data.data);
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}
function* listReloadGames() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_LIST_RELOAD_GAMES, function* (currencyID) {
		const { websiteID, langID } = yield select(getStoreData);
		const params = {
			website_id : websiteID,
			lang_id    : langID,
			currency_id: currencyID.data,
		};

		yield put(actions.uiRefresh({ loading: true }));

		let entities = {};
		try {
			const res = yield call(casinoAPI.casinoGamesbyCategories, params);
			if (res && res.status === 200) {
				entities = adaptGameCategoryList(res.data.data);
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.listRefreshGames(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* deleteItem() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_LIST_DELETE_ITEM, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { websiteID } = yield select(getStoreData);
		const params = {
			website_id: websiteID,
		};
		const { gameCategoryID } = action.data;
		try {
			const res = yield call(casinoAPI.gameCategoryDelete, gameCategoryID, params);
			if (res && res.status === 200) {
				showSuccess(messages.successItemDelete);
				yield put(actions.listReload());
			}
		} catch (error) {
			showError(messages.errorItemDelete);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* checkedCategoryGames() {
	yield takeEvery(actions.CHECKED_CATEGORY_GAMES, function* (action) {
		if (!action.data.categoryID) {
			yield put(actions.checkedAll({}));
			return;
		}
		const { allCheckedCategories } = yield select(getStoreData);
		const { categoryID, games, checkedAll, into, checked  } = action.data;
		const cloneGame = allCheckedCategories[categoryID] ? allCheckedCategories[categoryID].games : [];
		let oldGames;

		if (checkedAll || into) {
			oldGames = games;
		} else  if (checked) {
			oldGames = cloneGame.concat(games);
		} else oldGames = cloneGame.filter(item => item !== games[0]);


		const obj = {
			[categoryID]: {
				games     : games.length ? [...new Set(oldGames)] : [],
				allChecked: checkedAll,
			},
		};

		yield put(actions.checkedAll({ ...allCheckedCategories, ...obj }));
	});
}

function* oldDataCheckedList() {
	yield takeEvery(actions.OLD_DATA_CHECKED_LIST, function* (action) {
		if (!action.data.categoryID) {
			yield put(actions.oldCheckedData({}));
			return;
		}
		const { oldDataChecked } = yield select(getStoreData);
		const { categoryID, games, checkedAll, checked  } = action.data;
		const { gameID } = action;
		const cloneGame = oldDataChecked[categoryID] ? oldDataChecked[categoryID].games : [];
		const addIDGameInOldGames = [...new Set([...games, gameID])];
		const newGames =  cloneGame.concat(games);

		let gamesTheSame = [];
		if (gameID && checked) {
			gamesTheSame = cloneGame.concat(addIDGameInOldGames);
		} else if (!checked) {
			gamesTheSame = newGames.filter(item => item !== gameID);
		} else {
			gamesTheSame = newGames;
		}

		const obj = {
			[categoryID]: {
				games     : games.length || gameID ? [...new Set(gamesTheSame)] : [],
				allChecked: checkedAll,
			},
		};

		yield put(actions.oldCheckedData({ ...oldDataChecked, ...obj }));

	});
}

export default function* casinoGameCategoryListSaga() {
	yield all([
		fork(listReload),
		fork(listReloadGames),
		fork(deleteItem),
		fork(checkedCategoryGames),
		fork(oldDataCheckedList),
	]);
}
