import { combineReducers } from 'redux';

import List from './list/reducer';
import Website from './website/reducer';
import Scripts from './scripts/reducer';
import SmsGateways from './smsgateways/reducer';
import Payments from './payments/reducer';
import Payways from './payways/reducer';
import Providers from './providers/slice';

export default combineReducers({
	List,
	Website,
	Payments,
	Payways,
	Scripts,
	SmsGateways,
	Providers,
});
