import React from 'react';
import PropTypes from 'prop-types';

// import UserLink from '../../../../containers/AppTabsManager/components/UserLink';
import Formatter from '../../../../helpers/formatter';
import {
	ListItem,
	ListItemHeader,
	ListItemDate,
	ListItemBody,
	ListItemRow,
} from '../NotificationBadge.style';

const ItemCasino = ({ id, date, stake, currencyCode, userName, gameName, userID, className, onClick, itemIdByType }) => {
	const name = `ID: ${id}, stake: ${stake} ${currencyCode}`;
	// const info = 'Customer: ';
	const info = `Customer: ${userName}`;
	const gameInfo = `Game: ${gameName}`;

	// const link = (
	//   <UserLink userID={userID} type="normal">
	//     {userName}
	//   </UserLink>
	// );

	const onCasinoClick = () => {
		onClick(userID, id, null, null, itemIdByType);
	};

	return (
		<ListItem className={`clickable ${className}`} onClick={onCasinoClick}>
			<ListItemHeader>
				{name}
				<ListItemDate>{Formatter.dateTime(date)}</ListItemDate>
			</ListItemHeader>
			<ListItemBody>
				<ListItemRow>{gameInfo}</ListItemRow>
				<ListItemRow>{info}</ListItemRow>
			</ListItemBody>
		</ListItem>
	);
};

ItemCasino.propTypes = {
	itemIdByType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	id          : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	date        : PropTypes.string,
	stake       : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	currencyCode: PropTypes.string,
	gameName    : PropTypes.string,
	userID      : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className   : PropTypes.string,
	onClick     : PropTypes.func,
	userName    : PropTypes.string,
};

ItemCasino.defaultProps = {
	id          : '',
	date        : '',
	stake       : '',
	currencyCode: '',
	gameName    : '',
	userID      : '',
	userName    : '',
	className   : '',
	onClick     : () => {},
};

export default ItemCasino;
