const actions = {

	WEBSITES_LIST_RELOAD : 'WEBSITES_LIST_RELOAD',
	WEBSITES_LIST_REFRESH: 'WEBSITES_LIST_REFRESH',

	WEBSITES_LIST_DELETE_ITEM: 'WEBSITES_LIST_DELETE_PAGE',

	WEBSITES_LIST_UI_REFRESH: 'WEBSITES_LIST_UI_REFRESH',

	listReload: () => ({
		type: actions.WEBSITES_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.WEBSITES_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	deleteWebsite: websiteID => ({
		type: actions.WEBSITES_LIST_DELETE_ITEM,
		data: {
			websiteID,
		},
	}),
	uiRefresh: data => ({
		type: actions.WEBSITES_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
