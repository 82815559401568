import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IInitSportRiskList, IEntities, IRiskBetsItem, IRiskUI } from './types';
import { fill } from '../../../../helpers/utils';


const initialState: IInitSportRiskList = {
	sportRiskData      : [],
	entities           : {},
	withDetailsEntities: {},
	betIDs             : [],
	marketIDs          : [],
	
	UI: {
		loading    : false,
		error      : false,
		updateRowID: -1,
	},
};

export const riskSportBetsListSlice = createSlice({
	name    : 'riskSportBetsList',
	initialState,
	reducers: {
		setRiskData: (state: Draft<IInitSportRiskList>, action: PayloadAction<IRiskBetsItem[]>) => {
			state.sportRiskData = action.payload;
		},
		setRiskEntities: (state: Draft<IInitSportRiskList>, action: PayloadAction<IEntities>) => {
			state.entities = action.payload;
		},
		setRiskWithDetailsEntities: (state: Draft<IInitSportRiskList>, action: PayloadAction<IEntities>) => {
			state.entities = action.payload;
		},
		setRiskBetIDs: (state: Draft<IInitSportRiskList>, action: PayloadAction<string[]>) => {
			state.betIDs = action.payload;
		},
		setRiskMarketIDs: (state: Draft<IInitSportRiskList>, action: PayloadAction<string[]>) => {
			state.marketIDs = action.payload;
		},
		riskDataRefresh: (state: Draft<IInitSportRiskList>, action: PayloadAction<IRiskBetsItem>) => {
			const target = state.sportRiskData;
			state.sportRiskData = fill(action.payload, target, true);
		},
		uiRefresh: (state: Draft<IInitSportRiskList>, action: PayloadAction<IRiskUI>) => {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},
		resetSportRiskData: (state: Draft<IInitSportRiskList>) => {
			state.sportRiskData = initialState.sportRiskData;
		},
	},
});


export const actions = {
	...riskSportBetsListSlice.actions,
};

export default riskSportBetsListSlice.reducer;

