
import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const RtmIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_8)">
				<path {...props} d="M16 9.97456V1.26822C16 0.751281 15.5794 0.330719 15.0625 0.330719H0.9375C0.420563 0.330719 0 0.751281 0 1.26822V9.97453H16V9.97456ZM13.6562 7.55219C13.9151 7.55219 14.125 7.76206 14.125 8.02094C14.125 8.27981 13.9151 8.48969 13.6562 8.48969H2.34375C2.08488 8.48969 1.875 8.27981 1.875 8.02094V2.13997C1.875 1.88109 2.08488 1.67122 2.34375 1.67122C2.60262 1.67122 2.8125 1.88109 2.8125 2.13997V6.88931L5.24097 4.46084C5.32888 4.37294 5.44809 4.32353 5.57241 4.32353C5.69675 4.32353 5.81594 4.37294 5.90387 4.46084L6.61775 5.17472L7.6355 4.15697C7.72341 4.06906 7.84262 4.01966 7.96697 4.01966C8.09131 4.01966 8.2105 4.06906 8.29844 4.15697L8.63587 4.49441L10.0916 1.90994C10.1769 1.75838 10.3389 1.66681 10.5131 1.67141C10.687 1.67628 10.8439 1.77703 10.9206 1.93313L12.1111 4.35431H13.6562C13.9151 4.35431 14.125 4.56419 14.125 4.82306C14.125 5.08194 13.9151 5.29181 13.6562 5.29181H11.8192C11.6405 5.29181 11.4774 5.19025 11.3985 5.02991L10.4718 3.14513L9.14956 5.49269C9.07763 5.62041 8.95006 5.70716 8.80481 5.72706C8.6595 5.74694 8.51334 5.69778 8.40969 5.59412L7.96697 5.15137L6.94922 6.16913C6.86131 6.25703 6.74209 6.30644 6.61775 6.30644C6.49341 6.30644 6.37422 6.25703 6.28631 6.16913L5.57244 5.45525L3.47544 7.55225H13.6562V7.55219Z" fill={color} />
				<path {...props} d="M15.0625 12.787C15.5794 12.787 16 12.3665 16 11.8495V10.912H0V11.8495C0 12.3665 0.420563 12.787 0.9375 12.787H15.0625Z" fill={color} />
				<path {...props} d="M5.03869 13.726L4.64797 14.7318H3.71875C3.45987 14.7318 3.25 14.9417 3.25 15.2005C3.25 15.4594 3.45987 15.6693 3.71875 15.6693H12.2815C12.5404 15.6693 12.7503 15.4594 12.7503 15.2005C12.7503 14.9417 12.5404 14.7318 12.2815 14.7318H11.3523L10.9616 13.726H5.03869Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_8">
					<rect width={width} height={height} />
				</clipPath>
			</defs>
		</svg>
	);
};
RtmIcon.propTypes = propTypesIcons;

RtmIcon.defaultProps = defaultPropsIcons;
