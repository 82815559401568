import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	list: [],
});

export default function selectionsListReducer(state = initState, action) {

	switch (action.type) {

		case actions.SELECTIONS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		default:
			return state;
	}
}
