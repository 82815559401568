import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import actions from './actions';
import betsActions from '../../../rtm/bets/actions';
import depositsActions from '../../../rtm/deposits/actions';
import withdrawalsActions from '../../../rtm/withdrawals/actions';
import casinoActions from '../../../rtm/casino/actions';

import { contentAPI } from '../../../../helpers/api/content';
import { storeTableSettings, clearTableSettings } from '../../../../helpers/utility';
import notifications from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';

const tableBetsName        = 'rtmBets';
const tableDepositsName    = 'rtmDeposits';
const tableWithdrawalsName = 'rtmWithdrawals';
const tableCasinoName      = 'rtmCasino';

const prefix = 'menu.tables';

const messages = {
	errorTableSettingsLoad  : `${prefix}.loading.settings.failed`,
	errorTableSettingsSave  : `${prefix}.saving.settings.failed`,
	successTableSettingsSave: `${prefix}.settings.saved.success`,
};

function getStoreData({ Auth, RTM }) {

	return {
		user              : Auth.get('user'),
		columnsBets       : RTM.Bets.get('visibleColumns'),
		columnsDeposits   : RTM.Deposits.get('visibleColumns'),
		columnsWithdrawals: RTM.Withdrawals.get('visibleColumns'),
		columnsCasino     : RTM.Casino.get('visibleColumns'),
	};
}

export function* ctsReload() {

	yield takeEvery(actions.CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_RELOAD, function* (action) {

		const { userID } = action.data;

		let settings = {
			bet       : null,
			deposit   : null,
			withdrawal: null,
			casino    : null,
		};

		try {
			const res = yield call(contentAPI.customizableTablesSettingsLoad, userID);
			if (res && res.status === 200) {
				settings = res.data.data;
			}

		} catch (error) {
			notifications.showWarning(messages.errorTableSettingsLoad);
			logger.error(error);
		}

		// Bets
		if (isArray(settings.bet)) {
			yield call(storeTableSettings, tableBetsName, settings.bet);
			yield put(betsActions.tableSettingsRefresh(settings.bet));
		} else {
			yield call(clearTableSettings, tableBetsName);
		}

		// Deposits
		if (isArray(settings.deposit)) {
			yield call(storeTableSettings, tableDepositsName, settings.deposit);
			yield put(depositsActions.tableSettingsRefresh(settings.deposit));
		} else {
			yield call(clearTableSettings, tableDepositsName);
		}

		// Withdrawals
		if (isArray(settings.withdrawal)) {
			yield call(storeTableSettings, tableWithdrawalsName, settings.withdrawal);
			yield put(withdrawalsActions.tableSettingsRefresh(settings.withdrawal));
		} else {
			yield call(clearTableSettings, tableWithdrawalsName);
		}

		// Casino
		if (isArray(settings.casino)) {
			yield call(storeTableSettings, tableCasinoName, settings.casino);
			yield put(casinoActions.tableSettingsRefresh(settings.casino));
		} else {
			yield call(clearTableSettings, tableCasinoName);
		}
	});
}

export function* ctsSave() {

	yield takeEvery(actions.CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_SAVE, function* () {

		const { user, columnsBets, columnsDeposits, columnsWithdrawals, columnsCasino } = yield select(getStoreData);
		const userID = user.id;

		const settings = {
			user_id: userID,
		};

		if (isArray(columnsBets)        && !isEmpty(columnsBets))        { settings.bet        = columnsBets; }
		if (isArray(columnsDeposits)    && !isEmpty(columnsDeposits))    { settings.deposit    = columnsDeposits; }
		if (isArray(columnsWithdrawals) && !isEmpty(columnsWithdrawals)) { settings.withdrawal = columnsWithdrawals; }
		if (isArray(columnsCasino)      && !isEmpty(columnsCasino))      { settings.casino     = columnsCasino; }

		try {
			const res = yield call(contentAPI.customizableTablesSettingsSave, userID, settings);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.succesTableSettingsSave);
			}

		} catch (error) {
			notifications.showWarning(messages.errorTableSettingsSave);
			logger.error(error);
		}
	});
}

export default function* contentTablesSaga() {
	yield all([
		fork(ctsReload),
		fork(ctsSave),
	]);
}
