import { EVENT_STATUS } from '../../../../constants/events';
import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';

const { isValidID, isValidString, isValidArray, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	eventID    : isValidID,
	sportID    : isValidID,
	countryID  : isValidID,
	leagueID   : isValidID,
	tradingMode: isValidID,
	eventName  : isValidString,
    
	providerListIDs: isValidArray,
	statusID       : isValidArray,
    
	dateRange: isValidDateRange,
};

export const defaultValuesEventsFilter = {
	sportID        : 0,
	countryID      : 0,
	leagueID       : 0,
	statusID       : [EVENT_STATUS.active],
	dateRange      : [],
	providerListIDs: [],
};