import { EVENT_TYPES } from '../codes';
import { emitEvent } from '../socket';

function subscribeToUpdate(messagesIDs) {

	const event = {
		subjectType: EVENT_TYPES.messageRequest,
		ids        : messagesIDs,
	};

	return emitEvent('subscribe', event);
}

export const socketsMessagesAPI = {
	subscribeToUpdate,
};
