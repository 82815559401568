const actions = {

	USER_CASINO_STATISTIC_DATA_RELOAD : 'USER_CASINO_STATISTIC_DATA_RELOAD',
	USER_CASINO_STATISTIC_REFRESH     : 'USER_CASINO_STATISTIC_REFRESH',
	USER_CASINO_STATISTIC_SET_VALUE_UI: 'USER_CASINO_STATISTIC_SET_VALUE_UI',

	dataReload: (userID) => ({
		type: actions.USER_CASINO_STATISTIC_DATA_RELOAD,
		data: {
			userID,
		},
	}),
	sportsListRefresh: list => ({
		type: actions.USER_CASINO_STATISTIC_REFRESH,
		data: list,
	}),
	setValueUI: (valueName, value) => ({
		type: actions.USER_CASINO_STATISTIC_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
};

export default actions;
