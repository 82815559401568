const actions = {

	CATEGORY_MAPPING_DATA_RESET: 'CATEGORY_MAPPING_DATA_RESET',
	CATEGORY_MAPPING_LIST_SAVE : 'CATEGORY_MAPPING_LIST_SAVE',

	CATEGORY_MAPPING_LIST_RELOAD : 'CATEGORY_MAPPING_LIST_RELOAD',
	CATEGORY_MAPPING_LIST_REFRESH: 'CATEGORY_MAPPING_LIST_REFRESH',

	CATEGORY_UNMAPPED_LIST_RELOAD : 'CATEGORY_UNMAPPED_LIST_RELOAD',
	CATEGORY_UNMAPPED_LIST_REFRESH: 'CATEGORY_UNMAPPED_LIST_REFRESH',

	CATEGORY_MAPPING_SET_PROVIDER_ID : 'CATEGORY_MAPPING_SET_PROVIDER_ID',
	CATEGORY_MAPPING_MODAL_UI_REFRESH: 'CATEGORY_MAPPING_MODAL_UI_REFRESH',

	dataReset: () => ({
		type: actions.CATEGORY_MAPPING_DATA_RESET,
	}),
	dataSave: () => ({
		type: actions.CATEGORY_MAPPING_LIST_SAVE,
	}),
	mappingListReload: (providerID, categoryID) => ({
		type: actions.CATEGORY_MAPPING_LIST_RELOAD,
		data: {
			providerID,
			categoryID,
		},
	}),
	unmappedListReload: (typeID, providerID, sportID) => ({
		type: actions.CATEGORY_UNMAPPED_LIST_RELOAD,
		data: {
			typeID,
			providerID,
			sportID,
		},
	}),
	mappingListRefresh: list => ({
		type: actions.CATEGORY_MAPPING_LIST_REFRESH,
		data: list,
	}),
	unmappedListRefresh: list => ({
		type: actions.CATEGORY_UNMAPPED_LIST_REFRESH,
		data: list,
	}),

	setProviderID: providerID => ({
		type: actions.CATEGORY_MAPPING_SET_PROVIDER_ID,
		data: {
			providerID,
		},
	}),
	modalUIRefresh: data => ({
		type: actions.CATEGORY_MAPPING_MODAL_UI_REFRESH,
		data,
	}),
};

export default actions;
