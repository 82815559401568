import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { isSuperAdmin } from '../../../helpers/permissions/utils';
import { derivePartnersList } from '../../../selectors/app';

import FormSelect from '../../FormControls/FormSelect';

import { actions as partnerActions } from '../../../redux/partner/partners/list/actions';

import { Wrapper } from './PartnerSelect.style';
import { LoadingOutlined } from '@ant-design/icons';

class PartnerSelect extends Component {

	static propTypes = {
		partnerID        : PropTypes.number,
		disabled         : PropTypes.bool,
		noAutocomplete   : PropTypes.bool,
		onChange         : PropTypes.func,
		onAutoComplete   : PropTypes.func,
		usePopupContainer: PropTypes.bool,
		readOnly         : PropTypes.bool,
		loading          : PropTypes.bool.isRequired,
		// Redux
		partnerList      : PropTypes.array,
	};

	static defaultProps = {
		partnerID        : null,
		disabled         : false,
		noAutocomplete   : false,
		partnerList      : [],
		onChange         : () => {},
		onAutoComplete   : () => {},
		usePopupContainer: true,
		readOnly         : true,
	};

	constructor(props) {
		super(props);
		this.timeout = null;
		this.makeOptionsList = this.makeOptionsList.bind(this);
		this.onChange        = this.onChange.bind(this);
	}

	componentDidMount() {
		const { partnerList, noAutocomplete, onAutoComplete } = this.props;
		// eslint-disable-next-line no-empty
		if (!isArray(partnerList) || isEmpty(partnerList)) {

		} else {
			const partner = partnerList[0];
			if (!noAutocomplete) {
				onAutoComplete(toInteger(partner.id));
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { partnerList, noAutocomplete, onAutoComplete } = this.props;
		const prevWebsiteList = prevProps.partnerList;
		if (isEqual(partnerList, prevWebsiteList)) {
			return;
		}

		if (isArray(partnerList) && !isEmpty(partnerList)) {
			const partner = partnerList[0];
			if (!noAutocomplete) {
				onAutoComplete(toInteger(partner.id));
			}
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	// Service --------------------------------------------------------------------------------------
	makeOptionsList() {
		const { partnerID, partnerList, loading } = this.props;
		if (isArray(partnerList) && !isEmpty(partnerList)) {

			return partnerList.map(partner => {
				partner.name = partner.partnerName;
				return partner;
			});
		}
		// in order to show loading before partner/ website response.
		if (loading && Number(partnerID) === 0) {
			return [{
				id  : partnerID,
				name: (<LoadingOutlined />),
			}];
		}

		if (loading) {
			return [{
				id  : partnerID,
				name: (<LoadingOutlined />),
			}];
		}

		if (!partnerID) {
			return  [{
				id         : partnerID,
				name       : 'No Partner',
				partnerName: 'No Partner',
			}];
		}

		return [{
			id  : 0,
			name: (<LoadingOutlined />),
		}];
	}

	// Events ---------------------------------------------------------------------------------------
	onChange(websiteID) {
		const { onChange } = this.props;
		onChange(toInteger(websiteID));
	}

	// Renders --------------------------------------------------------------------------------------

	render() {
		const { partnerID, readOnly, usePopupContainer, disabled, ...restProps } = this.props;
		const optionsList = this.makeOptionsList();
		const hasArrow = optionsList.length > 1;
		return (
			<Wrapper hasArrow={hasArrow}>
				<FormSelect
					labelWidth={0}
					value={partnerID}
					disabled={!hasArrow || disabled || readOnly}
					optionsList={optionsList}
					onChange={this.onChange}
					usePopupContainer = {usePopupContainer}
					{...restProps}
				/>
			</Wrapper>
		);
	}
}

function mapStateToProps(state) {
	const partnerList = derivePartnersList(state);
	const superAdmin = isSuperAdmin();
	const loading = state.Staff.Person.get('UI').partnerLoading;
	const disabled = partnerList.length === 1;
	return {
		partnerList,
		readOnly: (!superAdmin && disabled) || disabled || loading,
		loading,
		disabled,
	};
}

export default connect(mapStateToProps, {
	listReload: partnerActions.partnerListReload,
})(PartnerSelect);
