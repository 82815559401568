import React from 'react';
import PropTypes from 'prop-types';

import TabLink from '../../containers/AppTabsManager/components/TabLink';

import { siteConfig } from '../../config';
import { APP_TABS } from '../../constants/appTabs';
import { COMPONENTS } from '../../constants/components';

const linkProps = {
	className    : 'link',
	id           : APP_TABS.dashboard,
	titleID      : 'sidebar.dashboard',
	componentName: COMPONENTS.dashboard,
	location     : '/',
};

const Logo = ({ collapsed }) => (
	<div className="isoLogoWrapper">
		{collapsed
			? (
				<div>
					<h3>
						<TabLink {...linkProps}>
							<i className={siteConfig.siteIcon} />
						</TabLink>
					</h3>
				</div>
			)
			: (
				<h3>
					<TabLink {...linkProps}>
						{siteConfig.siteName}
					</TabLink>
				</h3>
			)
		}
	</div>
);

Logo.propTypes = {
	collapsed: PropTypes.bool,
};

Logo.defaultProps = {
	collapsed: false,
};

export default Logo;
