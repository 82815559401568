const actions = {

	SIDEBAR_SETTINGS_RELOAD      : 'SIDEBAR_SETTINGS_RELOAD',
	SIDEBAR_SETTINGS_SET         : 'SIDEBAR_SETTINGS_SET',
	SIDEBAR_SETTINGS_SAVE        : 'SIDEBAR_SETTINGS_SAVE',
	SIDEBAR_SETTINGS_REFRESH     : 'SIDEBAR_SETTINGS_REFRESH',
	SIDEBAR_SETTINGS_EDIT_REFRESH: 'SIDEBAR_SETTINGS_EDIT_REFRESH',
	SIDEBAR_SETTINGS_RESET       : 'SIDEBAR_SETTINGS_RESET',

	SIDEBAR_KEYS_REFRESH: 'SIDEBAR_KEYS_REFRESH',
	SIDEBAR_UI_REFRESH  : 'SIDEBAR_UI_REFRESH',

	SEARCH_MENU: 'SEARCH_MENU',

	sidebarReload: () => ({
		type: actions.SIDEBAR_SETTINGS_RELOAD,
	}),
	sidebarSave: () => ({
		type: actions.SIDEBAR_SETTINGS_SAVE,
	}),
	sidebarRefresh: data => ({
		type: actions.SIDEBAR_SETTINGS_REFRESH,
		data,
	}),
	sidebarKeysRefresh: data => ({
		type: actions.SIDEBAR_KEYS_REFRESH,
		data,
	}),
	sidebarEditRefresh: data => ({
		type: actions.SIDEBAR_SETTINGS_EDIT_REFRESH,
		data,
	}),
	sidebarReset: () => ({
		type: actions.SIDEBAR_SETTINGS_RESET,
	}),
	addDataInSearchMenu: data => ({
		type: actions.SEARCH_MENU,
		data,
	}),
	uiRefresh: data => ({
		type: actions.SIDEBAR_UI_REFRESH,
		data,
	}),
};
export default actions;
