import { makeActionCreator } from '../../../helpers';

const prefix = 'CASHBACK_USERS';

const TYPES = {
	LIST_RELOAD       : `${prefix}_LIST_RELOAD`,
	LIST_REFRESH      : `${prefix}_LIST_REFRESH`,
	FILTER_REFRESH    : `${prefix}_FILTER_REFRESH`,
	FILTER_APPLY      : `${prefix}_FILTER_APPLY`,
	FILTER_RESET      : `${prefix}_FILTER_RESET`,
	BONUS_SAVE        : `${prefix}_BONUS_SAVE`,
	BONUS_DATA_REFRESH: `${prefix}_BONUS_DATA_REFRESH`,
	BONUS_UI_REFRESH  : `${prefix}_BONUS_UI_REFRESH`,
};

const actions = {
	...TYPES,

	listReload   : makeActionCreator(TYPES.LIST_RELOAD),
	listRefresh  : makeActionCreator(TYPES.LIST_REFRESH, 'entities'),
	filterRefresh: makeActionCreator(TYPES.FILTER_REFRESH, 'filter'),
	filterApply  : makeActionCreator(TYPES.FILTER_APPLY),
	filterReset  : makeActionCreator(TYPES.FILTER_RESET),
	bonusSave    : makeActionCreator(TYPES.BONUS_SAVE, 'data', 'id'),
	dataRefresh  : makeActionCreator(TYPES.BONUS_DATA_REFRESH, 'bonusData'),
	uiRefresh    : makeActionCreator(TYPES.BONUS_UI_REFRESH, 'UI'),
};

export default actions;
