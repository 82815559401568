import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { actions } from './actions';
import { fill } from '../../../../../helpers/utils';

const initState = new Map({
	baseData: {
		smsGatewaysList: [],
		websiteID      : 1,
	},
	UI: {
		loading      : false,
		editMode     : false,
		isBaseChanged: false,
	},
});

const clonedState = cloneDeep(initState);

export default (state = clonedState, { type, data }) => {
	switch (type) {
		case actions.BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(data, target, true);
			return state.set('baseData', result);
		}
		case actions.UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(data, target, true);
			return state.set('UI', result);
		}
		case actions.STORE_RESET: {
			return initState;
		}
		default: {
			return state;
		}
	}
};
