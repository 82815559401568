const languageActions = {

	LANGUAGE_RELOAD_LIST : 'LANGUAGE_RELOAD_LIST',
	LANGUAGE_REFRESH_LIST: 'LANGUAGE_REFRESH_LIST',
	UI_REFRESH           : 'UI_REFRESH',


	languageListReload: () => ({
		type: languageActions.LANGUAGE_RELOAD_LIST,
	}),
	currencyListRefresh: list => ({
		type: languageActions.LANGUAGE_REFRESH_LIST,
		data: list,
	}),

	uiRefresh: (data) => {
		return {
			type: languageActions.UI_REFRESH,
			data,
		};
	},

};

export default languageActions;
