import { all, put, fork, call, select, takeEvery } from 'redux-saga/effects';
import notifications from '../../../helpers/notifications';
import actions from './actions';
import { dashboardAPI } from '../../../helpers/api/dashboard';
import { createCommonParams } from '../utils';
import { adaptWithdrawalsData } from './utils';

const prefix = 'dashboard.withdrawals';

const messages = {
	errorWithdrawalsData: `${prefix}.errorWithdrawalsData`,
};

function getStoreData({ Dashboard, App }) {
	return {
		period   : Dashboard.Common.get('period'),
		websiteID: App.get('websiteID'),
	};
}

function* withdrawalsDataReload() {

	yield takeEvery(actions.DASHBOARD_WITHDRAWALS_DATA_RELOAD, function* () {
		yield put(actions.withdrawalsUIRefresh({ loading: true, visible: false }));
		const { period, websiteID } = yield select(getStoreData);
		const params = createCommonParams(period, websiteID);
		let dataToStore = [];
		try {
			const response = yield call(dashboardAPI.playersWithdrawalsReq, params);
			if (response && response.status === 200) {
				dataToStore = adaptWithdrawalsData(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorWithdrawalsData, error);
		}
		yield put(actions.withdrawalsDataRefresh(dataToStore));
		yield put(actions.withdrawalsUIRefresh({ loading: false, visible: true }));
	});
}

export default function* withdrawalsDataSaga() {
	yield all([
		fork(withdrawalsDataReload),
	]);
}
