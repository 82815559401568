const actions = {

	DASHBOARD_PLACED_BETS_DATA_REFRESH: 'DASHBOARD_PLACED_BETS_DATA_REFRESH',
	DASHBOARD_PLACED_BETS_DATA_RELOAD : 'DASHBOARD_PLACED_BETS_DATA_RELOAD',

	placedBetsDataRefresh: data => ({
		type: actions.DASHBOARD_PLACED_BETS_DATA_REFRESH,
		data: {
			placedBetsData: data,
		},
	}),
	placedBetsDataReload: () => ({
		type: actions.DASHBOARD_PLACED_BETS_DATA_RELOAD,
	}),

};

export default actions;
