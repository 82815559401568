import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';

const { isValidID, isValidString, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	sportID           : isValidID,
	countryID         : isValidID,
	leagueID          : isValidID,
	statusID          : isValidID,
	resolutionStatusID: isValidID,
	tradingMode       : isValidID,
	eventName         : isValidString,
	date              : isValidDateRange,
};


export const defaultValuesResultsFilter = {
	sportID           : 0,
	countryID         : 0,
	leagueID          : 0,
	resolutionStatusID: 0,
	tradingMode       : 0,
};