const actions = {

	EVENT_MARKET_UPDATE                       : 'EVENT_MARKET_UPDATE',
	EVENT_MARKET_CANCEL                       : 'EVENT_MARKET_CANCEL',
	EVENT_MARKET_BASE_DATA_REFRESH            : 'EVENT_MARKET_BASE_DATA_REFRESH',
	EVENT_MARKET_SETTINGS_DATA_RELOAD         : 'EVENT_MARKET_SETTINGS_DATA_RELOAD',
	EVENT_MARKET_SETTINGS_DATA_REFRESH        : 'EVENT_MARKET_SETTINGS_DATA_REFRESH',
	EVENT_MARKET_DYNAMIC_LIMITS_DATA_RELOAD   : 'EVENT_MARKET_DYNAMIC_LIMITS_DATA_RELOAD',
	EVENT_MARKET_DYNAMIC_LIMITS_DATA_REFRESH  : 'EVENT_MARKET_DYNAMIC_LIMITS_DATA_REFRESH',
	EVENT_MARKET_SET_VALUE_UI                 : 'EVENT_MARKET_SET_VALUE_UI',
	EVENT_MARKET_DATA_RESET                   : 'EVENT_MARKET_DATA_RESET',
	EVENT_MARKET_STATUS_UPDATE                : 'EVENT_MARKET_STATUS_UPDATE',
	EVENT_MARKET_STATUS_UPDATE_WITH_SELECTIONS: 'EVENT_MARKET_STATUS_UPDATE_WITH_SELECTIONS',

	marketUpdate: (eventMarketID, closeModal) => ({
		type: actions.EVENT_MARKET_UPDATE,
		data: {
			eventMarketID,
			closeModal,
		},
	}),
	marketCancel: (eventID, eventMarketID) => ({
		type: actions.EVENT_MARKET_CANCEL,
		data: {
			eventID,
			eventMarketID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.EVENT_MARKET_BASE_DATA_REFRESH,
		data,
	}),
	settingsDataReload: eventMarketID => ({
		type: actions.EVENT_MARKET_SETTINGS_DATA_RELOAD,
		data: {
			eventMarketID,
		},
	}),
	settingsDataRefresh: data => ({
		type: actions.EVENT_MARKET_SETTINGS_DATA_REFRESH,
		data,
	}),
	dynamicLimitsDataReload: eventMarketID => ({
		type: actions.EVENT_MARKET_DYNAMIC_LIMITS_DATA_RELOAD,
		data: {
			eventMarketID,
		},
	}),
	dynamicLimitsDataRefresh: data => ({
		type: actions.EVENT_MARKET_DYNAMIC_LIMITS_DATA_REFRESH,
		data,
	}),
	setValueUI: (valueName, value) => ({
		type: actions.EVENT_MARKET_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	dataReset: () => ({
		type: actions.EVENT_MARKET_DATA_RESET,
	}),
	marketStatusUpdate: (eventMarketID, statusID, onSuccess = null) => ({
		type: actions.EVENT_MARKET_STATUS_UPDATE,
		data: {
			eventMarketID,
			statusID,
			onSuccess,
		},
	}),
	marketStatusUpdateWithSelections: (marketID, statusID, selections, onSuccess = null) => ({
		type: actions.EVENT_MARKET_STATUS_UPDATE_WITH_SELECTIONS,
		data: {
			marketID,
			statusID,
			selections,
			onSuccess,
		},
	}),
};

export default actions;
