import { all, fork, call, takeEvery } from 'redux-saga/effects';
import { watchMessages } from './watcher';

import internalSaga from './internalSaga';
import channelEmitter from './channelEmitter/saga';

function* externalSaga() {
	const channel = yield call(watchMessages);

	yield takeEvery(channel, function* (action) {
		yield all([
			fork(channelEmitter, action),
		]);
	});
}

export default function* socketSaga() {
	yield all([
		fork(externalSaga),
		fork(internalSaga),
	]);
}
