const actions = {
	USER_GROUP_DATA_RELOAD : 'USER_GROUP_DATA_RELOAD',
	USER_GROUP_DATA_REFRESH: 'USER_GROUP_DATA_REFRESH',

	dataReload: userID => ({
		type: actions.USER_GROUP_DATA_RELOAD,
		data: {
			userID,
		},
	}),

	dataRefresh: data => ({
		type: actions.USER_GROUP_DATA_REFRESH,
		data,
	}),

};

export default actions;
