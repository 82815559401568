import { fork, all, takeLatest, put } from 'redux-saga/effects';
import { actions as filterActions } from './actions';
import { actions } from '../list/actions';

function* filterApply() {
	yield takeLatest(filterActions.FILTER_APPLY, function* () {
		yield put(actions.partnerListReload());
	});
}

export default function* partnerFilterSaga() {
	yield all([
		fork(filterApply),
	]);
}
