export const SMS_GATEWAYS_STATUS = {
	active  : 0,
	inactive: 1,
};
export const SMS_GATEWAYS_PROVIDER_TYPE = {
	customProvider: 0,
};
export const SMS_GATEWAYS_AUTORIZATION = {
	applicationUrlencoded: 'application/x-www-from-urlencoded',
	applicationJson      : 'application/json',
	text                 : 'text/xml',
};
