import { RESPONSIBLE_STATUS_IDS, RESPONSIBLE_TYPE_IDS } from '../redux/users/userInfo/responsibleGambling/types';

export const RESPONSIBLE_STATUS_IDS_NAMES = {
	[RESPONSIBLE_STATUS_IDS.ACTIVE] : 'Active',
	[RESPONSIBLE_STATUS_IDS.DELETED]: 'Deleted',
	[RESPONSIBLE_STATUS_IDS.EXPIRED]: 'Expired',
};

export const RESPONSIBLE_TYPE_IDS_NAMES =  {
	[RESPONSIBLE_TYPE_IDS.TIME_OUT]      : 'Time Out',
	[RESPONSIBLE_TYPE_IDS.AVAILABLE_TIME]: 'Available Time',
	[RESPONSIBLE_TYPE_IDS.SELF_EXCLUSION]: 'Self exclusion',
	[RESPONSIBLE_TYPE_IDS.DEPOSIT_LIMIT] : 'Deposit Limit',
};
