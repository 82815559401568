import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { fields, getListParams } from '../bets/utils';

export const availableFields = [
	'betID',
	'userID',
	'userName',
	'websiteID',
	'channelID',
	'userCountryID',
	'userStatusID',
	'userGroupID',
	'userBetFactor',
	'betStatusID',
	'betIP',
	'betCurrencyId',
	'betIPCountry',
	'betNumber',
	'betPlacementTime',
	'betStake',
	'betStakeUSD',
	'betTotalPrice',
	'betTradingModeID',
	'betTypeID',
	'maxPossiblePayout',
	'maxPossiblePayoutUSD',
	'maxPossibleWinning',
	'maxPossibleWinningUSD',
	'code',
	'priceProviderID',
	'settlementProviderID',
	'sportName',
	'countryName',
	'leagueName',
	'eventName',
	'marketName',
	'selectionName',
	'betSettlementTime',
	'eventStartingTime',
	'userBalanceBefore',
	'userBalanceAfter',
	'userBalanceAfterSettlement',
	'userBalanceBeforeUSD',
	'userBalanceAfterUSD',
	'userBalanceAfterSettlementUSD',
];

export function createReportParams(filter, sorting, reportFields) {

	const params  = getListParams(filter, sorting);
	params.fields = getReportFields(reportFields);

	return params;
}

// Service ----------------------------------------------------------------------------------------

function getReportFields(reportFields) {

	if (!isArray(reportFields) || isEmpty(reportFields)) {
		return availableFields.map(key => fields[key]);
	}

	const result = [];
	reportFields.forEach(field => {
		const { id, checked } = field;
		if (checked) {
			result.push(fields[id]);
		}
	});

	return result;
}
