const actions = {

	REPORT_WITHDRAWALS_DATA_RELOAD   : 'REPORT_WITHDRAWALS_DATA_RELOAD',
	REPORT_WITHDRAWALS_DATA_REFRESH  : 'REPORT_WITHDRAWALS_DATA_REFRESH',
	REPORT_WITHDRAWALS_RECALCULATE   : 'REPORT_WITHDRAWALS_RECALCULATE',
	REPORT_WITHDRAWALS_FIELDS_REFRESH: 'REPORT_WITHDRAWALS_FIELDS_REFRESH',
	REPORT_WITHDRAWALS_UI_REFRESH    : 'REPORT_WITHDRAWALS_UI_REFRESH',

	dataReload: (useUserFilter) => ({
		type: actions.REPORT_WITHDRAWALS_DATA_RELOAD,
		data: {
			useUserFilter,
		},
	}),
	dataRefresh: data => ({
		type: actions.REPORT_WITHDRAWALS_DATA_REFRESH,
		data,
	}),
	dataRecalculate: () => ({
		type: actions.REPORT_WITHDRAWALS_RECALCULATE,
	}),
	fieldsRefresh: data => ({
		type: actions.REPORT_WITHDRAWALS_FIELDS_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.REPORT_WITHDRAWALS_UI_REFRESH,
		data,
	}),
};

export default actions;
