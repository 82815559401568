import { combineReducers } from 'redux';
import Filter from './filter/reducer';
import List from './list/reducer';
import Modal from './modal/reducer';

export default combineReducers({
	Filter,
	List,
	Modal,
});
