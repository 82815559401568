import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import filterSaga from './filter/saga';
import participantSaga from './participant/saga';
import memberSaga from './member/saga';
import agesSaga from './ages/saga';
import mappingSaga from './mapping/saga';
import participantHistorySaga from './participantHistory/saga';

export default function* participantsSaga() {
	yield all([
		fork(listSaga),
		fork(filterSaga),
		fork(participantSaga),
		fork(memberSaga),
		fork(agesSaga),
		fork(mappingSaga),
		fork(participantHistorySaga),
	]);
}
