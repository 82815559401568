import { all, fork, put, select } from 'redux-saga/effects';

import socketActions from '../actions';
import betsActions from '../../rtm/bets/actions';
import depositsActions from '../../rtm/deposits/actions';
import documentsActions from '../../rtm/documents/actions';
import riskActions from '../../rtm/riskManagement/actions';
import withdrawalsActions from '../../rtm/withdrawals/actions';
import casinoActions from '../../rtm/casino/actions';
import messagesActions from '../../notificationCenter/messages/actions';
import eventActions from '../../sport/events/event/actions';
import notificationActions from '../../notifications/actions';
import userDocumentsActions from '../../users/documents/actions';
import actionsSportRiskList from '../../sportRiskManagement/sportBets/list/actions';

//import userDocumentsActions from '../../users/documents/actions';

const rtmTabs = [
	'rtm/bets',
	'rtm/deposits',
	'rtm/withdrawals',
	'rtm/casino',
	'rtm/documents',
	'rtm/risk-alerts',
	'messages',
];

function isOpenedRTM({ AppTabs }) {
	const activeTabID = AppTabs.get('activeTabID');
	return rtmTabs.includes(activeTabID);
}

function* channelEmitter(action) {
	const  eventActionsList = [socketActions.SOCKET_NOTIFY_UPDATE_EVENT_MARKETS_STATISTICS, socketActions.SOCKET_NOTIFY_UPDATE_EVENT_STATISTICS];
	const notificationActionsList = [
		socketActions.SOCKET_NOTIFY_CREATE_NOTIFICATION_RISK,
		socketActions.SOCKET_NOTIFY_UPDATE_NOTIFICATION_RISK,
		socketActions.SOCKET_NOTIFY_CREATE_USER_DOCUMENT,
		socketActions.SOCKET_NOTIFY_UPDATE_USER_DOCUMENT,
		socketActions.SOCKET_NOTIFY_CREATE_NOTIFICATION_DEPOSIT,
		socketActions.SOCKET_NOTIFY_CREATE_NOTIFICATION_CASINO,
		socketActions.SOCKET_NOTIFY_CREATE_NOTIFICATION_WITHDRAWAL,
		socketActions.SOCKET_NOTIFY_UPDATE_NOTIFICATION_WITHDRAWAL,
	];

	const riskManagementBetsList = [
		socketActions.SOCKET_RISK_MANAGEMENT_MESSAGE_UPDATE,
		socketActions.SOCKET_RISK_MANAGEMENT_MESSAGE_LIST_REFRESH,
		socketActions.SOCKET_RISK_MANAGEMENT_MARKET_IDS_UPDATE,
	];
	const isRTM = yield select(isOpenedRTM);
	if (!isRTM && !eventActionsList.includes(action.type) && !notificationActionsList.includes(action.type) && !riskManagementBetsList.includes(action.type)) {
		return;
	}

	switch (action.type) {

		case socketActions.SOCKET_SET_CONNECTION_STATUS:
			yield put(action);
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_BET:
			yield put(betsActions.notifyCreateBet(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_BET:
			yield put(betsActions.notifyUpdateBet(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_DEPOSIT:
			yield put(depositsActions.notifyCreateDeposit(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_DEPOSIT:
			yield put(depositsActions.notifyUpdateDeposit(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_DOCUMENT:
			yield put(documentsActions.notifyCreateDocument(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_DOCUMENT:
			yield put(documentsActions.notifyUpdateDocument(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_RISK:
			yield put(riskActions.notifyCreateRisk(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_RISK:
			yield put(riskActions.notifyUpdateRisk(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_NOTIFICATION_RISK:
			yield put(notificationActions.notifyCreateRisk(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_NOTIFICATION_RISK:
			yield put(notificationActions.notifyUpdateRisk(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_NOTIFICATION_DEPOSIT:
			yield put(notificationActions.notifyCreateDeposit(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_NOTIFICATION_CASINO:
			yield put(notificationActions.notifyCreateCasino(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_NOTIFICATION_WITHDRAWAL:
			yield put(notificationActions.notifyCreateWithdrawal(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_NOTIFICATION_WITHDRAWAL:
			yield put(notificationActions.notifyUpdateWithdrawal(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_WITHDRAWAL:
			yield put(withdrawalsActions.notifyCreateWithdrawal(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_WITHDRAWAL:
			yield put(withdrawalsActions.notifyUpdateWithdrawal(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_CASINO_TRANSACTION:
			yield put(casinoActions.notifyCreateTransaction(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_CASINO_TRANSACTION:
			yield put(casinoActions.notifyUpdateTransaction(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_MESSAGES:
			yield put(messagesActions.notifyCreateMessages(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_MESSAGES:
			yield put(messagesActions.notifyUpdateMessages(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_UPDATE_EVENT_MARKETS_STATISTICS:
			yield put(eventActions.notifyEventMarketsStatisticsUpdate(action.data));
			break;
		case socketActions.SOCKET_NOTIFY_UPDATE_EVENT_STATISTIC:
			yield put(eventActions.notifyEventStatisticUpdate(action.data));
			break;

		case socketActions.SOCKET_NOTIFY_CREATE_USER_DOCUMENT:
			yield put(userDocumentsActions.notifyCreateDocument(action.data));
			break;
		case socketActions.SOCKET_NOTIFY_UPDATE_USER_DOCUMENT:
			yield put(userDocumentsActions.notifyUpdateDocument(action.data));
			break;

		case socketActions.SOCKET_RISK_MANAGEMENT_MESSAGE_UPDATE:
			yield put(actionsSportRiskList.socketUpdateData(action.data));
			break;

		case socketActions.SOCKET_RISK_MANAGEMENT_MESSAGE_LIST_REFRESH:
			yield put(actionsSportRiskList.socketRefreshList(action.data));
			break;
			
		case socketActions.SOCKET_RISK_MANAGEMENT_MARKET_IDS_UPDATE:
			yield put(actionsSportRiskList.socketUpdateMarketIDs(action.data));
			break;
				

		default:
			break;
	}
}

export default function* (action) {
	yield all([
		fork(channelEmitter, action),
	]);
}
