import { Map } from 'immutable';
import uniq from 'lodash/uniq';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({

	list      : [],
	changedIDs: [],

	filter: createFilter(filtersURL.ladders, initFilterTypes),

	UI: {
		loading: false,
	},
});

export default function marketsLaddersReducer(state = initState, action) {

	switch (action.type) {

		case actions.MARKETS_LADDERS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.MARKETS_LADDERS_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.MARKETS_LADDERS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.MARKETS_LADDERS_CHANGED_ID_ADD: {
			const { ladderID } = action.data;
			const changedIDs = state.get('changedIDs');
			const result = uniq([...changedIDs, ladderID]);

			return state.set('changedIDs', result);
		}

		case actions.MARKETS_LADDERS_CHANGED_IDS_RESET: {
			return state.set('changedIDs', []);
		}

		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
