import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import actions from './actions';
import { usersAPI } from '../../helpers/api/users';
import { showError } from '../../helpers/notifications';

const prefix = 'customerIP';

const messages = {
	errorDataLoad: `${prefix}.errorDataLoad`,
};


function* dataReload() {

	yield takeEvery(actions.DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { ip } = action.data;
		const ipData = { usersList: [] };
		try {
			const res = yield call(usersAPI.usersListByIP, ip);
			ipData.usersList = res.data.data;

		} catch (error) {
			showError(messages.errorDataLoad, error);
		}

		yield put(actions.dataRefresh(ip, ipData));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* customerIPSaga() {
	yield all([
		fork(dataReload),
	]);
}
