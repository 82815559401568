import { all, fork } from 'redux-saga/effects';

import getIntegratorsWithProviders from './partner/saga';
import partnerCountriesSaga from './countries/saga';

export default function* () {
	yield all([
		fork(getIntegratorsWithProviders),
		fork(partnerCountriesSaga),
	]);
}
