import EntityAdapter from '../../helpers/entityAdapter';


export const fields = {
	id                       : 'id',
	name                     : 'name',
	riskGroup                : 'risk_group',
	riskType                 : 'risk_type',
	priority                 : 'priority',
	amount                   : 'amount',
	settingsForRiskManagement: 'settings_for_risk_management',
	riskManagementId         : 'risk_management_id',
	currencyID               : 'currency_id',
	settings                 : 'settings',
};

const riskTypesAdapter = createRiskTypesListAdapter();
const riskAdapter = createRiskAdapter();


export function prepareRisks(rawData = []) {
	riskAdapter.clearExcludes();
	const adaptedData = riskAdapter.prepareList(rawData);
	return adaptedData;
}
// Adapt ------------------------------------------------------------------------------------------
export function adaptSettingsRisks(rawData = []) {
	riskAdapter.clearExcludes();
	const adaptedData = riskAdapter.adaptList(rawData);
	return adaptedData;
}

export function adaptRiskTypesList(rawData = []) {
	riskTypesAdapter.clearExcludes();
	const adaptedData = riskTypesAdapter.adapt(rawData);
	return adaptedData;
}

export function prepareSettings(sourse) {
	const settings = {
		list : [],
		risks: {},
	};
	const { list, risks } = settings;
	sourse.forEach(item => {
		const { id } = item;
		risks[id] = adaptSettingsRisks(item.settings_for_risk_management);
		delete item.settings_for_risk_management;
		const listItem = adaptRiskTypesList(item);
		list.push(listItem);
	});
	return settings;
}

// Adapters ---------------------------------------------------------------------------------------

export function createRiskTypesListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.arrayNumber, 'riskGroup', fields.riskGroup);
	adapter.addField(rules.number, 'riskType', fields.riskType);
	adapter.addField(rules.number, 'priority', fields.priority);
	adapter.addField(rules.object, 'settings', fields.settings);
	adapter.addField(rules.arrayObject, 'settingsForRiskManagement', fields.settingsForRiskManagement);


	return adapter;
}

// amount
export function createRiskAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	// adapter.addField(rules.id, 'id', fields.id);
	// adapter.addField(rules.id, 'riskManagementId', fields.riskManagementId);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.object, 'amount', fields.settings);
	return adapter;
}