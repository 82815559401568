import EntityAdapter from '../../../helpers/entityAdapter';

export const fields = {
	id            : 'provider_id',
	name          : 'name',
	alias         : 'alias',
	logo          : 'logo_url',
	integratorID  : 'integrator_id',
	// auth
	casinoID      : 'casino_id',
	authIP        : 'authorization_ip',
	// names
	channelID     : 'channel_id',
	langID        : 'lang_id',
	channel       : 'channel',
	altName       : 'alt_name',
	description   : 'description',
	websiteID     : 'website_id',
	orderNumber   : 'order_number',
	integratorName: 'integrator_name',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptCasinoList(rawData = []) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);
	return adaptedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'type', fields.type);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'logo', fields.logo);
	adapter.addField(rules.string, 'websiteID', fields.websiteID);
	adapter.addField(rules.string, 'integratorName', fields.integratorName);
	adapter.addField(rules.number, 'integratorID', fields.integratorID);
	adapter.addField(rules.number, 'orderNumber', fields.orderNumber);

	return adapter;
}
