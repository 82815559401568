import { all, fork } from 'redux-saga/effects';

import bonuses from './bonuses/saga';
import userBonusSaga from './userBonus/saga';
import firstDepositSaga from './firstDeposit/saga';
import welcomeBonusSaga from './welcomeBonus/saga';
import casinoCashbackSaga from './cashback/saga';
import userCasinoBonusesSaga from './userCasinoBonuses/saga';
import userCasinoFreeSpinsSaga from './userCasinoFreeSpins/saga';
import userFreeBetsSagas from './userFreeBets/saga';

export default function* bonusesRootSaga() {
	yield all([
		fork(bonuses),
		fork(userBonusSaga),
		fork(firstDepositSaga),
		fork(welcomeBonusSaga),
		fork(casinoCashbackSaga),
		fork(userCasinoBonusesSaga),
		fork(userCasinoFreeSpinsSaga),
		fork(userFreeBetsSagas),
	]);
}
