const actions = {

	MEMBER_BASE_DATA_RELOAD : 'MEMBER_BASE_DATA_RELOAD',
	MEMBER_BASE_DATA_REFRESH: 'MEMBER_BASE_DATA_REFRESH',
	MEMBER_UPDATE  		       : 'MEMBER_UPDATE',
	MEMBER_MODAL_UI_REFRESH : 'MEMBER_MODAL_UI_REFRESH',

	baseDataReload: memberID => ({
		type: actions.MEMBER_BASE_DATA_RELOAD,
		data: {
			memberID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.MEMBER_BASE_DATA_REFRESH,
		data,
	}),
	memberUpdate: () => ({
		type: actions.MEMBER_UPDATE,
	}),
	modalUIRefresh: data => ({ // ui refresh
		type: actions.MEMBER_MODAL_UI_REFRESH,
		data,
	}),
};

export default actions;
