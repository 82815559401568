import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';
import { cloneDeep } from 'lodash';

const initState = new Map({

	entities: {},
	filter  : createFilter(filtersURL.smsList, initFilterTypes),
	UI      : {
		loading: false,
	},

});

export default function smsListReducer(state = initState, action) {

	switch (action.type) {

		case actions.LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target, true);
			return state.set('UI', result);
		}

		case actions.LIST_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}

		case actions.DATA_RESET: {
			const target = cloneDeep(initState);
			target.set('filter', state.get('filter'));
			return target;
		}

		default:
			return state;
	}
}
