import EntityAdapter from '../../helpers/entityAdapter';

export const fields = {
	id                     : 'id',
	weeklyLimit            : 'weekly_limit',
	depositPart            : 'deposit_part',
	cleanIpHours           : 'clean_ip_hours',
	websiteId              : 'website_id',
	name                   : 'name',
	autoWithdrawalSettingID: 'auto_withdrawal_setting_id',
	currencyID             : 'currency_id',
};

export function createLimitAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'autoWithdrawalSettingID', fields.autoWithdrawalSettingID);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.number, 'weeklyLimit', fields.weeklyLimit);
	adapter.addField(rules.number, 'depositPart', fields.depositPart);
	return adapter;
}

export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.number, 'websiteId', fields.websiteId);
	adapter.addField(rules.number, 'cleanIpHours', fields.cleanIpHours);
	adapter.addField(rules.string, 'name', fields.name);
	return adapter;
}

const listAdapter = createListAdapter();
const limitsAdapter = createLimitAdapter();

export function prepareLimits(rawData = []) {
	limitsAdapter.clearExcludes();
	limitsAdapter.addExcludeField('id');
	limitsAdapter.addExcludeField('autoWithdrawalSettingID');
	const adaptedData = limitsAdapter.prepareList(rawData);

	return adaptedData;
}

export function adaptSettingsLimits(rawData = []) {
	limitsAdapter.clearExcludes();
	const adaptedData = limitsAdapter.adaptList(rawData);
	return adaptedData;
}

export function adaptSettingsList(rawData = []) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);
	return adaptedData;
}

export function prepareSettings(sourse) {
	const settings = {
		list  : [],
		limits: {},
	};
	const { list, limits } = settings;
	sourse.forEach(item => {
		const { id } = item;
		limits[id] = adaptSettingsLimits(item.auto_withdrawal_limits);
		delete item.auto_withdrawal_limits;
		const listItem = adaptSettingsList(item);
		list.push(listItem);
	});
	return settings;
}
