import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hammer from 'react-hammerjs';
import { StyledModal } from './DraggableModal.style';

class DraggableModal extends Component {
	titleRef;

	modal;

	shiftX;

	shiftY;

	state = {
		style: {},
	};

	static propTypes = {
		styleModal: PropTypes.object,
		visible   : PropTypes.bool.isRequired,
		title     : PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.element,
		]),
		footer: PropTypes.element,
		width : PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]).isRequired,
	};

	static defaultProps = {
		title     : null,
		footer    : null,
		width     : '',
		styleModal: {},
	};

	constructor(props) {
		super(props);

		this.onPanStart = this.onPanStart.bind(this);
		this.onPan = this.onPan.bind(this);
	}


	onPanStart({ center }) {
		this.modal = this.titleRef.parentNode.parentElement.parentElement.parentElement;
		const rect = this.modal.getBoundingClientRect();

		this.shiftX = center.x - rect.left;
		this.shiftY = center.y - rect.top;
	}

	onPan({ center }) {
		const { style } = this.state;
		const left = center.x - this.shiftX;
		const top = center.y - this.shiftY;

		if (left > 0) {
			this.setState({
				style: {
					transform: 'translateX(0)',
					left     : left > 0 && left < window.innerWidth - this.modal.offsetWidth ? left : style.left,
					top      : top > 0 && top < window.innerHeight - this.modal.offsetHeight ? top : style.top,
				},
			});
		}

	}

	render() {
		const { children, title, footer, visible, width, styleModal, ...restProps } = this.props;
		const { style } = this.state;

		const modalProps = {};

		if (footer) {
			modalProps.footer = footer;
		}

		return (
			<StyledModal
				{...restProps}
				destroyOnClose
				open={visible}
				title={(
					<div ref={elem => { this.titleRef = elem; }}>
						<Hammer onPanStart={this.onPanStart} onPan={this.onPan}>
							<div style={{ cursor: 'move' }}>{title}</div>
						</Hammer>
					</div>
				)}
				{...modalProps}
				width={width}
				style={{
					left: `calc((100% - ${width}) / 2)`,
					...style,
					...styleModal,
				}}
			>
				{children}
			</StyledModal>
		);
	}
}

export default DraggableModal;
