import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import actions from './actions';
import listActions from '../list/actions';

import { participantsAPI } from '../../../../helpers/api/participants';
import notifications from '../../../../helpers/notifications';

import { adaptBaseData, prepareBaseData, prepareTranslate } from './utils';
import { logger } from '../../../../helpers/logger';

const prefix = 'participants.member';

const messages = {
	errorBaseDataLoad       : `${prefix}.errorBaseDataLoad`,
	errorMemberUpdate       : `${prefix}.errorMemberUpdate`,
	errorMemberAdd          : `${prefix}.errorMemberAdd`,
	errorTranslationUpdate  : `${prefix}.errorTranslationUpdate`,
	successMemberUpdate     : `${prefix}.successMemberUpdate`,
	successMemberAdd        : `${prefix}.successMemberAdd`,
	successTranslationUpdate: `${prefix}.successTranslationUpdate`,
};
// Service ----------------------------------------------------------------------------------------

function getStoreData(state) {

	return {
		baseData: state.Sport.Participants.Member.get('baseData'),
		modalUI : state.Sport.Participants.Member.get('modalUI'),
	};
}

// Saga -------------------------------------------------------------------------------------------

function* baseDataReload() {

	yield takeEvery(actions.MEMBER_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const { memberID } = action.data;
		const { modalUI } = storeData;

		const params = { lang_id: modalUI.langID };
		try {
			const resBaseData = yield call(participantsAPI.memberData, memberID);
			const resTranslate = yield call(participantsAPI.memberTranslationList, memberID, params);

			if (
				resBaseData
        && resBaseData.status === 200
        && (resTranslate && resTranslate.status === 200)
			) {
				const rawBaseData = resBaseData.data.data;
				const rawTranslate = resTranslate.data.data;

				const baseData = adaptBaseData(rawBaseData, rawTranslate);

				yield put(actions.baseDataRefresh(baseData));
			}
		} catch (error) {
			notifications.showError(messages.errorBaseDataLoad, error);
			logger.log(error);
		}

		yield put(actions.modalUIRefresh({
			loading             : false,
			isBaseChanged       : false,
			isTranslationChanged: false,
		}));
	});
}

function* memberUpdate() {

	yield takeEvery(actions.MEMBER_UPDATE, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const { baseData, modalUI } = storeData;
		const {
			editMode,
			langID,
			isTranslationChanged,
			closeModal,
		} = modalUI;

		let { memberID } = modalUI;
		let isError = false;
		let errorMessage = '';

		try {
			// base data
			const preparedBaseData = prepareBaseData(baseData, memberID);
			if (editMode) {
				errorMessage = messages.errorMemberUpdate;
				yield call(participantsAPI.memberUpdate, memberID, preparedBaseData);

			} else {
				errorMessage = messages.errorMemberAdd;
				const response = yield call(participantsAPI.memberAdd, preparedBaseData);
				memberID = toInteger(response.data.data.id);
				yield put(actions.modalUIRefresh({ memberID, editMode: true }));
			}

			// translation
			if (isTranslationChanged) {
				errorMessage = messages.errorTranslationUpdate;
				const preparedTranslate = prepareTranslate(baseData, memberID, langID);
				yield call(participantsAPI.memberTranslationUpdate, memberID, preparedTranslate);
				yield put(actions.modalUIRefresh({ isTranslationChanged: false }));
			}

			notifications.showSuccess(messages.successMemberUpdate);

		} catch (error) {
			notifications.showError(errorMessage, error);
			isError = true;
			logger.log(error);
		}

		yield put(listActions.listReload());

		if (!closeModal) {
			yield put(actions.baseDataReload(memberID));
		}

		yield put(actions.modalUIRefresh({
			loading             : false,
			isBaseChanged       : false,
			isTranslationChanged: false,
			visible             : !(closeModal && !isError),
		}));
	});
}

export default function* memberSaga() {
	yield all([
		fork(baseDataReload),
		fork(memberUpdate),
	]);
}
