import { apiRequest } from './index';

/* ------------------------------------------------------------------------------------------------
	Periods
------------------------------------------------------------------------------------------------ */

// GET: scopes list
function scopeList(sportID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/scopes',
		params: {
			...additionalParams,
			sport_id: sportID,
		},
	};
	return apiRequest(req);
}

// POST: add scope
function scopeAdd(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/scope',
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// POST: update scope
function scopeUpdate(scopeID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/scope/${scopeID}`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// POST: update all scopes
function scopeUpdateAll(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/scopes',
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// DELETE: delete scope
function scopeDelete(scopeID) {
	const req = {
		method: 'DELETE',
		url   : `/scope/${scopeID}`,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
	Statistic Types
------------------------------------------------------------------------------------------------ */

// GET: statistic types
function statisticTypeList(sportID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/statisticTypes',
		params: {
			...additionalParams,
			sport_id: sportID,
		},
	};
	return apiRequest(req);
}

// POST: add statistic type
function statisticTypeAdd(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/statisticType',
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// POST: update statistic type
function statisticTypeUpdate(typeID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/statisticType/${typeID}`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// POST: update all statistic types
function statisticTypeUpdateAll(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/statisticTypes',
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// DELETE: delete scope
function statisticTypeDelete(typeID) {
	const req = {
		method: 'DELETE',
		url   : `/statisticType/${typeID}`,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
	Selection Rules
------------------------------------------------------------------------------------------------ */

// GET: rules list
function rulesList(marketID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/rules',
		params: {
			...additionalParams,
			market_id: marketID,
		},
	};
	return apiRequest(req);
}

// POST: add/update rules list
function rulesUpdate(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/rules',
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// DELETE: delete rules
function rulesDelete(rulesList, additionalParams = {}) {
	const req = {
		method: 'DELETE',
		url   : '/rules',
		params: { ...additionalParams },
		data  : rulesList,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
	Export
------------------------------------------------------------------------------------------------ */

export const settingsAPI = {

	// Scope
	scopeList,
	scopeAdd,
	scopeUpdate,
	scopeUpdateAll,
	scopeDelete,

	// Statistic Types
	statisticTypeList,
	statisticTypeAdd,
	statisticTypeUpdate,
	statisticTypeUpdateAll,
	statisticTypeDelete,

	// Selection Rules
	rulesList,
	rulesUpdate,
	rulesDelete,
};
