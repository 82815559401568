import { Map } from 'immutable';
import { liveStatisticTypesFilterActions } from './actions';
import { createFilter, filtersURL } from '../../../../helpers/utils';
import { defaultValuesLiveStatisticFilter, initFilterTypes } from './utils';

const initState = new Map({
	filter: createFilter(filtersURL.liveStatisticTypes, initFilterTypes, defaultValuesLiveStatisticFilter),
});

export default function LSTFilterReducer(state = initState, action) {

	switch (action.type) {

		case liveStatisticTypesFilterActions.LST_FILTER_SET_VALUE: {
			const { valueName, value } = action.data;
			const filter = new Map(state.get('filter')).toObject();
			filter[valueName] = value;

			return state.set('filter', filter);
		}

		case liveStatisticTypesFilterActions.LST_CLEAR_FILTERS: {
			return state.set('filter', defaultValuesLiveStatisticFilter);
		}
		
		default:
			return state;
	}
}
