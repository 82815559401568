import toInteger from 'lodash/toInteger';
import { isID } from '../../../../../helpers/utils';
import { fields, createListAdapter } from '../list/utils';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../utility';

const listAdapter = createListAdapter();
const revisionAdapter = createRevisionAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptInfoPage(rawData = {}) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

export function adaptRevisionsList(rawData = []) {
	let activeRevisionID = null;
	revisionAdapter.clearExcludes();

	const adaptedData = revisionAdapter.adaptList(rawData);

	adaptedData.forEach(revision => {
		revision.page = adaptInfoPage(revision.page);
		if (revision.active) {
			activeRevisionID = revision.id;
		}
	});

	const revisionsEntities = createEntities(adaptedData);

	return {
		revisionsEntities,
		activeRevisionID,
	};
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareInfoPage(baseData, langID) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(baseData);

	preparedData[fields.langID] = langID;
	if (isID(baseData.id)) {
		preparedData[fields.id] = toInteger(baseData.id);
	}

	return preparedData;
}

export function prepareRevisionData(pageID, revisionData, langID) {
	revisionAdapter.clearExcludes();
	listAdapter.clearExcludes();

	const page = prepareInfoPage(revisionData, langID);

	return page;
}

// Adapters ---------------------------------------------------------------------------------------

function createRevisionAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'pageID', fields.pageID);
	adapter.addField(rules.id, 'userID', fields.userID);

	adapter.addField(rules.bool, 'active', fields.active);
	adapter.addField(rules.fullDate, 'date', fields.date);
	adapter.addField(rules.noCondition, 'page', fields.page);

	return adapter;
}
