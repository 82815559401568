import { apiRequest } from './index';

function getFilterRoles( params = {}) {

	const req = {
		method: 'GET',
		url   : '/userRoles',
		params,
	};
	return apiRequest(req);
}

export const staffAPI = {
	getFilterRoles,
};
