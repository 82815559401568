const actions = {

	MARKETS_GROUPS_LIST_RELOAD : 'MARKETS_GROUPS_LIST_RELOAD',
	MARKETS_GROUPS_LIST_REFRESH: 'MARKETS_GROUPS_LIST_REFRESH',
	MARKETS_GROUPS_LIST_REORDER: 'MARKETS_GROUPS_LIST_REORDER',

	MARKETS_GROUPS_FILTER_SET_VALUE: 'MARKETS_GROUPS_FILTER_SET_VALUE',
	MARKETS_GROUPS_FILTER_REFRESH	 : 'MARKETS_GROUPS_FILTER_REFRESH',

	MARKETS_GROUPS_SAVE_GROUP	 : 'MARKETS_GROUPS_SAVE_GROUP',
	MARKETS_GROUPS_DELETE_GROUP: 'MARKETS_GROUPS_DELETE_GROUP',

	MARKETS_GROUPS_SET_VALUE_UI: 'MARKETS_GROUPS_SET_VALUE_UI',
	MARKETS_GROUPS_SAVE_ALL    : 'MARKETS_GROUPS_SAVE_ALL',

	MARKETS_GROUPS_CHANGED_ID_ADD   : 'MARKETS_GROUPS_CHANGED_ID_ADD',
	MARKETS_GROUPS_CHANGED_IDS_RESET: 'MARKETS_GROUPS_CHANGED_IDS_RESET',

	FILTER_RESET: 'MARKETS_GROUPS_FILTER_RESET',

	listReload: () => ({
		type: actions.MARKETS_GROUPS_LIST_RELOAD,
	}),
	listRefresh: list => ({
		type: actions.MARKETS_GROUPS_LIST_REFRESH,
		data: list,
	}),
	listReorder: list => ({
		type: actions.MARKETS_GROUPS_LIST_REORDER,
		data: list,
	}),

	filterReset: () => ({
		type: actions.FILTER_RESET,
	}),

	filterSetValue: (valueName, value) => ({
		type: actions.MARKETS_GROUPS_FILTER_SET_VALUE,
		data: {
			valueName,
			value,
		},
	}),
	filterRefresh: data => ({
		type: actions.MARKETS_GROUPS_FILTER_REFRESH,
		data,
	}),

	saveGroup: groupData => ({
		type: actions.MARKETS_GROUPS_SAVE_GROUP,
		data: groupData,
	}),
	saveAll: list => ({
		type: actions.MARKETS_GROUPS_SAVE_ALL,
		data: list,
	}),
	deleteGroup: groupID => ({
		type: actions.MARKETS_GROUPS_DELETE_GROUP,
		data: {
			groupID,
		},
	}),

	setValueUI: (valueName, value) => ({
		type: actions.MARKETS_GROUPS_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),

	changedIDAdd: groupID => ({
		type: actions.MARKETS_GROUPS_CHANGED_ID_ADD,
		data: {
			groupID,
		},
	}),
	changedIDsReset: () => ({
		type: actions.MARKETS_GROUPS_CHANGED_IDS_RESET,
	}),
};

export default actions;
