import every from 'lodash/every';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';

export const fields = {
	providerID   : 'provider_id',
	betsAmount   : 'bets_amount',
	betsCount    : 'bets_count',
	date         : 'date',
	playersCount : 'players_count',
	bet          : 'bet',
	winAmount    : 'win_amount',
	win          : 'win',
	ggrTotal     : 'ggr_total',
	ggr          : 'ggr',
	uniquePlayers: 'unique_plauers',

	// filter
	startDate: 'start_date',
	endDate  : 'end_date',
};

const widgetDataAdapter = createWidgetDataAdapter();

// Filters ----------------------------------------------------------------------------------------
export function createListParams(partnersList) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	const filter = {};

	const allPartnersSelected = every(partnersList, { selected: true });
	if (allPartnersSelected) {
		filter.providerID = [];

	} else {
		const IDs = partnersList
			.filter(item => item.selected && item.id.includes('bet-'))
			.map(item => item.id)
			.map(id => id.split('-').reverse()[0]);

		filter.providerID = IDs;
	}

	builder.addField(rules.isArrayID, 'providerID', fields.providerID);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapting ---------------------------------------------------------------------------------------
export function adaptWidgetData(rawData = []) {

	widgetDataAdapter.clearExcludes();
	const adaptedData = widgetDataAdapter.adaptList(rawData);

	return adaptedData;
}

export function adaptTotal(rawData = {}) {

	widgetDataAdapter.clearExcludes();
	const adaptedData = widgetDataAdapter.adapt(rawData);

	return adaptedData;
}

// Adapters ---------------------------------------------------------------------------------------
export function createWidgetDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'providerID', fields.providerID);

	adapter.addField(rules.fullDate, 'date', fields.date);

	adapter.addField(rules.number, 'ggr',          fields.ggr);
	adapter.addField(rules.number, 'betsCount',    fields.betsCount);
	adapter.addField(rules.number, 'betsAmount',   fields.betsAmount);
	adapter.addField(rules.number, 'playersCount', fields.playersCount);

	// adapter.addField(rules.string, 'bet', fields.bet);
	// adapter.addField(rules.string, 'win', fields.win);

	return adapter;
}
