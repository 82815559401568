import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { marketsAPI } from '../../../helpers/api/markets';
import notifications from '../../../helpers/notifications';

import { adaptSelectionsList } from './utils';
import { logger } from '../../../helpers/logger';


const prefix = 'selections.list';

const messages = {
	errorLoadSelections: `${prefix}.errorLoadSelections`,
};
function* listReload() {

	const takeAction   = actions.SELECTIONS_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {

		const { marketID } = action.data;
		let list = [];
		try {
			const response = yield call(marketsAPI.marketSelectionsList, marketID);
			if (response && response.status === 200) {
				list = adaptSelectionsList(response.data.data);
			}

		} catch (error) {
			notifications.showError(messages.errorLoadSelections, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(list));
	});
}

export default function* selectionsListSaga() {
	yield all([
		fork(listReload),
	]);
}
