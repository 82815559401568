import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import marketFilterActions from '../filter/actions';

import { marketsAPI } from '../../../../helpers/api/markets';
import { showSuccess, showError } from '../../../../helpers/notifications';
import { isEqualID, isID } from '../../../../helpers/utils';

import { adaptList, prepareType } from './utils';
import { logger } from '../../../../helpers/logger';

const prefix = 'sport.markets.displaytypes';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
	errorUpdate    : `${prefix}.errorUpdate`,
	errorDelete    : `${prefix}.errorDelete`,
	errorReorder   : `${prefix}.errorReorder`,
	successUpdate  : `${prefix}.successUpdate`,
	successDelete  : `${prefix}.successDelete`,
	successReorder : `${prefix}.successReorder`,
};

function getStoreData({ Sport: { Markets } }) {

	return {
		list        : Markets.DisplayTypes.get('list'),
		filter      : Markets.DisplayTypes.get('filter'),
		marketFilter: Markets.Filter.get('filter'),
	};
}

function* listReload() {

	yield takeEvery(actions.MARKETS_DISPLAY_TYPES_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter } = yield select(getStoreData);
		const { sportID, name } = filter;

		const params = {};
		if (isID(sportID)) { params.sport_id = sportID; }
		if (name)          { params.name = name; }

		let list = [];
		try {
			const response = yield call(marketsAPI.displayTypeList, params);
			if (response && response.status === 200) {
				list = adaptList(response.data.data);
			}
		} catch (error) {
			showError(messages.errorListReload, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(list));
		yield put(actions.changedIDsReset());
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* deleteType() {
	yield takeEvery(actions.MARKETS_DISPLAY_TYPES_DELETE_TYPE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { typeID } = action.data;
		const { filter, marketFilter } = yield select(getStoreData);

		try {
			const response = yield call(marketsAPI.displayTypeRemove, typeID);
			if (response && response.status === 200) {
				showSuccess(messages.successDelete);
			}
		} catch (error) {
			showError(messages.errorDelete, error);
			logger.log(error);
		}

		yield put(actions.listReload());

		// We need to reload display types list in the Market Filter if sportID is equal
		// because Market Modal uses that list for choosing values
		if (isEqualID(marketFilter.sportID, filter.sportID)) {
			yield put(marketFilterActions.displayTypeListReload(marketFilter.sportID));
		}
	});
}

function* saveType() {
	yield takeEvery(actions.MARKETS_DISPLAY_TYPES_SAVE_TYPE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, marketFilter } = yield select(getStoreData);
		const typeData = prepareType(action.data, filter.sportID);
		const isUpdate = isID(typeData.id);

		try {
			// update
			if (isUpdate) {
				const response = yield call(marketsAPI.displayTypeUpdate, typeData);
				if (response && response.status === 200) {
					showSuccess(messages.successUpdate);
				}

				// add
			} else {
				const response = yield call(marketsAPI.displayTypeAdd, typeData);
				if (response && response.status === 200) {
					showSuccess(messages.successUpdate);
					yield put(actions.typesModalRefresh({
						showModal: false,
						id       : null,
					}));
				}
			}
		} catch (error) {
			showError(messages.errorUpdate, error);
			logger.log(error);
		}

		yield put(actions.listReload());

		// We need to reload display types list in the Market Filter if sportID is equal
		// because Market Modal uses that list for choosing values
		if (isEqualID(marketFilter.sportID, filter.sportID)) {
			yield put(marketFilterActions.displayTypeListReload(marketFilter.sportID));
		}
	});
}

function* listReorder() {
	yield takeEvery(actions.MARKETS_DISPLAY_TYPES_LIST_REORDER, function* (
		action
	) {
		yield put(actions.uiRefresh({ loading: true }));

		const typesList = action.data;
		try {
			const response = yield call(marketsAPI.displayTypeUpdateOrder, typesList);
			if (response && response.status === 200) {
				showSuccess(messages.successReorder);
			}
		} catch (error) {
			showError(messages.errorReorder, error);
			logger.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* marketsDisplayTypesSaga() {
	yield all([
		fork(listReload),
		fork(deleteType),
		fork(saveType),
		fork(listReorder),
	]);
}
