import { combineReducers } from 'redux';

import Questions from './questions/reducer';
import Whitelist from './whitelist/reducer';
import BlockedIPs from './blockedIps/reducer';

export default combineReducers({
	Questions,
	Whitelist,
	BlockedIPs,
});
