import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import { adaptWithdrawal, compareWithdrawalWithFilter } from './utils';

function getStoreData({ AppTabs, RTM }) {

	return {
		isRTMWithdrawals     : Boolean( find(AppTabs.get('tabs'), { id: 'rtm/withdrawals' }) ),
		filter               : RTM.Withdrawals.get('filter'),
		pagination           : RTM.Withdrawals.get('pagination'),
		withdrawalIDs        : RTM.Withdrawals.get('withdrawalIDs'),
		entities             : RTM.Withdrawals.get('entities'),
		newWithdrawalIDs     : RTM.Withdrawals.get('newWithdrawalIDs'),
		newWithdrawalEntities: RTM.Withdrawals.get('newWithdrawalEntities'),
		withdrawalTotals     : RTM.Withdrawals.get('withdrawalTotals'),
	};
}

function* onNotifyCreateWithdrawal() {

	yield takeEvery(actions.RTM_WITHDRAWALS_SOCKET_NOTIFY_CREATE_ITEM, function* (action) {

		const rawWithdrawal = action.data;
		const wdID = toInteger(rawWithdrawal.id);
		if (!wdID) {
			return;
		}

		const storeData = yield select(getStoreData);
		const { isRTMWithdrawals, filter, pagination } = storeData;

		const withdrawalIDs         = cloneDeep(storeData.withdrawalIDs);
		const entities              = cloneDeep(storeData.entities);
		const newWithdrawalIDs      = cloneDeep(storeData.newWithdrawalIDs);
		const newWithdrawalEntities = cloneDeep(storeData.newWithdrawalEntities);
		const withdrawalTotals      = cloneDeep(storeData.withdrawalTotals);

		const adaptedWithdrawal     = adaptWithdrawal(rawWithdrawal);

		newWithdrawalEntities[wdID] = adaptedWithdrawal;
		newWithdrawalIDs.unshift(wdID);

		if (isRTMWithdrawals && pagination.currentPage === 1) {
			const hasMatches = compareWithdrawalWithFilter(adaptedWithdrawal, filter);
			if (hasMatches) {
				entities[wdID] = adaptedWithdrawal;
				withdrawalIDs.unshift(wdID);
			}
		}

		yield put(actions.dataRefresh(withdrawalIDs, entities, withdrawalTotals));
		yield put(actions.newWithdrawalListRefresh(newWithdrawalIDs, newWithdrawalEntities));

		let toSubscribeIDs = cloneDeep(withdrawalIDs);
		toSubscribeIDs.push(wdID);
		toSubscribeIDs = uniq(toSubscribeIDs);

		yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}

function* onNotifyUpdateWithdrawal() {

	yield takeEvery(actions.RTM_WITHDRAWALS_SOCKET_NOTIFY_UPDATE_ITEM, function* (action) {

		const rawWithdrawal = action.data;
		const wdID = toInteger(rawWithdrawal.id);
		if (!wdID) {
			return;
		}

		const storeData = yield select(getStoreData);

		const withdrawalIDs    = cloneDeep(storeData.withdrawalIDs);
		const entities         = cloneDeep(storeData.entities);
		const withdrawalTotals = cloneDeep(storeData.withdrawalTotals);

		const wdEntity = entities[wdID];
		if (!wdEntity) {
			return;
		}

		const adaptedWithdrawal = adaptWithdrawal(rawWithdrawal);
		entities[wdID] = adaptedWithdrawal;

		yield put(actions.dataRefresh(withdrawalIDs, entities, withdrawalTotals));
	});
}

export default function* rtmWithdrawalsExternalSaga() {
	yield all([
		fork(onNotifyCreateWithdrawal),
		fork(onNotifyUpdateWithdrawal),
	]);
}
