import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import isArray from 'lodash/isArray';
import { showError } from '../../../helpers/notifications';
import { staffAPI } from '../../../helpers/api/staff';
import { ROLE_TYPE } from '../../../containers/StaffManagement/modals/Role/utils';
import actionsRoles from '../roles/actions';
import { adaptRolesList } from '../roles/utils';
import actions from './actions';
import { logger } from '../../../helpers/logger';

const prefix = 'staff.filterroles';

const messages = {
	errorRolesListLoad: `${prefix}.errorRolesListLoad`,
};

// function getStoreData({ Auth }) {
//  const { adminType } = Auth.get('user');
//
//   return {
//     adminType,
//   };
// }

function* dataSourceRoleReload() {

	yield takeEvery(actions.AUTOCOMPLETE_DATA_SOURCE_ROLE_RELOAD, function* (action) {
		const { roleType, roleName } = action.data;
		const partner =  !roleType ? null : roleType === ROLE_TYPE.partner;
		const params = {
			partner,
			name: roleName,
		};

		yield put(actionsRoles.roleUIRefresh({ loading: true }));
		let rolesList = [];
		try {
			const res =  yield call(staffAPI.getFilterRoles, params);
			if (res && res.status === 200) {
				const serverData = res.data.data;
				if (isArray(serverData)) {
					rolesList = adaptRolesList(res.data.data);
				}
				yield put(actionsRoles.roleUIRefresh({ loading: false }));
			}
		} catch (error) {
			showError(messages.errorRolesListLoad, error);
			yield put(actionsRoles.roleUIRefresh({ loading: false }));
			logger.log(error);
		}

		yield put(actionsRoles.rolesListRefresh(rolesList));
	});
}

export default function* personalRoleFilterSaga() {
	yield all([
		fork(dataSourceRoleReload),
	]);
}
