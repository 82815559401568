import PropTypes from 'prop-types';

import Formatter from '../../../../helpers/formatter';
import { userDocumentTypesList } from '../../../../helpers/commonEnums';
import { mapEnum } from '../../../../helpers/utils';
import {
	ListItem,
	ListItemHeader,
	ListItemDate,
	ListItemBody,
} from '../NotificationBadge.style';

const docTypes = mapEnum(userDocumentTypesList);

const ItemDocuments = ({ id, date, documentType, userID, userName, userEmail, onClick, className, docID }) => {

	const docName = `ID: ${id}, Type: ${docTypes[documentType]}`;
	const docInfo = !userName.trim().length ? `Customer: ${userEmail}` : `Customer: ${userName}, ${userEmail}`;

	return (
		<ListItem className={`clickable ${className}`} onClick={() =>  onClick(userID, id, docID)}>
			<ListItemHeader>
				{docName}
				<ListItemDate>{Formatter.dateTime(date)}</ListItemDate>
			</ListItemHeader>
			<ListItemBody>{docInfo}</ListItemBody>
		</ListItem>
	);
};

ItemDocuments.propTypes = {
	id          : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	date        : PropTypes.string,
	documentType: PropTypes.number,
	docID       : PropTypes.number.isRequired,
	userID      : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	userName    : PropTypes.string,
	userEmail   : PropTypes.string,
	className   : PropTypes.string,
	onClick     : PropTypes.func,
};

ItemDocuments.defaultProps = {
	id          : '',
	date        : '',
	documentType: 0,
	userID      : '',
	userName    : '',
	userEmail   : '',
	className   : '',
	onClick     : () => {},
};

export default ItemDocuments;
