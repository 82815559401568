import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { BET_HISTORY_TYPES } from '../../../../helpers/commonConstants';

import actions from './actions';
import { loadBetHistoryData } from '../betHistoryUtils/generators';

function getStoreData({ Sport: { Events } }) {
	const { BetHistoryToolbar } = Events;
	const { market } = BetHistoryToolbar.get('toolbarData');
	const { placeDateFrom, placeDateTo } = market;

	return {
		bets      : Events.MarketBetHistory.get('bets'),
		UI        : Events.MarketBetHistory.get('UI'),
		pagination: Events.MarketBetHistory.get('pagination'),
		filter    : {
			placeDateFrom,
			placeDateTo,
		},
	};
}

function* dataReload() {

	yield takeEvery(actions.DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, pagination } = yield select(getStoreData);
		const { eventMarketID } = action.data;

		const { bets, totalCount } = yield call(
			loadBetHistoryData,
			BET_HISTORY_TYPES.market,
			eventMarketID,
			filter,
			pagination
		);

		yield put(actions.dataRefresh(bets));
		yield put(actions.selectedIDsRefresh([]));
		yield put(actions.paginationRefresh({ totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* marketBetHistorySaga() {
	yield all([fork(dataReload)]);
}
