import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toInteger from 'lodash/toInteger';

import WebsiteSelect from '../../../components/connected/WebsiteSelect';
import appActions from '../../../redux/app/actions';

import { Wrapper } from './WebsiteSwitcher.style';

class WebsiteSwitcher extends Component {
	static propTypes = {
		websiteID    : PropTypes.number.isRequired,
		websiteChange: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(newWebsiteID) {
		const { websiteID, websiteChange } = this.props;
		if (newWebsiteID === websiteID) {
			return;
		}

		websiteChange(newWebsiteID);
	}

	render() {
		const { websiteID } = this.props;
		return (
			<Wrapper>
				<div className="control">
					<WebsiteSelect
						noAutocomplete
						websiteID={websiteID}
						onChange={this.onChange}
						usePopupContainer={false}
					/>
				</div>
			</Wrapper>
		);
	}
}

const mapStateToProps = state => {
	const { App } = state;

	return {
		websiteID: toInteger(App.get('websiteID')),
	};
};

const mapDispatchToProps = {
	websiteChange: appActions.websiteChange,
};

const connectedWebsiteSwitcher = connect(mapStateToProps, mapDispatchToProps)(WebsiteSwitcher);

// because of:
// https://github.com/reduxjs/react-redux/issues/111#issuecomment-140744686
connectedWebsiteSwitcher.defaultProps = WebsiteSwitcher.defaultProps;

export default connectedWebsiteSwitcher;
