import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({

	entities: {},
	filter  : createFilter(filtersURL.notifications, initFilterTypes),

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	UI: {
		loading: false,
	},
});

export default function pushNotificationsUsersListReducer(state = initState, action) {

	switch (action.type) {

		case actions.LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}
		case actions.FILTER_REFRESH: {
			const { filter } = action.data;
			const target = state.get('filter');
			const result = fill(filter, target, true);
			return state.set('filter', result);
		}
		case actions.PAGINATION_REFRESH: {
			const { pagination } = action.data;
			const target = state.get('pagination');
			const result = fill(pagination, target);
			return state.set('pagination', result);
		}
		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}
		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}
		default:
			return state;
	}
}
