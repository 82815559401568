import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const StaffIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path {...props} d="M9.65531 3.57859C10.3951 4.04244 10.9139 4.82736 11.0083 5.73848C11.31 5.87945 11.6449 5.96052 11.9999 5.96052C13.296 5.96052 14.3465 4.91003 14.3465 3.61413C14.3465 2.31804 13.296 1.26755 11.9999 1.26755C10.7162 1.26795 9.67488 2.29967 9.65531 3.57859ZM8.11801 8.38319C9.4141 8.38319 10.4646 7.33249 10.4646 6.0366C10.4646 4.7407 9.4139 3.69021 8.11801 3.69021C6.82211 3.69021 5.77102 4.7409 5.77102 6.0368C5.77102 7.33269 6.82211 8.38319 8.11801 8.38319ZM9.11339 8.54313H7.12223C5.46552 8.54313 4.11771 9.89114 4.11771 11.5478V13.9829L4.1239 14.021L4.29163 14.0735C5.87266 14.5675 7.24622 14.7323 8.37679 14.7323C10.585 14.7323 11.8649 14.1027 11.9438 14.0626L12.1005 13.9833H12.1173V11.5478C12.1179 9.89114 10.7701 8.54313 9.11339 8.54313ZM12.9957 6.12066H11.0199C10.9985 6.91118 10.6611 7.62302 10.1273 8.13499C11.6 8.57288 12.6774 9.93846 12.6774 11.5514V12.3018C14.6282 12.2303 15.7524 11.6774 15.8265 11.6403L15.9832 11.5608H16V9.12498C16 7.46847 14.6522 6.12066 12.9957 6.12066ZM4.0005 5.96092C4.45955 5.96092 4.88666 5.82694 5.24847 5.59871C5.36348 4.84853 5.76563 4.19299 6.3401 3.74652C6.34249 3.70259 6.34669 3.65906 6.34669 3.61473C6.34669 2.31864 5.29599 1.26815 4.0005 1.26815C2.70421 1.26815 1.65391 2.31864 1.65391 3.61473C1.65391 4.91023 2.70421 5.96092 4.0005 5.96092ZM6.10787 8.13499C5.57674 7.62562 5.24048 6.91757 5.21592 6.13184C5.14264 6.12645 5.07016 6.12066 4.99548 6.12066H3.00452C1.34781 6.12066 0 7.46847 0 9.12498V11.5604L0.00618994 11.598L0.173917 11.6509C1.44226 12.0468 2.57422 12.2293 3.55742 12.2868V11.5514C3.55782 9.93846 4.63487 8.57328 6.10787 8.13499Z" fill={color} />
		</svg>
	);
};
StaffIcon.propTypes = propTypesIcons;

StaffIcon.defaultProps = defaultPropsIcons;
