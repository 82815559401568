import toInteger from 'lodash/toInteger';
import { isID } from '../../../../../helpers/utils';
import { fields, createListAdapter } from '../list/utils';

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptTemplate(rawData = {}) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareTemplate(baseData, langID) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(baseData);

	preparedData[fields.langID] = langID;
	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}

	return preparedData;
}
