import { makeActionCreator } from '../../../helpers';

const prefix = 'PARTICIPANT_HISTORY';

const TYPES = {
	DATA_RELOAD : `${prefix}DATA_RELOAD`,
	DATA_REFRESH: `${prefix}DATA_REFRESH`,
	UI_REFRESH  : `${prefix}UI_REFRESH`,
};

const actions = {
	...TYPES,
	dataReload : makeActionCreator(TYPES.DATA_RELOAD, 'participantID', 'logModel'),
	dataRefresh: makeActionCreator(TYPES.DATA_RELOAD, 'logData', 'logModel'),
	uiRefresh  : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
};

export default actions;
