import { Routes } from '../routes/routesData';

export function addNewTab(userID, tab, openTab) {
	const { id, titleID, componentName } = tab;

	const newTab = {
		id,
		titleID,
		componentName,
		componentProps: { userID },
		location      : `${Routes.dashboard}/${id}/${userID}`,
	};

	openTab(newTab);
}
