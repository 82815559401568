import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import dayjs from 'dayjs';
import { usersAPI } from '../../../helpers/api/users';
import notifications from '../../../helpers/notifications';
import ParamsBuilder from '../../../helpers/paramsBuilder';

import actions from './actions';
import { logger } from '../../../helpers/logger';

const prefix = 'users.casinoStatistics';

const messages = {
	errorLoadStatistics: `${prefix}.errorLoadStatistics`,
};

function getStoreData(state) {
	const UI = state.Users.CasinoStatistic.get('UI');
	return {
		UI,
	};
}

function getParams(userID, filter) {
	const builder = new ParamsBuilder();
	builder.addValue('id', userID);
	builder.addValue('start_date', dayjs(filter.dateFrom).toISOString());
	builder.addValue('end_date', dayjs(filter.dateTo).toISOString());

	const params = builder.biuldParams({});

	return params;
}

function* dataReload() {

	yield takeEvery(actions.USER_CASINO_STATISTIC_DATA_RELOAD, function* (action) {
		yield put(actions.setValueUI('loading', true));
		const { userID } = action.data;

		const storeData = yield select(getStoreData);
		const params = getParams(userID, storeData.UI);
		try {
			const response = yield call(usersAPI.getUserCasinoStatistic, params);
			if (response && response.status === 200) {
				yield put(actions.sportsListRefresh(response.data.data));
			}
		} catch (error) {
			notifications.showError(messages.errorLoadStatistics, error);
			logger.log(error);
		}


		yield put(actions.setValueUI('loading', false));
	});
}

export default function* statisticSaga() {
	yield all([fork(dataReload)]);
}
