import { Map, List } from 'immutable';
import uniq from 'lodash/uniq';
import { fill } from '../../../helpers/utils';
import actions from './actions';
import { convertToEntity } from './utils';

const initState = new Map({

	list               : [],
	entities           : {},
	changedIDs         : [],
	historyList        : [],
	websiteCurrencyList: [],

	modalUI: {
		currencyID: null,
		visible   : false,
		loading   : false,
		isChanged : false,
	},
	UI: { 
		loading   : false,
		loadEntity: false,
	},
	paymentsList: [],
});

export default function currencyReducer(state = initState, action) {

	switch (action.type) {

		// Currency List ------------------------------------------------------------------------------
		case actions.CURRENCY_REFRESH_LIST: {
			return state.set('list', action.data);
		}

		case actions.WEBSITE_CURRENCY_REFRESH_LIST: {
			return state.set('websiteCurrencyList', action.data);
		}

		case actions.CURRENCY_LIST_ADD_ITEM: {
			const data = state.get('list');
			data.unshift(action.data);
			return state.set('list', data );
		}

		case actions.CURRENCY_LIST_CHANGE_ITEM: {
			const { item, index }  = action.data;

			const list     = new List(state.get('list')).set(index, item);
			const entities = new Map(state.get('entities')).toObject();

			entities[item.id] = convertToEntity(item);

			return state.set('list', list.toArray()).set('entities', entities);
		}

		case actions.CURRENCY_LIST_CHANGED_ID_ADD: {
			const { currencyID } = action.data;
			const changedIDs = state.get('changedIDs');
			const result = uniq([...changedIDs, currencyID]);

			return state.set('changedIDs', result);
		}

		case actions.CURRENCY_LIST_CHANGED_IDS_RESET: {
			return state.set('changedIDs', []);
		}

		case actions.CURRENCY_LIST_UI_REFRESH: {
			const UI  = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}
		// Entities -----------------------------------------------------------------------------------
		case actions.CURRENCY_REFRESH_ENTITIES: {
			return state.set('entities', action.data);
		}

		// Rates List ---------------------------------------------------------------------------------
		case actions.CURRENCY_REFRESH_RATES_LIST: {
			const { currencyID, rateList } = action.data;

			const entitiesObject = new Map(state.get('entities')).toObject();
			const entity = entitiesObject[currencyID];

			entity.hasRates = true;
			entity.rates    = rateList;

			return state.set('entities', entitiesObject);
		}

		case actions.MODAL_RATES_LIST_SET_DATA: {
			const modalUI = new Map(action.data).toObject();
			return state.set('modalUI', modalUI);
		}

		case actions.MODAL_RATES_LIST_SET_VISIBLE: {
			const modalUI = new Map(state.get('modalUI')).toObject();
			modalUI.visible = action.data;
			return state.set('modalUI', modalUI);
		}

		case actions.MODAL_RATES_LIST_SET_LOADING: {
			const modalUI = new Map(state.get('modalUI')).toObject();
			modalUI.loading = action.data;
			return state.set('modalUI', modalUI);
		}

		case actions.MODAL_RATES_UI_REFRESH: {
			const target = state.get('modalUI');
			const result = fill(action.data, target);

			return state.set('modalUI', result);
		}

		// Payments -----------------------------------------------------------------------------------
		case actions.CURRENCY_PAYMENTS_LIST_REFRESH: {
			return state.set('paymentsList', action.data);
		}

		// History ------------------------------------------------------------------------------------
		case actions.CURRENCY_HISTORY_LIST_REFRESH: {
			return state.set('historyList', action.data);
		}

		default:
			return state;
	}
}
