import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';
import { CHANNEL_TYPES } from '../../../../helpers/commonConstants';

const initState = new Map({

	baseData : {},
	namesData: [],
	cmsData  : [],

	membersList       : [],
	membersSelectedIDs: [],

	countryList: [],
	leagueList : [],
	ageList    : [],

	historyNamesData: [],

	modalUI: {
		participantID   : null,
		participantName : '',
		visible         : false,
		loading         : false,
		editMode        : false,
		langID          : 1,
		channelID       : CHANNEL_TYPES.web,
		closeModal      : false,
		isBaseChanged   : false,
		isCMSChanged    : false,
		isNamesChanged  : false,
		isMembersChanged: false,
	},

	images: {
		iconFile   : null,
		logoFile   : null,
		pictureFile: null,

		isIconChanged   : false,
		isLogoChanged   : false,
		isPictureChanged: false,

		showIconDZ   : false,
		showLogoDZ   : false,
		showPictureDZ: false,
	},
});

export default function participantReducer(state = initState, action) {

	switch (action.type) {

		case actions.PARTICIPANT_BASE_DATA_REFRESH: {
			return state.set('baseData', action.data);
		}
		case actions.PARTICIPANTS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.PARTICIPANT_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.PARTICIPANT_HISTORY_NAMES_DATA_REFRESH: {
			return state.set('historyNamesData', action.data);
		}

		case actions.PARTICIPANT_CMS_DATA_REFRESH: {
			return state.set('cmsData', action.data);
		}

		case actions.PARTICIPANT_MEMBERS_LIST_REFRESH: {
			return state.set('membersList', action.data);
		}

		case actions.PARTICIPANT_MEMBERS_SELECTED_IDS_REFRESH: {
			return state.set('membersSelectedIDs', action.data);
		}

		case actions.PARTICIPANT_SET_MODAL_VALUE_UI: {
			const { valueName, value } = action.data;
			const modalUI = new Map(state.get('modalUI')).toObject();
			modalUI[valueName] = value;

			return state.set('modalUI', modalUI);
		}

		case actions.PARTICIPANT_MODAL_UI_REFRESH: {
			const target = state.get('modalUI');
			const result = fill(action.data, target);

			return state.set('modalUI', result);
		}

		case actions.PARTICIPANT_COUNTRY_LIST_REFRESH: {
			return state.set('countryList', action.data);
		}

		case actions.PARTICIPANT_LEAGUE_LIST_REFRESH: {
			return state.set('leagueList', action.data);
		}

		case actions.PARTICIPANT_AGE_LIST_REFRESH: {
			return state.set('ageList', action.data);
		}

		default:
			return state;
	}
}
