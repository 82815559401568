import React from 'react';
import * as PropTypes from 'prop-types';

import Formatter from '../../../../helpers/formatter';
import {
	ListItem,
	ListItemHeader,
	ListItemDate,
	ListItemBody,
} from '../NotificationBadge.style';

const ItemBets = ({ id, date, stake, userName, className }) => {

	const betName = `ID: ${id}, stake: ${Formatter.sum(stake)}`;
	const betInfo = `Customer: ${userName}`;

	return (
		<ListItem className={className}>
			<ListItemHeader>
				{betName}
				<ListItemDate>{Formatter.dateTime(date)}</ListItemDate>
			</ListItemHeader>
			<ListItemBody>{betInfo}</ListItemBody>
		</ListItem>
	);
};

ItemBets.propTypes = {
	id       : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	date     : PropTypes.string,
	stake    : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	userName : PropTypes.string,
	className: PropTypes.string,
};

ItemBets.defaultProps = {
	id       : '',
	date     : '',
	stake    : '',
	userName : '',
	className: '',
};

export default ItemBets;
