import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { integratorSelectAPI } from '../../helpers/api/integrator';
import notifications from '../../helpers/notifications';

import actions from './actions';
import { logger } from '../../helpers/logger';

const prefix = 'integrator';

const messages = {
	errorIntegratorLoad: `${prefix}.errorIntegratorLoad`,
};


function* integratorListReload() {

	yield takeEvery(actions.INTEGRATOR_RELOAD_LIST, function* () {

		let integratorList = [];
		try {
			const res = yield call(integratorSelectAPI.integratorList);
			if (res && res.status === 200) {
				integratorList = res.data.data;
			}

		} catch (error) {
			notifications.showError(messages.errorIntegratorLoad, error);
			logger.log(error);
		}

		yield put(actions.integratorListRefresh(integratorList));
	});
}

function* partnerIntegratorListReload() {

	yield takeEvery(actions.PARTNER_INTEGRATOR_RELOAD_LIST, function* () {

		let integratorList = [];
		try {
			const res = yield call(integratorSelectAPI.integratorsWithProvidersNew);
			if (res && res.status === 200) {
				integratorList = res.data.data;
			}

		} catch (error) {
			notifications.showError(messages.errorIntegratorLoad, error);
			logger.log(error);
		}

		yield put(actions.partnerIntegratorListRefresh(integratorList));
	});
}

export default function* currenciesSaga() {
	yield all([
		fork(integratorListReload),
		fork(partnerIntegratorListReload),
	]);
}
