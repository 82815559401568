import { isID } from '../../../../helpers/utils';
import EntityAdapter from '../../../../helpers/entityAdapter';

const fields = {
	id              : 'id',
	name            : 'name',
	periods         : 'periods',
	extraTime       : 'extra_time',
	extraTimePeriods: 'extra_time_periods',
	sportID         : 'sport_id',
	duration        : 'duration',
};

// Adapting ---------------------------------------------------------------------------------------

export function adaptTypeList(rawData = []) {
	const listAdapter = createListAdapter();
	const periodAdapter = createPeriodAdapter();

	const adaptedList = listAdapter.adaptList(rawData);

	adaptedList.forEach(item => {
		const periods = periodAdapter.adaptList(item.periods);
		const { extraTimePeriods } = item;

		item.periods = periods;
		item.extraTimePeriods = extraTimePeriods;
	});

	return adaptedList;
}

// Preparing ---------------------------------------------------------------------------------------

export function prepareTypeData(rawData = []) {
	const data = [];
	const listAdapter = createListAdapter();
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	rawData.forEach(item => {
		const periodAdapter = createPeriodAdapter();
		const periods = periodAdapter.adaptList(item.periods);
		const extraTimePeriods = periodAdapter.adaptList(item.extraTimePeriods);
		const adaptedItem = listAdapter.prepare(item);

		item[fields.periods] = periods;
		item[fields.extraTimePeriods] = extraTimePeriods;
		if (isID(item.id)) {
			adaptedItem.id = item.id;
		}
		data.push(adaptedItem);
	});
	return data;
}

// Adapters ---------------------------------------------------------------------------------------

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'sportID', fields.sportID);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.bool, 'extraTime', fields.extraTime);

	adapter.addField(rules.arrayObject, 'periods', fields.periods);
	adapter.addField(rules.arrayObject, 'extraTimePeriods', fields.extraTimePeriods);

	return adapter;
}

function createPeriodAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.positiveNumber, 'duration', fields.duration);

	return adapter;
}
