import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import tabsActions from '../../../../redux/appTabs/actions';
import { Wrapper } from './TabLink.style';
import { useNavigate } from 'react-router';

const TabLink = ({ id, titleID, type, componentName, location, componentProps, onClick, openTab, className, children, ...restProps }) => {
	const navigate = useNavigate();

	const onClickLink = (event) => {
		const url = `/dashboard/${String(location).replace(/^\/{1}/, '')}`;

		const newTab = {
			id,
			titleID,
			componentName,
			componentProps,
			location: url,
			search  : '',
		};

		navigate(url);
		openTab(newTab);

		if (onClick) {
			onClick({
				id,
				type,
				componentName,
				componentProps,
				location,
				event,
			});
		}
	};

	const classes = classnames(className, {
		'tab-link': (type === 'link'),
	});

	return (
		<Wrapper {...restProps} className={classes} onClick={onClickLink}>
			{children}
		</Wrapper>
	);
};

TabLink.propTypes = {
	/**
	 * Unique identifier for Tab that will be open by this link
	 */
	id            : PropTypes.string.isRequired,
	/**
	 * Title for Tab: message ID for using with IntlMessage
	 */
	titleID       : PropTypes.string.isRequired,
	/**
	 * Type of link container:
	 * - normal (by default): regular <div> without any styling (excepting 'cursor: pointer')
	 * - link: chidren will decorate as <a> in Ant-Design
	 */
	type          : PropTypes.oneOf(['normal', 'link']),
	/**
	 * Name of component that will be opened in new tab.
	 * All available names are in constant COMPONENTS
	 * (/src/constants/components.js)
	 */
	componentName : PropTypes.string.isRequired,
	/**
	 * URL that will be shown in address bar after going to this link.
	 * Be careful: URL haven't to include part '/dashboard', this component
	 * // will add it itself
	 */
	location      : PropTypes.string.isRequired,
	/**
	 * Additional props for component in Tab (optional)
	 */
	componentProps: PropTypes.object,
	/**
	 * Additional callback for onClick event:
	 * function({
	 *  id,
	 *  type,
	 *  componentName,
	 *  location,
	 *  componentProps,
	 *  event: Syntetic Event
	 * })
	 */
	onClick       : PropTypes.func,
	openTab       : PropTypes.func,
	className     : PropTypes.string,
	children      : PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

TabLink.defaultProps = {
	type          : 'normal',
	componentProps: {},
	onClick       : null,
	openTab       : null,
	className     : '',
};

export default connect(null, {
	openTab: tabsActions.openTab,
})(TabLink);
