const actions = {

	NEWS_LIST_RELOAD : 'NEWS_LIST_RELOAD',
	NEWS_LIST_REFRESH: 'NEWS_LIST_REFRESH',

	NEWS_LIST_DELETE_ITEM: 'NEWS_LIST_DELETE_ITEM',
	NEWS_LIST_UI_REFRESH	: 'NEWS_LIST_UI_REFRESH',
	listReload           : () => ({
		type: actions.NEWS_LIST_RELOAD,
	}),
	listRefresh: (listIDs, entities) => ({
		type: actions.NEWS_LIST_REFRESH,
		data: {
			listIDs,
			entities,
		},
	}),
	deleteItem: itemID => ({
		type: actions.NEWS_LIST_DELETE_ITEM,
		data: {
			itemID,
		},
	}),
	uiRefresh: data => ({
		type: actions.NEWS_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
