import EntityAdapter from '../../helpers/entityAdapter';

const fields = {
	dateFormat : 'date_format',
	customerAge: 'customer_age',
};

const websiteSettingsAdapter = createWebsiteSettingsAdapter();

// Adapt ------------------------------------------------------------------------------------------
export function adaptWebsiteSettings(rawData = {}) {

	websiteSettingsAdapter.clearExcludes();
	const adaptedData = websiteSettingsAdapter.adapt(rawData);

	return adaptedData;
}

// Adapters ---------------------------------------------------------------------------------------
function createWebsiteSettingsAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.string, 'dateFormat', fields.dateFormat);
	adapter.addField(rules.positiveNumber, 'customerAge', fields.customerAge);

	return adapter;
}

// Others -----------------------------------------------------------------------------------------
export function getView(width) {

	if (width >= 1220) {
		return 'DesktopView';
	}
	if (width >= 767) {
		return 'TabView';
	}

	return 'MobileView';
}

export const API_STATUS = {
	success: 1,
	failure: 2,
	initial: 3,
};
