const prefix = 'LIVE_STATISTIC_TYPES';
export const liveStatisticTypesModalActions = {
	LIVE_STATISTIC_TYPES_BASE_DATA_REFRESH    : `${prefix}_BASE_DATA_REFRESH`,
	LIVE_STATISTIC_TYPES_BASE_DATA_RELOAD_CASE: `${prefix}_BASE_DATA_RELOAD_CASE`,
	LIVE_STATISTIC_TYPES_NAME_RELOAD_CASE     : `${prefix}_NAME_RELOAD_CASE`,
	LIVE_STATISTIC_TYPES_VISIBILITY_CASE      : `${prefix}_VISIBILITY_CASE`,
	LIVE_STATISTIC_TYPES_GET_MODAL_DATA       : `${prefix}_GET_MODAL_DATA`,
	LIVE_STATISTIC_TYPES_MODAL_UI_REFRESH     : `${prefix}_MODAL_UI_REFRESH`,
	LIVE_STATISTIC_TYPES_SAVE                 : `${prefix}_SAVE`,
	LIVE_STATISTIC_TYPES_CLEAR_MODAL          : `${prefix}_CLEAR_MODAL`,

	listReload: data => ({
		type: liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_BASE_DATA_REFRESH,
		data,
	}),

	listReloadCase: data => ({
		type: liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_BASE_DATA_RELOAD_CASE,
		data,
	}),

	namesReloadCase: data => ({
		type: liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_NAME_RELOAD_CASE,
		data,
	}),

	visibilityReloadCase: data => ({
		type: liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_VISIBILITY_CASE,
		data,
	}),

	getModalData: data => ({
		type: liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_GET_MODAL_DATA,
		data,
	}),

	saveLST: data => ({
		type: liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_SAVE,
		data,
	}),

	uiRefresh: data => ({
		type: liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_MODAL_UI_REFRESH,
		data,
	}),

	clearModal: () => ({
		type: liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_CLEAR_MODAL,
	}),
};
