import { Map } from 'immutable';
import { cloneDeep } from 'lodash';
import { fill } from '../../../helpers/utils';
import actions from './actions';

/*
const tempNewDocs = [
  { id: 1, userID: 1, typeID: 1, created: '2018-10-26' },
  { id: 2, userID: 2, typeID: 1, created: '2018-10-27' },
  { id: 3, userID: 1, typeID: 2, created: '2018-09-15' },
]
*/

const initState = new Map({

	entities : {},
	source   : {},
	newDocs  : [],
	modalData: {
		visible            : false,
		loading            : false,
		userID             : 0,
		documentType       : 0,
		documentUrl        : '',
		created            : '',
		documentNumber     : '',
		documentDescription: '',
	},

	UI: {
		loading         : false,
		userID          : 0,
		id              : null,
		showDeleted     : false,
		canSavedocNumber: false,
		edit            : false,
		editDocNumber   : false,
		editModeMRZ  			: false,
	},
});

export default function userDocumentsReducer(state = initState, action) {

	switch (action.type) {
		
		case actions.USER_DOCUMENTS_LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}
		case actions.USER_DOCUMENTS_SOURCE_REFRESH: {
			const { entities } = action.data;
			return state.set('source', entities);
		}
		case actions.USER_DOCUMENTS_NEW_DOCS_LIST_REFRESH: {
			const { newDocs } = action.data;
			return state.set('newDocs', newDocs);
		}

		case actions.USER_DOCUMENTS_LIST_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.USER_DOCUMENTS_MODAL_DATA_REFRESH: {
			const target = state.get('modalData');
			const result = fill(action.data, target, true);
			return state.set('modalData', result);
		}

		case actions.USER_DOCUMENTS_MODAL_DATA_RESET: {
			const result = initState.get('modalData');
			return state.set('modalData', result);
		}
		case actions.USER_DOCUMENT_NUMBER_CHANGE: {
			const  { payload :{ docID, documentNumber } } = action;
			const  entities = state.get('entities');
			const  filtred  = entities[docID];
			const  newEntity = { ...filtred, documentNumber };
			const newEntities = { ...entities, [docID]: newEntity };
			return state.set('entities', newEntities);
		}

		case actions.USER_MRZ_DATA_CHANGE: {
			const  { data: { docID, data } } = action;
			const  entities = state.get('entities');
			// const  filtered  = entities[docID];
			// const  newEntity = { ...filtered, ...data };
			const newEntities = { ...entities, [docID]: data };
			return state.set('entities', newEntities);
		}

		case actions.CAN_SAVE_DOCUMENT_NUMBER: {
			const target = state.get('UI');
			const  { payload :{ id, canSavedocNumber } } = action;
			const newUI = { ...target, id, canSavedocNumber };
			return state.set('UI', newUI);
		}

		case actions.USER_DOCUMENTS_REASON: {
			const { docID, visible, reason } = action.reasonData;
			const currentObj = cloneDeep(state.get('entities'));
			const document = currentObj[docID];

			document.visible = visible;
			document.reason = reason;

			currentObj[docID] = document;
			return state.set('entities', currentObj);
		}

		case actions.USER_DOCUMENTS_NUMBER: {
			const { docID, visible, documentNumber } = action.docNumberData;
			const currentObj = cloneDeep(state.get('entities'));
			const document = currentObj[docID];

			document.visible = visible;
			document.documentNumber = documentNumber;

			currentObj[docID] = document;
			return state.set('entities', currentObj);
		}

		default:
			return state;
	}
}
