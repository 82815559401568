import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';

export function convertToEntities(countryList = []) {
	const entities = {};
	if (!isArray(countryList)) {
		return entities;
	}

	countryList.forEach(item => {
		const itemID = toInteger(item.id);
		entities[itemID] = convertToEntity(item);
	});
	return entities;
}

export function convertToEntity(countryItem) {

	return {
		id     : countryItem.id,
		isoCode: countryItem.iso_code,
		name   : countryItem.name,
	};
}
