const actions = {

	PAYMENTS_LIST_RELOAD    : 'PAYMENTS_LIST_RELOAD',
	PAYMENTS_LIST_REFRESH   : 'PAYMENTS_LIST_REFRESH',
	PAYMENTS_LIST_UI_REFRESH: 'PAYMENTS_LIST_UI_REFRESH',

	PAYMENTS_MAIN_LIST_RELOAD : 'PAYMENTS_MAIN_LIST_RELOAD',
	PAYMENTS_MAIN_LIST_REFRESH: 'PAYMENTS_MAIN_LIST_REFRESH',

	listReload: (data) => ({
		type: actions.PAYMENTS_LIST_RELOAD,
		data,
	}),
	mainListReload: (data) => ({
		type: actions.PAYMENTS_MAIN_LIST_RELOAD,
		data,
	}),
	listRefresh: entities => ({
		type: actions.PAYMENTS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	mainListRefresh: entities => ({
		type: actions.PAYMENTS_MAIN_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	uiRefresh: data => ({
		type: actions.PAYMENTS_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
