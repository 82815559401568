import React from 'react';

export const RiskIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_204_4)">
				<path fillRule="evenodd" clipRule="evenodd" d="M21.135 20.5009C21.135 20.076 20.8163 19.8636 20.4977 19.8636H19.0105V12.7467C19.0105 8.81643 15.8239 5.62976 11.9999 5.62976C8.17586 5.62976 4.98919 8.81643 4.98919 12.6404V19.7573H3.50208C3.07719 19.7573 2.86475 20.076 2.86475 20.3947V23.2627C2.86475 23.6876 3.18341 23.9 3.50208 23.9H20.3914C20.8163 23.9 21.0288 23.5813 21.0288 23.2627V20.5009H21.135ZM6.26386 12.6404C6.26386 9.55999 8.8132 7.01065 11.9999 7.01065C15.1865 7.01065 17.6296 9.55999 17.6296 12.6404V19.7573H6.26386V12.6404ZM19.7541 22.6253H4.13941V21.1382H19.6479V22.6253H19.7541Z" fill="#595959" />
				<path d="M11.2568 3.39911V0.743556C11.2568 0.318667 11.5755 0 12.0004 0C12.4253 0 12.7439 0.318667 12.7439 0.743556V3.39911C12.7439 3.824 12.4253 4.14267 12.0004 4.14267C11.5755 4.14267 11.2568 3.824 11.2568 3.39911Z" fill="#595959" />
				<path d="M16.3554 4.35513L18.1611 2.44313C18.4798 2.12447 18.9047 2.12447 19.2234 2.44313C19.542 2.7618 19.542 3.18669 19.2234 3.50536L17.3114 5.41736C16.9927 5.73602 16.5678 5.73602 16.2491 5.41736C16.0367 5.09869 16.0367 4.56758 16.3554 4.35513Z" fill="#595959" />
				<path d="M20.1798 8.28533L22.5167 6.90444C22.8353 6.692 23.3664 6.79822 23.5789 7.22311C23.7913 7.54177 23.6851 8.07289 23.2602 8.28533L20.9233 9.66622C20.6047 9.87867 20.0735 9.77244 19.8611 9.34755C19.6487 8.92266 19.7549 8.49778 20.1798 8.28533Z" fill="#595959" />
				<path d="M7.53883 5.41736C7.22017 5.6298 6.79528 5.6298 6.47661 5.41736L4.56461 3.50536C4.35216 3.18669 4.35216 2.7618 4.56461 2.44313C4.88327 2.12447 5.30816 2.12447 5.62683 2.44313L7.53883 4.35513C7.8575 4.56758 7.8575 5.09869 7.53883 5.41736Z" fill="#595959" />
				<path d="M4.03361 9.24133C3.82116 9.56 3.39628 9.77244 2.97139 9.56L0.634494 8.17911C0.315827 7.96666 0.209605 7.54177 0.42205 7.22311C0.634494 6.79822 1.05938 6.692 1.37805 6.90444L3.71494 8.28533C4.13983 8.49778 4.24605 8.92266 4.03361 9.24133Z" fill="#595959" />
			</g>
			<defs>
				<clipPath id="clip0_204_4">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>

	);
};
