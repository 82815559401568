import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { createEntities } from '../../../utility';
import { IRiskBetsItem, IRowDataAdapt } from './types';
import { cloneDeep } from 'lodash';

export const fields = {
	// list
	betStake             : 'stake',
	betStakeUSD          : 'stake_usd',
	betPlacementTime     : 'place_date',
	betTotalPrice        : 'total_odd',
	maxPossiblePayoutUSD : 'won_amount_usd',
	maxPossiblePayout    : 'won_amount',
	maxPossibleWinningUSD: 'possible_won_amount_usd',
	maxPossibleWinning   : 'possible_won_amount',
	eventStartingTime    : 'event_start_date',
	oddPrice             : 'odd',
	repetitiveNumber     : 'repetitive_number',

	// filter
	betID                    : 'id',
	customerID               : 'user_id',
	betNumber                : 'bet_number',
	currencyID               : 'currency_id',
	eventName                : 'event_name',
	marketName               : 'market_name',
	sportName                : 'sport_name',
	selectionName            : 'selection_name',
	statusID                 : 'status_id',
	countryName              : 'country_name',
	leagueName               : 'league_name',
	stakeFrom                : 'stake_from',
	stakeTo                  : 'stake_to',
	eventID                  : 'event_id',
	gameType                 : 'game_type',
	betPlacementTimeFrom     : 'place_date_from',
	betPlacementTimeTo       : 'place_date_to',
	betTotalPriceFrom        : 'total_odd_from',
	betTotalPriceTo          : 'total_odd_to',
	betType                  : 'bet_type',
	maxPossiblePayoutUSDFrom : 'won_amount_usd_from',
	maxPossiblePayoutUSDTo   : 'won_amount_usd_to',
	maxPossiblePayoutFrom    : 'won_amount_from',
	maxPossiblePayoutTo      : 'won_amount_to',
	maxPossibleWinningUSDFrom: 'possible_won_amount_usd_from',
	maxPossibleWinningUSDTo  : 'possible_won_amount_usd_to',
	maxPossibleWinningFrom   : 'possible_won_amount_from',
	maxPossibleWinningTo     : 'possible_won_amount_to',
	priceProvider            : 'source_id',
	betStakeUSDFrom          : 'stake_usd_from',
	betStakeUSDTo            : 'stake_usd_to',
	eventStartingTimeFrom    : 'event_start_date_from',
	eventStartingTimeTo      : 'event_start_date_to',
	betTradingMode           : 'trading_mode',
	marketID                 : 'market_id',
	gameVariety              : 'game_variety',
	gamePeriod               : 'game_period',
	repetitiveNumberFrom     : 'repetitive_number_from',
	repetitiveNumberTo       : 'repetitive_number_to',
	currentMarginFrom        : 'margin_from',
	currentMarginTo          : 'margin_to',

	// event Markets
	eventMarkets : 'event_markets',
	reason       : 'reason',
	suspend      : 'suspend',
	initialMargin: 'initial_margin',
	currentMargin: 'current_margin',
};

const baseAdapter				= createBaseBetAdapter();
const detailsAdapter			= createDetailsBetAdapter();
const eventMarketsAdapter		= createEventMarketsAdapter();

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules   = builder.RULES;

	builder.addValue('sort_by', sorting.sortBy ? fields[sorting.sortBy] : 'id');
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'betID', fields.betID);
	builder.addField(rules.isString, 'customerID', fields.customerID);
	builder.addField(rules.isString, 'betNumber', fields.betNumber);
	builder.addField(rules.isString, 'currencyID', fields.currencyID);
	builder.addField(rules.isString, 'eventName', fields.eventName);
	builder.addField(rules.isString, 'marketName', fields.marketName);
	builder.addField(rules.isString, 'sportName', fields.sportName);
	builder.addField(rules.isString, 'selectionName', fields.selectionName);
	builder.addField(rules.isID, 'statusID', fields.statusID);
	builder.addField(rules.isString, 'countryName', fields.countryName);
	builder.addField(rules.isString, 'leagueName', fields.leagueName);
	builder.addField(rules.isID, 'suspend', fields.suspend);
	builder.addRangeField(rules.isNumberRange, filter.betStake, [
		fields.stakeFrom,
		fields.stakeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.repetitiveNumber, [
		fields.repetitiveNumberFrom,
		fields.repetitiveNumberTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.currentMargin, [
		fields.currentMarginFrom,
		fields.currentMarginTo,
	]);
	builder.addField(rules.isString, 'eventID', fields.eventID);
	builder.addField(rules.isString, 'gameType', fields.gameType);
	builder.addRangeField(rules.isDateTimeRange, filter.betPlacementTime, [
		fields.betPlacementTimeFrom,
		fields.betPlacementTimeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.betTotalPrice, [
		fields.betTotalPriceFrom,
		fields.betTotalPriceTo,
	]);
	builder.addField(rules.isID, 'betType', fields.betType);
	builder.addRangeField(rules.isNumberRange, filter.maxPossiblePayoutUSD, [
		fields.maxPossiblePayoutUSDFrom,
		fields.maxPossiblePayoutUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossiblePayout, [
		fields.maxPossiblePayoutFrom,
		fields.maxPossiblePayoutTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossibleWinningUSD, [
		fields.maxPossibleWinningUSDFrom,
		fields.maxPossibleWinningUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossibleWinning, [
		fields.maxPossibleWinningFrom,
		fields.maxPossibleWinningTo,
	]);
	builder.addField(rules.isID, 'priceProvider', fields.priceProvider);
	builder.addRangeField(rules.isNumberRange, filter.betStakeUSD, [
		fields.betStakeUSDFrom,
		fields.betStakeUSDTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.eventStartingTime, [
		fields.eventStartingTimeFrom,
		fields.eventStartingTimeTo,
	]);
	builder.addField(rules.isID, 'betTradingMode', fields.betTradingMode);
	builder.addField(rules.isString, 'marketID', fields.marketID);
	builder.addField(rules.isString, 'gameVariety', fields.gameVariety);
	builder.addField(rules.isString, 'gamePeriod', fields.gamePeriod);
	builder.addField(rules.isNumber, 'oddPrice', fields.oddPrice);


	const params = builder.biuldParams(filter);

	return params;
}

function createBaseBetAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addStorage({
		betStake             : 2, // toFixed count
		betStakeUSD          : 2, // toFixed count
		maxPossiblePayoutUSD : 2, // toFixed count
		maxPossiblePayout    : 2, // toFixed count
		maxPossibleWinningUSD: 2, // toFixed count
		maxPossibleWinning   : 2, // toFixed count
		betTotalPrice        : 3, // toFixed count
	});

	adapter.addField(rules.id, 'betID', fields.betID);
	adapter.addField(rules.string, 'customerID', fields.customerID);
	adapter.addField(rules.positiveNumber, 'betNumber', fields.betNumber);
	adapter.addField(rules.number, 'currencyID', fields.currencyID);
	adapter.addField(rules.toFixedNumber, 'betStake', fields.betStake);
	adapter.addField(rules.toFixedNumber, 'betStakeUSD', fields.betStakeUSD);
	adapter.addField(rules.dateTime, 'betPlacementTime', fields.betPlacementTime);
	adapter.addField(rules.toFixedNumber, 'maxPossiblePayoutUSD', fields.maxPossiblePayoutUSD);
	adapter.addField(rules.toFixedNumber, 'maxPossiblePayout', fields.maxPossiblePayout);
	adapter.addField(rules.toFixedNumber, 'maxPossibleWinningUSD', fields.maxPossibleWinningUSD);
	adapter.addField(rules.toFixedNumber, 'maxPossibleWinning', fields.maxPossibleWinning);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.number, 'priceProvider', fields.priceProvider);
	adapter.addField(rules.toFixedNumber, 'betTotalPrice', fields.betTotalPrice);
	adapter.addField(rules.number, 'betType', fields.betType);
	adapter.addField(rules.number, 'repetitiveNumber', fields.repetitiveNumber);

	return adapter;
}
function createDetailsBetAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addStorage({
		oddPrice: 3,  // toFixed count
	});

	adapter.addField(rules.string, 'sportName', fields.sportName);
	adapter.addField(rules.string, 'selectionName', fields.selectionName);
	adapter.addField(rules.string, 'leagueName', fields.leagueName);
	adapter.addField(rules.string, 'gameVariety', fields.gameVariety);
	adapter.addField(rules.string, 'marketID', fields.marketID);
	adapter.addField(rules.string, 'gameType', fields.gameType);
	adapter.addField(rules.string, 'gamePeriod', fields.gamePeriod);
	adapter.addField(rules.string, 'marketName', fields.marketName);
	adapter.addField(rules.toFixedNumber, 'oddPrice', fields.oddPrice);
	adapter.addField(rules.string, 'eventID', fields.eventID);
	adapter.addField(rules.number, 'initialMargin', fields.initialMargin);
	adapter.addField(rules.string, 'countryName', fields.countryName);
	adapter.addField(rules.string, 'eventName', fields.eventName);
	adapter.addField(rules.dateTime, 'eventStartingTime', fields.eventStartingTime);
	adapter.addField(rules.string, 'betTradingMode', fields.betTradingMode);
	adapter.addField(rules.object, 'eventMarkets', fields.eventMarkets);


	return adapter;
}

function createEventMarketsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;
	adapter.addField(rules.id, 'suspendID', fields.suspend);
	adapter.addField(rules.number, 'currentMargin', fields.currentMargin);
	adapter.addField(rules.string, 'reason', fields.reason);
	adapter.addField(rules.string, 'marketID', fields.marketID);


	return adapter;
}
export function adaptBet(rawData:IRiskBetsItem, marketIDs: string[]) {
	baseAdapter.clearExcludes();
	detailsAdapter.clearExcludes();

	let adaptedBet			= baseAdapter.adapt(rawData);
	adaptedBet.details		= detailsAdapter.adaptList(rawData.details);

	adaptedBet = {
		...adaptedBet,
		details: adaptedBet.details.map(detail => {
			if (detail.marketID && !marketIDs.includes(detail.marketID)) {
				marketIDs.push(detail.marketID);
			}
			const eventMarkets = eventMarketsAdapter.adapt(detail.eventMarkets);
			return {
				...detail,
				eventMarkets,
				suspendLoading: false,
				popoverVisible: false,
			};
		}),
	};
	return adaptedBet;
}

export function adaptBetsList(rawData:IRowDataAdapt = []) {
	if (!isArray(rawData)) {
		return {};
	}
	const marketIDs: string[] = [];
	const adaptedData:IRiskBetsItem[] = rawData.map(rawBet => {
		return adaptBet(rawBet, marketIDs);
	});
	const normalizedData = createEntities(adaptedData, 'betID');
	const betIDs = Object.keys(normalizedData) || [];
	const entities = normalizedData || {};
	const withDetailsEntities = cloneDeep(entities);

	const result = {
		riskList: adaptedData,
		betIDs,
		entities,
		withDetailsEntities,
		marketIDs,
	};

	return result;
}