import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';

const { isValidID, isValidString } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	sportID: isValidID,
	nameLST: isValidString,
};

export const defaultValuesLiveStatisticFilter = {
	sportID: 150592,
	nameLST: '',
};
