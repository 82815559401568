import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { getHeadersTotalCount } from '../../../../../helpers/utils';
import QueryToFilterValidaion from '../../../../../helpers/queryToFilterValidaion';
import { createEntities } from '../../../../utility';


const { isValidID, isValidString, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	statusID: isValidID,
	notSent : isValidID,
	to      : isValidString,
	sendDate: isValidDateRange,
};


export const fields = {
	id         : 'id',
	from       : 'from',
	to         : 'to',
	subject    : 'subject',
	content    : 'content',
	sendDate   : 'date',
	updateDate : 'updated',
	readDate   : 'read_date',
	categoryID : 'category',
	statusID   : 'status_id',
	statusText : 'status_text',
	mailingHost: 'mailing_host',
	websiteId  : 'website_id',

	// filter
	notSent     : 'not_sent',
	notRead     : 'not_read',
	sendDateFrom: 'send_date_from',
	sendDateTo  : 'send_date_to',
	readDateFrom: 'read_date_from',
	readDateTo  : 'read_date_to',
};

const listAdapter = createListAdapter();

export function getListParams(filter, pagination, sorting = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'statusID', fields.statusID);

	builder.addField(rules.isString, 'to', fields.to);

	builder.addField(rules.isBoolean, 'notSent', fields.notSent);
	builder.addField(rules.isBoolean, 'notRead', fields.notRead);

	builder.addRangeField(rules.isDateTimeRange, filter.sendDate, [
		fields.sendDateFrom,
		fields.sendDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.readDate, [
		fields.readDateFrom,
		fields.readDateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptEmailsList(rawData = [], responseHeaders) {
	if (!isArray(rawData)) {
		return {
			entities  : {},
			totalCount: 0,
		};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	const entities		= createEntities(adaptedData);
	const totalCount	= getHeadersTotalCount(responseHeaders) || adaptedData.length;

	return {
		entities,
		totalCount,
	};
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'categoryID', fields.categoryID);

	adapter.addField(rules.string, 'from', fields.from);
	adapter.addField(rules.string, 'to', fields.to);
	adapter.addField(rules.string, 'subject', fields.subject);
	adapter.addField(rules.string, 'content', fields.content);
	adapter.addField(rules.string, 'statusText', fields.statusText);
	adapter.addField(rules.string, 'mailingHost', fields.mailingHost);
	adapter.addField(rules.string, 'websiteId', fields.websiteId);

	adapter.addField(rules.fullDate, 'sendDate', fields.sendDate);
	adapter.addField(rules.fullDate, 'updateDate', fields.updateDate);
	adapter.addField(rules.fullDate, 'readDate', fields.readDate);

	return adapter;
}
