import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import { CHANNEL_TYPES } from '../../../../helpers/commonConstants';
import actions from './actions';

const initState = new Map({

	baseData    : {},
	seoData     : {},
	settingsData: [],
	dynamicData : [],
	namesData   : [],
	cmsData     : [],
	historyData : [],


	sportRegulationTypeList : [],
	leagueRegulationTypeList: [],

	modalUI: {
		visible      : false,
		editMode     : false,
		loading      : false,
		sportID      : null,
		categoryID   : null,
		categoryName : null,
		typeID       : null,
		langID       : null,
		parentID     : null,
		closeModal   : false,
		categoryPos  : null,
		parentPos    : null,
		channelID    : CHANNEL_TYPES.web,
		activeTab    : 'main',
		categoryColor: '#ffffff',
	},

	isChanged: {
		base           : false,
		settings       : false,
		dynamic        : false,
		names          : false,
		cms            : false,
		markets        : false,
		margins        : false,
		regulationTypes: false,
		tournamentType : false,
	},

	marketsUI: {
		marketIDs             : [],
		liveMarketIDs         : [],
		prematchMarketIDs     : [],
		marketEntities        : {},
		channelMarketList     : [],
		leagueMarketMarginList: [],
		tradingModeID         : 0,
		channelID             : CHANNEL_TYPES.web,
	},
});

export default function categoryReducer(state = initState, action) {

	switch (action.type) {

		case actions.CATEGORY_BASE_DATA_REFRESH: {
			return state.set('baseData', action.data);
		}

		case actions.CATEGORY_SETTINGS_DATA_REFRESH: {
			return state.set('settingsData', action.data);
		}

		case actions.CATEGORY_DYNAMIC_DATA_REFRESH: {
			return state.set('dynamicData', action.data);
		}

		case actions.CATEGORY_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.CATEGORY_CMS_DATA_REFRESH: {
			return state.set('cmsData', action.data);
		}

		case actions.CATEGORY_HISTORY_DATA_REFRESH: {
			return state.set('historyData', action.data);
		}

		case actions.CATEGORY_SPORT_REGULATION_TYPE_LIST_REFRESH: {
			return state.set('sportRegulationTypeList', action.data);
		}

		case actions.CATEGORY_LEAGUE_REGULATION_TYPE_LIST_REFRESH: {
			return state.set('leagueRegulationTypeList', action.data);
		}

		case actions.CATEGORY_MODAL_UI_REFRESH: {
			const target = state.get('modalUI');
			const result = fill(action.data, target);
			return state.set('modalUI', result);
		}

		case actions.CATEGORY_IS_CHANGED_REFRESH: {
			const target = state.get('isChanged');
			const result = fill(action.data, target);
			return state.set('isChanged', result);
		}

		case actions.CATEGORY_IMAGES_REFRESH: {
			const target = state.get('images');
			const result = fill(action.data, target);
			return state.set('images', result);
		}

		case actions.CATEGORY_MARKETS_UI_REFRESH: {
			const target = state.get('marketsUI');
			const result = fill(action.data, target);
			return state.set('marketsUI', result);
		}

		case actions.SPORT_CATEGORY_SEO_DATA_REFRESH: {
			return state.set('seoData', action.data);
		}

		case actions.CATEGORY_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
