import { combineReducers } from 'redux';
import Game from './reportsByGame/reducer';
import Integrator from './reportsByIntegrator/reducer';
import Player from './reportsByPlayer/reducer';
import Provider from './reportsByProvider/reducer';
import Transactions from './transactions/reducer';

export default combineReducers({
	Game,
	Integrator,
	Player,
	Provider,
	Transactions,
});
