import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { usersAPI } from '../../../helpers/api/users';
import notifications from '../../../helpers/notifications';
import ParamsBuilder from '../../../helpers/paramsBuilder';

import actions from './actions';
import { logger } from '../../../helpers/logger';

const prefix = 'user.sportsstats';

const messages = {
	errorLoadStatistic: `${prefix}.errorLoadStatistic`,
};

function getStoreData(state) {
	const UI = state.Users.SportStatistic.get('UI');
	return {
		UI,
	};
}

function getParams(userID, filter) {
	const builder = new ParamsBuilder();

	builder.addValue('id', userID);
	builder.addValue('start_date', filter.dateFrom);
	builder.addValue('end_date', filter.dateTo);
	const params = builder.biuldParams({});

	return params;
}

function* dataReload() {

	yield takeEvery(actions.USER_SPORT_STATISTIC_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { userID } = action.data;
		const storeData = yield select(getStoreData);
		const params = getParams(userID, storeData.UI);
		try {
			const response = yield call(usersAPI.getUserSportStatistic, params);
			if (response && response.status === 200) {
				yield put(actions.sportsListRefresh(response.data.data));
			}
		} catch (error) {
			notifications.showError(messages.errorLoadStatistic, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

export default function* statisticSaga() {
	yield all([fork(dataReload)]);
}
