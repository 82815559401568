import { apiRequest } from './index';

function tournaments(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/admin/tournaments',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function tournament(tournamentID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/admin/tournaments/${tournamentID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}


export const tournamentsAPI = {
	tournaments,
	tournament,
};
