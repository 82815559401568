import { all, fork } from 'redux-saga/effects';

import GameGroupSaga from './game/saga';
import GamesSearchResult from './searchResults/saga';
import CustomProviderGroupSaga from './customProvider/saga';
import CategoryGroupSaga from './category/saga';
import TagsGroupSaga from './tags/saga';

export default function* usersRootSaga() {
	yield all([
		fork(GameGroupSaga),
		fork(GamesSearchResult),
		fork(CustomProviderGroupSaga),
		fork(CategoryGroupSaga),
		fork(TagsGroupSaga),
	]);
}
