import { availableFields } from '../../../redux/reports/reportsByPlayer/utils';


const lang = {
	wonAmountTotal: 'reportByPlayer.wonAmountTotal',
	ggr           : 'reportByPlayer.ggr',
	spinsCount    : 'reportByPlayer.spinsCount',
	stakeTotal    : 'reportByPlayer.stakeTotal',
	integrator    : 'reportByPlayer.integrator',
	profitability : 'reportByPlayer.profitability',
	userID        : 'reportByPlayer.userID',
	username      : 'reportByPlayer.username',
	email         : 'reportByPlayer.email',
	currency      : 'reportByPlayer.currency',
	casinoGame    : 'reportByPlayer.casinoGame',
	providerName  : 'reportByPlayer.provider',
};
export const defaultReportFields = availableFields.map(fieldKey => {
	return {
		id     : fieldKey,
		intlID : lang[fieldKey],
		checked: true,
	};
});
