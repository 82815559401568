import { apiRequest } from './index';

/* ------------------------------------------------------------------------------------------------
    Main
------------------------------------------------------------------------------------------------ */

// get list of participants
function participantList(filter) {

	const req = {
		method: 'GET',
		url   : '/participants',
		params: filter,
	};
	return apiRequest(req);
}

// get data of Participant
function participantData(participantID, additionalParams = {}) {

	const params = {
		...additionalParams,
		model_type: 2, // BASE
	};
	const req = {
		method: 'GET',
		url   : `/participants/${participantID}`,
		params,
	};
	return apiRequest(req);
}
// add new participant
function participantAdd(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/participants',
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// update participant
function participantUpdate(participantID, data, additionalParams = {}) {

	const req = {
		method: 'POST',
		url   : `/participants/${participantID}`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// delete participant
function participantDelete(participantID) {
	const req = {
		method: 'DELETE',
		url   : `/participants/${participantID}`,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
    Additional
------------------------------------------------------------------------------------------------ */

/* --------------------------------------------------------------------------------------- Names */
// GET: participant's Names
function participantNamesList(participantID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/participants/${participantID}/names`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update participant's Names
function participantNamesUpdate(participantID, data, additionalParams = {}) {

	const req = {
		method: 'POST',
		url   : `/participants/${participantID}/names`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------- Descriptions (CMS) */
// GET: participant's Descriptions
function participantDescriptionsList(participantID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/participants/${participantID}/cms`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update participant's Descriptions
function participantDescriptionsUpdate(participantID, data, additionalParams = {}) {

	const req = {
		method: 'POST',
		url   : `/participants/${participantID}/cms`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------- Mapping */
// GET: mappings list
function participantMappingsList(participantID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/participants/${participantID}/mappings`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update mappings list
function participantMappingsUpdate(participantID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/participants/${participantID}/mappings`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// GET: providers list
function participantProviderMappingsList(providerID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/provider/${providerID}/participants`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: mapping participant
function participantMappingSet(participantID, providerID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/provider/${providerID}/mappings/${participantID}/participants`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
    Members
------------------------------------------------------------------------------------------------ */
// GET: members list
function membersList(additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/participantMember',
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// GET: member info
function memberData(memberID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/participantMember/${memberID}`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: create new member
function memberAdd(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/participantMember',
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// POST: update member
function memberUpdate(memberID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/participantMember/${memberID}`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// DELETE: delete member
function memberDelete(memberID) {
	const req = {
		method: 'DELETE',
		url   : `/participantMember/${memberID}`,
	};
	return apiRequest(req);
}

// GET: translations of member
function memberTranslationList(memberID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/participantMember/${memberID}/translation`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update translations of member
function memberTranslationUpdate(memberID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/participantMember/${memberID}/translation`,
		data,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// GET: members list of Participant
function participantMembersList(participantID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/participants/${participantID}/members`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update members list of Participant
function participantMembersUpdate(participantID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/participants/${participantID}/members`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
    Others
------------------------------------------------------------------------------------------------ */

function participantAgeList(additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/participantAge/list',
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

function participantAgeAdd(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/participantAge',
		data,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

function participantAgeUpdate(ageID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/participantAge/${ageID}`,
		data,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

function participantAgeDelete(ageID) {
	const req = {
		method: 'DELETE',
		url   : `/participantAge/${ageID}`,
	};
	return apiRequest(req);
}


export const participantsAPI = {

	// Main
	participantList,
	participantData,
	participantAdd,
	participantUpdate,
	participantDelete,

	// Additional
	participantNamesList,
	participantNamesUpdate,
	participantDescriptionsList,
	participantDescriptionsUpdate,

	// Mapping
	participantMappingsList,
	participantMappingsUpdate,
	participantProviderMappingsList,
	participantMappingSet,

	// Members
	membersList,
	memberData,
	memberAdd,
	memberUpdate,
	memberDelete,
	memberTranslationList,
	memberTranslationUpdate,

	participantMembersList,
	participantMembersUpdate,

	// Others
	participantAgeList,
	participantAgeAdd,
	participantAgeUpdate,
	participantAgeDelete,
};
