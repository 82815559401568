import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import ipSaga from './ip/saga';

export default function* blockedIpsSaga() {
	yield all([
		fork(listSaga),
		fork(ipSaga),
	]);
}
