import { Map } from 'immutable';
import { getToken, getUser, getPermissions } from '../../helpers/utility';
import { fill } from '../../helpers/utils';
import actions from './actions';

const restoreRememberMe = () => {
	try {
		return JSON.parse(localStorage.getItem('remember')) || false;
	} catch (e) {
		return  false;
	}
};
const initState = new Map({
	idToken    : getToken(),
	user       : getUser(),
	permissions: getPermissions(),
	UI         : {
		loading   : false,
		rememberMe: restoreRememberMe(),
	},
});

export default function authReducer(state = initState, action) {

	switch (action.type) {

		case actions.LOGIN_SUCCESS: {
			const { token, user } = action.data;
			return state.set('idToken', token).set('user', user);
		}

		case actions.LOGOUT_CASE:
			return initState.set('UI', {
				loading   : false,
				rememberMe: restoreRememberMe(),
			}).set('idToken', null);

		case actions.AUTH_USER_DATA_REFRESH: {
			const { userData } = action.data;
			const target = state.get('user');
			const result = fill(userData, target);
			return state.set('user', result);
		}

		case actions.UI_REFRESH: {
			const { data } = action;
			const target = state.get('UI');
			const result = fill(data, target, true);
			return state.set('UI', result);
		}

		case actions.PERMISSIONS_REFRESH: {
			const { permissions } = action.data;
			return state.set('permissions', permissions);
		}

		default:
			return state;
	}
}
