import isArray from 'lodash/isArray';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../utility';

export const fields = {
	id         : 'event_id',
	name       : 'event_name',
	startDate  : 'start_date',
	sportName  : 'sport_name',
	countryName: 'country_name',
	leagueName : 'league_name',
	category   : 'category',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptUpcomingEventsList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.category = `${item.sportName} / ${item.countryName}`;
		if (item.leagueName) {
			item.category += ` / ${item.leagueName}`;
		}
	});
	const entities = createEntities(adaptedData);

	return entities;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'sportName', fields.sportName);
	adapter.addField(rules.string, 'countryName', fields.countryName);
	adapter.addField(rules.string, 'leagueName', fields.leagueName);
	adapter.addField(rules.string, 'category', fields.category);

	adapter.addField(rules.dateTime, 'startDate', fields.startDate);

	return adapter;
}
