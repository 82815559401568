import { all, fork } from 'redux-saga/effects';
import filter from './filter/saga';
import sports from './sports/saga';

export default function* sportsProritiesSaga() {
	yield all([
		fork(filter),
		fork(sports),
	]);
}
