import antdSA from 'antd/lib/locale/de_DE';
import saMessages from '../locales/de_DE.json';

const saLang = {
	messages: {
		...saMessages,
	},
	antd  : antdSA,
	locale: 'de',
	// data: appLocaleData,
};
export default saLang;
