const actions = {

	RTM_USER_CASINO_LIST_RELOAD : 'RTM_USER_CASINO_LIST_RELOAD',
	RTM_USER_CASINO_DATA_REFRESH: 'RTM_USER_CASINO_DATA_REFRESH',

	RTM_USER_CASINO_FILTER_REFRESH: 'RTM_USER_CASINO_FILTER_REFRESH',
	RTM_USER_CASINO_FILTER_APPLY  : 'RTM_USER_CASINO_FILTER_APPLY',

	RTM_USER_CASINO_UI_REFRESH: 'RTM_USER_CASINO_UI_REFRESH',

	listReload: () => ({
		type: actions.RTM_USER_CASINO_LIST_RELOAD,
	}),
	dataRefresh: (transactionIDs, entities, casinoTotals) => ({
		type: actions.RTM_USER_CASINO_DATA_REFRESH,
		data: {
			transactionIDs,
			entities,
			casinoTotals,
		},
	}),
	filterRefresh: data => ({
		type: actions.RTM_USER_CASINO_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.RTM_USER_CASINO_FILTER_APPLY,
	}),
	uiRefresh: data => ({
		type: actions.RTM_USER_CASINO_UI_REFRESH,
		data,
	}),
};

export default actions;
