import { Map } from 'immutable';
import invert from 'lodash/invert';
import cloneDeep from 'lodash/cloneDeep';
import { fill } from '../../../helpers/utils';
import { USER_LOG_MODELS } from '../../../helpers/commonConstants';

import actions from './actions';
import { logger } from '../../../helpers/logger';

const modelsMap = invert(cloneDeep(USER_LOG_MODELS));

const initState = new Map({
	all     : [],
	user    : [],
	gambling: [],

	UI: {
		loading: false,
	},
});

export default function userHistoryReducer(state = initState, action) {

	switch (action.type) {

		case actions.USER_HISTORY_MODEL_DATA_REFRESH: {
			const { logData, logModel } = action.data;
			const stateDataName = modelsMap[logModel];
			if (!stateDataName) {
				logger.error(`UserHistoryReducer: Unknown log model ${logModel}`);
				return state;
			}

			return state.set(stateDataName, logData);
		}

		case actions.USER_HISTORY_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
