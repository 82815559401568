import { makeActionCreator } from '../../../helpers';

const prefix = 'RESULT_DETAILS';

const TYPES = {
	DATA_RELOAD : `${prefix}_DATA_RELOAD`,
	DATA_REFRESH: `${prefix}_DATA_REFRESH`,
	DATA_UPDATE : `${prefix}_DATA_UPDATE`,
	DATA_RESET  : `${prefix}_DATA_RESET`,

	MARKET_UPDATE: `${prefix}_MARKET_UPDATE`,
	UI_REFRESH   : `${prefix}_UI_REFRESH`,

	DO_UNSETTLE: `${prefix}_DO_UNSETTLE`,

	TYPES_REFRESH: `${prefix}_TYPES_REFRESH`,
};

const actions = {
	...TYPES,

	dataReload : makeActionCreator(TYPES.DATA_RELOAD, 'eventID'),
	dataRefresh: makeActionCreator(TYPES.DATA_REFRESH, 'resultData'),
	dataUpdate : makeActionCreator(TYPES.DATA_UPDATE),
	dataReset  : makeActionCreator(TYPES.DATA_RESET),

	marketUpdate: makeActionCreator(TYPES.MARKET_UPDATE, 'marketID', 'onSuccess'),
	uiRefresh   : makeActionCreator(TYPES.UI_REFRESH, 'UI'),

	doUnsettle: makeActionCreator(TYPES.DO_UNSETTLE, 'name', 'id', 'onSuccess'),

	typesRefresh: makeActionCreator(TYPES.TYPES_REFRESH, 'displayTypes'),
};

export default actions;
