const prefix = 'USER_LIMITS_RESTRICT_BY_CATEGORY_';

export const actions = {
	RESTRICT_BY_CATEGORY_REFRESH           : `${prefix}REFRESH`,
	RESTRICT_BY_CATEGORY_ASSOC_LIST_REFRESH: `${prefix}ASSOC_LIST_REFRESH`,
	RESTRICT_BY_CATEGORY_RELOAD            : `${prefix}RELOAD`,
	RESTRICT_BY_CATEGORY_SAVE              : `${prefix}SAVE`,
	RESTRICT_BY_CATEGORY_UI_REFRESH        : `${prefix}UI_REFRESH`,

	dataReload: data => (
		{
			type: actions.RESTRICT_BY_CATEGORY_RELOAD,
			data,
		}
	),

	dataSave: data => (
		{
			type: actions.RESTRICT_BY_CATEGORY_SAVE,
			data,
		}
	),

	uiRefresh: data => (
		{
			type: actions.RESTRICT_BY_CATEGORY_UI_REFRESH,
			data,
		}
	),

	// categoryAssociativeListRefresh: data => (
	//     {
	//         type: actions.RESTRICT_BY_CATEGORY_ASSOC_LIST_REFRESH,
	//         data,
	//     }
	// ),

	categoriesListRefresh: data => (
		{
			type: actions.RESTRICT_BY_CATEGORY_REFRESH,
			data,
		}
	),
};
