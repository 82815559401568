const actions = {

	NEWS_ITEM_SAVE             : 'NEWS_ITEM_SAVE',
	NEWS_ITEM_DATA_RESET       : 'NEWS_ITEM_DATA_RESET',
	NEWS_ITEM_BASE_DATA_RELOAD : 'NEWS_ITEM_BASE_DATA_RELOAD',
	NEWS_ITEM_BASE_DATA_REFRESH: 'NEWS_ITEM_BASE_DATA_REFRESH',

	NEWS_ITEM_UI_REFRESH: 'NEWS_ITEM_UI_REFRESH',

	itemSave: itemID => ({
		type: actions.NEWS_ITEM_SAVE,
		data: {
			itemID,
		},
	}),
	dataReset: () => ({
		type: actions.NEWS_ITEM_DATA_RESET,
	}),
	baseDataReload: itemID => ({
		type: actions.NEWS_ITEM_BASE_DATA_RELOAD,
		data: {
			itemID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.NEWS_ITEM_BASE_DATA_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.NEWS_ITEM_UI_REFRESH,
		data,
	}),
};

export default actions;
