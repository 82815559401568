const actions = {

	PARTICIPANTS_AGES_LIST_RELOAD : 'PARTICIPANTS_AGES_LIST_RELOAD',
	PARTICIPANTS_AGES_LIST_REFRESH: 'PARTICIPANTS_AGES_LIST_REFRESH',

	PARTICIPANTS_AGES_SAVE_AGE  : 'PARTICIPANTS_AGES_SAVE_AGE',
	PARTICIPANTS_AGES_DELETE_AGE: 'PARTICIPANTS_AGES_DELETE_AGE',

	PARTICIPANTS_AGES_UI_REFRESH: 'PARTICIPANTS_AGES_UI_REFRESH',

	PARTICIPANTS_AGES_CHANGED_ID_ADD   : 'PARTICIPANTS_AGES_CHANGED_ID_ADD',
	PARTICIPANTS_AGES_CHANGED_IDS_RESET: 'PARTICIPANTS_AGES_CHANGED_IDS_RESET',

	listReload: () => ({
		type: actions.PARTICIPANTS_AGES_LIST_RELOAD,
	}),
	listRefresh: list => ({
		type: actions.PARTICIPANTS_AGES_LIST_REFRESH,
		data: list,
	}),

	saveAge: data => ({
		type: actions.PARTICIPANTS_AGES_SAVE_AGE,
		data,
	}),
	deleteAge: ageID => ({
		type: actions.PARTICIPANTS_AGES_DELETE_AGE,
		data: {
			ageID,
		},
	}),

	uiRefresh: data => ({
		type: actions.PARTICIPANTS_AGES_UI_REFRESH,
		data,
	}),

	changedIDAdd: ageID => ({
		type: actions.PARTICIPANTS_AGES_CHANGED_ID_ADD,
		data: {
			ageID,
		},
	}),
	changedIDsReset: () => ({
		type: actions.PARTICIPANTS_AGES_CHANGED_IDS_RESET,
	}),
};

export default actions;
