import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import isArray from 'lodash/isArray';

import notifications from '../../../helpers/notifications';
import { usersAPI } from '../../../helpers/api/users';
import { AUTOCOMPLETE_SOURCES } from '../../../helpers/commonConstants';
import { storeUsersFilter } from '../../../helpers/utility';
import { getHeadersTotalCount } from '../../../helpers/utils';

import actions from './actions';
import { logger } from '../../../helpers/logger';

const prefix = 'users.filter';

const messages = {
	errorRiskGroupLoad   : `${prefix}.errorRiskGroupLoad`,
	errorAutocompleteLoad: `${prefix}.errorAutocompleteLoad`,
};

function getStoreData({ Users }) {
	const { Filter } = Users;

	return {
		filter: Filter.get('filter'),
	};
}

function* riskGoupsReload() {

	yield takeEvery(actions.USERS_FILTER_RISK_GROUP_LIST_RELOAD, function* () {
		try {
			const response = yield call(usersAPI.getRiskGroupsList);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.riskGoupsRefresh(list));
			}
		} catch (error) {
			notifications.showError(messages.errorRiskGroupLoad, error);
			logger.log(error);
		}
	});
}

function* dataSourceReloadMail() {

	yield takeEvery(actions.USERS_FILTER_DATA_SOURCE_AUTOCOMPLETE_MAIL_RELOAD, function* (action) {
		const { value, type, pagination, limit, search } = action.data;
		const params = {
			value,
			field: type,
			limit,
			page : pagination,
			search,
		};

		let list = [];
		let hasMore;

		try {
			const response = yield call(usersAPI.getDataAutocoplete, params);
			if (response && response.status === 200) {
				const serverData = response.data.data;
				hasMore = getHeadersTotalCount(response.headers) > 0;

				if (isArray(serverData)) {
					list = serverData.filter(item => item !== null);
				}
			}
		} catch (error) {
			notifications.showError(messages.errorAutocompleteLoad, error);
			logger.log(error);
		}

		switch (type) {
			case AUTOCOMPLETE_SOURCES.userName:
				yield put(actions.dataSourceUserNameRefresh(list));
				break;

			case AUTOCOMPLETE_SOURCES.userEmail:
				if ( limit && pagination ) {
					if (search) yield put(actions.dataSourceUserEmailRefresh(list));
					else yield put(actions.dataSourceUserEmailRefreshScroll(list));
					yield put(actions.dataHasMore({ email: hasMore }));
				} else {
					yield put(actions.dataSourceUserEmailRefresh(list));
				}
				break;

			case AUTOCOMPLETE_SOURCES.userFirstName:
				yield put(actions.dataSourceFirstNameRefresh(list));
				break;

			case AUTOCOMPLETE_SOURCES.userLastName:
				yield put(actions.dataSourceLastNameRefresh(list));
				break;

			default:
		}
	});
}


function* dataSourceReload() {

	yield takeEvery(actions.USERS_FILTER_DATA_SOURCE_AUTOCOMPLETE_RELOAD, function* (action) {
		const { value, type } = action.data;
		const params = {
			value,
			field: type,
		};

		let list = [];

		try {
			const response = yield call(usersAPI.getDataAutocoplete, params);
			if (response && response.status === 200) {
				const serverData = response.data.data;
				if (isArray(serverData)) {
					list = serverData.filter(item => item !== null);
				}
			}
		} catch (error) {
			notifications.showError(messages.errorAutocompleteLoad, error);
			logger.log(error);
		}

		switch (type) {
			case AUTOCOMPLETE_SOURCES.userName:
				yield put(actions.dataSourceUserNameRefresh(list));
				break;

			case AUTOCOMPLETE_SOURCES.userEmail:
				yield put(actions.dataSourceUserEmailRefresh(list));
				break;

			case AUTOCOMPLETE_SOURCES.userFirstName:
				yield put(actions.dataSourceFirstNameRefresh(list));
				break;

			case AUTOCOMPLETE_SOURCES.userLastName:
				yield put(actions.dataSourceLastNameRefresh(list));
				break;

			default:
		}
	});
}

function* dataStore() {

	yield takeEvery(actions.USERS_FILTER_DATA_STORE, function* () {
		try {
			const { filter } = yield select(getStoreData);
			yield call(storeUsersFilter, filter);

		} catch (error) {
			logger.log(error);
		}
	});
}

export default function* filterSaga() {
	yield all([
		fork(riskGoupsReload),
		fork(dataSourceReload),
		fork(dataSourceReloadMail),
		fork(dataStore),
	]);
}
