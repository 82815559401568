import styled from 'styled-components';

import WithDirection from '../../config/withDirection';
// import { Layout } from 'antd';
// const { Header } = Layout;

export const TopBarContainerDiv = styled.div`

  display: flex;
  align-items: center;  
  position: sticky !important;
  top: 0;
  padding: 0 16px;
  background-color: #fff;
  z-index: 1000;
  .isoLeft {
    display: flex;
    span {
      margin-right: 16px;
      font-size: 20px;
    }

    .ant-input-group {
      .ant-input {
        vertical-align: bottom;
			}
		}
  }
  .isoNotify, .isoUser {
    cursor: pointer;
  }
  .iso-right {
    margin-left: auto;
    align-items: center;
    li {
      margin-right: 16px;
      svg {
        vertical-align: top;
      }
      .isoIconWrapper {
        position: relative;
        line-height: normal;
        .count {
          color: #fff;
          font-size: 10px;
          width: 22px;
          height: 22px;
          background-color: #3775F2;
          position: absolute;
          top: -10px;
          left: 12px;
          right: inherit;
          border-radius: 50%;

          display: inline-flex;
          justify-content: center;
          text-align: center;
          line-height: 20px;
          
          display: -webkit-inline-flex;
          display: -ms-inline-flex;
          display: -webkit-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          -webkit-align-items: center;
          -webkit-box-align: center;
        }
      }
    }
  }
  ul {
    display: flex;
    list-style: none;
  }

    @media only screen and (max-width: 1100px) {
        overflow-x: scroll;
        height: 95px !important;
    }
`;
export const TopBarContainer = WithDirection(TopBarContainerDiv);
