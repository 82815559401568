import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import actions from './actions';

import { marketsAPI } from '../../../../helpers/api/markets';
import notifications from '../../../../helpers/notifications';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { isID } from '../../../../helpers/utils';
import { logger } from '../../../../helpers/logger';

const prefix = 'sport.settings.ladders';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
	errorUpdate    : `${prefix}.errorUpdate`,
	errorDelete    : `${prefix}.errorDelete`,
	successUpdate  : `${prefix}.successUpdate`,
	successDelete  : `${prefix}.successDelete`,
};

function getStoreData({ Sport: { Settings } }) {

	return {
		filter: Settings.Ladders.get('filter'),
		list  : Settings.Ladders.get('list'),
	};
}

function getParams(filter) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addField(rules.isPositiveNumber, 'from', 'from');
	builder.addField(rules.isPositiveNumber, 'to', 'to');

	const params = builder.biuldParams(filter);

	return params;
}

function* listReload() {

	yield takeEvery(actions.MARKETS_LADDERS_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { filter } = storeData;

		const params = getParams(filter);

		let list = [];
		try {
			const response = yield call(marketsAPI.laddersList, params);
			if (response && response.status === 200) {
				list = response.data.data;
			}
		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(list));
		yield put(actions.changedIDsReset());
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* deleteLadder() {

	yield takeEvery(actions.MARKETS_LADDERS_DELETE_LADDER, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { ladderID } = action.data;
		try {
			const response = yield call(marketsAPI.ladderRemove, ladderID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successDelete);
			}
		} catch (error) {
			notifications.showError(messages.errorDelete, error);
			logger.log(error);
		}

		yield put(actions.listReload());
	});
}

function* updateAll() {

	yield takeEvery(actions.MARKETS_LADDERS_UPDATE_ALL, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { list } = storeData;

		const preparedList = list.map(item => {
			const newItem = {
				odd: item.odd,
			};
			if (isID(item.id)) {
				newItem.id = toInteger(item.id);
			}

			return newItem;
		});

		try {
			const response = yield call(marketsAPI.ladderUpdateList, preparedList);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successUpdate);
			}
		} catch (error) {
			notifications.showError(messages.errorUpdate, error);
			logger.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* marketsLaddersSaga() {
	yield all([
		fork(listReload),
		fork(deleteLadder),
		fork(updateAll),
	]);
}
