import { isJSON } from '../../../../helpers/utils';
import { ITransactionsData } from '../../../../redux/users/userInfo/transactions/allTransactions/types';
import { PERMISSIONS } from '../../../../helpers/permissions/constants';
import { availableFields } from '../../../../redux/users/userInfo/transactions/allTransactions/utils';

const lang = {
	pageTitle              : 'transactions.title' ,
	ID                     : 'transactions.columns.ID',
	typeID                 : 'transactions.columns.typeID',
	statusID               : 'transactions.columns.statusID',
	externalReferenceNumber: 'transactions.columns.externalReferenceNumber',
	user                   : 'transactions.columns.user',
	userID                 : 'transactions.columns.userID',
	moneyTypeID            : 'transactions.columns.moneyTypeID',
	amount                 : 'transactions.columns.amount',
	amountUSD              : 'transactions.columns.amountUSD',
	currencyID             : 'transactions.columns.currencyID',
	productID              : 'transactions.columns.productID',
	data                   : 'transactions.columns.data',
	balanceBefore          : 'transactions.columns.balanceBefore',
	balanceAfter           : 'transactions.columns.balanceAfter',
	channelID              : 'transactions.columns.channelID',
	ip                     : 'transactions.columns.ip',
	ipCountry              : 'transactions.columns.ipCountry',
	sourceID               : 'transactions.columns.sourceID',
	code                   : 'transactions.columns.code',
	sourceTypeID           : 'transactions.columns.sourceTypeID',
	bonusType              : 'transactions.columns.bonusType',
	sourceAccount          : 'transactions.columns.sourceAccount',
	sourceAmount           : 'transactions.columns.sourceAmount',
	bonusEligibility       : 'transactions.columns.bonusEligibility',
	bonusPercentage        : 'transactions.columns.bonusPercentage',
	bonusAmount            : 'transactions.columns.bonusAmount',
	totalBonus             : 'transactions.columns.totalBonus',
	actionNumber           : 'transactions.columns.actionNumber',
	created                : 'transactions.columns.date',
	viewInModal            : 'transactions.columns.viewInModal',
	noData                 : 'transactions.columns.noData',
	no                     : 'common.no',
};

export const defaultReportFields = availableFields.map(fieldKey => {
	return {
		id     : fieldKey,
		intlID : lang[fieldKey],
		checked: true,
	};
});

const TRANSACTIONS_TABS = {
	all: 'all',
};

const renderDataFormModal = (record: ITransactionsData, colName:string) => {
	let idFormModal = 0;
	const dataFormModal = {};
	const recordData = record.data.data;
	if (recordData) {
		if (JSON.parse(recordData) !== '') {
			const objKey = colName + idFormModal;
			dataFormModal[objKey] = {};
			idFormModal++;
			const recordParsedData = JSON.parse(recordData);
			const recursionFunction = (data:JSON) => {
				Object.entries(data).forEach(([key, value]) => {

					if  (isJSON(value)) {
						value = JSON.parse(value);
					}

					if (value instanceof Object && !Array.isArray(value)) {
						recursionFunction(value);
					} else {
						if (Array.isArray(value)) {
							return;
						}
						if (typeof value === 'boolean' || value === null ) {
							value = JSON.stringify(value);
						}
						dataFormModal[objKey][key] = value;
					}

				});
			};
			recursionFunction(recordParsedData);
			return objKey;
		}
		return 'empty';
	}
	return 'empty';
};

const canReadTransaction = (permissions) => {
	return (permissions.super_admin || permissions[PERMISSIONS.accessCustomerTransactionsAll]);
};

export {
	TRANSACTIONS_TABS,
	renderDataFormModal,
	canReadTransaction,
};