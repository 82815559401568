import { createSelector } from 'reselect';

const status								= (state) => state.Socket.get('status');
const rtmSubscribedType						= (state) => state.Socket.get('rtmSubscribedType');
const riskManagementBetsSubscribedType		= (state) => state.Socket.get('riskManagementBetsType');
const riskManagementMarketIDsSubscribedType	= (state) => state.Socket.get('riskManagementMarketIDType');

export const deriveSocketStatus = createSelector(
	[status],
	(status) => status
);

export const deriveSubscribedTypeRTM = createSelector(
	[rtmSubscribedType],
	(rtmSubscribedType) => rtmSubscribedType
);

export const deriveSubscribedTypeRiskManagementBets = createSelector(
	[riskManagementBetsSubscribedType],
	(riskManagementBetsType) => riskManagementBetsType
);

export const deriveSubscribedTypeRiskManagementMarketIDs = createSelector(
	[riskManagementMarketIDsSubscribedType],
	(riskManagementBetsType) => riskManagementBetsType
);

