const actions = {

	MARKET_BASE_DATA_RELOAD : 'MARKET_BASE_DATA_RELOAD',
	MARKET_BASE_DATA_REFRESH: 'MARKET_BASE_DATA_REFRESH',

	MARKET_NAMES_DATA_RELOAD : 'MARKET_NAMES_DATA_RELOAD',
	MARKET_NAMES_DATA_REFRESH: 'MARKET_NAMES_DATA_REFRESH',

	MARKET_ORDERS_DATA_RELOAD : 'MARKET_ORDERS_DATA_RELOAD',
	MARKET_ORDERS_DATA_REFRESH: 'MARKET_ORDERS_DATA_REFRESH',

	MARKET_CMS_DATA_RELOAD : 'MARKET_CMS_DATA_RELOAD',
	MARKET_CMS_DATA_REFRESH: 'MARKET_CMS_DATA_REFRESH',

	MARKET_SETTINGS_DATA_RELOAD : 'MARKET_SETTINGS_DATA_RELOAD',
	MARKET_SETTINGS_DATA_REFRESH: 'MARKET_SETTINGS_DATA_REFRESH',

	MARKET_DYNAMIC_DATA_RELOAD : 'MARKET_DYNAMIC_DATA_RELOAD',
	MARKET_DYNAMIC_DATA_REFRESH: 'MARKET_DYNAMIC_DATA_REFRESH',

	MARKET_MAPPINGS_DATA_RELOAD : 'MARKET_MAPPINGS_DATA_RELOAD',
	MARKET_MAPPINGS_DATA_REFRESH: 'MARKET_MAPPINGS_DATA_REFRESH',

	MARKET_RESULT_RULES_DATA_RELOAD : 'MARKET_RESULT_RULES_DATA_RELOAD',
	MARKET_RESULT_RULES_DATA_REFRESH: 'MARKET_RESULT_RULES_DATA_REFRESH',

	MARKET_UPDATE          : 'MARKET_UPDATE',
	MARKET_MODAL_UI_REFRESH: 'MARKET_MODAL_UI_REFRESH',

	MARKET_DATA_RESET: 'MARKET_DATA_RESET',
	MARKET_DATA_COPY : 'MARKET_DATA_COPY',

	baseDataReload: marketID => ({
		type: actions.MARKET_BASE_DATA_RELOAD,
		data: {
			marketID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.MARKET_BASE_DATA_REFRESH,
		data,
	}),

	namesDataReload: marketID => ({
		type: actions.MARKET_NAMES_DATA_RELOAD,
		data: {
			marketID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.MARKET_NAMES_DATA_REFRESH,
		data,
	}),
	ordersDataReload: marketID => ({
		type: actions.MARKET_ORDERS_DATA_RELOAD,
		data: {
			marketID,
		},
	}),
	ordersDataRefresh: data => ({
		type: actions.MARKET_ORDERS_DATA_REFRESH,
		data,
	}),
	cmsDataReload: marketID => ({
		type: actions.MARKET_CMS_DATA_RELOAD,
		data: {
			marketID,
		},
	}),
	cmsDataRefresh: data => ({
		type: actions.MARKET_CMS_DATA_REFRESH,
		data,
	}),
	settingsDataReload: marketID => ({
		type: actions.MARKET_SETTINGS_DATA_RELOAD,
		data: {
			marketID,
		},
	}),
	settingsDataRefresh: data => ({
		type: actions.MARKET_SETTINGS_DATA_REFRESH,
		data,
	}),
	dynamicDataReload: marketID => ({
		type: actions.MARKET_DYNAMIC_DATA_RELOAD,
		data: {
			marketID,
		},
	}),
	dynamicDataRefresh: data => ({
		type: actions.MARKET_DYNAMIC_DATA_REFRESH,
		data,
	}),
	mappingsDataReload: marketID => ({
		type: actions.MARKET_MAPPINGS_DATA_RELOAD,
		data: {
			marketID,
		},
	}),
	mappingsDataRefresh: data => ({
		type: actions.MARKET_MAPPINGS_DATA_REFRESH,
		data,
	}),

	rulesDataReload: marketID => ({
		type: actions.MARKET_RESULT_RULES_DATA_RELOAD,
		data: {
			marketID,
		},
	}),

	rulesDataRefresh: list => ({
		type: actions.MARKET_RESULT_RULES_DATA_REFRESH,
		data: list,
	}),

	marketDataCopy: marketID => ({
		type: actions.MARKET_DATA_COPY,
		data: marketID,
	}),

	marketUpdate: () => ({
		type: actions.MARKET_UPDATE,
	}),
	modalUIRefresh: data => ({
		type: actions.MARKET_MODAL_UI_REFRESH,
		data,
	}),
	marketDataReset: () => ({
		type: actions.MARKET_DATA_RESET,
	}),
};

export default actions;
