import { all, fork } from 'redux-saga/effects';

import betsSaga from './bets/saga';
import depositsSaga from './deposits/saga';
import withdrawalsSaga from './withdrawals/saga';
import casinoSaga from './casino/saga';
import documentsSaga from './documents/saga';
import riskManagementSaga from './riskManagement/saga';

import reportBetsSaga from './reportBets/saga';
import reportDepositsSaga from './reportDeposits/saga';
import reportWithdrawalsSaga from './reportWithdrawals/saga';
import reportCasinoSaga from './reportCasino/saga';

import userBetsSaga from './userBets/saga';
import userDepositsSaga from './userDeposits/saga';
import userWithdrawalsSaga from './userWithdrawals/saga';
import userCasinoSaga from './userCasino/saga';

export default function* rtmRootSaga() {
	yield all([
		fork(betsSaga),
		fork(depositsSaga),
		fork(withdrawalsSaga),
		fork(casinoSaga),
		fork(documentsSaga),
		fork(riskManagementSaga),

		fork(reportBetsSaga),
		fork(reportDepositsSaga),
		fork(reportWithdrawalsSaga),
		fork(reportCasinoSaga),

		fork(userBetsSaga),
		fork(userDepositsSaga),
		fork(userWithdrawalsSaga),
		fork(userCasinoSaga),
	]);
}
