import validate from 'validate.js';

validate.options = { format: 'flat' };

const prefix = 'profile.modal';

const messages = {
	errorMatchPass: `${prefix}.errorMatchPass`,
};

const changePasswordConstrants = {
	password: {
		presence: true,
		length  : {
			minimum: 6,
			message: '^profile.modal.passMin6',
		},
	},
	currentPassword: {
		presence: true,
		length  : {
			minimum: 6,
			message: '^profile.modal.passMin6',

		},
	},
};

export function validatePassword(data) {

	let errors = [];
	const commonErrors = validate(data, changePasswordConstrants);
	if (commonErrors) {
		errors = errors.concat(commonErrors);
	}
	if (data.password !== data.confirmPassword) {
		errors.push(messages.errorMatchPass);
	}

	return errors.length > 0 ? errors : null;
}
