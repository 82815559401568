import { all, put, fork, call, select, takeEvery } from 'redux-saga/effects';
import notifications from '../../../helpers/notifications';
import { dashboardAPI } from '../../../helpers/api/dashboard';
import { createCommonParams } from '../utils';
import actions from './actions';

const prefix = 'usersChart';

const messages = {
	errorChartData: `${prefix}.errorChartData`,
	chartNoData   : `${prefix}.chartNoData`,
};

function getStoreData({ Dashboard, App }) {
	return {
		period   : Dashboard.Common.get('period'),
		websiteID: App.get('websiteID'),
	};
}

function* usersChartDataReload() {

	yield takeEvery(actions.DASHBOARD_USERS_DATA_RELOAD, function* () {

		let dataToStore = [];
		yield put(actions.chartUIRefresh({ loading: true }));
		const { period, websiteID } = yield select(getStoreData);

		const params = createCommonParams(period, websiteID);
		try {
			const response = yield call(dashboardAPI.dashboardUsersChartReq, params);
			if (response && response.status === 200) {
				dataToStore = response.data.data;
				if (!response.data.data.length) {
					notifications.showWarning(messages.chartNoData);
				}
			}
		} catch (error) {
			notifications.showError(messages.errorChartData, error);
		}
		yield put(actions.chartDataRefresh(dataToStore));
		yield put(actions.chartUIRefresh({ loading: false }));
	});
}

export default function* marketingChartSaga() {
	yield all([
		fork(usersChartDataReload),
	]);
}
