const prefix = 'USER_LIMITS_RESTRICT_BY_GAMES_SEARCH_';

export const actions = {
	RESTRICT_BY_GAMES_REFRESH   : `${prefix}REFRESH`,
	RESTRICT_BY_GAMES_RELOAD    : `${prefix}RELOAD`,
	RESTRICT_BY_GAMES_SAVE      : `${prefix}SAVE`,
	RESTRICT_BY_GAMES_UI_REFRESH: `${prefix}UI_REFRESH`,

	dataReload: data => (
		{
			type: actions.RESTRICT_BY_GAMES_RELOAD,
			data,
		}
	),

	dataSave: data => (
		{
			type: actions.RESTRICT_BY_GAMES_SAVE,
			data,
		}
	),

	uiRefresh: data => (
		{
			type: actions.RESTRICT_BY_GAMES_UI_REFRESH,
			data,
		}
	),


	gamesListRefresh: data => (
		{
			type: actions.RESTRICT_BY_GAMES_REFRESH,
			data,
		}
	),
};
