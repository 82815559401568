import { apiRequest } from './index';
// AutoWithdraw settings ----------------------------------------------------------------------------------------

function AutoWithdrawSettingsList(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/auto_withdrawal_settings`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function AutoWithdrawSettingsCreate(websiteID, data,  params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/auto_withdrawal_settings`,
		data,
		params,
	};

	return apiRequest(req);
}

function AutoWithdrawSettingsUpdate(websiteID, settingId, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/auto_withdrawal_settings/${settingId}`,
		data,
		params,
	};

	return apiRequest(req);
}

function AutoWithdrawSettingsDelate(websiteID, settingId, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/websites/${websiteID}/auto_withdrawal_settings/${settingId}`,
		params,
	};

	return apiRequest(req);
}

export const AutoWithdrawalSettingsApi = {
	AutoWithdrawSettingsList,
	AutoWithdrawSettingsCreate,
	AutoWithdrawSettingsUpdate,
	AutoWithdrawSettingsDelate,
};
