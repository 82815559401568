import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import invert from 'lodash/invert';
import isArray from 'lodash/isArray';

import { showError } from '../../../helpers/notifications';
import { usersAPI } from '../../../helpers/api/users';
import { AUTOCOMPLETE_SOURCES } from '../../../helpers/commonConstants';

import actions from './actions';
import { logger } from '../../../helpers/logger';

const prefix = 'staff.filter';

const messages = {
	errorAutocompleteLoad: `${prefix}.errorAutocompleteLoad`,
};


const autocompleteSourceTypes = invert(AUTOCOMPLETE_SOURCES);

function* dataSourceReload() {

	yield takeEvery(actions.AUTOCOMPLETE_DATA_SOURCE_RELOAD, function* (action) {
		const { value, type } = action.data;
		const params = {
			value,
			field: type,
		};

		let dataSource = [];
		try {
			const res = yield call(usersAPI.getAdminDataAutocomplete, params);
			if (res && res.status === 200) {
				const serverData = res.data.data;
				if (isArray(serverData)) {
					dataSource = serverData.filter(item => item !== null);
				}
			}
		} catch (error) {
			showError(messages.errorAutocompleteLoad, error);
			logger.log(error);
		}

		const dataSourceType = autocompleteSourceTypes[type];
		yield put(actions.dataSourceRefresh(dataSource, dataSourceType));
	});
}

export default function* personalFilterSaga() {
	yield all([
		fork(dataSourceReload),
	]);
}
