import { entries } from 'lodash';
import { CHANNEL_TYPES } from '../../../helpers/commonConstants';
import { channelTypeList } from '../../../helpers/commonEnums';
import EntityAdapter from '../../../helpers/entityAdapter';


const fields = {
	casinoGameID  : 'casino_game_id',
	channelID     : 'channel_id',
	name          : 'name',
	altName       : 'alt_name',
	description   : 'description',
	langID        : 'lang_id',
	seoKeywords   : 'seo_keywords',
	seoTitle      : 'seo_title',
	seoDescription: 'seo_description',
	OGDescription : 'og_description',
	OGImage       : 'og_image',
	OGTitle       : 'og_title',
	alias         : 'alias',
};

const namesAdapter  = createNamesAdapter();
const seoAdapter  = createSeoAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptNamesData(rawData = [], defaultName = null) {
	namesAdapter.clearExcludes();

	const result = [];
	const adaptedList = namesAdapter.adaptList(rawData);

	channelTypeList.forEach( channelItem => {

		const channelID   = channelItem.id;
		const channelName = channelItem.name;

		const namesItems = adaptedList.filter(item => item.channelID === channelID);
		namesItems.forEach(item => {
			const record = {
				channelID,
				channel    : channelName,
				name       : item.name || '',
				altName    : item.altName || '',
				description: item.description || '',
				langID     : item.langID || 0,
			};
			if (channelID === CHANNEL_TYPES.backend && !record.name && defaultName) {
				record.name = defaultName;
			}

			result.push(record);
		});
	});

	return result;
}

export function prepareSeoData(rawData = {}, hasAlias) {
	const langIDs = Object.keys(rawData);
	seoAdapter.clearExcludes();
	hasAlias && seoAdapter.addExcludeField('alias');

	return langIDs.reduce((acc, langID) => {
		const data                = Object.assign({}, rawData[langID]);
		data[fields.langID]       = langID;

		const adapted             = seoAdapter.prepare(data);
		acc.push(adapted);
		return acc;
	}, []);

}

export function adaptSeoData(rawData = [], languageList = []) {

	const adapted =  rawData.reduce((acc, item) => {
		const langID = item[fields.langID];
		const data = seoAdapter.adapt(item);
		acc[langID] = data;
		return acc;
	}, {});

	languageList.forEach(websiteLang => {
		if (!adapted[websiteLang.id]) {
			adapted[websiteLang.id] = {
				langID        : websiteLang.id,
				SEOTitle      : '',
				SEODescription: '',
				SEOKeywords   : '',
				OGTitle       : '',
				OGDescription : '',
				OGImage       : '',
				alias         : '',
			};
		}

	});

	return adapted;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareNamesData(rawData = [], casinoGameID) {

	namesAdapter.clearExcludes();
	namesAdapter.addExcludeField('casinoGameID');
	namesAdapter.addExcludeField('langID');
	namesAdapter.addExcludeField('channel');
	const flattedNames = flatNamesData(rawData);

	return flattedNames.map(item => {
		return {
			alt_name      : item.altName,
			casino_game_id: casinoGameID,
			channel_id    : item.channelID,
			description   : item.description,
			lang_id       : item.langID,
			name          : item.name ? item.name : null,
		};
	});
}

// Adapters ---------------------------------------------------------------------------------------

function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'casinoGameID', fields.casinoGameID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'langID', fields.langID);

	adapter.addField(rules.string, 'channel', fields.channel);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'altName', fields.altName);
	adapter.addField(rules.string, 'description', fields.description);

	return adapter;
}

function createSeoAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.stringOrNull, 'SEOTitle', fields.seoTitle);
	adapter.addField(rules.stringOrNull, 'SEODescription', fields.seoDescription);
	adapter.addField(rules.stringOrNull, 'SEOKeywords', fields.seoKeywords);

	adapter.addField(rules.stringOrNull, 'OGTitle', fields.OGTitle);
	adapter.addField(rules.stringOrNull, 'OGDescription', fields.OGDescription);
	adapter.addField(rules.stringOrNull, 'OGImage', fields.OGImage);
	adapter.addField(rules.stringOrNull, 'alias', fields.alias);
	adapter.addField(rules.stringOrNull, 'langID', fields.langID);

	return adapter;
}

export const adaptNames = (list = []) => {
	return list.reduce((acc, item) => {
		if (acc[item.langID]) {
			acc[item.langID].push(item);
		} else {
			acc[item.langID] = [item];
		}
		return acc;
	}, {});
};


export const flatNamesData = (namesData = {}) => {
	const nameValues = entries(namesData);
	return nameValues.reduce((acc, item) => {
		const [langID, value] = item;
		value.langID = langID;
		acc.push(...value);
		return acc;
	}, []);
};
