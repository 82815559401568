import { makeActionCreator } from '../../helpers';

const prefix = 'RESPONSIBLE_GAMBLING';

const TYPES = {
	HISTORY_LOGS_RELOAD : `${prefix}_HISTORY_LOGS_RELOAD`,
	HISTORY_LOGS_REFRESH: `${prefix}_HISTORY_LOGS_REFRESH`,

	UI_REFRESH: `${prefix}_UI_REFRESH`,
};

const actions = {
	...TYPES,

	uiRefresh         : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
	historyLogsReload : makeActionCreator(TYPES.HISTORY_LOGS_RELOAD, 'userID'),
	historyLogsRefresh: makeActionCreator(TYPES.HISTORY_LOGS_REFRESH, 'historyLogs'),
};

export default actions;
