
export interface IResponsibleInitState {
	list    : IResponsibleAdapted[];
	entities: IResponsibleEntities;
	UI      : IResponsibleUI;
}

export interface IResponsibleEntities {
	[k: number]: IResponsibleAdapted;
}

export interface IResponsibleUI {
	loading: boolean;
}

export interface IResponsibleResponse {
	limitation_id: number;
	user_id      : number;
	status_id    : number;
	type         : number;
	duration     : RESPONSIBLE_PERIODS;
	start_date   : string;
	end_date     : string;
	value        : string;
	category_name: string;
}
export interface IResponsibleAdapted {
	limitationID: number;
	userID      : number;
	statusID    : RESPONSIBLE_STATUS_IDS;
	type        : RESPONSIBLE_TYPE_IDS;
	duration    : RESPONSIBLE_PERIODS;
	startDate   : string;
	endDate     : string;
	value       : string;
	loading     : boolean;
	categoryName: string;
}


export enum RESPONSIBLE_STATUS_IDS {
	ACTIVE = 1,
	DELETED = 2,
	EXPIRED = 3,
}

export enum RESPONSIBLE_TYPE_IDS {
	TIME_OUT = 1,
	AVAILABLE_TIME = 2,
	SELF_EXCLUSION = 3,
	DEPOSIT_LIMIT = 4
}

export enum RESPONSIBLE_PERIODS {
	_24H = '24h',
	_48H = '48h',
	_7D = '7d',
	_30D = '30d',
	CUSTOM = 'custom'
}
