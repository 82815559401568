import { Map } from 'immutable';
import { fill } from '../../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	customProviderList: [],

	baseData: {},

	UI: {
		loading: false,
	},

});

export default function userLimitsCasinoCustomProviderReducer(state = initState, action) {

	switch (action.type) {

		case actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_LIST_REFRESH: {
			return state.set('customProviderList', action.data);
		}

		case actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_BASEDATA_REFRESH: {
			return state.set('baseData', action.data);
		}

		case actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_UI_REFRESH: {
			const UI  = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}

		case actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_RESET: {
			return initState;
		}

		default:
			return state;

	}

}
