import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import { CASINO_GAMES_VIEW_TABS, CHANNEL_TYPES } from '../../../helpers/commonConstants';

import actions from './actions';
import cloneDeep from 'lodash/cloneDeep';
import { defaultValuesGamesFilter, initFilterTypes } from './utils';

const initState = new Map({

	providersWithGames: {},
	gamesList         : [],
	entities          : {},
	gamesListInModal  : [],
	searchValue       : '',
	gamesSearchList   : [],
	filter            : createFilter(filtersURL.gamesList, initFilterTypes, defaultValuesGamesFilter),

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},
	searchPagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	UI: {
		websiteID       : null,
		providerID      : null,
		customProviderID: null,
		casinoID        : null,
		loading         : false,
		channelID       : CHANNEL_TYPES.mobile,
		currentTab      : CASINO_GAMES_VIEW_TABS.grid,
		categoryID      : 0,
		categories      : [],
		isGamesChanged  : false,
		searchQuery     : '',
		hasMore         : false,
	},
});

export default function casinoGamesReducer(state = initState, action) {
	switch (action.type) {

		case actions.CASINO_GAMES_LIST_REFRESH: {
			return state.set('gamesList', action.data);
		}

		case actions.CASINO_GAMES_ENTITIES_REFRESH: {
			return state.set('entities', action.data);
		}

		case actions.CASINO_GAMES_LIST_MODAL_REFRESH: {
			return state.set('gamesListInModal', action.data);
		}

		case actions.CASINO_GAMES_SEARCH_VALUE: {
			return state.set('searchValue', action.data);
		}

		case actions.CASINO_GAMES_SEARCH_LIST_UPDATE: {
			const oldGames = state.get('gamesSearchList');
			const target = cloneDeep(oldGames);
			return state.set('gamesSearchList', [...target, ...action.data]);
		}

		case actions.CASINO_GAMES_SEARCH_LIST_REFRESH: {
			return state.set('gamesSearchList', action.data);
		}

		case actions.ADD_GAME_AS_ELIGIBLE: {
			const gameIDS = state.getIn(['eligability', 'gameIDS']).push(action.data);
			return state.setIn(['eligability', 'gameIDS'], gameIDS);
		}

		case actions.REMOVE_GAME_AS_ELIGIBLE: {
			return state.set('gamesList', action.data);
		}

		case actions.CASINO_GAMES_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.UPDATE_CATEGORIES: {
			const UI = state.get('UI' );
			return state.set('UI', { ...UI, categories: action.data });
		}

		case actions.UPDATE_SEARCH_QUERY: {
			const UI = state.get('UI' );
			return state.set('UI', { ...UI, searchQuery: action.data });
		}

		case actions.CASINO_GAMES_NEW_GAMES_BY_PAGE_REFRESH: {
			return state.set('gamesList', action.data);
		}

		case actions.FILTER_REFRESH: {
			const { filter } = action.data;
			const target = state.get('filter');
			const result     = fill(filter, target, true);
			return state.set('filter', result);
		}

		case actions.FILTER_RESET: {
			return state.set('filter', defaultValuesGamesFilter);
		}

		case actions.PAGINATION_REFRESH: {
			const target = state.get('pagination');
			const result = fill(action.data, target);
			return state.set('pagination', result);
		}

		case actions.SEARCH_PAGINATION_REFRESH: {
			const target = state.get('searchPagination');
			const result = fill(action.data, target);
			return state.set('searchPagination', result);
		}

		case actions.SEARCH_PAGINATION_RESET: {
			return state.set('searchPagination', initState.get('searchPagination'));
		}

		case actions.CASINO_GAMES_PROVIDERS_WITH_GAMES_REFRESH: {
			const target= state.get('providersWithGames');
			const { id, gameDataByProvider, isLoading } = action.data;
			const result = cloneDeep(target);
			if (Object.keys(action.data).length && id) {
				result[id] = {
					gameDataByProvider,
					isLoading,
				};
			}
			return state.set('providersWithGames', result);
		}

		case actions.CASINO_GAMES_LIST_RESET: {
			return state.set('gamesList', initState.get('gamesList'));
		}

		case actions.CASINO_GAMES_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
