import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	group: null,
});

export default function userGroupReducer(state = initState, action) {

	switch (action.type) {

		case actions.USER_GROUP_DATA_REFRESH:
			return state.set('group', action.data);

		default:
			return state;

	}

}
