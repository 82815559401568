import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { bettingRulesAPI } from '../../../../../helpers/api/bettingRules';
import { showError, showSuccess } from '../../../../../helpers/notifications';

import { adaptRulesList } from '../utils';

const prefix = 'sport.settings.rules';

const messages = {
	errorListLoad    : `${prefix}.errorListLoad`,
	errorItemRemove  : `${prefix}.errorItemRemove`,
	successItemRemove: `${prefix}.successItemRemove`,
};
function getStoreData({ App }) {

	return {
		websiteID: App.get('websiteID'),
	};
}

function* dataReload() {

	yield takeEvery(actions.DATA_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { websiteID } = yield select(getStoreData);

		try {
			const res = yield call(bettingRulesAPI.rulesList, { website_id: Number(websiteID) });
			const entities = adaptRulesList(res.data.data);
			yield put(actions.dataRefresh(entities));

		} catch (error) {
			showError(messages.errorListLoad, error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* itemRemove() {

	yield takeEvery(actions.ITEM_REMOVE, function* (action) {

		const { ruleID } = action.data;
		try {
			yield call(bettingRulesAPI.ruleDelete, ruleID);
			showSuccess(messages.successItemRemove);
			yield put(actions.dataReload());

		} catch (error) {
			showError(messages.errorItemRemove, error);
		}
	});
}

export default function* bettingRulesListSaga() {
	yield all([
		fork(dataReload),
		fork(itemRemove),
	]);
}
