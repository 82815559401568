import React from 'react';
import PropTypes from 'prop-types';

import Formatter from '../../../../helpers/formatter';
import { riskManagementRiskTypeList } from '../../../../helpers/commonEnums';
import { mapEnum } from '../../../../helpers/utils';
import { ListItem, ListItemHeader, ListItemBody, ListItemDate, ListItemMain } from '../NotificationBadge.style';

const riskTypes = mapEnum(riskManagementRiskTypeList);

const ItemRisks = ({ id, created, riskType, email, riskName, userID, onClick, className, riskID, priority }) => {

	const notifID = `ID: ${id}`;
	const info    = `Customer: ${email}`;

	return (
		<ListItem className={`clickable ${className}`} onClick={() => onClick(userID, id, riskID, priority)}>
			<ListItemMain>
				{notifID}
				<ListItemDate>{Formatter.dateTime(created)}</ListItemDate>
			</ListItemMain>
			<ListItemHeader> {`Risk Type: ${riskTypes[riskType]}`} </ListItemHeader>
			<ListItemHeader> {`Risk Name: ${riskName}`} </ListItemHeader>
			<ListItemBody>{info}</ListItemBody>
		</ListItem>
	);
};

ItemRisks.propTypes = {
	id       : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	created  : PropTypes.string,
	riskType : PropTypes.number,
	riskID   : PropTypes.number,
	priority : PropTypes.number,
	email    : PropTypes.string,
	riskName : PropTypes.string,
	userID   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	onClick  : PropTypes.func,
};

ItemRisks.defaultProps = {
	id       : '',
	created  : '',
	userID   : '',
	className: '',
	riskType : null,
	riskID   : null,
	priority : null,
	email    : '',
	riskName : '',
	onClick  : () => {},
};

export default ItemRisks;
