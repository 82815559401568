import { availableFields } from '../../../redux/reports/reportsByProvider/utils';


const lang                       = {
	wonAmountTotal: 'reportByPlayer.wonAmountTotal',
	ggr           : 'reportByPlayer.ggr',
	spinsCount    : 'reportByPlayer.spinsCount',
	stakeTotal    : 'reportByPlayer.stakeTotal',
	integrator    : 'reportByPlayer.integrator',
	profitability : 'reportByPlayer.profitability',
	providerName  : 'reportByGame.provider',
};
export const defaultReportFields = availableFields.map(fieldKey => {
	return {
		id     : fieldKey,
		intlID : lang[fieldKey],
		checked: true,
	};
});
