import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { fields, getListParams } from '../withdrawals/utils';

export const availableFields = [
	'wdID',
	'wdTypeID',
	'code',
	'websiteID',
	'userCountryID',
	'channelID',
	'userID',
	'userName',
	'wdNumber',
	'wdPlacementTime',
	'userGroupID',
	'userStatusID',
	'wdSource',
	'wdAmount',
	'wdCurrencyID',
	'wdStatusID',
	'wdSourceAccount',
	'wdPaymentMethodEligibility',
	'wdDecision',
	'wdDecisionReason',
	'wdDecisionTime',
	'wdDecisionSource',
	'wdIP',
	'userBalanceBefore',
	'userBalanceAfter',
	'wdIPCountry',
	'wdAmountUSD',
	'userBalanceBeforeUSD',
	'userBalanceAfterUSD',
	'wdMessage',
	'affiliateReference',
];

export function createReportParams(filter, sorting, reportFields) {

	const params  = getListParams(filter, sorting);
	params.fields = getReportFields(reportFields);

	return params;
}

// Service ----------------------------------------------------------------------------------------

function getReportFields(reportFields) {

	if (!isArray(reportFields) || isEmpty(reportFields)) {
		return availableFields.map(key => fields[key]);
	}

	const result = [];
	reportFields.forEach(field => {
		const { id, checked } = field;
		if (checked) {
			result.push(fields[id]);
		}
	});

	return result;
}
