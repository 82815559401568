import styled from 'styled-components';

export const ErrorBoundaryContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const ErrorDescriptionWrapper = styled.div`
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
	button {
		max-width: max-content;
	}
`;

