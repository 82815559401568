import toInteger from 'lodash/toInteger';
import { apiRequest } from './index';
import { CATEGORIES_TYPES } from '../commonConstants';
/* ------------------------------------------------------------------------------------------------
    Main
------------------------------------------------------------------------------------------------ */

// get list of categories

function getCategories(params = {}) {
	params.unlimit = true;
	const req = {
		method: 'GET',
		url   : '/category',
		params,
	};

	return apiRequest(req);
}

// get category data
function categoryInfo(categoryID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}`,
		params,
	};
	return apiRequest(req);
}

// udate category's data
function updateCategory(category) {
	const categoryID = category.id;
	const req = {
		method: 'POST',
		url   : `/category/${categoryID}`,
		data  : category,
	};

	return apiRequest(req);
}

// add new category
function addCategory(category, params = {}) {
	const req = {
		method: 'POST',
		url   : '/category',
		data  : category,
		params,
	};

	return apiRequest(req);
}

// delete category
function deleteCategory(id, moveEventsToLeague = false) {
	const req = {
		method: 'DELETE',
		url   : '/category/delete',
		data  : {
			id            : Number(id),
			move_to_league: moveEventsToLeague,
		},
	};

	return apiRequest(req);
}

// get list of sports
function getSports(params = {}) {
	const filter = {
		...params,
		parent_id : '',
		status_id : '',
		model_type: 1,
	};
	return getCategories(filter);
}

// get list of categories of sport
function getCategoriesOfSport(countryID = null, params = {}) {
	if (params.parents_id && !params.parents_id.length) {
		params.parent_id = countryID;
		params.type_id = CATEGORIES_TYPES.sport;
	} else {
		params.type_id = CATEGORIES_TYPES.league;
	}

	const filter = {
		...params,
		model_type: 1,
	};

	return getCategories(filter);
}

/* ------------------------------------------------------------------------------------------------
    Additional
------------------------------------------------------------------------------------------------ */

/* -------------------------------------------------------------------------------------- Status */
// hide/show category or mark as deleted
function updateCategoryStatus(categoryID, statusData) {
	/*
    statusData: {
        statusID: 1..4
        OR
        displayStatusID: 1..2
    }
    */

	const data = { id: categoryID };
	if (statusData.statusID) data.status_id = toInteger(statusData.statusID);
	if (statusData.displayStatusID) data.display_status_id = toInteger(statusData.displayStatusID);

	const req = {
		method: 'POST',
		url   : `/category/${categoryID}/updateStatus`,
		data,
	};

	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------ Settings */
// GET: category settings
function categorySettingsList(categoryID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}/settings`,
		params,
	};
	return apiRequest(req);
}

// POST: udate category's settings
function categorySettingsUpdate(categoryID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/category/${categoryID}/settings`,
		data,
		params,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------ Dynamic Limits */
// GET: category's Dynamic Limits
function categoryDynamicLimitsList(categoryID) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}/dynamicLimit`,
	};
	return apiRequest(req);
}

// POST: update category's Dynamic Limits
function categoryDynamicLimitsUpdate(categoryID, data) {
	const req = {
		method: 'POST',
		url   : `/category/${categoryID}/dynamicLimit`,
		data,
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------- Descriptions (CMS) */
// GET: descriptions list
function categoryDescriptionsList(categoryID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}/cms`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update category's descriptions (CMS)
function categoryDescriptionsUpdate(categoryID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/category/${categoryID}/cms`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

/* -------------------------------------------------------------------------------------- Orders */
// GET: orders list
function categoryOrdersList(categoryID) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}/order`,
	};
	return apiRequest(req);
}

// POST: update category's orders
function categoryOrdersUpdate(categoryID, data) {
	const req = {
		method: 'POST',
		url   : `/category/${categoryID}/order`,
		data,
	};

	return apiRequest(req);
}

function categoryOrdersUpdateWithChannel(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/categoryOrder',
		data,
		params,
	};

	return apiRequest(req);
}

/* --------------------------------------------------------------------------------------- Names */
// GET: names list
function categoryNamesList(categoryID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}/name`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update category's names
function categoryNamesUpdate(categoryID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/category/${categoryID}/name`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

/* ------------------------------------------------------------------------------- Markets Lists */
// GET: market list of League
function categoryMarketsList(categoryID) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}/marketsLists`,
	};
	return apiRequest(req);
}

// POST: update market list of League
function categoryMarketsUpdate(categoryID, data) {
	const req = {
		method: 'POST',
		url   : `/category/${categoryID}/marketsLists`,
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------- History Lists */
// GET: history list of League
function categoryHistoryList(categoryID) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}/history`,
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------- Order (deprecated) */
// update category order (deprecated)
function updateCategoryOrder(category, step, direction) {
	const { id, typeID, parentID } = category;
	const req = {
		method: 'POST',
		url   : '/category/updateCategoryOrder',
		data  : {
			id,
			step,
			direction,
			type_id           : typeID,
			parent_category_id: parentID,
		},
	};

	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
    League
------------------------------------------------------------------------------------------------ */

/* -------------------------------------------------------------------------------- Restrictions */
// GET: list of league restrictions
function leagueRestrictionsList(leagueID) {
	const req = {
		method: 'GET',
		url   : `/league/${leagueID}/leagueRestriction`,
	};

	return apiRequest(req);
}

// POST: update league restriction
function leagueRestrictionsUpdate(leagueID, restrictionList) {
	const req = {
		method: 'POST',
		url   : `/league/${leagueID}/leagueRestriction`,
		data  : {
			id                : leagueID,
			restricted_leagues: restrictionList,
		},
	};

	return apiRequest(req);
}

/* ---------------------------------------------------------------------------------- Tournament */
// GET: league tournament
function leagueTournamentList(leagueID) {
	const req = {
		method: 'GET',
		url   : `/league/${leagueID}/leagueTournament`,
	};
	return apiRequest(req);
}

// POST: update category's league tournament
function leagueTournamentUpdate(leagueID, data) {
	const req = {
		method: 'POST',
		url   : `/league/${leagueID}/leagueTournament`,
		data,
	};

	return apiRequest(req);
}

/* ------------------------------------------------------------------------------- Market Margin */
// GET: league markets margin
function leagueMarketMarginList(leagueID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/league/${leagueID}/marketsMargin`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update league markets margin
function leagueMarketMarginUpdate(leagueID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/league/${leagueID}/marketsMargin`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* --------------------------------------------------------------------------------- Regulations */
// GET: league regulation types
function leagueRegulationsList(leagueID) {
	const req = {
		method: 'GET',
		url   : `/league/${leagueID}/regulations`,
	};
	return apiRequest(req);
}

// POST: update league regulation types
function leagueRegulationsUpdate(leagueID, data) {
	const req = {
		method: 'POST',
		url   : `/league/${leagueID}/regulations`,
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
    Others
------------------------------------------------------------------------------------------------ */

// get category mapping
function getCategoryMappingsByProviderId(categoryID, providerID) {
	const req = {
		method: 'GET',
		url   : '/mapping/category/getCategoryMappingsByProviderId',
		params: {
			system_category_id: categoryID,
			provider_id       : providerID,
		},
	};

	return apiRequest(req);
}

// get unmapped provider's categories
function getUnmappedCategoriesByProviderIdAndTypeId(providerID, typeID, sportID) {
	const req = {
		method: 'GET',
		url   : '/mapping/category/getUnmappedCategoriesByProviderIdAndTypeId',
		params: {
			type_id    : typeID,
			provider_id: providerID,
			sport_id   : sportID,
		},
	};

	return apiRequest(req);
}

// mapping
function mapCategory(categoryID, providerID, providerObjectsID, categoryType) {
	const req = {
		method: 'POST',
		url   : '/mapping/category/map',
		data  : {
			system_category_id : categoryID,
			provider_id        : providerID,
			provider_objects_id: providerObjectsID,
			category_type      : categoryType,
		},
	};

	return apiRequest(req);
}

// get list of regulation types
function regulationTypesList(categoryID, filter = {}) {
	const req = {
		method: 'GET',
		url   : '/sportRegulationType/',
		params: {
			...filter,
			sport_id: categoryID,
		},
	};

	return apiRequest(req);
}

// add regulation type
function regulationTypeAdd(data) {
	/*
    data: {
        id?                 : number;
        name                : string;
        periods             : [];
        extra_time?         : boolean;
        extra_time_periods  : [];
        sport_id            : number;
    }
    */
	const req = {
		method: 'POST',
		url   : '/sportRegulationType',
		data,
	};

	return apiRequest(req);
}

// update regulation type
function regulationTypeUpdate(typeID, data) {
	/*
    data: {
        id?                 : number;
        name                : string;
        periods             : [];
        extra_time?         : boolean;
        extra_time_periods  : [];
        sport_id            : number;
    }
    */

	const req = {
		method: 'POST',
		url   : `/sportRegulationType/${typeID}`,
		data,
	};

	return apiRequest(req);
}

// delete regulation type
function regulationTypeDelete(typeID) {
	const req = {
		method: 'DELETE',
		url   : `/sportRegulationType/${typeID}`,
	};

	return apiRequest(req);
}

function marketSettings(params = {}) {
	const req = {
		method: 'GET',
		url   : '/marketsettings',
		params,
	};

	return apiRequest(req);
}

function updateMarketSettings(params = {}, data) {
	const req = {
		method: 'POST',
		url   : '/marketsettings',
		params,
		data,
	};

	return apiRequest(req);
}

function getSeo(categoryID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/category/${categoryID}/seo`,
		params,
	};

	return apiRequest(req);
}

function seoSave(data, categoryID, params) {
	const req = {
		method: 'POST',
		url   : `/category/${categoryID}/seo`,
		data,
		params,
	};

	return apiRequest(req);
}

export const categoriesAPI = {
	// main
	getCategories,
	categoryInfo,
	updateCategory,
	addCategory,
	deleteCategory,
	getSports,
	getCategoriesOfSport,
	getSeo,

	seoSave,
	// additional
	updateCategoryStatus,

	categorySettingsList,
	categorySettingsUpdate,

	categoryDynamicLimitsList,
	categoryDynamicLimitsUpdate,

	categoryDescriptionsList,
	categoryDescriptionsUpdate,

	categoryNamesList,
	categoryNamesUpdate,

	categoryOrdersList,
	categoryOrdersUpdate,
	categoryOrdersUpdateWithChannel,

	categoryMarketsList,
	categoryMarketsUpdate,

	categoryHistoryList,

	updateCategoryOrder,

	// league
	leagueRestrictionsList,
	leagueRestrictionsUpdate,
	leagueTournamentList,
	leagueTournamentUpdate,
	leagueRegulationsList,
	leagueRegulationsUpdate,
	leagueMarketMarginList,
	leagueMarketMarginUpdate,

	// others
	getCategoryMappingsByProviderId,
	getUnmappedCategoriesByProviderIdAndTypeId,
	mapCategory,

	regulationTypesList,
	regulationTypeAdd,
	regulationTypeUpdate,
	regulationTypeDelete,

	marketSettings,
	updateMarketSettings,
};
