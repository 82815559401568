import toInteger from 'lodash/toInteger';
import { find, isEmpty, isArray } from 'lodash';
import { isID } from '../../../../helpers/utils';
import {
	fields,
	createListAdapter,
	createPaymentsListAdapter,
	createPaymentLimitsAdapter,
	createPaymentDescriptionsAdapter,

} from '../list/utils';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { createEntities } from '../../../utility';
import { showError } from '../../../../helpers/notifications';
import { createError } from '../../../../helpers/sockets/socket';

const messages = {
	errorDeposit : 'partner.website.payment.limits.max.deposit',
	errorWithdraw: 'partner.website.payment.limits.max.withdraw',
};
const listAdapter                 = createListAdapter();
const paymentsListAdapter         = createPaymentsListAdapter();
const paymentLimitsAdapter        = createPaymentLimitsAdapter();
const paymentDescriptionsAdapter  = createPaymentDescriptionsAdapter();

const namesAdapter     = createNamesAdapter();
const currencyAdapter  = createCurrencyAdapter();
// Adapt ------------------------------------------------------------------------------------------
function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'paymentID', fields.paymentID);
	adapter.addField(rules.string, 'depositName', fields.depositName);
	adapter.addField(rules.string, 'withdrawalName', fields.withdrawalName);
	adapter.addField(rules.string, 'description', fields.description);

	return adapter;
}

function createCurrencyAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', 'description');
	adapter.addField(rules.bool, 'status', 'status');

	return adapter;
}

export function adaptWebsite(rawData = {}) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

export function adaptPayments(rawData = [], firstLangID, paymentUI, reStoreNamesData) {

	paymentsListAdapter.clearExcludes();
	const namesData				= {};
	const isMainRowDataChanges	= {};
	const isBaseDataChanges		= {};
	const isCurrencyChanges		= {};
	const isNamesChanges		= {};
	const tabsNames				= {};
	const langIDs				= {};
	const paymentsIDs			= [];
	const adaptedData = paymentsListAdapter.adaptList(rawData).map(item => {
		const { id }				= item;
		namesData[id]				= reStoreNamesData?.[id]		|| [];
		tabsNames[id]				= paymentUI?.tabsNames?.[id]	|| 'settings';
		langIDs[id]					= paymentUI?.langIDs?.[id]		|| firstLangID;
		isNamesChanges[id]			= false;
		isCurrencyChanges[id]		= false;
		isBaseDataChanges[id]		= false;
		isMainRowDataChanges[id]	= false;
		paymentsIDs.push(id);
		return {
			...item,
			firstLangID,
		};
	});

	const entities = createEntities(adaptedData);

	return {
		entities,
		namesData,
		isNamesChanges,
		isCurrencyChanges,
		tabsNames,
		langIDs,
		paymentsIDs,
		isBaseDataChanges,
		isMainRowDataChanges,
	};
}

export function adaptPaymentLimits(rawData = []) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	paymentLimitsAdapter.clearExcludes();
	return paymentLimitsAdapter.adaptList(rawData);
}

export function adaptPaymentDescriptions(rawData = []) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	paymentDescriptionsAdapter.clearExcludes();
	return paymentDescriptionsAdapter.adaptList(rawData);
}

//Names
export function adaptNamesData(rawData = [], langList = []) {

	const result = [];

	namesAdapter.clearExcludes();
	const adaptedList = namesAdapter.adaptList(rawData);

	langList.forEach( langItem => {
		const langID   = langItem.id;
		const nameItem = find(adaptedList, { langID }) || { langID, name: '' };

		result.push(nameItem);
	});

	return result;
}


export function preparePayments(rawData = {}, websiteID) {
	paymentsListAdapter.clearExcludes();
	paymentsListAdapter.addExcludeField('id');

	rawData.websiteID = websiteID;

	const preparedData = paymentsListAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}

	return preparedData;
}


export function preparePaymentLimits(rawData = [], websitePaymentID = null) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}
	paymentLimitsAdapter.clearExcludes();

	const result = rawData.map(rawItem => {
		if (rawItem.minDeposit > rawItem.maxDeposit) {
			showError(messages.errorDeposit);
			throw createError();
		}
		if (rawItem.minWithdraw > rawItem.maxWithdraw) {
			showError(messages.errorWithdraw);
			throw createError();
		}
		const preparedItem = paymentLimitsAdapter.prepare(rawItem);
		preparedItem[fields.websitePaymentID] = websitePaymentID;

		return preparedItem;
	});

	return result;
}

export function preparePaymentDescriptions(rawData = [], websitePaymentID = null, langID = null) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	paymentDescriptionsAdapter.clearExcludes();

	const result = rawData.map(rawItem => {

		const preparedItem = paymentDescriptionsAdapter.prepare(rawItem);
		preparedItem[fields.websitePaymentID] = websitePaymentID;
		preparedItem[fields.langID] = langID;

		return preparedItem;
	});

	return result;
}

export function prepareCurrencyData(rawData = [], websitePaymentID) {

	currencyAdapter.clearExcludes();

	const adaptedList = rawData.map(rawItem => {
		const currency = currencyAdapter.adapt(rawItem);

		return {
			currencyID  : currency.id,
			currencyName: currency.name,
			status      : currency.status,
		};
	});

	return {
		[websitePaymentID]: adaptedList,
	};

}
