import EntityAdapter from '../../../helpers/entityAdapter';
import { createEntities } from '../../utility';

export const fields = {
	id     : 'id',
	name   : 'name',
	games  : 'games',
	orderID: 'order_id',
	logoURL: 'logo_url',

	gameCategoryID: 'casino_category_id',
	channelID     : 'channel_id',
	langID        : 'lang_id',
	websiteID     : 'website_id',
	alias         : 'alias',
	providerID    : 'provider_id',
	leftMenu      : 'left_menu',

	seoKeywords   : 'seo_keywords',
	seoTitle      : 'seo_title',
	seoDescription: 'seo_description',
	OGDescription : 'og_description',
	OGImage       : 'og_image',
	OGTitle       : 'og_title',
};

const listAdapter = createListAdapter();
const listAdapterGames = createListAdapter(true);
const gameAdapter = createGameAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptGameCategoryList(rawData = [], games) {
	listAdapter.clearExcludes();

	let adaptedData;
	if (games) {
		adaptedData = listAdapter.adaptList(rawData);
	} else {
		adaptedData = listAdapterGames.adaptList(rawData);
	}

	adaptedData.forEach(category => {
		category.games = gameAdapter.adaptList(category.games);
	});

	return createEntities(adaptedData);
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter(games) {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;
	if (games) {
		adapter.addField(rules.arrayObject, 'games', fields.games);
	}
	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'orderID', fields.orderID);

	adapter.addField(rules.bool, 'leftMenu', fields.leftMenu);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'logoURL', fields.logoURL);
	adapter.addField(rules.string, 'websiteID', fields.websiteID);
	adapter.addField(rules.string, 'alias', fields.alias);

	// adapter.addField(rules.string, 'SEOTitle', fields.seoTitle);
	// adapter.addField(rules.string, 'SEODescription', fields.seoDescription);
	// adapter.addField(rules.string, 'SEOKeywords', fields.seoKeywords);
	//
	// adapter.addField(rules.string, 'OGTitle', fields.OGTitle);
	// adapter.addField(rules.string, 'OGDescription', fields.OGDescription);
	// adapter.addField(rules.string, 'OGImage', fields.OGImage);
	return adapter;

}

export function createGameAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.id, 'providerID', fields.providerID);

	return adapter;

}
