import ParamsBuilder from '../../../helpers/paramsBuilder';
import { fields } from '../bets/utils';

export function getListParams(filter, sorting, pagination = null) {
	const builder = new ParamsBuilder();
	const rules   = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'betID', fields.betID);
	builder.addField(rules.isID, 'userID', fields.userID);

	builder.addField(rules.isArrayID, 'betChannelIDs', fields.betChannelIDs);
	builder.addField(rules.isArrayID, 'sportIDs', fields.sportID);
	builder.addField(rules.isArrayID, 'countryIDs', fields.countryID);
	builder.addField(rules.isArrayID, 'leagueIDs', fields.leagueID);
	builder.addField(rules.isArrayID, 'eventIDs', fields.eventID);
	builder.addField(rules.isArrayID, 'betStatusIDs', fields.betStatusID);
	builder.addField(rules.isArrayID, 'betTypeIDs', fields.betTypeID);

	builder.addField(rules.isString, 'marketName', fields.marketName);
	builder.addField(rules.isString, 'selectionName', fields.selectionName);
	builder.addField(rules.isString, 'betIP', fields.betIP);

	builder.addField(rules.isArrayString, 'userCountryIDs', fields.userCountryID);
	builder.addField(rules.isArrayString, 'betIPCountry', fields.betIPCountry);
	builder.addField(rules.isID, 'priceProviderTypeID', fields.priceProviderTypeID);

	builder.addRangeField(rules.isNumberRange, filter.betStake, [
		fields.betStakeFrom,
		fields.betStakeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.betStakeUSD, [
		fields.betStakeUSDFrom,
		fields.betStakeUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossibleWinning, [
		fields.maxPossibleWinningFrom,
		fields.maxPossibleWinningTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossibleWinningUSD, [
		fields.maxPossibleWinningUSDFrom,
		fields.maxPossibleWinningUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossiblePayout, [
		fields.maxPossiblePayoutFrom,
		fields.maxPossiblePayoutTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossiblePayoutUSD, [
		fields.maxPossiblePayoutUSDFrom,
		fields.maxPossiblePayoutUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.betTotalPrice, [
		fields.betTotalPriceFrom,
		fields.betTotalPriceTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.betPlacementTime, [
		fields.betPlacementTimeFrom,
		fields.betPlacementTimeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.betNumber, [fields.betNumberFrom, fields.betNumberTo]);
	const params = builder.biuldParams(filter);

	return params;
}
