import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../tables/actions';

import { showError, showSuccess } from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';

import {
	getListParams,
	adaptList,
	prepareBonusData,
} from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { logger } from '../../../../helpers/logger';


const messages = {
	errorListLoad: 'casinocashback.list.load.fail',
	errorSave    : 'casinocashback.save.fail',
	successSave  : 'casinocashback.save.success',
};


const tableType   = TABLE_TYPES.cashbackUsers;

function getStoreData(state) {
	const { Loyalty: { BonusingHistory }, Tables, App } = state;
	const { CashbackUsers } = BonusingHistory;

	return {
		filter    : CashbackUsers.get('filter'),
		sorting   : Tables.get(tableType).sorting,
		pagination: deriveTablePagination(tableType, state),
		websiteID : App.get('websiteID'),
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination, websiteID } = yield select(getStoreData);

		const params = getListParams(filter, sorting, pagination, websiteID);

		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(bonusesAPI.cashbackUsersList, params);
			if (res && res.status === 200) {
				entities   = adaptList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(entities));
			}

			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

function* bonusSave() {

	yield takeEvery(actions.BONUS_SAVE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID } = yield select(getStoreData);
		const { id, data } = action.data;
		data.websiteID  = websiteID;
		const preparedData = prepareBonusData(data);

		try {
			yield call(bonusesAPI.cashbackUsersUpdate, id, preparedData);
			showSuccess(messages.successSave);
		} catch (error) {
			showError(messages.errorSave, error);
		}

		yield put(actions.listReload());
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* casinoCashbackUsersSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(bonusSave),
	]);
}
