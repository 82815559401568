import { combineReducers } from 'redux';

import List from './list/reducer';
import Filter from './filter/reducer';
import Participant from './participant/reducer';
import Member from './member/reducer';
import Ages from './ages/reducer';
import Mapping from './mapping/reducer';
import ParticipantHistory from './participantHistory/reducer';

export default combineReducers({
	List,
	Filter,
	Participant,
	Member,
	Ages,
	Mapping,
	ParticipantHistory,
});
