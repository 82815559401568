import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { SMS_GATEWAYS_PROVIDER_TYPE, SMS_GATEWAYS_STATUS } from '../../../../constants/smsGateways';
import { fill } from '../../../../helpers/utils';
import { actions } from './actions';

const initState = new Map({

	entity: {},

	baseData: {
		name          : '',
		url           : '',
		postData      : '',
		providerType  : SMS_GATEWAYS_PROVIDER_TYPE.customProvider,
		sender        : '',
		status        : SMS_GATEWAYS_STATUS.active,
		defaultGateway: false,
		id            : null,

		//Authorization header
		login    : '',
		password : '',
		userAgent: '',
		content  : '',
		number   : '',
	},

	UI: {
		loadEntity   : false,
		loading      : false,
		editMode     : false,
		visible      : false,
		isBaseChanged: false,
	},
});

const clonedState = cloneDeep(initState);

export default (state = clonedState, { type, data }) => {
	switch (type) {
		case actions.BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(data, target);
			return state.set('baseData', result);
		}

		case actions.ENTITY_REFRESH: {
			return state.set('entity', data);
		}

		case actions.UI_REFRESH: {
			const UI  = data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}

		case actions.DATA_RESET: {
			const target = state.get('entity');
			return clonedState.set('entity', target);
		}

		default: {
			return state;
		}
	}
};
