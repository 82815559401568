import React, { Ref, useLayoutEffect, useRef, useState } from 'react';
import { InputWrapper } from './InputComponent.style';

type InputProps = React.ComponentProps<'input'> & Ref<HTMLInputElement>;
const InputComponent: React.FC<InputProps> = (props) => {
	const { value, onChange, ...rest } = props;
	const [cursor, setCursor] = useState<number | null>(null);
	const ref = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		ref.current?.setSelectionRange(cursor, cursor);
	}, [ref, cursor, value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCursor(e.target.selectionStart);
		onChange?.(e);
	};

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	return <InputWrapper ref={ref} value={value} onChange={handleChange} {...rest} />;
};

export { InputComponent };
