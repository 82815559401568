import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';

import actions from './actions';
import tableActions from '../../tables/actions';

import { getListParams } from './utils';
import { adaptBetsList } from '../bets/utils';

import { categoriesAPI } from '../../../helpers/api/categories';
import { eventsAPI } from '../../../helpers/api/events';
import { betsAPI } from '../../../helpers/api/bets';
import { getHeadersTotalCount } from '../../../helpers/utils';
import { showError } from '../../../helpers/notifications';
import { DISPLAY_STATUS } from '../../../helpers/commonConstants';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';


const prefix = 'rtm.userBets';

const messages = {
	errorListReload       : `${prefix}.errorListReload`,
	errorCountryListReload: `${prefix}.errorCountryListReload`,
	errorLeagueListReload : `${prefix}.errorLeagueListReload`,
	errorEventListReload  : `${prefix}.errorEventListReload`,
};

const tableType = TABLE_TYPES.betProfile;

function getStoreData(state) {
	const { RTM, Tables } = state;
	const { UserBets } = RTM;

	return {
		filter                : UserBets.get('filter'),
		pagination            : deriveTablePagination(tableType, state),
		countryList           : UserBets.get('countryList'),
		leagueList            : UserBets.get('leagueList'),
		eventList             : UserBets.get('eventList'),
		loadedCountryParentIDs: UserBets.get('loadedCountryParentIDs'),
		loadedLeagueParentIDs : UserBets.get('loadedLeagueParentIDs'),
		loadedEventsParentIDs : UserBets.get('loadedEventsParentIDs'),
		sorting               : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params   = getListParams(filter, sorting, pagination);
		let betIDs     = [];
		let entities   = {};
		let totalCount = 0;
		try {
			const response = yield call(betsAPI.rtmBetslipsList, params);
			if (response && response.status === 200) {
				const result = adaptBetsList(response.data.data);
				betIDs       = result.betIDs;   // eslint-disable-line prefer-destructuring
				entities     = result.entities; // eslint-disable-line prefer-destructuring
				totalCount   = getHeadersTotalCount(response.headers) || betIDs.length;
			}

		} catch (error) {
			showError(messages.errorListReload, error);
		}

		yield put(actions.dataRefresh(betIDs, entities));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

function* countryListReload() {

	yield takeEvery(actions.COUNTRY_LIST_RELOAD, function* (action) {

		const { newSportIDs, oldSportIDs } = action.data;
		const parentIDs = difference(newSportIDs, oldSportIDs);
		if (isEmpty(parentIDs)) {
			return;
		}

		const storeData                  = yield select(getStoreData);
		const { loadedCountryParentIDs } = storeData;
		let { countryList }              = storeData;

		try {
			for (let i = 0; i < parentIDs.length; i++) {
				const parentID = parentIDs[i];
				if (loadedCountryParentIDs.includes(parentID)) {
					continue; // eslint-disable-line no-continue
				}

				const params = {
					parent_id        : parentID,
					display_status_id: DISPLAY_STATUS.visible,
				};
				const response = yield call(categoriesAPI.getCategories, params);
				if (response && response.status === 200) {
					const list = response.data.data;
					countryList = countryList.concat(list);
					yield put(actions.loadedCountryParentIDsRefresh(parentID));
				}
			}

		} catch (error) {
			showError(messages.errorCountryListReload, error);
		}

		yield put(actions.countryListRefresh(countryList));
	});
}

function* leagueListReload() {

	yield takeEvery(actions.LEAGUE_LIST_RELOAD, function* (action) {

		const { newCountryIDs, oldCountryIDs } = action.data;
		const parentIDs = difference(newCountryIDs, oldCountryIDs);
		if (isEmpty(parentIDs)) {
			return;
		}

		const storeData                 = yield select(getStoreData);
		const { loadedLeagueParentIDs } = storeData;
		let { leagueList }              = storeData;

		try {
			for (let i = 0; i < parentIDs.length; i++) {
				const parentID = parentIDs[i];
				if (loadedLeagueParentIDs.includes(parentID)) {
					continue; // eslint-disable-line no-continue
				}

				const params = {
					display_status_id: DISPLAY_STATUS.visible,
				};
				const response = yield call(categoriesAPI.getCategoriesOfSport, parentID, params);
				if (response && response.status === 200) {
					const list = response.data.data;
					leagueList = leagueList.concat(list);
					yield put(actions.loadedLeagueParentIDsRefresh(parentID));
				}
			}
		} catch (error) {
			showError(messages.errorLeagueListReload, error);
		}

		yield put(actions.leagueListRefresh(leagueList));
	});
}

function* eventListReload() {

	yield takeEvery(actions.EVENT_LIST_RELOAD, function* (action) {

		const { newLeagueIDs, oldLeagueIDs } = action.data;
		const parentIDs = difference(newLeagueIDs, oldLeagueIDs);
		if (isEmpty(parentIDs)) {
			return;
		}

		const storeData                 = yield select(getStoreData);
		const { loadedEventsParentIDs } = storeData;
		let { eventList }               = storeData;

		try {
			for (let i = 0; i < parentIDs.length; i++) {
				const parentID = parentIDs[i];
				if (loadedEventsParentIDs.includes(parentID)) {
					continue; // eslint-disable-line no-continue
				}

				const params = { league_id: parentID };
				const response = yield call(eventsAPI.eventList, params);
				if (response && response.status === 200) {
					const list = response.data.data;
					eventList = eventList.concat(list);
					yield put(actions.loadedEventParentIDsRefresh(parentID));
				}
			}
		} catch (error) {
			showError(messages.errorEventListReload, error);
		}

		yield put(actions.eventListRefresh(eventList));
	});
}

export default function* rtmUserBetsSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(countryListReload),
		fork(leagueListReload),
		fork(eventListReload),
	]);
}
