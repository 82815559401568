import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import QueryToFilterValidaion from '../../../../../helpers/queryToFilterValidaion';
import { createEntities } from '../../../../utility';

const { isValidID, isValidDateRange, isValidString } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	id       : isValidID,
	statusID : isValidID,
	name     : isValidString,
	title    : isValidString,
	createdAt: isValidDateRange,
	updatedAt: isValidDateRange,
};


export const fields = {
	id       : 'id',
	websiteID: 'website_id',
	langID   : 'lang_id',
	channelID: 'channel_id',
	createdAt: 'created_at',
	updatedAt: 'updated_at',

	name                  : 'name',
	alias                 : 'alias',
	title                 : 'title',
	statusID              : 'status_id',
	subtitle              : 'subtitle',
	description           : 'description',
	headerImage           : 'header_image',
	thumbnailImage        : 'thumbnail_image',
	contentTitle          : 'games',
	callToActionImage     : 'call_to_action_image',
	callToActionTitle     : 'call_to_action_title',
	callToActionSubtitle  : 'call_to_action_subtitle',
	callToActionButtonText: 'call_to_action_button_text',
	callToActionButtonLink: 'call_to_action_button_link',
	gameIDs               : 'games',

	// SEO
	SEOTitle      : 'seo_title',
	SEODescription: 'seo_description',
	SEOKeywords   : 'seo_keywords',
	OGTitle       : 'og_title',
	OGDescription : 'og_description',
	OGImage       : 'og_image',

	// revision
	promotionID: 'promotion_id',
	promotion  : 'promotion',
	active     : 'is_active',
	userID     : 'created_by',
	date       : 'created_at',

	//games
	imageLGurl: 'image_lg_url',
	imageMDurl: 'image_md_url',
	imageSMurl: 'image_sm_url',
	thumbUrl  : 'thumb_url',

	updatedFrom: 'updated_from',
	updatedTo  : 'updated_to',
	createdFrom: 'created_from',
	createdTo  : 'created_to',
};

const listAdapter = createListAdapter();

export function getListParams(pagination, sorting = null, filter, websiteID) {

	const builder = new ParamsBuilder();
	const rules   = builder.RULES;

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('website_id', websiteID);

	builder.addValue('limit', pagination.itemsPerPage);
	builder.addValue('promotion', pagination.currentpromotion);
	builder.addValue('page', pagination.currentPage);

	builder.addField(rules.isID, 'id', fields.id);

	builder.addField(rules.isString, fields.name);
	builder.addField(rules.isString, fields.title);
	builder.addField(rules.isID, 'statusID', fields.statusID);

	builder.addRangeField(rules.isDateTimeRange, filter.createdAt, [
		fields.createdFrom,
		fields.createdTo,
	]);

	builder.addRangeField(rules.isDateTimeRange, filter.updatedAt, [
		fields.updatedFrom,
		fields.updatedTo,
	]);
	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptPromotionsList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedData = [];

	rawData.forEach(item => {
		item.promotion.id = item.id;
		item.promotion.lang_id = item.lang_id;
		item.promotion.website_id = item.website_id;
		item.promotion.created_at = item.created_at;
		item.promotion.updated_at = item.updated_at;
		item.promotion.website_id = item.website_id;
		item.promotion.status_id  = item.status_id;
		item.promotion.channel_id = item.channel_id;
		item.promotion.alias      = item.alias;
		const adaptedItem = item.promotion
			? listAdapter.adapt(item.promotion)
			: listAdapter.adapt(item);
		adaptedData.push(adaptedItem);
	});

	const entities = createEntities(adaptedData);
	const listIDs = Object.keys(entities);

	const result = {
		listIDs,
		entities,
	};

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.fullDate, 'createdAt', fields.createdAt);
	adapter.addField(rules.fullDate, 'updatedAt', fields.updatedAt);
	adapter.addField(rules.id,   'statusID', fields.statusID);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'subtitle', fields.subtitle);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'headerImage', fields.headerImage);
	adapter.addField(rules.string, 'thumbnailImage', fields.thumbnailImage);
	adapter.addField(rules.arrayObject, 'gameIDs', fields.gameIDs);
	adapter.addField(rules.string, 'callToActionImage', fields.callToActionImage);
	adapter.addField(rules.string, 'callToActionTitle', fields.callToActionTitle);
	adapter.addField(rules.string, 'callToActionSubtitle', fields.callToActionSubtitle);
	adapter.addField(rules.string, 'callToActionButtonText', fields.callToActionButtonText);
	adapter.addField(rules.string, 'callToActionButtonLink', fields.callToActionButtonLink);

	// SEO
	adapter.addField(rules.string, 'SEOTitle', fields.SEOTitle);
	adapter.addField(rules.string, 'SEODescription', fields.SEODescription);
	adapter.addField(rules.string, 'SEOKeywords', fields.SEOKeywords);
	adapter.addField(rules.string, 'OGTitle', fields.OGTitle);
	adapter.addField(rules.string, 'OGDescription', fields.OGDescription);
	adapter.addField(rules.string, 'OGImage', fields.OGImage);

	return adapter;
}

export function createGameListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	// adapter.addField(rules.id, 'name', fields.name);
	// adapter.addField(rules.id, 'imageLGurl', fields.imageLGUrl);
	// adapter.addField(rules.id, 'imageMGurl', fields.imageMGUrl);
	// adapter.addField(rules.id, 'imageSMurl', fields.imageSMUrl);
	// adapter.addField(rules.id, 'thumbUrl',   fields.thumbUrl);


	return adapter;
}
