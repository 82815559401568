const actions = {

	USERS_GROUPS_LIST_RELOAD : 'USERS_GROUPS_LIST_RELOAD',
	USERS_GROUPS_LIST_REFRESH: 'USERS_GROUPS_LIST_REFRESH',

	USERS_GROUPS_SAVE_GROUP  : 'USERS_GROUPS_SAVE_GROUP',
	USERS_GROUPS_DELETE_GROUP: 'USERS_GROUPS_DELETE_GROUP',

	USERS_GROUPS_MODAL_UI_REFRESH : 'USERS_GROUPS_MODAL_UI_REFRESH',
	USERS_GROUPS_BASE_DATA_REFRESH: 'USERS_GROUPS_BASE_DATA_REFRESH',
	USERS_GROUPS_DATA_RESET       : 'USERS_GROUPS_DATA_RESET',

	listReload: () => ({
		type: actions.USERS_GROUPS_LIST_RELOAD,
	}),
	listRefresh: list => ({
		type: actions.USERS_GROUPS_LIST_REFRESH,
		data: list,
	}),

	saveGroup: () => ({
		type: actions.USERS_GROUPS_SAVE_GROUP,
	}),

	modalUIRefresh: data => ({
		type: actions.USERS_GROUPS_MODAL_UI_REFRESH,
		data,
	}),
	baseDataRefresh: data => ({
		type: actions.USERS_GROUPS_BASE_DATA_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.USERS_GROUPS_DATA_RESET,
	}),
};

export default actions;
