const actions = {

	TOPIC_SAVE             : 'TOPIC_SAVE',
	TOPIC_DATA_RESET       : 'TOPIC_DATA_RESET',
	TOPIC_BASE_DATA_RELOAD : 'TOPIC_BASE_DATA_RELOAD',
	TOPIC_BASE_DATA_REFRESH: 'TOPIC_BASE_DATA_REFRESH',

	TOPIC_UI_REFRESH: 'TOPIC_UI_REFRESH',

	topicSave: (visible) => ({
		type: actions.TOPIC_SAVE,
		data: {
			visible,
		},
	}),
	dataReset: () => ({
		type: actions.TOPIC_DATA_RESET,
	}),

	dataRefresh: (dataName, data) => ({
		type: actions.TOPIC_BASE_DATA_REFRESH,
		data: {
			dataName,
			data,
		},
	}),
	uiRefresh: data => ({
		type: actions.TOPIC_UI_REFRESH,
		data,
	}),
};

export default actions;
