import { all, fork } from 'redux-saga/effects';

import categoriesSaga from './categories/saga';
import eventsSaga from './events/saga';
import marketsSaga from './markets/saga';
import participantsSaga from './participants/saga';
import resultsSaga from './results/saga';
import settingsSaga from './settings/saga';
import liveStatisticTypesRootSaga from './liveStatisticTypes/saga';


export default function* rootSaga() {
	yield all([
		fork(categoriesSaga),
		fork(eventsSaga),
		fork(marketsSaga),
		fork(participantsSaga),
		fork(resultsSaga),
		fork(settingsSaga),
		fork(liveStatisticTypesRootSaga),
	]);
}
