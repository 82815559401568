import { TStringOrNumber } from '../../../../containers/Partner/Websites/Inner/tabs/TabProvider/types';


const actions = {
	WEBSITE_PROVIDER_DATA_RELOAD                       : 'WEBSITE_PROVIDER_DATA_RELOAD',
	WEBSITE_PROVIDER_SAVE                              : 'WEBSITE_PROVIDER_SAVE',
	WEBSITE_PROVIDER_DELETE                            : 'WEBSITE_PROVIDER_DELETE',
	WEBSITE_PROVIDER_NAMES_DATA_RELOAD                 : 'WEBSITE_PROVIDER_NAMES_DATA_RELOAD',
	WEBSITE_PROVIDER_SEO_DATA_RELOAD                   : 'WEBSITE_PROVIDER_SEO_DATA_RELOAD',
	WEBSITE_PROVIDER_SEO_DATA_SAVE                     : 'WEBSITE_PROVIDER_SEO_DATA_SAVE',
	WEBSITE_PROVIDER_CASINO_GAMES_LIST_REFRESH_FROM_API: 'WEBSITE_PROVIDER_CASINO_GAMES_LIST_REFRESH_FROM_API',
	WEBSITE_PROVIDER_MAIN_DATA_RELOAD                  : 'WEBSITE_PROVIDER_MAIN_DATA_RELOAD',
	reloadWebsiteProviders                             : (websiteID: number) => {
		return {
			type: actions.WEBSITE_PROVIDER_DATA_RELOAD,
			data: {
				websiteID,
			},
		};
	},

	providerSave: (recordID: TStringOrNumber, providerID: number) => ({
		type: actions.WEBSITE_PROVIDER_SAVE,
		data: {
			recordID,
			providerID,
		},
	}),

	providerDelete: (providerID: TStringOrNumber) => ({
		type: actions.WEBSITE_PROVIDER_DELETE,
		data: {
			providerID,
		},
	}),

	mainDataReload: (providerID: TStringOrNumber, rowID: TStringOrNumber) => ({
		type: actions.WEBSITE_PROVIDER_MAIN_DATA_RELOAD,
		data: {
			providerID,
			rowID,
		},
	}),

	namesDataReload: ({ providerID, rowID }: { providerID: TStringOrNumber; rowID: TStringOrNumber }) => ({
		type: actions.WEBSITE_PROVIDER_NAMES_DATA_RELOAD,
		data: {
			providerID,
			rowID,
		},
	}),
	seoDataReload: (providerID: TStringOrNumber, rowID: TStringOrNumber) => ({
		type: actions.WEBSITE_PROVIDER_SEO_DATA_RELOAD,
		data: {
			providerID,
			rowID,
		},
	}),

	seoDataSave: ({  rowID }: { rowID: TStringOrNumber }) => ({
		type: actions.WEBSITE_PROVIDER_SEO_DATA_SAVE,
		data: {
			rowID,
		},
	}),

	reloadFromApi: (providerID: number) => ({
		type: actions.WEBSITE_PROVIDER_CASINO_GAMES_LIST_REFRESH_FROM_API,
		data: {
			providerID,
		},
	}),

};


export { actions as websiteProviderActions };
