import styled from 'styled-components';

export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;

  .tab-link {
    text-align: right;
  }
`;
