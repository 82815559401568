import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import StandartModal from '../../../../components/StandartModal';

import profileActions from '../../../../redux/profile/actions';
import { showError } from '../../../../helpers/notifications';

import { lang } from '../../lang';

import CardAvatar from './CardAvatar';
import CardMain from './CardMain';
import { validateBaseData } from './validators';

class Profile extends Component {

	static propTypes = {
		baseData : PropTypes.object.isRequired,
		visible  : PropTypes.bool.isRequired,
		loading  : PropTypes.bool.isRequired,
		dataSave : PropTypes.func.isRequired,
		dataReset: PropTypes.func.isRequired,
		uiRefresh: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.onSave           = this.onSave.bind(this);
		this.onClickSave      = this.onClickSave.bind(this);
		this.onClickSaveClose = this.onClickSaveClose.bind(this);
	}

	// Events ---------------------------------------------------------------------------------------
	onSave(closeModal) {
		const { baseData, dataSave, uiRefresh } = this.props;
		const errors = validateBaseData(baseData);
		if (errors) {
			showError(errors);
			return;
		}

		uiRefresh({ closeModal });
		dataSave();
	}

	onClickSave() {
		this.onSave(false);
	}

	onClickSaveClose() {
		this.onSave(true);
	}

	// Renders --------------------------------------------------------------------------------------
	render() {
		const { visible, loading, dataReset } = this.props;

		return (
			<StandartModal
				visible={visible}
				title={lang.modalTitle}
				width="50%"
				readOnly={loading}
				onCancel={dataReset}
				onSave={this.onClickSave}
				onSaveClose={this.onClickSaveClose}
			>
				<Row gutter={8}>
					<Col span={10}>
						<CardAvatar />
					</Col>
					<Col span={14}>
						<CardMain />
					</Col>
				</Row>
			</StandartModal>
		);
	}
}

function mapStateToProps(state) {
	const { Profile } = state;
	const UI = Profile.get('UI');

	return {
		baseData: Profile.get('baseData'),
		visible : UI.visible,
		loading : UI.loading,
	};
}

export default connect(mapStateToProps, {
	dataSave : profileActions.dataSave,
	dataReset: profileActions.dataReset,
	uiRefresh: profileActions.uiRefresh,
})(Profile);
