const actions = {

	DASHBOARD_WITHDRAWALS_DATA_REFRESH: 'DASHBOARD_WITHDRAWALS_DATA_REFRESH',
	DASHBOARD_WITHDRAWALS_DATA_RELOAD : 'DASHBOARD_WITHDRAWALS_DATA_RELOAD',
	DASHBOARD_WITHDRAWALS_UI_REFRESH  : 'DASHBOARD_WITHDRAWALS_UI_REFRESH',

	withdrawalsDataRefresh: data => ({
		type: actions.DASHBOARD_WITHDRAWALS_DATA_REFRESH,
		data,
	}),
	withdrawalsDataReload: () => ({
		type: actions.DASHBOARD_WITHDRAWALS_DATA_RELOAD,
	}),
	withdrawalsUIRefresh: data => ({
		type: actions.DASHBOARD_WITHDRAWALS_UI_REFRESH,
		data,
	}),

};

export default actions;
