import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { usersAPI } from '../../../helpers/api/users';
import { showError, showSuccess } from '../../../helpers/notifications';
import balanceAdjustmentsActions from '../balanceAdjustments/actions';
import { logger } from '../../../helpers/logger';

const prefix = 'users.userBalance';

const messages = {
	errorBalanceSave  : `${prefix}.errorBalanceSave`,
	successBalanceSave: `${prefix}.successBalanceSave`,
};

function getStoreData({ Users }) {
	return {
		closeModal : Users.UserBalance.get('UI').closeModal,
		balanceData: Users.UserBalance.get('balanceData'),
		userID     : Users.User.get('baseData').id,
	};
}

function* balanceSave() {
	yield takeEvery(actions.USER_BALANCE_SAVE, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { closeModal } = yield select(getStoreData);
		const { userID, balanceData } = yield select(getStoreData);
		const { amount, comment, userComment, wageringTurnover, daysToExpire, forced, logoURL, titles } = balanceData;

		const preparedData = {
			user_id          : userID,
			user_comment     : userComment,
			wagering_turnover: wageringTurnover,
			days_to_expire   : daysToExpire,
			amount,
			comment,
		};
		if (wageringTurnover) {
			preparedData.forced   = forced;
			preparedData.logo_url = logoURL;
			preparedData.titles   = titles;
		}

		let isError = false;
		try {
			const res = yield call(usersAPI.userBalanceUpdate, userID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successBalanceSave);
			}
		} catch (error) {
			isError = true;
			showError(messages.errorBalanceSave, error);
			logger.log(error);
		}

		yield put(balanceAdjustmentsActions.listReload(userID));
		yield put(
			actions.uiRefresh({
				loading  : false,
				isChanged: false,
			})
		);
		if (closeModal && !isError) {
			yield put(actions.dataReset());
		}
	});
}

export default function* userBalanceSaga() {
	yield all([fork(balanceSave)]);
}
