import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { fields, getListParams } from '../casino/utils';

export const availableFields = [
	'transactionID',
	'gamePlacementTime',
	'userID',
	'userName',
	'gameCurrencyID',
	'gameWebsiteCurrencyID',
	'gameID',
	'gameName',
	'channelID',
	'gameProviderID',
	'GGR',
	'gameBetNumber',
	'gameIP',
	'gameIPCountry',
	'userRegistrationIP',
	'betType',
	'userStatusID',
	'gameStake',
	'gameWonAmount',
	'gameStatusID',
	'userBalanceBeforeUSD',
	'userBalanceAfterUSD',
	'websiteID',
	'gameWonAmountReal',
	'gameWonAmountBonus',
	'gameStakeReal',
	'gameStakeBonus',
	'userBalanceBefore',
	'userBalanceAfter',
];

export function createReportParams(filter, sorting, reportFields) {

	const params  = getListParams(filter, sorting);
	params.fields = getReportFields(reportFields);

	return params;
}

// Service ----------------------------------------------------------------------------------------

function getReportFields(reportFields) {

	if (!isArray(reportFields) || isEmpty(reportFields)) {
		return availableFields.map(key => fields[key]);
	}

	const result = [];
	reportFields.forEach(field => {
		const { id, checked } = field;
		if (checked) {
			result.push(fields[id]);
		}
	});

	return result;
}
