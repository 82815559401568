import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { showError, showSuccess } from '../../../helpers/notifications';
import { usersAPI } from '../../../helpers/api/users';
import { getHeadersTotalCount } from '../../../helpers/utils';

import actions from './actions';
import tableActions from '../../tables/actions';
import { deriveTablePagination } from '../../../selectors/tables';
import { getListParams, adaptAdminList, updateAdminItem } from './utils';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { derivePersonalList } from '../../../selectors/personal';
import { logger } from '../../../helpers/logger';

const prefix = 'staff.list';

const messages = {
	errorListLoad      : `${prefix}.errorListLoad`,
	errorStatusUpdate  : `${prefix}.errorStatusUpdate`,
	successStatusUpdate: `${prefix}.successStatusUpdate`,
};


const tableType     = TABLE_TYPES.personal;

function getStoreData(state) {
	const { Staff, Tables } = state;
	const { Filter } = Staff;

	return {
		filter      : Filter.get('filter'),
		pagination  : deriveTablePagination(tableType, state),
		sorting     : Tables.get(tableType).sorting,
		personalList: derivePersonalList(state),
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));
		let list = [];
		let totalCount = 0;

		const { filter, pagination, sorting } = yield select(getStoreData);
		const params = getListParams(filter, pagination, sorting);
		try {
			const res = yield call(usersAPI.adminsList, params);
			if (res && res.status === 200) {

				list = adaptAdminList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);

				yield put(actions.listRefresh(list));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* statusUpdate() {

	yield takeEvery(actions.ITEM_STATUS_UPDATE, function* (action) {
		const { personalList } = yield select(getStoreData);
		const { userID, statusID } = action.data;

		let list = updateAdminItem(personalList, userID, true);
		yield put(actions.listRefresh(list));

		const data = {
			id       : userID,
			status_id: statusID,
		};

		try {
			const res = yield call(usersAPI.updateAdminData, userID, data);

			if (res && res.status === 200) {
				const { id, status_id } = res.data.data;

				list = updateAdminItem(personalList, id, false, status_id);
				showSuccess(messages.successStatusUpdate);
			}
		} catch (error) {
			list = updateAdminItem(personalList, userID, false);
			showError(messages.errorStatusUpdate);
			logger.log(error);
		} finally {
			yield put(actions.listRefresh(list));
		}
	});
}

export default function* personalListSaga() {
	yield all([
		fork(listReload),
		fork(statusUpdate),
	]);
}
