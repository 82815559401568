import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({

	transactionIDs: [],
	entities      : {},
	casinoTotals  : {},

	newTransactionIDs     : [],
	newTransactionEntities: {},

	filter: createFilter(filtersURL.casino, initFilterTypes),

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	UI: {
		loading: false,
	},
});

export default function rtmCasinoReducer(state = initState, action) {

	switch (action.type) {

		case actions.RTM_CASINO_DATA_REFRESH: {
			const { transactionIDs, entities, casinoTotals } = action.data;
			return state
				.set('transactionIDs', transactionIDs)
				.set('entities', entities)
				.set('casinoTotals', casinoTotals);
		}

		case actions.RTM_CASINO_NEW_TRANSACTION_LIST_REFRESH: {
			const { newTransactionIDs, newTransactionEntities } = action.data;
			return state
				.set('newTransactionIDs', newTransactionIDs)
				.set('newTransactionEntities', newTransactionEntities);
		}

		case actions.RTM_CASINO_FILTER_RESET: {
			const target = state.get('pagination');
			const result = fill({ currentPage: 1 }, target);
			return state
				.set('filter', {})
				.set('pagination', result);
		}

		case actions.RTM_CASINO_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.RTM_CASINO_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.RTM_CASINO_PAGINATION_REFRESH: {
			const target = state.get('pagination');
			const result = fill(action.data, target);
			return state.set('pagination', result);
		}

		default:
			return state;
	}
}
