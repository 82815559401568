import EntityAdapter from '../../../helpers/entityAdapter';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import QueryToFilterValidaion from '../../../helpers/queryToFilterValidaion';
import { getReportFields } from '../reportsByGame/utils';

const { isValidID, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	channelID     : isValidID,
	gameProviderID: isValidID,
	betTypeID     : isValidID,
	date          : isValidDateRange,
};

export const fields = {
	stakeTotal    : 'stake_total',
	wonAmountTotal: 'won_amount_total',
	ggr           : 'ggr',
	userID        : 'user_id',
	username      : 'username',
	email         : 'email',
	currencyID    : 'currency_id',
	currency      : 'currency',
	casinoGame    : 'casino_game',
	spinsCount    : 'spins_count',
	casinoGameID  : 'casino_game_id',
	providerName  : 'provider_name',
	providerID    : 'provider_id',
	betTypeID     : 'type_id',
	dateFrom      : 'date_from',
	dateTo        : 'date_to',
	channelID     : 'channel_id',
	riskGroup     : 'risk_group',
	sortBy        : 'sort_by',
	sortOrder     : 'sort_order',
	profitability : 'profitability',
	integrator    : 'integrator_name',
};

export const availableFields = [
	'stakeTotal',
	'wonAmountTotal',
	'ggr',
	'integrator',
	'spinsCount',
	'profitability',
	'providerName',
];

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptReportsList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedList = listAdapter.adaptList(rawData);
	return adaptedList;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'stakeTotal', fields.stakeTotal);
	adapter.addField(rules.number, 'wonAmountTotal', fields.wonAmountTotal);
	adapter.addField(rules.number, 'ggr', fields.ggr);
	adapter.addField(rules.id,     'userID', fields.userID);
	adapter.addField(rules.string, 'username', fields.username);
	adapter.addField(rules.string, 'username', fields.username);
	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.string, 'currency', fields.currency);
	adapter.addField(rules.number, 'spinsCount', fields.spinsCount);
	adapter.addField(rules.id,     'casinoGameID', fields.casinoGameID);
	adapter.addField(rules.id,     'providerID', fields.providerID);
	adapter.addField(rules.string, 'providerName', fields.providerName);
	adapter.addField(rules.string, 'providerName', fields.providerName);
	adapter.addField(rules.string, 'integrator', fields.integrator);
	adapter.addField(rules.number, 'profitability', fields.profitability);

	return adapter;
}

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID,     'userID', fields.userID);
	builder.addField(rules.isString, 'currencyID', fields.currencyID);
	builder.addRangeField(rules.isDateTimeRange, filter.date, [fields.dateFrom, fields.dateTo]);
	builder.addField(rules.id, 'gameProviderID', fields.providerID);
	builder.addField(rules.string, 'providerName', fields.providerName);
	filter.channelID !== '0' && builder.addField(rules.id, 'channelID', fields.channelID);
	builder.addField(rules.isString, 'betTypeID', fields.betTypeID);
	builder.addField(rules.isArrayString, 'riskGroup', fields.riskGroup);
	builder.addField(rules.isString, 'sortBy',  fields.sortBy);
	builder.addField(rules.isString, 'sortOrder',  fields.sortOrder);
	const params = builder.biuldParams({ ...filter, ...sorting });


	return params;
}


export function checkSortReportByGame( sorting ) {

	const checkColumn = {
		email         : 'email',
		ggr           : 'ggr',
		profitability : 'profitability',
		providerName  : 'provider_name',
		spinsCount    : 'spins_count',
		stakeTotal    : 'stake_total',
		userID        : 'user_id',
		username      : 'username',
		wonAmountTotal: 'won_amount_total',
	};

	if ( checkColumn[sorting.sortBy] ) {
		return {
			sortBy   : checkColumn[sorting.sortBy],
			sortOrder: sorting.sortOrder,
		};

	}

	return { sortOrder: null, sortBy: null };

}
export function createReportParams(filter, sorting, reportFields, availableFields) {

	const params  = getListParams(filter, sorting);
	params.fields = getReportFields(reportFields, availableFields);

	return params;
}
