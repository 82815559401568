const actions = {

	RTM_WITHDRAWALS_LIST_RELOAD          : 'RTM_WITHDRAWALS_LIST_RELOAD',
	RTM_WITHDRAWALS_DATA_REFRESH         : 'RTM_WITHDRAWALS_DATA_REFRESH',
	RTM_WITHDRAWALS_NEW_ITEM_DATA_REFRESH: 'RTM_WITHDRAWALS_NEW_ITEM_DATA_REFRESH',

	RTM_WITHDRAWALS_FILTER_RESET  : 'RTM_WITHDRAWALS_FILTER_RESET',
	RTM_WITHDRAWALS_FILTER_REFRESH: 'RTM_WITHDRAWALS_FILTER_REFRESH',
	RTM_WITHDRAWALS_FILTER_APPLY  : 'RTM_WITHDRAWALS_FILTER_APPLY',

	RTM_WITHDRAWALS_UI_REFRESH: 'RTM_WITHDRAWALS_UI_REFRESH',

	RTM_WITHDRAWALS_SOCKET_SUBSCRIBE_TO_UPDATE: 'RTM_WITHDRAWALS_SOCKET_SUBSCRIBE_TO_UPDATE',
	RTM_WITHDRAWALS_SOCKET_NOTIFY_CREATE_ITEM : 'RTM_WITHDRAWALS_SOCKET_NOTIFY_CREATE_ITEM',
	RTM_WITHDRAWALS_SOCKET_NOTIFY_UPDATE_ITEM : 'RTM_WITHDRAWALS_SOCKET_NOTIFY_UPDATE_ITEM',

	RTM_WITHDRAWALS_ITEM_APPROVE: 'RTM_WITHDRAWALS_ITEM_APPROVE',
	RTM_WITHDRAWALS_ITEM_REFRESH: 'RTM_WITHDRAWALS_ITEM_REFRESH',

	listReload: () => ({
		type: actions.RTM_WITHDRAWALS_LIST_RELOAD,
	}),
	dataRefresh: (withdrawalIDs, entities, withdrawalTotals) => ({
		type: actions.RTM_WITHDRAWALS_DATA_REFRESH,
		data: {
			withdrawalIDs,
			entities,
			withdrawalTotals,
		},
	}),
	newWithdrawalListRefresh: (newWithdrawalIDs, newWithdrawalEntities) => ({
		type: actions.RTM_WITHDRAWALS_NEW_ITEM_DATA_REFRESH,
		data: {
			newWithdrawalIDs,
			newWithdrawalEntities,
		},
	}),

	filterReset: () => ({
		type: actions.RTM_WITHDRAWALS_FILTER_RESET,
	}),
	filterRefresh: data => ({
		type: actions.RTM_WITHDRAWALS_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.RTM_WITHDRAWALS_FILTER_APPLY,
	}),

	uiRefresh: data => ({
		type: actions.RTM_WITHDRAWALS_UI_REFRESH,
		data,
	}),

	notifyCreateWithdrawal: data => ({
		type: actions.RTM_WITHDRAWALS_SOCKET_NOTIFY_CREATE_ITEM,
		data,
	}),
	notifyUpdateWithdrawal: data => ({
		type: actions.RTM_WITHDRAWALS_SOCKET_NOTIFY_UPDATE_ITEM,
		data,
	}),
	subscribeToUpdate: withdrawalIDs => ({
		type: actions.RTM_WITHDRAWALS_SOCKET_SUBSCRIBE_TO_UPDATE,
		data: {
			withdrawalIDs,
		},
	}),
	withdrawalItemRefresh: (id, withdrawalItem) => ({
		type: actions.RTM_WITHDRAWALS_ITEM_REFRESH,
		data: {
			withdrawalItem,
			id,
		},
	}),
	

	withdrawalApprove: ({ wdID, reason, reasonForAdmin = '', reasonForUser = '', approved = false, userID = null }) => ({
		type: actions.RTM_WITHDRAWALS_ITEM_APPROVE,
		data: {
			wdID,
			reason,
			reasonForAdmin,
			reasonForUser,
			approved,
			userID,
		},
	}),
};

export default actions;
