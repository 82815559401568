const actions = {

	RTM_BETS_LIST_RELOAD                     : 'RTM_BETS_LIST_RELOAD',
	RTM_BETS_DATA_REFRESH                    : 'RTM_BETS_DATA_REFRESH',
	RTM_BETS_NEW_BET_LIST_REFRESH            : 'RTM_BETS_NEW_BET_LIST_REFRESH',
	RTM_BETS_EDITED_DATA_REFRESH             : 'RTM_BETS_EDITED_DATA_REFRESH',
	RTM_BETS_FILTER_RESET                    : 'RTM_BETS_FILTER_RESET',
	RTM_BETS_FILTER_REFRESH                  : 'RTM_BETS_FILTER_REFRESH',
	RTM_BETS_FILTER_APPLY                    : 'RTM_BETS_FILTER_APPLY',
	RTM_BETS_FILTER_COUNTRY_LIST_RELOAD      : 'RTM_BETS_FILTER_COUNTRY_LIST_RELOAD',
	RTM_BETS_FILTER_COUNTRY_LIST_REFRESH     : 'RTM_BETS_FILTER_COUNTRY_LIST_REFRESH',
	RTM_BETS_FILTER_LEAGUE_LIST_RELOAD       : 'RTM_BETS_FILTER_LEAGUE_LIST_RELOAD',
	RTM_BETS_FILTER_LEAGUE_LIST_REFRESH      : 'RTM_BETS_FILTER_LEAGUE_LIST_REFRESH',
	RTM_BETS_FILTER_EVENT_LIST_RELOAD        : 'RTM_BETS_FILTER_EVENT_LIST_RELOAD',
	RTM_BETS_FILTER_EVENT_LIST_REFRESH       : 'RTM_BETS_FILTER_EVENT_LIST_REFRESH',
	RTM_BETS_FILTER_EVENT_NAME_LIST_RELOAD   : 'RTM_BETS_FILTER_EVENT_NAME_LIST_RELOAD',
	RTM_BETS_FILTER_EVENT_NAME_LIST_REFRESH  : 'RTM_BETS_FILTER_EVENT_NAME_LIST_REFRESH',
	RTM_BETS_UI_REFRESH          		          : 'RTM_BETS_UI_REFRESH',
	RTM_BETS_LOADED_COUNTRY_PARENT_ID_REFRESH: 'RTM_BETS_LOADED_COUNTRY_PARENT_ID_REFRESH',
	RTM_BETS_LOADED_LEAGUE_PARENT_ID_REFRESH : 'RTM_BETS_LOADED_LEAGUE_PARENT_ID_REFRESH',
	RTM_BETS_LOADED_EVENT_PARENT_ID_REFRESH  : 'RTM_BETS_LOADED_EVENT_PARENT_ID_REFRESH',
	RTM_BETS_SOCKET_SUBSCRIBE_TO_UPDATE      : 'RTM_BETS_SOCKET_SUBSCRIBE_TO_UPDATE',
	RTM_BETS_SOCKET_NOTIFY_CREATE_BET        : 'RTM_BETS_SOCKET_NOTIFY_CREATE_BET',
	RTM_BETS_SOCKET_NOTIFY_UPDATE_BET        : 'RTM_BETS_SOCKET_NOTIFY_UPDATE_BET',
	RTM_BETS_ACCEPT_BET          		          : 'RTM_BETS_ACCEPT_BET',
	RTM_BETS_REJECT_BET          		          : 'RTM_BETS_REJECT_BET',
	RTM_BETS_EDITED_BET_SAVE             		  : 'RTM_BETS_EDITED_BET_SAVE',
	LEAGUE_FILTERED_LIST                     : 'LEAGUE_FILTERED_LIST',

	setFilteredLeagueList: (filteredLeagues) => ({
		type: actions.LEAGUE_FILTERED_LIST,
		data: { filteredLeagues },
	}),
	listReload: () => ({
		type: actions.RTM_BETS_LIST_RELOAD,
	}),
	dataRefresh: (betIDs, entities) => ({
		type: actions.RTM_BETS_DATA_REFRESH,
		data: {
			betIDs,
			entities,
		},
	}),
	newBetListRefresh: (newBetIDs, newBetEntities) => ({
		type: actions.RTM_BETS_NEW_BET_LIST_REFRESH,
		data: {
			newBetIDs,
			newBetEntities,
		},
	}),
	editedDataRefresh: (editedBetIDs, editedBetEntities) => ({
		type: actions.RTM_BETS_EDITED_DATA_REFRESH,
		data: {
			editedBetIDs,
			editedBetEntities,
		},
	}),

	filterReset: () => ({
		type: actions.RTM_BETS_FILTER_RESET,
	}),
	filterRefresh: data => ({
		type: actions.RTM_BETS_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.RTM_BETS_FILTER_APPLY,
	}),
	countryListReload: (selectedSport, oldSelectedSport ) => ({
		type: actions.RTM_BETS_FILTER_COUNTRY_LIST_RELOAD,
		data: {
			selectedSport,
			oldSelectedSport,
		},
	}),
	leagueListReload: (countryIDs, oldCountryIDs) => ({
		type: actions.RTM_BETS_FILTER_LEAGUE_LIST_RELOAD,
		data: {
			countryIDs,
			oldCountryIDs,
		},
	}),
	eventNameListReload: (eventName) => ({
		type: actions.RTM_BETS_FILTER_EVENT_NAME_LIST_RELOAD,
		data: {
			eventName,
		},
	}),
	eventListReload: (newLeagueIDs, oldLeagueIDs) => ({
		type: actions.RTM_BETS_FILTER_EVENT_LIST_RELOAD,
		data: {
			newLeagueIDs,
			oldLeagueIDs,
		},
	}),
	countryListRefresh: list => ({
		type: actions.RTM_BETS_FILTER_COUNTRY_LIST_REFRESH,
		data: list,
	}),
	leagueListRefresh: list => ({
		type: actions.RTM_BETS_FILTER_LEAGUE_LIST_REFRESH,
		data: list,
	}),
	eventListRefresh: list => ({
		type: actions.RTM_BETS_FILTER_EVENT_LIST_REFRESH,
		data: list,
	}),
	eventNameListRefresh: list => ({
		type: actions.RTM_BETS_FILTER_EVENT_NAME_LIST_REFRESH,
		data: list,
	}),

	uiRefresh: data => ({
		type: actions.RTM_BETS_UI_REFRESH,
		data,
	}),
	loadedCountryParentIDsRefresh: sportID => ({
		type: actions.RTM_BETS_LOADED_COUNTRY_PARENT_ID_REFRESH,
		data: {
			sportID,
		},
	}),
	loadedLeagueParentIDsRefresh: sportID => ({
		type: actions.RTM_BETS_LOADED_LEAGUE_PARENT_ID_REFRESH,
		data: {
			sportID,
		},
	}),
	loadedEventParentIDsRefresh: leagueID => ({
		type: actions.RTM_BETS_LOADED_EVENT_PARENT_ID_REFRESH,
		data: {
			leagueID,
		},
	}),

	notifyCreateBet: data => ({
		type: actions.RTM_BETS_SOCKET_NOTIFY_CREATE_BET,
		data,
	}),
	notifyUpdateBet: data => ({
		type: actions.RTM_BETS_SOCKET_NOTIFY_UPDATE_BET,
		data,
	}),
	subscribeToUpdate: betIDs => ({
		type: actions.RTM_BETS_SOCKET_SUBSCRIBE_TO_UPDATE,
		data: {
			betIDs,
		},
	}),

	acceptBet: betID => ({
		type: actions.RTM_BETS_ACCEPT_BET,
		data: {
			betID,
		},
	}),
	rejectBet: betID => ({
		type: actions.RTM_BETS_REJECT_BET,
		data: {
			betID,
		},
	}),
	editedBetSave: betID => ({
		type: actions.RTM_BETS_EDITED_BET_SAVE,
		data: {
			betID,
		},
	}),
};

export default actions;
