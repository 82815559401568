export interface ISMS {
	issueDate: string;
	message  : string;
	status   : number;
	id       : number;
	messageID: number;
	subject  : string;
}

export interface ISmsUI {
	loading      : boolean;
	modalVisible : boolean;
	modalCalendar: boolean;
	modalTemplate: boolean;
}

export interface ISmsFilter {
	totalCount: number;
	hasMore   : boolean;
}

export interface ISmsUserData {
	firstName: string;
	lastName : string;
	email    : string;
	id       : number;
}


type TCallBack = <T>( data: T & { SMSes: ISMS[]}) => {
	SMSes: ISMS[];
} & T;

export interface IMessageBoxProps {
	SMS     : ISMS;
	setState: (cb: TCallBack) => void;
}


export interface GetPrimaryTitleProps {
	lang: {
		editTemplateTitle: string;
		titleAddTemplate : string;
		titleSaveTemplate: string;
	};
	templateEditMode: boolean;
	addTemplate     : boolean;
	permissionsData: {
		update: boolean;
		create: boolean;
	};
}

export interface PrimaryTitleResult {
	primaryButtonTitle: string;
	readOnly          : boolean;
}

export enum EMessageStatus {
	SUCCESS = 1 ,
	FAILED ,
	PENDING ,
}
