import { eventChannel } from 'redux-saga';
import getSocket from '../../helpers/sockets/socket';
import subscribe from './subscribes';
import { logger } from '../../helpers/logger';

export function watchMessages() {
	const socket = getSocket();

	return eventChannel(emit => {
		subscribe(socket, emit);

		// unsubscriber
		return () => {
			logger.log('Socket off');
		};
	});
}
