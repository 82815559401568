import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	entities            : {},
	entitiesGames       : {},
	allCheckedCategories: {},
	showCheckboxInto    : false,
	oldDataChecked      : {},
	UI                  : {
		loading: false,
	},
});

export default function casinoGameCategoryListReducer(state = initState, action) {

	switch (action.type) {

		case actions.CASINO_GAME_CATEGORY_LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.CASINO_GAME_CATEGORY_LIST_REFRESH_GAMES: {
			const { entities } = action.data;
			return state.set('entitiesGames', entities);
		}

		case actions.CASINO_GAME_CATEGORY_LIST_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.CHECKED_ALL_GAMES: {
			return state.set('allCheckedCategories', action.data);
		}

		case actions.SHOW_CHECK_BOX_CHECK_ALL_INTO: {
			return state.set('showCheckboxInto', action.data);
		}

		case actions.OLD_DATA_CHECKED: {
			return state.set('oldDataChecked', action.data);
		}

		case actions.CASINO_GAME_CATEGORIES_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
