import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import listActions from '../payways/actions';

import { paymentsAPI } from '../../../../../helpers/api/payments';
import notifications from '../../../../../helpers/notifications';

import { adaptBaseNamesData, prepareNames } from './utils';
import { logger } from '../../../../../helpers/logger';

const prefix = 'payments.payway';

const messages = {
	errorPaywayReload: `${prefix}.errorPaywayReload`,
	errorPaywaySave  : `${prefix}.errorPaywaySave`,
	successPaywaySave: `${prefix}.successPaywaySave`,
};

function getStoreData({ Partner: { Websites } }) {

	const { Payway } = Websites.Payways;
	const UI = Payway.get('UI');

	return {
		baseData  : Payway.get('baseData'),
		namesData : Payway.get('namesData'),
		paymentID : UI.paymentID,
		paywayID  : UI.paywayID,
		closeModal: UI.closeModal,
		langID    : UI.langID,
	};
}

function* namesDataReload() {

	yield takeEvery(actions.PAYWAY_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { langID } = yield select(getStoreData);
		const { paymentID, paywayID } = action.data;

		let baseNamesData = adaptBaseNamesData([]);
		try {
			const params = { lang_id: langID };
			const res = yield call(paymentsAPI.paywayNamesList, paymentID, paywayID, params);
			if (res && res.status === 200) {
				baseNamesData = adaptBaseNamesData(res.data.data);
			}

		} catch (error) {
			notifications.showError(messages.errorPaywayReload, error);
		}

		yield put(actions.baseDataRefresh(baseNamesData.baseData));
		yield put(actions.namesDataRefresh(baseNamesData.namesData));
		yield put(actions.uiRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

function* paywaySave() {

	yield takeEvery(actions.PAYWAY_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, namesData, paymentID, paywayID, langID, closeModal } = yield select(getStoreData);
		let isError = false;

		try {
			// names
			const params = { lang_id: langID };
			const preparedData = prepareNames(baseData, namesData, langID);
			yield call(paymentsAPI.paywayNamesUpdate, paymentID, paywayID, preparedData, params);
			yield put(actions.uiRefresh({ isChanged: false }));

			notifications.showSuccess(messages.successPaywaySave);

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorPaywaySave, error);
			logger.log(error);
		}

		if (!isError && !closeModal) {
			yield put(actions.namesDataReload(paymentID, paywayID));
		}

		yield put(listActions.listReload(paymentID));
		yield put(actions.uiRefresh({
			loading: false,
			visible: !(closeModal && !isError),
		}));
	});
}

export default function* paywayModalSaga() {
	yield all([
		fork(namesDataReload),
		fork(paywaySave),
	]);
}
