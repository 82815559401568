import io from 'socket.io-client';
import isPlainObject from 'lodash/isPlainObject';
import { SOCKET_URL } from '../../config';
import { getRawToken, getStoredDataBaseLangID, getWebsiteID } from '../utility';
import { PRODUCT_TYPE } from '../../constants/common';

const socket = createSocket();

export function createSocket() {

	const storeData = getStoreData();

	const socket = io(SOCKET_URL, {
		transports: ['websocket'],
		query     : {
			token       : storeData.token,
			lang_id     : storeData.langID,
			website_id  : storeData.websiteID,
			product_type: PRODUCT_TYPE.SYSTEM,
		},
	});

	socket.on('reconnect_attempt', () => {
		const storeData = getStoreData();
		socket.io.opts.query = {
			token       : storeData.token,
			lang_id     : storeData.langID,
			website_id  : storeData.websiteID,
			product_type: PRODUCT_TYPE.SYSTEM,
		};
	});

	return socket;
}

export function createEvent(eventType, payload = {}) {
	const event = {
		type: eventType,
		body: {
			...payload,
		},
	};

	return event;
}

export function emitEvent(eventType, event) {

	const emitter = new Promise((resolve, reject) => {
		try {
			socket.emit(eventType, event, res => {
				resolve(res);
			});
		} catch (err) {
			reject(err);
		}
	});

	return emitter;
}

export function createError(socketResponse) {

	let errorMessage = null;

	if (!isPlainObject(socketResponse)) {
		errorMessage = `Socket response is not an object: ${socketResponse}`;
	}

	if (socketResponse.body) {
		errorMessage = `Socket response error: ${socketResponse.body}`;
		if (socketResponse.error) {
			errorMessage += ` ${String(socketResponse.error)}`;
		}
	}

	if (socketResponse.data) {
		errorMessage = `Socket response error: ${socketResponse.data}`;
	}

	if (errorMessage) {
		const error = new Error(errorMessage);
		error.response = {
			data: {
				text: errorMessage,
			},
		};
		return error;
	}

	return new Error(JSON.stringify(socketResponse));
}

export function closeSocketPromise(socket) {

	const result = new Promise((resolve, reject) => {
		try {
			socket.close();
			resolve(true);
		} catch (err) {
			reject(err);
		}
	});

	return result;
}

export function connectSocketPromise(socket) {

	const result = new Promise((resolve, reject) => {
		try {
			socket.connect();
			resolve(true);
		} catch (err) {
			reject(err);
		}
	});

	return result;
}

function getStoreData() {
	const data = {
		token    : getRawToken(),
		langID   : getStoredDataBaseLangID(),
		websiteID: getWebsiteID(),
	};

	return data;
}

export default () => socket;
