import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	names    : [],
	questions: [],

	UI: {
		topicID      : null,
		editMode     : false,
		loading      : false,
		loadEntity   : false,
		langID       : 1,
		isDataChanged: false,
		visible      : false,
	},
});

export default function helpCenterReducer(state = initState, action) {

	switch (action.type) {

		case actions.TOPIC_BASE_DATA_REFRESH: {
			const { dataName, data } = action.data;
			return state.set(dataName, data);
		}

		case actions.TOPIC_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.TOPIC_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
