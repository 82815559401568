import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData: {},

	UI: {
		visible   : false,
		templateID: null,
		loading   : false,
		closeModal: false,
		editMode  : false,
		langID    : null,
		isChanged : false,
	},
});

export default function emailTemplateModalReducer(state = initState, action) {

	switch (action.type) {

		case actions.EMAIL_TEMPLATE_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}

		case actions.EMAIL_TEMPLATE_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.EMAIL_TEMPLATE_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
