import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import { websiteProvidersSaga } from './providers/saga';
import websiteSaga from './website/saga';
import websiteScriptsSaga from './scripts/saga';
import websiteSmsGatewaysSaga from './smsgateways/saga';
import websitePaymentsSaga from './payments/saga';
import websitePayways from './payways/saga';
import responsibleGamblingSaga from './responsibleGambling/saga';

export default function* websitesSaga() {
	yield all([
		fork(listSaga),
		fork(websiteSaga),
		fork(websitePaymentsSaga),
		fork(websitePayways),
		fork(websiteSmsGatewaysSaga),
		fork(websiteScriptsSaga),
		fork(responsibleGamblingSaga),
		fork(websiteProvidersSaga),
	]);
}
