import dayjs from 'dayjs';
import { Map } from 'immutable';
import actions from './actions';
import { FORMATS } from '../../../helpers/commonConstants';

const yearAgo = dayjs()
	.subtract(12, 'months')
	.format(FORMATS.date);
const yearNow = dayjs()
	.add(1, 'days')
	.format(FORMATS.date);

const initState = new Map({
	casinoStatistic: {},

	UI: {
		dateFrom: yearAgo,
		dateTo  : yearNow,
		loading : false,
	},
});

export default function statisticReducer(state = initState, action) {
	switch (action.type) {
		case actions.USER_CASINO_STATISTIC_REFRESH: {
			return state.set('casinoStatistic', action.data);
		}
		case actions.USER_CASINO_STATISTIC_SET_VALUE_UI: {
			const { valueName, value } = action.data;
			const UI = new Map(state.get('UI')).toObject();
			UI[valueName] = value;

			return state.set('UI', UI);
		}

		default:
			return state;
	}
}
