import { DestroyAllWrapper } from './notifications.style';
import { AppNotification } from '../../helpers/notifications';
import { CloseOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const lang = {
	clearAll: <FormattedMessage id="notifications.clearAll"  />,
};
export const DestroyAll = () => {
	const notifications = new AppNotification();
	return <DestroyAllWrapper className="destroy_all" >
		<button onClick={() => {
			notifications.destroyAll();
		}}>
			{lang.clearAll}
			<CloseOutlined />
		</button>

	</DestroyAllWrapper>;
};
