import { makeActionCreator } from '../helpers';

const prefix = 'NOTIFICATIONS';

const TYPES = {
	COUNT_RELOAD: `${prefix}_COUNT_RELOAD`,
	COUNT_MERGE	: `${prefix}_COUNT_MERGE`,

	DATA_RELOAD		: `${prefix}_DATA_RELOAD`,
	DATA_REFRESH	: `${prefix}_DATA_REFRESH`,
	DATA_MARK_ONE: `${prefix}_DATA_MARK_ONE`,
	DATA_MARK_ALL: `${prefix}_DATA_MARK_ALL`,
	DATA_RESET			: `${prefix}_DATA_RESET`,

	PAGE_CHANGE         					: `${prefix}_PAGE_CHANGE`,
	PAGE_MERGE          					: `${prefix}_PAGE_MERGE`,
	MORE_MERGE          					: `${prefix}_MORE_MERGE`,
	UI_REFRESH         						: `${prefix}_UI_REFRESH`,
	SOCKET_NOTIFY_CREATE_RISK: `${prefix}_SOCKET_NOTIFY_CREATE_RISK`,
	SOCKET_NOTIFY_UPDATE_RISK: `${prefix}_SOCKET_NOTIFY_UPDATE_RISK`,

	SOCKET_NOTIFY_CREATE_DEPOSIT  	: `${prefix}_SOCKET_NOTIFY_CREATE_DEPOSIT`,
	SOCKET_NOTIFY_CREATE_CASINO  		: `${prefix}_SOCKET_NOTIFY_CREATE_CASINO`,
	SOCKET_NOTIFY_CREATE_WITHDRAWAL: `${prefix}_SOCKET_NOTIFY_CREATE_WITHDRAWAL`,

	SOCKET_NOTIFY_UPDATE_WITHDRAWAL: `${prefix}_SOCKET_NOTIFY_UPDATE_WITHDRAWAL`,
	SOCKET_SUBSCRIBE_TO_UPDATE   		: `${prefix}_SOCKET_SUBSCRIBE_TO_UPDATE`,

	NOTIF_DATA_REFRESH				: `${prefix}_NOTIF_DATA_REFRESH`,
	NEW_NOTIF_LIST_REFRESH: `${prefix}_NEW_NOTIF_LIST_REFRESH`,
	SOCET_DATA_REFRESH				: `${prefix}_SOCET_DATA_REFRESH`,
	RISK_DATA_RESET  					: `${prefix}_RISK_DATA_RESET`,

	ACTUAL_IDS_SUBSCRIBE: `${prefix}_ACTUAL_IDS_SUBSCRIBE`,
	RESET_NOTIF_IDS					: `${prefix}_RESET_NOTIF_IDS`,
	DATA_REFRESH_SEEN			: `${prefix}_DATA_REFRESH_SEEN`,
};

const actions = {
	...TYPES,

	countReload: makeActionCreator(TYPES.COUNT_RELOAD),
	countMerge	: makeActionCreator(TYPES.COUNT_MERGE, 'count'),

	dataReload   			: makeActionCreator(TYPES.DATA_RELOAD, 'typeID', 'id'),
	dataRefresh  			: makeActionCreator(TYPES.DATA_REFRESH, 'typeID', 'notificationsList', 'id', 'byApi'),
	socetDataRefresh: makeActionCreator(TYPES.SOCET_DATA_REFRESH, 'typeID', 'notificationsList'),
	dataMarkOne  			: makeActionCreator(TYPES.DATA_MARK_ONE, 'typeID', 'id', 'websiteID'),
	dataMarkAll  			: makeActionCreator(TYPES.DATA_MARK_ALL, 'typeID', 'websiteID'),
	dataReset    			: makeActionCreator(TYPES.DATA_RESET, 'typeID'),
	riskDataReset			: makeActionCreator(TYPES.RISK_DATA_RESET, 'typeID', 'tab', 'closeModal'),

	pageChange  			: makeActionCreator(TYPES.PAGE_CHANGE, 'typeID', 'pageNumber'),
	pageMerge   			: makeActionCreator(TYPES.PAGE_MERGE, 'page'),
	hasMoreMerge			: makeActionCreator(TYPES.MORE_MERGE, 'hasMore'),
	dataRefreshSeen: makeActionCreator(TYPES.DATA_REFRESH_SEEN, 'sectionName', 'seenList'),

	uiRefresh         				: makeActionCreator(TYPES.UI_REFRESH, 'UI'),
	notifyCreateRisk    		: makeActionCreator(TYPES.SOCKET_NOTIFY_CREATE_RISK, 'data'),
	notifyUpdateRisk    		: makeActionCreator(TYPES.SOCKET_NOTIFY_UPDATE_RISK, 'data'),
	notifyCreateDeposit 		: makeActionCreator(TYPES.SOCKET_NOTIFY_CREATE_DEPOSIT, 'data'),
	notifyCreateCasino  		: makeActionCreator(TYPES.SOCKET_NOTIFY_CREATE_CASINO, 'data'),
	notifyCreateWithdrawal: makeActionCreator(TYPES.SOCKET_NOTIFY_CREATE_WITHDRAWAL, 'data'),
	notifyUpdateWithdrawal: makeActionCreator(TYPES.SOCKET_NOTIFY_UPDATE_WITHDRAWAL, 'data'),

	subscribeToUpdate		: makeActionCreator(TYPES.SOCKET_SUBSCRIBE_TO_UPDATE, 'notifIDs'),
	notifDataRefresh 		: makeActionCreator(TYPES.NOTIF_DATA_REFRESH, 'notifIDs', 'entities'),
	newNotifListRefresh: makeActionCreator(TYPES.NEW_NOTIF_LIST_REFRESH, 'newNotifIDs', 'newNotifEntities'),

	actualIDsSubscribe: makeActionCreator(TYPES.ACTUAL_IDS_SUBSCRIBE, 'typeID', 'subscribeIDs'),
	resetNotifIDs  			: makeActionCreator(TYPES.RESET_NOTIF_IDS),
};

export default actions;
