const actions = {

	LIST_RELOAD       : 'PERSONAL_LIST_RELOAD',
	LIST_REFRESH      : 'PERSONAL_LIST_REFRESH',
	UI_REFRESH        : 'PERSONAL_LIST_UI_REFRESH',
	ITEM_STATUS_UPDATE: 'PERSONAL_LIST_ITEM_STATUS_UPDATE',

	listReload: () => ({
		type: actions.LIST_RELOAD,
	}),
	listRefresh: (list) => ({
		type: actions.LIST_REFRESH,
		data: {
			list,
		},
	}),
	uiRefresh: (UI) => ({
		type: actions.UI_REFRESH,
		data: {
			UI,
		},
	}),
	statusUpdate: (userID, statusID) => ({
		type: actions.ITEM_STATUS_UPDATE,
		data: {
			userID,
			statusID,
		},
	}),
};

export default actions;
