const actions = {

	AVAILABLE_PERMISSIONS_LIST_RELOAD : 'AVAILABLE_PERMISSIONS_LIST_RELOAD',
	AVAILABLE_PERMISSIONS_LIST_REFRESH: 'AVAILABLE_PERMISSIONS_LIST_REFRESH',
	AVAILABLE_ROLES_LIST_RELOAD       : 'AVAILABLE_ROLES_LIST_RELOAD',
	AVAILABLE_ROLES_LIST_REFRESH      : 'AVAILABLE_ROLES_LIST_REFRESH',
	AVAILABLE_ROLES_ITEM_SAVE         : 'AVAILABLE_ROLES_ITEM_SAVE',
	AVAILABLE_ROLES_ITEM_DELETE       : 'AVAILABLE_ROLES_ITEM_DELETE',
	AVAILABLE_ROLES_ROLE_UI_REFRESH   : 'AVAILABLE_ROLES_MODAL_UI_REFRESH',
	AVAILABLE_ROLES_ROLE_UI_RESET     : 'AVAILABLE_ROLES_ROLE_UI_RESET',

	permissionsListReload: () => ({
		type: actions.AVAILABLE_PERMISSIONS_LIST_RELOAD,
	}),
	permissionsListRefresh: permissionsList => ({
		type: actions.AVAILABLE_PERMISSIONS_LIST_REFRESH,
		data: {
			permissionsList,
		},
	}),
	rolesListReload: (data) => ({
		type: actions.AVAILABLE_ROLES_LIST_RELOAD,
		data: {
			partner: data,
		},
	}),
	rolesListRefresh: rolesList => ({
		type: actions.AVAILABLE_ROLES_LIST_REFRESH,
		data: {
			rolesList,
		},
	}),
	roleSave: (data) => ({
		type: actions.AVAILABLE_ROLES_ITEM_SAVE,
		data,
	}),
	roleDelete: (roleID) => ({
		type: actions.AVAILABLE_ROLES_ITEM_DELETE,
		data: {
			roleID,
		},
	}),
	roleUIRefresh: data => ({
		type: actions.AVAILABLE_ROLES_ROLE_UI_REFRESH,
		data,
	}),
	roleUIReset: () => ({
		type: actions.AVAILABLE_ROLES_ROLE_UI_RESET,
	}),
};

export default actions;
