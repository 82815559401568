const actions = {

	SELECTION_BASE_DATA_RELOAD : 'SELECTION_BASE_DATA_RELOAD',
	SELECTION_BASE_DATA_REFRESH: 'SELECTION_BASE_DATA_REFRESH',

	SELECTION_NAMES_DATA_RELOAD    : 'SELECTION_NAMES_DATA_RELOAD',
	SELECTION_NAMES_DATA_REFRESH   : 'SELECTION_NAMES_DATA_REFRESH',
	SELECTION_ORDERS_DATA_RELOAD   : 'SELECTION_ORDERS_DATA_RELOAD',
	SELECTION_ORDERS_DATA_REFRESH  : 'SELECTION_ORDERS_DATA_REFRESH',
	SELECTION_SETTINGS_DATA_RELOAD : 'SELECTION_SETTINGS_DATA_RELOAD',
	SELECTION_SETTINGS_DATA_REFRESH: 'SELECTION_SETTINGS_DATA_REFRESH',

	SELECTION_UPDATE		          : 'SELECTION_UPDATE',
	SELECTION_DELETE		          : 'SELECTION_DELETE',
	SELECTION_RESTORE 		        : 'SELECTION_RESTORE',
	SELECTION_CHECK_DELETE		    : 'SELECTION_CHECK_DELETE',
	SELECTION_SET_MODAL_VALUE_UI: 'SELECTION_SET_MODAL_VALUE_UI',
	SELECTION_MODAL_UI_REFRESH  : 'SELECTION_MODAL_UI_REFRESH',

	SELECTION_SET_CONFIRM_VALUE_UI: 'SELECTION_SET_CONFIRM_VALUE_UI',
	SELECTION_CONFIRM_UI_REFRESH  : 'SELECTION_CONFIRM_UI_REFRESH',

	SELECTION_DATA_RESET: 'SELECTION_DATA_RESET',

	baseDataReload: selectionID => ({
		type: actions.SELECTION_BASE_DATA_RELOAD,
		data: {
			selectionID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.SELECTION_BASE_DATA_REFRESH,
		data,
	}),

	namesDataReload: selectionID => ({
		type: actions.SELECTION_NAMES_DATA_RELOAD,
		data: {
			selectionID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.SELECTION_NAMES_DATA_REFRESH,
		data,
	}),
	ordersDataReload: selectionID => ({
		type: actions.SELECTION_ORDERS_DATA_RELOAD,
		data: {
			selectionID,
		},
	}),
	ordersDataRefresh: data => ({
		type: actions.SELECTION_ORDERS_DATA_REFRESH,
		data,
	}),
	settingsDataReload: selectionID => ({
		type: actions.SELECTION_SETTINGS_DATA_RELOAD,
		data: {
			selectionID,
		},
	}),
	settingsDataRefresh: data => ({
		type: actions.SELECTION_SETTINGS_DATA_REFRESH,
		data,
	}),

	selectionUpdate: () => ({
		type: actions.SELECTION_UPDATE,
	}),
	selectionCheckDelete: (selectionID, selectionName) => ({
		type: actions.SELECTION_CHECK_DELETE,
		data: {
			selectionID,
			selectionName,
		},
	}),
	selectionDelete: selectionID => ({
		type: actions.SELECTION_DELETE,
		data: {
			selectionID,
		},
	}),
	selectionRestore: selectionID => ({
		type: actions.SELECTION_RESTORE,
		data: {
			selectionID,
		},
	}),
	setModalValueUI: (valueName, value) => ({
		type: actions.SELECTION_SET_MODAL_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	modalUIRefresh: data => ({
		type: actions.SELECTION_MODAL_UI_REFRESH,
		data,
	}),
	setConfirmValueUI: (valueName, value) => ({
		type: actions.SELECTION_SET_CONFIRM_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	confirmUIRefresh: data => ({
		type: actions.SELECTION_CONFIRM_UI_REFRESH,
		data,
	}),
	selectionDataReset: () => ({
		type: actions.SELECTION_DATA_RESET,
	}),
};

export default actions;
