import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { connect } from 'react-redux';
import toInteger from 'lodash/toInteger';
import { userTie } from 'react-icons-kit/icomoon/userTie';
import { SelectIconWrapper } from '../../../components/uielements/styles/selectList.style';

import PartnerSelect from '../../../components/connected/PartnerSelect';
import appActions from '../../../redux/app/actions';

import { Wrapper } from './PartnerSwitcher.style';


class PartnerSwitcher extends Component {

	static propTypes = {
		partnerID    : PropTypes.number.isRequired,
		partnerChange: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.onChange         = this.onChange.bind(this);
		this.renderPrefixIcon = this.renderPrefixIcon.bind(this);
	}

	onChange(newPartnerID) {
		const { partnerID, partnerChange } = this.props;
		if (newPartnerID === partnerID) {
			return;
		}

		partnerChange(newPartnerID);
	}

	renderPrefixIcon() {

		return (
			<SelectIconWrapper>
				<Icon icon={userTie} />
			</SelectIconWrapper>
		);
	}

	render() {
		const { partnerID } = this.props;
		return (
			<Wrapper>
				<div className="control">
					<PartnerSelect
						preFixIcon={this.renderPrefixIcon()}
						noAutocomplete
						partnerID={partnerID}
						onChange={this.onChange}
						usePopupContainer = {false}
					/>
				</div>
			</Wrapper>
		);
	}
}

const mapStateToProps = state => {
	const { App } = state;

	return {
		partnerID: toInteger(App.get('partnerID')),
	};
};

const mapDispatchToProps = {
	partnerChange: appActions.partnerChange,
};

const connectedPartnerSwitcher = connect(
	mapStateToProps,
	mapDispatchToProps
)(PartnerSwitcher);

// because of: https://github.com/reduxjs/react-redux/issues/111#issuecomment-140744686
connectedPartnerSwitcher.defaultProps = PartnerSwitcher.defaultProps;

export default connectedPartnerSwitcher;
