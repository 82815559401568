const actions = {

	MARKET_HISTORY_MODEL_DATA_RELOAD : 'MARKET_HISTORY_MODEL_DATA_RELOAD',
	MARKET_HISTORY_MODEL_DATA_REFRESH: 'MARKET_HISTORY_MODEL_DATA_REFRESH',
	MARKET_HISTORY_UI_REFRESH        : 'MARKET_HISTORY_UI_REFRESH',

	dataReload: (marketID, logModel) => ({
		type: actions.MARKET_HISTORY_MODEL_DATA_RELOAD,
		data: {
			marketID,
			logModel,
		},
	}),
	dataRefresh: (logData, logModel) => ({
		type: actions.MARKET_HISTORY_MODEL_DATA_REFRESH,
		data: {
			logData,
			logModel,
		},
	}),
	uiRefresh: data => ({
		type: actions.MARKET_HISTORY_UI_REFRESH,
		data,
	}),
};

export default actions;
