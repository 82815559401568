import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	mappingList       : [],
	unmappedList      : [],
	selectedProviderID: null,

	modalUI: {
		visible     : false,
		loading     : false,
		sportID     : null,
		categoryID  : null,
		categoryName: null,
		typeID      : null,
		closeModal  : false,
		isChanged   : false,
	},
});

export default function categoryMappingReducer(state = initState, action) {

	switch (action.type) {

		case actions.CATEGORY_MAPPING_DATA_RESET: {
			return initState;
		}

		case actions.CATEGORY_MAPPING_LIST_REFRESH: {
			return state.set('mappingList', action.data);
		}

		case actions.CATEGORY_UNMAPPED_LIST_REFRESH: {
			return state.set('unmappedList', action.data);
		}

		case actions.CATEGORY_MAPPING_SET_PROVIDER_ID: {
			const { providerID } = action.data;
			return state.set('selectedProviderID', providerID);
		}

		case actions.CATEGORY_MAPPING_MODAL_UI_REFRESH: {
			const target = state.get('modalUI');
			const result = fill(action.data, target);
			return state.set('modalUI', result);
		}

		default:
			return state;
	}
}
