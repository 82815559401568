const actions = {

	RTM_CASINO_LIST_RELOAD                 : 'RTM_CASINO_LIST_RELOAD',
	RTM_CASINO_DATA_REFRESH                : 'RTM_CASINO_DATA_REFRESH',
	RTM_CASINO_NEW_TRANSACTION_LIST_REFRESH: 'RTM_CASINO_NEW_TRANSACTION_LIST_REFRESH',

	RTM_CASINO_FILTER_RESET  : 'RTM_CASINO_FILTER_RESET',
	RTM_CASINO_FILTER_REFRESH: 'RTM_CASINO_FILTER_REFRESH',
	RTM_CASINO_FILTER_APPLY  : 'RTM_CASINO_FILTER_APPLY',

	RTM_CASINO_UI_REFRESH        : 'RTM_CASINO_UI_REFRESH',
	RTM_CASINO_PAGINATION_REFRESH: 'RTM_CASINO_PAGINATION_REFRESH',
	RTM_CASINO_SORTING_REFRESH   : 'RTM_CASINO_SORTING_REFRESH',

	RTM_CASINO_SOCKET_SUBSCRIBE_TO_UPDATE      : 'RTM_CASINO_SOCKET_SUBSCRIBE_TO_UPDATE',
	RTM_CASINO_SOCKET_NOTIFY_CREATE_TRANSACTION: 'RTM_CASINO_SOCKET_NOTIFY_CREATE_TRANSACTION',
	RTM_CASINO_SOCKET_NOTIFY_UPDATE_TRANSACTION: 'RTM_CASINO_SOCKET_NOTIFY_UPDATE_TRANSACTION',

	RTM_CASINO_TAKE_SAVED_TABLES: 'RTM_CASINO_TAKE_SAVED_TABLES',
	RTM_CASINO_COLUMNS_REFRESH  : 'RTM_CASINO_COLUMNS_REFRESH',
	RTM_CASINO_POST_TABLES      : 'RTM_CASINO_POST_TABLES',

	listReload: () => ({
		type: actions.RTM_CASINO_LIST_RELOAD,
	}),
	dataRefresh: (transactionIDs, entities, casinoTotals) => ({
		type: actions.RTM_CASINO_DATA_REFRESH,
		data: {
			transactionIDs,
			entities,
			casinoTotals,
		},
	}),
	newTransactionListRefresh: (newTransactionIDs, newTransactionEntities) => ({
		type: actions.RTM_CASINO_NEW_TRANSACTION_LIST_REFRESH,
		data: {
			newTransactionIDs,
			newTransactionEntities,
		},
	}),

	filterReset: () => ({
		type: actions.RTM_CASINO_FILTER_RESET,
	}),
	filterRefresh: data => ({
		type: actions.RTM_CASINO_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.RTM_CASINO_FILTER_APPLY,
	}),

	uiRefresh: data => ({
		type: actions.RTM_CASINO_UI_REFRESH,
		data,
	}),
	paginationRefresh: data => ({
		type: actions.RTM_CASINO_PAGINATION_REFRESH,
		data,
	}),

	notifyCreateTransaction: data => ({
		type: actions.RTM_CASINO_SOCKET_NOTIFY_CREATE_TRANSACTION,
		data,
	}),
	notifyUpdateTransaction: data => ({
		type: actions.RTM_CASINO_SOCKET_NOTIFY_UPDATE_TRANSACTION,
		data,
	}),
	subscribeToUpdate: transactionIDs => ({
		type: actions.RTM_CASINO_SOCKET_SUBSCRIBE_TO_UPDATE,
		data: {
			transactionIDs,
		},
	}),
};

export default actions;
