import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import notifications from '../../../../helpers/notifications';
import { liveStatisticTypesAPI } from '../../../../helpers/api/liveStatisticTypes';
import { adaptList, convertToEntities } from '../listLST/utils';
import { liveStatisticTypesActions } from '../listLST/actions';
import { liveStatisticTypesFilterActions } from './actions';

const messages = {
	errorListLoad: 'sport.liveStatistic.load.error',
};

function getStoreData({ Sport }) {

	const { sportID, nameLST: name } = Sport.LiveStatisticTypes.Filter.get('filter');

	return {
		sportID,
		name,
	};
}


function* filterLTS() {
	yield takeEvery(liveStatisticTypesFilterActions.LST_FILTER_REFRESH, function* () {
		yield put(liveStatisticTypesActions.uiRefresh({ loading: false }));
		const { sportID, name } = yield select(getStoreData);

		const params = {
			sport_id: sportID,
			name,
		};

		try {
			const res = yield call(liveStatisticTypesAPI.listLST, params);
			if (res.status === 200) {
				const adaptData = adaptList(res.data.data);
				const entities = convertToEntities(adaptData);
				yield put(liveStatisticTypesActions.listReloadCase(adaptData));
				yield put(liveStatisticTypesActions.entitiesReloadCase(entities));
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
		}
		yield put(liveStatisticTypesActions.uiRefresh({ loading: true }));
	});
}

export default function* filterLSTSaga() {
	yield all([
		fork(filterLTS),
	]);
}
