import dayjs from 'dayjs';
import isArray from 'lodash/isArray';

import defaultLogo from '../image/flag/world-wide-web.svg';

export const durationTypes = {
	SECOND: 'second',
	MINUTE: 'minute',
	HOUR  : 'hour',
	DAY   : 'day',
	WEEK  : 'week',
	MONTH : 'month',
	YEAR  : 'year',
};

export function clearToken() {
	localStorage.removeItem('id_token');
	sessionStorage.removeItem('id_token');
}

export function clearUser() {
	localStorage.removeItem('user');
	sessionStorage.removeItem('user');
}

export function clearStorageDataByKey(key) {
	localStorage.removeItem(key);
	sessionStorage.removeItem(key);
}

export function getToken() {
	try {
		return localStorage.getItem('id_token') || sessionStorage.getItem('id_token');
	} catch (err) {
		clearToken();
		return null;
	}
}

export function getRawToken() {
	return localStorage.getItem('id_token') || sessionStorage.getItem('id_token');
}

export function getWebsiteID() {
	return localStorage.getItem('websiteID');
}

export function getUser() {
	try {
		const rawUser = localStorage.getItem('user') || sessionStorage.getItem('user');
		return JSON.parse(rawUser);
	} catch (error) {
		clearUser();
		return null;
	}
}
export const getPermissions = () => {
	const commonData = {
		projectPermissions: {},
		rootSidebar       : {},
	};
	try {
		const rawPermissions = localStorage.getItem('permissions');
		return JSON.parse(rawPermissions) || commonData;
	} catch (error) {
		clearUser();
		return commonData;
	}
};
export const getTabIDs = () => {
	try {
		const rawTabs = localStorage.getItem('tabIDS');
		return JSON.parse(rawTabs);
	} catch (error) {
		clearUser();
		return null;
	}
};

export function timeDifference(givenTime) {
	givenTime = new Date(givenTime);
	const milliseconds = new Date().getTime() - givenTime.getTime();
	const numberEnding = number => {
		return number > 1 ? 's' : '';
	};
	const number = num => num > 9 ? `${num}` : `0${num}`;
	const getTime = () => {
		let temp = Math.floor(milliseconds / 1000);
		const years = Math.floor(temp / 31536000);
		if (years) {
			const month = number(givenTime.getUTCMonth() + 1);
			const day = number(givenTime.getUTCDate());
			const year = givenTime.getUTCFullYear() % 100;
			return `${day}-${month}-${year}`;
		}
		const days = Math.floor((temp %= 31536000) / 86400);
		if (days) {
			if (days < 28) {
				return `${days} day${numberEnding(days)}`;
			}
			const months = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			];
			const month = months[givenTime.getUTCMonth()];
			const day = number(givenTime.getUTCDate());
			return `${day} ${month}`;

		}
		const hours = Math.floor((temp %= 86400) / 3600);
		if (hours) {
			return `${hours} hour${numberEnding(hours)} ago`;
		}
		const minutes = Math.floor((temp %= 3600) / 60);
		if (minutes) {
			return `${minutes} minute${numberEnding(minutes)} ago`;
		}
		return 'a few seconds ago';
	};
	return getTime();
}

// get duration in seconds of ms
export function durationSeconds(milliseconds) {
	return dayjs.duration(milliseconds).asSeconds();
}

// get duration in minutes of ms
export function durationMinutes(milliseconds) {
	return dayjs.duration(milliseconds).asMinutes();
}

// get duration in hours of ms
export function durationHours(milliseconds) {
	return dayjs.duration(milliseconds).asHours();
}

// get duration in days of ms
export function durationDays(milliseconds) {
	return dayjs.duration(milliseconds).asDays();
}

// get duration in weeks of ms
export function durationWeeks(milliseconds) {
	return dayjs.duration(milliseconds).asWeeks();
}

// get duration in months of ms
export function durationMonths(milliseconds) {
	return dayjs.duration(milliseconds).asMonths();
}

// get duration in years of ms
export function durationYears(milliseconds) {
	return dayjs.duration(milliseconds).asYears();
}

// define type duration of ms: year/month/week/day etc
export function durationType(milliseconds, exactComparison = false) {

	const result = {
		type : durationTypes.SECOND,
		value: 0,
	};

	const durations = {
		years  : durationYears(milliseconds),
		months : durationMonths(milliseconds),
		weeks  : durationWeeks(milliseconds),
		days   : durationDays(milliseconds),
		hours  : durationHours(milliseconds),
		minutes: durationMinutes(milliseconds),
		seconds: durationSeconds(milliseconds),
	};

	const exactDurations = {
		years  : Math.floor(durations.years) === durations.years ? durations.years : 0,
		months : Math.floor(durations.months) === durations.months ? durations.months : 0,
		weeks  : Math.floor(durations.weeks) === durations.weeks ? durations.weeks : 0,
		days   : Math.floor(durations.days) === durations.days ? durations.days : 0,
		hours  : Math.floor(durations.hours) === durations.hours ? durations.hours : 0,
		minutes: Math.floor(durations.minutes) === durations.minutes ? durations.minutes : 0,
		seconds: Math.floor(durations.seconds) === durations.seconds ? durations.seconds : 0,
	};

	const years = !exactComparison ? Math.floor(durations.years) : exactDurations.years;
	const months = !exactComparison ? Math.floor(durations.months) : exactDurations.months;
	const weeks = !exactComparison ? Math.floor(durations.weeks) : exactDurations.weeks;
	const days = !exactComparison ? Math.floor(durations.days) : exactDurations.days;
	const hours = !exactComparison ? Math.floor(durations.hours) : exactDurations.hours;
	const minutes = !exactComparison ? Math.floor(durations.minutes) : exactDurations.minutes;
	const seconds = !exactComparison ? Math.floor(durations.seconds) : exactDurations.seconds;

	if (years > 0) {
		result.type = durationTypes.YEAR;
		result.value = years;
	} else if (months > 0) {
		result.type = durationTypes.MONTH;
		result.value = months;
	} else if (weeks > 0) {
		result.type = durationTypes.WEEK;
		result.value = weeks;
	} else if (days > 0) {
		result.type = durationTypes.DAY;
		result.value = days;
	} else if (hours > 0) {
		result.type = durationTypes.HOUR;
		result.value = hours;
	} else if (minutes > 0) {
		result.type = durationTypes.MINUTE;
		result.value = minutes;
	} else if (seconds > 0) {
		result.type = durationTypes.SECOND;
		result.value = seconds;
	}

	return result;
}

// get type of time
export function getTimeOfMilliseconds(milliseconds, exactComparison = false, defaultTimeID = 1) {
	const result = { id: defaultTimeID, count: 0 }; // always

	if (!milliseconds || milliseconds === 0) return result;

	const ourDurationType = durationType(milliseconds, exactComparison);
	switch (ourDurationType.type) {
		case durationTypes.MINUTE:
			result.id = 1;
			result.count = ourDurationType.value;
			break;
		case durationTypes.HOUR:
			result.id = 2;
			result.count = ourDurationType.value;
			break;
		case durationTypes.DAY:
			result.id = 3;
			result.count = ourDurationType.value;
			break;
		case durationTypes.WEEK:
			result.id = 4;
			result.count = ourDurationType.value;
			break;
		case durationTypes.MONTH:
			result.id = 5;
			result.count = ourDurationType.value;
			break;
		default:
	}
	return result;
}

// convert into ms
export function getMillisecondsOfTime(timeValue, rawTimeID) {
	const timeID = Number(rawTimeID);
	if (timeID === 6 || !timeValue) return 0;

	let result = 0;

	switch (timeID) {
		case 1:
			result = dayjs.duration(timeValue, 'minutes').asMilliseconds();
			break;
		case 2:
			result = dayjs.duration(timeValue, 'hours').asMilliseconds();
			break;
		case 3:
			result = dayjs.duration(timeValue, 'days').asMilliseconds();
			break;
		case 4:
			result = dayjs.duration(timeValue, 'weeks').asMilliseconds();
			break;
		case 5:
			result = dayjs.duration(timeValue, 'months').asMilliseconds();
			break;
		default:
	}
	return result;
}

// check that value is valid array with 2 elements
export function isValidFilterArray(list) {
	return (Boolean(list) && isArray(list) && list.length === 2);
}

// save current language
export function storeLanguage(language) {
	const rawLanguage = JSON.stringify(language);
	localStorage.setItem('language', rawLanguage);
}

// restore language
export function restoreLanguage() {
	try {
		const rawLanguage = localStorage.getItem('language');
		return JSON.parse(rawLanguage);
	} catch (error) {
		clearLanguage();
		return null;
	}
}

// clear language
export function clearLanguage() {
	localStorage.removeItem('language');
}

// database lang_id
export function getStoredDataBaseLangID() {
	const defaultLangID = 1;
	const storedLanguage = restoreLanguage();
	if (!storedLanguage) {
		return defaultLangID;
	}

	const { dataBaseLangID } = storedLanguage;
	if (!dataBaseLangID) {
		return defaultLangID;
	}

	return dataBaseLangID;
}

// Settings of Customizable Tables ----------------------------------------------------------------

export function clearTableSettings(tableName) {
	localStorage.removeItem(tableName);
}

export function storeTableSettings(tableName, tableSettings) {
	const rawSettings = JSON.stringify(tableSettings);
	localStorage.setItem(tableName, rawSettings);
}

export function restoreTableSettings(tableName) {
	try {
		const rawSettings = localStorage.getItem(tableName);
		return JSON.parse(rawSettings);
	} catch (error) {
		clearTableSettings(tableName);
		return [];
	}
}

// Settings of Sidebar ----------------------------------------------------------------------------

export function clearSidebarSettings() {
	localStorage.removeItem('sidebar');
}

export function storeSidebarSettings(settings) {
	const rawSettings = JSON.stringify(settings);
	localStorage.setItem('sidebar', rawSettings);
}
export const  storeTabIDS = (tabIDS) => {
	const rawTabs = JSON.stringify(tabIDS);
	localStorage.setItem('tabIDS', rawTabs);
};

export function restoreSidebarSettings() {
	try {
		const rawSettings = localStorage.getItem('sidebar');
		return JSON.parse(rawSettings);
	} catch (error) {
		clearSidebarSettings();
		return null;
	}
}

// App Tabs ---------------------------------------------------------------------------------------

export function clearAppTabs() {
	localStorage.removeItem('appTabs');
}

export function storeAppTabs(appTabs) {
	const rawTabs = JSON.stringify(appTabs);
	localStorage.setItem('appTabs', rawTabs);
}

export function restoreAppTabs() {
	try {
		const rawTabs = localStorage.getItem('appTabs');
		return JSON.parse(rawTabs);
	} catch (error) {
		clearAppTabs();
		return null;
	}
}

// CustomerManagement Filter -----------------------------------------------------------------------------------

export function clearUsersFilter() {
	localStorage.removeItem('usersFilter');
}

export function storeUsersFilter(filter) {
	const rawFilter = JSON.stringify(filter);
	localStorage.setItem('usersFilter', rawFilter);
}

export function restoreUsersFilter() {
	try {
		const rawFilter = localStorage.getItem('usersFilter');
		return JSON.parse(rawFilter);
	} catch (error) {
		clearUsersFilter();
		return null;
	}
}

// Website ID -------------------------------------------------------------------------------------

export function storeWebsiteID(websiteID) {
	localStorage.setItem('websiteID', websiteID);
}
export function removeWebsiteID(websiteID) {
	localStorage.removeItem('websiteID', websiteID);
}


export function storePartnerID(partnerID) {
	localStorage.setItem('partnerID', partnerID);
}


export function restoreWebsiteID() {
	try {
		const user = localStorage.getItem('user') || sessionStorage.getItem('user');
		const isSuperAdmin = JSON.parse(user).userRoles.indexOf('super_admin') !== -1;
		return isSuperAdmin ? localStorage.getItem('websiteID') : JSON.parse(user).websiteID;
	} catch (error) {
		return null;
	}
}

export const restoreWebsite = () => {
	try {
		return localStorage.getItem('websiteID');

	} catch (e) {
		return null;
	}
};

export function restorePartnerID() {
	try {
		return localStorage.getItem('partnerID');
	} catch (error) {
		return null;
	}
}

export function setLocalStorageDataByKey(key, data) {
	const rawData = JSON.stringify(data);
	localStorage.setItem(key, rawData);
}

export function getLocalStorageDataByKey(key) {
	try {
		const rawData = localStorage.getItem(key);
		return JSON.parse(rawData);
	} catch (err) {
		clearStorageDataByKey(key);
		return null;
	}
}

export const iconWebsite =  defaultLogo;

export function formatDate(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


const utils = {
	durationTypes,
	getRawToken,
	durationDays,

	durationType,
	getStoredDataBaseLangID,
	iconWebsite,
};

export default utils;
