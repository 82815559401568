import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { fields } from '../list/utils';
import { IRiskBetsItem } from '../list/types';
import { IUpdateRiskBetsDataParams } from './types';

export function getUpdateDataAdapt(data: IRiskBetsItem): IUpdateRiskBetsDataParams {
	const builder = new ParamsBuilder();
	const rules   = builder.RULES;

	builder.addField(rules.isID, 'statusID', fields.statusID);

	return builder.biuldParams(data);
}