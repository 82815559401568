import { call, put, select, takeEvery, all, fork } from 'redux-saga/effects';
import actions from './actions';
import { apiRequest } from '../../../../../helpers/api';
import notification from '../../../../../helpers/notifications';

const prefix = 'sportspriorities.sports';

const messages = {
	errorReorder: `${prefix}.reordering.failed`,
};

function* reorderSportSaga() {
	yield takeEvery(actions.PRIOR_REORDER_SPORT, function* ({ data: { oldIndex, newIndex } }) {
		try {
			const sports = yield select(({ CMS }) => CMS.LandingPage.SportsPriorities.Sports.get('sports'));
			const movedItem = sports.get(oldIndex);
			const updatedSports = sports.delete(oldIndex).insert(newIndex, movedItem);
			yield put(actions.reorderSportSuccess(updatedSports));
			const orderedIds = updatedSports.map((sport, i) => ({
				id           : sport.get('id'),
				home_order_id: i + 1,
			}));
			// let slicedSports;
			// if(oldIndex > newIndex) {
			//   slicedSports = updatedSports.slice(newIndex,oldIndex + 1).map((sport,i) => ({
			//     id: sport.get('id'),
			//     home_order_id: i + 1
			//   }));
			// }
			// else {
			//   slicedSports = updatedSports.slice(oldIndex,newIndex + 1).map((sport,i) => ({
			//     id: sport.get('id'),
			//     home_order_id: i - 1
			//   }));
			// }
			yield call(apiRequest, {
				method: 'POST',
				url   : '/categoryList',
				data  : orderedIds.toJS(),
			});
		} catch (e) {
			notification.showError(messages.errorReorder);
		}
	});
}

export default function* prioritiesSportsSaga() {
	yield all([
		fork(reorderSportSaga),
	]);
}
