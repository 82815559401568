import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IInitSportRiskModal } from './types';
import { fill } from '../../../../helpers/utils';
import { IFilterItem } from '../filter/types';
import { IItemDetailsUpdateSuccses, IItemDetailsUpdateSuccsesMargin, IRiskBetsItem } from '../list/types';

const initialState: IInitSportRiskModal = {
	modalData: {},
	UI       : {
		loading    : false,
		error      : false,
		visible    : false,
		changed    : false,
		loadSuspend: false,
	},
};

export const riskSportBetsModalSlice = createSlice({
	name    : 'riskSportBetsModal',
	initialState,
	reducers: {
		setRiskModalData: (state: Draft<IInitSportRiskModal>, action: PayloadAction<IRiskBetsItem>) => {
			state.modalData = action.payload;
		},
		riskModalDataRefresh: (state: Draft<IInitSportRiskModal>, action: PayloadAction<IRiskBetsItem>) => {
			const target = state.modalData;
			state.modalData = fill(action.payload, target, true);
		},

		riskModalDataDetailsRefresh: (state: Draft<IInitSportRiskModal>, action: PayloadAction<IItemDetailsUpdateSuccses>) => {
			const target = state.modalData;
			const targetDetails = target.details.find(item => item.marketID === action.payload.marketID);
			if (action.payload.successSaved) {
				targetDetails.eventMarkets.suspendID = action.payload.suspend;
				targetDetails.eventMarkets.reason = action.payload.reason;
			}
			if (action.payload.successMarginSaved) {
				targetDetails.eventMarkets.currentMargin = action.payload.margin;
				
			}
			targetDetails.popoverVisible		= action.payload.popoverVisible;
			targetDetails.suspendLoading		= action.payload.suspendLoading;

			targetDetails.marginPopoverVisible	= action.payload.marginPopoverVisible;
			targetDetails.marginLoading			= action.payload.marginLoading;
	
			state.modalData = target;
		},

		riskModalDataDetailsRefreshMargin: (state: Draft<IInitSportRiskModal>, action: PayloadAction<IItemDetailsUpdateSuccsesMargin>) => {
			const target = state.modalData;
			const targetDetails = target.details.find(item => item.marketID === action.payload.marketID);

			targetDetails.marginPopoverVisible	= action.payload.marginPopoverVisible;
			targetDetails.marginLoading			= action.payload.marginLoading;
			if (action.payload.successMarginSaved) {
				targetDetails.eventMarkets.currentMargin = action.payload.margin;
			}
			state.modalData = target;
		},
		
		uiRefresh: (state: Draft<IInitSportRiskModal>, action: PayloadAction<IFilterItem>) => {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},
		resetSportModalData: () => {
			return initialState;
		},
	},
});


export const actions = {
	...riskSportBetsModalSlice.actions,
};

export default riskSportBetsModalSlice.reducer;

