import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';

export const fields = {
	id          : 'id',
	websiteID   : 'website_id',
	script      : 'script',
	positionID  : 'position_id',
	cookieTypeID: 'type',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptScriptsList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareScriptsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const result = rawData.map(item => {
		const preparedItem = listAdapter.prepare(item);
		if (isID(item.id)) {
			preparedItem.id = toInteger(item.id);
		}

		return preparedItem;
	});

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'positionID', fields.positionID);
	adapter.addField(rules.id, 'cookieTypeID', fields.cookieTypeID);

	adapter.addField(rules.string, 'script', fields.script);

	return adapter;
}
