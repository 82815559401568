import PropTypes from 'prop-types';
import React from 'react';
import IntlMessages from '../../../../../components/utility/intlMessages';

const lang = {
	selectedWebsites: <IntlMessages id="userInfo.tabPersonal.data.selectedWebsites" />,
};

export const WebsiteCount = ({ count, item }) => {

	if (!count[item.id]) {
		return     null;
	}
	return (
		<span>  {lang.selectedWebsites} {count[item.id]} </span>
	);
};

WebsiteCount.propTypes = {
	count: PropTypes.object.isRequired,
	item : PropTypes.shape({
		id: PropTypes.number.isRequired,
	}).isRequired,
};
