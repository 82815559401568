import { reducer as formReducer } from 'redux-form';
import Affiliates from './affiliates/reducer';

import App from './app/reducer';
import AppTabs from './appTabs/reducer';
import Auth from './auth/reducer';

import Loyalty from './loyalty/reducer';
import Casino from './casino/reducer';
import Common from './common/reducer';
import CustomerCards from './customerCards/reducer';
import CustomerIP from './customerIP/reducer';
import Integrator from './integrator/reducer';

import Dashboard from './dashboard/reducer';

import Emails from './notificationCenter/emails/emails/reducer';
import EmailTemplates from './notificationCenter/emails/emailTemplates/reducer';

import Images from './images/reducer';

import LanguageSwitcher from './languageSwitcher/reducer';

import Messages from './notificationCenter/messages/reducer';

import Notifications from './notifications/reducer';

import Staff from './staff/reducer';
import Profile from './profile/reducer';

import RTM from './rtm/reducer';

import Selections from './selections/reducer';
import Settings from './settings/reducer';
import Sidebar from './sidebar/reducer';
import Socket from './socket/reducer';

import ThemeSwitcher from './themeSwitcher/reducer';
import Tables from './tables/reducer';

import Users from './users/reducer';

import AutoWithdrawalSettings from './autoWithdrawalSettings/reducer';
import RiskManagementSettings from './riskManagement/reducer';

import Partner from './partner/reducer';
import PartnerAuth from './newAuth/reducer';

// -- new structure --

import CMS from './cms/reducer';
import Sport from './sport/reducer';
import Reports from './reports/reducer';
import NotificationsCenter from './notificationCenter/reducer';
import SportRiskManagement from './sportRiskManagement/slices';

export default {
	Affiliates,
	App,
	AppTabs,
	Auth,

	// Bonuses,
	Loyalty,

	Casino,
	Common,
	CustomerCards,
	CustomerIP,
	Integrator,

	Dashboard,

	Emails,
	EmailTemplates,

	Images,

	LanguageSwitcher,

	Messages,

	Notifications,

	Profile,

	RTM,

	SportRiskManagement,

	Selections,
	Settings,
	Sidebar,
	Socket,

	ThemeSwitcher,
	Tables,

	Users,
	AutoWithdrawalSettings,
	RiskManagementSettings,

	form: formReducer,


	// -- new struct ---

	CMS,
	Partner,
	PartnerAuth,

	Staff,

	Sport,
	Reports,
	NotificationsCenter,
};
