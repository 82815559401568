import { makeActionCreator } from '../../../helpers';

const prefix = 'EVENT_SELECTION_BET_HISTORY';

const TYPES = {
	DATA_RELOAD         : `${prefix}_DATA_RELOAD`,
	DATA_REFRESH        : `${prefix}_DATA_REFRESH`,
	UI_REFRESH          : `${prefix}_UI_REFRESH`,
	PAGINATION_REFRESH  : `${prefix}_PAGINATION_REFRESH`,
	SELECTED_IDS_REFRESH: `${prefix}_SELECTED_IDS_REFRESH`,
};

const actions = {
	...TYPES,

	dataReload        : makeActionCreator(TYPES.DATA_RELOAD, 'eventSelectionID'),
	dataRefresh       : makeActionCreator(TYPES.DATA_REFRESH, 'bets'),
	uiRefresh         : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
	paginationRefresh : makeActionCreator(TYPES.PAGINATION_REFRESH, 'pagination'),
	selectedIDsRefresh: makeActionCreator(TYPES.SELECTED_IDS_REFRESH, 'selectedIDs'),
};

export default actions;
