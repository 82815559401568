import { apiRequest } from './index';

function reportsListByPlayer(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/casinoReports/listByGamePlayer',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

export const reportsListByPlayerAPI = {
	reportsListByPlayer,
};
