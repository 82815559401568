import styled from 'styled-components';
import { AutoComplete } from 'antd';

export const Wrapper = styled(AutoComplete)`
    display: flex;
    .ant-select-selector {
        width: ${props => props.className.includes('opened') ? '200px' : 0};
        transition: all .3s;
        .ant-select-selection-search {
            .ant-input-group-wrapper {
                .ant-input-wrapper {
                    input {
                        padding: ${props => props.className.includes('opened') ? '4px 11px' : 0};
                        border: ${props => props.className.includes('opened') ? '1px solid #d9d9d9' : 'none'};
												box-shadow: none;
												outline: none;

                        &:focus {
                            border-color: #d9d9d9;
                        }
                        &:active {
                            color: rgba(0, 0, 0, 0.45);
                            border-color: #d9d9d9;
                        }
                        &:hover {
                            color:  rgba(0, 0, 0, 0.45);
                            border-color: #d9d9d9;
                        }
                    }
                    .ant-input-group-addon {
                        .ant-btn {
                            border-color: #d9d9d9;
                            &:focus {
                                border-color: #d9d9d9;
                            }
                            &:active {
                                color: rgba(0, 0, 0, 0.45);
                                border-color: #d9d9d9;
                            }
                            &:hover {
                                color:  rgba(0, 0, 0, 0.45);
                                border-color: #d9d9d9;
                            }
                            border-radius: ${props => props.className.includes('opened') ? '0 6px 6px 0' : '6px'};
														.ant-btn-icon {
																margin-right: 0;
																.anticon {
																		margin: 0;
																}
														}
                        }
                    }

                }
            }
        }
    }
`;
