import { combineReducers } from 'redux';

import RestrictOptionSearch from './restrictOptionSearch/reducer';
import Game from './game/reducer';
import CustomProvider from './customProvider/reducer';
import Categories from './category/reducer';
import Tags from './tags/reducer';
import GamesSearch from './searchResults/reducer';

export default combineReducers({
	RestrictOptionSearch,
	Game,
	GamesSearch,
	CustomProvider,
	Categories,
	Tags,

});
