import { Map } from 'immutable';
import actions from './actions';
import { createFilter, filtersURL } from '../../../../helpers/utils';
import { defaultValuesParticipantsFilter, initFilterTypes } from './utils';

const initState = new Map({
	filter     : createFilter(filtersURL.participants, initFilterTypes, defaultValuesParticipantsFilter),
	countryList: [],
	leagueList : [],
	ageList    : [],
});

export default function participantsFilterReducer(state = initState, action) {

	switch (action.type) {

		case actions.PARTICIPANTS_FILTER_SET_VALUE: {
			const { valueName, value } = action.data;
			const filter               = new Map(state.get('filter')).toObject();
			filter[valueName]          = value;

			return state.set('filter', filter);
		}

		case actions.PARTICIPANTS_FILTER_REFRESH: {
			return state.set('filter', action.data);
		}

		case actions.PARTICIPANTS_FILTER_COUNTRY_LIST_REFRESH: {
			return state.set('countryList', action.data);
		}

		case actions.PARTICIPANTS_FILTER_LEAGUE_LIST_REFRESH: {
			return state.set('leagueList', action.data);
		}

		case actions.PARTICIPANTS_FILTER_AGE_LIST_REFRESH: {
			return state.set('ageList', action.data);
		}
		case actions.FILTER_RESET: {
			return state.set('filter', defaultValuesParticipantsFilter);
		}

		default:
			return state;
	}
}
