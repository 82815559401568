import isPlainObject from 'lodash/isPlainObject';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import { CHANNEL_TYPES } from '../../../../helpers/commonConstants';
import { channelTypeList } from '../../../../helpers/commonEnums';
import { isID } from '../../../../helpers/utils';
import ParamsBuilder from '../../../../helpers/paramsBuilder';

const fields = {
	// base data
	id           : 'id',
	sportID      : 'sport_id',
	countryIDs   : 'countries_id',
	leagueIDs    : 'leagues_id',
	typeID       : 'type_id',
	genderID     : 'gender_id',
	roleID       : 'role_id',
	ageID        : 'age_id',
	birthday     : 'birthday',
	verified     : 'verified',
	eventsCount  : 'events_count',
	latestEvent  : 'latest_event',
	countries    : 'countries',
	leagues      : 'leagues',
	sport        : 'sport',
	fullName     : 'name',
	// images
	iconURL      : 'icon_url',
	iconSmallURL : 'icon_small_url',
	pictureURL   : 'picture_url',
	logoURL      : 'logo_url',
	// names
	channelID    : 'channel_id',
	channel      : 'channel',
	name         : 'name',
	altName1     : 'alt_name_1',
	altName2     : 'alt_name_2',
	info         : 'info',
	// CMS
	participantID: 'participant_id',
	//channelID   : 'channel_id',
	langID       : 'lang_id',
	description1 : 'description_1',
	description2 : 'description_2',
};

export function getListParams(sorting) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);
	builder.addField(rules.isString, 'name', fields.name);
	builder.addField(rules.isNumber, 'participantID', fields.id);

	const params = builder.biuldParams(sorting);

	return params;
}

export function adaptBaseData(rawBaseData = {}) {

	const result = {
		id      : toInteger(rawBaseData[fields.id]),
		sportID : toInteger(rawBaseData[fields.sportID]),
		typeID  : toInteger(rawBaseData[fields.typeID]),
		genderID: toInteger(rawBaseData[fields.genderID]),
		ageID   : toInteger(rawBaseData[fields.ageID]),

		countryIDs: adaptArrayID(rawBaseData[fields.countryIDs]),
		leagueIDs : adaptArrayID(rawBaseData[fields.leagueIDs]),

		birthday   : rawBaseData[fields.birthday],
		verified   : Boolean(rawBaseData[fields.verified]),
		eventsCount: toInteger(rawBaseData[fields.eventsCount]),
		latestEvent: rawBaseData[fields.latestEvent],

		iconURL     : rawBaseData[fields.iconURL],
		iconSmallURL: rawBaseData[fields.iconSmallURL],
		pictureURL  : rawBaseData[fields.pictureURL],
		logoURL     : rawBaseData[fields.logoURL],

		countries: rawBaseData[fields.countries],
		leagues  : rawBaseData[fields.leagues],
		sport    : rawBaseData[fields.sport],
		name     : rawBaseData[fields.fullName],
	};

	return result;
}

export function prepareBaseData(rawData, participantID) {

	const result = {
		[fields.sportID] : toInteger(rawData.sportID),
		[fields.typeID]  : toInteger(rawData.typeID),
		[fields.genderID]: toInteger(rawData.genderID),
		[fields.roleID]  : toInteger(rawData.roleID),
		[fields.ageID]   : toInteger(rawData.ageID),

		[fields.countryIDs]: adaptArrayID(rawData.countryIDs),
		[fields.leagueIDs] : adaptArrayID(rawData.leagueIDs),

		[fields.birthday]: rawData.birthday,
		[fields.verified]: Boolean(rawData.verified),

		[fields.iconURL]     : rawData.iconURL,
		[fields.iconSmallURL]: rawData.iconSmallURL,
		[fields.pictureURL]  : rawData.pictureURL,
		[fields.logoURL]     : rawData.logoURL,

		[fields.countries]: rawData.countries,
		[fields.leagues]  : rawData.leagues,
		[fields.sport]    : rawData.sport,
		[fields.fullName] : rawData.name,
	};

	if (isID(participantID)) {
		result.id = toInteger(participantID);
	}

	return result;
}

export function adaptNamesData(rawData = [], defaultName = null) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = [];

	channelTypeList.forEach(channelItem => {
		const channelID = channelItem.id;
		const channelName = channelItem.name;

		const namesItem =      rawData.find(item => toInteger(item[fields.channelID]) === channelID)
      || {};

		const record = {
			channelID,
			channel : channelName,
			name    : namesItem[fields.name],
			altName1: namesItem[fields.altName1],
			altName2: namesItem[fields.altName2],
			info    : namesItem[fields.info],
		};

		if (channelID === CHANNEL_TYPES.backend && !record.name && defaultName) {
			record.name = defaultName;
		}

		result.push(record);
	});

	return result;
}

export function prepareNamesData(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = [];

	rawData.forEach(namesItem => {
		if (!namesItem.name) {
			return;
		}

		result.push({
			[fields.channelID]: namesItem.channelID,
			[fields.name]     : namesItem.name,
			[fields.altName1] : namesItem.altName1,
			[fields.altName2] : namesItem.altName2,
			[fields.info]     : namesItem.info,
		});
	});

	return result;
}

export function adaptMembersList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = rawData.map(item => {
		const listItem = {
			...item,
			key: String(item.id),
		};
		return listItem;
	});

	return result;
}

export function adaptSelectedIDsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}
	const result = rawData.map(item => String(item.id));

	return result;
}

export function prepareSelectedIDsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}
	const result = rawData.map(item => {
		return {
			id: toInteger(item),
		};
	});

	return result;
}

export function adaptCMSData(serverData = [], participantID = null) {
	let rawData = cloneDeep(serverData);

	const result = [];

	if (isEmpty(rawData)) {
		rawData = [];
	}
	if (isPlainObject(rawData)) {
		rawData = [rawData];
	}

	channelTypeList.forEach(channelItem => {
		const channelID = channelItem.id;
		const cmsItem =      rawData.find(item => toInteger(item[fields.channelID]) === channelID)
      || {};

		result.push({
			channelID,
			participantID,
			description1: cmsItem[fields.description1],
			description2: cmsItem[fields.description2],
		});
	});

	return result;
}

export function prepareCMSData(rawData = [], participantID = null) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = rawData.map(item => {
		return {
			[fields.channelID]    : item.channelID,
			[fields.participantID]: participantID,
			[fields.description1] : item.description1,
			[fields.description2] : item.description2,
		};
	});

	return result;
}

function adaptArrayID(rawList) {
	if (!isArray(rawList)) {
		return [];
	}
	const result = rawList.map(item => toInteger(item));

	return result;
}
