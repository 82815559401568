import orderBy from 'lodash/orderBy';
import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { createEntities } from '../../../utility';
import cloneDeep from 'lodash/cloneDeep';

export const fields = {
	ID              : 'id',
	expireDate      : 'expire_date',
	givenDate       : 'created',
	claimDate       : 'claim_date',
	bonusTypeID     : 'bonus_type_id',
	typeID          : 'type_id',
	userID          : 'user_id',
	wonAmount       : 'won_amount',
	wager           : 'wagering_turnover',
	casinoGameName  : 'casino_game_name',
	currencyCode    : 'currency_code',
	leftSpins       : 'left_spins',
	personalID      : 'personal_id',
	spinsTotal      : 'spins_total',
	statusID        : 'status_id',
	adminID         : 'admin_id',
	cancelDate      : 'cancel_date',
	maxPayoutAmount : 'max_payout_amount',
	maxPayoutPercent: 'max_payout_percent', // 0-100 number
};

const listAdapter = createListAdapter();

export function getListParams(pagination, filter) {

	const builder = new ParamsBuilder();

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	filter.status && builder.addValue('status_id', filter.status);
	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptFreeSpinsList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = orderBy(listAdapter.adaptList(rawData), ['ID'], ['desc']);

	return createEntities(adaptedData, 'ID');
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'ID', fields.ID);
	adapter.addField(rules.id, 'bonusTypeID', fields.bonusTypeID);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'adminID', fields.adminID);

	adapter.addField(rules.dateTime, 'expireDate', fields.expireDate);
	adapter.addField(rules.dateTime, 'givenDate', fields.givenDate);
	adapter.addField(rules.dateTime, 'claimDate', fields.claimDate);
	adapter.addField(rules.dateTime, 'cancelDate', fields.cancelDate);

	adapter.addField(rules.number, 'wonAmount', fields.wonAmount);

	adapter.addField(rules.number, 'wager', fields.wager);
	adapter.addField(rules.number, 'leftSpins', fields.leftSpins);
	adapter.addField(rules.number, 'spinsTotal', fields.spinsTotal);

	adapter.addField(rules.string, 'casinoGameName', fields.casinoGameName);
	adapter.addField(rules.string, 'currencyCode', fields.currencyCode);

	adapter.addField(rules.numberOrNull,  'maxPayoutAmount', fields.maxPayoutAmount);
	adapter.addField(rules.numberOrNull,  'maxPayoutPercent', fields.maxPayoutPercent);

	return adapter;
}

export function updateFreeSpinList({ entities = {}, spinID, loading, statusID, adminID, cancelDate }) {
	const clonedEntities = cloneDeep(entities);

	const spin = clonedEntities[spinID];

	if (spin) {
		clonedEntities[spinID]  = {
			...spin,
			loading,
			...(statusID && { statusID }),
			...(adminID && { adminID }),
			...(cancelDate && { cancelDate }),
		};

		return clonedEntities;
	}

	return clonedEntities;
}