import React from 'react';
import { useNavigate, NavigateFunction } from 'react-router';

interface WithRouterProps {
	history: NavigateFunction;
}

export const withRouter = <P extends object>(Component: React.ComponentType<P & WithRouterProps>) => {
	const Wrapper: React.FC<P> = (props: P) => {
		const history = useNavigate();

		return <Component history={history} {...props} />;
	};

	return Wrapper;
};
