import { all, fork } from 'redux-saga/effects';

import treeSaga from './tree/saga';
import categorySaga from './category/saga';
import leagueRestrictionSaga from './leagueRestriction/saga';
import regulationTypesSaga from './regulationTypes/saga';
import mappingSaga from './mapping/saga';
import historySaga from './history/saga';
import marketSettingsSaga from './settings/saga';
import bettingRules from '../settings/bettingRules/saga';

export default function* marketsSaga() {
	yield all([
		fork(treeSaga),
		fork(categorySaga),
		fork(leagueRestrictionSaga),
		fork(regulationTypesSaga),
		fork(mappingSaga),
		fork(historySaga),
		fork(marketSettingsSaga),
		fork(bettingRules),
	]);
}
