import { all, call, takeEvery, put, fork, select } from 'redux-saga/effects';
import { usersAPI } from '../../../../helpers/api/users';
import { showError, showSuccess } from '../../../../helpers/notifications';
import actions from './actions';
import { adaptSMSLsit, normalizeDate } from './utils';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { logger } from '../../../../helpers/logger';

const prefix = 'users.userinfo.sendSms';

const messages = {
	messagesListReload : `${prefix}.messagesListReload`,
	messagesSaveSuccess: `${prefix}.messagesSaveSuccess`,
	messagesSendError  : `${prefix}.messagesSendError`,
};

const getBaseData = ({ Users, Auth }) => {

	return {
		baseData  : Users.UserInfo.userSMS.get('baseData'),
		listLength: Users.UserInfo.userSMS.get('list').length,
		adminID   : Auth.get('user').id,
		filter    : Users.UserInfo.userSMS.get('filter'),
	};
};

function* messageListReload() {
	yield takeEvery(actions.USER_SEND_SMS_LIST_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		// yield put(actions.filterRefresh({ hasMore: true }));
		const { filter, listLength } = yield select(getBaseData);

		const { data } = action;
		const { userID, statusIDs = filter.statusIDs, reset } = data;

		const params = {
			user_id   : userID,
			page      : filter.page,
			limit     : filter.itemsPerPage,
			status_ids: statusIDs,
		};
		let entites = [];

		try {
			const res = yield call(usersAPI.getUserSMSList, params);

			if (res && res.status === 200) {
				const totalCount = getHeadersTotalCount(res.headers);
				entites = adaptSMSLsit(res.data.data);

				yield put(actions.filterRefresh({ page: filter.page + 1, totalCount, hasMore: totalCount > (listLength + res.data.data.length)  }));
			}
		} catch (e) {
			showError(messages.messagesListReload, e);
			logger.log(e);
		}

		if (reset) {
			yield put(actions.messageListRefresh(entites));
		} else {
			yield put(actions.messageListUpdate(entites));
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* saveUserMessage() {
	yield takeEvery(actions.SAVE_MESSAGE, function* ({ data }) {
		const { modalVisible } = data;
		yield put(actions.uiRefresh({ loading: true }));
		const { adminID, baseData } = yield select(getBaseData);
		const { id: userID, message, date } = baseData;

		const combinedDateTime = normalizeDate(date);

		const prepared = { user_id: userID, sender_id: adminID, message, date: combinedDateTime };

		try {
			yield call(usersAPI.sendSMS, prepared);

			yield put(actions.uiRefresh({ modalVisible }));
			if (!modalVisible) {
				yield put(actions.baseDataReset());
				yield put(actions.uiRefresh({
					addTemplate     : false,
					modalTemplate   : false,
					modalWidth      : '40%',
					templateEditMode: false,
				}));
			}
			showSuccess(messages.messagesSaveSuccess);
		} catch (e) {
			showError(messages.messagesListReload, e);
			logger.log(e);
		}

		yield put(actions.messageListReload({ userID }));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

export default function* userSMSSaga() {
	yield all([
		fork(messageListReload),
		fork(saveUserMessage),
	]);
}
