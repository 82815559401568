import { isAvailable } from '../../../containers/StaffManagement/modals/Role/utils';

export const CRUD_OPTIONS = ['create', 'delete', 'read', 'update'];
export const CRUD_OPTIONS_ENUM = {
	CREATE: 'create',
	READ  : 'read',
	UPDATE: 'update',
	DELETE: 'delete',
};

export const canRead = (permissions = {}) => {
	return CRUD_OPTIONS.some(item => permissions[item]);
};

const recursive = (
	globalObject, paths = [], fullPermissionKey, rolePermissions
) => {
	if (!paths.length) {
		return globalObject;
	}
	const [firstKey] = paths;

	if (!globalObject[firstKey]) {
		const isLastNode = CRUD_OPTIONS.some(item => item === firstKey);
		const hasRole = rolePermissions.some(
			item => item === fullPermissionKey
		);
		if (isLastNode && !globalObject[firstKey]) {
			globalObject[firstKey]         = { isExist: true };

			globalObject[firstKey].checked = hasRole;
			return globalObject;
		}
		globalObject[firstKey] = recursive(
			(globalObject[firstKey] = {}), paths.slice(1), fullPermissionKey,
			rolePermissions
		);

		return globalObject;
	}

	globalObject[firstKey] = recursive(
		globalObject[firstKey], paths.slice(1), fullPermissionKey, rolePermissions
	);

	return globalObject;
};

const checkIsIndeterminate = (item, crudType ) => {
	if (!item.children) {
		return item[crudType];
	}
	return item.children.some(child => checkIsIndeterminate(child, crudType));
};

const detectChecked = (item, crudType) => {
	if (!item.children) {
		return item[crudType];
	}


	return item.children.filter(item => isAvailable(item, crudType)).every(child => detectChecked(child, crudType));

};

const recursiveArrayFromObject = (globalObject, resultArr = [], path) => {
	const entries = Object.entries(globalObject);

	entries.forEach(([key, value]) => {
		const item      = { [key]: value };
		const isLastKey = Object.keys(value)
			.some(item => CRUD_OPTIONS.includes(item));
		const fullPermissionKey = `${path}_${key}`;

		if (typeof value === 'object' && !isLastKey) {
			item.children = recursiveArrayFromObject(value, [], `${path}_${key}`);
			item.name     = key;
			item.checked  = false;
			item.path     = path;
			item.key      = fullPermissionKey;

			item.createIndeterminate = checkIsIndeterminate(item, CRUD_OPTIONS_ENUM.CREATE);
			item.create = detectChecked(item, CRUD_OPTIONS_ENUM.CREATE);

			item.readIndeterminate = checkIsIndeterminate(item, CRUD_OPTIONS_ENUM.READ);
			item.read = detectChecked(item, CRUD_OPTIONS_ENUM.READ);

			item.updateIndeterminate = checkIsIndeterminate(item, CRUD_OPTIONS_ENUM.UPDATE);
			item.update = detectChecked(item, CRUD_OPTIONS_ENUM.UPDATE);

			item.deleteIndeterminate = checkIsIndeterminate(item, CRUD_OPTIONS_ENUM.DELETE);
			item.delete = detectChecked(item, CRUD_OPTIONS_ENUM.DELETE);


			delete item[key];
			resultArr.push(item);
		} else {
			item.name = key;
			item.path = path;
			item.key  = fullPermissionKey;

			const lastObjectKeys = Object.keys(item[item.name]);
			lastObjectKeys.forEach(crud => {
				item[crud] = item[item.name][crud].checked;

			});

			resultArr.push(item);
		}
	});
	return resultArr;
};

export const generateObjectFromPermissions = (permissions, rolePermissions) => {
	const regex = /^admin.*_(create|read|update|delete)$/;
	const temp = permissions.filter(item => regex.test(item))
		.map(item => item.split('admin_')[1]);
	let res    = {};
	temp.forEach(permission => {
		const paths             = permission.split('_');
		const fullPermissionKey = `admin_${permission}`;

		res = recursive(res, paths, fullPermissionKey, rolePermissions);
	});
	return recursiveArrayFromObject(res, [], 'admin');
};
