import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({

	entities: {},
	filter  : createFilter(filtersURL.emailsList, initFilterTypes),

	UI: {
		loading: false,
	},
});

export default function emailsListReducer(state = initState, action) {

	switch (action.type) {

		case actions.EMAILS_LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.EMAILS_LIST_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.EMAILS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target, true);
			return state.set('UI', result);
		}

		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
