export const SENDING_TYPES = {
	user : 'user',
	group: 'group',
};

export const NOTIFICATION_SEND_TYPES = {
	generalNotifications: 1,
	pushNotification    : 2,
	messages            : 3,
	sendMessage         : 5,
};
