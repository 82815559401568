import { combineReducers } from 'redux';
import HelpCenter from './helpCenter/reducer';
import InfoPages from './infoPages/reducer';
import News from './news/reducer';
import Promotions from './promotions/reducer';

export default combineReducers({
	HelpCenter,
	InfoPages,
	News,
	Promotions,
});
