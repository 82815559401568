
import toInteger from 'lodash/toInteger';
import { isID } from '../../../../helpers/utils';
import { createListAdapter } from '../groups/utils';

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptBannerGroup(rawData = {}) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareBannerGroup(baseData) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}

	return preparedData;
}
