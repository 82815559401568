import { apiRequest } from './index';

// Payments ---------------------------------------------------------------------------------------
function currencyPaymensList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/payments/list',
		params,
	};

	return apiRequest(req);
}

function paymentsList(websiteID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/websites/${websiteID}/payments/list`,
		//withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function paymentsMainList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/payments',
		// withoutWebsiteID: true,
		params,
	};

	return apiRequest(req);
}

function paymentInfo(paymentID, params = {}) {

	const req = {
		method          : 'GET',
		url             : `/payments/${paymentID}`,
		withoutWebsiteID: true,
		params,
	};

	return apiRequest(req);
}

function websitePaymentUpdate(paymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/website_payments/${paymentID}`,
		data,
		params,
	};

	return apiRequest(req );
}

function paymentUpdate(paymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/payments/${paymentID}`,
		data,
		params,
	};

	return apiRequest(req );
}

function paymentNamesList(paymentID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/payments/${paymentID}/names`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function paymentNamesUpdate(paymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/payments/${paymentID}/names`,
		data,
		params,
	};

	return apiRequest(req);
}

// Payways ----------------------------------------------------------------------------------------

function paywaysListRefresh(paymentID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/payments/${paymentID}/payways/refresh`,
		params,
	};

	return apiRequest(req);
}

function paywaysList(paymentID, params = {}) {

	const req = {
		method: 'GET',
		url   : '/payways',
		params: {
			...params,
			payment_id: paymentID,
		},
	};

	return apiRequest(req);
}

function paywayNamesList(paymentID, paywayID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/payments/${paymentID}/payways/${paywayID}`,
		params,
	};

	return apiRequest(req);
}

function paywayNamesUpdate(paymentID, paywayID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/payments/${paymentID}/payways/${paywayID}`,
		data,
		params,
	};

	return apiRequest(req);
}

export const paymentsAPI = {
	paymentsList,
	paymentsMainList,
	paymentInfo,
	paymentUpdate,
	websitePaymentUpdate, // inner main tab
	paymentNamesList,
	paymentNamesUpdate,
	currencyPaymensList,
	paywaysListRefresh,
	paywaysList,
	paywayNamesList,
	paywayNamesUpdate,
};
