const actions = {

	USER_HISTORY_MODEL_DATA_RELOAD : 'USER_HISTORY_MODEL_DATA_RELOAD',
	USER_HISTORY_MODEL_DATA_REFRESH: 'USER_HISTORY_MODEL_DATA_REFRESH',
	USER_HISTORY_UI_REFRESH        : 'USER_HISTORY_UI_REFRESH',

	dataReload: (userID, logModel) => ({
		type: actions.USER_HISTORY_MODEL_DATA_RELOAD,
		data: {
			userID,
			logModel,
		},
	}),
	dataRefresh: (logData, logModel) => ({
		type: actions.USER_HISTORY_MODEL_DATA_REFRESH,
		data: {
			logData,
			logModel,
		},
	}),
	uiRefresh: data => ({
		type: actions.USER_HISTORY_UI_REFRESH,
		data,
	}),
};

export default actions;
