import { call, takeEvery, all, fork, put, select } from 'redux-saga/effects';
import { actions } from './actions';
import { partnerAPI } from '../../../../../helpers/api/partner';
import { showError } from '../../../../../helpers/notifications';
import { TABLE_TYPES } from '../../../../../constants/tableTypes';
import { deriveTablePagination } from '../../../../../selectors/tables';
import { websitesAPI } from '../../../../../helpers/api/websites';
import { adaptLists } from '../utils';

const prefix = 'partner.partners';
const tableType = TABLE_TYPES.partners;

const messages = {
	getPartnersCountriesErr: `${prefix}.getPartnersCountriesErr`,
};

function getStoreData(state) {
	const { Partner, Auth } = state;

	const userPermissions = Auth.get('user').userRoles;

	const { editMode } = Partner.Partner.List.get('UI');

	return {

		filter    : Partner.Partner.Filter.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : {},
		partnerID : Partner.Partner.Modal.Partner.get('baseData').id,
		userPermissions,

		editMode,
	};
}
function* partnerCountriesReload() {
	yield takeEvery(actions.DATA_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { partnerID } = yield select(getStoreData);

		try {
			const [resAllCountries, linkedCountries] = yield all([call(websitesAPI.allCountries), call(partnerAPI.getPartnerSettingsCountries, partnerID)]);

			if (resAllCountries && resAllCountries.status === 200) {
				const countriesList = adaptLists(linkedCountries.data.data.country_ids, resAllCountries.data.data, 'countryID');
				yield put(actions.baseDataRefresh({ countriesList }));
			}
		} catch (e) {
			showError(messages.getPartnersCountriesErr);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* partnerCountriesSaga() {
	yield all([fork(partnerCountriesReload)]);
}
