import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';

import actions from './actions';

const initState = new Map({
	logData: [],

	UI: {
		loading      : false,
		marketName   : '',
		selectionName: '',
	},
});

export default function resultHistoryReducer(state = initState, action) {
	switch (action.type) {
		case actions.RESULT_HISTORY_MODEL_DATA_REFRESH: {
			const { logData } = action.data;
			return state.set('logData', logData);
		}

		case actions.RESULT_HISTORY_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.RESULT_HISTORY_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
