const prefix = 'PERMISSIONS_';

const actions = {
	PERMISSIONS_DATA_RELOAD         : `${prefix}DATA_RELOAD`,
	PERMISSIONS_DATA_REFRESH        : `${prefix}DATA_REFRESH`,
	PERMISSIONS_KEY_REFRESH         : `${prefix}KEY_REFRESH`,
	SELECTED_PERMISSIONS_KEY_REFRESH: `${prefix}SELECTED_PERMISSIONS_KEY_REFRESH`,
	PERMISSIONS_UI_REFRESH          : `${prefix}UI_REFRESH`,
	DATA_RESET                      : `${prefix}DATA_RESET`,

	dataReload: data => {
		return {
			type: actions.PERMISSIONS_DATA_RELOAD,
			data,
		};
	},


	// data refresh
	dataRefresh: data => {
		return {
			type: actions.PERMISSIONS_DATA_REFRESH,
			data,
		};
	},
	permissionsKeysRefresh: data => {
		return {
			type: actions.PERMISSIONS_KEY_REFRESH,
			data,
		};
	},
	// selected permissions
	selectedPermissionsRefresh: data => {
		return {
			type: actions.SELECTED_PERMISSIONS_KEY_REFRESH,
			data,
		};
	},


	uiRefresh: data => {
		return {
			type: actions.PERMISSIONS_UI_REFRESH,
			data,
		};
	},

	modalDataReset: () => {
		return {
			type: actions.DATA_RESET,
		};
	},
};

export default actions;
