import dayjs from 'dayjs';
import { isValidTimeFormat } from '../utils';

class QueryToFilterValidaion {

	isValidBool(value: string) {
		if (value === 'true' || value === 'false') {
			return JSON.parse(decodeURIComponent(value));
		}
	}

	isValidID(value: string) {
		const number = Number(decodeURIComponent(value));
		if (!Number.isNaN(number) && number >= 0) {
			return String(number);
		}
	}

	isValidString(value: string) {
		if (value) return decodeURIComponent(value);
	}

	isValidArray(value: string) {
		const array = decodeURIComponent(value).split(',');
		if (Array.isArray(array)) return array;
	}

	isValidTimeRange(value: string) {
		const array = decodeURIComponent(value).split(',');
		if (Array.isArray(array)) {
			const validItems = array.filter(time => isValidTimeFormat(time));
			if (array.length === validItems.length) {
				return array;
			}
		}
	}

	isValidDateRange(range: string) {
		const dateRange = decodeURIComponent(range).split(',');
		if (Array.isArray(dateRange)) {
			const correctDate = []; 
			dateRange.forEach(date => {
				const normalDate = date.replace(/\+/g, ' ');
				const formatDate = dayjs(normalDate).isValid();
				if (formatDate) {
					correctDate.push(formatDate);
				}
			});
			if (dateRange.length === correctDate.length) {
				return dateRange.map(date => date.replace(/\+/g, ' '));
			}
		}
	}
}

export default new QueryToFilterValidaion();