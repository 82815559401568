const integratorActions = {

	INTEGRATOR_RELOAD_LIST : 'INTEGRATOR_RELOAD_LIST',
	INTEGRATOR_REFRESH_LIST: 'INTEGRATOR_REFRESH_LIST',

	PARTNER_INTEGRATOR_RELOAD_LIST : 'PARTNER_INTEGRATOR_RELOAD_LIST',
	PARTNER_INTEGRATOR_REFRESH_LIST: 'PARTNER_INTEGRATOR_REFRESH_LIST',

	INTEGRATORS_LIST_UI_REFRESH: 'INTEGRATORS_LIST_UI_REFRESH',

	integratorListReload: () => ({
		type: integratorActions.INTEGRATOR_RELOAD_LIST,
	}),

	integratorListRefresh: integratorList => ({
		type: integratorActions.INTEGRATOR_REFRESH_LIST,
		data: integratorList,
	}),

	partnerIntegratorListReload: () => ({
		type: integratorActions.PARTNER_INTEGRATOR_RELOAD_LIST,
	}),

	partnerIntegratorListRefresh: integratorList => ({
		type: integratorActions.PARTNER_INTEGRATOR_REFRESH_LIST,
		data: integratorList,
	}),

	integratorUIRefresh: data => ({
		type: integratorActions.INTEGRATORS_LIST_UI_REFRESH,
		data,
	}),

};

export default integratorActions;
