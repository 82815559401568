import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	withdrawalIDs   : [],
	entities        : {},
	withdrawalTotals: {},

	filter: {
		userID: 0,
	},

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	UI: {
		loading: false,
	},
});

export default function rtmUserWithdrawalReducer(state = initState, action) {

	switch (action.type) {

		case actions.RTM_USER_WITHDRAWALS_DATA_REFRESH: {
			const { withdrawalIDs, entities, withdrawalTotals } = action.data;
			return state
				.set('withdrawalIDs', withdrawalIDs)
				.set('entities', entities)
				.set('withdrawalTotals', withdrawalTotals);
		}

		case actions.RTM_USER_WITHDRAWALS_ITEM_REFRESH: {
			const { id, withdrawalItem } = action.data;
			const target = state.get('entities');
			const updatedEntities = { ...target, [id]: withdrawalItem };
			return state.set('entities', updatedEntities);
		}

		case actions.RTM_USER_WITHDRAWALS_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.RTM_USER_WITHDRAWALS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.RTM_USER_WITHDRAWALS_PAGINATION_REFRESH: {
			const target = state.get('pagination');
			const result = fill(action.data, target);
			return state.set('pagination', result);
		}

		case actions.RTM_USER_WITHDRAWALS_FILTER_RESET: {
			const { userID } = state.get('filter');
			return  state.set('filter', {  userID });
		}

		default:
			return state;
	}
}
