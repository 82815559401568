const prefix = 'WEBSITE_RISK_MANAGEMENT_';

const actions = {
	WEBSITE_RISK_MANAGEMENT_RELOAD              			: `${prefix}RELOAD`,
	WEBSITE_RISK_MANAGEMENT_LIST_REFRESH        			: `${prefix}LIST_REFRESH`,
	WEBSITE_RISK_MANAGEMENT_ADD                 			: `${prefix}ADD`,
	WEBSITE_RISK_MANAGEMENT_SAVE                			: `${prefix}SAVE`,
	WEBSITE_RISK_MANAGEMENT_DELETE              			: `${prefix}DELETE`,
	WEBSITE_RISK_MANAGEMENT_UI_REFRESH          			: `${prefix}UI_REFRESH`,
	WEBSITE_RISK_MANAGEMENT_RISKS_REFRESH      				: `${prefix}RISKS_REFRESH`,
	WEBSITE_RISK_MANAGEMENT_RISK_ADD          					: `${prefix}RISK_ADD`,
	WEBSITE_RISK_MANAGEMENT_RISK_TYPES_LIST_REFRESH: `${prefix}RISK_TYPES_LIST_REFRESH`,
	WEBSITE_RISK_MANAGEMENT_DATA_RESET        					: `${prefix}WEBSITE_RISK_MANAGEMENT_DATA_RESET`,
	// For Settings List
	listReload                                     : websiteID => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_RELOAD,
		data: {
			websiteID,
		},
	}),
	listResresh: entities => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_LIST_REFRESH,
		data: entities,
	}),
	listDelete: (id) => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_DELETE,
		data: {
			id,
		},
	}),
	listAdd: data => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_ADD,
		data,
	}),
	// For Settings Risks
	risksAdd: data => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_RISK_ADD,
		data,
	}),
	risksResresh: (entities) => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_RISKS_REFRESH,
		data: entities,
	}),
	riskSave: data => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_SAVE,
		data,
	}),
	uiRefresh: data => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_UI_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.WEBSITE_RISK_MANAGEMENT_DATA_RESET,
	}),
};

export default actions;
