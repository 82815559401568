import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from  '../../../components/uielements/Table/Table';
import actions from '../../../redux/dashboard/placedBets/actions';
import { createColumn } from '../../../helpers/utils';
import IntlMessages from '../../../components/utility/intlMessages';
import { PlacedBetsTable } from './PlacedBets.style';


const lang = {
	channelId: <IntlMessages id="dashboard.widget.placedBets.chanel_id" />,
	amount   : <IntlMessages id="dashboard.widget.placedBets.amount" />,
	ggr      : <IntlMessages id="dashboard.widget.placedBets.ggr" />,
	count    : <IntlMessages id="dashboard.widget.placedBets.count" />,
	players  : <IntlMessages id="dashboard.widget.placedBets.players" />,
};

export const langChannelId = {
	backend: <IntlMessages id="dashboard.widget.placedBets.chanel_id.backend" />,
	desktop: <IntlMessages id="dashboard.widget.placedBets.chanel_id.desktop" />,
	tablet : <IntlMessages id="dashboard.widget.placedBets.chanel_id.tablet" />,
	mobile : <IntlMessages id="dashboard.widget.placedBets.chanel_id.mobile" />,
	web    : <IntlMessages id="dashboard.widget.placedBets.chanel_id.web" />,
};

class WidgetPlacedBets extends Component {
	static propTypes ={
		placedBetsDataReload: PropTypes.func.isRequired,
		placedBetsData      : PropTypes.array.isRequired,
	};

	constructor(props) {
		super(props);
		this.columns = [
			createColumn('channel_id', lang.channelId, null, null, null, 'placedBetsColumn'),
			createColumn('amount', lang.amount, null, null, null, 'placedBetsColumn'),
			createColumn('ggr', lang.ggr, null, null, null, 'placedBetsColumn'),
			createColumn('count', lang.count, null, null, null, 'placedBetsColumn'),
			createColumn('players', lang.players, null, null, null, 'placedBetsColumn'),
		];

		this.sortableColumns = this.columns.map(item => item.key);
	}

	componentDidMount() {
		const { placedBetsDataReload } =  this.props;
		placedBetsDataReload();
	}

	render() {
		const { placedBetsData } = this.props;
		return (
			<div>
				<PlacedBetsTable>
					<Table
						rowKey="rowKey"
						columns={this.columns}
						dataSource={placedBetsData}
						pagination={false}
					/>
				</PlacedBetsTable>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { Dashboard } = state;
	const { PlacedBets } = Dashboard;
	return {
		period        : PlacedBets.get('period'),
		placedBetsData: PlacedBets.get('placedBetsData'),
	};
}

export default connect(mapStateToProps, {
	placedBetsDataRefresh: actions.placedBetsRefresh,
	placedBetsDataReload : actions.placedBetsDataReload,
})(WidgetPlacedBets);
