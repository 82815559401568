import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({

	listIDs : [],
	entities: {},
	UI      : { loading: false },
});

export default function newsListReducer(state = initState, action) {

	switch (action.type) {

		case actions.NEWS_LIST_REFRESH: {
			const { listIDs, entities } = action.data;
			return state.set('listIDs', listIDs).set('entities', entities);
		}

		case actions.NEWS_LIST_UI_REFRESH: {
			return state.set('UI', action.data);
		}

		default:
			return state;
	}
}
