import EntityAdapter from '../../../../helpers/entityAdapter';

const fields = {
	id         : 'id',
	name       : 'name',
	code       : 'code',
	isoCode    : 'code',
	isDefault  : 'is_default',
	description: 'description',
};
const languagesListAdapter  = createListAdapter();
const currencyListAdapter   = createCurrencyListAdapter();

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.string, 'code', fields.isoCode);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.bool, 'isDefault', fields.isDefault);
	return adapter;
}
function createCurrencyListAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.string, 'name', fields.description);
	adapter.addField(rules.bool, 'isDefault', fields.isDefault);
	return adapter;
}
// adaptPartnerCountries
export const adaptLists = (rawData = [], entity, ID = 'id') => {
	let adaptedData;
	if (ID === 'currencyID') {
		adaptedData = currencyListAdapter.adaptList(entity);
	} else {
		adaptedData = languagesListAdapter.adaptList(entity);
	}

	return adaptedData.map(item => {
		const { id } = item;
		const res    = {
			selected: false,
			[ID]    : id,
			...item,
		};
		if (rawData && rawData.includes(id)) {
			res.selected = true;
		}
		return res;
	});
};
