import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import compact from 'lodash/compact';
import actions from './actions';

import { upcomingAPI } from '../../../../../helpers/api/upcoming';
import notifications from '../../../../../helpers/notifications';

import { adaptUpcomingEventsList } from './utils';
import { logger } from '../../../../../helpers/logger';

const prefix = 'sportspriorities.upcoming';

const messages = {
	errorListLoad  : `${prefix}.loading.events.list.failed`,
	errorListSave  : `${prefix}.saving.events.list.failed`,
	successListSave: `${prefix}.events.list.saved.success`,
};

function getStoreData({ CMS, App }) {
	return {
		entities : CMS.LandingPage.Upcoming.List.get('entities'),
		websiteID: App.get('websiteID'),
	};
}

function* listReload() {
	yield takeEvery(actions.UPCOMING_EVENTS_LIST_RELOAD, function* () {
		let entities = {};
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(upcomingAPI.upcomingEventsList);
			if (res && res.status === 200) {
				entities = adaptUpcomingEventsList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.uiRefresh({ isChanged: false, loading: false }));
	});
}

function* listSave() {
	yield takeEvery(actions.UPCOMING_EVENTS_LIST_SAVE, function* () {
		const { entities, websiteID } = yield select(getStoreData);
		const params = { website_id: websiteID };
		const eventsList = compact(Object.keys(entities)).map(ID => {
			return {
				event_id: ID,
			};
		});
		try {
			const res = yield call(upcomingAPI.upcomingEventsListUpdate, eventsList, params);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successListSave);
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorListSave, error);
			logger.log(error);
		}
	});
}

export default function* upcomingEventsListSaga() {
	yield all([fork(listReload), fork(listSave)]);
}
