import { apiRequest } from './index';

function affiliatesList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/affiliate',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function affiliatesResend(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/affiliate/resend',
		data,
		params,
	};

	return apiRequest(req);
}

export const affiliatesAPI = {
	affiliatesList,
	affiliatesResend,
};
