import { getTimeZones } from '@vvo/tzdb';
import { uniqBy } from 'lodash';

export const getTimeZonesCustom = () => {
	const timezones = getTimeZones();

	const result = timezones.reduce((accum, item) => {
		const zoneNumber = item.currentTimeFormat.split(' ')[0];
		const singleZone = {
			code: zoneNumber,
			name: `GMT(${zoneNumber}) ${item.group[0]}`,
			id  : zoneNumber,
		};
		accum.push(singleZone);
		return accum;
	}, []);
	return uniqBy(result, 'id');
};


export const getClientCurrentTimeZoneCode = () => {
	const date = new Date();
	const dateAsString = date.toString();

	const timezone = dateAsString.match(/([+-][0-9]+.)/)[1];
	const splited = timezone.split('');
	const [sign, first, second, third, fourth] = splited;

	return `${sign + first + second}:${third}${fourth}`;
};
