import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import { isID } from '../../../../helpers/utils';
import { fields, createListAdapter, createDescAdapter } from '../list/utils';

const listAdapter = createListAdapter();
const descAdapter = createDescAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptBaseData(rawData = {}) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

export function adaptDescData(rawData = []) {

	descAdapter.clearExcludes();

	if (!isArray(rawData) || isEmpty(rawData)) {
		return descAdapter.adapt({});
	}

	const adaptedData = descAdapter.adapt(rawData[0]);

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareBaseData(baseData) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('viewCount');
	listAdapter.addExcludeField('startDate');
	listAdapter.addExcludeField('endDate');

	const preparedData = listAdapter.prepare(baseData);

	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}

	if (baseData.startDate) {
		preparedData[fields.startDate] = new Date(baseData.startDate).toISOString();
	}
	if (baseData.endDate) {
		preparedData[fields.endDate] = new Date(baseData.endDate).toISOString();
	}

	return preparedData;
}

export function prepareDescData(descData, websiteID, langID, channelID) {

	descAdapter.clearExcludes();

	const preparedData = descAdapter.prepare(descData);

	preparedData[fields.langID]    = langID;
	preparedData[fields.channelID] = channelID;
	preparedData[fields.websiteID] = websiteID;

	return [preparedData];
}
