import cloneDeep from 'lodash/cloneDeep';

export const adaptCategories = (categoryList = [], categoryLimits = [], data) => {
	const { userID, typeID } = data;
	const associativeList    = categoryList.reduce((acc, item) => {
		const { id, name } = item;
		acc[id]            = {
			loading      : false,
			isBaseChanged: false,
			block        : false,
			limitID      : null,
			maxStake     : null,
			minStake     : null,
			categoryID   : id,
			limitTypeID  : typeID,
			name,
			userID,
		};
		return acc;
	}, {});

	categoryLimits.forEach(item => {
		const {
			service_id: categoryID,
			max_stake : maxStake,
			min_stake : minStake,
			type      : limitTypeID,
			id        : limitID,
			block,
		} = item;

		if (!Object.hasOwn(associativeList, categoryID)) {
			return;
		}

		const singleLimit           = {
			categoryID,
			maxStake,
			minStake,
			block,
			limitTypeID,
			limitID,
		};
		associativeList[categoryID] = Object.assign(associativeList[categoryID], singleLimit);
	});

	return {
		list: Object.values(associativeList).map(item => item),
		associativeList,
	};

};

export const updateCategoryItem = (categoryList = [], categoryID, loading = false, isBaseChanged = false) => {
	const clonedList = cloneDeep(categoryList);

	const index = clonedList.findIndex(item => item.categoryID === categoryID);

	if (index > -1) {
		const item = clonedList[index];
		item.loading = loading;
		item.isBaseChanged = isBaseChanged;
		return clonedList;
	}

	return clonedList;
};
