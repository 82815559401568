export const HTTPStatusCodes = {
	Continue                  : 100,
	SwitchingProtocols        : 101,
	Processing                : 102,
	EarlyHints                : 103,
	OK                        : 200,
	Created                   : 201,
	Accepted                  : 202,
	onAuthoritativeInformation: 203,
	NoContent                 : 204,
	ResetContent              : 205,
	PartialContent            : 206,
	MultiStatus               : 207,
	AlreadyReported           : 208,
	IMUsed                    : 226,
	MultipleChoices           : 300,
	MovedPermanently          : 301,
	Found                     : 302,
	BadRequest                : 400,
	Unauthorized              : 401,
	PaymentRequired           : 402,
	Forbidden                 : 403,
	NotFound                  : 404,
	MethodNotAllowed          : 405,
	InternalServerError       : 500,
};
