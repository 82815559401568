import { all, takeEvery, put, call, fork, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import whitelistActions from './actions';
import { securityAPI } from '../../../../helpers/api/security';
import notifications from '../../../../helpers/notifications';
import { convertListToMap } from './utils';

const prefix = 'security.whitelist';

const messages = {
	errorListLoad               : `${prefix}.errorListLoad`,
	errorWhitelistDomainSave    : `${prefix}.errorWhitelistDomainSave`,
	errorWhitelistDomainDelete  : `${prefix}.errorWhitelistDomainDelete`,
	successWhitelistDomainSave  : `${prefix}.successWhitelistDomainSave`,
	successWhitelistDomainDelete: `${prefix}.successWhitelistDomainDelete`,
};
function getStoreData({ Settings, App }) {
	return {
		entities : Settings.Security.Whitelist.get('entities'),
		UI       : Settings.Security.Whitelist.get('UI'),
		websiteID: App.get('websiteID'),
	};
}

function* listReload() {
	yield takeEvery(whitelistActions.SECURITY_WHITELIST_RELOAD, function* () {
		yield put(whitelistActions.uiRefresh({ loading: true }));

		try {
			const res = yield call(securityAPI.domainsList);
			if (res && res.status === 200) {
				const data = convertListToMap(res.data.data);
				yield put(whitelistActions.listRefresh(data));
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
		}
		yield put(whitelistActions.uiRefresh({ loading: false }));

	});
}

function* saveDomain() {
	yield takeEvery(whitelistActions.SECURITY_WHITELIST_SAVE_ITEM, function* (action) {
		yield put(whitelistActions.uiRefresh({ loadEntity: true }));
		const { UI, websiteID, entities } = yield select(getStoreData);

		let { domainID } = action.data;
		const { editMode, closeModal, domainData: { domain } } = UI;

		const params = {
			website_id: websiteID,
		};

		let isError = false;
		try {
			if (editMode) {
				const res = yield call(securityAPI.domainUpdate, domainID, domain, params);
				if (res && res.status === 200) {
					const { data } = res.data;
					entities[data.id] = data;
					yield put(whitelistActions.uiRefresh({ 
						isChanged : true,
						domainData: data,
					}));
				}
			} else {
				const res = yield call(securityAPI.domainAdd, domain, { website_id: Number(websiteID) });
				if (res && res.status === 200) {
					domainID = toInteger(res.data.data.id);
					notifications.showSuccess(messages.successWhitelistDomainSave);
					yield put(whitelistActions.uiRefresh({
						domainID,
						editMode : true,
						isChanged: false,
					}));
					yield put(whitelistActions.listReload());
				}
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorWhitelistDomainSave);
		}

		yield put(whitelistActions.uiRefresh({
			loadEntity: false,
			visible   : !(closeModal && !isError),
		}));
	}
	);
}

function* deleteDomain() {
	yield takeEvery(
		whitelistActions.SECURITY_WHITELIST_DELETE_ITEM,
		function* (action) {
			yield put(whitelistActions.uiRefresh({ loading: true }));

			const { domainID } = action.data;

			try {
				const res = yield call(securityAPI.domainDelete, domainID);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successWhitelistDomainDelete);
				}
			} catch (error) {
				notifications.showError(messages.errorWhitelistDomainDelete);
			}

			yield put(whitelistActions.listReload());
		}
	);
}

export default function* whitelistSaga() {
	yield all([
		fork(listReload),
		fork(saveDomain),
		fork(deleteDomain),
	]);
}
