import { takeEvery, put, select, call, fork, all, delay } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { sportRiskManagementAPI } from '../../../../helpers/api/sportRiskManagement';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import socketActions from '../../../socket/actions';
import tableActions from '../../../tables/actions';
import notifications from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';
import { deriveTablePagination } from '../../../../selectors/tables';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { RootState } from '../../../store';
import { IApiResponse } from '../../../types';
import { actions as riskSportBetsModalSlice } from '../modal/slice';
import { actions as riskSportBetsListSlice } from '../list/slice';
import { IActionListRefreshSocket, IActionMarketIDsRefreshSocket, IAdapter, IGetRiskDataParams, IRiskBetsItem } from './types';
import { actions } from './slice';
import actionsSportRiskList from './actions';
import { adaptBet, adaptBetsList, getListParams } from './utils';


const tableType          = TABLE_TYPES.sportRiskManagement;

const messages = {
	errorListReload: 'riskBets.list.reload.error',
};

function getStoreData(state:RootState) {
	const { SportRiskManagement, Tables, AppTabs, Settings } = state;
	const { riskManagementBetsSlice: { riskSportBetsList, riskSportBetsFilter } } = SportRiskManagement;
	return {
		betIDs       : riskSportBetsList.betIDs,
		entities     : riskSportBetsList.entities,
		sportRiskData: riskSportBetsList.sportRiskData,
		filter       : riskSportBetsFilter.filterData,
		sorting      : Tables.get(tableType).sorting,
		//eslint-disable-next-line
		// @ts-ignore
		pagination   : deriveTablePagination( tableType, state ),
		currencyList : Settings.CurrencyModule.get('entities'),
		activeTabID  : AppTabs.get('activeTabID'),

	};
}


function* listReload() {

	yield takeEvery(actionsSportRiskList.SPORT_RISK_BETS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { filter, sorting, pagination, activeTabID } = yield select(getStoreData);
		const filterCopy =  cloneDeep(filter);
		const params: IGetRiskDataParams = getListParams(filterCopy, sorting, pagination);
		let betIDs		= [];
		let marketIDs	= [];
		let entities	= {};
		let totalCount	= 0;

		try {
			const response: IApiResponse<IRiskBetsItem> = yield call(sportRiskManagementAPI.getSportRisk, params);
			if (response && response.status === 200) {
				const result: IAdapter = adaptBetsList(response.data.data);
				betIDs		= result.betIDs;
				entities	= result.entities;
				marketIDs	= result.marketIDs;
				totalCount	= getHeadersTotalCount(response.headers) || betIDs.length;

				yield put(actions.setRiskData(result.riskList));
				yield put(actions.setRiskEntities(entities));
				yield put(actions.setRiskBetIDs(betIDs));
				yield put(actions.setRiskMarketIDs(marketIDs));
				yield put(socketActions.riskManagementBetsSubscribe(activeTabID, betIDs));
				yield put(socketActions.riskManagementMarketIDsSubscribe(`${activeTabID}-marketIDs`, marketIDs));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			notifications.showError(messages.errorListReload);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* riskMarketIDSUpdateSocket() {

	yield takeEvery(actionsSportRiskList.SPORT_RISK_BETS_SOCKET_MARKET_IDS_UPDATE_LIST, function* ({ data }: IActionMarketIDsRefreshSocket) {

		yield put(actions.uiRefresh({ loading: true }));
		const { sportRiskData } = yield select(getStoreData);
		const clonedRikData = cloneDeep(sportRiskData);

		try {
			if (sportRiskData.length) {
				const listWithUpdatedMarketIDs = clonedRikData.map((item => {
					if (item.details && item.details.length) {
						return {
							...item,
							details: item.details.map(detail => {
								if (detail.marketID === data.id) {
									return {
										...detail,
										eventMarkets: {
											marketID     : data.id,
											reason       : data.reason,
											suspend      : data.suspend,
											suspendID    : data.suspend,
											currentMargin: data.margin,
										},
									};
								}
								return detail;
							}),
						};
					}
					return item;
				}));
				yield put(actions.setRiskData(listWithUpdatedMarketIDs));
			}
			yield put(riskSportBetsModalSlice.riskModalDataDetailsRefresh({ 
				popoverVisible: false,
				suspendLoading: false,
				successSaved  : true,
				marketID      : data.id,
				suspendID     : data.suspend,
				suspend       : data.suspend,
				reason        : data.reason,
				margin        : data.margin,
				
				marginPopoverVisible: false,
				marginLoading       : false,
				successMarginSaved  : true,
			}));
			
		} catch (error) {
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* riskBetsUpdateSocket() {

	yield takeEvery(actionsSportRiskList.SPORT_RISK_BETS_SOCKET_UPDATE_LIST, function* ({ data }: IActionListRefreshSocket) {
		yield put(actions.uiRefresh({ loading: true }));
		const { sportRiskData, entities } = yield select(getStoreData);
		const listDataCloned = cloneDeep(sportRiskData);
		const entitiesCloned = cloneDeep(entities);
		try {
			const result: IRiskBetsItem = adaptBet(data, []);

			if (listDataCloned.length) {
				listDataCloned.forEach((item: IRiskBetsItem) => {
					if (item.betID === result.betID) {
						item.statusID = result.statusID;
					}
				});
				yield put(actions.setRiskData(listDataCloned));
				yield put(actions.setRiskEntities({ ...entitiesCloned, [result.betID]: result }));
				yield put(riskSportBetsModalSlice.riskModalDataRefresh({ statusID: result.statusID }));
				yield put(riskSportBetsListSlice.uiRefresh({ updateRowID: result.betID }));
			}
		} catch (error) {
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
		yield delay(2000);
		yield put(riskSportBetsListSlice.uiRefresh({ updateRowID: -1 }));
	});
}

function* listRefreshSocket() {

	yield takeEvery(actionsSportRiskList.SPORT_RISK_BETS_SOCKET_REFRESH_LIST, function* ({ data }: IActionListRefreshSocket) {
		yield put(actions.uiRefresh({ loading: true }));
		const { sportRiskData, entities, betIDs, activeTabID } = yield select(getStoreData);
		const listDataCloned = cloneDeep(sportRiskData);
		const entitiesCloned = cloneDeep(entities);
		const clonedIDs = cloneDeep(betIDs);
		try {
			const result: IRiskBetsItem = adaptBet(data, []);
			if (listDataCloned.length) {
				listDataCloned.unshift(result);
				entitiesCloned[result.betID] = result;
				clonedIDs.push(result.betID);
				yield put(actions.setRiskData(listDataCloned));
				yield put(actions.setRiskEntities(entitiesCloned));
				yield put(riskSportBetsListSlice.setRiskBetIDs(clonedIDs));
				yield put(socketActions.riskManagementBetsSubscribe(activeTabID, clonedIDs));
				yield put(riskSportBetsListSlice.uiRefresh({ updateRowID: result.betID }));
			}
		} catch (error) {
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}
export default function* sportRiskListSaga() {
	yield all([
		fork(listReload),
		fork(riskBetsUpdateSocket),
		fork(riskMarketIDSUpdateSocket),
		fork(listRefreshSocket),
	]);
}
