export const BONUS_TYPES = {
	all                 : 0,
	depositBonus        : 1,
	sportsBookBonus     : 2,
	welcome             : 3,
	deposit             : 4,
	freeSpin            : 5,
	birthday            : 6,
	bonusSpin           : 7,
	registrationFreeSpin: 8,
	balanceAdjustment   : 9,
	cashback            : 10,
	freeBet             : 11,
	depositFreeSpin     : 12,
	pack                : 13,
	nextDepositFreeSpin : 14,
	nextDepositBonus    : 15,
	packNext            : 16,
	manualBonus         : 17,
	massFreeSpin        : 18,
};

export const BONUS_TYPE_NAMES = {
	[BONUS_TYPES.depositBonus]        : 'bonus.depositBonus',
	[BONUS_TYPES.sportsBookBonus]     : 'bonus.sportsbookBonus',
	[BONUS_TYPES.welcome]             : 'bonus.welcome',
	[BONUS_TYPES.deposit]             : 'bonus.firstDeposit',
	[BONUS_TYPES.freeSpin]            : 'bonus.freeSpin',
	[BONUS_TYPES.birthday]            : 'bonus.birthday',
	[BONUS_TYPES.bonusSpin]           : 'bonus.bonusSpin',
	[BONUS_TYPES.registrationFreeSpin]: 'bonus.registrationFreeSpin',
	[BONUS_TYPES.balanceAdjustment]   : 'bonus.balanceAdjustment',
	[BONUS_TYPES.cashback]            : 'bonus.cashback',
	[BONUS_TYPES.freeBet]             : 'bonus.freeBet',
	[BONUS_TYPES.depositFreeSpin]     : 'bonus.depositFreeSpin',
	[BONUS_TYPES.pack]                : 'bonus.pack',
	[BONUS_TYPES.nextDepositFreeSpin] : 'bonus.nextDepositFreeSpin',
	[BONUS_TYPES.nextDepositBonus]    : 'bonus.nextDepositBonus',
	[BONUS_TYPES.packNext]            : 'bonus.packNext',
	[BONUS_TYPES.manualBonus]         : 'bonus.manual',
	[BONUS_TYPES.massFreeSpin]        : 'bonus.massFreeSpin',
};
export const CASINO_EXTRA_SPIN_TYPES = {
	freeSpin : 1,
	bonusSpin: 2,
};

export const BONUS_STATUS = {
	active  : 1,
	inactive: 2,
};

export const WAY_STATUS = {
	firstWay : 1,
	secondWay: 2,
};
export const BONUS_STATUSES = {
	ACTIVE   : 1,
	INACTIVE : 2,
	EXPIRED  : 3,
	FINISHED : 4,
	GRANTED  : 5,
	CLAIMED  : 6,
	CONVERTED: 7,
	DECLINED : 8,
	CANCELED : 9,
};

export const BONUS_STATUS_NAMES = {
	[BONUS_STATUSES.ACTIVE]   : 'bonus.status.active',
	[BONUS_STATUSES.INACTIVE] : 'bonus.status.inactive',
	[BONUS_STATUSES.GRANTED]  : 'bonus.status.granted',
	[BONUS_STATUSES.CLAIMED]  : 'bonus.status.claimed',
	[BONUS_STATUSES.CONVERTED]: 'bonus.status.converted',
	[BONUS_STATUSES.FINISHED] : 'bonus.status.finished',
	[BONUS_STATUSES.EXPIRED]  : 'bonus.status.expired',
	[BONUS_STATUSES.DECLINED] : 'bonus.status.declined',
	[BONUS_STATUSES.CANCELED] : 'bonus.status.canceled',
};

export const CASHBACK_STATUSES = {
	ACTIVE     : 1,
	DEACTIVATED: 2,
	FINISHED   : 5,
};

export const CASHBACK_STATUS_NAMES = {
	[CASHBACK_STATUSES.ACTIVE]     : 'cashback.status.active',
	[CASHBACK_STATUSES.DEACTIVATED]: 'cashback.status.deactivated',
	[CASHBACK_STATUSES.FINISHED]   : 'cashback.status.finished',
};

export const SETTLEMENT_STATUSES = {
	SETTLED  : 1,
	UNSETTLED: 2,
	REJECTED : 3,
};

export const SETTLEMENT_STATUS_NAMES = {
	[BONUS_STATUSES.SETTLED]  : 'settlement.status.settled',
	[BONUS_STATUSES.UNSETTLED]: 'settlement.status.unsettled',
	[BONUS_STATUSES.REJECTED] : 'settlement.status.rejected',
};
