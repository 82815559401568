import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import IntlMessages from '../../../components/utility/intlMessages';
import Button from '../../../components/uielements/button';
import FormInput from '../../../components/FormControls/FormInput';
import sportstatisticActions from '../../../redux/users/sportStatistic/actions';
import casinoStatisticActions from '../../../redux/users/casinoStatistic/actions';
import userActions from '../../../redux/users/user/actions';
import tabsActions from '../../../redux/appTabs/actions';
import notifications from '../../../helpers/notifications';

import { SearchForm, SubmitButton } from './UserSearch.style';

import DraggableModal from '../../../components/DraggableModal';
import { RocketOutlined } from '@ant-design/icons';

const lang = {
	userID   : <IntlMessages id = "userSearch.modal.userID" />,
	userName : <IntlMessages id = "userSearch.modal.userName" />,
	userEmail: <IntlMessages id = "userSearch.modal.userEmail" />,
};

const prefix = 'user.usersearch';

const messages = {
	warnAtLeastOne: `${prefix}.warnAtLeastOne`,
};

class UserSearch extends Component {

	static propTypes = {
		userQuickSearchDataRefrash: PropTypes.func.isRequired,
		userSetValueUI            : PropTypes.func.isRequired,
		userQuickSearch           : PropTypes.func.isRequired,
		userQuickSearchDataReset  : PropTypes.func.isRequired,
		userQuickSearchData       : PropTypes.object.isRequired,
		modalVisible              : PropTypes.bool.isRequired,
	};

	constructor(props) {
		super(props);

		this.onOpenModal   = this.onOpenModal.bind(this);
		this.onClickSearch = this.onClickSearch.bind(this);
		this.onClickCancel = this.onClickCancel.bind(this);
		this.onChangeField = this.onChangeField.bind(this);
	}

	shouldComponentUpdate(nextProps) {
		const { userQuickSearchData, modalVisible } = this.props;
		if (isEqual(userQuickSearchData, nextProps.userQuickSearchData) || (modalVisible !== nextProps.modalVisible)) {
			return true;
		}
		return false;
	}


	onOpenModal() {
		const { userSetValueUI } = this.props;
		userSetValueUI('modalVisible', true);
	}

	onClickSearch(e) {
		e.preventDefault();
		const { userQuickSearch, userQuickSearchData } = this.props;

		if (isEmpty(userQuickSearchData)) {
			return notifications.showWarning(messages.warnAtLeastOne);
		}
		return userQuickSearch();
	}


	onClickCancel() {
		const { userSetValueUI, userQuickSearchDataReset } = this.props;
		userSetValueUI('modalVisible', false);
		userQuickSearchDataReset();
	}

	onChangeField(event) {
		const { userQuickSearchDataRefrash, userQuickSearchData } = this.props;
		const { value, name } = event.target;
		const resData = cloneDeep(userQuickSearchData);
		resData[name] = value;
		userQuickSearchData[name] = value;
		userQuickSearchDataRefrash(resData);
	}

	render() {
		const { userQuickSearchData, modalVisible } = this.props;
		const { userID, userName, userEmail } = userQuickSearchData;

		return (
			<SearchForm
				className={modalVisible ? 'non-active' : ''}
			>
				<Button
					shape="circle"
					icon={<RocketOutlined />}
					type="primary"
					title="Quick User Search"
					size="large"
					onClick={this.onOpenModal}
				/>
				<DraggableModal
					visible={modalVisible}
					title="Search user"
					width="40%"
					okText="Search"
					onOk={this.onClickSearch}
					onCancel={this.onClickCancel}
				>
					<form onSubmit={this.onClickSearch}>
						<FormInput
							label={lang.userID}
							labelWidth={20}
							name="userID"
							value={userID}
							onChange={this.onChangeField}
						/>
						<FormInput
							label={lang.userName}
							labelWidth={20}
							name="userName"
							value={userName}
							onChange={this.onChangeField}
						/>
						<FormInput
							label={lang.userEmail}
							labelWidth={20}
							name="userEmail"
							value={userEmail}
							onChange={this.onChangeField}
						/>
						<SubmitButton type="submit" />
					</form>

				</DraggableModal>
			</SearchForm>
		);
	}
}

const mapStateToProps = ({ Users }) => {
	return {
		userQuickSearchData: Users.User.get('userQuickSearchData'),
		modalVisible       : Users.User.get('UI').modalVisible,
	};
};

const mapDispatchToProps = {
	openTab                   : tabsActions.openTab,
	reloadSportStatistic      : sportstatisticActions.dataReload,
	reloadCasinoStatistic     : casinoStatisticActions.dataReload,
	userQuickSearchDataRefrash: userActions.userQuickSearchDataRefrash,
	userQuickSearchDataReset  : userActions.userQuickSearchDataReset,
	userSetValueUI            : userActions.setValueUI,
	userQuickSearch           : userActions.userQuickSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
