import { Map } from 'immutable';
import { fill } from '../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list: [],

	UI: {
		changedID: null,
	},
});

export default function Integrator(state = initState, action) {

	switch (action.type) {

		case actions.INTEGRATOR_REFRESH_LIST: {
			return state.set('list', action.data);
		}

		case actions.PARTNER_INTEGRATOR_REFRESH_LIST: {
			return state.set('list', action.data);
		}

		case actions.INTEGRATORS_LIST_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;

	}

}
