const actions = {

	// Main data
	EVENT_BASE_DATA_RELOAD           : 'EVENT_BASE_DATA_RELOAD',
	EVENT_BASE_DATA_REFRESH          : 'EVENT_BASE_DATA_REFRESH',
	EVENT_UPDATE                     : 'EVENT_UPDATE',
	// Advanced data
	EVENT_COMMON_DATA_RELOAD         : 'EVENT_COMMON_DATA_RELOAD',
	EVENT_COMMON_DATA_REFRESH        : 'EVENT_COMMON_DATA_REFRESH',
	EVENT_ODDS_DATA_RELOAD           : 'EVENT_ODDS_DATA_RELOAD',
	EVENT_ODDS_DATA_REFRESH          : 'EVENT_ODDS_DATA_REFRESH',
	EVENT_SETTINGS_DATA_RELOAD       : 'EVENT_SETTINGS_DATA_RELOAD',
	EVENT_SETTINGS_DATA_REFRESH      : 'EVENT_SETTINGS_DATA_REFRESH',
	EVENT_NAMES_DATA_RELOAD          : 'EVENT_NAMES_DATA_RELOAD',
	EVENT_NAMES_DATA_REFRESH         : 'EVENT_NAMES_DATA_REFRESH',
	EVENT_SEO_DATA_REFRESH           : 'EVENT_SEO_DATA_REFRESH',
	EVENT_SEO_DATA_RELOAD            : 'EVENT_SEO_DATA_RELOAD',
	EVENT_SEO_DATA_SAVE              : 'EVENT_SEO_DATA_SAVE',
	EVENT_ORDERS_DATA_RELOAD         : 'EVENT_ORDERS_DATA_RELOAD',
	EVENT_ORDERS_DATA_REFRESH        : 'EVENT_ORDERS_DATA_REFRESH',
	EVENT_DYNAMIC_LIMITS_DATA_RELOAD : 'EVENT_DYNAMIC_LIMITS_DATA_RELOAD',
	EVENT_DYNAMIC_LIMITS_DATA_REFRESH: 'EVENT_DYNAMIC_LIMITS_DATA_REFRESH',
	EVENT_UPDATE_MARKETS_STATISTICS  : 'EVENT_UPDATE_MARKETS_STATISTICS',
	// UI
	EVENT_SET_VALUE_UI               : 'EVENT_SET_VALUE_UI',

	//subscribe to  Event Markets statistics
	EVENT_SOCKET_SUBSCRIBE		: 'EVENT_SOCKET_SUBSCRIBE',
	EVENT_CHANGE_SELECTED			: 'EVENT_CHANGE_SELECTED',
	EVENT_SET_SELECTED						: 'EVENT_SET_SELECTED',
	EVENT_SOCKET_UNSUBSCRIBE: 'EVENT_SOCKET_UNSUBSCRIBE',

	EVENT_NOTIFY_STATISTIC_UPDATE       		:	'EVENT_NOTIFY_STATISTIC_UPDATE',
	EVENT_STATISTIC_DATA_REFRESH       			:	'EVENT_STATISTIC_DATA_REFRESH',
	EVENT_NOTIFY_MARKETS_STATISTICS_UPDATE: 'EVENT_NOTIFY_MARKETS_STATISTICS_UPDATE',
	EVENT_MARKETS_STATISTICS_DATA_REFRESH : 'EVENT_MARKETS_STATISTICS_DATA_REFRESH',
	// main data
	baseDataReload                        : eventID => ({
		type: actions.EVENT_BASE_DATA_RELOAD,
		data: {
			eventID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.EVENT_BASE_DATA_REFRESH,
		data,
	}),

	marketsStatisticsRefresh: data => ({
		type: actions.EVENT_MARKETS_STATISTICS_DATA_REFRESH,
		data,
	}),
	eventStatisticRefresh: data => ({
		type: actions.EVENT_STATISTIC_DATA_REFRESH,
		data,
	}),

	eventUpdate: eventID => ({
		type: actions.EVENT_UPDATE,
		data: {
			eventID,
		},
	}),

	// Advanced data
	commonDataReload: ({ sportID, countryID, leagueID, sportList }) => ({
		type: actions.EVENT_COMMON_DATA_RELOAD,
		data: {
			sportID,
			countryID,
			leagueID,
			sportList,
		},
	}),
	commonDataRefresh: data => ({
		type: actions.EVENT_COMMON_DATA_REFRESH,
		data,
	}),
	oddsDataReload: eventID => ({
		type: actions.EVENT_ODDS_DATA_RELOAD,
		data: {
			eventID,
		},
	}),
	oddsDataRefresh: data => ({
		type: actions.EVENT_ODDS_DATA_REFRESH,
		data,
	}),
	settingsDataReload: eventID => ({
		type: actions.EVENT_SETTINGS_DATA_RELOAD,
		data: {
			eventID,
		},
	}),
	settingsDataRefresh: data => ({
		type: actions.EVENT_SETTINGS_DATA_REFRESH,
		data,
	}),
	namesDataReload: (eventID, langID) => ({
		type: actions.EVENT_NAMES_DATA_RELOAD,
		data: {
			eventID,
			langID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.EVENT_NAMES_DATA_REFRESH,
		data,
	}),
	seoDataRefresh: data => ({
		type: actions.EVENT_SEO_DATA_REFRESH,
		data,
	}),
	seoDataReload: eventID => ({
		type: actions.EVENT_SEO_DATA_RELOAD,
		data: {
			eventID,
		},
	}),
	seoDataSave: eventID => ({
		type: actions.EVENT_SEO_DATA_SAVE,
		data: {
			eventID,
		},
	}),
	ordersDataReload: eventID => ({
		type: actions.EVENT_ORDERS_DATA_RELOAD,
		data: {
			eventID,
		},
	}),
	ordersDataRefresh: data => ({
		type: actions.EVENT_ORDERS_DATA_REFRESH,
		data,
	}),
	dynamicLimitsDataReload: eventID => ({
		type: actions.EVENT_DYNAMIC_LIMITS_DATA_RELOAD,
		data: {
			eventID,
		},
	}),
	dynamicLimitsDataRefresh: data => ({
		type: actions.EVENT_DYNAMIC_LIMITS_DATA_REFRESH,
		data,
	}),

	setValueUI: (valueName, value) => ({
		type: actions.EVENT_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),

	notifyEventMarketsStatisticsUpdate: data => ({
		type: actions.EVENT_NOTIFY_MARKETS_STATISTICS_UPDATE,
		data,
	}),
	notifyEventStatisticUpdate: data => ({
		type: actions.EVENT_NOTIFY_STATISTIC_UPDATE,
		data,
	}),

	eventMarketsStatisticsSubscribe: (eventID, subjectType) => ({
		type: actions.EVENT_SOCKET_SUBSCRIBE,
		data: { eventID, subjectType },
	}),

	eventMarketsStatisticsUnsubscribe: (type) => ({
		type: actions.EVENT_SOCKET_UNSUBSCRIBE,
		data: { type },
	}),

	changeSelectedEvent: data => ({
		type: actions.EVENT_CHANGE_SELECTED,
		data,
	}),
	setSelectedEvent: data => ({
		type: actions.EVENT_SET_SELECTED,
		data,
	}),

	// updateEventMarketStatistics: data => ({
	// 	type: actions.EVENT_UPDATE_MARKETS_STATISTICS,
	// 	data,
	// }),

};

export default actions;
