const actions = {

	MARKET_CONFIRM_CANCEL_SET_MODAL_VALUE_UI: 'MARKET_CONFIRM_CANCEL_SET_MODAL_VALUE_UI',
	MARKET_CONFIRM_CANCEL_MODAL_UI_REFRESH  : 'MARKET_CONFIRM_CANCEL_MODAL_UI_REFRESH',
	MARKET_CONFIRM_CANCEL_DATA_RESET        : 'MARKET_CONFIRM_CANCEL_DATA_RESET',

	setModalValueUI: (valueName, value) => ({
		type: actions.MARKET_CONFIRM_CANCEL_SET_MODAL_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	modalUIRefresh: data => ({
		type: actions.MARKET_CONFIRM_CANCEL_MODAL_UI_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.MARKET_CONFIRM_CANCEL_DATA_RESET,
	}),
};

export default actions;
