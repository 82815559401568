import { calculateLimit } from '../../../../helpers/utils';
import { createMarketListAdapter } from '../event/utils';

const marketAdapter = createMarketListAdapter();

// Preparing --------------------------------------------------------------------------------------

export function prepareBaseData(rawData = {}) {
	marketAdapter.clearExcludes();
	marketAdapter.addExcludeField('name');
	marketAdapter.addExcludeField('selections');

	// calculate real absolute limits
	rawData.userStakeLimitParent = calculateLimit({
		override      : rawData.userStakeLimitOverride,
		limitLocal    : rawData.userStakeLimit,
		limitTypeID   : rawData.userStakeLimitTypeID,
		parentAbsolute: rawData.userStakeLimitParentAbsolute,
	});
	rawData.userWonLimitParent = calculateLimit({
		override      : rawData.userWonLimitOverride,
		limitLocal    : rawData.userWonLimit,
		limitTypeID   : rawData.userWonLimitTypeID,
		parentAbsolute: rawData.userWonLimitParentAbsolute,
	});

	const preparedData = marketAdapter.prepare(rawData);

	return preparedData;
}
