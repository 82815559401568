import { all, call, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { usersAPI } from '../../../helpers/api/users';
import { logger } from '../../../helpers/logger';

function* listReload() {
	yield takeEvery(actions.USER_GROUP_DATA_RELOAD, function* (action) {
		try {
			const { userID } = action.data;
			yield call(usersAPI.getUserRiskGroupList, userID);
		} catch (e) {
			logger.log(e);
		}
	});
}

export default function* userGroupSaga() {
	yield all([
		fork(listReload),
	]);
}
