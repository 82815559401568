import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import notifications from '../../../helpers/notifications';
import { casinoAPI } from '../../../helpers/api/casino';
import { restoreWebsite } from '../../../helpers/utility';

import actions from './actions';
import gamesActions from '../games/actions';
import { prepareGamesList, updateGamesList } from '../games/utils';
import {
	adaptNames,
	adaptNamesData,
	adaptSeoData,
	prepareNamesData,
	prepareSeoData,
} from './utils';
import { logger } from '../../../helpers/logger';

const messages = {
	errorGameUpdate   : 'casino.game.error.update',
	errorNamesReload  : 'casino.game.error.names.reload',
	errorNamesUpdate  : 'casino.game.error.names.update',
	successGameUpdate : 'casino.game.success.update',
	successNamesUpdate: 'casino.game.success.names.update',
	errorTagsReload   : 'casino.game.error.tags.reload',
	errorSeoReload    : 'casino.game.error.seo.reload',
	successSeoReload  : 'casino.game.success.seo.reload',
	errorSeoSave      : 'casino.game.error.seo.save',
	successSeoSave    : 'casino.game.success.seo.save',
};

function getStoreData({ Casino, App, CMS }) {
	return {
		gamesList       : Casino.Games.get('gamesList'),
		gridGamesList   : CMS.LandingPage.GameGrid.get('gamesList'),
		baseData        : Casino.Game.get('baseData'),
		namesData       : Casino.Game.get('namesData'),
		casinoGameID    : Casino.Game.get('UI').casinoGameID,
		casinoGameName  : Casino.Game.get('UI').casinoGameName,
		langID          : Casino.Game.get('UI').langID,
		closeModal      : Casino.Game.get('UI').closeModal,
		isBaseChanged   : Casino.Game.get('UI').isBaseChanged,
		isNamesChanged  : Casino.Game.get('UI').isNamesChanged,
		websiteID	      : App.get('websiteID'),
		tagsAutoComplete: Casino.Game.get('tags'),
		providerID      : Casino.Games.get('UI').providerID,
		seoData         : Casino.Game.get('seoData'),
	};
}

function* casinoGameSave() {
	yield takeEvery(actions.CASINO_GAME_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const {
			baseData,
			namesData,
			casinoGameID,
			langID,
			closeModal,
			isNamesChanged,
			websiteID,
			// providerID,
		} = yield select(getStoreData);

		let isError      = false;
		let errorMessage = '';
		const { gamesList } = yield select(getStoreData);

		try {
			const updatedGamesList = updateGamesList(gamesList, null, casinoGameID, true, true);
			yield put(gamesActions.gamesListRefresh(updatedGamesList));

			// Base data
			errorMessage = messages.errorGameUpdate;
			const gameData = [{
				...baseData,
				isChanged: true,
			}];

			const preparedList = prepareGamesList(gameData);
			preparedList[0].tags = gameData[0].tags;
			const params = { website_id: websiteID };
			const res = yield call(casinoAPI.gamesListUpdate, preparedList, params );

			yield put(actions.seoDataSave());

			yield put(actions.uiRefresh({ isBaseChanged: false }));
			if (res.status === 200) {
				const updatedGamesList = updateGamesList(gamesList, baseData, casinoGameID, false, false);
				yield put(gamesActions.gamesListRefresh(updatedGamesList));
				notifications.showSuccess(messages.successGameUpdate);
			}

			// names
			if (isNamesChanged) {
				errorMessage = messages.errorNamesUpdate;
				const params = { lang_id: langID, website_id: websiteID };
				const preparedList = prepareNamesData(namesData, casinoGameID);

				yield call(casinoAPI.gameNamesUpdate, casinoGameID, preparedList, params);
				yield put(actions.uiRefresh({ isNamesChanged: false }));
			}
		} catch (error) {
			const updatedGamesList = updateGamesList(gamesList, null, casinoGameID, false, false);
			yield put(gamesActions.gamesListRefresh(updatedGamesList));

			isError = true;
			notifications.showError(errorMessage, error);
			logger.log(error);
		}

		if (!isError && !closeModal) {
			if (isNamesChanged) {
				yield put(actions.namesDataReload(casinoGameID));
			}
		}

		yield put(actions.uiRefresh({
			loading: false,
			visible: !((closeModal && !isError)),
		}));
	});
}

function* baseDataReload() {

	yield takeEvery(actions.CASINO_GAME_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { casinoGameID } = action.data;
		const { gamesList, gridGamesList } = yield select(getStoreData);

		const gameBaseData = find(gamesList, { id: casinoGameID }) || find(gridGamesList, { id: casinoGameID }) || {};
		yield put(actions.casinoGameTagsReload(casinoGameID));

		yield put(actions.baseDataRefresh(gameBaseData));
		yield put(actions.uiRefresh({
			loading      : false,
			isBaseChanged: false,
		}));
	});
}

function* namesDataReload() {

	yield takeEvery(actions.CASINO_GAME_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { casinoGameName } = yield select(getStoreData);

		const { casinoGameID } = action.data;
		let namesData = yield call(adaptNamesData, [], casinoGameName);


		try {
			const res = yield call(casinoAPI.gameNamesList, casinoGameID, {});
			if (res && res.status === 200) {
				namesData = yield call(adaptNamesData, res.data.data, casinoGameName);

				const entities = adaptNames(namesData);
				yield put(actions.namesDataRefresh(entities));

			}

		} catch (error) {
			notifications.showError(messages.errorNamesReload, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

function* seoDataReload() {

	yield takeEvery(actions.CASINO_GAME_SEO_DATA_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { casinoGameID } = yield select(getStoreData);

		try {
			const res = yield call(casinoAPI.gameSeoGet, casinoGameID);
			if (res && res.status === 200) {
				const adapted = adaptSeoData(res.data.data);
				yield put(actions.seoDataRefresh(adapted));
			}

		} catch (error) {
			notifications.showError(messages.errorNamesReload, error);
			logger.log(error);
		}
		yield put(actions.uiRefresh({
			loading: false,
		}));
	});
}

function* seoDataSave() {

	yield takeEvery(actions.CASINO_GAME_SEO_DATA_SAVE, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { seoData, casinoGameID } = yield select(getStoreData);
		const params = {
			website_id: restoreWebsite(),
		};
		try {
			const preparedData = prepareSeoData(seoData);
			const resSeoData = yield call(casinoAPI.gameSeoSave, preparedData, casinoGameID, params);
			if (resSeoData.status === 200) {
				const adapted = adaptSeoData(resSeoData.data.data);
				yield put(actions.seoDataRefresh(adapted));
			}

		} catch (e) {
			notifications.showError(messages.errorSeoSave);
		}


		yield put(actions.uiRefresh({
			loading: false,
		}));
	});
}

function* casinoGameTagsReload() {
	yield takeEvery(actions.CASINO_GAME_TAGS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { langID, websiteID, baseData } = yield select(getStoreData);
		const clonedBaseData = cloneDeep(baseData);
		const { casinoGameID } = action.data;
		const params = { lang_id: langID, website_id: websiteID };
		try {
			const res = yield call(casinoAPI.casinoTags, casinoGameID, params);
			if (res && res.status === 200) {
				clonedBaseData.tags = res.data.data;
				yield put(actions.baseDataRefresh(clonedBaseData));
			}

		} catch (error) {
			notifications.showError(messages.errorTagsReload, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

function* fetchGameTags() {

	yield takeEvery(actions.CASINO_GAME_TAGS_SET, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { langID, websiteID } = yield select(getStoreData);
		const params = { lang_id: langID, website_id: websiteID, name: action.data };
		try {
			const res = yield call(casinoAPI.getTagNames, null, params);
			if (res && res.status === 200) {
				yield put({ type: actions.CASINO_GAME_TAGS_SUCCESS, data: res.data.data });
			}

		} catch (error) {
			notifications.showError(messages.errorTagsReload, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({
			loading       : false,
			isNamesChanged: false,
		}));

	});
}

export default function* casinoGameModalSaga() {
	yield all([
		fork(casinoGameSave),
		fork(baseDataReload),
		fork(casinoGameTagsReload),
		fork(namesDataReload),
		fork(seoDataReload),
		fork(seoDataSave),
		fork(fetchGameTags),
	]);
}
