import { all, takeLatest, put, fork, call, select } from 'redux-saga/effects';
import { restoreWebsite } from '../../../../helpers/utility';
import actions from './actions';

import { showError, showSuccess } from '../../../../helpers/notifications';
import { pushNotificationsAPI } from '../../../../helpers/api/pushNotifications';

import { prepareNotification, getListParams } from '../utils';

const prefix = 'push.notif';

const messages = {
	errorNotificationSend  : `${prefix}.errorNotificationSend`,
	errorNoUser            : `${prefix}.errorNoUser`,
	successNotificationSend: `${prefix}.successNotificationSend`,
};

function getStoreData({ NotificationsCenter: { Notifications } }) {
	const UI = Notifications.Notification.get('UI');

	return {
		filter  : Notifications.UsersList.get('filter'),
		baseData: Notifications.Notification.get('baseData'),
		userID  : UI.userID,
	};
}

function* sendToUser() {

	yield takeLatest(actions.SEND_TO_USER, function* () {

		yield put(actions.uiRefresh({ loading: true }));
		const params = {
			website_id: restoreWebsite(),
		};
		const { userID, baseData } = yield select(getStoreData);
		const preparedData = {
			filter: { user_id: userID },
			data  : prepareNotification(baseData),
		};

		try {
			yield call(pushNotificationsAPI.sendNotification, preparedData, params);
			showSuccess(messages.successNotificationSend);

		} catch (error) {
			showError(messages.errorNotificationSend);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* sendToGroup() {

	yield takeLatest(actions.SEND_TO_GROUP, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, baseData } = yield select(getStoreData);
		const params = {
			website_id: restoreWebsite(),
		};
		const preparedData = {
			filter: { ...getListParams(filter) },
			data  : prepareNotification(baseData),
		};

		try {
			yield call(pushNotificationsAPI.sendNotification, preparedData, params);
			showSuccess(messages.successNotificationSend);

		} catch (error) {
			showError(messages.errorNotificationSend);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* pushNotificationSaga() {
	yield all([
		fork(sendToUser),
		fork(sendToGroup),
	]);
}
