import cloneDeep from 'lodash/cloneDeep';
import { ADMIN_TABLE_TYPES } from '../../helpers/commonConstants';
import { template } from './reducer';

export const adaptTableData = (data, storeTables) => {
	const tables = {};
	const entity = data.reduce((acc, item) => {
		acc[item.type_id] = item;
		return acc;
	}, {});

	storeTables.keySeq().forEach(item => {

		const typeID = ADMIN_TABLE_TYPES[item];
		const normalized = {
			...cloneDeep(template),
			// ...entity[typeID],
		};

		if (entity[typeID]) {
			const { itemsPerPage, columns } = entity[typeID].settings;

			normalized.savedColumns = columns || [];
			normalized.pagination.itemsPerPage = itemsPerPage || 100;
		}
		tables[item] = normalized;
	});
	return tables;
};
