import { apiRequest } from './index';

function upcomingEventsList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/content/upcomingEvents',
		params,
	};

	return apiRequest(req);
}

function upcomingEventsListUpdate(eventsList = [], params = {}) {
	const req = {
		method: 'POST',
		url   : '/content/upcomingEvents',
		data  : eventsList,
		params,
	};

	return apiRequest(req);
}

export const upcomingAPI = {
	upcomingEventsList,
	upcomingEventsListUpdate,
};
