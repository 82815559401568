const currencyActions = {

	CURRENCY_RELOAD_LIST : 'CURRENCY_RELOAD_LIST',
	CURRENCY_REFRESH_LIST: 'CURRENCY_REFRESH_LIST',

	WEBSITE_CURRENCY_RELOAD_LIST : 'WEBSITE_CURRENCY_RELOAD_LIST',
	WEBSITE_CURRENCY_REFRESH_LIST: 'WEBSITE_CURRENCY_REFRESH_LIST',

	CURRENCY_LIST_ADD_ITEM   : 'CURRENCY_LIST_ADD_ITEM',
	CURRENCY_LIST_REMOVE_ITEM: 'CURRENCY_LIST_REMOVE_ITEM',
	CURRENCY_LIST_CHANGE_ITEM: 'CURRENCY_LIST_CHANGE_ITEM',

	CURRENCY_LIST_CHANGED_ID_ADD   : 'CURRENCY_LIST_CHANGED_ID_ADD',
	CURRENCY_LIST_CHANGED_IDS_RESET: 'CURRENCY_LIST_CHANGED_IDS_RESET',

	CURRENCY_REFRESH_ENTITIES: 'CURRENCY_REFRESH_ENTITIES',
	CURRENCY_UPDATE_ITEM     : 'CURRENCY_UPDATE_ITEM',

	CURRENCY_RELOAD_RATES_LIST : 'CURRENCY_RELOAD_RATES_LIST',
	CURRENCY_REFRESH_RATES_LIST: 'CURRENCY_REFRESH_RATES_LIST',
	CURRENCY_UPDATE_RATES_LIST : 'CURRENCY_UPDATE_RATES_LIST',

	MODAL_RATES_LIST_SET_DATA   : 'MODAL_RATES_LIST_SET_DATA',
	MODAL_RATES_LIST_SET_VISIBLE: 'MODAL_RATES_LIST_SET_VISIBLE',
	MODAL_RATES_LIST_SET_LOADING: 'MODAL_RATES_LIST_SET_LOADING',
	MODAL_RATES_UI_REFRESH      : 'MODAL_RATES_UI_REFRESH',
	CURRENCY_LIST_UI_REFRESH  		: 'CURRENCY_LIST_UI_REFRESH',

	CURRENCY_PAYMENTS_LIST_RELOAD : 'CURRENCY_PAYMENTS_LIST_RELOAD',
	CURRENCY_PAYMENTS_LIST_REFRESH: 'CURRENCY_PAYMENTS_LIST_REFRESH',
	CURRENCY_PAYMENTS_LIST_SAVE   : 'CURRENCY_PAYMENTS_LIST_SAVE',

	CURRENCY_HISTORY_LIST_RELOAD : 'CURRENCY_HISTORY_LIST_RELOAD',
	CURRENCY_HISTORY_LIST_REFRESH: 'CURRENCY_HISTORY_LIST_REFRESH',

	currencyListReload: () => ({
		type: currencyActions.CURRENCY_RELOAD_LIST,
	}),
	currencyListRefresh: currencyList => ({
		type: currencyActions.CURRENCY_REFRESH_LIST,
		data: currencyList,
	}),

	websiteCurrencyListReload: () => ({
		type: currencyActions.WEBSITE_CURRENCY_RELOAD_LIST,
	}),
	websiteCurrencyListRefresh: currencyList => ({
		type: currencyActions.WEBSITE_CURRENCY_REFRESH_LIST,
		data: currencyList,
	}),

	currencyListAddItem: item => ({
		type: currencyActions.CURRENCY_LIST_ADD_ITEM,
		data: item,
	}),
	currencyListRemoveItem: itemID => ({
		type: currencyActions.CURRENCY_LIST_REMOVE_ITEM,
		data: itemID,
	}),
	currencyListChangeItem: (item, index) => ({
		type: currencyActions.CURRENCY_LIST_CHANGE_ITEM,
		data: { item, index },
	}),

	changedIDAdd: currencyID => ({
		type: currencyActions.CURRENCY_LIST_CHANGED_ID_ADD,
		data: {
			currencyID,
		},
	}),
	changedIDsReset: () => ({
		type: currencyActions.CURRENCY_LIST_CHANGED_IDS_RESET,
	}),

	currencyRefreshEntities: entities => ({
		type: currencyActions.CURRENCY_REFRESH_ENTITIES,
		data: entities,
	}),
	currencyUpdateEntity: item => ({
		type: currencyActions.CURRENCY_UPDATE_ITEM,
		data: item,
	}),

	currencyReloadRatesList: (currencyID, currencyList) => ({
		type: currencyActions.CURRENCY_RELOAD_RATES_LIST,
		data: {
			currencyID,
			currencyList,
		},
	}),
	currencyRefreshRatesList: (currencyID, rateList) => ({
		type: currencyActions.CURRENCY_REFRESH_RATES_LIST,
		data: {
			currencyID,
			rateList,
		},
	}),
	currencyUpdateRatesList: (currencyID, ratesList, withClose = false) => ({
		type: currencyActions.CURRENCY_UPDATE_RATES_LIST,
		data: {
			currencyID,
			ratesList,
			withClose,
		},
	}),

	modalRatesListSetData: data => ({
		type: currencyActions.MODAL_RATES_LIST_SET_DATA,
		data,
	}),
	modalRatesListSetVisible: visible => ({
		type: currencyActions.MODAL_RATES_LIST_SET_VISIBLE,
		data: visible,
	}),
	modalRatesListSetLoading: loading => ({
		type: currencyActions.MODAL_RATES_LIST_SET_LOADING,
		data: loading,
	}),
	modalUIRefresh: data => ({
		type: currencyActions.MODAL_RATES_UI_REFRESH,
		data,
	}),

	paymentsListReload: currencyID => ({
		type: currencyActions.CURRENCY_PAYMENTS_LIST_RELOAD,
		data: {
			currencyID,
		},
	}),
	paymentsListRefresh: data => ({
		type: currencyActions.CURRENCY_PAYMENTS_LIST_REFRESH,
		data,
	}),
	paymentsListSave: currencyID => ({
		type: currencyActions.CURRENCY_PAYMENTS_LIST_SAVE,
		data: {
			currencyID,
		},
	}),

	historyListReload: currencyID => ({
		type: currencyActions.CURRENCY_HISTORY_LIST_RELOAD,
		data: {
			currencyID,
		},
	}),
	historyListRefresh: list => ({
		type: currencyActions.CURRENCY_HISTORY_LIST_REFRESH,
		data: list,
	}),
	uiRefresh: data => ({
		type: currencyActions.CURRENCY_LIST_UI_REFRESH,
		data,
	}),
};

export default currencyActions;
