import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../tables/actions';

import { bannersAPI } from '../../../../helpers/api/banners';
import notifications from '../../../../helpers/notifications';
import { deriveTablePagination } from '../../../../selectors/tables';

import { getListParams, adaptBannersList } from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { logger } from '../../../../helpers/logger';

const messages = {
	errorListLoad      : 'Loading Banners list failed',
	errorBannerCopy    : 'Copying Banner failed',
	errorBannerDelete  : 'Deleting Banner failed',
	successBannerCopy  : 'Banner has been copied',
	successBannerDelete: 'Banner has been deleted',
};

const tableType     = TABLE_TYPES.banners;

function getStoreData(state) {
	const { CMS, Tables, App } = state;

	return {
		filter    : CMS.Banners.List.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
		websiteID : App.get('websiteID'),
	};
}

function* listReload() {

	yield takeEvery(actions.BANNERS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, pagination, sorting, websiteID } = yield select(getStoreData);
		filter.websiteID = websiteID;
		const params = getListParams(filter, pagination, sorting);
		if (params.status_id === 1) {
			params.status_id = 2;
		} else  if (params.status_id === 2) {
			params.status_id = 1;
		} else {
			delete params.status_id;
		}
		let entities = {};
		let totalCount = 0;
		try {
			const res = yield call(bannersAPI.bannersList, params);
			if (res && res.status === 200) {
				const result = adaptBannersList(res.data.data, res.headers);
				entities     = result.entities;
				totalCount   = result.totalCount;
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

// function* copyItem(action) {
//
//   yield takeEvery(actions.BANNERS_LIST_COPY_ITEM, function*(action) {
//
//     const { bannerID } = action.data;
//     try {
//       const res = yield call(bannersAPI.bannerCopy, bannerID);
//       if (res && res.status === 200) {
//         notifications.showSuccess(messages.successBannerCopy);
//         yield put(actions.listReload());
//       }
//     } catch (error) {
//       notifications.showError(messages.errorBannerCopy, error);
//       logger.log(error);
//     }
//   });
// }

function* deleteItem() {

	yield takeEvery(actions.BANNERS_LIST_DELETE_ITEM, function* (action) {
		const { pagination: { currentPage, totalCount, itemsPerPage } } = yield select(getStoreData);

		const { bannerID } = action.data;
		try {
			const res = yield call(bannersAPI.bannerDelete, bannerID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successBannerDelete);
				if (currentPage > 1 && totalCount % itemsPerPage === 1) {
					yield put(tableActions.paginationRefresh(tableType, { currentPage: currentPage - 1 }));
				}
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorBannerDelete, error);
			logger.log(error);
		}
	});
}

export default function* bannersListSaga() {
	yield all([
		fork(listReload),
		// fork(copyItem),
		fork(deleteItem),
	]);
}
