const actions = {

	DASHBOARD_BETS_BY_PROVIDER_DATA_RELOAD : 'DASHBOARD_BETS_BY_PROVIDER_DATA_RELOAD',
	DASHBOARD_BETS_BY_PROVIDER_DATA_REFRESH: 'DASHBOARD_BETS_BY_PROVIDER_DATA_REFRESH',
	DASHBOARD_BETS_BY_PROVIDER_UI_REFRESH  : 'DASHBOARD_BETS_BY_PROVIDER_UI_REFRESH',

	dataReload: () => ({
		type: actions.DASHBOARD_BETS_BY_PROVIDER_DATA_RELOAD,
	}),
	dataRefresh: data => ({
		type: actions.DASHBOARD_BETS_BY_PROVIDER_DATA_REFRESH,
		data,
	}),
	dataUIRefresh: data => ({
		type: actions.DASHBOARD_BETS_BY_PROVIDER_UI_REFRESH,
		data,
	}),
};

export default actions;
