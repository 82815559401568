import { combineReducers } from 'redux';

import Languages from './language/reducer';
import Operator from './operator/reducer';
import Partner from './partner/reducer';
import PaymentMethods from './paymentMethods/reducer';
import Currency from './currency/reducer';
import SmsGateways from './smsGateways/reducer';
import Countries from './countries/reducer';


export default combineReducers({
	Languages,
	Operator,
	Partner,
	PaymentMethods,
	Currency,
	SmsGateways,
	Countries,
});
