import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import uniqBy from 'lodash/uniqBy';
import actions from './actions';
import listActions from '../list/actions';

import { participantsAPI } from '../../../../helpers/api/participants';
import { historyAPI } from '../../../../helpers/api/history';
import { categoriesAPI } from '../../../../helpers/api/categories';
import { DISPLAY_STATUS } from '../../../../helpers/commonConstants';
import notifications from '../../../../helpers/notifications';

import {
	adaptBaseData,
	prepareBaseData,
	prepareNamesData,
	adaptMembersList,
	adaptSelectedIDsList,
	prepareSelectedIDsList,
	adaptCMSData,
	prepareCMSData,
} from './utils';
import { adaptNames } from '../../categories/history/utils';
import { adaptNamesData } from '../../markets/market/utils';
import { logger } from '../../../../helpers/logger';
import memberActions from '../member/actions';

const prefix = 'participants.participants';

const messages = {
	errorBaseDataLoad          : `${prefix}.errorBaseDataLoad`,
	errorCountryListLoad       : `${prefix}.errorCountryListLoad`,
	errorLeagueListLoad        : `${prefix}.errorLeagueListLoad`,
	errorAgeListLoad           : `${prefix}.errorAgeListLoad`,
	errorParticipantUpdate     : `${prefix}.errorParticipantUpdate`,
	errorParticipantAdd        : `${prefix}.errorParticipantAdd`,
	errorNamesSave             : `${prefix}.errorNamesSave`,
	errorMembersSave           : `${prefix}.errorMembersSave`,
	errorCMSSave               : `${prefix}.errorCMSSave`,
	errorNamesLoad             : `${prefix}.errorNamesLoad`,
	errorMembersLoad           : `${prefix}.errorMembersLoad`,
	errorParticipantMembersLoad: `${prefix}.errorParticipantMembersLoad`,
	errorCMSLoad               : `${prefix}.errorCMSLoad`,
	successparticipantUpdate   : `${prefix}.successparticipantUpdate`,
	successparticipantAdd      : `${prefix}.successparticipantAdd`,
	successNamesSave           : `${prefix}.successNamesSave`,
	successMembersSave         : `${prefix}.successMembersSave`,
	successCMSSave             : `${prefix}.successCMSSave`,
};


// Service ----------------------------------------------------------------------------------------

function getStoreData(state) {

	const { Participant } = state.Participants;

	return {
		baseData          : Participant.get('baseData'),
		namesData         : Participant.get('namesData'),
		cmsData           : Participant.get('cmsData'),
		membersSelectedIDs: Participant.get('membersSelectedIDs'),
		modalUI           : Participant.get('modalUI'),
		images            : Participant.get('images'),
	};
}

// Saga -------------------------------------------------------------------------------------------

function* baseDataReload() {

	yield takeEvery(actions.PARTICIPANT_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { participantID } = action.data;
		let sportID = null;
		let countryIDs = null;
		try {
			const resBaseData = yield call(participantsAPI.participantData, participantID);
			if (resBaseData && resBaseData.status === 200) {
				const rawBaseData = resBaseData.data.data;
				const baseData = adaptBaseData(rawBaseData);

				yield put(actions.baseDataRefresh(baseData));
				sportID = baseData.sportID; // eslint-disable-line prefer-destructuring
				countryIDs = baseData.countryIDs; // eslint-disable-line prefer-destructuring
			}

			// additional lists
			if (sportID) {
				yield put(actions.countryListReload(sportID));
			}
			if (countryIDs) {
				yield put(actions.leagueListReload(countryIDs));
			}

		} catch (error) {
			notifications.showError(messages.errorBaseDataLoad, error);
			logger.log(error);
		}

		yield put(actions.modalUIRefresh({
			loading  : false,
			isChanged: false,
		}));
		yield put(memberActions.modalUIRefresh({
			loading: false,
		}));

	});
}

function* countryListReload() {

	yield takeEvery(actions.PARTICIPANT_COUNTRY_LIST_RELOAD, function* (action) {
		const { sportID } = action.data;
		const params = {
			parent_id        : sportID,
			display_status_id: DISPLAY_STATUS.visible,
		};
		try {
			const response = yield call(categoriesAPI.getCategories, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				const countryIDs = list.map(country => {
					return country.id;
				});
				yield put(actions.countryListRefresh(list));
				yield put(actions.leagueListReload(countryIDs));
			}
		} catch (error) {
			notifications.showError(messages.errorCountryListLoad, error);
			logger.log(error);
		}
	});
}

function* leagueListReload() {

	yield takeEvery(actions.PARTICIPANT_LEAGUE_LIST_RELOAD, function* (action) {
		const { countryID } = action.data;
		const { baseData } = yield select(getStoreData);
		const { sportID } = baseData;
		const params = {
			display_status_id: DISPLAY_STATUS.visible,
			parents_id       : countryID,
		};
		try {
			const response = yield call(categoriesAPI.getCategoriesOfSport, sportID, params);
			if (response && response.status === 200) {
				const list = uniqBy(response.data.data, 'id');
				yield put(actions.leagueListRefresh(list));
			}
		} catch (error) {
			notifications.showError(messages.errorLeagueListLoad, error);
			logger.log(error);
		}
	});
}

function* ageListReload() {

	yield takeEvery(actions.PARTICIPANT_AGE_LIST_RELOAD, function* () {
		let ageList = [];

		try {
			const response = yield call(participantsAPI.participantAgeList);
			if (response && response.status === 200) {
				ageList = response.data.data || [];
			}
		} catch (error) {
			notifications.showError(messages.errorAgeListLoad, error);
			logger.log(error);
		}

		yield put(actions.ageListRefresh(ageList));
	});
}

function* participantUpdate() {

	yield takeEvery(actions.PARTICIPANT_UPDATE, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const {
			baseData,
			namesData,
			cmsData,
			membersSelectedIDs,
			modalUI,
		} = storeData;

		const { editMode, langID, closeModal } = modalUI;

		let { participantID } = modalUI;
		let isError = false;
		let errorMessage = messages.errorParticipantUpdate;

		try {
			// base data
			const preparedBaseData = prepareBaseData(baseData, participantID);
			if (editMode) {
				yield call(participantsAPI.participantUpdate, participantID, preparedBaseData);
				yield put(actions.modalUIRefresh({ isBaseChanged: false }));

			} else {
				errorMessage = messages.errorParticipantAdd;
				const response = yield call(participantsAPI.participantAdd, preparedBaseData);
				participantID = toInteger(response.data.data.id);

				yield put(actions.modalUIRefresh({
					participantID,
					editMode     : true,
					isBaseChanged: false,
				}));
			}

			// names
			if (modalUI.isNamesChanged) {
				errorMessage = messages.errorNamesSave;
				const preparedNames = prepareNamesData(namesData);
				const params = { lang_id: langID };
				yield call(participantsAPI.participantNamesUpdate, participantID, preparedNames, params);
				yield put(actions.modalUIRefresh({ isNamesChanged: false }));
			}

			// members
			if (modalUI.isMembersChanged) {
				errorMessage = messages.errorMembersSave;
				const preparedIDs = prepareSelectedIDsList(membersSelectedIDs);
				yield call(participantsAPI.participantMembersUpdate, participantID, preparedIDs);
				yield put(actions.modalUIRefresh({ isMembersChanged: false }));
			}

			// CMS
			if (modalUI.isCMSChanged) {
				errorMessage = messages.errorCMSSave;
				const preparedCMS = prepareCMSData(cmsData, langID, participantID);
				const params = { lang_id: langID };
				yield call(participantsAPI.participantDescriptionsUpdate, participantID, preparedCMS, params);
				yield put(actions.modalUIRefresh({ isCMSChanged: false }));
			}

			notifications.showSuccess(messages.successparticipantUpdate);

		} catch (error) {
			notifications.showError(errorMessage, error);
			isError = true;
			logger.log(error);
		}

		yield put(listActions.listReload());

		if (!closeModal) {
			yield put(actions.baseDataReload(participantID));

			if (modalUI.isNamesChanged) {
				yield put(actions.namesDataReload(participantID));
			}

			if (modalUI.isMembersChanged) {
				yield put(actions.membersSelectedIDsReload(participantID));
			}

			if (modalUI.isCMSChanged) {
				yield put(actions.cmsDataReload(participantID));
			}
		}

		yield put(actions.modalUIRefresh({
			loading: false,
			visible: !(closeModal && !isError),
		}));
	});
}

function* namesDataReload() {

	yield takeEvery(actions.PARTICIPANT_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const { modalUI } = storeData;
		const { langID } = modalUI;
		const { participantID } = action.data;

		let namesData;

		const params = { lang_id: langID };
		try {
			const res = yield call(participantsAPI.participantNamesList, participantID, params);
			if (res && res.status === 200) {
				const rawData = res.data.data;
				namesData = adaptNamesData(rawData);
			}
		} catch (error) {
			notifications.showError(messages.errorNamesLoad, error);
			logger.log(error);
		}

		yield put(actions.namesDataRefresh(namesData));
		yield put(actions.modalUIRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

function* historyNamesDataReload() {
	yield takeEvery(actions.PARTICIPANT_HISTORY_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const { modalUI } = storeData;
		const { langID } = modalUI;
		const { participantID } = action.data;

		let historyNamesData;

		const params = { lang_id: langID };
		try {
			const res = yield call(historyAPI.historyParticipantTranslations, participantID, params);
			if (res && res.status === 200) {
				const rawData = res.data.data;
				historyNamesData = adaptNames(rawData);
			}
		} catch (error) {
			notifications.showError(messages.errorNamesLoad, error);
			logger.log(error);
		}

		yield put(actions.historyNamesDataRefresh(historyNamesData));
		yield put(actions.modalUIRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

function* membersListReload() {

	yield takeEvery(actions.PARTICIPANT_MEMBERS_LIST_RELOAD, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		let membersList = [];

		const params = { unlimit: true };
		try {
			const res = yield call(participantsAPI.membersList, params);
			if (res && res.status === 200) {
				const rawData = res.data.data;
				membersList = adaptMembersList(rawData);
			}
		} catch (error) {
			notifications.showError(messages.errorMembersLoad, error);
			logger.log(error);
		}

		yield put(actions.membersListRefresh(membersList));
		yield put(actions.modalUIRefresh({
			loading         : false,
			isMembersChanged: false,
		}));
	});
}

function* membersSelectedIDsReload() {

	yield takeEvery(actions.PARTICIPANT_MEMBERS_SELECTED_IDS_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { participantID } = action.data;
		let selectedIDs = [];

		try {
			const res = yield call(participantsAPI.participantMembersList, participantID);
			if (res && res.status === 200) {
				const rawData = res.data.data;
				selectedIDs = adaptSelectedIDsList(rawData);
			}
		} catch (error) {
			notifications.showError(messages.errorParticipantMembersLoad, error);
			logger.log(error);
		}

		yield put(actions.membersSelectedIDsRefresh(selectedIDs));
		yield put(actions.modalUIRefresh({
			loading         : false,
			isMembersChanged: false,
		}));
	});
}

function* cmsDataReload() {

	yield takeEvery(actions.PARTICIPANT_CMS_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const { modalUI } = storeData;
		const { langID } = modalUI;

		const { participantID } = action.data;

		let cmsData = adaptCMSData([], participantID);

		const params = { lang_id: langID };
		try {
			const res = yield call(participantsAPI.participantDescriptionsList, participantID, params);
			if (res && res.status === 200) {
				const rawData = res.data.data;
				cmsData = adaptCMSData(rawData, participantID);
			}
		} catch (error) {
			notifications.showError(messages.errorCMSLoad, error);
			logger.log(error);
		}

		yield put(actions.cmsDataRefresh(cmsData));
		yield put(actions.modalUIRefresh({
			loading     : false,
			isCMSChanged: false,
		}));
	});
}

export default function* participantSaga() {
	yield all([
		fork(baseDataReload),
		fork(countryListReload),
		fork(leagueListReload),
		fork(ageListReload),
		fork(participantUpdate),
		fork(namesDataReload),
		fork(historyNamesDataReload),
		fork(membersListReload),
		fork(membersSelectedIDsReload),
		fork(cmsDataReload),
	]);
}
