import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData: {},

	UI: {
		ruleID    : null,
		visible   : false,
		editMode  : false,
		loading   : false,
		closeModal: false,
		isChanged : false,
	},
});

export default function bettingRuleReducer(state = initState, action) {

	switch (action.type) {

		case actions.DATA_REFRESH: {
			const { baseData } = action.data;
			const target = state.get('baseData');
			const result = fill(baseData, target, true);

			return state.set('baseData', result);
		}

		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);

			return state.set('UI', result);
		}

		case actions.DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
