import { fork, all } from 'redux-saga/effects';
import helpCenter from './helpCenter/saga';
import infoPages from './infoPages/saga';
import news from './news/saga';
import promotions from './promotions/saga';

export default function* contentSaga() {
	yield all([
		fork(helpCenter),
		fork(infoPages),
		fork(news),
		fork(promotions),
	]);
}
