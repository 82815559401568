import { apiRequest } from './index';

function uploadSingleFile(file, onProgress, params) {
	const originFileName = file.name;

	const formData = new FormData();
	formData.append('file', file, originFileName);

	const req = {
		method          : 'POST',
		url             : '/upload',
		data            : formData,
		onUploadProgress: onProgress,
		params,

	};
	return apiRequest(req);
}

function unloadSingleFile(fileLink) {

	const data = { file: fileLink };

	const req = {
		method: 'POST',
		url   : '/unload',
		data,
	};

	return apiRequest(req);
}

export const uploadsAPI = {
	uploadSingleFile,
	unloadSingleFile,
};
