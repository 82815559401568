import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';
import { CHANNEL_TYPES } from '../../../../helpers/commonConstants';
import { MODAL_TABS } from '../../../../constants/markets';

const initState = new Map({

	baseData    : {},
	namesData   : [],
	ordersData  : [],
	cmsData     : [],
	settingsData: {},
	dynamicData : [],
	mappingsData: [],
	rulesData   : [],

	modalUI: {
		marketID         : null,
		marketName       : '',
		visible          : false,
		loading          : false,
		editMode         : false,
		langID           : 1,
		channelID        : CHANNEL_TYPES.web,
		activeTab        : MODAL_TABS.details,
		closeModal       : false,
		isBaseChanged    : false,
		isCMSChanged     : false,
		isNamesChanged   : false,
		isOrdersChanged  : false,
		isSettingsChanged: false,
		isDynamicChanged : false,
		isRulesChanged   : false,
	},
});

export default function marketReducer(state = initState, action) {

	switch (action.type) {

		case actions.MARKET_BASE_DATA_REFRESH: {
			return state.set('baseData', action.data);
		}

		case actions.MARKET_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.MARKET_ORDERS_DATA_REFRESH: {
			return state.set('ordersData', action.data);
		}

		case actions.MARKET_CMS_DATA_REFRESH: {
			return state.set('cmsData', action.data);
		}

		case actions.MARKET_SETTINGS_DATA_REFRESH: {
			return state.set('settingsData', action.data);
		}

		case actions.MARKET_DYNAMIC_DATA_REFRESH: {
			return state.set('dynamicData', action.data);
		}

		case actions.MARKET_MAPPINGS_DATA_REFRESH: {
			return state.set('mappingsData', action.data);
		}

		case actions.MARKET_RESULT_RULES_DATA_REFRESH: {
			return state.set('rulesData', action.data);
		}

		case actions.MARKET_MODAL_UI_REFRESH: {
			const target = state.get('modalUI');
			const result = fill(action.data, target);
			return state.set('modalUI', result);
		}

		case actions.MARKET_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
