import { Map } from 'immutable';
import { actions } from './actions';
import { fill } from '../../../../../../helpers/utils';

const initState = new Map({
	baseData: {
		list           : [],
		associativeList: {},
	},

	UI: {
		loading: false,
	},

});

export default function gamesSearchResult(state = initState, { data, type }) {
	switch (type) {
		case actions.RESTRICT_BY_GAMES_REFRESH: {
			const target = state.get('baseData');
			const result = fill(data, target);
			return state.set('baseData', result);
		}


		case actions.RESTRICT_BY_GAMES_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(data, target);
			return state.set('UI', result);
		}


		default: {
			return state;
		}
	}
}
