const actions = {

	MARKETS_LIST_RELOAD                				  : 'MARKETS_LIST_RELOAD',
	MARKETS_LIST_REFRESH                 				: 'MARKETS_LIST_REFRESH',
	MARKETS_LIST_MARKET_TOGGLE_DISPLAY_STATUS: 'MARKETS_LIST_MARKET_TOGGLE_DISPLAY_STATUS',
	MARKETS_LIST_MARKET_TOGGLE_ARGUMENT      : 'MARKETS_LIST_MARKET_TOGGLE_ARGUMENT',

	MARKETS_LIST_MARKET_CANCEL_CHECK				: 'MARKETS_LIST_MARKET_CANCEL_CHECK',
	MARKETS_LIST_MARKET_CANCEL  								: 'MARKETS_LIST_MARKET_CANCEL',
	MARKETS_LIST_MARKET_RESTORE  							: 'MARKETS_LIST_MARKET_RESTORE',
	MARKETS_LIST_MARKET_CHANGE_STATUS_ID: 'MARKETS_LIST_MARKET_CHANGE_STATUS_ID',
	UI_REFRESH                        		: 'MARKETS_LIST_MARKET_UI_REFRESH',

	listReload: data => ({
		type   : actions.MARKETS_LIST_RELOAD,
		payload: data,
	}),
	listRefresh: list => ({
		type: actions.MARKETS_LIST_REFRESH,
		data: list,
	}),
	toggleDisplayStatus: (marketID, displayStatusID) => ({
		type: actions.MARKETS_LIST_MARKET_TOGGLE_DISPLAY_STATUS,
		data: {
			marketID,
			displayStatusID,
		},
	}),
	toggleArgument: (marketID, showArgument) => ({
		type: actions.MARKETS_LIST_MARKET_TOGGLE_ARGUMENT,
		data: {
			marketID,
			showArgument,
		},
	}),
	cancelCheckMarket: (marketID, marketName) => ({
		type: actions.MARKETS_LIST_MARKET_CANCEL_CHECK,
		data: {
			marketID,
			marketName,
		},
	}),
	cancelMarket: marketID => ({
		type: actions.MARKETS_LIST_MARKET_CANCEL,
		data: {
			marketID,
		},
	}),
	restoreMarket: marketID => ({
		type: actions.MARKETS_LIST_MARKET_RESTORE,
		data: {
			marketID,
		},
	}),
	onChangeStatusID: (marketID, statusID) => ({
		type: actions.MARKETS_LIST_MARKET_CHANGE_STATUS_ID,
		data: {
			marketID,
			statusID,
		},
	}),
	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),
};

export default actions;
