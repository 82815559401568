import { values } from 'lodash';
import { createSelector } from 'reselect';

const app             = (state) => state.App;
const websiteID       = (state) => state.App.get('websiteID');
const websiteSettings = (state) => state.App.get('websiteSettings');
const websiteEntities = (state) => state.App.get('websiteEntity');
const partnersEntities = (state) => state.App.get('partnerEntity');


export const deriveApp = createSelector(
	[app],
	(app) => ({
		collapsed : app.get('collapsed'),
		view      : app.get('view'),
		height    : app.get('height'),
		openDrawer: app.get('openDrawer'),
		openKeys  : app.get('openKeys'),
	})
);

export const deriveWebsiteID = createSelector(
	[websiteID],
	(websiteID) => websiteID
);

export const deriveWebsiteSettings = createSelector(
	[websiteSettings],
	(websiteSettings) => websiteSettings
);
export const deriveWebsitesList = createSelector(
	[websiteEntities],
	(website) => values(website)
);
export const derivePartnersList = createSelector(
	[partnersEntities],
	(partner) => values(partner)
);
