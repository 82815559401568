import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { channelTypeList } from '../../../../../helpers/commonEnums';
import { fields } from '../payways/utils';

const namesAdapter = createNamesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptBaseNamesData(rawData = []) {

	namesAdapter.clearExcludes();
	const adaptedList = namesAdapter.adaptList(rawData);

	const baseData = {
		logo       : '',
		currencyIDs: [],
	};
	const namesData = channelTypeList.map( channelItem => {

		const channelID = channelItem.id;
		const nameItem  = find(adaptedList, { channelID }) || {};

		if (!baseData.name && nameItem.name)         { baseData.name = nameItem.name; }
		if (!baseData.logo && nameItem.logo)         { baseData.logo = nameItem.logo; }
		if (!baseData.statusID && nameItem.statusID) { baseData.statusID = nameItem.statusID; }
		if (!baseData.orderID && nameItem.orderID)   { baseData.orderID = nameItem.orderID; }
		if (!baseData.max && nameItem.max)   { baseData.orderID = nameItem.max; }
		if (!baseData.min && nameItem.min)   { baseData.orderID = nameItem.min; }

		if (isEmpty(baseData.currencyIDs) && !isEmpty(nameItem.currencyIDs)) {
			baseData.currencyIDs = nameItem.currencyIDs;
		}

		return {
			channelID,
			...nameItem,
		};
	});

	return {
		baseData,
		namesData,
	};
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareNames(baseData = {}, namesData = [], langID = null) {
	if (!isArray(namesData)) {
		return [];
	}

	namesAdapter.clearExcludes();
	namesAdapter.addExcludeField('langID');
	namesAdapter.addExcludeField('logo');
	namesAdapter.addExcludeField('statusID');
	namesAdapter.addExcludeField('orderID');
	namesAdapter.addExcludeField('currencyIDs');
	namesAdapter.addExcludeField('max');
	namesAdapter.addExcludeField('min');

	const result = [];

	namesData.forEach( item => {
		const preparedItem = namesAdapter.prepare(item);

		preparedItem[fields.langID]      = langID;
		preparedItem[fields.logo]        = baseData.logo;
		preparedItem[fields.statusID]    = baseData.statusID;
		preparedItem[fields.orderID]     = baseData.orderID;
		preparedItem[fields.currencyIDs] = baseData.currencyIDs;
		preparedItem[fields.max] = baseData.max;
		preparedItem[fields.min] = baseData.min;

		result.push(preparedItem);
	});

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'orderID', fields.orderID);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'logo', fields.logo);

	adapter.addField(rules.arrayID, 'currencyIDs', fields.currencyIDs);
	adapter.addField(rules.number, 'max', fields.max);
	adapter.addField(rules.number, 'min', fields.min);

	return adapter;
}
