import { Map } from 'immutable';
import union from 'lodash/union';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({
	filter: createFilter(filtersURL.usersList, initFilterTypes),

	hasMore: {
		email: true,
	},

	riskGroupsList: [],

	dataSourceUserName : [],
	dataSourceUserEmail: [],
	dataSourceFirstName: [],
	dataSourceLastName : [],
});

export default function filterReducer(state = initState, action) {

	switch (action.type) {

		case actions.USERS_FILTER_SET_VALUE: {
			const { valueName, value } = action.data;
			const filter               = new Map(state.get('filter')).toObject();
			filter[valueName]          = value;

			return state.set('filter', filter);
		}

		case actions.USERS_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.USERS_FILTER_RISK_GROUP_LIST_REFRESH: {
			return state.set('riskGroupsList', action.data);
		}

		case actions.USERS_FILTER_DATA_SOURCE_USER_NAME_REFRESH: {
			return state.set('dataSourceUserName', action.data);
		}

		case actions.USERS_FILTER_DATA_SOURCE_USER_EMAIL_REFRESH: {
			return state.set('dataSourceUserEmail', action.data);
		}

		case actions.USERS_FILTER_DATA_SOURCE_USER_EMAIL_SCROLL_REFRESH: {
			const target = state.get('dataSourceUserEmail');
			const result = union( target, action.data );
			return state.set('dataSourceUserEmail', result);
		}

		case actions.USERS_FILTER_DATA_SOURCE_FIRST_NAME_REFRESH: {
			return state.set('dataSourceFirstName', action.data);
		}

		case actions.USERS_DATA_HASMORE: {
			return state.set('hasMore', action.data);
		}

		case actions.USERS_FILTER_DATA_SOURCE_LAST_NAME_REFRESH: {
			return state.set('dataSourceLastName', action.data);
		}

		case actions.USERS_FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
