import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';
import FilterComparator from '../../../helpers/filterComparator';
import { isID } from '../../../helpers/utils';
import queryToFilterValidaion from '../../../helpers/queryToFilterValidaion';
import { createEntities } from '../../utility';

const { isValidID, isValidBool, isValidString, isValidArray, isValidDateRange } = queryToFilterValidaion;
export const initFilterTypes = {
	// primary
	depositID    : isValidID, // number Transaction ID
	depositTypeID: isValidID, // Number
	userID       : isValidID, // number
	
	transactionCode: isValidString, // String
	userName       : isValidString, // String
	depositIP      : isValidString, // String
	
	userGroupIDs     : isValidArray, // Number[]
	userStatusIDs    : isValidArray, // Number[]
	depositChannelIDs: isValidArray, // Number[]
	depositStatusIDs : isValidArray, // Number[]
	userCountryIDs   : isValidArray, // String[]
	depositIPCountry : isValidArray, // String[]
	// advanced
	affiliateUser    : isValidBool, // Bool
	
	depositBonusEligibility: isValidID, // Boolean Number
	depositBonusWagering   : isValidID, // Boolean Number
	depositSourceAccount   : isValidString, // String
	affiliateReference     : isValidString, // String
	
	depositCurrencyIDs : isValidArray, // Number[]
	depositBonusTypeIDs: isValidArray, // Number[]
	depositSource      : isValidArray, // Number[]
	depositNumber      : isValidArray, // Number[](2)
	depositAmount      : isValidArray, // Number[](2)
	userBalanceBefore  : isValidArray, // Number[](2)
	userBalanceAfter   : isValidArray, // Number[](2)

	depositPlacementTime: isValidDateRange, // Date[](2)
};

export const fields = {
	depositID       : 'id',
	transactionCode : 'deposit_transaction',
	depositWebsiteID: 'deposit_website_id',
	depositChannelID: 'deposit_channel_id',

	depositSource       : 'deposit_source',
	depositSourceAccount: 'deposit_source_account',
	depositMessage      : 'deposit_message',

	depositPlacementTime    : 'deposit_placement_time',
	depositTypeID           : 'deposit_type_id',
	depositAmount           : 'deposit_amount',
	depositCurrencyID       : 'deposit_currency_id',
	depositStatusID         : 'deposit_status_id',
	depositCountryID        : 'deposit_country_id',
	depositIPCountry        : 'deposit_ip_country',
	depositTypeIDs          : 'deposit_type_ids',
	depositCurrencyIDs      : 'deposit_currency_ids',
	depositStatusIDs        : 'deposit_status_ids',
	depositNumber           : 'deposit_number',
	depositNumberFrom       : 'deposit_number_from',
	depositNumberTo         : 'deposit_number_to',
	depositPlacementTimeFrom: 'deposit_placement_time_from',
	depositPlacementTimeTo  : 'deposit_placement_time_to',
	depositAmountFrom       : 'deposit_amount_from',
	depositAmountTo         : 'deposit_amount_to',
	depositIP               : 'deposit_ip',
	depositCount            : 'deposit_count',
	amount                  : 'deposit_amount',

	depositBonusPercentage : 'deposit_bonus_percentage',
	depositBonusTypeID     : 'deposit_bonus_type',
	depositBonusTypeIDs    : 'deposit_bonus_type_ids',
	depositBonusEligibility: 'deposit_bonus_eligibility',
	depositBonusWagering   : 'deposit_bonus_wagering',

	userID               : 'user_id',
	userCountryID        : 'user_country_id',
	userStatusID         : 'user_status_id',
	userGroupID          : 'user_group_id',
	userName             : 'user_name',
	userBalanceBefore    : 'user_balance_before',
	userBalanceAfter     : 'user_balance_after',
	userBalanceBeforeFrom: 'user_balance_before_from',
	userBalanceBeforeTo  : 'user_balance_before_to',
	userBalanceAfterFrom : 'user_balance_after_from',
	userBalanceAfterTo   : 'user_balance_after_to',

	channelID: 'channel_id',
	websiteID: 'website_id',
	code     : 'code',

	depositTotalAmountUSD: 'deposit_total_amount_usd',

	depositAmountUSD    : 'deposit_amount_usd',
	userBalanceBeforeUSD: 'user_balance_before_usd',
	userBalanceAfterUSD : 'user_balance_after_usd',
	affiliateUser       : 'affiliate_user',
	affiliateReference  : 'affiliate_reference',
};


const listAdapter       = createListDataAdapter();
const totalAdapter      = createTotalDataAdapter();
const depositComparator = createDepositComparator();

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'depositID', fields.depositID);
	builder.addField(rules.isString, 'transactionCode', fields.transactionCode);
	builder.addField(rules.isID, 'userID', fields.userID);

	builder.addField(rules.isArrayID, 'depositWebsiteIDs', fields.depositWebsiteID);
	builder.addField(rules.isArrayID, 'depositChannelIDs', fields.depositChannelID);
	builder.addField(rules.isArrayID, 'depositBonusTypeIDs', fields.depositBonusTypeIDs);
	builder.addField(rules.isArrayID, 'depositCurrencyIDs', fields.depositCurrencyIDs);
	builder.addField(rules.isArrayID, 'depositStatusIDs', fields.depositStatusIDs);
	builder.addField(rules.isArrayID, 'userGroupIDs', fields.userGroupID);
	builder.addField(rules.isArrayID, 'userStatusIDs', fields.userStatusID);

	builder.addField(rules.isString, 'userName', fields.userName);
	builder.addField(rules.isString, 'depositIP', fields.depositIP);
	builder.addField(rules.isString, 'depositSourceAccount', fields.depositSourceAccount);
	builder.addField(rules.isString, 'depositMessage', fields.depositMessage);
	builder.addField(rules.isString, 'affiliateReference', fields.affiliateReference);

	builder.addField(rules.isArrayString, 'userCountryIDs', fields.userCountryID);
	builder.addField(rules.isArrayString, 'depositIPCountry', fields.depositIPCountry);

	builder.addField(rules.isArrayString, 'depositSource', fields.depositSource);

	builder.addField(rules.isBoolean, 'depositBonusEligibility', fields.depositBonusEligibility);
	builder.addField(rules.isBoolean, 'depositBonusWagering', fields.depositBonusWagering);
	builder.addField(rules.isBoolean, 'affiliateUser', fields.affiliateUser);

	builder.addRangeField(rules.isNumberRange, filter.depositNumber, [
		fields.depositNumberFrom,
		fields.depositNumberTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.depositAmount, [
		fields.depositAmountFrom,
		fields.depositAmountTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.userBalanceBefore, [
		fields.userBalanceBeforeFrom,
		fields.userBalanceBeforeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.userBalanceAfter, [
		fields.userBalanceAfterFrom,
		fields.userBalanceAfterTo,
	]);

	builder.addRangeField(rules.isDateTimeRange, filter.depositPlacementTime, [
		fields.depositPlacementTimeFrom,
		fields.depositPlacementTimeTo,
	]);

	const params = builder.biuldParams(filter);

	if (isID(filter.depositTypeID)) {
		params[fields.depositTypeIDs] = [filter.depositTypeID];
	}

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptDepositList(rawData = {}) {

	const adaptedData   = rawData.data ? listAdapter.adaptList(rawData.data) : [];
	const depositTotals = rawData.total ? totalAdapter.adapt(rawData.total) : totalAdapter.adapt({});
	const entities		= createEntities(adaptedData, 'depositID');
	const depositIDs	= Object.keys(entities);
	
	const result = {
		depositTotals,
		entities,
		depositIDs,
	};

	return result;
}

export function adaptDeposit(rawData = {}) {

	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

// Compare ----------------------------------------------------------------------------------------

export function compareDepositWithFilter(deposit, filter) {
	if (!deposit) {
		return false;
	}
	const hasMatches = depositComparator.compare(deposit, filter);

	return hasMatches;
}

// Adapters ---------------------------------------------------------------------------------------

function createListDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'depositID', fields.depositID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'userStatusID', fields.userStatusID);
	adapter.addField(rules.id, 'userGroupID', fields.userGroupID);
	adapter.addField(rules.id, 'depositTypeID', fields.depositTypeID);
	adapter.addField(rules.id, 'depositCurrencyID', fields.depositCurrencyID);

	adapter.addField(rules.id, 'currencyID', fields.depositCurrencyID);

	adapter.addField(rules.id, 'depositStatusID', fields.depositStatusID);
	adapter.addField(rules.id, 'depositBonusTypeID', fields.depositBonusTypeID);

	adapter.addField(rules.positiveNumber, 'depositNumber', fields.depositNumber);
	adapter.addField(rules.positiveNumber, 'userBalanceBefore', fields.userBalanceBefore);
	adapter.addField(rules.positiveNumber, 'userBalanceAfter', fields.userBalanceAfter);
	adapter.addField(rules.positiveNumber, 'depositSource', fields.depositSource);
	adapter.addField(rules.positiveNumber, 'depositAmount', fields.depositAmount);
	adapter.addField(rules.positiveNumber, 'amount', fields.amount);
	adapter.addField(rules.positiveNumber, 'depositBonusPercentage', fields.depositBonusPercentage);
	adapter.addField(rules.positiveNumber, 'depositAmountUSD', fields.depositAmountUSD);
	adapter.addField(rules.positiveNumber, 'userBalanceBeforeUSD', fields.userBalanceBeforeUSD);
	adapter.addField(rules.positiveNumber, 'userBalanceAfterUSD', fields.userBalanceAfterUSD);

	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'userCountryID', fields.userCountryID);
	adapter.addField(rules.string, 'depositSourceAccount', fields.depositSourceAccount);
	adapter.addField(rules.string, 'depositIP', fields.depositIP);
	adapter.addField(rules.string, 'depositCountryID', fields.depositCountryID);
	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.string, 'depositMessage', fields.depositMessage);
	adapter.addField(rules.string, 'affiliateReference', fields.affiliateReference);

	adapter.addField(rules.dateTime, 'depositPlacementTime', fields.depositPlacementTime);

	adapter.addField(rules.bool, 'depositBonusEligibility', fields.depositBonusEligibility);
	adapter.addField(rules.bool, 'depositBonusWagering', fields.depositBonusWagering);
	adapter.addField(rules.bool, 'affiliateUser', fields.affiliateUser);

	return adapter;
}

function createTotalDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'depositAmount', fields.depositAmount);
	adapter.addField(rules.number, 'depositCount', fields.depositCount);
	adapter.addField(rules.number, 'depositAmountUSD', fields.depositAmountUSD);

	return adapter;
}

function createDepositComparator() {

	const comparator = new FilterComparator();
	const rules = comparator.RULES;

	comparator.addField(rules.isEqual, 'depositID', 'depositID');
	comparator.addField(rules.isEqual, 'userID', 'userID');
	comparator.addField(rules.isEqual, 'userName', 'userName');
	comparator.addField(rules.isEqual, 'depositIP', 'depositIP');
	comparator.addField(rules.isEqual, 'depositSourceAccount', 'depositSourceAccount');
	comparator.addField(rules.isEqual, 'depositSource', 'depositSource');
	comparator.addField(rules.isEqual, 'depositBonusEligibility', 'depositBonusEligibility');
	comparator.addField(rules.isEqual, 'depositBonusWagering', 'depositBonusWagering');

	comparator.addField(rules.inArrayID, 'websiteID', 'depositWebsiteIDs');
	comparator.addField(rules.inArrayID, 'channelID', 'depositChannelIDs');
	comparator.addField(rules.inArrayID, 'depositBonusTypeID', 'depositBonusTypeIDs');
	comparator.addField(rules.inArrayID, 'depositTypeID', 'depositTypeIDs');
	comparator.addField(rules.inArrayID, 'depositCurrencyID', 'depositCurrencyIDs');
	comparator.addField(rules.inArrayID, 'depositStatusID', 'depositStatusIDs');
	comparator.addField(rules.inArrayID, 'userGroupID', 'userGroupIDs');
	comparator.addField(rules.inArrayID, 'userStatusID', 'userStatusIDs');

	comparator.addField(rules.inArrayString, 'userCountryID', 'userCountryIDs');
	comparator.addField(rules.inArrayString, 'depositCountryID', 'depositIPCountry');

	comparator.addField(rules.inNumberRange, 'depositNumber', 'depositNumber');
	comparator.addField(rules.inNumberRange, 'depositAmount', 'depositAmount');
	comparator.addField(rules.inNumberRange, 'userBalanceBefore', 'userBalanceBefore');
	comparator.addField(rules.inNumberRange, 'userBalanceAfter', 'userBalanceAfter');

	comparator.addField(rules.inDateRange, 'depositPlacementTime', 'depositPlacementTime');

	return comparator;
}
