import isEqual from 'lodash/isEqual';
import isDate from 'lodash/isDate';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import cloneDeep from 'lodash/cloneDeep';
import camelCase from 'lodash/camelCase';
import uniq from 'lodash/uniq';
/*
options: {
    excludeKeys: String[],
    onlyChanged: Boolean
}
*/

export class DiffMapper {
	VALUE_CREATED = 'CREATED';

	VALUE_UPDATED = 'UPDATED';

	VALUE_DELETED = 'DELETED';

	VALUE_UNCHANGED = 'UNCHANGED';

	excludeKeys = [];

	currencyID  = 0;

	onlyChanged = false;

	useExcludeKeys = false;

	init = (options = {}) => {
		this.excludeKeys = isArray(options.excludeKeys) ? options.excludeKeys : [];
		this.onlyChanged = Boolean(options.onlyChanged);
		this.useExcludeKeys = Boolean(this.excludeKeys.length);
	};

	map = (objectBefore, objectAfter) => {
		if ((objectBefore && objectBefore.currency_id) || (objectAfter && objectAfter.currency_id)) {
			this.currencyID = objectBefore.currency_id || objectAfter.currency_id;
		}
		if (isFunction(objectBefore) || isFunction(objectAfter)) {
			throw new Error('Invalid argument. Function given, object expected.');
		}

		if (this.isValue(objectBefore) || this.isValue(objectAfter)) {
			return {
				type      : this.compareValues(objectBefore, objectAfter),
				before    : objectBefore,
				after     : objectAfter,
				currencyID: this.currencyID,
			};
		}

		const diff = {};

		// object 'Before'
		Object.keys(objectBefore).forEach(key => {
			if (this.excludeKey(key)) {
				return;
			}
			if (isFunction(objectBefore[key])) {
				return;
			}

			let value2;
			if (typeof objectAfter[key] !== 'undefined') {
				value2 = objectAfter[key];
			}

			diff[key] = this.map(objectBefore[key], value2);
		});

		// object 'After'
		Object.keys(objectAfter).forEach(key => {
			if (this.excludeKey(key)) {
				return;
			}
			if (isFunction(objectAfter[key]) || typeof diff[key] !== 'undefined') {
				return;
			}

			diff[key] = this.map(undefined, objectAfter[key]);
		});

		// processing diff (if need)
		if (!this.onlyChanged) return diff;

		const clonedDiff = cloneDeep(diff);
		const adaptedDiff = {};
		Object.keys(clonedDiff).forEach(key => {
			if (clonedDiff[key].type !== this.VALUE_UNCHANGED) {
				adaptedDiff[key] = clonedDiff[key];
			}
		});

		return adaptedDiff;
	};

	compareValues = (value1, value2) => {
		if (value1 === value2) {
			return this.VALUE_UNCHANGED;
		}
		if (isDate(value1) && isDate(value2) && isEqual(value1, value1)) {
			return this.VALUE_UNCHANGED;
		}
		if (typeof value1 === 'undefined') {
			return this.VALUE_CREATED;
		}
		if (typeof value2 === 'undefined') {
			return this.VALUE_DELETED;
		}
		return this.VALUE_UPDATED;
	};

	isValue = obj => {
		return !isPlainObject(obj) && !isArray(obj);
	};

	excludeKey = key => {
		if (!this.useExcludeKeys) return false;

		return this.excludeKeys.indexOf(key) !== -1;
	};

	toList = (resultData, fieldPrefix = '') => {
		const list = [];
		Object.keys(resultData).forEach(key => {
			const resValue = resultData[key];
			if (!resValue) {
				return;
			}

			let { before, after } = resValue;
			if (before === undefined || before === null) { before = ''; }
			if (after  === undefined || after  === null) { after = ''; }

			before = this.isValue(before) ? String(before) : JSON.stringify(before);
			after  = this.isValue(after)  ? String(after)  : JSON.stringify(after);
			const filedName = camelCase(key);
			const record = {
				fieldName : fieldPrefix ? `${fieldPrefix}` : filedName,
				type      : resValue.type,
				before,
				after,
				currencyID: resValue.currencyID,
			};
			list.push(record);
		});

		return list;
	};

	groupByPropName = (data, propName) => {
		const obj = {};
		const { length } = data;
		for (let i = 0; i < length; i++) {
			obj[data[i][propName]] = data[i];
		}
		return obj;
	};

	beforeAfterToString = (item) => {
		if (isPlainObject(item)) {
			const { limit } = item;
			return {
				trading_mode  : item.trading_mode,
				minutes_before: item.minutes_before,
				limit,
			};
		}
	};

	toListCreatedDeleted = (item, fieldName) => {
		const { before, after, type } = item;
		const itemBefore = before  ? this.beforeAfterToString(before) : null;
		// const itemBefore = before || null;
		const itemAfter = after  ? this.beforeAfterToString(after) : null;
		// const itemAfter = after  || null;

		return {
			fieldName,
			type,
			before: itemBefore,
			after : itemAfter,
		};
	};

	mapObjectsList = (objectBefore, objectAfter, listName) => {
		let mapList = [];

		const listBefore = isArray(objectBefore[listName]) ? objectBefore[listName] : [];
		const listAfter = isArray(objectAfter[listName]) ? objectAfter[listName] : [];
		const listBeforeObj = this.groupByPropName(listBefore, 'id');
		const listAfterObj = this.groupByPropName(listAfter, 'id');
		const keys = uniq(Object.keys(listAfterObj).concat(Object.keys(listBeforeObj)));
		const { length } = keys;
		for (let i = 0; i < length; i++) {
			const itemMap = this.map(listBeforeObj[keys[i]], listAfterObj[keys[i]]);
			if (itemMap.type && (itemMap.type === 'CREATED' || itemMap.type === 'DELETED')) {
				mapList = mapList.concat(this.toListCreatedDeleted(itemMap, listName));
			} else {
				mapList = mapList.concat(this.toList(itemMap));
			}
		}
		return mapList;
	};

	mapList = (objectBefore, objectAfter, listName) => {
		let mapList = [];

		const listBefore = isArray(objectBefore[listName]) ? objectBefore[listName] : [];
		const listAfter = isArray(objectAfter[listName]) ? objectAfter[listName] : [];

		const maxLength = Math.max(listBefore.length, listAfter.length);
		for (let i = 0; i < maxLength; i++) {
			const valueBefore = !this.isValue(listBefore[i]) ? listBefore[i] : { value: listBefore[i] };
			const valueAfter = !this.isValue(listAfter[i]) ? listAfter[i] : { value: listAfter[i] };

			const itemMap = this.map(valueBefore, valueAfter);

			// if (transformToList) {
			const itemList = this.toList(itemMap, `${camelCase(listName)}`);
			mapList = mapList.concat(itemList);
			// } else {
			//   mapList.push(itemMap);
			// }
		}

		return mapList;
	};
}

// test
/*
var result = deepDiffMapper.map({
      a:'i am unchanged',
      b:'i am deleted',
      e:{ a: 1,b:false, c: null},
      f: [1,{a: 'same',b:[{a:'same'},{d: 'delete'}]}],
      g: new Date('2017.11.25')
  },
  {
      a:'i am unchanged',
      c:'i am created',
      e:{ a: '1', b: '', d:'created'},
      f: [{a: 'same',b:[{a:'same'},{c: 'create'}]},1],
      g: new Date('2017.11.25')
  });

logger.log(result);
*/
