import { EVENT_TYPES } from '../codes';
import { emitEvent } from '../socket';

function subscribeToUpdate(transactionIDs) {

	const event = {
		subjectType: EVENT_TYPES.casino,
		ids        : transactionIDs,
	};

	return emitEvent('subscribe', event);
}

export const casinoAPI = {
	subscribeToUpdate,
};
