import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	statisticID  : null,
	statisticList: [],

	scopes        : {},
	statisticTypes: {},
	participants  : {},

	UI: {
		visible   : false,
		loading   : false,
		eventID   : null,
		eventName : '',
		sportID   : null,
		closeModal: false,
	},
});

export default function resultsStatisticsReducer(state = initState, action) {
	switch (action.type) {
		case actions.RESULT_STATISTICS_DATA_REFRESH: {
			const { statisticID, statisticList } = action.data;

			return state
				.set('statisticID', statisticID)
				.set('statisticList', statisticList);
		}

		case actions.RESULT_STATISTICS_DATA_RESET: {
			return initState;
		}

		case actions.RESULT_STATISTICS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target, true);
			return state.set('UI', result);
		}

		case actions.RESULT_STATISTICS_COMMON_DATA_REFRESH: {
			const { scopes, statisticTypes, participants } = action.data;

			return state
				.set('scopes', scopes)
				.set('statisticTypes', statisticTypes)
				.set('participants', participants);
		}

		default:
			return state;
	}
}
