const actions = {

	CASINO_TAGS_LIST_RELOAD : 'CASINO_TAGS_LIST_RELOAD',
	CASINO_TAGS_LIST_REFRESH: 'CASINO_TAGS_LIST_REFRESH',

	listReload: (data) => ({
		type: actions.CASINO_TAGS_LIST_RELOAD,
		data,
	}),
	listRefresh: entities => ({
		type: actions.CASINO_TAGS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
};

export default actions;
