import { combineReducers } from 'redux';

import Tree from './tree/reducer';
import Category from './category/reducer';
import LeagueRestriction from './leagueRestriction/reducer';
import RegulationTypes from './regulationTypes/reducer';
import Mapping from './mapping/reducer';
import History from './history/reducer';
import MarketSettings from './settings/reducer';

export default combineReducers({
	Tree,
	Category,
	LeagueRestriction,
	RegulationTypes,
	Mapping,
	History,
	MarketSettings,
	// BettingRules,
});
