import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';

const freeSpinsList             = (state) => state.Loyalty.BonusingHistory.FreeSpins.get('list');
const freeSpinsFilter           = (state) => state.Loyalty.BonusingHistory.FreeSpins.get('filter');
const freeSpinsBonusData        = (state) => state.Loyalty.Bonuses.Bonuses.get('bonusData');
const freeSpinLineAndDenominationsRequired   = (state) => state.Loyalty.Bonuses.Bonuses.get('lineAndDenominationsRequired');

const bonusesEntities           = (state) => state.Loyalty.Bonuses.Bonuses.get('entities');
const bonusesList               = (state) => state.Loyalty.Bonuses.Bonuses.get('bonusList');
const regFreeSpinsFilter        = (state) => state.Loyalty.Bonuses.Bonuses.get('filter');
const regFreeSpinsBonusData     = (state) => state.Loyalty.Bonuses.Bonuses.get('bonusData');
const regBonusesBets            = (state) => state.Loyalty.Bonuses.Bonuses.get('bets');
const bonusesDenominations      = (state) => state.Loyalty.Bonuses.Bonuses.get('denominations');
const bonusesUI                 = (state) => state.Loyalty.Bonuses.Bonuses.get('UI');
const regFreeSpinsCurrencyList  = (state) => state.Loyalty.Bonuses.Bonuses.get('currencyList');

const depositEntities           = (state) => state.Loyalty.Bonuses.Bonuses.get('entities');
const depositFilter             = (state) => state.Loyalty.Bonuses.Bonuses.get('filter');
const depositBonusData          = (state) => state.Loyalty.Bonuses.Bonuses.get('bonusData');
const depositUI                 = (state) => state.Loyalty.Bonuses.Bonuses.get('UI');
const depositCurrencyList       = (state) => state.Loyalty.Bonuses.Bonuses.get('currencyList');

const nextDepositEntities       = (state) => state.Loyalty.Bonuses.Bonuses.get('entities');
const nextDepositFilter         = (state) => state.Loyalty.Bonuses.Bonuses.get('filter');
const nextDepositBonusData      = (state) => state.Loyalty.Bonuses.Bonuses.get('bonusData');
const nextDepositUI             = (state) => state.Loyalty.Bonuses.Bonuses.get('UI');
const nextDepositCurrencyList   = (state) => state.Loyalty.Bonuses.Bonuses.get('currencyList');

const welcomeBonusEntities      = (state) => state.Loyalty.Bonuses.WelcomeBonus.get('entities');
const welcomeBonusFilter        = (state) => state.Loyalty.Bonuses.WelcomeBonus.get('filter');
const welcomeBonusBonusData     = (state) => state.Loyalty.Bonuses.WelcomeBonus.get('bonusData');
const welcomeBonusUI            = (state) => state.Loyalty.Bonuses.WelcomeBonus.get('UI');
const welcomeBonusCurrencyList  = (state) => state.Loyalty.Bonuses.WelcomeBonus.get('currencyList');

const cashbackEntities          = (state) => state.Loyalty.Bonuses.Cashback.get('entities');
const subCashbackList           = (state) => state.Loyalty.Bonuses.Cashback.get('subCashbackList');
const cashbackFilter            = (state) => state.Loyalty.Bonuses.Cashback.get('filter');
const cashbackBonusData         = (state) => state.Loyalty.Bonuses.Cashback.get('bonusData');
const cashbackUI                = (state) => state.Loyalty.Bonuses.Cashback.get('UI');

const cashbackUsersEntities     = (state) => state.Loyalty.BonusingHistory.CashbackUsers.get('entities');
const cashbackUsersFilter       = (state) => state.Loyalty.BonusingHistory.CashbackUsers.get('filter');

const userBonusData             = (state) => state.Loyalty.Bonuses.UserBonus.get('bonusData');
const userBonusBets             = (state) => state.Loyalty.Bonuses.UserBonus.get('bets');
const userBonusDenominations    = (state) => state.Loyalty.Bonuses.UserBonus.get('denominations');
const userBonusLineAndDenominationsRequired    = (state) => state.Loyalty.Bonuses.UserBonus.get('lineAndDenominationsRequired');
const userBonusUI               = (state) => state.Loyalty.Bonuses.UserBonus.get('UI');
const userBonusFreeBets         = (state) => state.Loyalty.Bonuses.UserFreeBets.get('entities');

const casinoBonusesEntities     = (state) => state.Loyalty.Bonuses.UserCasinoBonuses.get('entities');
const casinoBonusesUI           = (state) => state.Loyalty.Bonuses.UserCasinoBonuses.get('UI');
const casinoSpinsEntities       = (state) => state.Loyalty.Bonuses.UserCasinoFreeSpins.get('entities');
const casinoSpinsUI             = (state) => state.Loyalty.Bonuses.UserCasinoFreeSpins.get('UI');

const freeBetsEntities          = (state) => state.Loyalty.BonusingHistory.FreeBets.get('entities');

// Free Spins -------------------------------------------------------------------------------------
export const deriveFreeSpinsList = createSelector(
	[freeSpinsList],
	(list) => list
);

export const deriveFreeSpinsFilter = createSelector(
	[freeSpinsFilter],
	(filter) => filter
);

export const deriveFreeSpinsLineAndDenominationsRequired = createSelector(
	[freeSpinLineAndDenominationsRequired],
	(lineAndDenominationsRequired) => lineAndDenominationsRequired
);

// Registration Free Spins ------------------------------------------------------------------------
export const deriveBonusesEntities = createSelector(
	[bonusesEntities],
	(entities) => entities
);

export const deriveBonusesList = createSelector(
	[bonusesList],
	(list) => list
);
export const deriveRegFreeSpinsFilter = createSelector(
	[regFreeSpinsFilter],
	(filter) => filter
);

export const deriveBonusesBonusData = createSelector(
	[regFreeSpinsBonusData],
	(bonusData) => bonusData
);

export const deriveBonusesSpinsBets = createSelector(
	[regBonusesBets],
	(bets) => bets
);

export const deriveBonusesDenominations = createSelector(
	[bonusesDenominations],
	(denominations) => denominations
);

export const deriveBonusesUI = createSelector(
	[bonusesUI],
	(UI) => UI
);

export const deriveRegFreeSpinsCurrencyList = createSelector(
	[regFreeSpinsCurrencyList],
	(currencyList) => orderBy(currencyList, ['orderID'], ['asc'])
);

// First Deposit ----------------------------------------------------------------------------------
export const derivedepositEntities = createSelector(
	[depositEntities],
	(entities) => entities
);
export const firstDepositUI = createSelector(
	[depositEntities],
	(entities) => entities
);

export const deriveFirstDepositList = createSelector(
	[derivedepositEntities],
	(entities) => orderBy(values(entities), ['statusID', 'id'], ['desc', 'asc'])
);
export const deriveFirstDepositUI = createSelector(
	[firstDepositUI],
	(entities) => entities
);
export const deriveFirstDepositEntities = createSelector(
	[firstDepositUI],
	(entities) => entities
);

export const derivedepositFilter = createSelector(
	[depositFilter],
	(filter) => filter
);

export const deriveDepositBonusData = createSelector(
	[depositBonusData],
	(bonusData) => bonusData
);

export const deriveDepositUI = createSelector(
	[depositUI],
	(UI) => UI
);

export const derivedepositCurrencyList = createSelector(
	[depositCurrencyList],
	(currencyList) => currencyList
);
// Next Deposit ----------------------------------------------------------------------------------
export const deriveNextDepositEntities = createSelector(
	[nextDepositEntities],
	(entities) => entities
);

export const deriveNextDepositList = createSelector(
	[deriveNextDepositEntities],
	(entities) => orderBy(values(entities), ['statusID', 'id'], ['desc', 'asc'])
);

export const deriveNextDepositFilter = createSelector(
	[nextDepositFilter],
	(filter) => filter
);

export const deriveNextDepositBonusData = createSelector(
	[nextDepositBonusData],
	(bonusData) => bonusData
);

export const deriveNextDepositUI = createSelector(
	[nextDepositUI],
	(UI) => UI
);

export const deriveNextDepositCurrencyList = createSelector(
	[nextDepositCurrencyList],
	(currencyList) => currencyList
);
// Welcome Bonus ----------------------------------------------------------------------------------
export const deriveWelcomeBonusEntities = createSelector(
	[welcomeBonusEntities],
	(entities) => entities
);

export const deriveWelcomeBonusList = createSelector(
	[deriveWelcomeBonusEntities],
	(entities) => orderBy(values(entities), ['statusID', 'id'], ['desc', 'asc'])
);

export const deriveWelcomeBonusFilter = createSelector(
	[welcomeBonusFilter],
	(filter) => filter
);

export const deriveWelcomeBonusData = createSelector(
	[welcomeBonusBonusData],
	(bonusData) => bonusData
);

export const deriveWelcomeBonusUI = createSelector(
	[welcomeBonusUI],
	(UI) => UI
);

export const deriveWelcomeCurrencyList = createSelector(
	[welcomeBonusCurrencyList],
	(currencyList) => currencyList
);

// CasinoCashback ----------------------------------------------------------------------------------
export const deriveCashbackEntities = createSelector(
	[cashbackEntities],
	(entities) => entities
);

export const deriveCashbackList = createSelector(
	[deriveCashbackEntities],
	(entities) => orderBy(values(entities), ['id'], ['desc'])
);

export const deriveCashbackFilter = createSelector(
	[cashbackFilter],
	(filter) => filter
);

export const deriveCashbackBonusData = createSelector(
	[cashbackBonusData],
	(bonusData) => bonusData
);

export const deriveCashbackUI = createSelector(
	[cashbackUI],
	(UI) => UI
);

export const deriveSubCashbackList = createSelector(
	[subCashbackList],
	(list) => list
);

export const deriveCashbackUsersEntities = createSelector(
	[cashbackUsersEntities],
	(entities) => entities
);

export const deriveCashbackUsersList = createSelector(
	[deriveCashbackUsersEntities],
	(entities) => orderBy(values(entities), ['id'], ['desc'])
);

export const deriveCashbackUsersFilter = createSelector(
	[cashbackUsersFilter],
	(filter) => filter
);

// User Bonus (free spins) ------------------------------------------------------------------------
export const deriveUserBonusData = createSelector(
	[userBonusData],
	(bonusData) => bonusData
);

export const deriveUserBonusBets = createSelector(
	[userBonusBets],
	(bets) => bets
);

export const deriveUserBonusDenominations = createSelector(
	[userBonusDenominations],
	(denominations) => denominations
);

export const deriveUserBonusUI = createSelector(
	[userBonusUI],
	(UI) => UI
);

export const deriveUserBonusLineAndDenominationsRequired = createSelector(
	[userBonusLineAndDenominationsRequired],
	(lineAndDenominationsRequired) => lineAndDenominationsRequired
);
// User Casino BonusingSystem ------------------------------------------------------------------------

export const deriveCasinoBonusesEntities = createSelector(
	[casinoBonusesEntities],
	(entities) => entities
);

export const deriveCasinoBonusesUI = createSelector(
	[casinoBonusesUI],
	(entities) => entities
);

export const deriveCasinoBonusesList = createSelector(
	[deriveCasinoBonusesEntities],
	(entities) => orderBy(values(entities), ['ID'], ['desc'])
);

// User Casino Free Spins ------------------------------------------------------------------------

export const deriveCasinoSpinsEntities = createSelector(
	[casinoSpinsEntities],
	(entities) => entities
);

export const deriveCasinoSpinsUI = createSelector(
	[casinoSpinsUI],
	(entities) => entities
);

export const deriveCasinoSpinsList = createSelector(
	[deriveCasinoSpinsEntities],
	(entities) => orderBy(values(entities), ['ID'], ['desc'])
);

/**
 * *User Free Bets
 */
export const deriveUserFreeBetsEntities = createSelector(
	[userBonusFreeBets],
	entities => entities
);

export const deriveUserFreeBetsList = createSelector(
	[deriveUserFreeBetsEntities],
	entities => orderBy(values(entities), ['ID'], ['desc'])
);

/**
 * *Free Bets
 */
export const deriveFreeBetsEntities = createSelector(
	[freeBetsEntities],
	(entities) => entities
);

export const deriveFreeBetsList = createSelector(
	[deriveFreeBetsEntities],
	(entitites) => orderBy(values(entitites), ['marketID'], ['desc'])
);

export {
	bonusesUI,
	freeSpinsBonusData,
	freeSpinLineAndDenominationsRequired,
	userBonusUI,
	userBonusData,
	userBonusLineAndDenominationsRequired,
};
