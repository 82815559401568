import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	depositIDs   : [],
	entities     : {},
	depositTotals: {},

	filter: {
		userID: 0,
	},

	UI: {
		loading: false,
	},
});

export default function rtmUserDepositsReducer(state = initState, action) {
	switch (action.type) {

		case actions.RTM_USER_DEPOSITS_DATA_REFRESH: {
			const { depositIDs, entities, depositTotals } = action.data;
			return state
				.set('depositIDs', depositIDs)
				.set('entities', entities)
				.set('depositTotals', depositTotals);
		}

		case actions.RTM_USER_DEPOSITS_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.RTM_USER_DEPOSITS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
