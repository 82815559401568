/* eslint-disable prefer-destructuring */
import merge from 'lodash/merge';
import findIndex from 'lodash/findIndex';
import initial from 'lodash/initial';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';

// Getters ----------------------------------------------------------------------------------------
export function getNodePosIndexes(nodePos, removeFirst = true) {
	// nodePos is like '0-1-0-3-12', where first '0' is useless
	let nodeIndexes = nodePos.split('-');
	if (removeFirst) {
		nodeIndexes.shift();
	}
	nodeIndexes = nodeIndexes.map(item => {
		return Number(item);
	});
	return nodeIndexes;
}

export function getNodeLevel(nodePos) {
	const nodeIndexes = getNodePosIndexes(nodePos);
	return nodeIndexes.length;
}

export function getTargetNode(treeData, nodePos) {

	// nodePos is like '0-1-0-3-12', where first '0' is useless
	const nodeIndexes = getNodePosIndexes(nodePos);
	const level       = nodeIndexes.length;
	if (!level) {
		return null;
	}

	const source = { treeData };
	let path = '';
	nodeIndexes.forEach((i, index) => {
		if (index === 0) {
			path += `treeData[${i}]`;
		} else {
			path += `.children[${i}]`;
		}
	});

	const targetNode = get(source, path, null);

	return targetNode;
}

export function getParentNode(treeData, nodePos) {
	const nodeIndexes = getNodePosIndexes(nodePos);
	nodeIndexes.pop();
	if (nodeIndexes.length === 0) {
		return null;
	}
	const parentNodePos = `0-${nodeIndexes.join('-')}`;
	const parentNode = getTargetNode(treeData, parentNodePos);

	return parentNode;
}

export function getNextPos(treeData, nodePos, step = 1) {

	const nodeIndexes = getNodePosIndexes(nodePos, false);
	const lastIndex = nodeIndexes.length - 1;

	nodeIndexes[lastIndex] += step;
	const nextPos  = nodeIndexes.join('-');
	const nextNode = getTargetNode(treeData, nextPos);

	return (nextNode) ? nextPos : nodePos;
}

export function getPreviousPos(treeData, nodePos, step = 1) {

	const nodeIndexes  = getNodePosIndexes(nodePos, false);
	const lastIndex    = nodeIndexes.length - 1;
	const currentValue = last(nodeIndexes);
	if (currentValue === 0) {
		return nodePos;
	}

	nodeIndexes[lastIndex] -= step;
	const previousPos  = nodeIndexes.join('-');
	const previousNode = getTargetNode(treeData, previousPos);

	return (previousNode) ? previousPos : nodePos;
}

export function getFirstPos(treeData, nodePos) {

	const nodeIndexes  = getNodePosIndexes(nodePos, false);
	const lastIndex    = nodeIndexes.length - 1;

	nodeIndexes[lastIndex] = 0;
	const firstPos  = nodeIndexes.join('-');
	const firstNode = getTargetNode(treeData, firstPos);

	return (firstNode) ? firstPos : nodePos;
}

export function getLastPos(treeData, nodePos) {

	const parentNode  = getParentNode(treeData, nodePos);
	const isRootLevel = !parentNode;
	const nodes       = isRootLevel ? treeData : parentNode.children;

	const nodeIndexes  = getNodePosIndexes(nodePos, false);
	const lastIndex    = nodeIndexes.length - 1;

	nodeIndexes[lastIndex] = nodes.length - 1;
	const lastPos  = nodeIndexes.join('-');
	const lastNode = getTargetNode(treeData, lastPos);

	return (lastNode) ? lastPos : nodePos;
}

export function getNextNode(treeData, nodePos) {
	const nextPos  = getNextPos(treeData, nodePos);
	const nextNode = getTargetNode(treeData, nextPos);

	return nextNode;
}

export function getPreviousNode(treeData, nodePos) {
	const previousPos  = getPreviousPos(treeData, nodePos);
	const previousNode = getTargetNode(treeData, previousPos);

	return previousNode;
}

export function getFirstNode(treeData, nodePos) {
	const firstPos  = getFirstPos(treeData, nodePos);
	const firstNode = getTargetNode(treeData, firstPos);

	return firstNode;
}

export function getLastNode(treeData, nodePos) {
	const lastPos  = getLastPos(treeData, nodePos);
	const lastNode = getTargetNode(treeData, lastPos);

	return lastNode;
}

export function getNodeIndex(nodePos) {
	const nodeIndexes = getNodePosIndexes(nodePos);
	return last(nodeIndexes);
}

// Setters/Tree Update ---------------------------------------------------------------------------------
export function updateNodeInTreeData(treeData, nodePos, node) {
	const targetNode = getTargetNode(treeData, nodePos);
	merge(targetNode, node);
}

export function updateNodePropertiesChildren(treeData, nodePos, nodeProps) {
	const targetNode = getTargetNode(treeData, nodePos);
	merge(targetNode, nodeProps);

	if (targetNode.children) {
		updateNodeChildrenProperties(targetNode.children, nodePos);
	}
}

export function updateNodeChildrenProperties(children, nodePos) {
	children.forEach(item => {
		merge(item, nodePos);
		if (item.children) {
			updateNodeChildrenProperties(item.children, nodePos);
		}
	});
}

export function deleteNodeFromTree(treeData, nodePos) {
	const node = getTargetNode(treeData, nodePos);
	const nodeID = node.id;

	const parentNode = getParentNode(treeData, nodePos);
	const root = parentNode ? parentNode.children : treeData;

	const nodeIndex = findIndex(root, item => {
		return item.id === nodeID;
	});

	root.splice(nodeIndex, 1);
}

export function moveNode(treeData, targetPos, sourcePos, setAfterTarget = false) {

	const result      = cloneDeep(treeData);
	const parentNode  = getParentNode(result, targetPos);
	const isRootLevel = !parentNode;
	const nodes       = isRootLevel ? result : cloneDeep(parentNode.children);

	const targetIndex = getNodeIndex(targetPos);
	const sourceIndex = getNodeIndex(sourcePos);
	const sourceNode  = getTargetNode(result, sourcePos);

	if (targetIndex < sourceIndex) {
		nodes.splice(sourceIndex, 1);
		nodes.splice(targetIndex, 0, sourceNode);
	} else {
		nodes.splice(targetIndex, 0, sourceNode);
		nodes.splice(sourceIndex, 1);
	}
	if (setAfterTarget) {
		const currentIndex = findIndex(nodes, { id: sourceNode.id });
		const nextIndex    = currentIndex + 1;
		const replacedNode = nodes[nextIndex];

		nodes[nextIndex]    = sourceNode;
		nodes[currentIndex] = replacedNode;
	}

	if (isRootLevel) {
		return nodes;
	}
	parentNode.children = nodes;

	return result;
}

// Service ----------------------------------------------------------------------------------------
export function isNodeExist(nodeArray, node) {
	const existNode = nodeArray.find(item => {
		return item.id === node.id;
	});

	if (existNode) {
		return true;
	}
	return false;
}

export function hasSameParents(targetNodePos, sourceNodePos) {

	const targetIndexes = initial(targetNodePos.split('-'));
	const sourceIndexes = initial(sourceNodePos.split('-'));

	const targetParentPos = targetIndexes.join('-');
	const sourceParentPos = sourceIndexes.join('-');

	return (targetParentPos === sourceParentPos);
}
