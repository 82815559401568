import { makeActionCreator } from '../../helpers';

const prefix = 'DASHBOARD_COMMON';

const TYPES = {
	PARTNERS_LIST_REFRESH: `${prefix}_PARTNERS_LIST_REFRESH`,
	PERIOD_REFRESH       : `${prefix}_PERIOD_REFRESH`,
	UI_REFRESH           : `${prefix}_UI_REFRESH`,
};

const actions = {
	...TYPES,

	partnersListRefresh: makeActionCreator(TYPES.PARTNERS_LIST_REFRESH, 'partnersList'),
	periodRefresh      : makeActionCreator(TYPES.PERIOD_REFRESH, 'period'),
	uiRefresh          : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
};

export default actions;
