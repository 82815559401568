import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	typeList: [],

	modalUI: {
		visible     : false,
		loading     : false,
		sportID     : null,
		categoryID  : null,
		categoryName: null,
		closeModal  : false,
		isChanged   : false,
	},
});

export default function regulationTypesReducer(state = initState, action) {

	switch (action.type) {

		case actions.CATEGORY_REGULATION_TYPES_DATA_RESET: {
			return initState;
		}

		case actions.CATEGORY_REGULATION_TYPES_DATA_REFRESH: {
			return state.set('typeList', action.data);
		}

		case actions.CATEGORY_REGULATION_TYPES_MODAL_UI_REFRESH: {
			const target = state.get('modalUI');
			const result = fill(action.data, target);
			return state.set('modalUI', result);
		}

		default:
			return state;
	}
}
