import { EVENT_TYPES } from '../codes';
import { emitEvent } from '../socket';

function subscribeToUpdate(eventID) {
	const event = {
		subjectType: EVENT_TYPES,
		id         : eventID,
	};

	return emitEvent('subscribe', event);
}

export const eventMarketsAPI = {
	subscribeToUpdate,
};

function subscribeExistIDs(ids, notifType) {

	const event = {
		notifType,
		ids,
	};

	return emitEvent('subscribe', event);
}

export const eventAPI = {
	subscribeToUpdate,
	subscribeExistIDs,
};
