import { all, fork } from 'redux-saga/effects';

import smsGatewaySaga from './smsGateway/saga';
import smsTemplatesSaga from './smsTemplates/saga';

export default function* websitesSaga() {
	yield all([
		fork(smsGatewaySaga),
		fork(smsTemplatesSaga),
	]);
}
