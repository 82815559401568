import React from 'react';
import { Alert } from 'antd';
import { ErrorBoundaryContainer, ErrorDescriptionWrapper } from './ErrorBoundary.style';
import Button from '../uielements/button';
import IntlMessages from '../utility/intlMessages';
import { logger } from '../../helpers/logger';

const lang = {
	errorDescription: <IntlMessages id="errorboundary.description"/>,
	errorTitle      : <IntlMessages id="errorboundary.title"/>,
	reload          : <IntlMessages id="errorboundary.reload"/>,
};
export class ErrorBoundary extends React.Component<{ children: React.ReactNode; id?: string }, { hasError: boolean }> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.onReload = this.onReload.bind(this);
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}


	componentDidCatch(error, errorInfo) {
		logger.log(error, errorInfo);
	}

	onReload() {
		window.location.reload();
	}
	render() {
		if (this.state.hasError) {
			logger.log('Error boundary ID: ',this.props.id);
			return (
				<ErrorBoundaryContainer>
					<Alert
						message="Error"
						description={(
							<ErrorDescriptionWrapper>
								<h1> {lang.errorTitle}</h1>
								<p>{lang.errorDescription}</p>
								<Button onClick={this.onReload} type="primary" >
									{lang.reload}
								</Button>
							</ErrorDescriptionWrapper>
						)}
						type="error"
						showIcon
					/>

				</ErrorBoundaryContainer>
			);
		}

		return this.props.children;
	}
}
