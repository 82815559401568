import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
  width: 100%;
		
		.ant-spin-dot {
				//width: 1.7em;
				//height: 1.7em;
		}
`;

