import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const CmsIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_143)">
				<path {...props} d="M12.6875 14.5625H11.1501L10.8376 13.625H5.16235L4.84985 14.5625H3.3125C3.05347 14.5625 2.84375 14.7722 2.84375 15.0312C2.84375 15.2903 3.05347 15.5 3.3125 15.5H12.6875C12.9465 15.5 13.1562 15.2903 13.1562 15.0312C13.1562 14.7722 12.9465 14.5625 12.6875 14.5625Z" fill={color} />
				<path {...props} d="M12.6875 3.3125C12.4288 3.3125 12.2188 3.52258 12.2188 3.78125C12.2188 4.03992 12.4288 4.25 12.6875 4.25C12.9462 4.25 13.1562 4.03992 13.1562 3.78125C13.1562 3.52258 12.9462 3.3125 12.6875 3.3125Z" fill={color} />
				<path {...props} d="M14.5625 0.5H1.40625C0.630859 0.5 0 1.13086 0 1.90625V9.875H2.84375V8.46875C2.84375 8.20972 3.05347 8 3.3125 8H4.03674C4.08521 7.86365 4.14062 7.72986 4.20337 7.59851L3.69104 7.08582C3.50793 6.90271 3.50793 6.60608 3.69104 6.42297L5.01672 5.09729C5.19983 4.91418 5.49646 4.91418 5.67957 5.09729L6.19226 5.60999C6.32361 5.54724 6.45728 5.49146 6.59375 5.44287V4.71875C6.59375 4.45972 6.80347 4.25 7.0625 4.25H8.9375C9.19653 4.25 9.40625 4.45972 9.40625 4.71875V5.44299C9.5426 5.49146 9.67639 5.54736 9.80774 5.60999L10.3204 5.09729C10.5035 4.91431 10.8002 4.91431 10.9833 5.09729L12.309 6.42297C12.4921 6.60608 12.4921 6.90283 12.309 7.08582L11.7966 7.59851C11.8594 7.72998 11.9148 7.86365 11.9633 8H12.6875C12.9465 8 13.1562 8.20972 13.1562 8.46875V9.875H16V1.90625C16 1.13086 15.3379 0.5 14.5625 0.5ZM12.6875 5.1875C11.9121 5.1875 11.2812 4.55664 11.2812 3.78125C11.2812 3.00586 11.9121 2.375 12.6875 2.375C13.4629 2.375 14.0938 3.00586 14.0938 3.78125C14.0938 4.55664 13.4629 5.1875 12.6875 5.1875Z" fill={color} />
				<path {...props} d="M6.59375 9.40625C6.59375 9.56824 6.6311 9.72449 6.68469 9.875H9.31531C9.3689 9.72449 9.40625 9.56824 9.40625 9.40625C9.40625 8.63086 8.77539 8 8 8C7.22449 8 6.59375 8.63086 6.59375 9.40625Z" fill={color} />
				<path {...props} d="M4.83679 8.58594C4.78333 8.79285 4.59656 8.9375 4.38269 8.9375H3.78125V9.875H5.7179C5.6864 9.72058 5.65625 9.56543 5.65625 9.40625C5.65625 8.11401 6.70776 7.0625 8 7.0625C9.29224 7.0625 10.3438 8.11401 10.3438 9.40625C10.3438 9.56543 10.3136 9.72058 10.2821 9.875H12.2188V8.9375H11.6173C11.4034 8.9375 11.2167 8.79285 11.1632 8.58594C11.0885 8.29529 10.9718 8.01416 10.8162 7.75049C10.7076 7.56653 10.7374 7.33215 10.8884 7.18103L11.3147 6.75439L10.6519 6.09155L10.2252 6.51819C10.0741 6.6698 9.83923 6.69995 9.65527 6.59009C9.39209 6.43494 9.11145 6.31775 8.82031 6.24304C8.6134 6.18958 8.46875 6.00281 8.46875 5.78894V5.1875H7.53125V5.78894C7.53125 6.00281 7.3866 6.18958 7.17969 6.24304C6.88855 6.31775 6.60791 6.43494 6.34473 6.59009C6.16162 6.69995 5.92627 6.6698 5.77478 6.51819L5.34814 6.09155L4.6853 6.75439L5.11157 7.18103C5.26257 7.33215 5.29236 7.56653 5.18384 7.75049C5.0282 8.01416 4.9115 8.29529 4.83679 8.58594Z" fill={color} />
				<path {...props} d="M0 11.2812C0 12.0566 0.630859 12.6875 1.40625 12.6875H14.5625C15.3379 12.6875 16 12.0566 16 11.2812V10.8125H0V11.2812Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_143">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
CmsIcon.propTypes = propTypesIcons;

CmsIcon.defaultProps = defaultPropsIcons;
