import { makeActionCreator } from '../../../helpers';

const prefix = 'USER_CASINO_FREESPINS';

const TYPES = {
	LIST_RELOAD       : `${prefix}_LIST_RELOAD`,
	LIST_REFRESH      : `${prefix}_LIST_REFRESH`,
	CANCEL_FREE_SPIN  : `${prefix}_CANCEL`,
	UI_MERGE          : `${prefix}_UI_MERGE`,
	FILTER_APPLY      : `${prefix}_FILTER_APPLY`,
	PAGINATION_REFRESH: `${prefix}_PAGINATION_REFRESH`,
};

const actions = {
	...TYPES,

	listReload       : makeActionCreator(TYPES.LIST_RELOAD, 'userID'),
	listRefresh      : makeActionCreator(TYPES.LIST_REFRESH, 'entities'),
	cancelFreeSpin   : makeActionCreator(TYPES.CANCEL_FREE_SPIN, 'data'),
	uiMerge          : makeActionCreator(TYPES.UI_MERGE, 'data'),
	filterApply      : makeActionCreator(TYPES.FILTER_APPLY),
	paginationRefresh: makeActionCreator(TYPES.PAGINATION_REFRESH, 'data'),
};

export default actions;
