import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';

import { CHANNEL_TYPES } from '../../../helpers/commonConstants';
import { channelTypeList } from '../../../helpers/commonEnums';
import { isID } from '../../../helpers/utils';
import EntityAdapter from '../../../helpers/entityAdapter';

const fields = {
	id      : 'id',
	name    : 'name',
	marketID: 'market_id',
	rowIndex: 'row_index',
	colIndex: 'column_index',
	statusID: 'status_id',

	userWonLimit        : 'user_won_limit',
	userWonLimitTypeID  : 'user_won_limit_value_type',
	userWonLimitOverride: 'override_user_won_limit',

	userStakeLimit        : 'user_stake_limit',
	userStakeLimitTypeID  : 'user_stake_limit_value_type',
	userStakeLimitOverride: 'override_user_stake_limit',

	betDelay            : 'bet_delay',
	allowMixedMultiplies: 'allow_mixed_multiplies',

	// names & orders
	langID   : 'lang_id',
	altName1 : 'alt_name_1',
	channelID: 'channel_id',

	// dynamic limits
	tradingModeID: 'trading_mode',
	minutesBefore: 'minutes_before',
	limit        : 'limit',

	// settings
	stakeLimitParent  : 'bet_stake_limit_parent',
	stakeLimit        : 'bet_stake_limit',
	stakeLimitTypeID  : 'bet_stake_limit_value_type',
	stakeLimitOverride: 'override_stake_limit',

	wonLimitParent  : 'bet_won_limit_parent',
	wonLimit        : 'bet_won_limit',
	wonLimitTypeID  : 'bet_won_limit_value_type',
	wonLimitOverride: 'override_won_limit',

	overask             : 'overask',
	overaskStakeLimitMin: 'overask_stake_min_limit',
	overaskStakeLimitMax: 'overask_stake_max_limit',
	overaskWonLimitMin  : 'overask_won_min_limit',
	overaskWonLimitMax  : 'overask_won_max_limit',
};

export function adaptBaseData(rawData = {}) {
	const adapter = getBaseDataAdapter();
	const result = adapter.adapt(rawData);

	// additional fields
	result.id = toInteger(rawData.id);

	return result;
}

export function prepareBaseData(rawData, selectionID = null) {
	const adapter = getBaseDataAdapter();
	const result = adapter.prepare(rawData);

	// additional fields
	if (isID(selectionID)) {
		result.id = toInteger(selectionID);
	}

	return result;
}

export function adaptNamesData(rawData = [], defaultName = null) {
	const result = [];

	channelTypeList.forEach(channelItem => {
		const channelID = channelItem.id;
		const channelName = channelItem.name;

		const namesItem =      rawData.find(item => toInteger(item[fields.channelID]) === channelID)
      || {};

		const record = {
			channelID,
			channel : channelName,
			name    : namesItem[fields.name],
			altName1: namesItem[fields.altName1],
		};

		if (channelID === CHANNEL_TYPES.backend && !record.name && defaultName) {
			record.name = defaultName;
		}

		result.push(record);
	});

	return result;
}

export function prepareNamesData(rawData = [], langID) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = [];

	rawData.forEach(namesItem => {
		if (!namesItem.name) {
			return;
		}

		result.push({
			[fields.channelID]: namesItem.channelID,
			[fields.name]     : namesItem.name,
			[fields.altName1] : namesItem.altName1,
			[fields.langID]   : langID,
		});
	});

	return result;
}

export function adaptOrdersData(rawData = []) {
	const result = [];

	channelTypeList.forEach(channelItem => {
		const channelID = channelItem.id;
		const ordersItem =      rawData.find(item => toInteger(item[fields.channelID]) === channelID)
      || {};

		const record = {
			channelID,
			rowIndex: toInteger(ordersItem[fields.rowIndex]),
			colIndex: toInteger(ordersItem[fields.colIndex]),
		};

		result.push(record);
	});

	return result;
}

export function prepareOrdersData(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = [];

	rawData.forEach(oredersItem => {
		if (!isNumber(oredersItem.rowIndex) || !isNumber(oredersItem.colIndex)) {
			return;
		}

		result.push({
			[fields.channelID]: oredersItem.channelID,
			[fields.rowIndex] : toInteger(oredersItem.rowIndex),
			[fields.colIndex] : toInteger(oredersItem.colIndex),
		});
	});

	return result;
}

export function adaptSettingsData(rawServerData) {
	const adapter = getSettingsDataAdapter();
	return adapter.adapt(rawServerData);
}

export function prepareSettingsData(settings) {
	if (isEmpty(settings)) {
		return {};
	}

	const adapter = getSettingsDataAdapter();

	adapter.addExcludeField('stakeLimitParent');
	adapter.addExcludeField('wonLimitParent');
	return adapter.prepare(settings);
}

function getBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'marketID', fields.marketID);
	adapter.addField(rules.id, 'statusID', fields.statusID);


	adapter.addField(rules.string, 'name', fields.name);

	adapter.addField(rules.bool, 'allowMixedMultiplies', fields.allowMixedMultiplies);

	adapter.addField(rules.positiveNumber, 'betDelay', fields.betDelay);

	adapter.addField(rules.positiveNumber, 'userWonLimit', fields.userWonLimit);
	// adapter.addField(rules.positiveNumber, 'userWonLimitLocal', fields.userWonLimitLocal);
	adapter.addField(rules.id, 'userWonLimitTypeID', fields.userWonLimitTypeID);
	adapter.addField(rules.bool, 'userWonLimitOverride', fields.userWonLimitOverride);

	adapter.addField(rules.positiveNumber, 'userStakeLimit', fields.userStakeLimit);
	// adapter.addField(rules.positiveNumber, 'userStakeLimitLocal', fields.userStakeLimitLocal);
	adapter.addField(rules.id, 'userStakeLimitTypeID', fields.userStakeLimitTypeID);
	adapter.addField(rules.bool, 'userStakeLimitOverride', fields.userStakeLimitOverride);

	return adapter;
}

function getSettingsDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'tradingModeID', fields.tradingModeID);

	adapter.addField(rules.id, 'stakeLimitTypeID', fields.stakeLimitTypeID);
	adapter.addField(rules.positiveNumber, 'stakeLimit', fields.stakeLimit);
	adapter.addField(rules.positiveNumber, 'stakeLimitParent', fields.stakeLimitParent);
	adapter.addField(rules.bool, 'stakeLimitOverride', fields.stakeLimitOverride);

	adapter.addField(rules.id, 'wonLimitTypeID', fields.wonLimitTypeID);
	adapter.addField(rules.positiveNumber, 'wonLimit', fields.wonLimit);
	adapter.addField(rules.positiveNumber, 'wonLimitParent', fields.wonLimitParent);
	adapter.addField(rules.bool, 'wonLimitOverride', fields.wonLimitOverride);

	adapter.addField(rules.bool, 'overask', fields.overask);
	adapter.addField(rules.positiveNumber, 'overaskStakeLimitMin', fields.overaskStakeLimitMin);
	adapter.addField(rules.positiveNumber, 'overaskStakeLimitMax', fields.overaskStakeLimitMax);
	adapter.addField(rules.positiveNumber, 'overaskWonLimitMin', fields.overaskWonLimitMin);
	adapter.addField(rules.positiveNumber, 'overaskWonLimitMax', fields.overaskWonLimitMax);

	return adapter;
}
