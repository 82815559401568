import isArray from 'lodash/isArray';
import EntityAdapter from '../../../helpers/entityAdapter';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import QueryToFilterValidaion from '../../../helpers/queryToFilterValidaion';

const { isValidID, isValidArray, isValidDateRange, isValidString } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	id         : isValidID,
	statusID   : isValidID,
	name       : isValidString,
	currencyID : isValidArray,
	startDate  : isValidDateRange,
	endDate    : isValidDateRange,
	publishDate: isValidDateRange,
};

export const fields = {
	id         : 'id',
	name       : 'name',                // string;
	statusID   : 'status_id',           // BonusStatus;
	published  : 'published',           // BonusStatus;
	currencyIDs: 'currency_ids',         // number;
	startDate  : 'start',          // Date;
	endDate    : 'end',          // Date;
	publishDate: 'publish',          // Date;

};

export const integratorFields = {
	id          : 'id',
	integratorID: 'integrator_id',
	providerID  : 'provider_id',
	providers   : 'providers',
	name        : 'name',
};

const listAdapter = createListAdapter();
const integratorAdapter = createIntegratorAdapter();
const providerAdapter = createProviderAdapter();

export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'statusID', fields.statusID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);
	builder.addField(rules.isID, 'casinoGameID', fields.casinoGameID);
	builder.addField(rules.isID, 'bonusType', fields.bonusType);

	builder.addField(rules.isString, 'name', fields.name);
	builder.addField(rules.isBoolean, 'overlap', fields.overlap);
	builder.addField(rules.isNumber, 'way', fields.way);

	builder.addField(rules.isString, 'affiliateReference', fields.affiliateReference);
	// builder.addField(rules.isPositiveNumber, 'amount', fields.amount);
	builder.addRangeField(rules.isNumberRange, filter.amount, [fields.amountFrom, fields.amountTo]);

	builder.addRangeField(rules.isDateTimeRange, filter.startDate, [
		fields.startDateFrom,
		fields.startDateTo,
	]);

	builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
		fields.endDateFrom,
		fields.endDateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptList(rawList = []) {
	if (!isArray(rawList)) {
		return {};
	}

	const adaptedData = listAdapter.adaptList(rawList);
	return adaptedData;

}


export function adaptTournament(rawData = {}) {
	// const { timePeriod, tournament } = rawData;
	// const normalized = Object.assign({
	// 	tournament_id: tournament.id,
	// }, timePeriod, tournament );
	const adaptedData = listAdapter.adapt(rawData);
	const settings = {
		tournamentName: adaptedData.name,
	};

	const main = {
		startEndDate: [adaptedData.startDate, adaptedData.endDate],
		publishDate : adaptedData.publishDate,
	};

	return {
		settings,
		main,
	};

}


export function adaptIntegrators(rawList = []) {
	if (!isArray(rawList)) {
		return {};
	}

	// listAdapter.clearExcludes();
	const adaptedData = integratorAdapter.adaptList(rawList);
	return adaptedData.map(integrator => {
		integrator.providers = providerAdapter.adaptList(integrator.providers);
		return integrator;
	});
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.bool, 'published', fields.published);
	adapter.addField(rules.arrayID, 'currencyIDs', fields.currencyIDs);
	adapter.addField(rules.dateTime, 'startDate', fields.startDate);
	adapter.addField(rules.dateTime, 'endDate', fields.endDate);
	adapter.addField(rules.dateTime, 'publishDate', fields.publishDate);

	return adapter;
}

export function createIntegratorAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', integratorFields.id);
	adapter.addField(rules.string, 'name', integratorFields.name);
	adapter.addField(rules.arrayObject, 'providers', integratorFields.providers);

	return adapter;
}
export function createProviderAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'integratorID', integratorFields.integratorID);
	adapter.addField(rules.string, 'name', integratorFields.name);
	adapter.addField(rules.id, 'providerID', integratorFields.providerID);

	return adapter;
}


export const delay = (ms) => {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve();
		}, ms);
	});
};
