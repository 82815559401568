import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const DashboardIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_4)">
				<path {...props} fillRule="evenodd" clipRule="evenodd" d="M5.625 0H1.875C0.841125 0 0 0.841125 0 1.875V6.875C0 7.90887 0.841125 8.75 1.875 8.75H5.625C6.65887 8.75 7.5 7.90887 7.5 6.875V1.875C7.5 0.841125 6.65887 0 5.625 0Z" fill={color} />
				<path {...props} d="M14.125 0H10.375C9.34113 0 8.5 0.841125 8.5 1.875V4.375C8.5 5.40887 9.34113 6.25 10.375 6.25H14.125C15.1589 6.25 16 5.40887 16 4.375V1.875C16 0.841125 15.1589 0 14.125 0Z" fill={color} />
				<path {...props} d="M14.125 7.25H10.375C9.34113 7.25 8.5 8.09113 8.5 9.125V14.125C8.5 15.1589 9.34113 16 10.375 16H14.125C15.1589 16 16 15.1589 16 14.125V9.125C16 8.09113 15.1589 7.25 14.125 7.25Z" fill={color} />
				<path {...props} d="M5.625 9.75H1.875C0.841125 9.75 0 10.5911 0 11.625V14.125C0 15.1589 0.841125 16 1.875 16H5.625C6.65887 16 7.5 15.1589 7.5 14.125V11.625C7.5 10.5911 6.65887 9.75 5.625 9.75Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_4">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
DashboardIcon.propTypes = propTypesIcons;

DashboardIcon.defaultProps = defaultPropsIcons;
