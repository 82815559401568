
import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const SportIcon = (props) => {
	const { color, width,  height, viewBox } = props;

	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_98)">
				<path {...props} d="M8.65125 1.29243C8.83222 1.05258 8.80312 0.543852 8.77736 0.0950824C8.77638 0.0757386 8.7753 0.0568203 8.77422 0.0376075C8.52056 0.0133868 8.26378 0 8.0039 0C7.00807 0 6.05522 0.183455 5.17578 0.516031C5.80313 0.817513 6.77421 1.33642 7.8536 2.10314C8.22189 1.78621 8.49964 1.49363 8.65125 1.29243Z" fill={color} />
				<path {...props} d="M12.7046 5.85732C12.3103 5.90187 11.9778 5.96658 11.6934 6.05734C13.0529 8.43103 13.583 11.4299 13.7127 13.6025C15.0595 12.2301 15.9149 10.3749 15.9958 8.31997C15.0677 7.17925 13.8383 5.72872 12.7046 5.85732Z" fill={color} />
				<path {...props} d="M4.62259 3.80841C5.57783 3.60964 6.47527 3.12886 7.19083 2.62137C5.69805 1.59324 4.45298 1.05966 4.20531 0.958099C2.65659 1.79532 1.41728 3.12925 0.696289 4.74552C1.63343 4.37213 2.64838 4.18216 3.64525 3.99707C3.96775 3.93717 4.30111 3.87528 4.62259 3.80841Z" fill={color} />
				<path {...props} d="M10.1519 4.04102C10.2371 4.12671 10.3222 4.21387 10.4066 4.30267C10.7182 4.62981 11.001 4.98762 11.2603 5.36619C11.6379 5.22361 12.0824 5.12251 12.6146 5.06235C13.8684 4.92053 14.9833 5.91374 15.9401 7.0072C15.735 5.34956 15.024 3.84929 13.9647 2.66611C13.3865 2.73579 12.7419 2.85385 12.0848 3.04991C11.6538 3.17864 10.9674 3.5284 10.1519 4.04102Z" fill={color} />
				<path {...props} d="M9.09952 8.17244C9.42764 7.06107 9.80771 6.25992 10.5441 5.7399C10.3231 5.42264 10.0853 5.12489 9.82764 4.85441C9.70847 4.72924 9.58796 4.60837 9.46692 4.48962C6.51256 6.50056 2.46565 10.1946 2.09766 13.3952C3.32604 14.7392 5.00715 15.6608 6.89963 15.9223C7.58871 14.5504 8.23726 11.7615 8.62277 10.1028C8.80799 9.3061 8.96784 8.6182 9.09952 8.17244Z" fill={color} />
				<path {...props} d="M5.7496 6.41005C6.7797 5.46083 7.86753 4.60964 8.86251 3.92554C8.53085 3.63211 8.19818 3.36162 7.87159 3.11356C7.04518 3.73478 5.96317 4.34665 4.78539 4.59161C4.45556 4.66024 4.11782 4.72299 3.7913 4.7836C2.5332 5.01723 1.3255 5.2435 0.309872 5.81017C0.112081 6.50638 0.00390625 7.2404 0.00390625 8.00001C0.00390625 9.69928 0.535386 11.2737 1.43878 12.5693C2.02404 10.2521 4.03147 7.99326 5.7496 6.41005Z" fill={color} />
				<path {...props} d="M9.55182 3.46864C10.5036 2.86188 11.3241 2.44233 11.8564 2.28343C12.3345 2.14066 12.8035 2.03667 13.2476 1.96083C12.2214 1.06909 10.967 0.433526 9.58219 0.156494C9.61289 0.715206 9.62628 1.32861 9.29017 1.77427C9.13047 1.98591 8.86032 2.27616 8.50879 2.59106C8.85305 2.85903 9.20291 3.15125 9.55182 3.46864Z" fill={color} />
				<path {...props} d="M9.86651 8.39896C9.74161 8.8221 9.58404 9.49952 9.40177 10.2838C8.86354 12.5997 8.34853 14.6719 7.74707 15.9934C7.83246 15.9962 7.91783 16 8.00387 16C9.86831 16 11.5822 15.3604 12.9424 14.2912C12.8887 12.0177 12.3437 8.80197 10.9708 6.41483C10.4383 6.81057 10.1518 7.43314 9.86651 8.39896Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_98">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
SportIcon.propTypes = propTypesIcons;

SportIcon.defaultProps = defaultPropsIcons;
