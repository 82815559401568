const actions = {
	LOGIN_REQUEST         : 'LOGIN_REQUEST',
	LOGOUT                : 'LOGOUT',
	LOGOUT_CASE           : 'LOGOUT_CASE',
	LOGIN_SUCCESS         : 'LOGIN_SUCCESS',
	LOGIN_ERROR           : 'LOGIN_ERROR',
	AUTH_USER_DATA_REFRESH: 'AUTH_USER_DATA_REFRESH',
	PERMISSIONS_REFRESH   : 'PERMISSIONS_REFRESH',
	UI_REFRESH            : 'UI_REFRESH',

	login: (authData, checked) => ({
		type: actions.LOGIN_REQUEST,
		data: authData,
		checked,
	}),
	loginSucces: (token, user, permissions, checked) => ({
		type: actions.LOGIN_SUCCESS,
		data: {
			token,
			user,
			permissions,
			checked,
		},
	}),
	loginError: () => ({
		type: actions.LOGIN_ERROR,
	}),
	logout: () => ({
		type: actions.LOGOUT,
	}),
	logoutCase: () => ({
		type: actions.LOGOUT_CASE,
	}),
	userDataRefresh: userData => ({
		type: actions.AUTH_USER_DATA_REFRESH,
		data: {
			userData,
		},
	}),
	permissionsRefresh: permissions => ({
		type: actions.PERMISSIONS_REFRESH,
		data: { permissions },
	}),
	uiRefresh: data => {
		return {
			type: actions.UI_REFRESH,
			data,
		};
	},
};

export default actions;
