import { combineReducers } from 'redux';

import Common from './common/reducer';
import Players from './players/reducer';
import Deposits from './deposits/reducer';
import PlacedBets from './placedBets/reducer';
import Withdrawals from './withdrawals/reducer';
import BetsByProvider from './betsByProvider/reducer';
import Overall from './overall/reducer';
import MarketingChart from './marketingChart/reducer';
import UsersChart from './usersChart/reducer';

export default combineReducers({
	Common,
	Players,
	Deposits,
	PlacedBets,
	BetsByProvider,
	Withdrawals,
	Overall,
	MarketingChart,
	UsersChart,
});
