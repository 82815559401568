import antdRu from 'antd/lib/locale/ru_RU';   // current version antd & react-intl does not contain locale UA
// import appLocaleData from 'react-intl/locale-data/ru'; // this is fallback
import cloneDeep from 'lodash/cloneDeep';
import uaMessages from '../locales/ua_UA.json';

const antdUA = cloneDeep(antdRu);
antdUA.locale = 'ua';

const UaLang = {
	messages: {
		...uaMessages,
	},
	antd  : antdRu,
	locale: 'ru-UA',
	// data: appLocaleData,
};
export default UaLang;
