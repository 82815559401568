import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import notifications from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { getHeadersTotalCount } from '../../../../helpers/utils';

import { getListParams, adaptCasinoBonusesList } from './utils';
import { logger } from '../../../../helpers/logger';

const messages = {
	errorListLoad: 'freespins.list.load.fail',
};

function getStoreData({ Loyalty: { Bonuses } }) {
	const { UserCasinoBonuses, UserCasinoBonusesFilter } = Bonuses;

	return {
		pagination: UserCasinoBonuses.get('pagination'),
		filter    : UserCasinoBonusesFilter.get('filter'),
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* (action) {

		yield put(actions.uiMerge({ loading: true }));

		const { pagination, filter } = yield select(getStoreData);
		const { userID } = action.data;
		const params = getListParams(pagination, filter);

		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(bonusesAPI.casinoBonusWallets, userID, params);
			if (res && res.status === 200) {
				entities   = adaptCasinoBonusesList(res.data.data, res.headers);
				totalCount = getHeadersTotalCount(res.headers);
			}

		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.paginationRefresh({ totalCount }));
		yield put(actions.uiMerge({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(actions.paginationRefresh({ currentPage: 1 }));
		yield put(actions.listReload());
	});
}

export default function* userCasinoBonusesSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
	]);
}
