import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData : {},
	namesData: {},
	seoData	 : {},
	tags     : [],

	UI: {
		visible       : false,
		casinoGameID  : null,
		casinoGameName: '',
		loading       : false,
		closeModal    : false,
		langID        : null,
		isBaseChanged : false,
		isNamesChanged: false,
		tags          : [],
		editMode      : false,
	},
});

export default function casinoGameModalReducer(state = initState, action) {


	switch (action.type) {

		case actions.CASINO_GAME_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}

		case actions.CASINO_GAME_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.CASINO_GAME_SEO_DATA_REFRESH: {
			return state.set('seoData', action.data);
		}

		case actions.CASINO_GAME_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.CASINO_GAME_BASE_DATA_RESET: {
			return state.set('baseData', initState.get('baseData'));
		}

		case actions.CASINO_GAME_DATA_RESET: {
			return initState;
		}

		case actions.CASINO_GAME_TAGS_SUCCESS: {
			return  state.set('tags', action.data);
		}

		default:
			return state;
	}
}
