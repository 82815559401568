import { apiRequest } from './index';

// get list of providers
export function getProviders() {
	const req = {
		method: 'GET',
		url   : '/common/providers',
	};

	return apiRequest(req);
}

export const providersAPI = {
	getProviders,
};
