import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import { categoriesAPI } from '../../helpers/api/categories';
import { providersAPI } from '../../helpers/api/providers';
import { usersAPI } from '../../helpers/api/users';
import { DISPLAY_STATUS } from '../../helpers/commonConstants';

import sportsActions from '../cms/landingPage/sportsPriorities/sports/actions';
import { adaptAdminList } from './utils';

// sports
export function* commonSportListReload() {

	yield takeEvery(actions.COMMON_SPORT_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const params = {
			display_status_id: DISPLAY_STATUS.visible,
		};
		try {
			const axiosResponse = yield call(categoriesAPI.getSports, params);
			if (axiosResponse && axiosResponse.status === 200) {
				const sportList = axiosResponse.data.data;
				yield put({
					type: actions.COMMON_SPORT_LIST_SUCCESS,
					data: sportList,
				});

				yield put(sportsActions.getSportsListSuccess(sportList));
			} else {
				yield put({ type: actions.COMMON_SPORT_LIST_ERROR });
			}
		} catch (error) {
			put({ type: actions.COMMON_SPORT_LIST_ERROR });
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

// providers
export function* commonProviderListReload() {

	yield takeEvery(actions.COMMON_PROVIDER_LIST_RELOAD, function* () {
		try {
			const axiosResponse = yield call(providersAPI.getProviders);
			if (axiosResponse && axiosResponse.status === 200) {
				const providerList = axiosResponse.data.data;
				yield put({
					type: actions.COMMON_PROVIDER_LIST_SUCCESS,
					data: providerList,
				});
			} else {
				yield put({ type: actions.COMMON_PROVIDER_LIST_ERROR });
			}
		} catch (error) {
			put({ type: actions.COMMON_PROVIDER_LIST_ERROR });
		}
	});
}

// risk groups
export function* commonRiskGroupListReload() {

	yield takeEvery(actions.COMMON_RISK_GROUP_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const axiosResponse = yield call(usersAPI.getRiskGroupsList);
			if (axiosResponse && axiosResponse.status === 200) {
				const riskGroupList = axiosResponse.data.data;
				yield put({
					type: actions.COMMON_RISK_GROUP_LIST_SUCCESS,
					data: riskGroupList,
				});
			} else {
				yield put({ type: actions.COMMON_RISK_GROUP_LIST_ERROR });
			}
		} catch (error) {
			put({ type: actions.COMMON_RISK_GROUP_LIST_ERROR });
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}
// admins
export function* commonAdminListReload() {

	yield takeEvery(actions.COMMON_ADMIN_LIST_RELOAD, function* () {

		const params = {
			unlimit: true,
		};
		try {
			const axiosResponse = yield call(usersAPI.adminsList, params);
			if (axiosResponse && axiosResponse.status === 200) {
				const adminList = adaptAdminList(axiosResponse.data.data);
				yield put({
					type: actions.COMMON_ADMIN_LIST_SUCCESS,
					data: adminList,
				});
			} else {
				yield put({ type: actions.COMMON_ADMIN_LIST_ERROR });
			}
		} catch (error) {
			put({ type: actions.COMMON_ADMIN_LIST_ERROR });
		}
	});
}

export default function* rootCommonSaga() {
	yield all([
		fork(commonSportListReload),
		fork(commonProviderListReload),
		fork(commonRiskGroupListReload),
		fork(commonAdminListReload),
	]);
}
