import { availableFields } from '../../../redux/reports/transactions/utils';

const lang = {
	pageTitle              : 'transactions.title' ,
	ID                     : 'transactions.columns.ID',
	typeID                 : 'transactions.columns.typeID',
	statusID               : 'transactions.columns.statusID',
	externalReferenceNumber: 'transactions.columns.externalReferenceNumber',
	user                   : 'transactions.columns.user',
	userID                 : 'transactions.columns.userID',
	moneyTypeID            : 'transactions.columns.moneyTypeID',
	amount                 : 'transactions.columns.amount',
	amountUSD              : 'transactions.columns.amountUSD',
	currencyID             : 'transactions.columns.currencyID',
	productID              : 'transactions.columns.productID',
	data                   : 'transactions.columns.data',
	balanceBefore          : 'transactions.columns.balanceBefore',
	balanceAfter           : 'transactions.columns.balanceAfter',
	channelID              : 'transactions.columns.channelID',
	ip                     : 'transactions.columns.ip',
	ipCountry              : 'transactions.columns.ipCountry',
	sourceID               : 'transactions.columns.sourceID',
	code                   : 'transactions.columns.code',
	sourceTypeID           : 'transactions.columns.sourceTypeID',
	bonusType              : 'transactions.columns.bonusType',
	sourceAccount          : 'transactions.columns.sourceAccount',
	sourceAmount           : 'transactions.columns.sourceAmount',
	bonusEligibility       : 'transactions.columns.bonusEligibility',
	bonusPercentage        : 'transactions.columns.bonusPercentage',
	bonusAmount            : 'transactions.columns.bonusAmount',
	totalBonus             : 'transactions.columns.totalBonus',
	actionNumber           : 'transactions.columns.actionNumber',
	created                : 'transactions.columns.date',
	viewInModal            : 'transactions.columns.viewInModal',
	noData                 : 'transactions.columns.noData',
	no                     : 'common.no',
};

export const defaultReportFields = availableFields.map(fieldKey => {
	return {
		id     : fieldKey,
		intlID : lang[fieldKey],
		checked: true,
	};
});
