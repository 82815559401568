
import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const CasinoIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (

		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_90)">
				<path {...props} d="M0.468749 12.9209C0.304443 12.9209 0.146728 12.8922 0 12.8402V15.5312C0 15.7902 0.209838 16 0.468749 16H12.5188C12.7776 16 12.9875 15.7902 12.9875 15.5312V12.8402C12.8408 12.8922 12.6831 12.9209 12.5188 12.9209H0.468749ZM11.0124 14.9292H9.50621C9.2473 14.9292 9.03746 14.7192 9.03746 14.4604C9.03746 14.2015 9.2473 13.9917 9.50621 13.9917H11.0124C11.2713 13.9917 11.4812 14.2015 11.4812 14.4604C11.4812 14.7192 11.2713 14.9292 11.0124 14.9292Z" fill={color} />
				<path {...props} d="M12.5188 9.90845H0.468749C0.209838 9.90845 0 10.1183 0 10.3772V11.5146C0 11.7734 0.209838 11.9834 0.468749 11.9834H12.5188C12.7776 11.9834 12.9875 11.7734 12.9875 11.5146V10.3772C12.9875 10.1183 12.7776 9.90845 12.5188 9.90845Z" fill={color} />
				<path {...props} d="M11.2635 3.05176e-05H1.724C0.770262 3.05176e-05 0 0.77188 0 1.72403V2.14163H12.9875V1.72403C12.9875 0.770415 12.2157 3.05176e-05 11.2635 3.05176e-05Z" fill={color} />
				<path {...props} d="M3.70409 8.97094V3.0791H0V9.05162C0.146606 8.99938 0.304321 8.97094 0.468749 8.97094H3.70409ZM1.32849 4.79626H2.37561C2.5238 4.79626 2.65917 4.88 2.72534 5.01245C2.79162 5.14489 2.77734 5.30346 2.68847 5.42199L2.56286 5.58947C2.28918 5.95434 2.13842 6.40649 2.13842 6.86266C2.13842 7.07861 1.96338 7.25365 1.74731 7.25365C1.53137 7.25365 1.35632 7.07861 1.35632 6.86266C1.35632 6.41808 1.46093 5.97619 1.65649 5.57836H1.32849C1.11255 5.57836 0.937498 5.40319 0.937498 5.18725C0.937498 4.97131 1.11255 4.79626 1.32849 4.79626Z" fill={color} />
				<path {...props} d="M4.6416 3.0791V8.97094H8.34594V3.0791H4.6416ZM7.33007 5.42199L7.20446 5.58947C6.93078 5.95434 6.78003 6.40649 6.78003 6.86266C6.78003 7.07861 6.60498 7.25365 6.38903 7.25365C6.17309 7.25365 5.99804 7.07861 5.99804 6.86266C5.99804 6.41808 6.10266 5.97631 6.29809 5.57836H5.97021C5.75427 5.57836 5.5791 5.40332 5.5791 5.18737C5.5791 4.97143 5.75427 4.79626 5.97021 4.79626H7.01733C7.1654 4.79626 7.30078 4.88 7.36706 5.01245C7.43322 5.14489 7.41894 5.30346 7.33007 5.42199Z" fill={color} />
				<path {...props} d="M12.9873 3.0791H9.2832V8.97094H12.5185C12.6829 8.97094 12.8407 8.99938 12.9873 9.05162V3.0791ZM11.9716 4.48681L11.8459 4.65429C11.5723 5.01928 11.4215 5.47143 11.4215 5.92748C11.4215 6.14355 11.2465 6.3186 11.0305 6.3186C10.8145 6.3186 10.6394 6.14355 10.6394 5.92748C10.6394 5.48291 10.744 5.04113 10.9396 4.64319H10.6116C10.3956 4.64319 10.2206 4.46814 10.2206 4.2522C10.2206 4.03625 10.3956 3.8612 10.6116 3.8612H11.6587C11.8069 3.8612 11.9423 3.94482 12.0084 4.07727C12.0747 4.20984 12.0604 4.36828 11.9716 4.48681Z" fill={color} />
				<path {...props} d="M14.9623 1.1886C14.3901 1.1886 13.9248 1.65393 13.9248 2.22607C13.9248 2.62951 14.1564 2.97961 14.4935 3.15112V6.99584C14.4935 7.1389 14.4379 7.2733 14.3367 7.3745C14.2356 7.47557 14.1011 7.53124 13.958 7.53124H13.9248V8.46874H13.9581C14.3516 8.46874 14.7214 8.31554 14.9996 8.03734C15.2778 7.75914 15.431 7.38927 15.431 6.99584V3.15112C15.7682 2.97961 15.9998 2.62951 15.9998 2.22607C15.9998 1.65393 15.5343 1.1886 14.9623 1.1886Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_90">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
CasinoIcon.propTypes = propTypesIcons;

CasinoIcon.defaultProps = defaultPropsIcons;
