import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import filterSaga from './filter/saga';
import eventSaga from './event/saga';
import marketSaga from './market/saga';
import selectionSaga from './selection/saga';

import eventBetHistorySaga from './eventBetHistory/saga';
import marketBetHistorySaga from './marketBetHistory/saga';
import selectionBetHistorySaga from './selectionBetHistory/saga';
import betHistoryToolbarSaga from './betHistoryToolbar/saga';

export default function* eventsRootSaga() {
	yield all([
		fork(listSaga),
		fork(filterSaga),
		fork(eventSaga),
		fork(marketSaga),
		fork(selectionSaga),
		fork(eventBetHistorySaga),
		fork(marketBetHistorySaga),
		fork(selectionBetHistorySaga),
		fork(betHistoryToolbarSaga),
	]);
}
