import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius, boxShadow } from '../../../config/style-util';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: -8px -16px;
  width: 360px;
  min-width: 160px;
  flex-shrink: 0;
  ${borderRadius('5px')};
  ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
  ${transition()};

  @media only screen and (max-width: 767px) {
    width: 310px;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 0px;
  padding: 15px 30px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  color: ${palette('text', 0)};
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  
`;

export const Body = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: ${palette('grayscale', 6)};

  a {
    text-decoration: none;
    display: block;
  }
`;

export const ListItem = styled.div`
  padding: 15px 30px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  text-align: ${props => (props['data-rtl'] === 'rtl' ? 'right' : 'left')};
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid ${palette('border', 2)};
  ${transition()};

  &:hover {
    background-color: ${palette('grayscale', 3)};
  }

  &.clickable {
    cursor: pointer;
  }
  
  &.not-read {
    background-color: #e3ffde;
  }
`;

export const AvatarWrap = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > div {
    width: calc( 100% - 50px );
    display: flex;
    flex-direction: column;
  }
`;

export const ListItemHeader = styled.div`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-bottom: 5px;

  font-size: 13px;
  font-weight: 500;
  color: ${palette('text', 0)};
  margin-top: 0;
`;
export const ListItemMain = styled.div`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-bottom: 5px;

  font-size: 13px;
  font-weight: 700;
  color: ${palette('text', 0)};
  margin-top: 0;
`;


export const ListItemDate = styled.span`
  font-size: 11px;
  color: ${palette('grayscale', 1)};
  font-weight: 500;
  flex-shrink: 0;
`;

export const ListItemBody = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${palette('text', 2)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LayoutContentStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const ListItemRow = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${palette('text', 2)};
  padding: 10px 15px 20px;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: ${({ disabled }) => !disabled ? 'pointer' : 'default'};
  ${transition()};

  &:hover {
    color: ${({ disabled }) => !disabled ? palette('primary', 0) : palette('text', 2)};
  }
`;
export const FooterWrap = styled.div`
  display: flex;
  textDecoration: none;
  align-items: center;
  justify-content : space-around;
`;

export const Avatar = styled.div`
  width: auto !important;
  height: auto;
  margin-right: 15px;
  text-align: center;

  img {
    width: 35px;
    height: 35px;
    height: 100%;
    ${borderRadius('50%')};
  }

  span {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #39435f;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 1px;
    ${borderRadius('50%')};
  }
`;
