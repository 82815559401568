import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import { adaptDocument } from './utils';

function getStoreData({ AppTabs, RTM }) {

	return {
		isRTMDocuments     : Boolean( find(AppTabs.get('tabs'), { id: 'rtm/documents' }) ),
		documentIDs        : RTM.Documents.get('documentIDs'),
		entities           : RTM.Documents.get('entities'),
		newDocumentIDs     : RTM.Documents.get('newDocumentIDs'),
		newDocumentEntities: RTM.Documents.get('newDocumentEntities'),
	};
}

function* onNotifyCreateDocument() {

	yield takeEvery(actions.RTM_DOCUMENTS_SOCKET_NOTIFY_CREATE_DOCUMENT, function* (action) {

		const { document, user } = action.data;
		const { id : rawDocumentID, created, description } = document;
		const rawDocument = Object.assign({}, document, user);
		rawDocument.id = rawDocumentID;
		rawDocument.created = created;
		rawDocument.description = description;
		rawDocument.username = user.username;

		const documentID = toInteger(rawDocument.id);
		if (!documentID) {
			return;
		}

		const storeData = yield select(getStoreData);

		const { isRTMDocuments }  = storeData;
		const documentIDs         = cloneDeep(storeData.documentIDs);
		const entities            = cloneDeep(storeData.entities);
		const newDocumentIDs      = cloneDeep(storeData.newDocumentIDs);
		const newDocumentEntities = cloneDeep(storeData.newDocumentEntities);

		const adaptedDocument     = adaptDocument(rawDocument);
		newDocumentEntities[documentID] = adaptedDocument;
		newDocumentIDs.unshift(documentID);

		if (isRTMDocuments) {
			if (!adaptedDocument) {
				return false;
			}
			entities[documentID] = adaptedDocument;
			documentIDs.unshift(documentID);
		}
		yield put(actions.dataRefresh(documentIDs, entities));
		yield put(actions.newDocumentListRefresh(newDocumentIDs, newDocumentEntities));

		let toSubscribeIDs = cloneDeep(documentIDs);
		toSubscribeIDs.push(documentID);
		toSubscribeIDs = uniq(toSubscribeIDs);

		yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}

function* onNotifyUpdateDocument() {

	yield takeEvery(actions.RTM_DOCUMENTS_SOCKET_NOTIFY_UPDATE_DOCUMENT, function* (action) {

		const rawDocument = action.data;
		const documentID = toInteger(rawDocument.data.id);
		if (!documentID) {
			return;
		}
		const storeData     = yield select(getStoreData);
		const documentIDs   = cloneDeep(storeData.documentIDs);
		const entities      = cloneDeep(storeData.entities);
		const documentEntity = entities[documentID];

		if (!documentEntity) {
			return;
		}
		documentEntity.statusId = rawDocument.data.status_id;
		entities[documentID] = documentEntity;

		yield put(actions.dataRefresh(documentIDs, entities));
	});
}

export default function* rtmDocumentsExternalSaga() {
	yield all([
		fork(onNotifyCreateDocument),
		fork(onNotifyUpdateDocument),
	]);
}
