import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'react-icons-kit';
import { angleRight } from 'react-icons-kit/fa/angleRight';
import { FormattedMessage } from 'react-intl';
import { CRUD_OPTIONS_ENUM } from '../../../../redux/staff/permissions/utils';


export const expandIcon = props => {
	const { record, expanded, onExpand } = props;
	if (!Array.isArray(record.children)) {
		return <span />;
	}
	return (
		<Icon
			style={{
				fontSize  : 18,
				cursor    : 'pointer',
				transform : expanded ? 'rotate(90deg)' : 'rotate(0deg)',
				transition: 'transform 0.1s',
			}}
			onClick={e => {
				onExpand(props.record, e);
			}}
			icon={angleRight}
			type="plus"
		/>
	);
};


expandIcon.propTypes = {
	record: PropTypes.shape({
		children: PropTypes.array,
	}),
	expanded: PropTypes.bool.isRequired,
	onExpand: PropTypes.func.isRequired,
};


export const permissionsColumns = [
	{
		title    : <FormattedMessage id="admin.user.permissions.permission" />,
		dataIndex: 'name',
		key      : 'name',
	},
	{
		title    : <FormattedMessage id="admin.user.permissions.create" />,
		dataIndex: 'create',
		key      : 'create',
	},
	{
		title    : <FormattedMessage id="admin.user.permissions.read" />,
		dataIndex: 'read',
		key      : 'read',
	},
	{
		title    : <FormattedMessage id="admin.user.permissions.update" />,
		dataIndex: 'update',
		key      : 'update',
	},
	{
		title    : <FormattedMessage id="admin.user.permissions.delete" />,
		dataIndex: 'delete',
		key      : 'delete',
	},
];


const detectDisabled = (item, crudType ) => {
	if (!item.children) {
		return item[item.name][crudType];
	}

	return item.children.some(child => detectDisabled(child, crudType));
};

export const getDefaultChecked = item => {
	const defaultCheckedCreate = { checked: false, disabled: false };
	const defaultCheckedRead   = { checked: false, disabled: false };
	const defaultCheckedUpdate = { checked: false, disabled: false };
	const defaultCheckedDelete = { checked: false, disabled: false };
	let defaultDisabledCreate = false;
	let defaultDisabledRead   = false;
	let defaultDisabledUpdate = false;
	let defaultDisabledDelete = false;


	if (item[item.name] && item[item.name].create) {
		defaultCheckedCreate.checked = item.create;
		defaultCheckedCreate.disabled = item[item.name].create.isExist;
	}

	if (item[item.name] && item[item.name].read) {
		defaultCheckedRead.checked = item.read;
		defaultCheckedRead.disabled = item[item.name].read.isExist;
	}

	if (item[item.name] && item[item.name].update) {
		defaultCheckedUpdate.checked = item.update;
		defaultCheckedUpdate.disabled = item[item.name].update.isExist;
	}
	if (item[item.name] && item[item.name].delete) {
		defaultCheckedDelete.checked = item.delete;
		defaultCheckedDelete.disabled = item[item.name].delete.isExist;
	}

	// default disabled recursive logic
	if (item.children) {

		defaultDisabledCreate = !item.children.some(child => detectDisabled(child, CRUD_OPTIONS_ENUM.CREATE));
		defaultDisabledRead = !item.children.some(child => detectDisabled(child, CRUD_OPTIONS_ENUM.READ));
		defaultDisabledUpdate = !item.children.some(child => detectDisabled(child, CRUD_OPTIONS_ENUM.UPDATE));
		defaultDisabledDelete = !item.children.some(child => detectDisabled(child, CRUD_OPTIONS_ENUM.DELETE));

	} else if (item[item.name] ) {
		defaultDisabledCreate = !item[item.name].create;
		defaultDisabledRead = !item[item.name].read;
		defaultDisabledUpdate = !item[item.name].update;
		defaultDisabledDelete = !item[item.name].delete;
	}

	// Default checked logic
	if (item.children) {
		defaultCheckedRead.checked = !!item.read;
		defaultCheckedCreate.checked = !!item.create;
		defaultCheckedUpdate.checked = !!item.update;
		defaultCheckedDelete.checked = !!item.delete;

	}


	return {
		read  : defaultCheckedRead,
		create: defaultCheckedCreate,
		update: defaultCheckedUpdate,
		delete: defaultCheckedDelete,
		defaultDisabledCreate,
		defaultDisabledRead,
		defaultDisabledUpdate,
		defaultDisabledDelete,
	};
};

export const isAvailable = (item, crudType) => {
	return ( item[item.name] && item[item.name][crudType] && typeof  item[item.name][crudType] !== 'boolean') || item.children;
};

export const checkAllDescendants = (permission, crudType, checked) => {
	if (!permission.children) {
		permission[crudType] = checked;
		return permission;
	}
	permission.children = permission.children.map(item => checkAllDescendants(item, crudType, checked));

	return permission;
};

export const generateRowData = ({
	item,
	onCheck,
	defaultDisabledCreate,
	read,
	create,
	update,
	defaultDelete,
	defaultDisabledRead,
	defaultDisabledUpdate,
	defaultDisabledDelete,
}) => {
	const rowData = {
		name  : <FormattedMessage id={`admin.user.permissions.${item.name}`} />,
		create: <Checkbox
			indeterminate={item.createIndeterminate && !item.create}
			onClick={(e) => onCheck(
				e.target.checked, item, CRUD_OPTIONS_ENUM.CREATE
			)}
			disabled={defaultDisabledCreate}
			checked={!defaultDisabledCreate
      && (create.checked || item.createIndeterminate)}
		/>,
		read: <Checkbox
			indeterminate={item.readIndeterminate && !item.read}
			onClick={(e) => onCheck(
				e.target.checked, item, CRUD_OPTIONS_ENUM.READ
			)}
			disabled={defaultDisabledRead}
			checked={!defaultDisabledRead
      && (read.checked || item.readIndeterminate)}
		/>,
		update: <Checkbox
			indeterminate={item.updateIndeterminate && !item.update}
			onClick={(e) => onCheck(
				e.target.checked, item, CRUD_OPTIONS_ENUM.UPDATE
			)}
			disabled={defaultDisabledUpdate}
			checked={!defaultDisabledUpdate
      && (update.checked || item.updateIndeterminate)}
		/>,
		delete: <Checkbox
			indeterminate={item.deleteIndeterminate && !item.delete}
			onClick={(e) => onCheck(
				e.target.checked, item, CRUD_OPTIONS_ENUM.DELETE
			)}
			disabled={defaultDisabledDelete}
			checked={!defaultDisabledDelete
      && (defaultDelete.checked || item.deleteIndeterminate)}
		/>,
	};

	return rowData;
};


export const ROLE_TYPE = {
	platform: '1',
	partner : '2',
};


export const ROLES_GROUP = [
	{
		id  : ROLE_TYPE.partner,
		type: ROLE_TYPE.partner,
		name: 'Partner',
	},
	{
		id  : ROLE_TYPE.platform,
		type: ROLE_TYPE.platform,
		name: 'Platform',
	},
];

export const ROLE_TYPES_ENUM = {
	platform: 'platform',
	partner : 'partner',
};
