import { apiRequest } from './index';

function rulesList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/bettingRules',
		params,
	};

	return apiRequest(req);
}

function ruleInfo(ruleID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/bettingRule/${ruleID}`,
		params,
	};

	return apiRequest(req);
}

function ruleCreate(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/bettingRule',
		data,
		params,
	};

	return apiRequest(req);
}

function ruleUpdate(ruleID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/bettingRule/${ruleID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function ruleDelete(ruleID, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/bettingRule/${ruleID}`,
		params,
	};

	return apiRequest(req);
}

export const bettingRulesAPI = {
	rulesList,
	ruleInfo,
	ruleCreate,
	ruleUpdate,
	ruleDelete,
};
