import { apiRequest } from './index';

function listLST(params = {}) {

	const req = {
		method: 'GET',
		url   : '/livestatistictypes',
		params,
	};

	return apiRequest(req);
}

function saveLST(statisticsData, params = {}) {

	const req = {
		method: 'POST',
		url   : '/livestatistictypes',
		data  : statisticsData,
		params,
	};

	return apiRequest(req);
}

function editLST(statisticsData, id, params = {}) {

	const req = {
		method: 'PUT',
		url   : `/livestatistictypes/${id}`,
		data  : statisticsData,
		params,
	};

	return apiRequest(req);
}

function createAndEditNames(statisticsData, id, params = {}) {

	const req = {
		method: 'POST',
		url   : `/livestatistictypes/${id}/names`,
		data  : statisticsData,
		params,
	};

	return apiRequest(req);
}

function getNames(id, params = {}) {

	const req = {
		method: 'GET',
		url   : `/livestatistictypes/${id}/names`,
		params,
	};

	return apiRequest(req);
}

function createAndEditVisibility(statisticsData, id, params = {}) {

	const req = {
		method: 'POST',
		url   : `/livestatistictypes/${id}/status`,
		data  : statisticsData,
		params,
	};

	return apiRequest(req);
}

function getVisibility(id, params = {}) {

	const req = {
		method: 'GET',
		url   : `/livestatistictypes/${id}/status`,
		params,
	};

	return apiRequest(req);
}

export const liveStatisticTypesAPI = {
	listLST,
	saveLST,
	editLST,
	createAndEditNames,
	getNames,
	createAndEditVisibility,
	getVisibility,
};
