import { apiRequest } from './index';

function promotionsList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/content/promotion',
		params,
	};

	return apiRequest(req);
}

function promotionInfo(promotionID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/promotion/${promotionID}`,
		params,
	};

	return apiRequest(req);
}

function promotionAdd(pageData, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/promotion',
		data  : pageData,
		params,
	};

	return apiRequest(req);
}

function promotionUpdate(promotionID, pageData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/promotion/${promotionID}`,
		data  : pageData,
		params,
	};

	return apiRequest(req);
}

function promotionDelete(promotionID, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/content/promotion/${promotionID}`,
		params,
	};

	return apiRequest(req);
}

function promotionRevisionsList(promotionID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/promotion/${promotionID}/revisions`,
		params,
	};

	return apiRequest(req);
}

function promotionRevisionAdd(promotionID, revisionData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/promotion/${promotionID}/revisions`,
		data  : revisionData,
		params,
	};

	return apiRequest(req);
}

function promotionRevisionApply(promotionID, revisionID) {

	const req = {
		method: 'POST',
		url   : `/content/promotion/${promotionID}/revisions/${revisionID}`,
	};

	return apiRequest(req);
}

export const promotionsAPI = {
	promotionsList,
	promotionInfo,
	promotionAdd,
	promotionUpdate,
	promotionDelete,

	promotionRevisionsList,
	promotionRevisionAdd,
	promotionRevisionApply,
};
