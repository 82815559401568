import { Map } from 'immutable';
import uniq from 'lodash/uniq';
import actions from './actions';
import { createFilter, filtersURL } from '../../../../helpers/utils';
import { initFilterTypes } from './utils';

const initState = new Map({
	list      : [],
	changedIDs: [],

	filter: createFilter(filtersURL.marketGroups, initFilterTypes),
	
	UI: {
		loading: false,
	},
});

export default function marketsGroupsReducer(state = initState, action) {

	switch (action.type) {

		case actions.MARKETS_GROUPS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.MARKETS_GROUPS_FILTER_REFRESH: {
			return state.set('filter', action.data);
		}

		case actions.MARKETS_GROUPS_FILTER_SET_VALUE: {
			const { valueName, value } = action.data;
			const filter      = new Map(state.get('filter')).toObject();
			filter[valueName] = value;

			return state.set('filter', filter);
		}

		case actions.MARKETS_GROUPS_SET_VALUE_UI: {
			const { valueName, value } = action.data;
			const UI      = new Map(state.get('UI')).toObject();
			UI[valueName] = value;

			return state.set('UI', UI);
		}

		case actions.MARKETS_GROUPS_CHANGED_ID_ADD: {
			const { groupID } = action.data;
			const changedIDs = state.get('changedIDs');
			const result = uniq([...changedIDs, groupID]);

			return state.set('changedIDs', result);
		}

		case actions.MARKETS_GROUPS_CHANGED_IDS_RESET: {
			return state.set('changedIDs', []);
		}
		case actions.FILTER_RESET: {
			return state.set('filter', { sportID: 150592 });
		}

		default:
			return state;
	}
}
