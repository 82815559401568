import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	list: [],

	UI: {
		loading        : false,
		providerLoading: false,
	},
});

export default function casinoListReducer(state = initState, action) {
	switch (action.type) {
		case actions.CASINO_LIST_REFRESH: {
			const { list } = action.data;
			return state.set('list', list);
		}

		case actions.CASINO_LIST_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target, true);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
