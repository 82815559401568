import EntityAdapter from '../../../helpers/entityAdapter';

export const generateIDs = (games) => {
	if (!games.length) {
		return {
			tagIDs             : [],
			currencyIDs        : [],
			customProviderIDs  : [],
			categoryIDs        : [],
			restrictedCountries: [],
			statusIDs          : [],
			bulkGameIDs        : [],
		};
	}
	const data = games.reduce((acc, item) => {
		const { customProviderIDs, categoryID, statusID, currencyIDs, id, restrictedCountries } = item;
		acc[id] = {
			currencyIDs,
			customProviderIDs,
			restrictedCountries,
			categoryIDs: [Number(categoryID)],
			statusIDs  : [Number(statusID)],
			bulkGameIDs: [Number(id)],
		};
		return acc;
	}, {});

	return Object.values(data).reduce((acc, item) => {
		return {
			currencyIDs        : [...new Set([...acc.currencyIDs, ...item.currencyIDs])],
			customProviderIDs  : [...new Set([...acc.customProviderIDs, ...item.customProviderIDs])],
			categoryIDs        : [...new Set([...acc.categoryIDs, ...item.categoryIDs])],
			restrictedCountries: [...new Set([...acc.restrictedCountries, ...item.restrictedCountries])],
			statusIDs          : [...new Set([...acc.statusIDs, ...item.statusIDs])],
			bulkGameIDs        : [...new Set([...acc.bulkGameIDs, ...item.bulkGameIDs])],
		};
	});
};

const compareArrays = (arr1, arr2) => {
	if (!arr1.length || !arr2.length) return true;
	if (arr1.length !== arr2.length) {
		return false;
	}

	for (const num of arr1) {
		if (!arr2.includes(num)) {
			return false;
		}
	}

	return true;
};

export const gameToPage = (bulkIDs = [], gameEntities = {}) => {
	const count = bulkIDs.reduce((acc, gameID) => {
		if (gameEntities[gameID]) {
			acc++;
		}
		return acc;
	}, 0);
	return { currentPage: count };
};

export const checkMultiply = (games, key) => {
	let variable;
	let res;
	for (let i = 0; i < games.length; i++) {
		variable = games[i][key];
		if (!variable.length) {
			continue;
		}
		for (let j = 0; j < games.length; j++) {
			res = compareArrays(variable, games[j][key]);
			if (!res) {
				break;
			}
		}
	}
	if (res === undefined) {
		res = 'empty';
	}
	return res;
};


export function mergeArrays(arr, obj) {
	const res = [];
	arr.map((item) => {
		if (item.id === obj.id) {
			res.push(obj);
		} else {
			res.push(item);
		}
	});
	return res;
}

// adapt data ---------------------------

const fields = {
	statusIDs          : 'status_id',
	categoryIDs        : 'category',
	customProviderIDs  : 'custom_provider_ids',
	tagIDs             : 'tags',
	currencyIDs        : 'currency_ids',
	bulkGameIDs        : 'ids',
	restrictedCountries: 'restricted_countries',
};

const bulkAdapt = createBulkAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function prepareBulkData(rawData = [], selectedArr) {
	bulkAdapt.clearExcludes();
	const data = { ...rawData, categoryIDs: rawData.categoryIDs[0], statusIDs: rawData.statusIDs[0] };

	const adaptedData = bulkAdapt.prepare(data);
	if (!selectedArr.statusIDs) {
		delete adaptedData[[fields.statusIDs]];
	}
	if (!selectedArr.categoryIDs) {
		delete adaptedData[fields.categoryIDs];
	}
	if (!selectedArr.customProviderIDs || !compareArrays(rawData.customProviderIDs, selectedArr.customProviderIDs)) {
		delete adaptedData[fields.customProviderIDs];
	}
	if (!selectedArr.restrictedCountries || !compareArrays(rawData.restrictedCountries, selectedArr.restrictedCountries)) {
		delete adaptedData[fields.restrictedCountries];
	}
	if (!selectedArr.tagIDs || !compareArrays(rawData.tagIDs, selectedArr.tagIDs)) {
		delete adaptedData[fields.tagIDs];
	}
	if (!selectedArr.currencyIDs || !compareArrays(rawData.currencyIDs, selectedArr.currencyIDs)) {
		delete adaptedData[fields.currencyIDs];
	}

	return adaptedData;
}

// Adapters ---------------------------------------------------------------------------------------

function createBulkAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.number, 'statusIDs', fields.statusIDs);
	adapter.addField(rules.number, 'categoryIDs', fields.categoryIDs);
	adapter.addField(rules.arrayNumber, 'customProviderIDs', fields.customProviderIDs);
	adapter.addField(rules.arrayObject, 'tagIDs', fields.tagIDs);
	adapter.addField(rules.arrayNumber, 'restrictedCountries', fields.restrictedCountries);
	adapter.addField(rules.arrayNumber, 'currencyIDs', fields.currencyIDs);
	adapter.addField(rules.arrayNumber, 'bulkGameIDs', fields.bulkGameIDs);
	return adapter;
}
