import { apiRequest } from './index';
import { IGetRiskDataParams, IRiskBetsItem } from '../../redux/sportRiskManagement/sportBets/list/types';
import { IActionMarginUpdateData, IActionSuspendUpdateData, IUpdateRiskBetsDataParams } from '../../redux/sportRiskManagement/sportBets/modal/types';
import { IApiResponse } from '../../redux/types';

function getSportRisk(params: IGetRiskDataParams = {}): Promise<IApiResponse<IRiskBetsItem>> {
	const req = {
		method          : 'GET',
		url             : '/sport_bet_risks',
		params,
		withoutLangID   : true,
		withoutWebsiteID: true,
	};

	return apiRequest(req);
}

function updateSportRisk(data: IUpdateRiskBetsDataParams, id: string): Promise<IApiResponse<IRiskBetsItem>> {
	const req = {
		method          : 'PUT',
		url             : `/sport_bet_risks/${id}`,
		data,
		withoutLangID   : true,
		withoutWebsiteID: true,
	};

	return apiRequest(req);
}

function updateRiskSuspend(data: IActionSuspendUpdateData): Promise<IApiResponse<object>> {
	const req = {
		method          : 'PUT',
		url             : '/suspend_markets',
		data,
		withoutLangID   : true,
		withoutWebsiteID: true,
	};

	return apiRequest(req);
}

function updateRiskMargin(data: IActionMarginUpdateData): Promise<IApiResponse<object>> {
	const req = {
		method          : 'PUT',
		url             : '/sport_risk_market',
		data,
		withoutLangID   : true,
		withoutWebsiteID: true,
	};

	return apiRequest(req);
}

export const sportRiskManagementAPI = {
	getSportRisk,
	updateSportRisk,
	updateRiskSuspend,
	updateRiskMargin,
};
