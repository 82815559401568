import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';

import actions from './actions';

const initState = new Map({
	all        : [],
	translation: [],

	UI: {
		loading: false,
	},
});

export default function participantHistoryReducer(state = initState, action) {

	switch (action.type) {

		case actions.DATA_REFRESH: {
			const { logData, logModel } = action.data;
			return state.set(logModel, logData);
		}

		case actions.UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
