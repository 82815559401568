const actions = {

	EVENT_MARKET_SELECTION_UPDATE                     : 'EVENT_MARKET_SELECTION_UPDATE',
	EVENT_MARKET_SELECTION_CANCEL                     : 'EVENT_MARKET_SELECTION_CANCEL',
	EVENT_MARKET_SELECTION_BASE_DATA_REFRESH          : 'EVENT_MARKET_SELECTION_BASE_DATA_REFRESH',
	EVENT_MARKET_SELECTION_SETTINGS_DATA_RELOAD       : 'EVENT_MARKET_SELECTION_SETTINGS_DATA_RELOAD',
	EVENT_MARKET_SELECTION_SETTINGS_DATA_REFRESH      : 'EVENT_MARKET_SELECTION_SETTINGS_DATA_REFRESH',
	EVENT_MARKET_SELECTION_DYNAMIC_LIMITS_DATA_RELOAD : 'EVENT_MARKET_SELECTION_DYNAMIC_LIMITS_DATA_RELOAD',
	EVENT_MARKET_SELECTION_DYNAMIC_LIMITS_DATA_REFRESH: 'EVENT_MARKET_SELECTION_DYNAMIC_LIMITS_DATA_REFRESH',
	EVENT_MARKET_SELECTION_SET_VALUE_UI               : 'EVENT_MARKET_SELECTION_SET_VALUE_UI',
	EVENT_MARKET_SELECTION_COMMON_DATA_SET_VALUE      : 'EVENT_MARKET_SELECTION_COMMON_DATA_SET_VALUE',
	EVENT_MARKET_SELECTION_DATA_RESET                 : 'EVENT_MARKET_SELECTION_DATA_RESET',
	EVENT_MARKET_SELECTION_ODD_UPDATE                 : 'EVENT_MARKET_SELECTION_ODD_UPDATE',
	EVENT_MARKET_SELECTION_STATUS_UPDATE              : 'EVENT_MARKET_SELECTION_STATUS_UPDATE',
	EVENT_MARKET_SELECTION_HISTORY_REFRESH     						 : 'EVENT_MARKET_SELECTION_HISTORY_REFRESH',
	EVENT_MARKET_SELECTION_HISTORY_RELOAD    						 	 : 'EVENT_MARKET_SELECTION_HISTORY_RELOAD',
	EVENT_MARKET_SELECTION_SET_HISTORY_VALUE_UI  				 : 'EVENT_MARKET_SELECTION_SET_HISTORY_VALUE_UI',

	selectionUpdate: (selectionID, closeModal) => ({
		type: actions.EVENT_MARKET_SELECTION_UPDATE,
		data: {
			selectionID,
			closeModal,
		},
	}),
	selectionCancel: (eventID, selectionID) => ({
		type: actions.EVENT_MARKET_SELECTION_CANCEL,
		data: {
			eventID,
			selectionID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.EVENT_MARKET_SELECTION_BASE_DATA_REFRESH,
		data,
	}),
	settingsDataReload: selectionID => ({
		type: actions.EVENT_MARKET_SELECTION_SETTINGS_DATA_RELOAD,
		data: {
			selectionID,
		},
	}),
	settingsDataRefresh: data => ({
		type: actions.EVENT_MARKET_SELECTION_SETTINGS_DATA_REFRESH,
		data,
	}),
	dynamicLimitsDataReload: selectionID => ({
		type: actions.EVENT_MARKET_SELECTION_DYNAMIC_LIMITS_DATA_RELOAD,
		data: {
			selectionID,
		},
	}),
	dynamicLimitsDataRefresh: data => ({
		type: actions.EVENT_MARKET_SELECTION_DYNAMIC_LIMITS_DATA_REFRESH,
		data,
	}),
	setValueUI: (valueName, value) => ({
		type: actions.EVENT_MARKET_SELECTION_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	commonDataSetValue: (valueName, value) => ({
		type: actions.EVENT_MARKET_SELECTION_COMMON_DATA_SET_VALUE,
		data: {
			valueName,
			value,
		},
	}),
	dataReset: () => ({
		type: actions.EVENT_MARKET_SELECTION_DATA_RESET,
	}),
	selectionOddUpdate: (selectionID, odd, onSuccess = null) => ({
		type: actions.EVENT_MARKET_SELECTION_ODD_UPDATE,
		data: {
			selectionID,
			odd,
			onSuccess,
		},
	}),
	selectionStatusUpdate: (selectionID, statusID, onSuccess = null) => ({
		type: actions.EVENT_MARKET_SELECTION_STATUS_UPDATE,
		data: {
			selectionID,
			statusID,
			onSuccess,
		},
	}),
	historyRefresh: data => ({
		type: actions.EVENT_MARKET_SELECTION_HISTORY_REFRESH,
		data,
	}),
	historyReload: selectionID => ({
		type: actions.EVENT_MARKET_SELECTION_HISTORY_RELOAD,
		data: {
			selectionID,
		},
	}),
	setHistoryValueUI: booleanValue => ({
		type: actions.EVENT_MARKET_SELECTION_SET_HISTORY_VALUE_UI,
		data: {
			historyDataLoading: booleanValue,
		},
	}),
};

export default actions;
