import toInteger from 'lodash/toInteger';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { createEntities } from '../../../utility';

export const fields = {
	id         : 'id',
	names      : 'names',
	questionID : 'secret_question_id',
	langID     : 'lang_id',
	translation: 'translation',
};

const questionAdapter = createQuestionAdapter();
const namesAdapter = createNamesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptQuestionsList(rawData = [], langList) {
	questionAdapter.clearExcludes();
	namesAdapter.clearExcludes();

	const adaptedData = questionAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.names = adaptNames(item.names, langList);
	});

	return createEntities(adaptedData);
}

export function adaptNames(rawData, langList) {
	namesAdapter.clearExcludes();
	const adaptedNames = namesAdapter.adaptList(rawData);

	const result = langList.map(langItem => {
		const langID = langItem.id;
		const nameItem = adaptedNames.find(item => item.langID === langID);

		if (nameItem) {
			return nameItem;
		}
		return {
			langID,
			translation: '',
		};

	});

	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareQuestion(rawNames, questionID) {
	const names = prepareNames(rawNames, questionID);
	const preparedData = {
		names,
	};

	if (isID(questionID)) {
		preparedData.id = toInteger(questionID);
	}

	return preparedData;
}

export function prepareNames(rawData = [], questionID) {
	namesAdapter.clearExcludes();
	namesAdapter.addExcludeField('id');
	namesAdapter.addExcludeField('questionID');

	const preparedData = rawData.map(item => {
		const preparedItem = namesAdapter.prepare(item);
		if (isID(item.id)) {
			preparedItem.id = toInteger(item.id);
		}
		if (isID(questionID)) {
			preparedItem[fields.questionID] = toInteger(questionID);
		}

		return preparedItem;
	});

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createQuestionAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.arrayObject, 'names', fields.names);

	return adapter;
}

export function createNamesAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'questionID', fields.questionID);
	adapter.addField(rules.id, 'langID', fields.langID);

	adapter.addField(rules.string, 'translation', fields.translation);

	return adapter;
}
