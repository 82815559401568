import { apiRequest } from './index';


const smsTemplates = (params = {}) => {
	const req = {
		method       : 'GET',
		url          : '/smsTemplates',
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
};

const createSmsTemplates = (data, params = {}) => {
	const req = {
		method       : 'POST',
		url          : '/smsTemplates',
		data,
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
};

const updateSmsTemplates = (smsTemplateID, data, params = {}) => {
	const req = {
		method       : 'PUT',
		url          : `/smsTemplates/${smsTemplateID}`,
		data,
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
};

const smsTemplatesByID = (templateID, params = {}) => {
	const req = {
		method       : 'GET',
		url          : `/smsTemplates/${templateID}`,
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
};

const deleteByID = (templateID, params = {}) => {
	const req = {
		method       : 'DELETE',
		url          : `/smsTemplates/${templateID}`,
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
};

const getSMSVariables = (params = {}) => {
	const req = {
		method       : 'GET',
		url          : '/smsTags/',
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
};

export const smsTemplatesAPI = {
	smsTemplates,
	smsTemplatesByID,
	deleteByID,
	createSmsTemplates,
	updateSmsTemplates,
	getSMSVariables,
};
