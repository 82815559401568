import { all, fork } from 'redux-saga/effects';

import scopesSaga from './scopes/saga';
import statisticTypesSaga from './statisticTypes/saga';
import smsSaga from './sms/saga';
import currencySagas from './currency/saga';
import languagesSaga from './languages/saga';
import CountrySaga from './countries/saga';
import paymentsSagas from './payments/saga';
import SecuritySagas from './security/saga';

export default function* settingsSaga() {
	yield all([
		fork(smsSaga),
		fork(currencySagas),
		fork(languagesSaga),
		fork(paymentsSagas),
		fork(SecuritySagas),
		fork(CountrySaga),

		fork(scopesSaga),
		fork(statisticTypesSaga),
	]);
}
