import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';

import { toInteger } from 'lodash';
import actions from './actions';
import { adaptDocument } from './utils';
import notifActions from '../../notifications/actions';

function getStoreData({ Notifications }) {

	return {
		// entities : Users.Documents.get('entities'),
		documents: Notifications.get('documents'),

		notifIDs        : Notifications.get('notifIDs'),
		entities        : Notifications.get('entities'),
		newNotifIDs     : Notifications.get('newNotifIDs'),
		newNotifEntities: Notifications.get('newNotifEntities'),
	};
}

function* onNotifyCreateDocument() {

	yield takeEvery(actions.USER_DOCUMENTS_SOCKET_NOTIFY_CREATE_DOCUMENT, function* (action) {

		const { id : notifID, type_id : typeID } = action.data;
		const rawData = JSON.parse(action.data.notification);
		const { user, document } = rawData;
		if (!user || !document) {
			return;
		}

		const storeData         = yield select(getStoreData);

		const entities          = cloneDeep(storeData.entities);
		const documents         = cloneDeep(storeData.documents);
		const notifIDs          = cloneDeep(storeData.notifIDs);

		const newNotifIDs       = cloneDeep(storeData.newNotifIDs);
		const newNotifEntities  = cloneDeep(storeData.newNotifEntities);

		const adaptedDoc        = adaptDocument(document, user, notifID);
		const docID             = toInteger(adaptedDoc.docID);

		newNotifEntities[docID] = adaptedDoc;
		newNotifIDs.unshift(docID);

		if (!adaptedDoc) {
			return false;
		}


		entities[docID]   = adaptedDoc;
		notifIDs.unshift(docID);
		documents.unshift(adaptedDoc);

		yield put(notifActions.dataRefresh(typeID, documents, docID));
		yield put(notifActions.notifDataRefresh(notifIDs, entities));
		yield put(notifActions.newNotifListRefresh(newNotifIDs, newNotifEntities));

		// let toSubscribeIDs = cloneDeep(notifIDs);
		// toSubscribeIDs.push(riskID);
		// toSubscribeIDs = uniq(toSubscribeIDs);

		// yield put(actions.subscribeToUpdate(toSubscribeIDs));
		//
		// yield put(actions.listRefresh(entities));
		// yield put(actions.newDocsListRefresh(documents));
	});
}
function* onNotifyUpdateDocument() {

	yield takeEvery(actions.USER_DOCUMENTS_SOCKET_NOTIFY_UPDATE_DOCUMENT, function* (action) {

		const rawDocument = action.data;
		const docID   = toInteger(rawDocument.id);
		const typeID  = toInteger(rawDocument.type_id);

		if (!docID) {
			return;
		}

		const storeData       = yield select(getStoreData);
		const documents       = cloneDeep(storeData.documents);
		const documentEntity  = cloneDeep(documents.find(doc => doc.id === docID));

		if (!documentEntity) {
			return;
		}

		documentEntity.seen = rawDocument.seen;
		documents.forEach((doc, i, array) => {
			if (doc.id === docID) {
				array.splice(i, 1, documentEntity);
			}
		});
		yield put(notifActions.dataRefresh(typeID, documents, docID));
	});
}

export default function* userDocumentsExternalSaga() {
	yield all([
		fork(onNotifyCreateDocument),
		fork(onNotifyUpdateDocument),
	]);
}
