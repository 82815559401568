import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import { adaptGroupsList, adaptBaseData, prepareBaseData } from './utils';

import { usersAPI } from '../../../helpers/api/users';
import notifications from '../../../helpers/notifications';
import { logger } from '../../../helpers/logger';

const prefix = 'users.groups';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
	errorUpdate    : `${prefix}.errorUpdate`,
	errorDelete    : `${prefix}.errorDelete`,
	successUpdate  : `${prefix}.successUpdate`,
	successDelete  : `${prefix}.successDelete`,
};

function getStoreData({ Users, App }) {

	return {
		baseData : Users.Groups.get('baseData'),
		modalUI  : Users.Groups.get('modalUI'),
		websiteID: App.get('websiteID'),
	};
}

function* listReload() {
	yield takeEvery(actions.USERS_GROUPS_LIST_RELOAD, function* () {
		yield put(actions.modalUIRefresh({ loading: true }));
		let list = [];
		try {
			const response = yield call(usersAPI.getRiskGroupsList);
			if (response && response.status === 200) {
				list = adaptGroupsList(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			logger.log(error);
		}
		yield put(actions.listRefresh(list));
		yield put(actions.modalUIRefresh({ loading: false }));

	});
}


function* saveGroup() {
	yield takeEvery(actions.USERS_GROUPS_SAVE_GROUP, function* () {
		yield put(actions.modalUIRefresh({ modalLoad: true }));

		const { baseData, modalUI, websiteID } = yield select(getStoreData);
		const { editMode, closeModal } = modalUI;

		const preparedData = prepareBaseData(baseData, websiteID);
		let isError = false;
		try {
			// update
			if (editMode) {
				const response = yield call(usersAPI.editRiskGroup, preparedData);
				if (response && response.status === 200) {
					notifications.showSuccess(messages.successUpdate);
					yield put(actions.modalUIRefresh({ defaultDisabled: response.data.data.default }));
				}
				// add
			} else {
				preparedData.id = null;
				const response = yield call(usersAPI.addRiskGroup, preparedData);
				if (response && response.status === 200) {
					const baseData = adaptBaseData(response.data.data);
					yield put(actions.modalUIRefresh({ editMode: true, defaultDisabled: baseData.default }));
					yield put(actions.baseDataRefresh(baseData));

					notifications.showSuccess(messages.successUpdate);
				}
			}

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorUpdate, error);
		}

		if (!isError && closeModal) {
			yield put(actions.listReload());
			yield put(actions.dataReset());

		} else if (!isError && !closeModal) {
			yield put(actions.listReload());
			yield put(actions.modalUIRefresh({
				loading  : false,
				isChanged: false,
			}));

		} else {
			yield put(actions.modalUIRefresh({ modalLoad: false }));
		}
		yield put(actions.modalUIRefresh({ modalLoad: false }));

	});
}

export default function* usersGroupsSaga() {
	yield all([
		fork(listReload),
		fork(saveGroup),
	]);
}
