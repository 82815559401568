import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list: [],

	UI: {
		loading: false,
	},
});

export default function personalListReducer(state = initState, action) {

	switch (action.type) {

		case actions.LIST_REFRESH: {
			const { list } = action.data;
			return state.set('list', list);
		}

		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);

			return state.set('UI', result);
		}

		default:
			return state;
	}
}
