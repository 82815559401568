import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import marketFilterActions from '../filter/actions';

import { marketsAPI } from '../../../../helpers/api/markets';
import { showSuccess, showError } from '../../../../helpers/notifications';
import { isEqualID } from '../../../../helpers/utils';
import { prepareGroupsList, adaptGroupsList } from './utils';
import { logger } from '../../../../helpers/logger';

const prefix = 'sport.markets.groups';

const messages = {
	errorLoadMarketGroup       : `${prefix}.errorLoadMarketGroup`,
	errorDeleteCombinationGroup: `${prefix}.errorDeleteCombinationGroup`,
	errorSaveCombinationGroup  : `${prefix}.errorSaveCombinationGroup`,
	errorSaveAllGroups         : `${prefix}.errorSaveAllGroups`,
	errorUpdateGroupsOrder     : `${prefix}.errorUpdateGroupsOrder`,
	successDeleteCombGroup     : `${prefix}.successDeleteCombGroup`,
	successUpdateCombGroup     : `${prefix}.successUpdateCombGroup`,
	successAddCombGroup        : `${prefix}.successAddCombGroup`,
	successSaveAll             : `${prefix}.successSaveAll`,
	successOrderUpdate         : `${prefix}.successOrderUpdate`,
};

function getStoreData({ Sport: { Markets } }) {

	return {
		filter      : Markets.Groups.get('filter'),
		list        : Markets.Groups.get('list'),
		marketFilter: Markets.Filter.get('filter'),
	};
}

function* listReload() {

	const takeAction   = actions.MARKETS_GROUPS_LIST_RELOAD;

	yield takeEvery(takeAction, function* () {

		yield put(actions.setValueUI('loading', true));

		const storeData	 = yield select(getStoreData);
		const { filter } = storeData;
		const { sportID, name } = filter;

		let list = [];
		try {
			const response = yield call(marketsAPI.combinationGroupList, sportID, name);
			if (response && response.status === 200) {
				list = adaptGroupsList(response.data.data);
			}

		} catch (error) {
			showError(messages.errorLoadMarketGroup, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(list));
		yield put(actions.changedIDsReset());
		yield put(actions.setValueUI('loading', false));
	});
}

function* deleteGroup() {

	const takeAction   = actions.MARKETS_GROUPS_DELETE_GROUP;

	yield takeEvery(takeAction, function* (action) {

		yield put(actions.setValueUI('loading', true));

		const { groupID } = action.data;
		const { filter, marketFilter, list } = yield select(getStoreData);

		try {
			const response = yield call(marketsAPI.combinationGroupRemove, groupID);
			if (response && response.status === 200) {
				showSuccess(messages.successDeleteCombGroup);
				const updateGroups = list.filter(item => item.id !== groupID);
				yield put(actions.listRefresh(updateGroups));
			}

		} catch (error) {
			showError(messages.errorDeleteCombinationGroup, error);
			logger.log(error);
		}

		// yield put(actions.listReload());

		// We need to reload groups list in the Market Filter if sportID is equal
		// because Market Modal uses that list for choosing values
		if (isEqualID(marketFilter.sportID, filter.sportID)) {
			yield put(marketFilterActions.groupListReload(marketFilter.sportID));
		}
	});
}

function* saveGroup() {

	const takeAction   = actions.MARKETS_GROUPS_SAVE_GROUP;

	yield takeEvery(takeAction, function* (action) {

		yield put(actions.setValueUI('loading', true));

		const groupData = action.data;
		const groupID = toInteger(groupData.id);
		const { filter, marketFilter, list } = yield select(getStoreData);

		try {

			// update
			if (groupID) {
				const response = yield call(marketsAPI.combinationGroupUpdate, groupData);
				if (response && response.status === 200) {
					showSuccess(messages.successUpdateCombGroup);
				}

			// add
			} else {
				groupData.id = null;
				const response = yield call(marketsAPI.combinationGroupAdd, groupData);
				if (response && response.status === 200) {
					const updatedList = list.map(item => {
						if (!toInteger(item.id)) { // this means that item id is temp id.
							item.id = response.data.data.id;
						}
						return item;
					});
					yield put(actions.listRefresh(updatedList));
					showSuccess(messages.successAddCombGroup);
				}
			}

			yield put(actions.changedIDsReset());


		} catch (error) {
			showError(messages.errorSaveCombinationGroup, error);
			logger.log(error);
		}


		// We need to reload groups list in the Market Filter if sportID is equal
		// because Market Modal uses that list for choosing values
		if (isEqualID(marketFilter.sportID, filter.sportID)) {
			yield put(marketFilterActions.groupListReload(marketFilter.sportID));
		}

		yield put(actions.setValueUI('loading', false));

	});
}

function* saveAll() {
	yield takeEvery(actions.MARKETS_GROUPS_SAVE_ALL, function* (action) {
		yield put(actions.setValueUI('loading', true));
		const groupsList = action.data;
		const preparedList = prepareGroupsList(groupsList);
		try {
			const res = yield call(marketsAPI.combinationGroupSaveAll, preparedList);
			if (res && res.status === 200) {
				showSuccess(messages.successSaveAll);
			}
		} catch (error) {
			showError(messages.errorSaveAllGroups, error);
		}
		yield put(actions.setValueUI('loading', false));

		yield put(actions.listReload());
	});
}

function* listReorder() {

	const takeAction   = actions.MARKETS_GROUPS_LIST_REORDER;


	yield takeEvery(takeAction, function* (action) {

		yield put(actions.setValueUI('loading', true));

		const groupsList = action.data;
		try {
			const response = yield call(marketsAPI.combinationGroupUpdateOrder, groupsList);
			if (response && response.status === 200) {
				showSuccess(messages.successOrderUpdate);
			}

		} catch (error) {
			showError(messages.errorUpdateGroupsOrder, error);
			logger.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* marketsGroupsSaga() {
	yield all([
		fork(listReload),
		fork(deleteGroup),
		fork(saveGroup),
		fork(listReorder),
		fork(saveAll),
	]);
}
