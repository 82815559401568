const actions = {

	TRANSACTIONS_LIST_RELOAD                 : 'TRANSACTIONS_LIST_RELOAD',
	TRANSACTIONS_DATA_REFRESH                : 'TRANSACTIONS_DATA_REFRESH',
	TRANSACTIONS_NEW_TRANSACTION_LIST_REFRESH: 'TRANSACTIONS_NEW_TRANSACTION_LIST_REFRESH',

	TRANSACTIONS_FILTER_RESET  : 'TRANSACTIONS_FILTER_RESET',
	TRANSACTIONS_FILTER_REFRESH: 'TRANSACTIONS_FILTER_REFRESH',
	TRANSACTIONS_FILTER_APPLY  : 'TRANSACTIONS_FILTER_APPLY',

	TRANSACTIONS_UI_REFRESH     : 'TRANSACTIONS_UI_REFRESH',
	TRANSACTIONS_SORTING_REFRESH: 'TRANSACTIONS_SORTING_REFRESH',

	TRANSACTIONS_SOCKET_SUBSCRIBE_TO_UPDATE      : 'TRANSACTIONS_SOCKET_SUBSCRIBE_TO_UPDATE',
	TRANSACTIONS_SOCKET_NOTIFY_CREATE_TRANSACTION: 'TRANSACTIONS_SOCKET_NOTIFY_CREATE_TRANSACTION',
	TRANSACTIONS_SOCKET_NOTIFY_UPDATE_TRANSACTION: 'TRANSACTIONS_SOCKET_NOTIFY_UPDATE_TRANSACTION',

	TRANSACTIONS_WEBSITES_LIST_RELOAD : 'TRANSACTIONS_WEBSITES_LIST_RELOAD',
	TRANSACTIONS_WEBSITES_LIST_REFRESH: 'TRANSACTIONS_WEBSITES_LIST_REFRESH',
	TRANSACTIONS_DATA_MODAL_REFRESH	  : 'TRANSACTIONS_DATA_MODAL_REFRESH',
	RESET_TRANSACTIONS												    : 'RESET_TRANSACTIONS',
	FILTER_RESET                      : 'TRANSACTIONS_DATA_FILTER_RESET',

	// report
	TRANSACTIONS_REPORT_DATA_RELOAD     			: 'TRANSACTIONS_REPORT_DATA_RELOAD',
	TRANSACTIONS_REPORT_DATA_FIELDS_REFRESH: 'TRANSACTIONS_REPORT_DATA_FIELDS_REFRESH',
	
	filterDataReset: () => ({
		type: actions.FILTER_RESET,
	}),
	listReload: () => ({
		type: actions.TRANSACTIONS_LIST_RELOAD,
	}),
	dataRefresh: (transactionIDs, entities) => ({
		type: actions.TRANSACTIONS_DATA_REFRESH,
		data: {
			transactionIDs,
			entities,
		},
	}),
	newTransactionListRefresh: (newTransactionIDs, newTransactionEntities) => ({
		type: actions.TRANSACTIONS_NEW_TRANSACTION_LIST_REFRESH,
		data: {
			newTransactionIDs,
			newTransactionEntities,
		},
	}),

	filterReset: (data) => ({
		type: actions.TRANSACTIONS_FILTER_RESET,
		data,
	}),
	filterRefresh: data => ({
		type: actions.TRANSACTIONS_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.TRANSACTIONS_FILTER_APPLY,
	}),

	uiRefresh: data => ({
		type: actions.TRANSACTIONS_UI_REFRESH,
		data,
	}),
	sortingRefresh: data => ({
		type: actions.TRANSACTIONS_SORTING_REFRESH,
		data,
	}),

	notifyCreateTransaction: data => ({
		type: actions.TRANSACTIONS_SOCKET_NOTIFY_CREATE_TRANSACTION,
		data,
	}),
	notifyUpdateTransaction: data => ({
		type: actions.TRANSACTIONS_SOCKET_NOTIFY_UPDATE_TRANSACTION,
		data,
	}),
	subscribeToUpdate: transactionIDs => ({
		type: actions.TRANSACTIONS_SOCKET_SUBSCRIBE_TO_UPDATE,
		data: {
			transactionIDs,
		},
	}),

	websitesListReload: () => ({
		type: actions.TRANSACTIONS_WEBSITES_LIST_RELOAD,
	}),

	websitesListRefresh: list => ({
		type: actions.TRANSACTIONS_WEBSITES_LIST_REFRESH,
		data: list,
	}),

	dataModalRefresh: data => ({
		type:	actions.TRANSACTIONS_DATA_MODAL_REFRESH,
		data,
	}),
	dataReset: () => ({
		type:	actions.RESET_TRANSACTIONS,
	}),

	dataReload: () => ({
		type: actions.TRANSACTIONS_REPORT_DATA_RELOAD,
	}),
	reportFieldsRefresh: reportFields => ({
		type: actions.TRANSACTIONS_REPORT_DATA_FIELDS_REFRESH,
		reportFields,
	}),
};

export default actions;
