import { makeActionCreator } from '../../helpers';

const prefix = 'RTM_USER_BETS';

const TYPES = {
	LIST_RELOAD   : `${prefix}_LIST_RELOAD`,
	DATA_REFRESH  : `${prefix}_DATA_REFRESH`,
	FILTER_REFRESH: `${prefix}_FILTER_REFRESH`,
	FILTER_APPLY  : `${prefix}_FILTER_APPLY`,
	FILTER_RESET  : `${prefix}_FILTER_RESET`,
	UI_REFRESH    : `${prefix}_UI_REFRESH`,

	COUNTRY_LIST_RELOAD : `${prefix}_COUNTRY_LIST_RELOAD`,
	COUNTRY_LIST_REFRESH: `${prefix}_COUNTRY_LIST_REFRESH`,
	LEAGUE_LIST_RELOAD  : `${prefix}_LEAGUE_LIST_RELOAD`,
	LEAGUE_LIST_REFRESH : `${prefix}_LEAGUE_LIST_REFRESH`,
	EVENT_LIST_RELOAD   : `${prefix}_EVENT_LIST_RELOAD`,
	EVENT_LIST_REFRESH  : `${prefix}_EVENT_LIST_REFRESH`,

	LOADED_COUNTRY_PARENT_ID_REFRESH: `${prefix}_LOADED_COUNTRY_PARENT_ID_REFRESH`,
	LOADED_LEAGUE_PARENT_ID_REFRESH : `${prefix}_LOADED_LEAGUE_PARENT_ID_REFRESH`,
	LOADED_EVENT_PARENT_ID_REFRESH  : `${prefix}_LOADED_EVENT_PARENT_ID_REFRESH`,
};

const actions = {
	...TYPES,

	listReload   : makeActionCreator(TYPES.LIST_RELOAD),
	dataRefresh  : makeActionCreator(TYPES.DATA_REFRESH, 'betIDs', 'entities'),
	filterRefresh: makeActionCreator(TYPES.FILTER_REFRESH, 'filter'),
	filterApply  : makeActionCreator(TYPES.FILTER_APPLY),
	filterReset  : makeActionCreator(TYPES.FILTER_RESET),
	uiRefresh    : makeActionCreator(TYPES.UI_REFRESH, 'UI'),

	countryListReload: makeActionCreator(TYPES.COUNTRY_LIST_RELOAD, 'newSportIDs', 'oldSportIDs'),
	leagueListReload : makeActionCreator(TYPES.LEAGUE_LIST_RELOAD, 'newCountryIDs', 'oldCountryIDs'),
	eventListReload  : makeActionCreator(TYPES.EVENT_LIST_RELOAD, 'newLeagueIDs', 'oldLeagueIDs'),

	countryListRefresh: makeActionCreator(TYPES.COUNTRY_LIST_REFRESH, 'countryList'),
	leagueListRefresh : makeActionCreator(TYPES.LEAGUE_LIST_REFRESH, 'leagueList'),
	eventListRefresh  : makeActionCreator(TYPES.EVENT_LIST_REFRESH, 'eventList'),

	loadedCountryParentIDsRefresh: makeActionCreator(TYPES.LOADED_COUNTRY_PARENT_ID_REFRESH, 'sportID'),
	loadedLeagueParentIDsRefresh : makeActionCreator(TYPES.LOADED_LEAGUE_PARENT_ID_REFRESH, 'sportID'),
	loadedEventParentIDsRefresh  : makeActionCreator(TYPES.LOADED_EVENT_PARENT_ID_REFRESH, 'leagueID'),
};

export default actions;
