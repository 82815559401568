import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import actions from './actions';
import { historyAPI } from '../../../helpers/api/history';
import { showError } from '../../../helpers/notifications';
import { adaptHistory } from './utils';
import { logger } from '../../../helpers/logger';

const messages = {
	errorHistoryListLoad: 'responsibleGambling.error.history.list.load',
};

function* historyLogsReload() {

	yield takeEvery(actions.HISTORY_LOGS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { userID } = action.data;
		let historyLogs = [];
		try {
			const res = yield call(historyAPI.historyResponsibleGamblingLogs, userID);
			if (res && res.status === 200) {
				historyLogs = adaptHistory(res.data.data);
				yield put(actions.historyLogsRefresh(historyLogs));
			}
		} catch (error) {
			showError(messages.errorHistoryListLoad, error);
			logger.log(error);
		}

		yield put(actions.historyLogsRefresh(historyLogs));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* responsibleGamblingSaga() {
	yield all([
		fork(historyLogsReload),
	]);
}
