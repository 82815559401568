const actions = {

	BANNER_DATA_RESET       : 'BANNER_DATA_RESET',
	BANNER_BASE_DATA_RELOAD : 'BANNER_BASE_DATA_RELOAD',
	BANNER_BASE_DATA_REFRESH: 'BANNER_BASE_DATA_REFRESH',
	BANNER_DESC_DATA_RELOAD : 'BANNER_DESC_DATA_RELOAD',
	BANNER_DESC_DATA_REFRESH: 'BANNER_DESC_DATA_REFRESH',

	BANNER_SAVE      : 'BANNER_SAVE',
	BANNER_UI_REFRESH: 'BANNER_UI_REFRESH',

	dataReset: () => ({
		type: actions.BANNER_DATA_RESET,
	}),
	baseDataReload: bannerID => ({
		type: actions.BANNER_BASE_DATA_RELOAD,
		data: {
			bannerID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.BANNER_BASE_DATA_REFRESH,
		data,
	}),
	descDataReload: bannerID => ({
		type: actions.BANNER_DESC_DATA_RELOAD,
		data: {
			bannerID,
		},
	}),
	descDataRefresh: data => ({
		type: actions.BANNER_DESC_DATA_REFRESH,
		data,
	}),

	dataSave: () => ({
		type: actions.BANNER_SAVE,
	}),
	uiRefresh: data => ({
		type: actions.BANNER_UI_REFRESH,
		data,
	}),
};

export default actions;
