import { createNamesAdapter, createQuestionsAdapter } from '../list/utils';

const namesAdapter = createNamesAdapter();
const questionsAdapter = createQuestionsAdapter();

// Prepare ----------------------------------------------------------------------------------------

export function prepareNames(baseData) {
	namesAdapter.clearExcludes();
	namesAdapter.addExcludeField('id');
	namesAdapter.addExcludeField('topicID');
	const preparedData = namesAdapter.prepareList(baseData);
	return preparedData;
}

export function prepareQuestions(baseData) {
	questionsAdapter.clearExcludes();
	questionsAdapter.addExcludeField('id');
	questionsAdapter.addExcludeField('topicID');
	questionsAdapter.addExcludeField('negativeRating');
	questionsAdapter.addExcludeField('neutralRating');
	questionsAdapter.addExcludeField('positiveRating');
	const preparedData = questionsAdapter.prepareList(baseData);
	return preparedData;
}
