
const prefix = 'WEBSITE_MODAL_RESPONSIBLE_GAMBLING_';

const actions = {
	DATA_RELOAD : `${prefix}RELOAD`,
	DATA_REFRESH: `${prefix}DATA_REFRESH`,
	DATA_SAVE   : `${prefix}DATA_SAVE`,
	UI_REFRESH  : `${prefix}UI_REFRESH`,
	DATA_RESET  : `${prefix}DATA_RESET`,


	baseDataReload: websiteID => ({
		type: actions.DATA_RELOAD,
		data: {
			websiteID,
		},
	}),

	dataSave: websiteID => ({
		type: actions.DATA_SAVE,
		data: {
			websiteID,
		},
	}),

	dataReset: () => ({
		type: actions.DATA_RESET,
	}),


	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),

	baseDataRefresh: data => ({
		type: actions.DATA_REFRESH,
		data,
	}),

};

export default actions;
