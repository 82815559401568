import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';
import { cloneDeep } from 'lodash';

const initState = new Map({
	payments             : {},
	paymentsListWithNames: {},
	paymentLimits        : {},
	paymentDescriptions  : {},

	baseMainData    : {},
	currencyMainData: {},
	namesData       : {},

	UI: {
		loading             : false,
		isMainRowDataChanges: {},
		isCurrencyChanges   : {},
		isBaseDataChanges   : {},
		isNamesChanges      : {},
		tabsNames           : {},
		langIDs             : {},
	},
});

export default function websiteModalPaymentsReducer(state = initState, action) {

	switch (action.type) {

		case actions.WEBSITE_PAYMENTS_DATA_REFRESH: {
			return state.set('payments', action.data);
		}

		case actions.WEBSITE_PAYMENTS_LIST_NAMES_DATA_REFRESH: {
			return state.set('paymentsListWithNames', action.data);
		}

		case actions.WEBSITE_PAYMENT_LIMITS_DATA_REFRESH: {
			const { websitePaymentID, limitsData } = action.data;
			const paymentLimits = cloneDeep(state.get('paymentLimits'));
			paymentLimits[websitePaymentID] = limitsData;
			return state.set('paymentLimits', paymentLimits);
		}

		case actions.WEBSITE_PAYMENT_DESCRIPTION_DATA_REFRESH: {
			const { websitePaymentID, data } = action.data;
			const paymentDescriptions = cloneDeep(state.get('paymentDescriptions'));
			paymentDescriptions[websitePaymentID] = data;
			return state.set('paymentDescriptions', paymentDescriptions);
		}

		case actions.WEBSITE_PAYMENTS_LIST_BASE_MAIN_DATA_REFRESH: {
			const { entities } = action.data;
			return state.set('baseMainData', entities);
		}

		case actions.WEBSITE_PAYMENTS_CURRENCY_MAIN_DATA_REFRESH: {
			return state.set('currencyMainData', action.data);
		}

		case actions.WEBSITE_PAYMENT_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.WEBSITE_PAYMENT_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.WEBSITE_PAYMENTS_RESET: {
			return initState;
		}


		default:
			return state;
	}
}
