import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const CustomerIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.0005 6.90012C9.72871 6.90012 11.1294 5.50453 11.1294 3.78326C11.1294 2.06198 9.72845 0.666656 8.0005 0.666656C6.27256 0.666656 4.87103 2.06224 4.87103 3.78352C4.87103 5.5048 6.27256 6.90012 8.0005 6.90012ZM9.32775 7.11256H6.67273C4.46367 7.11256 2.6665 8.90306 2.6665 11.1036V14.338L2.67476 14.3886L2.89841 14.4584C5.00655 15.1145 6.83807 15.3333 8.34556 15.3333C11.29 15.3333 12.9966 14.4971 13.1018 14.4438L13.3108 14.3385H13.3332V11.1036C13.334 8.90306 11.5368 7.11256 9.32775 7.11256Z" fill={color} />
		</svg>
	);
};
CustomerIcon.propTypes = propTypesIcons;

CustomerIcon.defaultProps = defaultPropsIcons;
