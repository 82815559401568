
import { language } from '../../../config';
import { LANGUAGES } from '../../../helpers/commonConstants';

import englishLang from '../../../image/flag/uk.svg';
import russianLang from '../../../image/flag/rus.svg';
import ukrainianLang from '../../../image/flag/ukraine.svg';
import turkishLang from '../../../image/flag/turkey.svg';
import germanLang from '../../../image/flag/germany.svg';

const config = {
	defaultLanguage: language,
	options        : [
		{
			languageId    : 'english',
			locale        : 'en',
			text          : 'English',
			icon          : englishLang,
			dataBaseLangID: LANGUAGES.en,
		},
		{
			languageId    : 'russian',
			locale        : 'ru',
			text          : 'Русский',
			icon          : russianLang,
			dataBaseLangID: LANGUAGES.ru,
		},
		{
			languageId    : 'ukrainian',
			locale        : 'ua',
			text          : 'Українська',
			icon          : ukrainianLang,
			dataBaseLangID: LANGUAGES.ua,
		},
		{
			languageId    : 'turkish',
			locale        : 'tr',
			text          : 'Türk',
			icon          : turkishLang,
			dataBaseLangID: LANGUAGES.tr,
		},
		{
			languageId    : 'german',
			locale        : 'de',
			text          : 'German',
			icon          : germanLang,
			dataBaseLangID: LANGUAGES.de,
		},

	],
};

export function getCurrentLanguage(lang) {
	let selectedLanguage = config.options[0];
	config.options.forEach(language => {
		if (language.dataBaseLangID === Number(lang)) {
			selectedLanguage = language;
		}
	});
	return selectedLanguage;
}
export default config;
