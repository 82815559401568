import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import groupBy from 'lodash/groupBy';
import { DURATIONS } from '../../../helpers/commonConstants';
import { EVENT_STATUS } from '../../../constants/events';

import EntityAdapter from '../../../helpers/entityAdapter';
import { isID } from '../../../helpers/utils';
// import { getMillisecondsOfTime } from '../../helpers/utility';
import ParamsBuilder from '../../../helpers/paramsBuilder';

export const fields = {
	id                : 'id',                          // "75487"
	code              : 'code',                        // ""
	name              : 'name',                        // "Italy - Scotland"
	startDate         : 'start_date',                  // "2019-02-02T14:15:00.000Z"
	statusID          : 'status_id',                   // 1
	statusIDs         : 'status_ids',                   // 1
	tournamentTypeID  : 'tournament_type_id',          // 1
	tradingModeID     : 'trading_mode',                // 2
	regulationTypeID  : 'regulation_type_id',          // 0
	resolutionStatusID: 'resolution_status_id',        // 2
	sourceID          : 'source_id',                   // 2
	sourceTypeID      : 'source_type_id',              // 1
	homePriorityTypeID: 'home_priority_type_id',       // 0
	marketsCount      : 'markets_count',               // "2"

	sportID        : 'sport_id',                    // "880"
	sportName      : 'sport_name',                  // "Rugby Union"
	sportStatusID  : 'sport_status_id',             // "1"
	countryID      : 'country_id',                  // "31712"
	countryName    : 'country_name',                // "European Union"
	countryStatusID: 'country_status_id',           // "1"
	leagueID       : 'league_id',                   // "53891"
	leagueName     : 'league_name',                 // "Six Nations"
	leagueStatusID : 'league_status_id',            // "1"

	overrideUserStakeLimit   : 'override_user_stake_limit',   // false
	overrideUserWonLimit     : 'override_user_won_limit',     // false
	userStakeLimit           : 'user_stake_limit',            // 0
	userStakeLimitLocal      : 'user_stake_limit_local',      // 100
	userStakeLimitValueTypeID: 'user_stake_limit_value_type', // 2
	userWonLimit             : 'user_won_limit',              // 0
	userWonLimitLocal        : 'user_won_limit_local',        // 100
	userWonLimitValueTypeID  : 'user_won_limit_value_type',   // 2

	participantsIDs      : 'participants',                // ["16935761", "26703"]
	providers            : 'providers',                   // [{id: 2, name: "RedKings", icon_url: "/uploads/redkings.png"}]
	restrictedBetTypesIDs: 'restricted_bet_types',        // []
	restrictedEventsIDs  : 'restricted_events',           // []
	additionalInfo       : 'additional_info',             // null
	allowMixedMultiplies : 'allow_mixed_multiplies',      // true
	autoSendLog          : 'autoSendLog',                 // true
	combinationMax       : 'combination_max',             // 0
	combinationMin       : 'combination_min',             // 0
	currentTime          : 'current_time',                // "0.00"
	notes                : 'notes',                       // []

	providersIDs      : 'providers_id',
	activeOnly        : 'active_only',
	category          : 'category',
	activeMarketsCount: 'active_markets_count',
};

const extFields = {
	// settings
	eventID         : 'id',
	eventMarketID   : 'event_market_id',
	eventSelectionID: 'event_selection_id',
	tradingModeID   : 'trading_mode',

	betStakeLimit           : 'bet_stake_limit',
	stakeLimitParentAbsolute: 'parent_bet_stake_limit',
	betStakeLimitValueType  : 'bet_stake_limit_value_type',
	stakeLimitOverride      : 'override_stake_limit',

	parentBetStakeLimit 		: 'parent_bet_stake_limit',
	parentBetWonLimit   		: 'parent_bet_won_limit',
	// wonLimitParent                 : 'bet_won_limit',
	// betWonLimit                    : 'bet_won_limit',
	wonLimitParentAbsolute: 'parent_bet_won_limit',
	betWonLimit           : 'bet_won_limit',
	betWonLimitValueType  : 'bet_won_limit_value_type',
	wonLimitOverride      : 'override_won_limit',

	betDelay: 'bet_delay',
	overask : 'overask',

	acceptBetsBeforeStart: 'accept_bets_before_start',
	// acceptBetsBeforeStartTimeValue : 'acceptBetsBeforeStartTimeValue',
	// acceptBetsBeforeStartTimeID    : 'acceptBetsBeforeStartTimeValue',

	// dynamic limits
	id                           : 'id',
	marketID                     : 'event_market_id',
	selectionID                  : 'event_selection_id',
	minutesBefore                : 'minutes_before',
	limit                        : 'limit',
	timeTypeID                   : 'timeTypeID',
	autoSendLog                  : 'autoSendLog',
	overrideAcceptBetsBeforeStart: 'override_accept_bets_before_start',
	overrideBetDelay             : 'override_bet_delay',
};

export const OWNERS = {
	event         : 1,
	eventMarket   : 2,
	eventSelection: 3,
};

const listAdapter              = createListAdapter();
const settingsAdapter          = createSettingsAdapter();
const dynamicLimitsAdapter     = createDynamicLimitsAdapter();

// Adapting ---------------------------------------------------------------------------------------

export function adaptEventsList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	adaptedData.forEach(item => {
		item.category = `${item.sportName} / ${item.countryName}`;
		if (item.leagueName) {
			item.category += ` / ${item.leagueName}`;
		}
	});

	return adaptedData;
}

export function adaptEventMarketsStatistics(data, groupByPropertyName) {
	return groupBy(data, groupByPropertyName);
}


export function adaptDynamiLimits(rawLimits = []) {
	let limits = cloneDeep(rawLimits);
	if (!isArray(limits)) {
		limits = [];
	}

	dynamicLimitsAdapter.clearExcludes();

	const dayDuration = DURATIONS.minutesInDay;
	const hourDuration = DURATIONS.minutesInHour;

	const adaptedLimits = dynamicLimitsAdapter.adaptList(limits);
	adaptedLimits.forEach(item => {
		const minutes = item.minutesBefore;
		const daysCount = minutes / dayDuration;
		const hoursCount = minutes / hourDuration;

		let timeTypeID = 1; // minutes
		let minutesBefore = minutes;

		// days
		if (daysCount >= 1 && Math.round(daysCount) === daysCount) {
			timeTypeID = 3;
			minutesBefore = daysCount;

			// hours
		} else if (hoursCount >= 1 && Math.round(hoursCount) === hoursCount) {
			timeTypeID = 2;
			minutesBefore = hoursCount;
		}

		item.timeTypeID = timeTypeID;
		item.minutesBefore = minutesBefore;
	});

	return adaptedLimits;
}

export function adaptSettings(rawSettings) {
	settingsAdapter.clearExcludes();
	const data = settingsAdapter.adapt(rawSettings);
	// if (owner !== OWNERS.event) {
	//   settingsAdapter.addExcludeField('betDelay');
	//   settingsAdapter.addExcludeField('overask');
	//   settingsAdapter.addExcludeField('acceptBetsBeforeStart');
	//   settingsAdapter.addExcludeField('acceptBetsBeforeStartTimeValue');
	//   settingsAdapter.addExcludeField('acceptBetsBeforeStartTimeID');
	// }

	// const createItem = function createSettingItem(modeID) {
	//   const item = settingsAdapter.adapt({});

	//   item.tradingModeID = modeID;
	//   item.stakeLimit = 100;
	//   item.stakeLimitTypeID = LIMIT_TYPES.percent;
	//   item.wonLimit = 100;
	//   item.wonLimitTypeID = LIMIT_TYPES.percent;

	//   if (owner === OWNERS.event) {
	//     item.betDelay = 0;
	//     item.overask = false;

	//     item.acceptBetsBeforeStart = 0;
	//     item.acceptBetsBeforeStartTimeValue = 0;
	//     item.acceptBetsBeforeStartTimeID = ACCEPT_BETS_TIMES.always;
	//   }

	//   return item;
	// };

	// const adaptItem = function adaptSettingItem(item) {
	//   const adaptedItem = settingsAdapter.adapt(item);
	//   if (owner === OWNERS.event) {
	//     const { acceptBetsBeforeStart } = item;

	//     adaptedItem.acceptBetsBeforeStartTimeValue = acceptBetsBeforeStart > 0
	//       ? getTimeOfMilliseconds(acceptBetsBeforeStart).count
	//       : 0;
	//     adaptedItem.acceptBetsBeforeStartTimeID = acceptBetsBeforeStart > 0
	//       ? getTimeOfMilliseconds(acceptBetsBeforeStart).id
	//       : ACCEPT_BETS_TIMES.always;
	//   }

	//   return adaptedItem;
	// };

	// let settingsData = cloneDeep(rawSettings);
	// if (!isArray(settingsData)) {
	//   settingsData = [];
	// }

	// let settingsItemPrematch = settingsData.find(
	//   item => toInteger(item[extFields.tradingModeID]) === TRADING_MODE.prematch,
	// );
	// let settingsItemLive = settingsData.find(
	//   item => toInteger(item[extFields.tradingModeID]) === TRADING_MODE.live,
	// );

	// settingsItemPrematch = settingsItemPrematch
	//   ? adaptItem(settingsItemPrematch)
	//   : createItem(TRADING_MODE.prematch);

	// settingsItemLive = settingsItemLive
	//   ? adaptItem(settingsItemLive)
	//   : createItem(TRADING_MODE.live);

	return data;
}

// Preparing --------------------------------------------------------------------------------------

export function prepareDynamicLimits(rawData = [], owner = OWNERS.event, entityID) {
	const limits = cloneDeep(rawData);
	if (!isArray(limits)) {
		return [];
	}

	dynamicLimitsAdapter.clearExcludes();
	dynamicLimitsAdapter.addExcludeField('id');
	dynamicLimitsAdapter.addExcludeField('timeTypeID');

	const dayDuration = DURATIONS.minutesInDay;
	const hourDuration = DURATIONS.minutesInHour;

	const preparedLimits = limits.map(item => {
		const minutes = toInteger(item.minutesBefore);
		const timeTypeID = toInteger(item.timeTypeID);

		let minutesBefore = minutes;
		// hours
		if (timeTypeID === 2) {
			minutesBefore = minutes * hourDuration;
			// days
		} else if (timeTypeID === 3) {
			minutesBefore = minutes * dayDuration;
		}

		item.minutesBefore = minutesBefore;

		const preparedItem = dynamicLimitsAdapter.prepare(item);
		if (isID(item.id)) {
			preparedItem.id = toInteger(item.id);
		}

		if (owner === OWNERS.eventMarket && isID(entityID)) {
			preparedItem[extFields.eventMarketID] = toInteger(entityID);
		}
		if (owner === OWNERS.eventSelection && isID(entityID)) {
			preparedItem[extFields.eventSelectionID] = toInteger(entityID);
		}

		return preparedItem;
	});

	return preparedLimits;
}

export function prepareSettings(settings /*owner = OWNERS.event, eventTradingModeID = TRADING_MODE.both*/) {
	// if (!isArray(settings)) {
	//   return [];
	// }

	// settingsAdapter.clearExcludes();
	// settingsAdapter.addExcludeField('stakeLimitParentAbsolute');
	// settingsAdapter.addExcludeField('wonLimitParentAbsolute');

	// if (owner !== OWNERS.event) {
	//   settingsAdapter.addExcludeField('betDelay');
	//   settingsAdapter.addExcludeField('overask');
	//   settingsAdapter.addExcludeField('acceptBetsBeforeStart');
	//   settingsAdapter.addExcludeField('acceptBetsBeforeStartTimeValue');
	//   settingsAdapter.addExcludeField('acceptBetsBeforeStartTimeID');
	// }

	// const preparedSettings;

	// settings.forEach(item => {
	// if (
	//   eventTradingModeID !== TRADING_MODE.both
	//   && settings.tradingModeID !== eventTradingModeID
	// ) {
	//   return;
	// }

	// settings.stakeLimitParent = calculateLimit({
	//   override       : settings.stakeLimitOverride,
	//   limitLocal     : settings.stakeLimit,
	//   limitTypeID    : settings.stakeLimitTypeID,
	//   parentAbsolute : settings.stakeLimitParentAbsolute,
	// });
	// settings.wonLimitParent = calculateLimit({
	//   override       : settings.wonLimitOverride,
	//   limitLocal     : settings.wonLimit,
	//   limitTypeID    : settings.wonLimitTypeID,
	//   parentAbsolute : settings.wonLimitParentAbsolute,
	// });

	// if (owner === OWNERS.event) {
	//   settings.acceptBetsBeforeStart = getMillisecondsOfTime(settings.acceptBetsBeforeStartTimeValue, settings.acceptBetsBeforeStartTimeID);
	// }

	const preparedItem = settingsAdapter.prepare(settings);
	// if (isID(settings.id)) {
	//   preparedItem.id = toInteger(settings.id);
	// }

	// preparedSettings.push(preparedItem);
	// });

	return preparedItem;
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'tournamentTypeID', fields.tournamentTypeID);
	adapter.addField(rules.intOrNull, 'tradingModeID', fields.tradingModeID);
	adapter.addField(rules.intOrNull, 'regulationTypeID', fields.regulationTypeID);
	adapter.addField(rules.intOrNull, 'resolutionStatusID', fields.resolutionStatusID);
	adapter.addField(rules.intOrNull, 'sourceID', fields.sourceID);
	adapter.addField(rules.intOrNull, 'sourceTypeID', fields.sourceTypeID);
	adapter.addField(rules.intOrNull, 'homePriorityTypeID', fields.homePriorityTypeID);
	adapter.addField(rules.intOrNull, 'sportID', fields.sportID);
	adapter.addField(rules.intOrNull, 'sportStatusID', fields.sportStatusID);
	adapter.addField(rules.intOrNull, 'countryID', fields.countryID);
	adapter.addField(rules.intOrNull, 'countryStatusID', fields.countryStatusID);
	adapter.addField(rules.intOrNull, 'leagueID', fields.leagueID);
	adapter.addField(rules.intOrNull, 'leagueStatusID', fields.leagueStatusID);
	adapter.addField(rules.intOrNull, 'userStakeLimitValueTypeID', fields.userStakeLimitValueTypeID);
	adapter.addField(rules.intOrNull, 'userWonLimitValueTypeID', fields.userWonLimitValueTypeID);
	adapter.addField(rules.intOrNull, 'homePriorityTypeID', fields.homePriorityTypeID);

	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'sportName', fields.sportName);
	adapter.addField(rules.string, 'countryName', fields.countryName);
	adapter.addField(rules.string, 'leagueName', fields.leagueName);
	adapter.addField(rules.string, 'additionalInfo', fields.additionalInfo);

	adapter.addField(rules.bool, 'overrideUserStakeLimit', fields.overrideUserStakeLimit);
	adapter.addField(rules.bool, 'overrideUserWonLimit', fields.overrideUserWonLimit);
	adapter.addField(rules.bool, 'allowMixedMultiplies', fields.allowMixedMultiplies);
	adapter.addField(rules.bool, 'autoSendLog', fields.autoSendLog);

	adapter.addField(rules.positiveNumber, 'userStakeLimit', fields.userStakeLimit);
	adapter.addField(rules.positiveNumber, 'userStakeLimitLocal', fields.userStakeLimitLocal);
	adapter.addField(rules.positiveNumber, 'userWonLimit', fields.userWonLimit);
	adapter.addField(rules.positiveNumber, 'userWonLimitLocal', fields.userWonLimitLocal);
	adapter.addField(rules.positiveNumber, 'activeMarketsCount', fields.activeMarketsCount);

	adapter.addField(rules.positiveNumber, 'marketsCount', fields.marketsCount);
	adapter.addField(rules.positiveNumber, 'combinationMax', fields.combinationMax);
	adapter.addField(rules.positiveNumber, 'combinationMin', fields.combinationMin);

	adapter.addField(rules.fullDate, 'startDate', fields.startDate);

	adapter.addField(rules.arrayID, 'participantsIDs', fields.participantsIDs);
	adapter.addField(rules.arrayID, 'restrictedBetTypesIDs', fields.restrictedBetTypesIDs);
	adapter.addField(rules.arrayID, 'restrictedEventsIDs', fields.restrictedEventsIDs);

	adapter.addField(rules.arrayString, 'notes', fields.notes);
	adapter.addField(rules.noCondition, 'providers', fields.providers);

	return adapter;
}

function createSettingsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;
	//  accept_bets_before_start: 0
	// autoSendLog: true
	// bet_delay: 0
	// bet_stake_limit: 0
	// bet_stake_limit_value_type: 2
	// bet_won_limit: 0
	// bet_won_limit_value_type: 2
	// id: 159876
	// overask: true
	// override_accept_bets_before_start: false
	// override_bet_delay: false
	// override_stake_limit: false
	// override_won_limit: false
	adapter.addField(rules.bool,           'autoSendLog',                   extFields.autoSendLog);
	adapter.addField(rules.positiveNumber, 'betDelay',                      extFields.betDelay);
	adapter.addField(rules.positiveNumber, 'betStakeLimit',                 extFields.betStakeLimit);
	adapter.addField(rules.id,             'betStakeLimitValueType',        extFields.betStakeLimitValueType);
	adapter.addField(rules.id,             'betWonLimitValueType',          extFields.betWonLimitValueType);
	adapter.addField(rules.id,             'evenID',                        extFields.id);
	adapter.addField(rules.bool,           'overask',                       extFields.overask);
	adapter.addField(rules.positiveNumber, 'acceptBetsBeforeStart',         extFields.acceptBetsBeforeStart);
	adapter.addField(rules.bool,           'overrideAcceptBetsBeforeStart', extFields.overrideAcceptBetsBeforeStart);
	adapter.addField(rules.bool,           'overrideBetDelay',              extFields.overrideBetDelay);
	adapter.addField(rules.bool,           'stakeLimitOverride',            extFields.stakeLimitOverride);
	adapter.addField(rules.bool,           'wonLimitOverride',              extFields.wonLimitOverride);
	adapter.addField(rules.intOrNull,      'tradingModeID',                 extFields.tradingModeID);
	adapter.addField(rules.positiveNumber, 'stakeLimitParentAbsolute',      extFields.stakeLimitParentAbsolute);
	adapter.addField(rules.positiveNumber, 'betWonLimit',                   extFields.betWonLimit);
	adapter.addField(rules.positiveNumber, 'stakeLimit',                    extFields.stakeLimit);
	adapter.addField(rules.positiveNumber, 'wonLimitParentAbsolute',        extFields.wonLimitParentAbsolute);
	adapter.addField(rules.positiveNumber, 'parentBetStakeLimit',			extFields.parentBetStakeLimit);
	adapter.addField(rules.positiveNumber, 'parentBetWonLimit',				extFields.parentBetWonLimit);


	// adapter.addField(rules.id,             'betWonLimit',                   extFields.betWonLimit);
	// adapter.addField(rules.positiveNumber, 'wonLimitParent',                extFields.wonLimitParent);
	// adapter.addField(rules.positiveNumber, 'stakeLimitParent',              extFields.stakeLimitParent);
	// adapter.addField(rules.positiveNumber, 'acceptBetsBeforeStart', extFields.acceptBetsBeforeStart);
	// // adapter.addField(rules.positiveNumber, 'acceptBetsBeforeStartTimeValue', extFields.acceptBetsBeforeStartTimeValue);

	return adapter;
}

function createDynamicLimitsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', extFields.id);
	adapter.addField(rules.id, 'tradingModeID', extFields.tradingModeID);
	adapter.addField(rules.id, 'timeTypeID', extFields.timeTypeID);

	adapter.addField(rules.positiveNumber, 'minutesBefore', extFields.minutesBefore);
	adapter.addField(rules.positiveNumber, 'limit', extFields.limit);

	return adapter;
}

// Service ----------------------------------------------------------------------------------------
export function prepareEventsParams(sportID, date) {

	return {
		sportID,
		dateRange: [date, date],
	};
}

export function prepareListParams(filter, pagination, sorting = null, tradingMode = null, sportID = null) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		if (fields[sorting.sortBy]) {
			builder.addValue('sort_order', sorting.sortOrder);
		}
	}

	if (pagination) {
		builder.addValue('page', pagination.currentPage);
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('include_providers', false);
	}
	if (tradingMode) {
		builder.addValue('trading_mode', tradingMode);
	}
	if (sportID) {
		builder.addValue('sport_id', sportID);
	}

	if (filter) {
		builder.addField(rules.isID, 'sportID', fields.sportID);
		builder.addField(rules.isID, 'eventID', fields.id);
		builder.addField(rules.isID, 'countryID', fields.countryID);
		builder.addField(rules.isID, 'leagueID', fields.leagueID);
		builder.addField(rules.isID, 'tradingMode', fields.tradingModeID);
		builder.addField(rules.isString, 'eventName', fields.name);
		builder.addField(rules.isArrayID, 'providerListIDs', fields.providersIDs);
		builder.addField(rules.isArrayID, 'statusID', fields.statusIDs);
		if (filter.dateRange[0] && filter.dateRange[1]) {
			builder.addRangeField(rules.isDateTimeRange, filter.dateRange, [
				'date_from',
				'date_to',
			]);
		}

		if ( filter.statusID !== EVENT_STATUS.active ) {
			filter.activeOnly = false;
			builder.addField(rules.isBoolean, 'activeOnly', fields.activeOnly);
		}
	}

	return builder.biuldParams(filter);

}
