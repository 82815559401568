const actions = {

	LIMIT_AND_RESTRICTION_CASINO_SEARCH_REFRESH   : 'LIMIT_AND_RESTRICTION_CASINO_SEARCH_REFRESH',
	LIMIT_AND_RESTRICTION_CASINO_COMPONENT_REFRESH: 'LIMIT_AND_RESTRICTION_CASINO_COMPONENT_REFRESH',

	searchRefresh: data => (
		{
			type: actions.LIMIT_AND_RESTRICTION_CASINO_SEARCH_REFRESH,
			data,
		}
	),


};

export default actions;
