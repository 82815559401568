import { apiRequest } from './index';
// SportRiskManagement settings ----------------------------------------------------------------------------------------

function riskManagementSettingsList(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/website/${websiteID}/risk_management`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function riskManagementSettingsCreate(websiteID, data,  params = {}) {

	const req = {
		method: 'POST',
		url   : `/website/${websiteID}/risk_management`,
		data,
		params,
	};

	return apiRequest(req);
}

function riskManagementSettingsUpdate(websiteID, settingId, data, params = {}) {

	const req = {
		method: 'PUT',
		url   : `/website/${websiteID}/risk_management/${settingId}`,
		data,
		params,
	};

	return apiRequest(req);
}

function riskManagementSettingsDelate(websiteID, settingId, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/website/${websiteID}/risk_management/${settingId}`,
		params,
	};

	return apiRequest(req);
}
//RTM
function riskList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/rtm/risk_alerts',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}
function riskCommentCreate(riskID, data, params = {}) {
	const req = {
		method: 'PUT',
		url   : `/rtm/risk_alerts/${riskID}`,
		data,
		params,
	};

	return apiRequest(req);
}


export const RiskManagementSettingsApi = {
	riskManagementSettingsList,
	riskManagementSettingsCreate,
	riskManagementSettingsUpdate,
	riskManagementSettingsDelate,
	riskList,
	riskCommentCreate,
};
