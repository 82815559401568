import isArray from 'lodash/isArray';

import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { adaptHistoryList } from '../utils';
import { createEntities } from '../../../utility';

export const fields = {
	id                : 'id',
	name              : 'name',                 // string;
	statusID          : 'status_id',            // BonusStatus;
	amount            : 'amount',               // number;
	currencyID        : 'currency_id',          // number;
	startDate         : 'start_date',           // Date;
	endDate           : 'end_date',             // Date;
	websiteID         : 'website_id',           // number
	period            : 'period',               // number;
	minWageringOdd    : 'min_wagering_odd',     // number;
	expressWageringOdd: 'express_wagering_odd', // number;
	wageringTurnover  : 'wagering_turnover',    // number;
	maxDepositAmount  : 'max_deposit_amount',   // number;
	daysToExpire      : 'days_to_expire',       // number;
	//filter
	startDateFrom     : 'start_date_from',     // Date;
	startDateTo       : 'start_date_to',        // Date;
	endDateFrom       : 'end_date_from',       // Date;
	endDateTo         : 'end_date_to',          // Date;
	gameIDs           : 'game_ids',            // array;
	allow             : 'allow',               // bool;
};

const listAdapter = createListAdapter();

export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'statusID', fields.statusID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);

	builder.addField(rules.isPositiveNumber, 'amount', fields.amount);
	builder.addField(rules.isPositiveNumber, 'period', fields.period);
	builder.addField(rules.isPositiveNumber, 'minWageringOdd', fields.minWageringOdd);
	builder.addField(rules.isPositiveNumber, 'expressWageringOdd', fields.expressWageringOdd);
	builder.addField(rules.isPositiveNumber, 'wageringTurnover', fields.wageringTurnover);
	builder.addField(rules.isPositiveNumber, 'maxDepositAmount', fields.maxDepositAmount);

	builder.addField(rules.isString, 'name', fields.name);

	builder.addRangeField(rules.isDateTimeRange, filter.startDate, [
		fields.startDateFrom,
		fields.startDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
		fields.endDateFrom,
		fields.endDateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return createEntities(adaptedData);
}

export function adaptHistory(rawData) {

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareBonusData(rawData = {}) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = rawData.id;
	}

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'currencyID', fields.currencyID);
	adapter.addField(rules.intOrNull, 'websiteID', fields.websiteID);

	adapter.addField(rules.string, 'name', fields.name);

	adapter.addField(rules.positiveNumber, 'amount', fields.amount);
	adapter.addField(rules.positiveNumber, 'period', fields.period);
	adapter.addField(rules.positiveNumber, 'minWageringOdd', fields.minWageringOdd);
	adapter.addField(rules.positiveNumber, 'expressWageringOdd', fields.expressWageringOdd);
	adapter.addField(rules.positiveNumber, 'wageringTurnover', fields.wageringTurnover);
	adapter.addField(rules.positiveNumber, 'maxDepositAmount', fields.maxDepositAmount);
	adapter.addField(rules.positiveNumber, 'daysToExpire', fields.daysToExpire);

	adapter.addField(rules.fullDate, 'startDate', fields.startDate);
	adapter.addField(rules.fullDate, 'endDate', fields.endDate);
	adapter.addField(rules.arrayNumber, 'gameIDs', fields.gameIDs);
	adapter.addField(rules.bool, 'allow', fields.allow);

	return adapter;
}
