const actions = {

	RESULT_HISTORY_MODEL_DATA_RELOAD : 'RESULT_HISTORY_MODEL_DATA_RELOAD',
	RESULT_HISTORY_MODEL_DATA_REFRESH: 'RESULT_HISTORY_MODEL_DATA_REFRESH',
	RESULT_HISTORY_UI_REFRESH        : 'RESULT_HISTORY_UI_REFRESH',
	RESULT_HISTORY_DATA_RESET        : 'RESULT_HISTORY_DATA_RESET',

	dataReload: eventSelectionID => ({
		type: actions.RESULT_HISTORY_MODEL_DATA_RELOAD,
		data: {
			eventSelectionID,
		},
	}),
	dataRefresh: logData => ({
		type: actions.RESULT_HISTORY_MODEL_DATA_REFRESH,
		data: {
			logData,
		},
	}),
	uiRefresh: data => ({
		type: actions.RESULT_HISTORY_UI_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.RESULT_HISTORY_DATA_RESET,
	}),
};

export default actions;
