const prefix = 'PARTNER_LIST_';

export const actions = {
	BASE_DATA_REFRESH           : `${prefix}BASE_DATA_REFRESH`,
	RESEND_INVITATION           : `${prefix}RESEND_INVITATION`,
	LIST_UI_REFRESH             : `${prefix}UI_REFRESH`,
	PARTNERS_WEBSITE_LIST_RELOAD: `${prefix}_PARTNERS_WEBSITE_LIST_RELOAD`,
	LIST_RELOAD                 : `${prefix}RELOAD`,
	LIST_UI_RESET               : `${prefix}UI_RESET`,
	LIST_DATA_RELOAD            : `${prefix}DATA_RELOAD`,

	GET_PARTNER   : `${prefix}GET_PARTNER`,
	CREATE_PARTNER: `${prefix}CREATE_PARTNER`,
	UPDATE_PARTNER: `${prefix}UPDATE_PARTNER`,
	DELETE_PARTNER: `${prefix}DELETE_PARTNERS`,

	PARTNERS_LIST_REFRESH                 : `${prefix}PARTNERS_LIST_REFRESH`,
	PARTNERS_SELECTED_REFRESH             : `${prefix}PARTNERS_SELECTED_REFRESH`,
	PARTNERS_WEBSITE_REFRESH              : `${prefix}PARTNERS_WEBSITE_REFRESH`,
	COMMON_LIST_RELOAD                    : `${prefix}COMMON_LIST_RELOAD`,
	DATA_RESET                            : `${prefix}DATA_RESET`,
	PARTNER_ATTACHED_LANGUAGES_LIST_RELOAD: `${prefix}ATTACHED_LANGUAGES_LIST_RELOAD`,
	WEBSITE_ATTACHED_LANGUAGES_LIST_RELOAD: `${prefix}WEBSITE_ATTACHED_LANGUAGES_LIST_RELOAD`,

	uiRefresh: (data) => {
		return {
			type: actions.LIST_UI_REFRESH,
			data,
		};
	},

	partnerListReload: (data) => {
		return {
			type: actions.LIST_RELOAD,
			data,
		};
	},
	partnersWithWebsitesReload: (data) => {
		return {
			type: actions.PARTNERS_WEBSITE_LIST_RELOAD,
			data,
		};
	},

	partnersListRefresh: data => {
		return {
			type: actions.PARTNERS_LIST_REFRESH,
			data,
		};
	},

	getPartnerById: (data, closeModal) => {
		return {
			type: actions.GET_PARTNER,
			data,
			closeModal,
		};
	},

	createPartner: data => {
		return {
			type: actions.CREATE_PARTNER,
			data,
		};
	},

	reSendInvitation: data => {
		return {
			type: actions.RESEND_INVITATION,
			data,
		};
	},

	updatePartner: data => {
		return {
			type: actions.UPDATE_PARTNER,
			data,
		};
	},


	baseDataRefresh: data => {
		return {
			type: actions.BASE_DATA_REFRESH,
			data,
		};
	},

	selectedPartnersAndWebsitesRefresh: data => ({
		type: actions.PARTNERS_SELECTED_REFRESH,
		data,
	}),

	partnerWebsiteRefresh: data => ({
		type: actions.PARTNERS_WEBSITE_REFRESH,
		data,
	}),
	deletePartner: data => ({
		type: actions.DELETE_PARTNER,
		data,
	}),

	commonListReload: data => ({
		type: actions.COMMON_LIST_RELOAD,
		data,
	}),
	partnerAttachedLanguages: data => ({
		type: actions.PARTNER_ATTACHED_LANGUAGES_LIST_RELOAD,
		data,
	}),
	websiteAttachedLanguages: data => ({
		type: actions.WEBSITE_ATTACHED_LANGUAGES_LIST_RELOAD,
		data,
	}),
	dataReset: () => ({
		type: actions.DATA_RESET,
	}),


};
