import { combineReducers } from 'redux';

import List from './list/reducer';
import Casino from './casino/reducer';
import CustomProviders from './customProviders/reducer';
import CustomProvider from './customProvider/reducer';
import Tags from './tags/reducer';
import Integrators from './integrators/reducer';
import Games from './games/reducer';
import Game from './game/reducer';
import GameCategories from './gameCategories/reducer';
import GameCategory from './gameCategory/reducer';
import Providers from './providers/reducer';
import CasinoGameBulk from './gamesBulk/reducer';

export default combineReducers({
	List,
	Casino,
	CustomProviders,
	CustomProvider,
	Games,
	Game,
	GameCategories,
	GameCategory,
	CasinoGameBulk,
	Providers,
	Tags,
	Integrators,
});
