import QueryToFilterValidaion from '../../../helpers/queryToFilterValidaion';


const { isValidID, isValidString, isValidArray, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	userID  : isValidID,
	statusID: isValidID,

	userName     : isValidString,
	userEmail    : isValidString,
	userFirstName: isValidString,
	userLastName : isValidString,

	role      : isValidArray,
	partnerIDs: isValidArray,
	websiteIDs: isValidArray,
    
	birthday: isValidDateRange,
};
