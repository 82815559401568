const actions = {

	MARKETS_FILTER_SET_VALUE                  : 'MARKETS_FILTER_SET_VALUE',
	MARKETS_FILTER_REFRESH                    : 'MARKETS_FILTER_REFRESH',
	MARKETS_FILTER_GROUP_LIST_RELOAD          : 'MARKETS_FILTER_GROUP_LIST_RELOAD',
	MARKETS_FILTER_GROUP_LIST_REFRESH         : 'MARKETS_FILTER_GROUP_LIST_REFRESH',
	MARKETS_FILTER_SCOPE_LIST_RELOAD          : 'MARKETS_FILTER_SCOPE_LIST_RELOAD',
	MARKETS_FILTER_SCOPE_LIST_REFRESH         : 'MARKETS_FILTER_SCOPE_LIST_REFRESH',
	MARKETS_FILTER_STATISTIC_TYPE_LIST_RELOAD : 'MARKETS_FILTER_STATISTIC_TYPE_LIST_RELOAD',
	MARKETS_FILTER_STATISTIC_TYPE_LIST_REFRESH: 'MARKETS_FILTER_STATISTIC_TYPE_LIST_REFRESH',
	MARKETS_FILTER_DISPLAY_TYPE_LIST_RELOAD   : 'MARKETS_FILTER_DISPLAY_TYPE_LIST_RELOAD',
	MARKETS_FILTER_DISPLAY_TYPE_LIST_REFRESH  : 'MARKETS_FILTER_DISPLAY_TYPE_LIST_REFRESH',
	FILTER_RESET                              : 'MARKETS_FILTER_FILTER_RESET',
	
	filterReset: () => ({
		type: actions.FILTER_RESET,
	}),
	filterSetValue: (valueName, value) => ({
		type: actions.MARKETS_FILTER_SET_VALUE,
		data: {
			valueName,
			value,
		},
	}),
	filterRefresh: data => ({
		type: actions.MARKETS_FILTER_REFRESH,
		data,
	}),
	groupListReload: sportID => ({
		type: actions.MARKETS_FILTER_GROUP_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	groupListRefresh: list => ({
		type: actions.MARKETS_FILTER_GROUP_LIST_REFRESH,
		data: list,
	}),
	scopeListReload: sportID => ({
		type: actions.MARKETS_FILTER_SCOPE_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	scopeListRefresh: list => ({
		type: actions.MARKETS_FILTER_SCOPE_LIST_REFRESH,
		data: list,
	}),
	statisticTypeListReload: sportID => ({
		type: actions.MARKETS_FILTER_STATISTIC_TYPE_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	statisticTypeListRefresh: list => ({
		type: actions.MARKETS_FILTER_STATISTIC_TYPE_LIST_REFRESH,
		data: list,
	}),
	displayTypeListReload: sportID => ({
		type: actions.MARKETS_FILTER_DISPLAY_TYPE_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	displayTypeListRefresh: list => ({
		type: actions.MARKETS_FILTER_DISPLAY_TYPE_LIST_REFRESH,
		data: list,
	}),
};

export default actions;
