import { apiRequest } from './index';

function templatesList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/emails/templates',
		params,
	};

	return apiRequest(req);
}

function templateInfo(templateID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/emails/templates/${templateID}`,
		params,
	};

	return apiRequest(req);
}

function templateAdd(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/emails/templates',
		data,
		params,
	};

	return apiRequest(req);
}

function templateUpdate(templateID, data, params = {}) {

	const req = {
		method: 'PUT',
		url   : `/emails/templates/${templateID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function templateDelete(templateID) {

	const req = {
		method: 'DELETE',
		url   : `/emails/templates/${templateID}`,
	};

	return apiRequest(req );
}

export const emailTemplatesAPI = {
	templatesList,
	templateInfo,
	templateAdd,
	templateUpdate,
	templateDelete,
};
