import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { fields, getListParams } from '../deposits/utils';

export const availableFields = [
	'depositID',
	'code',
	'websiteID',
	'userCountryID',
	'channelID',
	'userID',
	'userName',
	'depositNumber',
	'userGroupID',
	'userStatusID',
	'depositPlacementTime',
	'depositTypeID',
	'depositSource',
	'depositAmount',
	'depositCurrencyID',
	'depositStatusID',
	'depositSourceAccount',
	'depositBonusEligibility',
	'depositBonusPercentage',
	'depositBonusWagering',
	'userBalanceBefore',
	'userBalanceAfter',
	'depositBonusTypeID',
	'depositBonusWageringDemandType',
	'depositIP',
	'depositCountryID',
	'depositAmountUSD',
	'userBalanceBeforeUSD',
	'userBalanceAfterUSD',
	'depositMessage',
	'affiliateReference',
];

export function createReportParams(filter, sorting, reportFields) {

	const params  = getListParams(filter, sorting);
	params.fields = getReportFields(reportFields);

	return params;
}

// Service ----------------------------------------------------------------------------------------

export function getReportFields(reportFields) {

	if (!isArray(reportFields) || isEmpty(reportFields)) {
		return availableFields.map(key => fields[key]);
	}

	const result = [];
	reportFields.forEach(field => {
		const { id, checked } = field;
		if (checked) {
			result.push(fields[id]);
		}
	});

	return result;
}
