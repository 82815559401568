import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import find from 'lodash/find';

import actions from './actions';
import { adaptBet, adaptBetAfterUpdate, compareBetWithFilter } from './utils';

function getStoreData({ AppTabs, RTM }) {

	return {
		isRTMBets     : Boolean( find(AppTabs.get('tabs'), { id: 'rtm/bets' }) ),
		filter        : RTM.Bets.get('filter'),
		betIDs        : RTM.Bets.get('betIDs'),
		entities      : RTM.Bets.get('entities'),
		newBetIDs     : RTM.Bets.get('newBetIDs'),
		newBetEntities: RTM.Bets.get('newBetEntities'),
	};
}

function* onNotifyCreateBet() {

	yield takeEvery(actions.RTM_BETS_SOCKET_NOTIFY_CREATE_BET, function* (action) {

		const rawBet = action.data;
		const betID = toInteger(rawBet.id);
		if (!betID) {
			return;
		}

		const storeData = yield select(getStoreData);
		const { isRTMBets, filter } = storeData;

		const betIDs         = cloneDeep(storeData.betIDs);
		const entities       = cloneDeep(storeData.entities);
		const newBetIDs      = cloneDeep(storeData.newBetIDs);
		const newBetEntities = cloneDeep(storeData.newBetEntities);

		const adaptedBet     = adaptBet(rawBet);

		newBetEntities[betID] = adaptedBet;
		newBetIDs.unshift(betID);

		if (isRTMBets) {
			const hasMatches = compareBetWithFilter(adaptedBet, filter);
			if (hasMatches) {
				entities[betID] = adaptedBet;
				betIDs.unshift(betID);
				betIDs.pop();
			}
		}

		yield put(actions.dataRefresh(betIDs, entities));
		yield put(actions.newBetListRefresh(newBetIDs, newBetEntities));

		let toSubscribeIDs = cloneDeep(betIDs);
		toSubscribeIDs.push(betID);
		toSubscribeIDs = uniq(toSubscribeIDs);

		yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}

function* onNotifyUpdateBet() {

	yield takeEvery(actions.RTM_BETS_SOCKET_NOTIFY_UPDATE_BET, function* (action) {

		const rawBet = action.data;
		const betID = toInteger(rawBet.id);
		if (!betID) {
			return;
		}

		const storeData = yield select(getStoreData);

		const betIDs    = cloneDeep(storeData.betIDs);
		const entities  = cloneDeep(storeData.entities);

		const betEntity = entities[betID];
		if (!betEntity) {
			return;
		}

		const adaptedBet = adaptBetAfterUpdate(betEntity, rawBet);
		entities[betID] = adaptedBet;

		yield put(actions.dataRefresh(betIDs, entities));
	});
}

export default function* rtmBetsExternalSaga() {
	yield all([
		fork(onNotifyCreateBet),
		fork(onNotifyUpdateBet),
	]);
}
