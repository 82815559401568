import { all, fork } from 'redux-saga/effects';
import freeSpinsSaga from '../bonusingHistory/freeSpins/saga';
import casinoCashbackUsersSaga from '../bonusingHistory/cashbackUsers/saga';
import freeBetsSaga from '../bonusingHistory/freeBets/saga';

export default function* bonusesHistoryRootSaga() {
	yield all([
		fork(freeSpinsSaga),
		fork(casinoCashbackUsersSaga),
		fork(freeBetsSaga),
	]);
}