import React, { useState, useEffect, FC, MouseEvent } from 'react';
import Fuse, { FuseResult } from 'fuse.js';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import cn from 'classnames';
import tabsActions from '../../../redux/appTabs/actions';
import { RootState } from '../../../redux/store';
import { Wrapper } from './TopbarSearch.style';
import { fuseOptions, madeOptions } from './utils';
import { IFuseItem } from './types';

interface TopbarSearchProps {
}

interface ISidebarMenuItem { // TODO move to file type after sidebar interface implementation
	id            : string;
	componentName : string;
	componentProps: object;
	location      : string;
	titleID       : string;
	name          : string;
}

const TopbarSearch: FC<TopbarSearchProps> = () => {
	const dispatch = useDispatch();
	const sidebarMenu = useSelector((state: RootState) => state.Sidebar.get('searchMenu'));
	const [searchString, setSearchString] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [dataSearch, setDataSearch] = useState([]);
	const [fuse, setFuse] = useState(new Fuse([], fuseOptions));
	const [isSelect, setIsSelect] = useState(false);
	const onChange = (value: string) => {
		setSearchString(value);
	};

	const onBlur = () => {
		setIsOpen(false);
		setSearchString('');
	};

	const onClick = (e: MouseEvent<HTMLInputElement>) => {
		const target = e.target as HTMLElement;
		if (!target.tagName.toLowerCase().includes('input') && isOpen) {
			onBlur();
		} else {
			setIsOpen(true);
		}
	};

	const onSubmit = (searchString: string) => {
		if (!isOpen) {
			return;
		}
		setIsSelect(!isSelect);
		const tab = dataSearch.filter((item: ISidebarMenuItem) => item.name === searchString)[0];
		tab && dispatch(tabsActions.openTab(tab));
	};

	const fuseSearch = () => {
		const res: Array<FuseResult<IFuseItem>> = fuse.search(searchString);
		return madeOptions(res);
	};

	useEffect(() => {
		if (sidebarMenu && sidebarMenu.length) {
			const dataSearch = sidebarMenu.flat(Infinity);
			setDataSearch(dataSearch);
			setFuse(new Fuse(dataSearch, fuseOptions));
		}
	}, [sidebarMenu, searchString]);

	useEffect(() => {
		onBlur();
	}, [isSelect]);

	return (
		<Wrapper
			options={fuseSearch()}
			onSelect={onSubmit}
			onSearch={onChange}
			onClick={onClick}
			onBlur={onBlur}
			value={searchString}
			className={cn({ 'opened': isOpen })}
			popupClassName={cn({ 'ant_select_dropdown_close': !searchString })}
		>
			<Input.Search
				size="middle"
				placeholder="Search ..."
			/>
		</Wrapper>
	);
};
export default TopbarSearch;
