import { Form, Tabs } from 'antd';
import styled from 'styled-components';

const { Item } = Form;

export const FormItemWrapper = styled(Item)`
  display: flex;

  .ant-form-item {
    margin-bottom: 2px;
  }
  .ant-form-item-label {
    min-width: 14em;
    text-align: left;
  }
  .ant-form-item-control-input-content {
    display: flex;
    gap: 1%;
  }
  .ant-form-item-control {
    flex: auto !important;
  }
  .ant-form-item-control-wrapper{
    flex: 1;
  }
`;
export const TabsWrapper = styled(Tabs)`
  
  * .ant-form-item {
    margin-bottom: 4px !important;
  }
`;

export const SpanRequired = styled.span`
    &:before {
      content: '${props => props.mandatory ? '*' : ''}';
      color: red;
      padding-right: 6px;
    }
`;
