import isArray from 'lodash/isArray';

const fields = {
	provider  : 'provider_category_name',
	providerID: 'provider_category_id',
};

// Adapting ---------------------------------------------------------------------------------------

export function adaptMappingList(rawData = [], categoryName) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = rawData.map((item, index) => {
		return {
			id        : index,
			system    : categoryName,
			provider  : item[fields.provider],
			providerID: item[fields.providerID],
		};
	});
	return result;
}

export function adaptUnmappedList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = rawData.map(item => {
		return {
			id  : item[fields.providerID],
			name: item[fields.provider],
		};
	});

	return result;
}
