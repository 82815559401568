import React from 'react';
import IntlMessages from '../../../../components/utility/intlMessages';


const prefix      = 'loyalty.tournaments.steps';
const prefixModal = 'loyalty.tournaments.modal';
export const lang = {
	add            : <IntlMessages id={`${prefix}.add`} />,
	settings       : <IntlMessages id={`${prefix}.settings`} />,
	main           : <IntlMessages id={`${prefix}.main`} />,
	games          : <IntlMessages id={`${prefix}.games`} />,
	prizePool      : <IntlMessages id={`${prefix}.prizePool`} />,
	qualifying     : <IntlMessages id={`${prefix}.qualifyingConditions`} />,
	partners       : <IntlMessages id={`${prefix}.partners`} />,
	terms          : <IntlMessages id={`${prefix}.terms`} />,
	pointGeneration: <IntlMessages id={`${prefix}.pointGeneration`} />,

	tournamentName : <IntlMessages id={`${prefixModal}.tournamentName`} />,
	languages      : <IntlMessages id={`${prefixModal}.languages`} />,
	language       : <IntlMessages id={`${prefixModal}.language`} />,
	tournamentImage: <IntlMessages id={`${prefixModal}.tournamentImage`} />,
	useAsMain      : <IntlMessages id={`${prefixModal}.useAsMain`} />,
	currencies     : <IntlMessages id={`${prefixModal}.currencies`} />,
	currency       : <IntlMessages id={`${prefixModal}.currency`} />,
	status         : <IntlMessages id={`${prefixModal}.status`} />,

	lang        : <IntlMessages id={`${prefixModal}.lang`} />,
	subTitle    : <IntlMessages id={`${prefixModal}.subTitle`} />,
	title       : <IntlMessages id={`${prefixModal}.title`} />,
	description : <IntlMessages id={`${prefixModal}.description`} />,
	startEndDate: <IntlMessages id={`${prefixModal}.startEndDate`} />,
	publishDate : <IntlMessages id={`${prefixModal}.publishDate`} />,
	logo        : <IntlMessages id={`${prefixModal}.logo`} />,

	place           : <IntlMessages id={`${prefixModal}.place`} />,
	range           : <IntlMessages id={`${prefixModal}.range`} />,
	order           : <IntlMessages id={`${prefixModal}.order`} />,
	autoGranting    : <IntlMessages id={`${prefixModal}.autoGranting`} />,
	autoGrantingDate: <IntlMessages id={`${prefixModal}.autoGrantingDate`} />,

	amount    : <IntlMessages id={`${prefixModal}.amount`} />,
	bonusMoney: <IntlMessages id={`${prefixModal}.bonusMoney`} />,
	freeSpin  : <IntlMessages id={`${prefixModal}.freeSpin`} />,
	freeBet   : <IntlMessages id={`${prefixModal}.freeBet`} />,

	wagering       : <IntlMessages id={`${prefixModal}.wagering`} />,
	daysToExpire   : <IntlMessages id={`${prefixModal}.daysToExpire`} />,
	bonusMoneyTitle: <IntlMessages id={`${prefixModal}.bonusMoneyTitle`} />,
	bmDescription  : <IntlMessages id={`${prefixModal}.bmDescription`} />,
	forced         : <IntlMessages id={`${prefixModal}.forced`} />,
	bonusMoneyLogo : <IntlMessages id={`${prefixModal}.bonusMoneyLogo`} />,
	comment        : <IntlMessages id={`${prefixModal}.comment`} />,
	commentUser    : <IntlMessages id={`${prefixModal}.commentUser`} />,

	asPercentage: <IntlMessages id={`${prefixModal}.asPercentage`} />,
	eligibility : <IntlMessages id={`${prefixModal}.eligibility`} />,
	freeSpinMain: <IntlMessages id={`${prefixModal}.freeSpinMain`} />,


	maxBonusAmount    : <IntlMessages id={`${prefixModal}.maxBonusAmount`} />,
	daysToExpireBonus : <IntlMessages id={`${prefixModal}.daysToExpireBonus`} />,
	sport             : <IntlMessages id={`${prefixModal}.sport`} />,
	tradingMode       : <IntlMessages id={`${prefixModal}.tradingMode`} />,
	market            : <IntlMessages id={`${prefixModal}.market`} />,
	minWageringOdd    : <IntlMessages id={`${prefixModal}.minWageringOdd`} />,
	wageringTurnover  : <IntlMessages id={`${prefixModal}.wageringTurnover`} />,
	affiliateReference: <IntlMessages id={`${prefixModal}.affiliateReference`} />,


	minSpins      : <IntlMessages id={`${prefixModal}.minSpins`} />,
	minStake      : <IntlMessages id={`${prefixModal}.minStake`} />,
	minBalance    : <IntlMessages id={`${prefixModal}.minBalance`} />,
	minDeposit    : <IntlMessages id={`${prefixModal}.minDeposit`} />,
	minSpinsAmount: <IntlMessages id={`${prefixModal}.minSpinsAmount`} />,

	sendToAll    : <IntlMessages id={`${prefixModal}.sendToAll`} />,
	sendToPartner: <IntlMessages id={`${prefixModal}.sendToPartner`} />,

	content     : <IntlMessages id={`${prefixModal}.content`} />,
	contentTitle: <IntlMessages id={`${prefixModal}.contentTitle`} />,

	ruleType          : <IntlMessages id={`${prefixModal}.ruleType`} />,
	logicType         : <IntlMessages id={`${prefixModal}.logicType`} />,
	totalSpinsAmount  : <IntlMessages id={`${prefixModal}.totalSpinsAmount`} />,
	minimumStake      : <IntlMessages id={`${prefixModal}.minimumStake`} />,
	minimumStakeAmount: <IntlMessages id={`${prefixModal}.minimumStakeAmount`} />,
	points            : <IntlMessages id={`${prefixModal}.points`} />,
	stopFurtherRules  : <IntlMessages id={`${prefixModal}.stopFurtherRules`} />,
	priority          : <IntlMessages id={`${prefixModal}.priority`} />,
	total             : <IntlMessages id={`${prefixModal}.total`} />,
};


export const TOUR_STEPS = {
	MAIN      : 'main',
	SETTINGS  : 'settings',
	GAMES     : 'games',
	PRIZE_POOL: 'prizePool',
	QUALIFY   : 'qualifying',
	POINT     : 'pointGeneration',
	PARTNERS  : 'partners',
	TERMS     : 'terms',
};

export const prizeTypes = {
	amount    : 'amount',
	bonusMoney: 'bonusMoney',
	freeSpin  : 'freeSpin',
	freeBet   : 'freeBet',
};

export const prizeTypesList = [
	prizeTypes.amount,
	prizeTypes.bonusMoney,
	prizeTypes.freeSpin,
	prizeTypes.freeBet,
];

export const initialPrizeTypes = {
	amount    : {},
	bonusMoney: {},
	freeSpin  : {},
	freeBet   : {},
};


export const isValidNonNegative = (value) => {
	return /^\d+$/.test(value);
};

export const NON_NEGATIVES = {
	minSpins   : 'minSpins',
	minStake   : 'minStake',
	minBalance : 'minBalance',
	minDeposit : 'minDeposit',
	spinsRange : 'spinsRange',
	amountRange: 'amountRange',
	amount     : 'amount',
};

export const sendingTypes = {
	all     : 1,
	selected: 2,
};

export function changedPanelKey(prev, next) {
	const setPrev = new Set(prev);
	const setNext = new Set(next);

	const added = [...setNext].filter(item => !setPrev.has(item));
	const removed = [...setPrev].filter(item => !setNext.has(item));

	if (added.length === 1) {
		return { type: 'opened', element: added[0] };
	}

	if (removed.length === 1) {
		return { type: 'closed', element: removed[0] };
	}

	return null; // No element added or removed, or multiple changes occurred
}
