import EntityAdapter from '../../../helpers/entityAdapter';
import { CHANNEL_TYPES } from '../../../helpers/commonConstants';
import { langChannelId } from '../../../containers/Widgets/PlacedBets/PlacedBets';

const fields = {
	amount   : 'amount',
	channelId: 'channel_id',
	ggr      : 'ggr',
	count    : 'count',
	players  : 'players',
};
const objAdapter = createObjAdapter();
export function adaptPlacedBetsDataReload(rawData = []) {
	objAdapter.clearExcludes();
	const adaptedData = objAdapter.adaptList(rawData);
	return adaptedData;
}
export function createObjAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;
	adapter.addField(rules.number, 'channel_id', fields.channelId);
	adapter.addField(rules.number, 'amount', fields.amount);
	adapter.addField(rules.number, 'ggr', fields.ggr);
	adapter.addField(rules.number, 'count', fields.count);
	adapter.addField(rules.number, 'players', fields.players);
	return adapter;
}

export function getChannelType(dataToStore) {
	const swappedChannelTypes = {};
	const ent = Object.entries(CHANNEL_TYPES);
	for (let i = 0; i < ent.length; i++) {
		swappedChannelTypes[ent[i][1]] = langChannelId[ent[i][0]];
	}
	for (let i = 0; i < dataToStore.length; i++) {
		dataToStore[i].channel_id = swappedChannelTypes[dataToStore[i].channel_id];
		dataToStore[i].rowKey = i;
	}
	return  dataToStore;
}
