import { all, call, takeEvery, fork, put, select } from 'redux-saga/effects';
import actions from './actions';
import { usersAPI } from '../../../../../helpers/api/users';
import {
	adaptBody,
	createList,
	prepareLimitsPayments,
	updateUserLimitsPaymentItem,
	UserLimitsPaymentType,
} from './utils';
import notifications, { showError } from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';


const getBaseData = ({ Users }) => {
	return {
		baseData      : Users.UserInfo.UserLimitsAndRestrictions.LimitsPayment.get('baseData'),
		withdrawalData: Users.UserInfo.UserLimitsAndRestrictions.LimitsPayment.get('withdrawalData'),
		depositList   : Users.UserInfo.UserLimitsAndRestrictions.LimitsPayment.get('depositList'),
		withdrawalList: Users.UserInfo.UserLimitsAndRestrictions.LimitsPayment.get('withdrawalList'),
	};
};

const prefix = 'users.limits.payment';

const messages = {
	errorDepositListReload   : `${prefix}.errorDepositListReload`,
	errorWithdrawalListReload: `${prefix}.errorWithdrawalListReload`,
	errorDepositSave         : `${prefix}.errorDepositSave`,
	errorWithdrawalSave      : `${prefix}.errorWithdrawalSave`,
	errorSmallerDep          : `${prefix}.errorSmallerDep`,
	errorSmallerWid          : `${prefix}.errorSmallerWid`,
	successPaymentSave       : `${prefix}.successPaymentSave`,
};

function* paymentsDepositListReload() {

	yield takeEvery(actions.USER_LIMITS_PAYMENTS_DEPOSIT_RELOAD, function* (action) {
		yield put(actions.depositUiRefresh({ loading: true }));

		const userID = action.data;
		const params     = { user_id: userID };

		try {
			let deposit;
			let limits;

			const [resList, resLimitsCurrent] = yield all([call(usersAPI.getUserLimitsPaymentsDepositList), call(usersAPI.getUserLimits, params)]);

			if (resList && resList.status === 200 && resLimitsCurrent && resLimitsCurrent.status === 200 && !!resList.data.data.length) {
				const { payments } = resList.data.data[0];

				limits = resLimitsCurrent.data.data;
				deposit = adaptBody(payments, limits);

				const depositList = createList(deposit);
				const { sortedArr : depositArr, sortedObj : depositObj } = depositList;

				yield put(actions.depositListRefresh(depositArr));
				yield put(actions.baseDataRefresh(depositObj));
			}
		} catch (e) {
			showError(messages.errorDepositListReload, e);
			logger.log(e);
		} finally {
			yield put(actions.depositUiRefresh({ loading: false }));
		}
	});
}

function* paymentsWithdrawalListReload() {

	yield takeEvery(actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_RELOAD, function* (action) {
		yield put(actions.withdrawalUiRefresh({ loading: true }));

		const userID = action.data;
		const params     = { user_id: userID };

		try {
			let withdrawal;
			let limits;

			const [resList, resLimitsCurrent] = yield all([call(usersAPI.getUserLimitsPaymentsWithdrawalList), call(usersAPI.getUserLimits, params)]);

			if (resList && resList.status === 200 && resLimitsCurrent && resLimitsCurrent.status === 200 && !!resList.data.data[0]) {
				const { payments } = resList.data.data[0];

				limits = resLimitsCurrent.data.data;
				withdrawal = adaptBody(payments, limits);

				const withdrawalList = createList(withdrawal);
				const { sortedArr : withdrawalArr, sortedObj : withdrawalObj } = withdrawalList;

				yield put(actions.withdrawalListRefresh(withdrawalArr));
				yield put(actions.withdrawalDataRefresh(withdrawalObj));
			}

		} catch (e) {
			showError(messages.errorWithdrawalListReload, e);
			logger.log(e);
		} finally {
			yield put(actions.withdrawalUiRefresh({ loading: false }));
		}
	});
}

function* depositSave() {

	yield takeEvery(actions.PAYMENT_DEPOSIT_SAVE, function* ({ data }) {
		const { userID, paymentID } = data;
		const { baseData, depositList } = yield select(getBaseData);
		const { minDeposit, maxDeposit } = baseData[paymentID];

		if ((minDeposit && maxDeposit) && (minDeposit > maxDeposit)) {
			showError(messages.errorSmallerDep);
			return;
		}

		try {
			const updatedList = updateUserLimitsPaymentItem(depositList, baseData, paymentID, true, true, UserLimitsPaymentType.DEPOSIT);
			yield put(actions.depositListRefresh(updatedList));
			const preparedData = prepareLimitsPayments(baseData[paymentID], userID, UserLimitsPaymentType.DEPOSIT);
			const res = yield call(usersAPI.LimitsPaymentsUpdate, preparedData);

			if (res && res.status === 200) {
				notifications.showSuccess(messages.successPaymentSave);

				const updatedList = updateUserLimitsPaymentItem(depositList, baseData, paymentID, true, false, UserLimitsPaymentType.DEPOSIT);
				yield put(actions.depositListRefresh(updatedList));
			}
		} catch (e) {
			notifications.showError(messages.errorDepositSave, e);
			logger.log(e);

			const updatedList = updateUserLimitsPaymentItem(depositList, baseData, paymentID, false, false, UserLimitsPaymentType.DEPOSIT);
			yield put(actions.depositListRefresh(updatedList));
		}
	});
}

function* withdrawalSave() {
	yield takeEvery(actions.PAYMENT_WITHDRAWAL_SAVE, function* ({ data }) {
		const { userID, paymentID } = data;
		const { withdrawalData, withdrawalList } = yield select(getBaseData);

		const { minWithdraw, maxWithdraw } = withdrawalData[paymentID];

		if ((minWithdraw && maxWithdraw) && (minWithdraw > maxWithdraw)) {
			showError(messages.errorSmallerWid);
			return;
		}

		try {
			const updatedList = updateUserLimitsPaymentItem(withdrawalList, withdrawalData, paymentID, true, true, UserLimitsPaymentType.WITHDRAWAL);
			yield put(actions.withdrawalListRefresh(updatedList));
			const preparedData = prepareLimitsPayments(withdrawalData[paymentID], userID, UserLimitsPaymentType.WITHDRAWAL);
			const res = yield call(usersAPI.LimitsPaymentsUpdate, preparedData);

			if (res && res.status === 200) {
				notifications.showSuccess(messages.successPaymentSave);

				const updatedList = updateUserLimitsPaymentItem(withdrawalList, withdrawalData, paymentID, true, false, UserLimitsPaymentType.WITHDRAWAL);
				yield put(actions.withdrawalListRefresh(updatedList));
			}
		} catch (e) {
			notifications.showError(messages.errorWithdrawalSave, e);
			logger.log(e);

			const updatedList = updateUserLimitsPaymentItem(withdrawalList, withdrawalData, paymentID, false, false, UserLimitsPaymentType.WITHDRAWAL);
			yield put(actions.withdrawalListRefresh(updatedList));
		}

	});
}

export default function* userLimitsPaymentsSaga() {

	yield all([
		fork(paymentsDepositListReload),
		fork(paymentsWithdrawalListReload),
		fork(depositSave),
		fork(withdrawalSave),
	]);
}
