import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Input,  Result } from 'antd';
import actions from '../../../redux/app/actions';
import StandardModal from '../../../components/StandartModal';
import { API_STATUS } from '../../../redux/app/utils';
import { CheckList, rules } from '../../Page/utils';
import FormStatic from '../../../components/FormControls/FormStatic';
import authActions from '../../../redux/auth/actions';

const lang            = {
	oldPassword          : <FormattedMessage id="passwordexpired.old_password" />,
	password             : <FormattedMessage id="passwordexpired.password" />,
	confirm              : <FormattedMessage id="passwordexpired.confirm_password" />,
	modalTitle           : <FormattedMessage id="passwordexpired.modalTitle" />,
	passwordChangeSuccess: <FormattedMessage id="passwordexpired.password_change.success" />,
	saveBtn              : <FormattedMessage id="passwordexpired.modal.save" />,
	goToLogin            : <FormattedMessage id="passwordexpired.modal.got_to_login" />,
	successTitle         : <FormattedMessage id="passwordexpired.modal.successTitle" />,
	logout               : <FormattedMessage id ="passwordexpired.modal.logout" />,
};

const PasswordExpired = ({
	visible,
	baseDataRefresh,
	baseData,
	newPasswordReload,
	passwordLoading,
	success,
	passwordExpiredModalRefresh,
	logout,
}) => {
	const { password, confirm, oldPassword } = baseData;
	const onChangeField = (name, value) => {
		baseDataRefresh({ [name]: value });
	};

	const onCancel = () => {
		passwordExpiredModalRefresh({ visible: false });
	};

	const onSubmit = () => {
		if (password !== confirm) {
			return;
		}
		newPasswordReload({ password, oldPassword });
		if (success === API_STATUS.success) {
			localStorage.clear();
			window.location.reload();
		}
	};


	const canClose      = success === API_STATUS.success;
	const saveBtnTitle  = canClose ? lang.goToLogin : lang.saveBtn;
	const readOnly      = !rules.every(item => password && password.match(item.pattern)) || (
		password !== confirm || !password
	) || !oldPassword;

	return (
		<StandardModal
			// closable={canClose}
			title={canClose ? lang.passwordChangeSuccess : lang.modalTitle}
			noSave
			onlyX
			noSaveClose={canClose}
			noLogout={canClose}
			loading={passwordLoading}
			width="80%"
			noCancel={!canClose}
			// titleCancel = {!canClose && 'back'}
			titleSaveClose={saveBtnTitle}
			visible={visible}
			readOnly={readOnly}
			onLogout={logout}
			onSaveClose={onSubmit}
			onCancel={onCancel}
		>
			{success === API_STATUS.success ? <Result status="success" title={lang.successTitle} /> : (
				<>
					<FormStatic
						label={lang.oldPassword}
						value={(
							<Input.Password
								label={lang.oldPassword}
								value={oldPassword}
								onChange={(e) => onChangeField('oldPassword', e.target.value)}
							/>
						)}
					/>

					<FormStatic
						label={lang.password}
						value={(
							<Input.Password
								value={password}
								onChange={(e) => onChangeField('password', e.target.value)}
							/>
						)}
					/>

					<FormStatic
						label={lang.confirm}
						value={(
							<Input.Password
								value={confirm}
								onChange={(e) => onChangeField('confirm', e.target.value)}
							/>
						)}
					/>

					<FormStatic
						style={{ display: 'flex' }}
						label={null}
						value={<CheckList value={password} />}
					/>
				</>
			)

			}
		</StandardModal>
	);
};

PasswordExpired.propTypes = {
	visible        : PropTypes.bool.isRequired,
	passwordLoading: PropTypes.bool.isRequired,
	success        : PropTypes.number.isRequired,

	baseData: PropTypes.shape({
		password   : PropTypes.string.isRequired,
		confirm    : PropTypes.string.isRequired,
		oldPassword: PropTypes.string.isRequired,
	}).isRequired,
	baseDataRefresh            : PropTypes.func.isRequired,
	logout                     : PropTypes.func.isRequired,
	newPasswordReload          : PropTypes.func.isRequired,
	passwordExpiredModalRefresh: PropTypes.func.isRequired,
};

const mapStateToProps = ({ App }) => {
	const modals = App.get('modals');
	return {
		loading        : modals.passwordExpiredModal.loading,
		success        : modals.passwordExpiredModal.success,
		passwordLoading: modals.passwordExpiredModal.passwordLoading,
		baseData       : modals.baseData,
	};
};

const mapDispatchToProps = {

	baseDataRefresh            : actions.modalBaseDataRefresh,
	passwordExpiredModalRefresh: actions.passwordExpiredModalRefresh,
	newPasswordReload          : actions.newPasswordReload,
	logout                     : authActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpired);
