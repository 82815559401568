import isArray from 'lodash/isArray';
import EntityAdapter from '../../helpers/entityAdapter';

const adminFields = {
	id       : 'id',
	userName : 'username',
	firstName: 'first_name',
	lastName : 'last_name',
	email    : 'email',
	statusID : 'status_id',
	roles    : 'user_roles',
};

const adminAdapter = createAdminAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptAdminList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	const adaptedList = adminAdapter.adaptList(rawData);

	adaptedList.forEach(item => {
		if (!item.userName) {
			item.userName = item.email;
		}
		item.name = item.userName;
		item.fullName = `${item.firstName} ${item.lastName}`;
	});

	return adaptedList;
}

// Adapters ---------------------------------------------------------------------------------------

function createAdminAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', adminFields.id);
	adapter.addField(rules.id, 'statusID', adminFields.statusID);

	adapter.addField(rules.string, 'userName', adminFields.userName);
	adapter.addField(rules.string, 'firstName', adminFields.firstName);
	adapter.addField(rules.string, 'lastName', adminFields.lastName);
	adapter.addField(rules.string, 'email', adminFields.email);

	adapter.addField(rules.arrayString, 'roles', adminFields.roles);

	return adapter;
}

export const defaultSportList = [{ id: 150592, name: 'Football' }];
