import { all, fork } from 'redux-saga/effects';

import gameGrid from './gameGrid/saga';
import matchesOfDay from './matchesOfDay/saga';
import sportsPriorities from './sportsPriorities/saga';
import specialOffers from './specialOffers/saga';
import upcoming from './upcoming/saga';

export default function* rootSaga() {
	yield all([
		fork(gameGrid),
		fork(matchesOfDay),
		fork(sportsPriorities),
		fork(specialOffers),
		fork(upcoming),
	]);
}
