import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list: [],

	UI: {
		loading: false,
	},

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},
});

export default function userBalanceAdjustmentsReducer(state = initState, action) {

	switch (action.type) {

		case actions.BALANCE_ADJUSTMENTS_LIST_REFRESH: {
			const { list } = action.data;
			return state.set('list', list);
		}

		case actions.BALANCE_ADJUSTMENTS_LIST_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.BALANCE_ADJUSTMENTS_LIST_PAGINATION_REFRESH: {
			const target = state.get('pagination');
			const result = fill(action.data, target);
			return state.set('pagination', result);
		}

		default:
			return state;
	}
}
