import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData: {},
	descData: {},

	UI: {
		visible      : false,
		bannerID     : null,
		loading      : false,
		loadEntity   : false,
		closeModal   : false,
		editMode     : false,
		langID       : null,
		channelID    : null,
		isBaseChanged: false,
		isDescChanged: false,
	},
});

export default function bannerModalReducer(state = initState, action) {

	switch (action.type) {

		case actions.BANNER_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}

		case actions.BANNER_DESC_DATA_REFRESH: {
			const target = state.get('descData');
			const result = fill(action.data, target, true);
			return state.set('descData', result);
		}

		case actions.BANNER_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.BANNER_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
