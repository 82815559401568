import React, { Component } from 'react';
import PropsTypes from 'prop-types';
import toInteger from 'lodash/toInteger';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import Select, { SelectOption } from '../select';
import { getStoredDataBaseLangID } from '../../../helpers/utility';
import Wrapper from './index.style';

// component
class CustomModalTitle extends Component {

	static propTypes = {
		title              : PropsTypes.oneOfType([PropsTypes.string, PropsTypes.element]).isRequired,
		langID             : PropsTypes.number,
		disabled           : PropsTypes.bool,
		useLanguageSwitcher: PropsTypes.bool,
		onChangeLanguage   : PropsTypes.func,
		contentLanguageList: PropsTypes.array,
	};

	static defaultProps = {
		langID             : getStoredDataBaseLangID(),
		disabled           : false,
		useLanguageSwitcher: false,
		onChangeLanguage   : null,
		contentLanguageList: [],
	};

	constructor(props) {
		super(props);
		this.onChange         = this.onChange.bind(this);
		this.renderOptions    = this.renderOptions.bind(this);
	}

	onChange(value) {
		const { onChangeLanguage } = this.props;
		const langID = toInteger(value);
		if (onChangeLanguage) {
			onChangeLanguage(langID);
		}
	}

	renderOptions() {
		const { contentLanguageList } = this.props;
		return contentLanguageList.map(item => <SelectOption key={item.id}>{item.name}</SelectOption>);
	}

	render() {
		const { title, useLanguageSwitcher, disabled, langID } = this.props;
		const className = disabled ? 'language-switcher-disabled' : 'language-switcher';
		const options = this.renderOptions();
		const tooltipTitle = title && title.length > 40 ? title : '';
		return (
			<Wrapper>
				<Tooltip placement="top" title={tooltipTitle}>
					<div className="title">{title}</div>
				</Tooltip>

				{useLanguageSwitcher && (
					<div className={className}>
						<Select
							value={String(langID)}
							style={{ width: '100%' }}
							disabled={disabled}
							onChange={this.onChange}
						>
							{options}
						</Select>
					</div>
				)}
			</Wrapper>
		);
	}
}
const mapStateToProps = ({ App }) => ({ contentLanguageList: App.get('websiteAttachedLanguages') });

export default connect(mapStateToProps)(CustomModalTitle);
