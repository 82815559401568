import { Map } from 'immutable';
import { ADMIN_TYPES } from '../../../containers/StaffManagement/modals/Person/utils';
import { fill } from '../../../helpers/utils';
import actions from './actions';
import { LANGUAGES } from '../../../helpers/commonConstants';

const initState = new Map({

	baseData: {
		email        : '',
		password     : '',
		userName     : '',
		firstName    : '',
		lastName     : '',
		statusID     : null,
		roles        : [],
		selectedRoles: [],
		birthday     : null,
		langID       : LANGUAGES.en,
		websiteID    : 1,
		adminType    : ADMIN_TYPES.SYSTEM_OPERATOR,
	},

	UI: {
		visible       : false,
		loading       : false,
		closeModal    : false,
		goToUser      : false,
		partnerLoading: false,
	},
});

export default function personReducer(state = initState, action) {

	switch (action.type) {

		case actions.BASE_DATA_REFRESH: {
			const { baseData } = action.data;
			const target = state.get('baseData');
			const result = fill(baseData, target);

			return state.set('baseData', result);
		}

		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);

			return state.set('UI', result);
		}

		case actions.DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
