import { debugMode } from '../config';

class Logger {
	debugMode = false;
	constructor(debugMode: boolean ) {
		this.debugMode = debugMode;
	}

	shouldLog() {
		return this.debugMode;
	}
	log(...args: unknown[]) {
		if (!this.shouldLog()) return;
		// eslint-disable-next-line no-console
		console.log(...args);
	}
	dir(...args: unknown[]) {
		if (!this.shouldLog()) return;
		// eslint-disable-next-line no-console
		console.dir(...args);
	}
	error(...args: unknown[]) {
		if (!this.shouldLog()) return;
		// eslint-disable-next-line no-console
		console.error(...args);
	}
	warn(...args: unknown[]) {
		if (!this.shouldLog()) return;
		// eslint-disable-next-line no-console
		console.warn(...args);
	}
	table(...args: unknown[]) {
		if (!this.shouldLog()) return;
		// eslint-disable-next-line no-console
		console.table(...args);
	}

}


export const logger: Logger = new Logger(debugMode);

