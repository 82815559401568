import styled from 'styled-components';

export const Wrapper = styled.div`
  height: ${({ height }) => height};
`;

export const ImageHolder = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 25vh;
  overflow-x: hidden;

  img {
    object-fit: contain;
    object-position: center;
    margin-top: 16px;
  }

  p {
    padding-top: 24px;
  }
`;
