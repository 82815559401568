import { IItemDetails, IRiskBetsItem } from '../../../../redux/sportRiskManagement/sportBets/list/types';

interface IPropsModal {
	closeModal: (v: boolean) => void;
}

interface IPropsModalHeader {
	data: IRiskBetsItem;
}

interface IPropsModalSingleEvent {
	data: IItemDetails;
}

interface IPropsModalMultiEvent {
	data: IItemDetails[];
}
interface IPropsModalSuspendButton {
	item: IItemDetails;
}
interface IPropsModalMaginButton extends IPropsModalSuspendButton {
	buttonSize: 'small' | 'middle' | 'large';
}


interface IPropsItem {
	dataItem  : IItemDetails | IItemDetails[] | IRiskBetsItem;
	item?     : IItemDetails;
	type?     : 'title' | '';
	singleBet?: boolean | undefined;
}

export type {
	IPropsModal,
	IPropsModalHeader,
	IPropsModalSingleEvent,
	IPropsModalMultiEvent,
	IPropsItem,
	IPropsModalSuspendButton,
	IPropsModalMaginButton,
};

export enum EBetType {
	SINGLE_BET = 1
}
export enum SuspendedMarkets {
    NOT_SUSPENDED = 1,
    SUSPENDED = 2
}
export enum SuspendedMarketsColors {
    RED ='red',
    ORANGE = 'orange'
}
