import toInteger from 'lodash/toInteger';
import { CONTENT_MENU_TYPES } from '../../../../helpers/commonConstants';
import EntityAdapter from '../../../../helpers/entityAdapter';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { isID } from '../../../../helpers/utils';
import { createEntities } from '../../../utility';

export const fields = {
	id             : 'id',
	title          : 'title',
	linkTitle      : 'link_title',
	contentTitle   : 'content_title',
	contentSubtitle: 'content_subtitle',

	displayStatusID : 'display_status_id',
	orderID         : 'order_id',
	langID          : 'lang_id',
	colID           : 'column_id',
	pageID          : 'page_id',
	linkActionTypeID: 'link_action_type',
	productID       : 'product_id',
	menuTypeID      : 'menu_type_id',

	url     : 'url',
	imageURL: 'image_url',
	videoURL: 'video_url',

	useImage    : 'use_image',
	useVideo    : 'use_video',
	useLinkTitle: 'use_link_title',

	// revision
	active: 'is_active',
	menu  : 'menu',
	userID: 'created_by',
	date  : 'created_at',
};

const columnAdapter = createColumnAdapter();
const columnOrderAdapter = createColumnOrderAdapter();
const itemAdapter = createItemAdapter();
const itemOrderAdapter = createItemOrderAdapter();
const revisionAdapter = createRevisionAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptSettingsData(rawData = {}) {
	columnAdapter.clearExcludes();
	itemAdapter.clearExcludes();

	const adaptedColumns = columnAdapter.adaptList(rawData.columns);
	const adaptedItems = itemAdapter.adaptList(rawData.items).map(item => ({
		...item,
		id: `G${item.id}`,
	}));

	const columnsEntities	= createEntities(adaptedColumns);
	const itemsEntities		= createEntities(adaptedItems);

	const columnsIDs		= Object.keys(columnsEntities);
	const itemsIDs			= Object.keys(itemsEntities);

	const result = {
		columnsIDs,
		itemsIDs,
		columnsEntities,
		itemsEntities,
	};

	return result;
}

export function adaptColumn(rawData = {}) {
	columnAdapter.clearExcludes();
	const adaptedColumn = columnAdapter.adapt(rawData);

	return adaptedColumn;
}

export function adaptItem(rawData = {}) {
	itemAdapter.clearExcludes();
	const adaptedItem = itemAdapter.adapt(rawData);

	return adaptedItem;
}

export function adaptRevisionsList(rawData = []) {
	let activeRevisionID = null;
	revisionAdapter.clearExcludes();

	const adaptedData = revisionAdapter.adaptList(rawData);

	adaptedData.forEach(revision => {
		revision.menu = adaptSettingsData(revision.menu);
		if (revision.active) {
			activeRevisionID = revision.id;
		}
	});
	const revisionsEntities = createEntities(adaptedData);

	return {
		revisionsEntities,
		activeRevisionID,
	};
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareSettingsData(columnsEntities, itemsEntities) {
	columnAdapter.clearExcludes();
	itemAdapter.clearExcludes();

	const columns = Object.keys(columnsEntities).map(ID => {
		return columnAdapter.prepare(columnsEntities[ID]);
	});

	const items = Object.keys(itemsEntities).map(ID => {
		const item = itemsEntities[ID];
		item.id = isNaN(item.id) ? item.id.replace(/[a-zA-Z]/g, '') : item.id;
		return itemAdapter.prepare(itemsEntities[ID]);
	});

	return {
		columns,
		items,
	};
}

export function prepareColumn(rawData = {}, langID) {
	columnAdapter.clearExcludes();
	columnAdapter.addExcludeField('id');

	const preparedData = columnAdapter.prepare(rawData);

	preparedData[fields.langID] = langID;
	preparedData[fields.menuTypeID] = CONTENT_MENU_TYPES.footerMenu;

	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}

	return preparedData;
}

export function prepareItem(rawData = {}, langID) {
	itemAdapter.clearExcludes();
	itemAdapter.addExcludeField('id');
	itemAdapter.addExcludeField('linkTitle');
	itemAdapter.addExcludeField('contentTitle');
	itemAdapter.addExcludeField('contentSubtitle');
	itemAdapter.addExcludeField('url');
	itemAdapter.addExcludeField('imageURL');
	itemAdapter.addExcludeField('videoURL');

	const preparedData = itemAdapter.prepare(rawData);

	preparedData[fields.langID] = langID;

	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}

	return preparedData;
}

export function prepareColumnsList(rawData = []) {
	const columnsList = rawData.map((id, idx) => ({
		id,
		orderID: idx + 1,
	}));
	columnsList.sort((a, b) => a.orderID - b.orderID);
	const preparedData = columnOrderAdapter.prepareList(columnsList);
	return preparedData;
}

export function prepareItemsList(rawData = [], movedColumnID) {
	const data = rawData.map((item, idx) => ({
		colID  : movedColumnID,
		orderID: idx + 1,
		id     : item.id.replace(/[a-zA-Z]/, ''),
	}));
	const preparedData = itemOrderAdapter.prepareList(data);

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createColumnAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'displayStatusID', fields.displayStatusID);
	adapter.addField(rules.id, 'langID', fields.langID);

	adapter.addField(rules.string, 'title', fields.title);

	return adapter;
}

export function createColumnOrderAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.number, 'langID', fields.langID);
	adapter.addField(rules.string, 'menuTypeID', fields.menuTypeID);
	adapter.addField(rules.number, 'displayStatusID', fields.displayStatusID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	return adapter;
}

export function createItemAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'displayStatusID', fields.displayStatusID);
	adapter.addField(rules.id, 'colID', fields.colID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'pageID', fields.pageID);
	adapter.addField(rules.id, 'linkActionTypeID', fields.linkActionTypeID);
	adapter.addField(rules.id, 'productID', fields.productID);

	adapter.addField(rules.string, 'linkTitle', fields.linkTitle);
	adapter.addField(rules.string, 'contentTitle', fields.contentTitle);
	adapter.addField(rules.string, 'contentSubtitle', fields.contentSubtitle);
	adapter.addField(rules.string, 'url', fields.url);
	adapter.addField(rules.string, 'imageURL', fields.imageURL);
	adapter.addField(rules.string, 'videoURL', fields.videoURL);

	adapter.addField(rules.bool, 'useImage', fields.useImage);
	adapter.addField(rules.bool, 'useVideo', fields.useVideo);
	adapter.addField(rules.bool, 'useLinkTitle', fields.useLinkTitle);

	return adapter;
}

export function createItemOrderAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'colID', fields.colID);

	return adapter;
}

export function createRevisionAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'menuID', fields.menuID);
	adapter.addField(rules.id, 'userID', fields.userID);

	adapter.addField(rules.bool, 'active', fields.active);
	adapter.addField(rules.fullDate, 'date', fields.date);
	adapter.addField(rules.noCondition, 'menu', fields.menu);

	return adapter;
}

export function getListParams(websiteID) {

	const builder = new ParamsBuilder();
	//const rules   = builder.RULES;

	builder.addValue('website_id', websiteID);

	const params = builder.biuldParams({});

	return params;
}
