import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';
import appTabsActions from '../../../../appTabs/actions';

import { infoPagesAPI } from '../../../../../helpers/api/infoPages';
import notifications from '../../../../../helpers/notifications';
import { isID } from '../../../../../helpers/utils';

import {
	adaptInfoPage,
	prepareInfoPage,
	adaptRevisionsList,
	prepareRevisionData,
} from './utils';
import { logger } from '../../../../../helpers/logger';

const prefix = 'infopages.page';

const messages = {
	errorPageReload     : `${prefix}.errorPageReload`,
	errorPageSave       : `${prefix}.errorPageSave`,
	errorRevisionsLoad  : `${prefix}.errorRevisionsLoad`,
	errorRevisionSave   : `${prefix}.errorRevisionSave`,
	errorRevisionApply  : `${prefix}.errorRevisionApply`,
	successPageSave     : `${prefix}.successPageSave`,
	successRevisionSave : `${prefix}.successRevisionSave`,
	successRevisionApply: `${prefix}.successRevisionApply`,
};


function getStoreData({ CMS, App }) {

	return {
		listIDs          : CMS.Pages.InfoPages.List.get('listIDs'),
		entities         : CMS.Pages.InfoPages.List.get('entities'),
		baseData         : CMS.Pages.InfoPages.Page.get('baseData'),
		UI               : CMS.Pages.InfoPages.Page.get('UI'),
		revisionsEntities: CMS.Pages.InfoPages.Page.get('revisionsEntities'),
		websiteID        : App.get('websiteID'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.INFO_PAGES_PAGE_BASE_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const storeData = yield select(getStoreData);
		const { UI, websiteID } = storeData;
		const { pageID } = action.data;
		const params = { lang_id: UI.langID, website_id: websiteID };

		let baseData = adaptInfoPage({});
		try {
			const res = yield call(infoPagesAPI.infoPageInfo, pageID, params);
			if (res && res.status === 200) {
				baseData = adaptInfoPage(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorPageReload, error);
			logger.log(error);
		}

		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.uiRefresh({
			pageID       : baseData.id,
			pageName     : baseData.name,
			editMode     : true,
			loading      : false,
			isDataChanged: false,
		}));
	});
}

function* pageSave() {

	yield takeEvery(actions.INFO_PAGES_PAGE_SAVE, function* (action) {

		const { baseData, UI, websiteID } = yield select(getStoreData);
		yield put(actions.uiRefresh({ loading: true }));
		baseData.websiteID = websiteID;

		let { pageID } = action.data;

		const { editMode, langID } = UI;
		let isError = false;

		const preparedData = prepareInfoPage(baseData, langID);

		try {
			if (isID(pageID)) {
				const res = yield call(
					infoPagesAPI.infoPageUpdate,
					pageID,
					preparedData
				);
				if (res && res.status === 200) {
					const baseData = adaptInfoPage(res.data.data);
					yield put(actions.baseDataRefresh(baseData));
					notifications.showSuccess(messages.successPageSave);
				}
			} else {
				const res = yield call(infoPagesAPI.infoPageAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successPageSave);
					pageID = toInteger(res.data.data.id);
				}
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorPageSave, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
		if (!editMode && !isError) {
			yield put(actions.baseDataReload(pageID));
			yield put(appTabsActions.openTabInfoPage(pageID));
		}
		if (!isError) {
			yield put(listActions.listReload());
		}
	});
}

function* revisionsListReload() {

	yield takeEvery(actions.INFO_PAGES_REVISIONS_LIST_RELOAD, function* (action) {

		const { pageID } = action.data;
		const { UI } = yield select(getStoreData);

		let revisionsEntities = {};
		let activeRevisionID = null;
		const params = { lang_id: UI.langID };

		try {
			const res = yield call(infoPagesAPI.infoPageRevisionsList, pageID, params);
			if (res && res.status === 200) {
				const result = adaptRevisionsList(res.data.data);
				revisionsEntities = result.revisionsEntities; // eslint-disable-line prefer-destructuring
				activeRevisionID  = result.activeRevisionID;  // eslint-disable-line prefer-destructuring
			}
		} catch (error) {
			notifications.showError(messages.errorRevisionsLoad, error);
			logger.error(error);
		}

		yield put(actions.revisionsListRefresh(revisionsEntities));
		yield put(actions.uiRefresh({ revisionID: activeRevisionID }));
	});
}

function* revisionSave() {
	yield takeEvery(actions.INFO_PAGES_REVISION_SAVE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { pageID, revisionData } = action.data;
		const { UI } = yield select(getStoreData);
		const preparedData = prepareRevisionData(pageID, revisionData, UI.langID);

		try {
			const res = yield call(
				infoPagesAPI.infoPageRevisionAdd,
				pageID,
				preparedData
			);
			if (res && res.status === 200) {
				const { data } = res.data;
				revisionData.imageURL = data.page.image_url; 
				const revisionID = toInteger(data.id);
				yield put(actions.revisionsListReload(pageID));
				yield put(
					actions.uiRefresh({
						loading              : false,
						isDataChanged        : false,
						isContentShortChanged: false,
						isContentMainChanged : false,
						revisionID,
					})
				);

				notifications.showSuccess(messages.successRevisionSave);
			}
		} catch (error) {
			notifications.showError(messages.errorRevisionSave, error);
			logger.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* revisionApply() {
	yield takeEvery(actions.INFO_PAGES_REVISION_APPLY, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { pageID, revisionID } = action.data;

		try {
			const res = yield call(
				infoPagesAPI.infoPageRevisionApply,
				pageID,
				revisionID
			);
			if (res && res.status === 200) {
				yield put(actions.revisionsListReload(pageID));
				yield put(actions.baseDataReload(pageID));

				notifications.showSuccess(messages.successRevisionApply);
			}
		} catch (error) {
			notifications.showError(messages.errorRevisionApply, error);
			logger.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* infoPageSaga() {
	yield all([
		fork(pageSave),
		fork(baseDataReload),
		fork(revisionsListReload),
		fork(revisionSave),
		fork(revisionApply),
	]);
}
