import queryToFilterValidaion from '../../../helpers/queryToFilterValidaion';

const { isValidID, isValidString, isValidArray, isValidDateRange } = queryToFilterValidaion;
export const initFilterTypes = {
	userID              : isValidID,
	statusID            : isValidID,
	gamingStatusID      : isValidID,
	verificationStatusID: isValidID,
	genderID            : isValidID,
	profitabilityGradeID: isValidID,
	
	customerIP        : isValidString,
	userName          : isValidString,
	userEmail         : isValidString,
	registrationIP    : isValidString,
	userLastName      : isValidString,
	domain            : isValidString,
	userFirstName     : isValidString,
	affiliateReference: isValidString,
	documentNumber    : isValidString,
    
	country            : isValidArray,
	currency           : isValidArray,
	validationStatus   : isValidArray,
	registrationCountry: isValidArray,
	riskGroup          : isValidArray,
	age                : isValidArray,
	depositsRange      : isValidArray,
	withdrawalsRange   : isValidArray,
	withdrawalsAmount  : isValidArray,
	depositsAmount     : isValidArray,
	betsCount          : isValidArray,
	lastBetAmount      : isValidArray,
	betsAmount         : isValidArray,
	minStake           : isValidArray,
	maxStake           : isValidArray,
	
	regDateRange   : isValidDateRange,
	birthday       : isValidDateRange,
	firstDeposit   : isValidDateRange,
	firstWithdrawal: isValidDateRange,
	lastBetDate    : isValidDateRange,
	lastLoginDate  : isValidDateRange,	
};