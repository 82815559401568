import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IInitSportRiskFilter, IFilterItem } from './types';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import { initFilterTypes } from '../../../rtm/bets/utils';


const initialState: IInitSportRiskFilter={
	filterData: createFilter(filtersURL.sportRiskManagement, initFilterTypes),
};

export const riskSportBetsFilterSlice = createSlice({
	name    : 'riskSportBetsFilter',
	initialState,
	reducers: {
		setFilterData: (state: Draft<IInitSportRiskFilter>, action: PayloadAction<IFilterItem>) => {
			state.filterData = action.payload;
		},
		filterRefresh: (state: Draft<IInitSportRiskFilter>, action: PayloadAction<IFilterItem>) => {
			const target = state.filterData;
			state.filterData = fill(action.payload, target, true);
		},
		resetFilterData: (state: Draft<IInitSportRiskFilter>) => {
			state.filterData = initialState.filterData;
		},
	},
});


export const actions = {
	...riskSportBetsFilterSlice.actions,
};

export default riskSportBetsFilterSlice.reducer;

