import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';

const fields = {
	id      : 'id',
	name    : 'name',
	marketID: 'market_id',
	rowIndex: 'row_index',
	colIndex: 'column_index',
	statusID: 'status_id',
};

export function adaptSelectionsList(rawData) {
	if (!isArray(rawData)) {
		return [];
	}

	const adaptedList = rawData.map(item => {

		const newItem = {
			id      : toInteger(item[fields.id]),
			name    : item[fields.name],
			marketID: toInteger(item[fields.marketID]),
			rowIndex: toInteger(item[fields.rowIndex]),
			colIndex: toInteger(item[fields.colIndex]),
			statusID: toInteger(item[fields.statusID]),
		};

		return newItem;
	});

	return adaptedList;
}
