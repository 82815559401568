const actions = {

	EMAIL_TEMPLATES_LIST_RELOAD : 'EMAIL_TEMPLATES_LIST_RELOAD',
	EMAIL_TEMPLATES_LIST_REFRESH: 'EMAIL_TEMPLATES_LIST_REFRESH',

	EMAIL_TEMPLATES_LIST_DELETE_ITEM: 'EMAIL_TEMPLATES_LIST_DELETE_ITEM',

	listReload: () => ({
		type: actions.EMAIL_TEMPLATES_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.EMAIL_TEMPLATES_LIST_REFRESH,
		data: {
			entities,
		},
	}),

};

export default actions;
