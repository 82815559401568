import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { Row, Col } from 'antd';

import FormInput from '../../../../components/FormControls/FormInput';
import StandartModal from '../../../../components/StandartModal';

import profileActions from '../../../../redux/profile/actions';
import { showError } from '../../../../helpers/notifications';

import { lang } from '../../lang';

import { validatePassword } from './validators';

class ChangePassword extends Component {

	static propTypes = {
		// userID          : PropTypes.number,
		baseData       : PropTypes.object.isRequired,
		visible        : PropTypes.bool.isRequired,
		loading        : PropTypes.bool.isRequired,
		// baseDataReload  : PropTypes.func.isRequired,
		dataSave       : PropTypes.func.isRequired,
		dataReset      : PropTypes.func.isRequired,
		uiRefresh      : PropTypes.func.isRequired,
		baseDataRefresh: PropTypes.func.isRequired,
	};

	// static defaultProps = {
	//   userID: 0,
	// }

	constructor(props) {
		super(props);
		this.onSave           = this.onSave.bind(this);
		this.onClickSave      = this.onClickSave.bind(this);
		this.onClickSaveClose = this.onClickSaveClose.bind(this);
	}

	// componentDidMount() {
	//   const { userID, baseDataReload } = this.props;
	//   baseDataReload(userID);
	// }

	// Events ---------------------------------------------------------------------------------------
	onSave(closeModal) {
		const { baseData, dataSave, uiRefresh } = this.props;
		const errors = validatePassword(baseData);
		if (errors) {
			showError(errors);
			return;
		}

		uiRefresh({ closeModal });
		dataSave();
	}

	onClickSave() {
		this.onSave(false);
	}

	onClickSaveClose() {
		this.onSave(true);
	}

	onChangeField(value, dataName) {
		const { baseDataRefresh } = this.props;
		if (!dataName) {
			return;
		}

		baseDataRefresh({
			[dataName]: value,
		});
	}

	// Renders --------------------------------------------------------------------------------------
	render() {
		const { visible, loading, dataReset, baseData } = this.props;
		const { password, confirmPassword, currentPassword } = baseData;


		return (
			<StandartModal
				visible={visible}
				title={lang.changePassword}
				width="50%"
				loading={loading}
				readOnly={false}
				onCancel={dataReset}
				onSave={this.onClickSave}
				onSaveClose={this.onClickSaveClose}
			>
				<FormInput
					type="password"
					label={lang.currentPassword}
					value={currentPassword}
					disabled={loading}
					onChange={({ target }) => this.onChangeField(target.value, 'currentPassword')}
				/>
				<FormInput
					type="password"
					label={lang.changePassword}
					value={password}
					disabled={loading}
					onChange={({ target }) => this.onChangeField(target.value, 'password')}
				/>
				<FormInput
					type="password"
					label={lang.confirmPassword}
					value={confirmPassword}
					disabled={loading}
					onChange={({ target }) => this.onChangeField(target.value, 'confirmPassword')}
				/>
			</StandartModal>
		);
	}
}

function mapStateToProps(state) {
	const { Profile } = state;
	const UI = Profile.get('UI');

	return {
		baseData: Profile.get('baseData'),
		userID  : UI.userID,
		visible : UI.passwordModalVisible,
		loading : UI.loading,
	};
}

export default connect(mapStateToProps, {
	baseDataReload : profileActions.baseDataReload,
	dataSave       : profileActions.passwordChange,
	dataReset      : profileActions.dataReset,
	uiRefresh      : profileActions.uiRefresh,
	baseDataRefresh: profileActions.baseDataRefresh,
})(ChangePassword);
