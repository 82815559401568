const actions = {

	BASE_DATA_REFRESH      : 'PERSON_BASE_DATA_REFRESH',
	BASE_DATA_SAVE         : 'PERSON_BASE_DATA_SAVE',
	UI_REFRESH             : 'PERSON_UI_REFRESH',
	PARTNERS_WEBSITE_RELOAD: 'PARTNERS_WEBSITE_RELOAD',
	DATA_RESET             : 'PERSON_DATA_RESET',

	baseDataRefresh: (baseData) => ({
		type: actions.BASE_DATA_REFRESH,
		data: {
			baseData,
		},
	}),

	baseDataSave: () => ({
		type: actions.BASE_DATA_SAVE,
	}),

	uiRefresh: (UI) => ({
		type: actions.UI_REFRESH,
		data: {
			UI,
		},
	}),

	partnersWebsiteReload: () => ({
		type: actions.PARTNERS_WEBSITE_RELOAD,
	}),
	dataReset: () => ({
		type: actions.DATA_RESET,
	}),
};


export default actions;
