import { all, fork } from 'redux-saga/effects';
import emailsSaga from './emails/saga';
import emailTemplatesSaga from './emailTemplates/saga';

export default function* () {
	yield all([
		fork(emailsSaga),
		fork(emailTemplatesSaga),
	]);
}
