import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';

import IntlMessages from '../utility/intlMessages';
import Button from '../uielements/button';
import CustomModalTitle from '../uielements/CustomModalTitle';
import CustomModalFooter from '../uielements/CustomModalFooter';
import { getStoredDataBaseLangID } from '../../helpers/utility';
import DraggableModal from '../DraggableModal';

const lang = {
	cancel   : <IntlMessages id="common.cancel" />,
	logout   : <IntlMessages id="common.logout" />,
	save     : <IntlMessages id="common.save" />,
	saveClose: <IntlMessages id="common.saveClose" />,
	remove   : <IntlMessages id="common.delete" />,
};

class StandartModal extends Component {

	static propTypes = {
		langDisabled       : PropTypes.string,
		visible            : PropTypes.bool.isRequired,
		title              : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		loading            : PropTypes.bool,
		onlyX              : PropTypes.bool,
		useLanguageSwitcher: PropTypes.bool,
		langID             : PropTypes.number,
		width              : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		noSave             : PropTypes.bool,
		noSaveClose        : PropTypes.bool,
		noCancel           : PropTypes.bool,
		noLogout           : PropTypes.bool,
		showSuspends       : PropTypes.bool,
		readOnly           : PropTypes.bool,
		withRemove         : PropTypes.bool,
		bodyStyle          : PropTypes.object,
		children           : PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,

		titleCancel   : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		titleSave     : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		titleSaveClose: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		titleRemove   : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		titleLogout   : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

		onCancel        : PropTypes.func.isRequired,
		onSave          : PropTypes.func,
		onLogout        : PropTypes.func,
		onSaveClose     : PropTypes.func,
		onChangeLanguage: PropTypes.func,
		onRemove        : PropTypes.func,

		saveComponent        : PropTypes.element || null,
		saveAndCloseComponent: PropTypes.element || null,
		cancelButtonProps    : PropTypes.object,
	};

	static defaultProps = {
		title                : '',
		langDisabled         : '',
		onlyX                : false,
		loading              : false,
		useLanguageSwitcher  : false,
		langID               : getStoredDataBaseLangID(),
		onChangeLanguage     : null,
		width                : '40%',
		noSave               : false,
		noSaveClose          : false,
		showSuspends         : false,
		readOnly             : false,
		withRemove           : false,
		noCancel             : false,
		noLogout             : true,
		saveComponent        : null,
		saveAndCloseComponent: null,
		bodyStyle            : {},
		cancelButtonProps    : {},
		onLogout             : () => null,

		titleCancel   : lang.cancel,
		titleSave     : lang.save,
		titleSaveClose: lang.saveClose,
		titleRemove   : lang.remove,
		titleLogout   : lang.logout,

		onSave     : () => {},
		onSaveClose: () => {},
		onRemove   : () => {},
	};

	constructor(props) {
		super(props);

		this.onClickSave      = this.onClickSave.bind(this);
		this.onClickSaveClose = this.onClickSaveClose.bind(this);
		this.onClickCancel    = this.onClickCancel.bind(this);
		this.onClickRemove    = this.onClickRemove.bind(this);
		this.onClickLogout    = this.onClickLogout.bind(this);
		this.onChangeLanguage = this.onChangeLanguage.bind(this);

		this.renderModalTitle  = this.renderModalTitle.bind(this);
		this.renderModalFooter = this.renderModalFooter.bind(this);
	}

	// Local Events ---------------------------------------------------------------------------------

	onClickSave() {
		const { loading, onSave } = this.props;
		if (loading) {
			return;
		}

		onSave();
	}

	onClickSaveClose() {
		const { loading, onSaveClose } = this.props;
		if (loading) {
			return;
		}
		onSaveClose();
	}

	onClickCancel() {
		const { loading, onCancel, noCancel } = this.props;
		if (loading || noCancel) {
			return;
		}
		onCancel();
	}

	onClickLogout() {
		const { loading, onLogout } = this.props;
		if (loading) {
			return;
		}

		onLogout();
	}

	onClickRemove() {
		const { loading, onRemove, withRemove } = this.props;

		if (loading || !withRemove) {
			return;
		}

		onRemove();
	}

	onChangeLanguage(langID) {
		const { loading, onChangeLanguage } = this.props;
		if (loading || !onChangeLanguage) {
			return;
		}

		onChangeLanguage(langID);
	}

	// Renders --------------------------------------------------------------------------------------

	renderModalTitle() {
		const { loading, title, useLanguageSwitcher, langID, langDisabled } = this.props;

		return (
			<CustomModalTitle
				title={title}
				disabled={langDisabled || loading}
				useLanguageSwitcher={useLanguageSwitcher}
				langID={langID}
				onChangeLanguage={this.onChangeLanguage}
			/>
		);
	}

	renderModalFooter() {
		const {
			loading,
			noSave,
			noCancel,
			noLogout,
			noSaveClose,
			readOnly,
			withRemove,
			titleCancel,
			titleSave,
			titleSaveClose,
			titleRemove,
			titleLogout,
			saveComponent,
			saveAndCloseComponent,
			onlyX,
		} = this.props;

		const saveButton = saveComponent || (
			<Button disabled={readOnly || loading} onClick={this.onClickSave}>{titleSave}</Button>
		);

		const saveAndClose = saveAndCloseComponent || (
			<Button
				type="primary"
				disabled={readOnly || loading}
				onClick={this.onClickSaveClose}
			>
				{titleSaveClose}
			</Button>
		);

		const logout = saveAndCloseComponent || (
			<Button
				type="secondary"
				disabled={loading}
				onClick={this.onClickLogout}
			>
				{titleLogout}
			</Button>
		);

		const buttons = {
			cancel: {
				button: (
					<Button disabled={loading} onClick={this.onClickCancel}>
						{titleCancel}
					</Button>
				),
				position: 'left',
			},
			save: {
				button  : saveButton,
				position: 'right',
			},
			saveClose: {
				button  : saveAndClose,
				position: 'right',
			},
			remove: {
				button: (
					<Popconfirm
						title="Are you sure in deleting?"
						okText="Yes"
						cancelText="No"
						onConfirm={this.onClickRemove}
					>
						<Button type="danger" disabled={readOnly || loading} onClick={this.onClickRemove}>
							{titleRemove}
						</Button>
					</Popconfirm>
				),
				position: 'right',
			},
			logout: {
				button  : logout,
				position: 'left',
			},
		};

		const resButtons = [
		];
		if (withRemove) {
			resButtons.push(buttons.remove);
		}
		if (!noSave) {
			resButtons.push(buttons.save);
		}
		if (!noSaveClose) {
			resButtons.push(buttons.saveClose);
		}
		if (!noCancel && !onlyX) {
			resButtons.push(buttons.cancel);
		}
		if (!noLogout) {
			resButtons.push(buttons.logout);
		}

		return <CustomModalFooter buttons={resButtons} loading={loading} />;
	}

	render() {
		const { visible, width, children, bodyStyle, ...restProps } = this.props;

		const modalTitle  = this.renderModalTitle();
		const modalFooter = this.renderModalFooter();

		return (
			<DraggableModal
				{...restProps}
				visible={visible}
				title={modalTitle}
				footer={modalFooter}
				width={width}
				wrapClassName="drag-modal"
				onCancel={this.onClickCancel}
				styles={bodyStyle}
				// bodyStyle={bodyStyle}
			>
				{children}
			</DraggableModal>
		);
	}
}

export default StandartModal;
