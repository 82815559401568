import { all, takeEvery, call, put, select, fork } from 'redux-saga/effects';
import actions from './actions';
import listActions from '../list/actions';
import { blockedIpsAPI } from '../../../../../helpers/api/blockedIps';
import { prepareBlockedIp } from '../utils';
import { showSuccess, showError } from '../../../../../helpers/notifications';

const messages = {
	errorSave          : 'Saving Blocked IPs failed',
	errorBaseDataReload: 'Failed to load blocked ip data',
	successSave        : 'Blocked IP has been saved',
};

function getStoreData({ App,  Settings }) {

	return {
		baseData : Settings.Security.BlockedIPs.Ip.get('baseData'),
		UI       : Settings.Security.BlockedIPs.Ip.get('UI'),
		websiteID: App.get('websiteID'),
	};
}

function* dataSave() {

	yield takeEvery(actions.BLOCKED_IP_SAVE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { closeModal } = action.data;
		const { baseData, websiteID } = yield select(getStoreData);
		const preparedData = prepareBlockedIp({
			websiteID,
			...baseData,
		});

		const params = {
			website_id: websiteID,
		};

		let isError = false;
		try {
			yield call(blockedIpsAPI.blockedIpAdd, preparedData, params );
			showSuccess(messages.successSave);

			yield put(listActions.listReload());
			yield put(actions.uiRefresh({ isChanged: false }));
			yield put(actions.uiRefresh({
				visible: (isError || !closeModal),
			}));
		} catch (error) {
			isError = true;
			showError(messages.errorSave);
		}
		yield put(actions.uiRefresh({
			loading: false,
		}));
	});
}

export default function* ipSaga() {
	yield all([
		fork(dataSave),
	]);
}
