import { Entity } from './types';

export const createEntities = <T extends Entity, K extends keyof T>(
	list: T[], 
	idAttribute: K = 'id' as K
): Record<T[K], T> => {
	return list.reduce((acc, item) => {
		if (typeof item !== 'object' || Array.isArray(item)) {
			throw new Error('List items are not objects');
		}
		acc[item[idAttribute]] = item;
		return acc;
	}, {} as Record<T[K], T>);
};
