import toInteger from 'lodash/toInteger';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
	ROLE_TYPE,
	ROLE_TYPES_ENUM,
} from '../../../containers/StaffManagement/modals/Role/utils';

import { usersAPI } from '../../../helpers/api/users';
import { showError, showSuccess } from '../../../helpers/notifications';
import { isID } from '../../../helpers/utils';
import permissionsActions from '../permissions/actions';

import actions from './actions';
import { adaptPermissionsList, adaptRolesList, getListParams } from './utils';
import { logger } from '../../../helpers/logger';


const prefix = 'staff.roles';

const messages = {
	errorPermissionsListLoad: `${prefix}.errorPermissionsListLoad`,
	errorRolesListLoad      : `${prefix}.errorRolesListLoad`,
	errorRoleSave           : `${prefix}.errorRoleSave`,
	errorRoleDelete         : `${prefix}.errorRoleDelete`,
	successRoleSave         : `${prefix}.successRoleSave`,
	successRoleDelete       : `${prefix}.successRoleDelete`,
};

function getStoreData({ App, Staff, Users }) {
	const roleUI = Staff.Roles.get('roleUI');
	const userBaseData = Users.User.get('baseData');
	const { roleType } = Staff.Permissions.get('UI');
	const permissionsKeyList  = Staff.Permissions.get('permissionsKeyList');
	const filter = Staff.FilterRoles.get('filter');
	return {
		roleID     : roleUI.roleID,
		name       : roleUI.name,
		permissions: roleUI.permissions,
		loading    : roleUI.loading,
		visible    : roleUI.visible,
		closeModal : roleUI.closeModal,
		logout     : roleUI.logout,
		websiteID  : App.get('websiteID'),
		roleType,
		userBaseData,
		permissionsKeyList,
		filter,
	};
}

function* permissionsListReload() {
	yield takeEvery(actions.AVAILABLE_PERMISSIONS_LIST_RELOAD, function* () {
		let permissionsList = [];
		try {
			const res = yield call(usersAPI.availablePermissionsList);
			if (res && res.status === 200) {
				permissionsList = adaptPermissionsList(res.data.data);
			}
		} catch (error) {
			showError(messages.errorPermissionsListLoad);
			logger.log(error);
		}

		yield put(actions.permissionsListRefresh(permissionsList));
	});
}

function* rolesListReload() {
	yield takeEvery(actions.AVAILABLE_ROLES_LIST_RELOAD, function* ({ data }) {
		const { partner }  = data;
		const { filter } = yield select(getStoreData);
		const filterParams = getListParams(filter);
		const params = {
			partner,
			...filterParams,
		};


		yield put(actions.roleUIRefresh({ loading: true }));
		let rolesList = [];
		try {
			const res = yield call(usersAPI.availableUserRolesList, params);
			if (res && res.status === 200) {
				rolesList = adaptRolesList(res.data.data);
				yield put(actions.rolesListRefresh(rolesList));
			}

			yield put(actions.roleUIRefresh({ loading: false }));
		} catch (error) {
			showError(messages.errorRolesListLoad);
			yield put(actions.roleUIRefresh({ loading: false }));
			logger.log(error);
		}

	});
}

function* roleSave() {
	yield takeEvery(actions.AVAILABLE_ROLES_ITEM_SAVE, function* ({ data: payload }) {
		const { selectedPermissions, withPartner } = payload;
		yield put(actions.roleUIRefresh({ loading: true }));
		const { roleID, name, closeModal, websiteID, roleType, logout } = yield select(getStoreData);
		let isError = false;

		if (roleType === ROLE_TYPE.partner && !selectedPermissions.includes(ROLE_TYPE.partner)) {
			selectedPermissions.push(ROLE_TYPES_ENUM.partner);
		}
		const data = {
			name,
			permissions: selectedPermissions,
			website_id : websiteID,
			logout,
		};

		try {
			if (!isID(roleID)) {
				const res = yield call(usersAPI.availableRoleCreate, data);
				if (res && res.status === 200) {
					showSuccess(messages.successRoleSave);
					yield put(actions.roleUIRefresh({ roleID: toInteger(res.data.data.id) }));
				}
			} else {
				const res = yield call(usersAPI.availableRoleUpdate, roleID, data);
				if (res && res.status === 200) {
					showSuccess(messages.successRoleSave);

				}
			}
		} catch (error) {
			isError = true;
			showError(messages.errorRoleSave);
			yield put(actions.roleUIRefresh({ loading: false }));
			logger.log(error);
		}

		if (!isError && closeModal) {
			yield put(actions.roleUIReset());
			yield put(permissionsActions.dataRefresh([]));

		} else if (!isError && !closeModal && !withPartner) {
			yield put(actions.rolesListReload());
		}

		if (withPartner) {
			yield put(actions.rolesListReload(withPartner));
		} else {
			yield put(actions.rolesListReload());
		}
		yield put(
			actions.roleUIRefresh({
				loading  : false,
				isChanged: false,
			})
		);
	});
}

function* roleDelete() {
	yield takeEvery(actions.AVAILABLE_ROLES_ITEM_DELETE, function* (action) {
		yield put(actions.roleUIRefresh({ loading: true }));
		const { roleID } = action.data;
		const { logout } = yield select(getStoreData);
		const body = {
			logout,
		};
		try {
			const res = yield call(usersAPI.availableRoleDelete, roleID, body );
			if (res && res.status === 200) {
				showSuccess(messages.successRoleDelete);
				yield put(actions.rolesListReload());
			}
		} catch (error) {
			showError(messages.errorRolesListLoad);
			logger.log(error);
		}
		yield put(actions.roleUIRefresh({ loading: false }));

	});
}

export default function* rolesSaga() {
	yield all([
		fork(permissionsListReload),
		fork(rolesListReload),
		fork(roleSave),
		fork(roleDelete),
	]);
}
