import React from 'react';

export const CasinoIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.9999 9.9C12.4999 9.9 12.7999 10.3 12.7999 10.7C12.7999 11.1 13.1999 11.5 13.5999 11.5C14.0999 11.5 14.3999 11.1 14.3999 10.7C14.3999 9.7 13.6999 8.8 12.6999 8.5V7.3C12.6999 6.9 12.2999 6.5 11.8999 6.5C11.3999 6.5 11.0999 6.9 11.0999 7.3V8.5C10.0999 8.8 9.3999 9.7 9.3999 10.7C9.4999 12 10.5999 13 11.9999 13C12.4999 13 12.7999 13.4 12.7999 13.8C12.7999 14.2 12.3999 14.6 11.9999 14.6C11.4999 14.6 11.1999 14.2 11.1999 13.8C11.1999 13.4 10.7999 13 10.3999 13C9.8999 13 9.5999 13.4 9.5999 13.8C9.5999 14.8 10.2999 15.7 11.2999 16V16.7C11.2999 17.1 11.6999 17.5 12.0999 17.5C12.5999 17.5 12.8999 17.1 12.8999 16.7V16C13.8999 15.7 14.5999 14.8 14.5999 13.8C14.5999 12.5 13.4999 11.4 12.0999 11.4C11.5999 11.4 11.2999 11 11.2999 10.6C11.1999 10.2 11.4999 9.9 11.9999 9.9Z" fill="#323332" />
			<path d="M11.9998 1.20001C7.99984 1.20001 4.39984 3.40001 2.49984 6.90001L1.79984 4.20001C1.69984 3.90001 1.39984 3.70001 1.09984 3.80001C0.799841 3.90001 0.599841 4.30001 0.699841 4.60001L1.79984 8.50001C1.89984 8.90001 2.29984 9.00001 2.69984 8.90001L6.99984 7.80001C7.29984 7.70001 7.39984 7.30001 7.29984 7.00001C7.19984 6.60001 6.89984 6.50001 6.59984 6.60001L3.69984 7.30001C5.39984 4.30001 8.49984 2.40001 11.9998 2.40001C17.2998 2.40001 21.5998 6.70001 21.5998 12C21.5998 17.3 17.2998 21.6 11.9998 21.6C6.69984 21.6 2.39984 17.3 2.39984 12C2.39984 11.7 2.09984 11.4 1.79984 11.4C1.49984 11.4 1.19984 11.7 1.19984 12C1.19984 17.9 5.99984 22.8 11.9998 22.8C17.9998 22.8 22.7998 17.9 22.7998 12C22.7998 6.10001 17.8998 1.20001 11.9998 1.20001Z" fill="#323232" />
		</svg>
	);
};
