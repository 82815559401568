import { combineReducers } from 'redux';

import List from './list/reducer';
import Payment from './payment/reducer';

export default combineReducers({
	List,
	Payment,

});
