import { makeActionCreator } from '../../helpers';

const TYPES = {
	CASINO_GAMES_LIST_RELOAD          : 'CASINO_GAMES_LIST_RELOAD',
	CASINO_GAMES_LIST_RELOAD_GAMES    : 'CASINO_GAMES_LIST_RELOAD_GAMES',
	CASINO_GAMES_LIST_RESET           : 'CASINO_GAMES_LIST_RESET',
	CASINO_GAMES_LIST_REFRESH         : 'CASINO_GAMES_LIST_REFRESH',
	CASINO_GAMES_ENTITIES_REFRESH     : 'CASINO_GAMES_ENTITIES_REFRESH',
	CASINO_GAMES_SEARCH_LIST_RELOAD  	: 'CASINO_GAMES_SEARCH_LIST_RELOAD',
	CASINO_GAMES_SEARCH_LIST_REFRESH  : 'CASINO_GAMES_SEARCH_LIST_REFRESH',  // setting new data [...new]
	CASINO_GAMES_SEARCH_LIST_UPDATE   : 'CASINO_GAMES_SEARCH_LIST_UPDATE', // adding new data [...old, ...new]
	CASINO_GAMES_LIST_REFRESH_FROM_API: 'CASINO_GAMES_LIST_REFRESH_FROM_API',
	CASINO_GAMES_LIST_MODAL_REFRESH   : 'CASINO_GAMES_LIST_MODAL_REFRESH',

	FILTER_REFRESH           : 'CASINO_GAMES_LIST_FILTER_REFRESH',
	FILTER_RESET             : 'CASINO_GAMES_LIST_FILTER_RESET',
	PAGINATION_REFRESH       : 'CASINO_GAMES_LIST_PAGINATION_REFRESH',
	SEARCH_PAGINATION_REFRESH: 'CASINO_GAMES_LIST_SEARCH_PAGINATION_REFRESH',
	SEARCH_PAGINATION_RESET  : 'CASINO_GAMES_LIST_SEARCH_PAGINATION_RESET',

	CASINO_GAMES_UI_REFRESH  : 'CASINO_GAMES_UI_REFRESH',
	CASINO_GAMES_SEARCH_VALUE: 'CASINO_GAMES_SEARCH_VALUE',

	UPDATE_PAGE_NUM  : 'UPDATE_PAGE_NUM',
	UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',

	UPDATE_SEARCH_QUERY: 'UPDATE_SEARCH_QUERY',

	CASINO_GAMES_NEW_GAMES_BY_PAGE_RELOAD : 'CASINO_GAMES_NEW_GAMES_BY_PAGE_RELOAD',
	CASINO_GAMES_NEW_GAMES_BY_PAGE_REFRESH: 'CASINO_GAMES_NEW_GAMES_BY_PAGE_REFRESH',

	CASINO_GAMES_PROVIDERS_WITH_GAMES_RELOAD : 'CASINO_GAMES_PROVIDERS_WITH_GAMES_RELOAD',
	CASINO_GAMES_PROVIDERS_WITH_GAMES_REFRESH: 'CASINO_GAMES_PROVIDERS_WITH_GAMES_REFRESH',

	CASINO_GAMES_DATA_RESET: 'CASINO_GAMES_DATA_RESET',
};

const actions = {
	...TYPES,

	filterRefresh: makeActionCreator(TYPES.FILTER_REFRESH, 'filter'),
	filterReset  : makeActionCreator(TYPES.FILTER_RESET),

	/**
	 *
	 * @param websiteID
	 * @param casinoID
	 * @param channelID
	 * @param byCategory
	 * @param name is string
	 * @param providerType is string
	 * @param limit is number
	 * @param page  is number
	 * @param isUnlimit
	 * @returns {{data: {websiteID, byCategory: null, name, limit: number, casinoID, page: number, channelID, providerType: string}, type: string}}
	 */
	gamesListReload: (websiteID, casinoID, channelID, byCategory  = null, name, providerType = 'provider', limit, page, isUnlimit = true, byCurrency, sorting) => {

		return  {
			type: actions.CASINO_GAMES_LIST_RELOAD,
			data:	{
				websiteID,
				casinoID,
				channelID,
				byCategory,
				providerType,
				name,
				limit,
				page,
				isUnlimit,
				byCurrency,
				sorting,
			},
		};
	},

	gamesListReloadLimited: (name, page) => {
		return {
			type: actions.CASINO_GAMES_NEW_GAMES_BY_PAGE_RELOAD,
			data: {
				name,
				page,
			},
		};
	},

	casinoGameListReload: (data) => {
		return  {
			type: actions.CASINO_GAMES_LIST_RELOAD_GAMES,
			data,
		};
	},

	gamesListRefresh: list => ({
		type: actions.CASINO_GAMES_LIST_REFRESH,
		data: list,
	}),

	gamesEntitiesRefresh: data => ({
		type: actions.CASINO_GAMES_ENTITIES_REFRESH,
		data,
	}),

	gamesListModalRefresh: list => ({
		type: actions.CASINO_GAMES_LIST_MODAL_REFRESH,
		data: list,
	}),

	gamesSearchListReload: data => ({
		type: actions.CASINO_GAMES_SEARCH_LIST_RELOAD,
		data,
	}),

	gamesSearchListRefresh: list => ({
		type: actions.CASINO_GAMES_SEARCH_LIST_REFRESH,
		data: list,
	}),

	setSearchvalue: value => ({
		type: actions.CASINO_GAMES_SEARCH_VALUE,
		data: value,
	}),

	gamesListRefreshFromAPI: casinoID => ({
		type: actions.CASINO_GAMES_LIST_REFRESH_FROM_API,
		data: {
			casinoID,
		},
	}),

	uiRefresh: data => ({
		type: actions.CASINO_GAMES_UI_REFRESH,
		data,
	}),

	updatePageNum: (data) => {
		return {
			type: actions.UPDATE_PAGE_NUM,
			data,
		};
	},

	updateCategories: data => {
		return {
			type: actions.UPDATE_CATEGORIES,
			data,
		};
	},

	updateSearchQuery: data => {
		return {
			type: actions.UPDATE_SEARCH_QUERY,
			data,
		};
	},

	gamesListRefreshLimited: data => {
		return {
			type: actions.CASINO_GAMES_NEW_GAMES_BY_PAGE_REFRESH,
			data,
		};
	},

	gamesListRefreshPagination: data => {
		return {
			type: actions.PAGINATION_REFRESH,
			data,
		};
	},
	gamesListRefreshSearchPagination: data => {
		return {
			type: actions.SEARCH_PAGINATION_REFRESH,
			data,
		};
	},
	gamesListResetSearchPagination: data => {
		return {
			type: actions.SEARCH_PAGINATION_RESET,
			data,
		};
	},

	casinoGamesProvidersWithGamesReload: (data) => {
		return {
			type: actions.CASINO_GAMES_PROVIDERS_WITH_GAMES_RELOAD,
			data,
		};
	},

	casinoGamesProvidersWithGamesRefresh: data => (
		{
			type: actions.CASINO_GAMES_PROVIDERS_WITH_GAMES_REFRESH,
			data,
		}
	),

	dataReset: () => ({
		type: actions.CASINO_GAMES_DATA_RESET,
	}),

	gameListReset: () => ({
		type: actions.CASINO_GAMES_LIST_RESET,
	}),
};

export default actions;
