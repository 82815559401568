import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const SettingsIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_125)">
				<path {...props} d="M0.344543 10.7404L1.79909 13.2597C1.97247 13.5599 2.33363 13.696 2.66197 13.585L4.80229 12.8609C4.99143 12.9856 5.18755 13.0991 5.38939 13.2006L5.83235 15.4154C5.90032 15.7553 6.19885 16 6.54551 16H9.4546C9.80127 16 10.0998 15.7553 10.1678 15.4154L10.6107 13.2006C10.8126 13.0991 11.0086 12.9856 11.1978 12.8609L13.3381 13.585C13.6665 13.696 14.0277 13.56 14.201 13.2597L15.6556 10.7403C15.8289 10.4401 15.7663 10.0592 15.5059 9.8304L13.8084 8.33867C13.8149 8.22565 13.8182 8.11253 13.8182 8C13.8182 7.88742 13.8149 7.7743 13.8084 7.66128L15.5058 6.1696C15.7662 5.94075 15.8288 5.5599 15.6555 5.25968L14.2009 2.74032C14.0276 2.4401 13.6664 2.3039 13.338 2.41503L11.1978 3.1391C11.0087 3.0144 10.8126 2.90095 10.6107 2.79942L10.1678 0.58463C10.0998 0.244703 9.80127 0 9.4546 0H6.54551C6.19885 0 5.90032 0.244703 5.83235 0.58463L5.38939 2.79942C5.18755 2.90095 4.99148 3.01445 4.80234 3.1391L2.66197 2.41503C2.33373 2.304 1.97247 2.4401 1.79909 2.74032L0.344543 5.25968C0.17121 5.5599 0.233852 5.94075 0.494264 6.1696L2.19162 7.66128C2.18512 7.77416 2.18188 7.88727 2.18188 8C2.18188 8.11273 2.18512 8.22589 2.19162 8.33871L0.494264 9.8304C0.233852 10.0592 0.17121 10.4401 0.344543 10.7404ZM8.00006 5.09091C9.60413 5.09091 10.9091 6.39593 10.9091 8C10.9091 9.60407 9.60413 10.9091 8.00006 10.9091C6.39598 10.9091 5.09097 9.60407 5.09097 8C5.09097 6.39593 6.39598 5.09091 8.00006 5.09091Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_125">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
SettingsIcon.propTypes = propTypesIcons;

SettingsIcon.defaultProps = defaultPropsIcons;
