export const normalizePartnerWebsiteData = (data = [], partnersWithWebsites = []) => {
	const template = {
		partnerIDs: [],
		websiteIDs: [],
	};

	const websitePrefix = 'website';

	return  data.reduce((acc, item) => {
		const [type, id] = item.split('_');

		if (type === websitePrefix) {
			const partnerCandidate = partnersWithWebsites.find(partner => partner.websites.some(website => Number(website.id) === Number(id)));

			if (partnerCandidate && !acc.partnerIDs.includes(partnerCandidate.id)) {
				acc.partnerIDs.push(partnerCandidate.id);
			}
			acc.websiteIDs.push(Number(id));
		} else {
			acc.partnerIDs.push(Number(id));
		}

		return acc;
	}, template);
};
