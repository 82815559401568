import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

	.label {
		padding-right: 16px;
	}

	.control {
		width: 135px;
	}
`;
