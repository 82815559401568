import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../../tables/actions';

import { emailsAPI } from '../../../../../helpers/api/emails';
import { showError, showSuccess } from '../../../../../helpers/notifications';
import { deriveTablePagination } from '../../../../../selectors/tables';

import { getListParams, adaptEmailsList } from './utils';
import { TABLE_TYPES } from '../../../../../constants/tableTypes';
import { logger } from '../../../../../helpers/logger';

const prefix = 'notifcenter.emails.list';

const messages = {
	errorListLoad     : `${prefix}.errorListLoad`,
	errorEmailResend  : `${prefix}.errorEmailResend`,
	successEmailResend: `${prefix}.successEmailResend`,
};

const tableType        = TABLE_TYPES.emailsList;

function getStoreData(state) {
	const { Emails, Tables } = state;

	return {
		filter    : Emails.List.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.EMAILS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { filter, pagination, sorting } = yield select(getStoreData);

		const params   = getListParams(filter, pagination, sorting);
		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(emailsAPI.emailsList, params);
			if (res && res.status === 200) {
				const result = adaptEmailsList(res.data.data, res.headers);
				entities     = result.entities;   // eslint-disable-line prefer-destructuring
				totalCount   = result.totalCount; // eslint-disable-line prefer-destructuring
			}

		} catch (error) {
			showError(messages.errorListLoad, error);
			logger.log(error);
		}
		
		yield put(actions.listRefresh(entities));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* resendEmail() {

	yield takeEvery(actions.EMAILS_LIST_RESEND_EMAIL, function* (action) {

		const { emailID } = action.data;
		try {
			const res = yield call(emailsAPI.emailResend, emailID);
			if (res && res.status === 200) {
				showSuccess(messages.successEmailResend);
				yield put(actions.listReload());
			}

		} catch (error) {
			showError(messages.errorEmailResend, error);
			logger.log(error);
		}
	});
}

function* filterApply() {

	yield takeEvery(actions.EMAILS_LIST_FILTER_APPLY, function* () {

		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

export default function* emailsListSaga() {
	yield all([
		fork(listReload),
		fork(resendEmail),
		fork(filterApply),
	]);
}
