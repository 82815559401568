import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData : {},
	namesData: [],

	UI: {
		visible   : false,
		paymentID : null,
		paywayID  : null,
		loading   : false,
		closeModal: false,
		langID    : null,
		isChanged : false,
	},
});

export default function paywayModalReducer(state = initState, action) {

	switch (action.type) {

		case actions.PAYWAY_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}

		case actions.PAYWAY_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.PAYWAY_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.PAYWAY_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
