import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../../helpers/utils';

const initialState = new Map({
	baseData: {},

	UI: {
		visible    : false,
		editMode   : false,
		loading    : false,
		blockedIpID: null,
		isChanged  : false,
	},
});

export default (state = initialState, { type, data }) => {

	switch (type) {

		case actions.BLOCKED_IP_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(data, target, true);
			return state.set('baseData', result);
		}

		case actions.BLOCKED_IP_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(data, target);
			return state.set('UI', result);
		}

		case actions.BLOCKED_IP_DATA_RESET: {
			return initialState;
		}

		default:
			return state;
	}
};
