import { apiRequest } from './index';

function reportsListByGame(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/casinoReports/listByGame',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function reportsListByCSV(params = {}, listBy) {

	const req = {
		method       : 'GET',
		url          : `/casinoReports/csv/${listBy}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

export const reportsListByGameAPI = {
	reportsListByGame,
	reportsListByCSV,
};
