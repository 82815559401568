const actions = {

	INFO_PAGES_PAGE_SAVE             : 'INFO_PAGES_PAGE_SAVE',
	INFO_PAGES_PAGE_DATA_RESET       : 'INFO_PAGES_PAGE_DATA_RESET',
	INFO_PAGES_PAGE_BASE_DATA_RELOAD : 'INFO_PAGES_PAGE_BASE_DATA_RELOAD',
	INFO_PAGES_PAGE_BASE_DATA_REFRESH: 'INFO_PAGES_PAGE_BASE_DATA_REFRESH',

	INFO_PAGES_PAGE_UI_REFRESH: 'INFO_PAGES_PAGE_UI_REFRESH',

	INFO_PAGES_REVISIONS_LIST_RELOAD : 'INFO_PAGES_REVISIONS_LIST_RELOAD',
	INFO_PAGES_REVISIONS_LIST_REFRESH: 'INFO_PAGES_REVISIONS_LIST_REFRESH',
	INFO_PAGES_REVISION_SAVE         : 'INFO_PAGES_REVISION_SAVE',
	INFO_PAGES_REVISION_APPLY        : 'INFO_PAGES_REVISION_APPLY',


	pageSave: pageID => ({
		type: actions.INFO_PAGES_PAGE_SAVE,
		data: {
			pageID,
		},
	}),
	dataReset: () => ({
		type: actions.INFO_PAGES_PAGE_DATA_RESET,
	}),
	baseDataReload: pageID => ({
		type: actions.INFO_PAGES_PAGE_BASE_DATA_RELOAD,
		data: {
			pageID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.INFO_PAGES_PAGE_BASE_DATA_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.INFO_PAGES_PAGE_UI_REFRESH,
		data,
	}),

	revisionsListReload: pageID => ({
		type: actions.INFO_PAGES_REVISIONS_LIST_RELOAD,
		data: {
			pageID,
		},
	}),
	revisionsListRefresh: revisionsList => ({
		type: actions.INFO_PAGES_REVISIONS_LIST_REFRESH,
		data: revisionsList,
	}),
	revisionSave: (pageID, revisionData) => ({
		type: actions.INFO_PAGES_REVISION_SAVE,
		data: {
			pageID,
			revisionData,
		},
	}),
	revisionApply: (pageID, revisionID) => ({
		type: actions.INFO_PAGES_REVISION_APPLY,
		data: {
			pageID,
			revisionID,
		},
	}),

};

export default actions;
