import { all, put, fork, call, select, takeEvery } from 'redux-saga/effects';
import notifications from '../../../helpers/notifications';
import actions from './actions';
import { dashboardAPI } from '../../../helpers/api/dashboard';
import { createCommonParams } from '../utils';
import { adaptOverallData, adaptObjData } from './utils';

const prefix = 'dashboard.overall';

const messages = {
	errorPlayersOverallData: `${prefix}.errorPlayersOverallData`,
};


function getStoreData({ Dashboard, App }) {
	return {
		data     : Dashboard.Overall.get('overallData'),
		period   : Dashboard.Common.get('period'),
		websiteID: App.get('websiteID'),
	};
}

function* overallDataReload() {

	yield takeEvery(actions.PLAYERS_OVERALL_DATA_RELOAD, function* () {
		yield put(actions.overalUIRefresh({ loading: true }));
		let dataToStore = {};
		const { period, websiteID } = yield select(getStoreData);

		const params = createCommonParams(period, websiteID);
		try {
			const response = yield call(dashboardAPI.playersOverallReq, params);
			if (response && response.status === 200) {
				dataToStore = adaptOverallData(response.data.data);
				Object.entries(dataToStore).map(([key, value]) => {
					dataToStore[key] = adaptObjData(value);
					return true;
				});
			}
		} catch (error) {
			notifications.showError(messages.errorPlayersOverallData, error);
		}
		yield put(actions.overallDataRefresh(dataToStore));
		yield put(actions.overalUIRefresh({ loading: false }));
	});
}

export default function* overallDataSaga() {
	yield all([
		fork(overallDataReload),
	]);
}
