import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import actions from './actions';
import { defaultValuesResultsFilter, initFilterTypes } from './utils';

const initState = new Map({
	filter     : createFilter(filtersURL.resultsList, initFilterTypes, defaultValuesResultsFilter),
	countryList: [],
	leagueList : [],
});

export default function resultsFilterReducer(state = initState, action) {
	switch (action.type) {
		case actions.RESULTS_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.RESULTS_FILTER_COUNTRY_LIST_REFRESH: {
			return state.set('countryList', action.data);
		}

		case actions.RESULTS_FILTER_LEAGUE_LIST_REFRESH: {
			return state.set('leagueList', action.data);
		}

		case actions.FILTER_RESET: {
			return state.set('filter', defaultValuesResultsFilter);
		}

		default:
			return state;
	}
}
