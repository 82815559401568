import { EVENT_TYPES } from '../codes';
import { emitEvent } from '../socket';

function subscribeToUpdate(withdrawalIDs) {

	const event = {
		subjectType: EVENT_TYPES.withdrawal,
		ids        : withdrawalIDs,
	};

	return emitEvent('subscribe', event);
}

export const withdrawalsAPI = {
	subscribeToUpdate,
};
