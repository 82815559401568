import FormControl from '../../uielements/FormControl';
import Input from '../../uielements/input';

const FormInput = (props) => {
	const { label, labelWidth, height, name, widthInput, onChange, value, ...restProps } = props;
	const isLabel = (Boolean(labelWidth) || labelWidth === 0);
	const lw = isLabel ? labelWidth : 25;
	const mb = (labelWidth === 0) ? 0 : undefined;
	const h  = height ? { height } : {};
	return (
		<FormControl
			label={label}
			labelWidth={lw}
			marginBottom={mb}
			widthInput={widthInput}
			{...h}
			control={(
				<Input
					name={name}
					value={value}
					onChange={onChange}
					{...restProps}
				/>
			)}
		/>
	);
};

export default FormInput;
