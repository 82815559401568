import ParamsBuilder from '../../../../helpers/paramsBuilder';
import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';

const { isValidID, isValidString } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	status       : isValidID,
	partnerName  : isValidString,
	contactPerson: isValidString,
};
export const defaultValuesPartnersFilter = {
	partnerName  : '',
	contactPerson: '',
	status       : null,
};

const fields = {
	partnerName  : 'name',
	contactPerson: 'contact_name',
	status       : 'status_id',
};

export function getFilterParams(filter = {}, sorting = {}, pagination = null) {

	const builder = new ParamsBuilder();
	const rules   = builder.RULES;
	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isString, 'partnerName', fields.partnerName);
	builder.addField(rules.isString, 'contactPerson', fields.contactPerson);
	builder.addField(rules.number, 'status', fields.status);

	// builder.addField(rules.isString, 'sortBy',  fields.sortBy);
	// builder.addField(rules.isString, 'sortOrder',  fields.sortOrder);
	const params = builder.biuldParams({ ...filter, ...sorting });

	return params;
}
