const prefix = 'RTM_DOCUMENTS_';

const actions = {

	RELOAD            : `${prefix}RELOAD`,
	BASE_DATA_REFRESH : `${prefix}BASE_DATA_REFRESH`,
	ITEM_STATUS_UPDATE: `${prefix}ITEM_STATUS_UPDATE`,

	UI_REFRESH        : `${prefix}UI_REFRESH`,
	DATA_MODAL_REFRESH: `${prefix}DATA_MODAL_REFRESH`,
	DATA_RESET        : `${prefix}RESET`,

	RTM_DOCUMENTS_DATA_REFRESH             : `${prefix}DATA_REFRESH`,
	RTM_DOCUMENTS_NEW_DOCUMENT_LIST_REFRESH: `${prefix}NEW_DOCUMENT_LIST_REFRESH`,
	RTM_DOCUMENTS_PAGINATION_REFRESH    			: `${prefix}PAGINATION_REFRESH`,

	RTM_DOCUMENTS_FILTER_RESET		: `${prefix}FILTER_RESET`,
	RTM_DOCUMENTS_FILTER_REFRESH: `${prefix}FILTER_REFRESH`,
	RTM_DOCUMENTS_FILTER_APPLY		: `${prefix}FILTER_APPLY`,

	RTM_DOCUMENTS_SOCKET_NOTIFY_CREATE_DOCUMENT: `${prefix}SOCKET_NOTIFY_CREATE_DOCUMENT`,
	RTM_DOCUMENTS_SOCKET_NOTIFY_UPDATE_DOCUMENT: `${prefix}SOCKET_NOTIFY_UPDATE_DOCUMENT`,
	RTM_DOCUMENTS_SOCKET_SUBSCRIBE_TO_UPDATE   : `${prefix}SOCKET_SUBSCRIBE_TO_UPDATE`,

	listReload: (data) => ({
		type: actions.RELOAD,
		data,

	}),

	dataRefresh: (documentIDs, entities) => ({
		type: actions.RTM_DOCUMENTS_DATA_REFRESH,
		data: {
			documentIDs,
			entities,
		},
	}),

	newDocumentListRefresh: (documentIDs, newDocumentEntities) => ({
		type: actions.RTM_DOCUMENTS_NEW_DOCUMENT_LIST_REFRESH,
		data: {
			documentIDs,
			newDocumentEntities,
		},
	}),

	baseDataRefresh: data => ({
		type: actions.BASE_DATA_REFRESH,
		data,
	}),

	filterReset: () => ({
		type: actions.RTM_DOCUMENTS_FILTER_RESET,
	}),

	filterRefresh: data => ({
		type: actions.RTM_DOCUMENTS_FILTER_REFRESH,
		data,
	}),

	filterApply: () => ({
		type: actions.RTM_DOCUMENTS_FILTER_APPLY,
	}),

	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),

	paginationRefresh: data => ({
		type: actions.RTM_DOCUMENTS_PAGINATION_REFRESH,
		data,
	}),

	statusUpdate: (userId, documentId, statusId) => ({
		type: actions.ITEM_STATUS_UPDATE,
		data: {
			userId,
			documentId,
			statusId,
		},
	}),

	dataModalRefresh: data => ({
		type:	actions.DATA_MODAL_REFRESH,
		data,
	}),

	dataReset: () => ({
		type: actions.DATA_RESET,
	}),

	notifyCreateDocument: data => ({
		type: actions.RTM_DOCUMENTS_SOCKET_NOTIFY_CREATE_DOCUMENT,
		data,
	}),

	notifyUpdateDocument: data => ({
		type: actions.RTM_DOCUMENTS_SOCKET_NOTIFY_UPDATE_DOCUMENT,
		data,
	}),

	subscribeToUpdate: documentIDs => ({
		type: actions.RTM_DOCUMENTS_SOCKET_SUBSCRIBE_TO_UPDATE,
		data: {
			documentIDs,
		},
	}),

};

export default actions;
