import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import toInteger from 'lodash/toInteger';
import values from 'lodash/values';

const websiteEntities     		= (state) => state.Partner.Websites.List.get('entities');

const baseData            		= (state) => state.Partner.Websites.Website.get('baseData');
const websiteUI           		= (state) => state.Partner.Websites.Website.get('UI');

const websitePayments     		= (state) => state.Partner.Websites.Payments.get('payments');
const paymentLimits       		= (state) => state.Partner.Websites.Payments.get('paymentLimits');
const paymentDescriptions 		= (state) => state.Partner.Websites.Payments.get('paymentDescriptions');
const paymentsMainData			= (state) => state.Partner.Websites.Payments.get('baseMainData');

const currencyEntities			= (state) => state.Partner.Websites.Website.get('currencies');
const partnerScopeCurrencies	= (state) => state.Partner.Websites.Website.get('partnerScopeCurrencies');
const casinoEntities      		= (state) => state.Partner.Websites.Website.get('casino');
const activeTab           		= (state) => state.Partner.Websites.Website.get('activeTab');

const languageEntities    		= (state) => state.Partner.Websites.Website.get('languages');
const countryEntities     		= (state) => state.Partner.Websites.Website.get('countries');
// Websites ---------------------------------------------------------------------------------------

export const deriveWebsiteEntities = createSelector(
	[websiteEntities],
	(websiteEntities) => websiteEntities
);

export const deriveWebsiteList = createSelector(
	[websiteEntities],
	(websiteEntities) => values(websiteEntities)
);


export const deriveBaseData = createSelector(
	[baseData],
	(baseData) => baseData
);

export const deriveWebsitePaymentsList = createSelector(
	[paymentsMainData],
	(paymentsBaseMainData) => paymentsBaseMainData
);
export const deriveActiveTab = createSelector(
	[activeTab],
	(activeTab) => activeTab
);
export const deriveWebsiteUI = createSelector(
	[websiteUI],
	(UI) => UI
);

// Payments ---------------------------------------------------------------------------------------

export const derivePaymentsEntities = createSelector(
	[websitePayments],
	(entities) => entities
);

export const derivePaymentsList = createSelector(
	[derivePaymentsEntities],
	(entities) => sortBy(values(entities), [
		(item) => toInteger(item.orderID),
		(item) => toInteger(item.id),
	])
);

export const derivePaymentsLimits = createSelector(
	[paymentLimits],
	(paymentLimits) => paymentLimits
);

export const derivePaymentsLimitsList = createSelector(
	[paymentLimits],
	(paymentLimits) => values(paymentLimits)
);

const currentPaymentLimits = (state, websitePaymentID) => {
	const paymentLimits = derivePaymentsLimits(state);
	return paymentLimits[websitePaymentID] || [];
};

export const deriveCurrentPaymentLimits = createSelector(
	[currentPaymentLimits],
	(paymentLimits) => paymentLimits
);

export const derivePaymentDescriptions = createSelector(
	[paymentDescriptions],
	(paymentDescriptions) => paymentDescriptions
);

const currentPaymentDescriptions = (state, websitePaymentID) => {
	const paymentDescriptions = derivePaymentDescriptions(state);
	return paymentDescriptions[websitePaymentID] || [];
};

export const deriveCurrentPaymentDescriptions = createSelector(
	[currentPaymentDescriptions],
	(paymentDescriptions) => paymentDescriptions
);

// Currency ---------------------------------------------------------------------------------------

export const deriveCurrencyEntities = createSelector(
	[currencyEntities],
	(currencyEntities) => currencyEntities
);
export const derivePartnerScopeCurrencies = createSelector(
	[partnerScopeCurrencies],
	(partnerScopeCurrencies) => partnerScopeCurrencies
);

export const deriveCurrencyList = createSelector(
	[deriveCurrencyEntities],
	(currencyEntities) => sortBy( values(currencyEntities), ['orderID', 'name'] )
);

// Casino -----------------------------------------------------------------------------------------

export const deriveCasinoEntities = createSelector(
	[casinoEntities],
	(entities) => entities
);

export const deriveCasinoList = createSelector(
	[deriveCasinoEntities],
	(entities) => sortBy( values(entities), ['name'] )
);

// Countries ---------------------------------------------------------------------------------------

export const deriveCountryEntities = createSelector(
	[countryEntities],
	(countries) => countries
);

export const deriveCountryList = createSelector(
	[deriveCountryEntities],
	(countries) =>  sortBy(countries, ['isExist'])
);
// Languages ---------------------------------------------------------------------------------------

export const deriveLanguagesEntities = createSelector(
	[languageEntities],
	(languages) => languages
);

export const deriveLanguagesList = createSelector(
	[deriveLanguagesEntities],
	(languages) =>  sortBy(values(languages), ['orderID'])
);
