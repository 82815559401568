
import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const ReportsIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_157)">
				<path {...props} d="M9.0625 0.274506V3.12501H11.9129L9.0625 0.274506Z" fill={color} />
				<path {...props} d="M11.3596 5.625C11.644 5.625 11.9211 5.657 12.1875 5.71728V4.0625H8.59375C8.33488 4.0625 8.125 3.85262 8.125 3.59375V0H1.71875C0.771031 0 0 0.771031 0 1.71875V14.2812C0 15.229 0.771031 16 1.71875 16H10.4688C11.4165 16 12.1875 15.229 12.1875 14.2812V13.0327C11.9211 13.093 11.644 13.125 11.3596 13.125C9.29187 13.125 7.60962 11.4427 7.60962 9.375C7.60962 7.30725 9.29187 5.625 11.3596 5.625ZM6.40625 11.5625H2.34375C2.08488 11.5625 1.875 11.3526 1.875 11.0938C1.875 10.8349 2.08488 10.625 2.34375 10.625H6.40625C6.66512 10.625 6.875 10.8349 6.875 11.0938C6.875 11.3526 6.66512 11.5625 6.40625 11.5625ZM6.40625 9.6875H2.34375C2.08488 9.6875 1.875 9.47762 1.875 9.21875C1.875 8.95988 2.08488 8.75 2.34375 8.75H6.40625C6.66512 8.75 6.875 8.95988 6.875 9.21875C6.875 9.47762 6.66512 9.6875 6.40625 9.6875ZM6.40625 7.8125H2.34375C2.08488 7.8125 1.875 7.60262 1.875 7.34375C1.875 7.08488 2.08488 6.875 2.34375 6.875H6.40625C6.66512 6.875 6.875 7.08488 6.875 7.34375C6.875 7.60262 6.66512 7.8125 6.40625 7.8125ZM7.65625 5.9375H2.34375C2.08488 5.9375 1.875 5.72762 1.875 5.46875C1.875 5.20988 2.08488 5 2.34375 5H7.65625C7.91512 5 8.125 5.20988 8.125 5.46875C8.125 5.72762 7.91512 5.9375 7.65625 5.9375Z" fill={color} />
				<path {...props} d="M15.8625 13.2152L13.651 11.0037C13.9788 10.5438 14.1719 9.98153 14.1719 9.375C14.1719 7.82419 12.9102 6.5625 11.3594 6.5625C9.80856 6.5625 8.54688 7.82419 8.54688 9.375C8.54688 10.9258 9.80856 12.1875 11.3594 12.1875C11.9659 12.1875 12.5282 11.9945 12.9881 11.6667L15.1995 13.8781C15.2911 13.9696 15.411 14.0154 15.531 14.0154C15.6509 14.0154 15.7709 13.9696 15.8624 13.8781C16.0456 13.695 16.0456 13.3982 15.8625 13.2152Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_157">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
ReportsIcon.propTypes = propTypesIcons;

ReportsIcon.defaultProps = defaultPropsIcons;
