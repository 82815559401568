import styled from 'styled-components';

export const SearchForm = styled.div`
    position: fixed!important;
    bottom: 20px;
    right: 15px;
    z-index: 1001;

    &.non-active {
      display: none;
    }
`;

export const SubmitButton = styled.button`
  display: none;
`;
