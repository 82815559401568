import { useState } from 'react';
import Dropdown, { DropdownMenu, MenuItem } from '../../../../components/uielements/dropdown';
import Intl from '../../../../components/utility/intlMessages';
import { Wrapper } from './TabTitle.style';
import { lang, menuKeys } from './utils';
import { APP_TABS } from '../../../../constants/appTabs';
import { Tooltip } from 'antd';

const TabTitle = ({ id, titleID, closeTab, closeOtherTabs, closeRightTabs, tabs }) => {
	const [visible, setVisible] = useState(null);
	const [lastItemDisable, setLastItemDisable] = useState(false);

	const onClickItem = (e) => {
		e.domEvent.stopPropagation();
		setVisible(false);
		switch (+e.key) {
			case menuKeys.close:
				closeTab(id, 'remove', 'closeContext');
				break;
			case menuKeys.closeOtherTabs:
				closeOtherTabs(id);
				break;
			case menuKeys.closeRightTabs:
				closeRightTabs(id);
				break;
			default:
				break;
		}
	};

	const menu = (
		<DropdownMenu onClick={onClickItem}>
			<MenuItem key={menuKeys.close}>{lang.close}</MenuItem>
			<MenuItem key={menuKeys.closeOtherTabs}>{lang.closeOtherTabs}</MenuItem>
			<MenuItem key={menuKeys.closeRightTabs} disabled={lastItemDisable}>{lang.closeRightTabs}</MenuItem>
		</DropdownMenu>
	);

	const onContextMenu = (e) => {
		if (id === APP_TABS.dashboard) return;
		e.preventDefault();
		setVisible(true);
		const lastItem = tabs[tabs.length - 1];
		if (lastItem.id === id) {
			setLastItemDisable(true);
		}
	};
	return (
		<Tooltip placement="top" title={<Intl id={titleID} />}>
			<Dropdown className="dropdownAppTabs" overlay={menu} visible={visible} onVisibleChange={() => setVisible(false)}>
				<Wrapper onContextMenu={onContextMenu}>
					<Intl id={titleID} />
				</Wrapper>
			</Dropdown>
		</Tooltip>
	);
};

export default TabTitle;
