import { combineReducers } from 'redux';

import List from './list/reducer';
import Filter from './filter/reducer';
import Event from './event/reducer';
import Market from './market/reducer';
import Selection from './selection/reducer';

import EventBetHistory from './eventBetHistory/reducer';
import MarketBetHistory from './marketBetHistory/reducer';
import SelectionBetHistory from './selectionBetHistory/reducer';
import BetHistoryToolbar from './betHistoryToolbar/reducer';

export default combineReducers({
	List,
	Filter,
	Event,
	Market,
	Selection,

	EventBetHistory,
	MarketBetHistory,
	SelectionBetHistory,
	BetHistoryToolbar,
});
