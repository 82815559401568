import { all, fork } from 'redux-saga/effects';

import overallDataSaga from './overall/saga';
import depositsDataSaga from './deposits/saga';
import betsByProviderSaga from './betsByProvider/saga';
import placedBetsSaga from './placedBets/saga';
import withdrawalsDataSaga from './withdrawals/saga';
import marketingChartSaga from './marketingChart/saga';
import usersChartSaga from './usersChart/saga';


export default function* dashboardSaga() {
	yield all([
		fork(overallDataSaga),
		fork(betsByProviderSaga),
		fork(placedBetsSaga),
		fork(depositsDataSaga),
		fork(withdrawalsDataSaga),
		fork(marketingChartSaga),
		fork(usersChartSaga),
	]);
}
