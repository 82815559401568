const prefix = 'USER_LIMITS_CASINO_CUSTOM_PROVIDER';

const actions = {

	USER_LIMITS_CASINO_CUSTOM_PROVIDER_RELOAD      : `${prefix}_RELOAD`,
	USER_LIMITS_CASINO_CUSTOM_PROVIDER_LIST_REFRESH: `${prefix}_LIST_REFRESH`,

	USER_LIMITS_CASINO_CUSTOM_PROVIDER_BASEDATA_REFRESH: `${prefix}_BASEDATA_REFRESH`,

	USER_LIMITS_CASINO_CUSTOM_PROVIDER_UI_REFRESH: `${prefix}_UI_REFRESH`,

	USER_LIMITS_CASINO_CUSTOM_PROVIDER_SAVE: `${prefix}_SAVE`,

	USER_LIMITS_CASINO_CUSTOM_PROVIDER_RESET: `${prefix}_RESET`,


	customProviderListReload: (data) => ({
		type: actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_RELOAD,
		data,

	}),

	customProviderRefresh: data => ({
		type: actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_LIST_REFRESH,
		data,
	}),

	baseDataRefresh: data => ({
		type: actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_BASEDATA_REFRESH,
		data,
	}),

	customProviderUiRefresh: data => ({
		type: actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_UI_REFRESH,
		data,
	}),

	customProviderSave: providerId => ({
		type: actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_SAVE,
		providerId,

	}),

	customProviderDataReset: () => ({
		type: actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_RESET,
	}),

};

export default actions;
