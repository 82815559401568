import { makeActionCreator } from '../../../helpers';

const prefix = 'BET_HISTORY_TOOLBAR';

const TYPES = {
	DATA_REFRESH: `${prefix}_DATA_REFRESH`,
	DATA_RESET  : `${prefix}_DATA_RESET`,
	UI_REFRESH  : `${prefix}_UI_REFRESH`,

	VOID_BETS         : `${prefix}_VOID_BETS`,
	VOID_SELECTED_BETS: `${prefix}_VOID_SELECTED_BETS`,
};

const actions = {
	...TYPES,

	dataRefresh: makeActionCreator(TYPES.DATA_REFRESH, 'betHistoryType', 'toolbarData'),
	dataReset  : makeActionCreator(TYPES.DATA_RESET),
	uiRefresh  : makeActionCreator(TYPES.UI_REFRESH, 'UI'),

	voidBets        : makeActionCreator(TYPES.VOID_BETS, 'betHistoryType', 'onSuccess'),
	voidSelectedBets: makeActionCreator(TYPES.VOID_SELECTED_BETS, 'betHistoryType', 'onSuccess'),
};

export default actions;
