import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list    : [],
	baseData: {},

	modalUI: {
		visible        : false,
		loading        : false,
		modalLoad    		: false,
		editMode       : false,
		closeModal     : false,
		isChanged      : false,
		defaultDisabled: false,
	},
});

export default function usersGroupsReducer(state = initState, action) {

	switch (action.type) {

		case actions.USERS_GROUPS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.USERS_GROUPS_BASE_DATA_REFRESH: {
			return state.set('baseData', action.data);
		}

		case actions.USERS_GROUPS_MODAL_UI_REFRESH: {
			const target = state.get('modalUI');
			const result = fill(action.data, target);
			return state.set('modalUI', result);
		}

		case actions.USERS_GROUPS_DATA_RESET: {
			const baseData = initState.get('baseData');
			const modalUI  = initState.get('modalUI');

			return state
				.set('baseData', baseData)
				.set('modalUI', modalUI);
		}

		default:
			return state;
	}
}
