import styled from 'styled-components';

const Wrapper = styled.div`
    & { 
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .title {
        padding-right: 16px;
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .language-switcher {
        min-width: 120px;
        margin-right: 30px;
        font-weight: normal;
    }
    .language-switcher-disabled {
        min-width: 120px;
        margin-right: 30px;
        font-weight: normal;
        color: #D8D8D8;
        background: #f7f7f7;
        pointer-events: none;
        
        & .ant-select-selection {
            background: #f7f7f7;
            color: #D8D8D8;

        }
      }
`;

export default Wrapper;
