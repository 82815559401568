import { Map } from 'immutable';
import actions from './actions';
import { CHANNEL_TYPES, TRADING_MODE } from '../../../helpers/commonConstants';

const initState = new Map({
	baseData    : {},
	namesData   : [],
	ordersData  : [],
	settingsData: {},

	modalUI: {
		marketID         : null,
		selectionID      : null,
		selectionName    : '',
		rowIndex         : null,
		colIndex         : null,
		visible          : false,
		loading          : false,
		editMode         : false,
		langID           : 1,
		channelID        : CHANNEL_TYPES.web,
		tradingModeID    : TRADING_MODE.prematch,
		closeModal       : false,
		isNamesChanged   : false,
		isOrdersChanged  : false,
		isSettingsChanged: false,
		isDynamicChanged : false,
	},

	confirmUI: {
		selectionID  : null,
		selectionName: '',
		info         : '',
		visible      : false,
		loading      : false,
	},
});

export default function selectionReducer(state = initState, action) {
	switch (action.type) {
		case actions.SELECTION_BASE_DATA_REFRESH: {
			return state.set('baseData', action.data);
		}

		case actions.SELECTION_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.SELECTION_ORDERS_DATA_REFRESH: {
			return state.set('ordersData', action.data);
		}

		case actions.SELECTION_SETTINGS_DATA_REFRESH: {
			return state.set('settingsData', action.data);
		}

		case actions.SELECTION_SET_MODAL_VALUE_UI: {
			const { valueName, value } = action.data;
			const modalUI = new Map(state.get('modalUI')).toObject();
			modalUI[valueName] = value;

			return state.set('modalUI', modalUI);
		}

		case actions.SELECTION_MODAL_UI_REFRESH: {
			return state.set('modalUI', action.data);
		}

		case actions.SELECTION_SET_CONFIRM_VALUE_UI: {
			const { valueName, value } = action.data;
			const confirmUI = new Map(state.get('confirmUI')).toObject();
			confirmUI[valueName] = value;

			return state.set('confirmUI', confirmUI);
		}

		case actions.SELECTION_CONFIRM_UI_REFRESH: {
			return state.set('confirmUI', action.data);
		}

		case actions.SELECTION_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
