import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'antd';
import Button from '../../components/uielements/button';
import IntlMessages from '../../components/utility/intlMessages';
import SetPasswordWrapper from './setPartnerPassword.style';
import { actions } from '../../redux/newAuth/actions';
import { showError } from '../../helpers/notifications';
import { CheckOutlined } from '@ant-design/icons';


export const divWrapper = {
	height        : '100vh',
	display       : 'flex',
	justifyContent: 'center',
	alignItems    : 'center',
};
export const requiredDivWrapper = {
	marginBottom: '10px',
	minWidth    : '250px',
};

export const errorSubTitle = 'The link was set to expire after a certain amount of time. Please contact the person who shared this link with you.';
export const errorTitle = 'Sorry, the link has expired';

export function setPasswrodValidation(baseData, errors = []) {
	const passwrodPresence = [
		{ key: 'password',        title: 'Password' },
		{ key: 'confirmPassword', title: 'Confirm Password' },
	];

	passwrodPresence.forEach(item => {
		const { key } = item;
		const value = baseData[key];
		!value && errors.push(`page.passvalidation.${key}.empty`);
	});
	return errors;
}

export const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
export const LOWER_REGEX = new RegExp(/.*[a-z]/);
export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const SPECIAL_CHARS_REGEX = new RegExp(/.*[-'/`~!#*$@_%+=.,^&(){}[\]|:;"<>?\\]/);

const CustomCheck = ({ labelName }) => {
	return (
		<div style={requiredDivWrapper}>
			<CheckOutlined />
			<span> {labelName} </span>
		</div>
	);
};
CustomCheck.propTypes = {
	labelName: PropTypes.string.isRequired,
};

export const rules = [
	{ label: <CustomCheck labelName="At least one lower case letter [a-z]" />, pattern: LOWER_REGEX, labelName: 'At least one lower case letter [a-z]' },
	{ label: <CustomCheck labelName="At least one upper case letter [A-Z]" />, pattern: UPPERCASE_REGEX, labelName: 'At least one upper case letter [A-Z]' },
	{ label: <CustomCheck labelName="At least one numeral [0-9]" />, pattern: NUMBER_REGEX, labelName: 'At least one numeral [0-9]' },
	{ label: <CustomCheck labelName="At least one symbol [!@#^&*()+_,.{}?-]" />, pattern: SPECIAL_CHARS_REGEX, labelName: 'At least one symbol [!@#^&*()+_,.{}?-]' },
	{ label: <CustomCheck labelName="Minimum 8 charachter " />, pattern: LENGTH_REGEX, labelName: 'Minimum 8 charachter ' },
];

export const CheckList = ({ value }) => {
	return (
		<div style={{ marginTop: '75px' }}>
			<h3>Password requirements:</h3>
			<div style={requiredDivWrapper}>
				{rules.map(rule => {
					const cn = value && value.match(rule.pattern);
					return <div key={rule.id} style={{ color: cn ? 'green' : 'red', marginBottom: '10px' }}>  {cn ? rule.label : rule.labelName} </div>;
				})}
			</div>
		</div>
	);
};
CheckList.propTypes = {
	value: PropTypes.object.isRequired,

};

const IsValidHash = ({ baseDataRefresh, dataSave, baseData, uiRefresh, isValidPass }) => {
	const { location } = window;
	const hash = location.pathname.split('password/')[1];


	const handlePasswordChange = (e, fieldName) => {
		const pass = e.target.value;
		baseDataRefresh({ [fieldName]: pass });
	};

	useEffect(() => {
		const isValidPass = rules.every(item => baseData.password && baseData.password.match(item.pattern));
		if (isValidPass && baseData.password === baseData.confirmPassword ) {
			uiRefresh({ isValidPass: true });
		} else {
			uiRefresh({ isValidPass: false });
		}
		// eslint-disable-next-line
	}, [baseData.password, baseData.confirmPassword]);

	const savePassword = ( ) => {
		const errors = setPasswrodValidation(baseData);

		if (errors.length > 0) {
			showError(errors);
			return;
		}
		dataSave(hash);
	};
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10%' }}>
			<SetPasswordWrapper className="isoResetPassPage">
				<div className="isoFormContentWrapper">
					<div className="isoFormContent">
						<div className="isoLogoWrapper">
							<Link to="/dashboard">
								<IntlMessages id="page.resetPassTitle" />
							</Link>
						</div>

						<div className="isoFormHeadText">
							<h3> <IntlMessages id="page.setThePassSubTitle" /> </h3>
							<p> <IntlMessages id="page.resetPassDescription" /> </p>
						</div>

						<div className="isoResetPassForm">
							<div className="isoInputWrapper">
								<Input.Password
									size="large"
									type="password"
									placeholder="New Password"
									onChange={e => handlePasswordChange(e, 'password')}
								/>
							</div>

							<div className="isoInputWrapper">
								<Input.Password
									size="large"
									type="password"
									placeholder="Confirm Password"
									onChange={e => handlePasswordChange(e, 'confirmPassword')}
								/>
							</div>

							<div className="isoInputWrapper">
								<Button disabled={!isValidPass} type="primary" onClick={savePassword}>
									<IntlMessages id="page.resetPassSave" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</SetPasswordWrapper>
			<CheckList value={baseData.password} />
		</div>
	);
};
IsValidHash.propTypes = {
	isValidPass: PropTypes.bool.isRequired,
	baseData   : PropTypes.object.isRequired,

	baseDataRefresh: PropTypes.func.isRequired,
	dataSave       : PropTypes.func.isRequired,
	uiRefresh      : PropTypes.func.isRequired,
};

export default connect(({ PartnerAuth }) => {
	const baseData = PartnerAuth.get('baseData');
	return { baseData };
}, {
	baseDataRefresh: actions.baseDataRefresh,
	dataSave       : actions.dataSave,
	uiRefresh      : actions.uiRefresh,
})(IsValidHash);
