import { makeActionCreator } from '../helpers';

const prefix = 'CUSTOMER_IP';

const TYPES = {
	DATA_RELOAD : `${prefix}_DATA_RELOAD`,
	DATA_REFRESH: `${prefix}_DATA_REFRESH`,
	UI_REFRESH  : `${prefix}_UI_REFRESH`,
};

const actions = {
	...TYPES,

	dataReload : makeActionCreator(TYPES.DATA_RELOAD, 'ip'),
	dataRefresh: makeActionCreator(TYPES.DATA_REFRESH, 'ip', 'ipData'),
	uiRefresh  : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
};

export default actions;
