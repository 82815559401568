import { combineReducers } from 'redux';

import Scopes from './scopes/reducer';
import StatisticTypes from './statisticTypes/reducer';
import SMS from './sms/reducer';
import CurrencyModule from './currency/reducer';
import Languages from './languages/reducer';
import Payments from './payments/reducer';
import Security from './security/reducer';
import Country from './countries/reducer';

export default combineReducers({
	SMS,
	CurrencyModule,
	Languages,
	Payments,
	Scopes,
	StatisticTypes,
	Security,
	Country,
});
