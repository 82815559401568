const actions = {

	PARTICIPANTS_LIST_RELOAD            : 'PARTICIPANTS_LIST_RELOAD',
	PARTICIPANTS_LIST_REFRESH           : 'PARTICIPANTS_LIST_REFRESH',
	PARTICIPANTS_LIST_PARTICIPANT_DELETE: 'PARTICIPANTS_LIST_PARTICIPANT_DELETE',
	PARTICIPANTS_LIST_MEMBER_DELETE 	   : 'PARTICIPANTS_LIST_MEMBER_DELETE',
	PARTICIPANTS_LIST_PAGINATION_REFRESH: 'PARTICIPANTS_LIST_PAGINATION_REFRESH',
	PARTICIPANTS_LIST_SET_VALUE_UI      : 'PARTICIPANTS_LIST_SET_VALUE_UI',
	PARTICIPANTS_SORTING_REFRESH        : 'PARTICIPANTS_SORTING_REFRESH',

	listReload: () => ({
		type: actions.PARTICIPANTS_LIST_RELOAD,
	}),
	listRefresh: list => ({
		type: actions.PARTICIPANTS_LIST_REFRESH,
		data: list,
	}),
	participantDelete: participantID => ({
		type: actions.PARTICIPANTS_LIST_PARTICIPANT_DELETE,
		data: {
			participantID,
		},
	}),
	memberDelete: memberID => ({
		type: actions.PARTICIPANTS_LIST_MEMBER_DELETE,
		data: {
			memberID,
		},
	}),
	paginationRefresh: data => ({
		type: actions.PARTICIPANTS_LIST_PAGINATION_REFRESH,
		data,
	}),
	setValueUI: (valueName, value) => ({
		type: actions.PARTICIPANTS_LIST_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	sortingRefresh: data => ({
		type: actions.PARTICIPANTS_SORTING_REFRESH,
		data,
	}),


};

export default actions;
