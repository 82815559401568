import { isID } from '../utils';
import { apiRequest } from './index';

/* ------------------------------------------------------------------------------------------------
	Main Market Data
------------------------------------------------------------------------------------------------ */

// get market
function marketGet(marketID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/market/${marketID}`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// get list of markets
function marketList(params) {
	const req = {
		method: 'GET',
		url   : '/markets',
		params,
	};

	return apiRequest(req);
}

// add new market
function marketAdd(data) {
	const req = {
		method: 'POST',
		url   : '/market',
		data,
	};

	return apiRequest(req);
}

// update market
function marketUpdate(data) {
	const req = {
		method: 'POST',
		url   : `/market/${data.id}`,
		data,
	};

	return apiRequest(req);
}

// copy market
function marketCopy(marketID) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/clone`,
	};

	return apiRequest(req);
}

// delete market
function marketDelete(marketID) {
	const req = {
		method: 'DELETE',
		url   : `/market/${marketID}`,
	};

	return apiRequest(req);
}

// cancel market with check
function marketCancelCheck(marketID) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/cancel`,
	};

	return apiRequest(req);
}

// cancel market
function marketCancel(marketID) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/cancel`,
		params: {
			adminSuccess: true,
		},
	};

	return apiRequest(req);
}

// restore market
function marketRestore(marketID) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/activate`,
	};

	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
	Additional Market Data
------------------------------------------------------------------------------------------------ */

/* --------------------------------------------------------------------------------------- Names */

// GET: market names
function marketNamesList(marketID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/market/${marketID}/names`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update market names
function marketNamesUpdate(marketID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/names`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------------------- Orders */

// GET: market orders
function marketOrdersList(marketID) {
	const req = {
		method: 'GET',
		url   : `/market/${marketID}/orders`,
	};
	return apiRequest(req);
}

// POST: update market orders
function marketOrdersUpdate(marketID, data) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/orders`,
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------ Settings */

// GET: market settings
function marketSettingsList(marketID) {
	const req = {
		method: 'GET',
		url   : `/market/${marketID}/settings`,
	};
	return apiRequest(req);
}

// POST: update market settings
function marketSettingsUpdate(marketID, data) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/settings`,
		data,
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------- Descriptions (CMS) */

// GET: market descriptions
function marketDescriptionsList(marketID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/market/${marketID}/cms`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update market descriptions
function marketDescriptionsUpdate(marketID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/cms`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------------- Restrictions */

// GET: Restrictions List
function marketRestrictionsList(marketID) {
	const req = {
		method: 'GET',
		url   : `/market/${marketID}/restrictions`,
	};
	return apiRequest(req);
}

// POST: Restrictions update
function marketRestrictionsUpdate(marketID, data) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/restrictions`,
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------ Dynamic Limits */

// GET: Dynamic Limits List
function marketDynamicLimitsList(marketID) {
	const req = {
		method: 'GET',
		url   : `/market/${marketID}/dynamicLimits`,
	};
	return apiRequest(req);
}

// POST: Dynamic Limits update
function marketDynamicLimitsUpdate(marketID, data) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/dynamicLimits`,
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
	Selections
------------------------------------------------------------------------------------------------ */
/*
    ~ @Get('/market/:market_id/selections', AdminRequest)
    ~ @Post('/market/:market_id/selections/', AdminRequest)

    ~ @Get(`/selection/:id`, AdminRequest)
    ~ @Post(`/selection/:id`, AdminRequest)
    ~ @Delete(`/selection/:id`, AdminRequest)

    ~ @Get('/selection/:selection_id/names', AdminRequest)
    ~ @Post('/selection/:selection_id/names', AdminRequest)

    ~ @Get('/selection/:selection_id/order', AdminRequest)
    ~ @Post('/selection/:selection_id/order', AdminRequest)

    ~ @Get('/selection/:selection_id/settings', AdminRequest)
    ~ @Post('/selection/:selection_id/settings', AdminRequest)

    ~ @Get('/selection/:selection_id/dynamicLimits', AdminRequest)
    ~ @Post('/selection/:selection_id/dynamicLimits', AdminRequest)
*/
/* ---------------------------------------------------------------------------- Selections: Main */
// GET: selections list
function marketSelectionsList(marketID) {
	const req = {
		method: 'GET',
		url   : `/market/${marketID}/selections`,
	};
	return apiRequest(req);
}

// POST: add new selection
function marketSelectionsAdd(marketID, data) {
	const req = {
		method: 'POST',
		url   : `/market/${marketID}/selections`,
		data,
	};
	return apiRequest(req);
}

// GET: base info of selection
function selectionInfo(selectionID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/selection/${selectionID}`,
		params,
	};
	return apiRequest(req);
}

// POST: update selection
function selectionUpdate(selectionID, data) {
	const req = {
		method: 'POST',
		url   : `/selection/${selectionID}`,
		data,
	};
	return apiRequest(req);
}

// DELETE: delete selection
function selectionDelete(selectionID) {
	const req = {
		method: 'POST',
		url   : `/selection/${selectionID}/cancel`,
		params: {
			adminSuccess: true,
		},
	};
	return apiRequest(req);
}

// POST: check for deleting selection
function selectionCheckDelete(selectionID) {
	const req = {
		method: 'POST',
		url   : `/selection/${selectionID}/cancel`,
	};
	return apiRequest(req);
}

// POST: activate deleted selection
function selectionRestore(selectionID) {
	const req = {
		method: 'POST',
		url   : `/selection/${selectionID}/activate`,
	};
	return apiRequest(req);
}

/* --------------------------------------------------------------------------- Selections: Names */
// GET: selection Names
function selectionNamesList(selectionID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/selection/${selectionID}/names`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update selection Names
function selectionNamesUpdate(selectionID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/selection/${selectionID}/names`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------- Selections: Orders */
// GET: selection Orders
function selectionOrdersList(selectionID) {
	const req = {
		method: 'GET',
		url   : `/selection/${selectionID}/order`,
	};
	return apiRequest(req);
}

// POST: update selection Orders
function selectionOrdersUpdate(selectionID, data) {
	const req = {
		method: 'POST',
		url   : `/selection/${selectionID}/order`,
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------ Selections: Settings */
// GET: selection Settings
function selectionSettingsList(selectionID) {
	const req = {
		method: 'GET',
		url   : `/selection/${selectionID}/settings`,
	};
	return apiRequest(req);
}

// POST: update selection Settings
function selectionSettingsUpdate(selectionID, data) {
	const req = {
		method: 'POST',
		url   : `/selection/${selectionID}/settings`,
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------ Selections: Dynamic Limits */
// GET: selection Dynamic Limits
function selectionDynamicLimitsList(selectionID) {
	const req = {
		method: 'GET',
		url   : `/selection/${selectionID}/dynamicLimits`,
	};
	return apiRequest(req);
}

// POST: update selection Dynamic Limits
function selectionDynamicLimitsUpdate(selectionID, data) {
	const req = {
		method: 'POST',
		url   : `/selection/${selectionID}/dynamicLimits`,
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
	Others
------------------------------------------------------------------------------------------------ */

// update all markets: change its order
function marketUpdateOrder(data) {
	/* Example:
  data = [
    { id: 1, order_id: 1},
    { id: 30, order_id: 2},
    { id: 6, order_id: 3},
  ]
  */

	const req = {
		method: 'POST',
		url   : '/markets',
		data,
	};

	return apiRequest(req);
}

// get combination group list
function combinationGroupList(sportID = null, name = null) {
	const params = {};
	if (isID(sportID)) {
		params.category_id = sportID;
	}
	if (name) {
		params.name = name;
	}

	const req = {
		method: 'GET',
		url   : '/combinationGroups',
		params,
	};

	return apiRequest(req);
}

// add combination group
function combinationGroupAdd(data) {
	const req = {
		method: 'POST',
		url   : '/combinationGroup',
		data,
	};

	return apiRequest(req);
}

// remove combination group
function combinationGroupRemove(groupID) {
	const req = {
		method: 'DELETE',
		url   : `/combinationGroup/${groupID}`,
	};

	return apiRequest(req);
}

// update combination group
function combinationGroupUpdate(data) {
	const req = {
		method: 'POST',
		url   : `/combinationGroup/${data.id}`,
		data,
	};

	return apiRequest(req);
}

// save all combination groups
function combinationGroupSaveAll(data) {
	const req = {
		method: 'POST',
		url   : '/combinationGroups',
		data,
	};

	return apiRequest(req);
}

// update combination group order
function combinationGroupUpdateOrder(groupsList) {
	const req = {
		method: 'PUT',
		url   : '/combinationGroups',
		data  : groupsList,
	};

	return apiRequest(req);
}

// get display type list
function displayTypeList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/marketDisplayType',
		params,
	};

	return apiRequest(req);
}

// add display type
function displayTypeAdd(data) {
	const req = {
		method: 'POST',
		url   : '/marketDisplayType',
		data,
	};

	return apiRequest(req);
}

// update display type
function displayTypeUpdate(data) {
	const req = {
		method: 'POST',
		url   : `/marketDisplayType/${data.id}`,
		data,
	};

	return apiRequest(req);
}

// save all display types
function displayTypeSaveAll(data) {
	const req = {
		method: 'POST',
		url   : '/marketDisplayTypes',
		data,
	};

	return apiRequest(req);
}

// delete display type
function displayTypeRemove(typeID) {
	const req = {
		method: 'DELETE',
		url   : `/marketDisplayType/${typeID}`,
	};

	return apiRequest(req);
}

// update display types order
function displayTypeUpdateOrder(typeList) {
	const req = {
		method: 'PUT',
		url   : '/marketDisplayTypes',
		data  : typeList,
	};

	return apiRequest(req);
}

// get mapping list
function marketMappingList(data) {
	/* Example Data:
  data = {
    id: 142,
    page: 1,
    limit: 50
  }
  */

	const req = {
		method: 'GET',
		url   : '/mapping/market',
		params: {
			system_market_id: data.id,
			page            : data.page,
			limit           : data.limit,
		},
	};

	return apiRequest(req);
}

function marketResultRulesList(marketID) {
	const req = {
		method: 'GET',
		url   : '/rules',
		params: {
			market_id: marketID,
		},
	};

	return apiRequest(req);
}

function marketResultRulesUpdate(data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : '/rules',
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

// get mapping of market according providerID
function marketMappingByProvider({ marketID, providerID, tradingMode }) {
	const req = {
		method: 'GET',
		url   : '/mapping/market/getByProviderId',
		params: {
			systemMarketId: marketID,
			providerId  		: providerID,
			tradingMode,
		},
	};

	return apiRequest(req);
}

// get unmapping markets according providerID
function marketUnMappingByProviderAndSport(providerID, sportID, tradingMode) {
	const req = {
		method: 'GET',
		url   : '/mapping/market/getUnmappedByProviderIdAndSportId',
		params: {
			sportId   : sportID,
			providerId: providerID,
			tradingMode,
		},
	};

	return apiRequest(req);
}

// get mapping of selections according providerID & providerMarketID
function marketSelectionMappingByProvider({ providerID, providerMarketID, sportID, tradingMode }) {
	const req = {
		method: 'GET',
		url   : '/mapping/selection/getByProviderIdAndMarketId',
		params: {
			providerId      : providerID,
			providerMarketId: providerMarketID,
			systemCategoryId: sportID,
			tradingMode,
		},
	};

	return apiRequest(req);
}

// update market & selections mapping
function mapMarketWithSelections(providerID, marketID, marketMappings, selectionMappings) {
	const req = {
		method: 'POST',
		url   : '/mapping/market/mapWithSelections',
		data  : {
			provider_id       : providerID,
			system_market_id  : marketID,
			market_mappings   : marketMappings,
			selection_mappings: selectionMappings,
		},
	};

	return apiRequest(req);
}

// get list of default markets
function defaultMarketList(sportID, typeID = 1) {
	const req = {
		method: 'GET',
		url   : `/defaultMarket/${sportID}/type/${typeID}`,
	};

	return apiRequest(req);
}

// update list of default markets
function defaultMarketsUpdate(sportID, typeID = 1, marketList) {
	/**
	 * marketList = {
	 *    id: ... or null
	 *    market_id: market.id,
	 *    category_id: sportID,
	 *    type_id: marketTypeID,
	 *    argument: '',
	 * }
	 */

	const req = {
		method: 'POST',
		url   : `/defaultMarket/${sportID}/type/${typeID}`,
		data  : marketList,
	};

	return apiRequest(req);
}

// ladders list
function laddersList(filter) {
	const req = {
		method: 'GET',
		url   : '/ladders',
		params: filter,
	};

	return apiRequest(req);
}

// remove ladder
function ladderRemove(ladderID) {
	const req = {
		method: 'DELETE',
		url   : `/ladder/${ladderID}`,
	};

	return apiRequest(req);
}

// update list
function ladderUpdateList(ladderList) {
	const req = {
		method: 'POST',
		url   : '/ladders',
		data  : ladderList,
	};

	return apiRequest(req);
}

export const marketsAPI = {
	// main data
	marketGet,
	marketList,
	marketAdd,
	marketUpdate,
	marketCopy,
	marketDelete,
	marketCancelCheck,
	marketCancel,
	marketRestore,

	// additional data
	marketNamesList,
	marketNamesUpdate,
	marketOrdersList,
	marketOrdersUpdate,
	marketSettingsList,
	marketSettingsUpdate,
	marketDescriptionsList,
	marketDescriptionsUpdate,
	marketDynamicLimitsList,
	marketDynamicLimitsUpdate,

	// selections
	marketSelectionsList,
	marketSelectionsAdd,
	selectionInfo,
	selectionUpdate,
	selectionDelete,
	selectionCheckDelete,
	selectionRestore,

	selectionNamesList,
	selectionNamesUpdate,
	selectionOrdersList,
	selectionOrdersUpdate,
	selectionSettingsList,
	selectionSettingsUpdate,
	selectionDynamicLimitsList,
	selectionDynamicLimitsUpdate,

	// other
	marketUpdateOrder,

	combinationGroupList,
	combinationGroupAdd,
	combinationGroupRemove,
	combinationGroupUpdate,
	combinationGroupSaveAll,
	combinationGroupUpdateOrder,

	displayTypeList,
	displayTypeAdd,
	displayTypeUpdate,
	displayTypeSaveAll,
	displayTypeRemove,
	displayTypeUpdateOrder,

	marketMappingList,
	marketMappingByProvider,
	marketUnMappingByProviderAndSport,

	marketSelectionMappingByProvider,
	mapMarketWithSelections,

	defaultMarketList,
	defaultMarketsUpdate,

	laddersList,
	ladderRemove,
	ladderUpdateList,

	marketRestrictionsList,
	marketRestrictionsUpdate,

	marketResultRulesList,
	marketResultRulesUpdate,
};
