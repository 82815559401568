import invert from 'lodash/invert';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { RISK_MANAGEMENT_PRIORITY_TYPES } from '../../helpers/commonConstants';
import EntityAdapter from '../../helpers/entityAdapter';
import { NOTIFICATION_TYPES } from '../../constants/notifications';
import { EVENT_TYPES } from '../../helpers/sockets/codes';

const { bets, casino, deposits, withdrawal, documents, messages, riskAlertLow, riskAlertMedium, riskAlertHigh, riskManagement } = NOTIFICATION_TYPES;
const fields = {
	// Common
	id                   : 'id',
	notification         : 'notification',
	created              : 'created',
	seen                 : 'seen',
	userID               : 'user_id',
	userName             : 'user_name',
	// Casino
	gameName             : 'game_name',
	gameCurrencyID       : 'game_currency_id',
	gameDate             : 'game_placement_time',
	gameStake            : 'game_stake',
	gameStakeUSD         : 'game_stake_usd',
	// Deposits
	depositDate          : 'deposit_placement_time',
	depositAmount        : 'deposit_amount',
	depositAmountUSD     : 'deposit_amount_usd',
	depositCurrencyID    : 'deposit_currency_id',
	depositTotalAmount   : 'deposit_total_amount',
	depositTotalAmountUSD: 'deposit_total_amount_usd',
	// Withdrawals
	wdDate               : 'withdrawal_placement_time',
	wdAmount             : 'withdrawal_amount',
	wdAmountUSD          : 'withdrawal_amount_usd',
	wdCurrencyID         : 'withdrawal_currency_id',
	// Documents
	docUserName          : 'username',
	docFirstName         : 'first_name',
	docLastName          : 'last_name',
	docEmail             : 'email',
	docTypeID            : 'type_id',
	docDate              : 'created',
	docID                : 'id',
	//
	priority             : 'priority',
	riskType             : 'risk_type',
	riskID               : 'id',
	riskName             : 'risk_name',
	email                : 'email',
	// Messages
	messageDate          : 'created',
	messagesUserID       : 'author_id',
	messageFirstName     : 'first_name',
	messageLastName      : 'last_name',
	messagesAvatar       : 'avatar',
	messagesData         : 'messages',
	personalID           : 'personal_id',
	typeID               : 'type_id',

};

const invertedTypes         = invert(NOTIFICATION_TYPES);
const riskInvertedtypes     = invert(EVENT_TYPES);
const commonAdapter         = createCommonAdapter();
const casinoItemAdapter     = createCasinoItemAdapter();
const depositsItemAdapter   = createDepositsItemAdapter();
const withdrawalItemAdapter = createWithdrawalItemAdapter();
const docItemAdapter        = createDocItemAdapter();
const riskItemAdapter       = createRiskItemAdapter();
const messagesItemAdapter   = createMessagesItemAdapter();
const currencyCodes         = {};

// Adapt ------------------------------------------------------------------------------------------
export function adaptCount(count = {}) {
	if (!isPlainObject(count)) {
		return {};
	}
	let riskAlert = 0;
	return Object.keys(count).reduce((res, key) => {
		const isAnyRisk = (+key === riskAlertLow ) || (+key === riskAlertMedium) || (+key === riskAlertHigh);
		if (isAnyRisk) {
			const newRiskKey = invertedTypes[riskManagement];
			riskAlert += count[key];
			res[newRiskKey] = riskAlert;
		}
		const newKey = invertedTypes[key];
		res[newKey] = count[key];
		return res;
	}, {});
}

export function adaptNotificationsList({ typeID = null, currencyEntities = {}, rawList = [] }) {

	commonAdapter.clearExcludes();
	const commonData = commonAdapter.adaptList(rawList);

	const result = [];
	commonData.forEach(dataItem => {
		let rawItem = String(dataItem.notification);
		try {
			rawItem = JSON.parse(rawItem) || {};
		} catch (e) {
			return;
		}
		let adaptedItem = {};
		switch (typeID) {
			case bets: {
				adaptedItem = adaptBetsItem(rawItem);
				break;
			}
			case casino: {
				adaptedItem = adaptCasinoItem(rawItem);
				break;
			}
			case deposits: {
				adaptedItem = adaptDepositsItem(rawItem);
				break;
			}
			case withdrawal: {
				adaptedItem = adaptWithdrawItem(rawItem);
				break;
			}
			case documents: {
				adaptedItem = adaptDocItem(rawItem);
				break;
			}
			case messages: {
				adaptedItem = adaptMessagesItem(rawItem);
				break;
			}
			case riskAlertLow:
			case riskAlertMedium:
			case riskAlertHigh: {
				adaptedItem = adaptRiskItem(rawItem);
				break;
			}
			default:
		}

		if (!adaptedItem) {
			return;
		}

		adaptedItem.created = dataItem.created;
		adaptedItem.seen    = dataItem.seen;
		if (adaptedItem.currencyID) {
      
			adaptedItem.currencyCode = getCurrencyCode(adaptedItem.currencyID, currencyEntities);
		}
		// Previously adapted item id was overwritten, so I added new prop to this object.
		// adaptedItem.itemIdByType -> this is the item id by notification (for example, withdraw Id, or document Id
		// adaptedItem.id -> this is the notification id
		adaptedItem.itemIdByType = adaptedItem.id;
		adaptedItem.id = dataItem.id;

		result.push(adaptedItem);
	});

	return result;
}

function adaptBetsItem(rawData = {}) {
	// TODO: adapt bets notification
	// we don't know what shape is it
	const { data } = rawData;
	if (!data) {
		return null;
	}

	return null;
}

function adaptCasinoItem(rawData = {}) {
	const { data } = rawData;
	if (!data) {
		return null;
	}

	casinoItemAdapter.clearExcludes();
	const adaptedItem = casinoItemAdapter.adapt(data);

	return adaptedItem;
}

function adaptDepositsItem(rawData = {}) {
	const { data } = rawData;
	if (!data) {
		return null;
	}

	depositsItemAdapter.clearExcludes();
	const adaptedItem = depositsItemAdapter.adapt(data);

	return adaptedItem;
}

function adaptWithdrawItem(rawData = {}) {
	const { data } = rawData;
	if (!data) {
		return null;
	}

	withdrawalItemAdapter.clearExcludes();
	const adaptedItem = withdrawalItemAdapter.adapt(data);

	return adaptedItem;
}

function adaptRiskItem(rawData = {}) {
	const { data  } = rawData;
	if (!data) {
		return null;
	}
	const adaptedItem = riskItemAdapter.adapt(data.data);
	return adaptedItem;
}

function adaptDocItem(rawData = {}) {
	const { user, document } = rawData;

	if (!user || !document) {
		return null;
	}
	const composed = {
		...user,
		...document,
	};

	docItemAdapter.clearExcludes();
	const adaptedItem = docItemAdapter.adapt(composed);

	if (!adaptedItem.userName) {
		adaptedItem.userName = `${adaptedItem.firstName} ${adaptedItem.lastName}`;
	}
	return adaptedItem;
}

function adaptMessagesItem(rawData = {}) {
	const { request } = rawData;
	if (!request) {
		return null;
	}
	const composed = {
		...request,
		...request.author,
	};

	messagesItemAdapter.clearExcludes();
	const adaptedItem = messagesItemAdapter.adapt(composed);

	adaptedItem.userName = `${adaptedItem.firstName} ${adaptedItem.lastName}`;
	const { messages } = adaptedItem;
	if (messages && isArray(messages)) {
		const firstMessage = messages[0];
		adaptedItem.body = firstMessage && firstMessage.body;
	}

	return adaptedItem;
}

// Adapters ---------------------------------------------------------------------------------------
function createCommonAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.fullDate, 'created', fields.created);
	adapter.addField(rules.bool, 'seen', fields.seen);
	adapter.addField(rules.noCondition, 'notification', fields.notification);
	adapter.addField(rules.id, 'personalID', fields.personalID);
	adapter.addField(rules.id, 'typeID', fields.typeID);

	return adapter;
}

function createCasinoItemAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'currencyID', fields.gameCurrencyID);

	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'gameName', fields.gameName);

	adapter.addField(rules.positiveNumber, 'stake', fields.gameStake);
	adapter.addField(rules.dateTime, 'date', fields.gameDate);

	return adapter;
}

function createRiskItemAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;
	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'riskID', fields.id);

	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'priority', fields.priority);
	adapter.addField(rules.id, 'riskType', fields.riskType);

	adapter.addField(rules.string, 'created', fields.created);
	adapter.addField(rules.string, 'riskName', fields.riskName);
	adapter.addField(rules.string, 'email', fields.email);

	return adapter;
}

function createDepositsItemAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'currencyID', fields.depositCurrencyID);

	adapter.addField(rules.string, 'userName', fields.userName);

	adapter.addField(rules.positiveNumber, 'amount', fields.depositAmount);
	adapter.addField(rules.dateTime, 'date', fields.depositDate);

	return adapter;
}

function createWithdrawalItemAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'currencyID', fields.wdCurrencyID);

	adapter.addField(rules.string, 'userName', fields.userName);

	adapter.addField(rules.positiveNumber, 'amount', fields.wdAmount);
	adapter.addField(rules.dateTime, 'date', fields.wdDate);

	return adapter;
}

function createDocItemAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'docID', fields.docID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'typeID', fields.docTypeID);

	adapter.addField(rules.string, 'userName', fields.docUserName);
	adapter.addField(rules.string, 'firstName', fields.docFirstName);
	adapter.addField(rules.string, 'lastName', fields.docLastName);
	adapter.addField(rules.string, 'userEmail', fields.docEmail);

	adapter.addField(rules.dateTime, 'date', fields.docDate);

	return adapter;
}

function createMessagesItemAdapter() {

	/*
    ItemDeposits.propTypes = {
      id      : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      date    : PropTypes.string,
      userName: PropTypes.string,
      avatar  : PropTypes.string,
      body    : PropTypes.string,
    };
  */
	//{"actionType":2,"data":{"id":157263,"game_id":1,"game_name":"Egypt Gods","game_currency_id":4,"game_website_currency_id":null,"game_provider_id":1,"game_provider_name":"EvoPlay","game_bet_number":214,"game_ip":"91.196.37.181","game_ip_country":"AM","game_placement_time":"2019-03-21T07:27:53.353Z","bet_type_id":1,"user_country_id":"AM","game_stake":4.5,"game_stake_usd":5.15,"game_won_amount":0,"game_won_amount_usd":0,"game_status_id":3,"ggr":4.5,"ggr_website_currency":0,"user_id":11786,"user_name":"Lusine Sargsyan","user_group_id":0,"user_status_id":1,"user_registration_ip":"91.196.37.181","website_id":1,"channel_id":4,"user_balance_after":13.69,"user_balance_after_usd":15.65,"user_balance_before":18.19,"user_balance_before_usd":20.79}}"
	/*
    messageDate           : 'created',
    messageFirstName      : 'first_name',
    messageLastName       : 'last_name',
    messagesAvatar        : 'avatar',
    messagesData          : 'messages',
  */

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'userID', fields.messagesUserID);
	adapter.addField(rules.id, 'typeID', fields.docTypeID);

	adapter.addField(rules.string, 'firstName', fields.messageFirstName);
	adapter.addField(rules.string, 'lastName', fields.messageLastName);
	adapter.addField(rules.string, 'avatar', fields.messagesAvatar);

	adapter.addField(rules.dateTime, 'date', fields.messageDate);
	adapter.addField(rules.noCondition, 'messages', fields.messagesData);

	return adapter;
}

// Service ----------------------------------------------------------------------------------------
export function getNotificationTypeName(typeID) {
	return invertedTypes[typeID] || String(typeID);
}

export function getRiskTypeName(typeID) {
	return riskInvertedtypes[typeID] || String(typeID);
}

export function getCurrencyCode(currencyID, currencyEntities) {
	if (currencyCodes[currencyID]) {
		return currencyCodes[currencyID];
	}

	const currency = currencyEntities[currencyID] || {};
	const { code } = currency;

	currencyCodes[currencyID] = code;

	return code;
}
export const detectSectionNames = (tab) => {
	const { low, medium, high } = RISK_MANAGEMENT_PRIORITY_TYPES;
	if (tab === low) {
		return {
			firstReset : 'riskAlertMedium',
			secondReset: 'riskAlertHigh',
		};
	}
	if (tab === medium) {
		return {
			firstReset : 'riskAlertLow',
			secondReset: 'riskAlertHigh',
		};
	}
	if (tab === high) {
		return {
			firstReset : 'riskAlertLow',
			secondReset: 'riskAlertMedium',
		};
	}
};
