import isEmpty from 'lodash/isEmpty';
import EntityAdapter from '../../../../helpers/entityAdapter';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { TRADING_MODE } from '../../../../helpers/commonConstants';

const fields = {
	id                    : 'id',
	marketID              : 'market_id',
	marketName            : 'market_name',
	tradingMode           : 'trading_mode',
	betWonLimit           : 'bet_won_limit',
	betWonLimitLocal      : 'bet_won_limit_local',
	betWonLimitValueType  : 'bet_won_limit_value_type',
	overrideWonLimit      : 'override_won_limit',
	betStakeLimit         : 'bet_stake_limit',
	betStakeLimitLocal    : 'bet_stake_limit_local',
	betStakeLimitValueType: 'bet_stake_limit_value_type',
	overrideStakeLimit    : 'override_stake_limit',
	categoryID            : 'category_id',
};

const indexes = [];

// Adapting ---------------------------------------------------------------------------------------

export function getListParams(sportID, categoryID) {

	const builder = new ParamsBuilder();

	builder.addValue('sport_id', sportID);
	builder.addValue('category_id', categoryID);

	const params = builder.biuldParams({});

	return params;
}

export function adaptSettingsList(rawData = []) {
	const listAdapter = createListAdapter();

	const adaptedList = listAdapter.adaptList(rawData);

	const modifiedList = adaptedList.map((setting, index) => {
		if (indexes.includes(index)) return {};
		if (setting.tradingMode === TRADING_MODE.prematch) {
			const nextSetting = findNextMode(TRADING_MODE.prematch, setting.marketID, adaptedList);
			setting.prParentWonLimit = setting.betWonLimit;
			setting.prWonLimit = setting.betWonLimitLocal;
			setting.prWonLimitType = setting.betWonLimitValueType;
			setting.prOverrideWonLimit = setting.overrideWonLimit;

			setting.prParentStakeLimit = setting.betStakeLimit;
			setting.prStakeLimit = setting.betStakeLimitLocal;
			setting.prStakeLimitType = setting.betStakeLimitValueType;
			setting.prOverrideStakeLimit = setting.overrideStakeLimit;

			setting.liveParentWonLimit = nextSetting.betWonLimit;
			setting.liveWonLimit = nextSetting.betWonLimitLocal;
			setting.liveWonLimitType = nextSetting.betWonLimitValueType;
			setting.liveOverrideWonLimit = nextSetting.overrideWonLimit;

			setting.liveParentStakeLimit = nextSetting.betStakeLimit;
			setting.liveStakeLimit = nextSetting.betStakeLimitLocal;
			setting.liveStakeLimitType = nextSetting.betStakeLimitValueType;
			setting.liveOverrideStakeLimit = nextSetting.overrideStakeLimit;
			if (!isEmpty(nextSetting)) {
				setting.nextID = nextSetting.id;
				indexes.push(adaptedList.indexOf(nextSetting));
			}
		}
		if (setting.tradingMode === TRADING_MODE.live) {
			const nextSetting = findNextMode(TRADING_MODE.live, setting.marketID, adaptedList);
			setting.liveParentWonLimit = setting.betWonLimit;
			setting.liveWonLimit = setting.betWonLimitLocal;
			setting.liveWonLimitType = setting.betWonLimitValueType;
			setting.liveOverrideWonLimit = setting.overrideWonLimit;

			setting.liveParentStakeLimit = setting.betStakeLimit;
			setting.liveStakeLimit = setting.betStakeLimitLocal;
			setting.liveStakeLimitType = setting.betStakeLimitValueType;
			setting.liveOverrideStakeLimit = setting.overrideStakeLimit;

			setting.prParentWonLimit = nextSetting.betWonLimit;
			setting.prWonLimit = nextSetting.betWonLimitLocal;
			setting.prWonLimitType = nextSetting.betWonLimitValueType;
			setting.prOverrideWonLimit = nextSetting.overrideWonLimit;

			setting.prParentStakeLimit = nextSetting.betStakeLimit;
			setting.prStakeLimit = nextSetting.betStakeLimitLocal;
			setting.prStakeLimitType = nextSetting.betStakeLimitValueType;
			setting.prOverrideStakeLimit = nextSetting.overrideStakeLimit;
			if (!isEmpty(nextSetting)) {
				setting.nextID = nextSetting.id;
				indexes.push(adaptedList.indexOf(nextSetting));
			}
		}
		return setting;
	});
	indexes.forEach((index, i) => {
		modifiedList.splice(index - i, 1);
	});

	return modifiedList;
}

export function adaptSavingData(rawData = []) {
	const result = [];
	rawData.forEach(data => {
		const builder = new ParamsBuilder();

		builder.addValue(fields.id, data.id);
		builder.addValue(fields.marketID, data.marketID);
		builder.addValue(fields.tradingMode, data.tradingMode);
		builder.addValue(fields.betWonLimitLocal, data.wonLimit);
		builder.addValue(fields.betWonLimitValueType, data.wonLimitType);
		builder.addValue(fields.overrideWonLimit, data.overrideWonLimit);
		builder.addValue(fields.betStakeLimitLocal, data.stakeLimit);
		builder.addValue(fields.betStakeLimitValueType, data.stakeLimitType);
		builder.addValue(fields.overrideStakeLimit, data.overrideStakeLimit);

		const params = builder.biuldParams({});
		result.push(params);
	});

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'marketID', fields.marketID);
	adapter.addField(rules.id, 'categoryID', fields.categoryID);

	adapter.addField(rules.number, 'tradingMode', fields.tradingMode);
	adapter.addField(rules.number, 'betWonLimit', fields.betWonLimit);
	adapter.addField(rules.number, 'betWonLimitLocal', fields.betWonLimitLocal);
	adapter.addField(rules.number, 'betWonLimitValueType', fields.betWonLimitValueType);
	adapter.addField(rules.number, 'betStakeLimit', fields.betStakeLimit);
	adapter.addField(rules.number, 'betStakeLimitLocal', fields.betStakeLimitLocal);
	adapter.addField(rules.number, 'betStakeLimitValueType', fields.betStakeLimitValueType);
	adapter.addField(rules.bool, 'overrideWonLimit', fields.overrideWonLimit);
	adapter.addField(rules.bool, 'overrideStakeLimit', fields.overrideStakeLimit);
	adapter.addField(rules.string, 'marketName', fields.marketName);

	return adapter;
}

function findNextMode(mode, name, adaptedList) {
	const nextSetting = adaptedList.find(setting => setting.marketID === name && setting.tradingMode !== mode);
	return nextSetting || {};
}
