import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import bannerSaga from './banner/saga';
import groupsSaga from './groups/saga';
import bannerGroupSaga from './bannerGroup/saga';

export default function* rootSaga() {
	yield all([
		fork(listSaga),
		fork(bannerSaga),
		fork(groupsSaga),
		fork(bannerGroupSaga),
	]);
}
