import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import actions from './actions';
import paymentsActions from '../payments/actions';
import { default as appTabsActions } from '../../../appTabs/actions';
import responsibleGambling from '../responsibleGambling/actions';
import listActions from '../list/actions';
import smsGatewaysActions from '../smsgateways/actions';

import { websitesAPI } from '../../../../helpers/api/websites';
import { isID, isZeroOrID } from '../../../../helpers/utils';
import notifications, { showError, showSuccess } from '../../../../helpers/notifications';

import {
	adaptAdditionalDomains,
	adaptCasino,
	adaptCountries,
	adaptCurrencies,
	adaptLanguages,
	adaptNewDomainsForSend,
	adaptWebsite,
	createCurrenciesList,
	prepareCasino,
	prepareCountryData,
	prepareCurrency,
	prepareLanguages,
	prepareSeoData,
	prepareWebsite,
} from './utils';
import { objectsKeys } from '../../../../containers/Partner/Websites/modals/Websites/TabIntegration/utils/utils';

import { deriveLanguagesList } from '../../../../selectors/websites';
import { adaptSeoData } from '../../../casino/game/utils';
import { logger } from '../../../../helpers/logger';
import { createEntities } from '../../../utility';
import { isArray } from 'lodash';
import currencyActions from '../../../settings/currency/actions';
import { convertToEntities } from '../../../settings/currency/utils';


const prefix = 'partner.websites.website';

const messages = {
	errorWebsiteReload            : `${prefix}.errorWebsiteReload`,
	errorWebsiteSave              : `${prefix}.errorWebsiteSave`,
	errorAdditionalDomains        : `${prefix}.errorAdditionalDomains`,
	errorAdditionalDomainSave     : `${prefix}.errorAdditionalDomainSave`,
	errorAdditionalDomainDelete   : `${prefix}.errorAdditionalDomainDelete`,
	errorPaymentsReload           : `${prefix}.errorPaymentsReload`,
	errorPaymentsSave             : `${prefix}.errorPaymentsSave`,
	errorPaymentsAdd              : `${prefix}.errorPaymentsAdd`,
	errorPaymentsUpdate           : `${prefix}.errorPaymentsUpdate`,
	errorPaymentsDelete           : `${prefix}.errorPaymentsDelete`,
	errorPaymentsReorder          : `${prefix}.errorPaymentsReorder`,
	errorPaymentLimitsReload      : `${prefix}.errorPaymentLimitsReload`,
	errorPaymentDescriptionsReload: `${prefix}.errorPaymentDescriptionsReload`,
	errorPaymentLimitsSave        : `${prefix}.errorPaymentLimitsSave`,
	errorCasinoReload             : `${prefix}.errorCasinoReload`,
	errorCasinoSave               : `${prefix}.errorCasinoSave`,
	errorCasinoDelete             : `${prefix}.errorCasinoDelete`,
	errorCurrencyReload           : `${prefix}.errorCurrencyReload`,
	errorCurrencySave             : `${prefix}.errorCurrencySave`,
	errorCurrencyDelete           : `${prefix}.errorCurrencyDelete`,
	errorSuspend                  : `${prefix}.errorSuspend`,
	errorSendTestEmail            : `${prefix}.errorSendTestEmail`,
	errorSaveLanguage             : `${prefix}.errorSaveLanguage`,
	errorFetchLanguages           : `${prefix}.errorFetchLanguages`,
	errorDeleteLanguage           : `${prefix}.errorDeleteLanguage`,
	errorSaveCountry              : `${prefix}.errorSaveCountry`,
	errorDeleteCountry            : `${prefix}.errorDeleteCountry`,
	errorSeoSave                  : `${prefix}.error.seo.save`,

	successWebsiteSave            : `${prefix}.successWebsiteSave`,
	successAdditionalDomainSave   : `${prefix}.successAdditionalDomainSave`,
	successAdditionalDomainDelete : `${prefix}.successAdditionalDomainDelete`,
	successPaymentsSave           : `${prefix}.successPaymentsSave`,
	successPaymentsDelete         : `${prefix}.successPaymentsDelete`,
	successPaymentsReorder        : `${prefix}.successPaymentsReorder`,
	successPaymentLimitsSave      : `${prefix}.successPaymentLimitsSave`,
	successPaymentDescriptionsSave: `${prefix}.successPaymentDescriptionsSave`,
	successCasinoSave             : `${prefix}.successCasinoSave`,
	successCasinoDelete           : `${prefix}.successCasinoDelete`,
	successCurrencySave           : `${prefix}.successCurrencySave`,
	successCurrencyDelete         : `${prefix}.successCurrencyDelete`,
	successSuspend                : `${prefix}.successSuspend`,
	successSendTestEmail          : `${prefix}.successSendTestEmail`,
	successSaveLanguage           : `${prefix}.successSaveLanguage`,
	successDeleteLanguage         : `${prefix}.successDeleteLanguage`,
	successDeleteCountry          : `${prefix}.successDeleteCountry`,
	successSavedCountry           : `${prefix}.successSavedCountry`,
};


function getStoreData(state) {
	const { Partner: { Websites }, Settings } = state;
	return {
		baseData           : Websites.Website.get('baseData'),
		payments           : Websites.Payments.get('payments'),
		paymentLimits      : Websites.Payments.get('paymentLimits'),
		paymentDescriptions: Websites.Payments.get('paymentDescriptions'),
		casino             : Websites.Website.get('casino'),
		UI                 : Websites.Website.get('UI'),
		changedFields      : Websites.Website.get('changedFields'),
		mailingList        : Websites.Website.get('mailingList'),
		currencyList       : Settings.CurrencyModule.get('list'),
		languageList       : deriveLanguagesList(state),
		seoData            : Websites.Website.get('seoData'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.WEBSITE_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { websiteID } = action.data;

		// data from list
		let baseData = cloneDeep(storeData.baseData) || {};

		if (isID(baseData.id)) {

			yield put(paymentsActions.paymentsReload(websiteID)); // TODO CHECK IS NEED
			yield put(actions.casinoReload(websiteID));
			return;
		}

		// loading data
		try {
			const res = yield call(websitesAPI.websiteInfo, websiteID);

			if (res && res.status === 200) {
				baseData = adaptWebsite(res.data.data);
				yield put(actions.baseDataRefresh(baseData));
			}
		} catch (error) {
			showError(messages.errorWebsiteReload, error);
			logger.log(error);
		}
		yield put(actions.baseDataRefresh(baseData));
		yield put(paymentsActions.paymentsReload(websiteID)); // TODO CHECK IS NEED
		yield put(actions.casinoReload(websiteID));
		yield put(actions.uiRefresh({ isBaseChanged: false }));
	});
}

function* casinoReload() {

	yield takeEvery(actions.WEBSITE_CASINO_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true, casinoTabLoad: true }));
		const { websiteID } = action.data;
		const params = {
			unlimited: true,
		};

		let casino = {};
		try {
			const res = yield call(websitesAPI.casinoList, websiteID, params);
			if (res && res.status === 200) {
				casino = adaptCasino(res.data.data);
			}
		} catch (error) {
			showError(messages.errorCasinoReload, error);
			logger.log(error);
		}

		yield put(actions.casinoRefresh(casino));
		yield put(actions.uiRefresh({ loading: false, casinoTabLoad: false }));
	});
}

function* additionalDomainsReload() {

	yield takeEvery(actions.WEBSITE_ADDITIONAL_DOMAINS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID } = action.data;

		const params = { website_id: websiteID };

		let entities = {};

		try {
			const res = yield call(websitesAPI.websiteAdditionalDomainslist, params);
			if (res && res.status === 200) {
				entities = adaptAdditionalDomains(res.data.data);
				yield put(actions.additionalDomainsRefresh(entities));
			}

		} catch (error) {
			notifications.showError(messages.errorAdditionalDomains);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* newAdditionalDomainsCreate() {

	yield takeEvery(actions.WEBSITE_NEW_ADDITIONAL_DOMAINS_CREATE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData } = yield select(getStoreData);
		const { websiteID } = action.data;

		const data = adaptNewDomainsForSend(baseData.additionalDomains, websiteID);
		const params = { website_id: websiteID };

		let entities = {};

		try {
			const res = yield call(websitesAPI.websiteAdditionalDomainsUpdate, params, data);
			if (res && res.status === 200) {
				entities = adaptAdditionalDomains(res.data.data, baseData.additionalDomains, true);
			}

		} catch (error) {
			showError(messages.errorAdditionalDomains);
			logger.log(error);
		}

		yield put(actions.additionalDomainsRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* eachAdditionalDomainSaveDelete() {
	yield takeEvery(actions.WEBSITE_ADDITIONAL_DOMAIN_SAVE_AND_DELETE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID, domainInfo } = action.data;
		const data = adaptNewDomainsForSend(domainInfo, websiteID, true);
		const params = { website_id: websiteID };
		const domainID = domainInfo.id;

		try {

			const res = yield call(
				action.data.save
					? websitesAPI.websiteAdditionalDomainSave
					: websitesAPI.websiteAdditionalDomainDelete,
				domainID, params, data
			);

			if (res && res.status === 200) {
				//adaptAdditionalDomains(res.data.data, baseData.additionalDomains, true);
				showSuccess(messages[`successAdditionalDomain${action.data.save ? 'Save' : 'Delete'}`]);
			}

		} catch (error) {
			notifications.showError(messages[`errorAdditionalDomain${action.data.save ? 'Save' : 'Delete'}`]);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* dataSave() {
	yield takeEvery(actions.WEBSITE_SAVE, function* ({ activeTab }) {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, UI, changedFields, responsibleBaseChanged } = yield select(getStoreData);
		const { editMode, closeModal, currentTab, goToWebsite } = UI;
		const { websiteAffiliates, websiteAffiliate, websiteCdn } = objectsKeys;
		let { websiteID } = UI;
		let errorMessage  = '';
		let isError       = false;
		try {
			// base data
			errorMessage = messages.errorWebsiteSave;
			const data = adaptNewDomainsForSend(baseData.additionalDomains, websiteID);
			const preparedData = prepareWebsite(baseData, changedFields, currentTab, activeTab, editMode);

			const params = { website_id: websiteID };

			let entities = {};
			if (editMode) {
				const [res, additRes] = yield all([
					call(websitesAPI.websiteUpdate, websiteID, preparedData),
					call(websitesAPI.websiteAdditionalDomainsUpdate, params, data),
				]);
				if (res && res.status === 200 && additRes && additRes.status === 200) {
					yield put(actions.mailingListReload(websiteID));
					entities = adaptAdditionalDomains(additRes.data.data, baseData.additionalDomains, true, websiteID);

					const currentData = res.data.data;
					const editedBaseData = adaptWebsite(currentData);
					yield put(actions.baseDataRefresh(editedBaseData));
					if (currentData[websiteCdn]) {
						yield put(actions.setCdnId(currentData[websiteCdn].id));
					}
					currentData[websiteAffiliates] = currentData[websiteAffiliate];
					delete currentData[websiteAffiliate];
					currentData[websiteCdn] = baseData[websiteCdn];
					yield put(actions.seoDataSave(websiteID));
					yield put(actions.mailingListSet(currentData));
					yield put(actions.additionalDomainsRefresh(entities));
					responsibleBaseChanged && (yield put(responsibleGambling.dataSave(websiteID)));
				}
			} else {
				preparedData.website_domains = data;
				const res = yield call(websitesAPI.websiteAdd, preparedData);
				if (res && res.status === 200) {
					websiteID = toInteger(res.data.data.id);
					yield put(actions.mailingListSet(res.data.data));
					yield put(actions.uiRefresh({ editMode: true, websiteID }));
				}
			}

			yield put(actions.uiRefresh({ isBaseChanged: false }));
			showSuccess(messages.successWebsiteSave);

		} catch (error) {
			isError = true;
			showError(errorMessage, error);
		}
		if (goToWebsite && !isError) {
			yield put(appTabsActions.openTabWebsiteInner(websiteID));
			yield put(actions.dataReset());
		}
		if (!isError && closeModal) {
			yield put(listActions.listReload());
			yield put(actions.dataReset());

		} else if (!isError && !closeModal) {
			yield put(actions.uiRefresh({ loading: false }));
			yield put(actions.baseDataReload(websiteID));
			yield put(listActions.listReload());

		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* casinoSave() {

	yield takeEvery(actions.WEBSITE_CASINO_SAVE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { casino, UI } = yield select(getStoreData);
		const { websiteID } = UI;
		const { recordID, casinoID } = action.data;

		const casinoItem = casino[recordID];
		const editMode = isZeroOrID(recordID);
		try {
			const preparedData = prepareCasino(casinoItem);
			if (editMode) {
				yield call(
					websitesAPI.casinoUpdate,
					websiteID,
					casinoID,
					preparedData
				);


			} else {
				const res = yield call(websitesAPI.casinoAdd, websiteID, preparedData);
				if (res && res.status === 200) {
					yield put(actions.casinoReload(websiteID));

					showSuccess(messages.successCasinoSave);
				}
			}


		} catch (error) {
			showError(messages.errorCasinoSave, error);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* casinoDelete() {

	yield takeEvery(actions.WEBSITE_CASINO_DELETE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { UI } = yield select(getStoreData);
		const { websiteID } = UI;
		const { casinoID } = action.data;

		try {
			const res = yield call(websitesAPI.casinoDelete, websiteID, casinoID);
			if (res && res.status === 200) {
				showSuccess(messages.successCasinoDelete);
			}

			yield put(actions.casinoReload(websiteID));
		} catch (error) {
			showError(messages.errorCasinoDelete, error);
			logger.log(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* currenciesReload() {

	yield takeEvery(actions.WEBSITE_CURRENCIES_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { websiteID } = action.data;
		const params = {
			unlimit: true,
		};
		let currencies = [];
		let settingsCurrencies = [];

		try {
			const [resConnected, partnerScopeAvailable] = yield all(
				[call(websitesAPI.currencyList, websiteID),
					call(websitesAPI.avalibleCurrenciesList, websiteID, params)]
			);
			const partnerScopeCurrencySuccess = (partnerScopeAvailable && partnerScopeAvailable.status === 200);

			if (partnerScopeCurrencySuccess) {
				const { data } = partnerScopeAvailable.data;
				const partnerScopeCurrencies = createCurrenciesList(data);
				yield put(actions.partnerScopeCurrenciesRefresh(partnerScopeCurrencies));
				if (resConnected && resConnected.status === 200) {
					settingsCurrencies = convertToEntities(resConnected.data.data);
					currencies = adaptCurrencies(resConnected.data.data, data);
				}
			}
		} catch (error) {
			showError(messages.errorCurrencyReload, error);
			logger.log(error);
		}

		yield put(currencyActions.currencyRefreshEntities(settingsCurrencies));
		yield put(actions.currenciesResresh(currencies));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* currencyAdd() {

	yield takeEvery(actions.WEBSITE_CURRENCY_ADD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID } = action.data;

		try {
			const preparedData = prepareCurrency(action.data);
			const res = yield call(websitesAPI.currencyAdd, websiteID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successCurrencySave);
				yield put(actions.currenciesReload(websiteID));
			}
		} catch (error) {
			showError(messages.errorCurrencySave, error);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* currencySave() {

	yield takeEvery(actions.WEBSITE_CURRENCY_SAVE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID } = action.data;

		try {
			const preparedData = prepareCurrency(action.data);
			const res = yield call(websitesAPI.currencyUpdate, websiteID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successCurrencySave);
				yield put(actions.currenciesReload(websiteID));
			}
		} catch (error) {
			showError(messages.errorCurrencySave, error);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* currencyDelete() {

	yield takeEvery(actions.WEBSITE_CURRENCY_DELETE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID, currencyID } = action.data;

		try {
			const res = yield call(websitesAPI.currencyDelete, websiteID, currencyID);
			if (res && res.status === 200) {
				showSuccess(messages.successCurrencyDelete);
				yield put(actions.currenciesReload(websiteID));
			}
		} catch (error) {
			showError(messages.errorCurrencyDelete, error);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* sendTestEmail() {
	yield takeEvery(actions.WEBSITE_SEND_TEST_EMAIL, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(websitesAPI.sendTestEmail, action.data);
			if (res && res.status === 200) {
				showSuccess(messages.successSendTestEmail);
			}
		} catch (error) {
			showError(messages.errorSendTestEmail, error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* websiteMailingList() {
	yield takeEvery(actions.WEBSITE_MAILING_LIST_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true, intagrationTabLoad: true }));
		const mailingData = yield select(getStoreData);
		const { websiteID } = action.data;

		const mailingList = cloneDeep(mailingData.mailingList) || {};
		if (isID(mailingList.id)) {
			yield put(actions.mailingListSet(websiteID));
		}

		try {
			const res = yield call(websitesAPI.websitesMailingList, websiteID);
			if (res && res.status === 200) {
				const integrationData = {
					...res.data.data,
					website_cdn: isArray(res.data.data.website_cdn) ? createEntities(res.data.data.website_cdn, 'service_id') : {},
				};
				yield put(actions.mailingListSet(integrationData));
				
				if (res.data.data.website_cdn) {
					yield put(actions.setCdnId(res.data.data.website_cdn.id)); // todo wrong line
				}
			}
		} catch (error) {
			showError(messages.errorWebsiteReload, error);
			logger.log(error);

		}
		yield put(actions.uiRefresh({ loading: false, intagrationTabLoad: false }));
	});
}


function* getAllCountries() {

	yield takeEvery(actions.WEBSITE_ALL_COUNTRY_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { data } = action;
		const { websiteID } = data;
		try {
			const res = yield  all([
				call(websitesAPI.websiteAvailableCountries, websiteID),
				call(websitesAPI.countryList, websiteID),
			]);

			const [allCountries, attachedCountries] = res;
			const success = res.every(item => item && item.status === 200);
			if (success) {
				const { entities } = adaptCountries(allCountries.data.data, attachedCountries.data.data);
				yield put(actions.allCountriesListRefresh(entities));
			}
		} catch (error) {
			logger.log(error);
		}
		// yield put(actions.allCountriesListRefresh({1: { id: 1, country: 'US'}}));
		yield put(actions.uiRefresh({ isBaseChanged: false, loading: false }));
	});
}

function* countryListReload() {

	yield takeEvery(actions.WEBSITE_COUNTRY_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield call(websitesAPI.countryList);
			if (res && res.status === 200) {

				yield put(actions.countryListRefresh({}));
			}
		} catch (error) {
			logger.log(error);
		}
		yield put(actions.countryListRefresh({ 1: { id: 1, country: 'US' } }));
		yield put(actions.uiRefresh({ isBaseChanged: false, loading: false }));
	});
}

function* saveCountry() {

	yield takeEvery(actions.WEBSITE_COUNTRY_SAVE, function* (action) {
		const { data } = action;
		const { websiteID } = data;
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const preparedData = prepareCountryData(data);
			const res = yield call(websitesAPI.saveCountry, preparedData);
			if (res && res.status === 200) {

				yield put(actions.getAllCountries({ websiteID }));
				notifications.showSuccess(messages.successSavedCountry);
			}
		} catch (error) {
			logger.log(error);
			notifications.showError(messages.errorSaveCountry);
		}
		yield put(actions.uiRefresh({ isBaseChanged: false, loading: false }));
	});
}


function* getAllLanguages() {

	yield takeEvery(actions.WEBSITE_ALL_LANGUAGES_LIST_RELOAD, function* (action) {
		const { data } = action;
		const { websiteID } = data;

		yield put(actions.uiRefresh({ loading: true }));

		try {
			yield callAllLanguages(websiteID);
		} catch (error) {
			logger.log(error);
			notifications.showError(messages.errorFetchLanguages);
		}
		yield put(actions.uiRefresh({ isBaseChanged: false, loading: false }));
	});
}


function* getSeo() {

	yield takeEvery(actions.WEBSITE_GET_SEO, function* ({ websiteID }) {
		yield put(actions.uiRefresh({ loading: true }));
		const { languageList } = yield select(getStoreData);

		try {
			if (!languageList.length) {
				yield callAllLanguages(websiteID);
			}

			const res = yield call(websitesAPI.getSeoList, websiteID);
			if (res && res.status === 200) {
				const adapted = adaptSeoData(res.data.data);
				yield put(actions.seoDataRefresh(adapted));
			}
		} catch (error) {
			logger.log(error);
		}
		yield put(actions.uiRefresh({ isSeoChanged: false, loading: false }));
	});
}

function* seoDataSave() {

	yield takeEvery(actions.WEBSITE_SEO_DATA_SAVE, function* ({ websiteID }) {
		yield put(actions.uiRefresh({ loading: true }));
		const { seoData } = yield select(getStoreData);

		try {
			const preparedData = prepareSeoData(seoData, websiteID);
			const res = yield call(websitesAPI.seoSave, preparedData, websiteID);
			if (res && res.status === 200) {
				yield put(actions.getSeo(websiteID));
			}

		} catch (e) {
			showError(messages.errorSeoSave);
			yield put(actions.uiRefresh({ loading: false }));
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* languageListReload() {

	yield takeEvery(actions.WEBSITE_LANGUAGE_LIST_RELOAD, function* () {


		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield call(websitesAPI.languageList);
			if (res && res.status === 200) {

				yield put(actions.languageListRefresh({}));
			}
		} catch (error) {
			logger.log(error);
		}
		yield put(actions.languageListRefresh({ 1: { languageID: 1, language: 'English' } }));
		yield put(actions.uiRefresh({ isBaseChanged: false, loading: false }));
	});
}


function* saveLanguage() {

	yield takeEvery(actions.WEBSITE_LANGUAGE_SAVE, function* (action) {
		const { data } = action;
		const { websiteID } = data;
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const prepareLanguageData = prepareLanguages(data);
			const res = yield call(websitesAPI.saveLanguage, prepareLanguageData);
			if (res && res.status === 200) {

				yield put(actions.getAllLanguages({ websiteID }));
			}
			notifications.showSuccess(messages.successSaveLanguage);

		} catch (error) {
			logger.log(error);
			notifications.showError(messages.errorSaveLanguage);
		}
		yield put(actions.uiRefresh({ isBaseChanged: false, loading: false }));
	});
}

function* deleteLanguage() {

	yield takeEvery(actions.WEBSITE_LANGUAGE_DELETE, function* (action) {
		const { data } = action;
		const { websiteID, languageID } = data;
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const prepareLanguageData = prepareLanguages(data);
			const res = yield call(websitesAPI.deleteLanguage, prepareLanguageData, websiteID, languageID);
			if (res && res.status === 200) {
				yield put(actions.getAllLanguages({ websiteID }));
			}
			notifications.showSuccess(messages.successDeleteLanguage);

		} catch (error) {
			logger.log(error);
			notifications.showError(messages.errorDeleteLanguage);
		}
		// yield put(actions.languageListRefresh({1: {languageID: 1, language: 'English'}}));
		yield put(actions.uiRefresh({ isBaseChanged: false, loading: false }));
	});
}


function* deleteCountry() {

	yield takeEvery(actions.WEBSITE_COUNTRY_DELETE, function* (action) {
		const { data } = action;
		const { websiteID, countryID } = data;
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const preparedCountryData = prepareCountryData(data);
			const res = yield call(websitesAPI.deleteCountry, preparedCountryData, websiteID, countryID);
			if (res && res.status === 200) {
				yield put(actions.getAllCountries({ websiteID }));
			}
			notifications.showSuccess(messages.successDeleteCountry);

		} catch (error) {
			logger.log(error);
			notifications.showError(messages.errorDeleteCountry);
		}
		// yield put(actions.languageListRefresh({1: {languageID: 1, language: 'English'}}));
		yield put(actions.uiRefresh({ isBaseChanged: false, loading: false }));
	});
}

function* callAllLanguages(websiteID) {
	const { UI } = yield select(getStoreData);

	const res = yield all([
		call(websitesAPI.allLanguagesPartner, websiteID),
		call(websitesAPI.languageList, websiteID),
	]);
	const success = res.every(item => item && item.status === 200);
	const [languages, attachedLanguages] = res;
	if (success) {
		const { entities, attachedLangCount } = adaptLanguages(languages.data.data, attachedLanguages.data.data);
		yield put(actions.allLanguageListRefresh(entities));
		if (!UI.attachedLangCount) {
			yield put(actions.uiRefresh({ attachedLangCount }));
		}

	}
}

function* resetWebsiteData() {
	yield takeEvery(actions.RESET_WEBSITE_DATA_GET, function* () {
		yield all([
			put(actions.dataReset()),
			put(responsibleGambling.dataReset()),
			put(smsGatewaysActions.resetData()),
		]);
	});
}

export default function* websiteModalSaga() {
	yield all([
		fork(baseDataReload),
		fork(dataSave),
		fork(casinoReload),
		fork(additionalDomainsReload),
		fork(casinoSave),
		fork(casinoDelete),
		fork(currenciesReload),
		fork(currencyAdd),
		fork(currencySave),
		fork(currencyDelete),
		fork(newAdditionalDomainsCreate),
		fork(eachAdditionalDomainSaveDelete),
		fork(websiteMailingList),
		fork(countryListReload),
		fork(saveCountry),
		fork(languageListReload),
		fork(getAllCountries),
		fork(getAllLanguages),
		fork(saveLanguage),
		fork(deleteLanguage),
		fork(deleteCountry),
		fork(sendTestEmail),
		fork(getSeo),
		fork(seoDataSave),
		fork(resetWebsiteData),
	]);
}
