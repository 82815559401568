const actions = {

	BANNER_GROUP_DATA_RESET       : 'BANNER_GROUP_DATA_RESET',
	BANNER_GROUP_BASE_DATA_RELOAD : 'BANNER_GROUP_BASE_DATA_RELOAD',
	BANNER_GROUP_BASE_DATA_REFRESH: 'BANNER_GROUP_BASE_DATA_REFRESH',

	BANNER_GROUP_SAVE      : 'BANNER_GROUP_SAVE',
	BANNER_GROUP_UI_REFRESH: 'BANNER_GROUP_UI_REFRESH',

	dataReset: () => ({
		type: actions.BANNER_GROUP_DATA_RESET,
	}),
	baseDataReload: groupID => ({
		type: actions.BANNER_GROUP_BASE_DATA_RELOAD,
		data: {
			groupID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.BANNER_GROUP_BASE_DATA_REFRESH,
		data,
	}),

	dataSave: () => ({
		type: actions.BANNER_GROUP_SAVE,
	}),
	uiRefresh: data => ({
		type: actions.BANNER_GROUP_UI_REFRESH,
		data,
	}),
};

export default actions;
