import { createSelector } from 'reselect';

const appTabs        = (state) => state.AppTabs.get('tabs');
const activeTabID    = (state) => state.AppTabs.get('activeTabID');
const activeTabIsSearch    = (state) => state.AppTabs.get('isSearch');
const activeTabTitle = (state) => state.AppTabs.get('activeTabTitle');
const activeUserID    = (state) => state.AppTabs.get('activeUserID');

export const deriveAppTabs = createSelector(
	[appTabs],
	(appTabs) => appTabs
);

export const deriveActiveTabID = createSelector(
	[activeTabID],
	(activeTabID) => activeTabID
);

export const deriveActiveTabIsSearch = createSelector(
	[activeTabIsSearch],
	(isSearch) => isSearch
);

export const deriveActiveTabTitle = createSelector(
	[activeTabTitle],
	(activeTabTitle) => activeTabTitle
);

export const deriveActiveUserID = createSelector(
	[activeUserID],
	(activeUserID) => activeUserID
);
