import antdEn from 'antd/lib/locale/tr_TR';

// import appLocaleData from 'react-intl/locale-data/tr';
import trMessages from '../locales/en_US.json'; // TODO: create normal turkish translations
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const TrLang = {
	messages: {
		...trMessages,
	},
	antd  : antdEn,
	locale: 'tr-TR',
	// data: appLocaleData,
};
export default TrLang;
