import { apiRequest } from './index';

const checkPasswordHash = (data, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partners/password/${data}`,
		params,
	};

	return apiRequest(req);
};

const setPasswordSave = (data, hash, params = {}) => {
	const req = {
		method: 'POST',
		url   : `/partners/setPassword/${hash}`,
		data,
		params,
	};
	return apiRequest(req);
};

export const newAuthAPI = {
	checkPasswordHash,
	setPasswordSave,
};
