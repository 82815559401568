import EntityAdapter from '../../helpers/entityAdapter';

const fields = {
	id             : 'id',
	userName       : 'username',
	firstName      : 'first_name',
	lastName       : 'last_name',
	email          : 'email',
	avatar         : 'avatar',
	currentPassword: 'oldPassword',
	password       : 'newPassword',
	confirmPassword: 'confirm_password',
	currencyID     : 'currency_id',
};

const baseDataAdapter     = createBaseDataAdapter();
const passwordDataAdapter = createPasswordDataAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptBaseData(rawData = {}) {

	baseDataAdapter.clearExcludes();
	const adaptedData = baseDataAdapter.adapt(rawData);

	adaptedData.password        = '';
	adaptedData.confirmPassword = '';

	return adaptedData;
}

export function prepareBaseData(rawData = {}) {

	const preparedData = baseDataAdapter.prepare(rawData);

	return preparedData;
}

export function preparePasswordData(rawData = {}) {

	const preparedData = passwordDataAdapter.prepare(rawData);

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

function createBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'firstName', fields.firstName);
	adapter.addField(rules.string, 'lastName', fields.lastName);
	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.string, 'avatar', fields.avatar);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);

	return adapter;
}

function createPasswordDataAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'currentPassword', fields.currentPassword);
	adapter.addField(rules.string, 'password', fields.password);
	adapter.addField(rules.string, 'confirmPassword', fields.confirmPassword);

	return adapter;
}

export const URL_TYPE = {
	users : 'users',
	admins: 'admins',
};
