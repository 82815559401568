import { apiRequest } from './index';

function integratorList() {
	const req = {
		method       : 'GET',
		url          : '/integrators',
		withoutLangID: false,
	};

	return apiRequest(req);
}


function integratorsWithProviders(params = {}) {
	const req = {
		method          : 'GET',
		url             : '/integrators',
		withoutWebsiteID: false,
		params,
	};

	return apiRequest(req);

}

function integratorsWithProvidersNew(params = {}) {
	const req = {
		method          : 'GET',
		url             : '/partnerIntegrators',
		withoutWebsiteID: false,
		params,
	};

	return apiRequest(req);

}


function providersWithGames(params = {}) {
	const req = {
		method: 'GET',
		url   : '/casinoGame',
		params,
	};

	return apiRequest(req);

}
function providersGames(params = {}) {
	const req = {
		method: 'GET',
		url   : `/partner/provider/${params.provider_id}/games`,
		params,
	};

	return apiRequest(req);

}


export const integratorSelectAPI = {
	integratorList,
	integratorsWithProviders,
	integratorsWithProvidersNew,
	providersWithGames,
	providersGames,
};
