import { all, takeEvery, put, fork, call, select, takeLatest } from 'redux-saga/effects';
import { getHeadersTotalCount } from '../../../helpers/utils';
import { deriveTablePagination } from '../../../selectors/tables';
import { gameToPage } from '../gamesBulk/utils';
import actions from './actions';
import tableActions from '../../tables/actions';
import bulkActions from '../gamesBulk/actions';

import { casinoAPI } from '../../../helpers/api/casino';
import notifications from '../../../helpers/notifications';

import { adaptGamesList, getListParams } from './utils';

import { TABLE_TYPES } from '../../../constants/tableTypes';
import { CASINO_GAMES_STATUS } from '../../../helpers/commonConstants';
import { logger } from '../../../helpers/logger';
import { makeRequestCasinoList } from '../list/saga';

const tableType = TABLE_TYPES.game;

const messages = {
	errorListLoad             : 'casinoGames.error.list.load',
	errorListRefresh          : 'casinoGames.error.list.refresh',
	errorWebsiteCasinoListLoad: 'casinoGames.error.websiteCasinoList.load',
	errorGamesListUpdate      : 'casinoGames.error.gamesList.update',
	successListRefresh        : 'casinoGames.success.list.refresh',
	successGamesListUpdate    : 'casinoGames.success.gamesList.update',
};

function getStoreData(state) {
	const { Casino, App, Users, Tables } = state;
	const UI = Casino.Games.get('UI');
	const searchValue = Casino.Games.get('searchValue');

	return {
		gamesList  : Casino.Games.get('gamesList'),
		entities   : Casino.Games.get('entities'),
		defaultGrid: Casino.Games.get('defaultGrid'),

		isGamesChanged      : UI.isGamesChanged,
		isDefaultGridChanged: UI.isDefaultGridChanged,
		isGamesGridChanged  : UI.isGamesGridChanged,
		websiteID           : App.get('websiteID'),
		casinoID            : UI.casinoID,
		channelID           : UI.channelID,
		providerID          : UI.providerID,
		customProviderID    : UI.customProviderID,
		currencyID          : Users.User.get('baseData').currencyID,
		bulkIDs             : Casino.CasinoGameBulk.get('baseData').bulkGameIDs,
		gameListPagination  : Casino.Games.get('pagination'),

		filter          : Casino.Games.get('filter'),
		sorting         : Tables.get(tableType).sorting,
		pagination      : deriveTablePagination(tableType, state),
		searchPagination: Casino.Games.get('searchPagination'),
		gamesSearchList : Casino.Games.get('gamesSearchList'),
		searchValue,
	};
}

function* gamesListReload() {

	yield takeLatest(actions.CASINO_GAMES_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { currencyID } = yield select(getStoreData);

		const {
			websiteID,
			casinoID,
			channelID,
			byCategory,
			name,
			providerType,
			page,
			limit,
			isUnlimit,
			byCurrency,
			sorting = {},
		} = action.data;
		let params;
		if (providerType === 'provider') {
			params = {
				unlimit    : isUnlimit,
				channel_id : channelID,
				provider_id: casinoID,
				categories : byCategory,
				name,
				...sorting,
			};
		} else {
			params = {
				unlimit           : isUnlimit,
				channel_id        : channelID,
				custom_provider_id: casinoID,
				name,
				currency_id       : byCurrency && currencyID,
				...sorting,
			};
		}

		params.page = page;
		params.limit = limit;

		let gamesList = [];
		let totalCount;
		try {
			// games
			const res = yield call(casinoAPI.gamesList, websiteID, params);
			if (res && res.status === 200) {
				const { adaptedData } = adaptGamesList(res.data.data);
				gamesList = adaptedData;
				totalCount = getHeadersTotalCount(res.headers);

				yield put(actions.gamesListRefresh(gamesList));
				yield put(actions.gamesListModalRefresh(gamesList));

			}
			yield put(actions.uiRefresh({ hasMore: gamesList.length > 0, isGamesChanged: false }));
			yield put(tableActions.paginationRefresh(tableType, { currentPage: page, totalCount, itemsPerPage: limit }));

		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}
		// yield put(currenciesActions.websiteCurrencyListReload);
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* casinoGamesListReload() {
	yield takeEvery(actions.CASINO_GAMES_LIST_RELOAD_GAMES, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const {
			filter,
			sorting,
			pagination,
			currencyID,
			bulkIDs,
		} = yield select(getStoreData);
		const data = typeof action.data !== 'object' ? {} : action.data;
		const {
			providerType = 'provider',
			byCategory,
			page,
			limit,
			isUnlimit,
			byCurrency,
			isGamesModule,
		} = yield data;

		const params = getListParams(filter, sorting, pagination);

		let queryParams = { ...params };
		if (providerType === 'provider') {
			queryParams = {
				unlimit    : isUnlimit,
				categories : byCategory,
				currency_id: byCurrency && currencyID,
				...queryParams,
			};
		} else {
			queryParams = {
				unlimit    : isUnlimit,
				currency_id: byCurrency && currencyID,
				...queryParams,
			};
		}
		if (params.bonus_spins || params.free_spins) {
			queryParams.status_id = CASINO_GAMES_STATUS.active;
			delete queryParams.channel_id;
		}
		params.page = page;
		params.limit = limit;
		let gamesList = [];
		try {
			const casinoList = yield call(makeRequestCasinoList);
			if (isGamesModule && !filter.providerID) {
				queryParams.provider_id = casinoList[0]?.id;
			}
			const res = yield call(casinoAPI.casinoGamesList, queryParams);
			if (res && res.status === 200) {
				const { adaptedData, entities } = adaptGamesList(res.data.data);
				gamesList = adaptedData;
				const totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.gamesListRefreshPagination({ totalCount }));
				yield put(actions.gamesEntitiesRefresh(entities));
				const pageData = gameToPage(bulkIDs, entities);
				yield put(bulkActions.currentPageIDsRefresh(pageData));
				yield put(tableActions.paginationRefresh(tableType, { totalCount }));
				yield put(actions.uiRefresh({ hasMore: gamesList.length > 0, isGamesChanged: false }));
			}

		} catch (e) {
			notifications.showError(messages.errorListLoad, e);
			logger.log(e);
		}

		yield put(actions.gamesListRefresh(gamesList));
		yield put(actions.gamesListModalRefresh(gamesList));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* gamesListRefreshFromAPI() {

	yield takeEvery(actions.CASINO_GAMES_LIST_REFRESH_FROM_API, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { casinoID } = action.data;
		const { websiteID, channelID } = yield select(getStoreData);
		try {
			const res = yield call(casinoAPI.gamesListRefresh, { casinoID, websiteID });
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successListRefresh);
				yield put(actions.casinoGameListReload({
					websiteID,
					casinoID,
					channelID,
				}));
			}
		} catch (error) {
			notifications.showError(messages.errorListRefresh);
			logger.log(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}


function* gamesListLimitedReload() {

	yield takeEvery(actions.CASINO_GAMES_NEW_GAMES_BY_PAGE_RELOAD, function* (action) {
		const {
			filter,
			sorting,
			customProviderID,
			// providerID,
			currencyID/* , channelID */,
			gameListPagination,
			gamesList,
		} = yield select(getStoreData);

		const data = typeof action.data !== 'object' ? {} : action.data;
		const {
			name,
			page,
			// isSearch,
		} = data;
		const params = getListParams(filter, sorting, gameListPagination);

		let queryParams = { ...params };

		queryParams = {
			unlimit           : false,
			// channel_id        : channelID,
			// provider_id       : providerID,
			custom_provider_id: customProviderID,
			currency_id       : currencyID,
			status_id         : CASINO_GAMES_STATUS.active,
			...queryParams,
		};

		if (name) {
			queryParams.name = name;
		}

		try {
			const res = yield call(casinoAPI.casinoGamesList, queryParams);
			if (res && res.status === 200) {
				const { adaptedData } = adaptGamesList(res.data.data);
				const totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.gamesListRefreshPagination({ currentPage: page, totalCount }));

				if (page > 1) {
					const result = gamesList.concat(adaptedData);
					yield put(actions.gamesListRefreshLimited(result));
				} else {
					yield put(actions.gamesListRefreshLimited(adaptedData));
				}

				yield put(actions.uiRefresh({ isGamesChanged: false }));
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* gamesSearchListReload() {
	yield takeEvery(actions.CASINO_GAMES_SEARCH_LIST_RELOAD, function* (action) {
		yield put(actions.gamesSearchListRefresh(null));
		yield put(actions.uiRefresh({ loading: true }));

		const { currencyID, searchValue, searchPagination, gamesSearchList } = yield select(getStoreData);

		const { itemsPerPage, currentPage } = searchPagination;
		const data = typeof action.data !== 'object' ? {} : action.data;

		const {
			byCurrency,
			websiteID,
			limit = itemsPerPage,
		} = data;

		const params = {
			limit,
			page       : currentPage,
			name       : searchValue,
			currency_id: byCurrency && currencyID,
		};
		let stateListLength = 0;
		try {
			const res = yield call(casinoAPI.gamesList, websiteID, params);

			if (res && res.status === 200) {
				const totalCount = getHeadersTotalCount(res.headers);
				const { adaptedData } = adaptGamesList(res.data.data);
				if (!gamesSearchList) {
					yield put(actions.gamesSearchListRefresh(adaptedData));
					stateListLength = adaptedData.length;
				} else {
					yield put(actions.gamesSearchListRefresh(gamesSearchList.concat(adaptedData)));
					stateListLength = gamesSearchList.length + adaptedData.length;
				}
				
				yield put(actions.uiRefresh({ hasMore: (totalCount > stateListLength) }));
				yield put(actions.gamesListRefreshSearchPagination({ currentPage: currentPage + 1 }));
			}

		} catch (e) {
			notifications.showError(messages.errorListLoad, e);
			logger.log(e);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* getProvidersWithGames() {
	yield takeEvery(actions.CASINO_GAMES_PROVIDERS_WITH_GAMES_RELOAD, function* (action) {
		const { data } = action;
		const { providerID } = data;

		yield put(actions.casinoGamesProvidersWithGamesRefresh({ id: providerID, gameDataByProvider: [], isLoading: true }));

		try {
			const res = yield call(casinoAPI.gamesListByProvider, providerID);

			if (res && res.status && res.data.status) {
				const { adaptedData } = adaptGamesList(res.data.data);

				yield put(
					actions.casinoGamesProvidersWithGamesRefresh({
						id                : providerID,
						gameDataByProvider: adaptedData,
						isLoading         : false,
					})
				);
			}
		} catch (e) {
			logger.log(e, 'error');
			notifications.showError(messages.errorLoadProviders);
		}
	});
}

export default function* casinoGamesListSaga() {
	yield all([
		fork(gamesListReload),
		fork(casinoGamesListReload),
		fork(gamesListRefreshFromAPI),
		fork(gamesListLimitedReload),
		fork(gamesSearchListReload),
		fork(getProvidersWithGames),
	]);
}
