
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const PersonalLink = ({ userID, htmlId, children }) => {
	return  <NavLink
		to={htmlId ? `/dashboard/personal/info/${userID}#${htmlId}` : `/dashboard/personal/info/${userID}`}
	>
		{children}
	</NavLink>;
};

PersonalLink.propTypes = {
	userID  : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	type    : PropTypes.oneOf(['normal', 'link']),
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
	htmlId: PropTypes.string,
};

PersonalLink.defaultProps = {
	userID: null,
	type  : 'link',
	htmlId: null,
};

export default PersonalLink;
