import invert from 'lodash/invert';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import {
	ROLE_TYPE,
	ROLE_TYPES_ENUM,
} from '../../../containers/StaffManagement/modals/Role/utils';
import EntityAdapter from '../../../helpers/entityAdapter';
import {
	PERMISSIONS,
	PERMISSIONS_LANG,
} from '../../../helpers/permissions/constants';


export const fields = {
	id         : 'id',
	name       : 'name',
	permissions: 'permissions',
	canChange  : 'can_change',

	roleType: 'partner',
	roleName: 'role_name',
};

const withReconcilation = true;
const rolesListAdapter  = createRolesListAdapter();

// Adapt
// ------------------------------------------------------------------------------------------
export function adaptRolesList(rawData = []) {

	rolesListAdapter.clearExcludes();
	return rolesListAdapter.adaptList(rawData)
		.filter(item => {
			const isPartner = item.permissions.some(item => item === ROLE_TYPES_ENUM.partner);
			item.roleType   = isPartner ? ROLE_TYPE.partner : ROLE_TYPE.platform;
			return (item.canChange);
		});
}

export function adaptPermissionsList(rawData = []) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	if (withReconcilation) {
		reconcilatePermissions(rawData);
	}

	const processedAdminKeys = [];
	const invertedPermissions = invert(PERMISSIONS);
	const result = rawData.map(value => {
		const adminKey = invertedPermissions[value] || null;
		if (!adminKey) {
			// console.error(`Permission "${value}" does not exist in constant PERMISSIONS. Value is excluded from Permissions List`);
			return null;
		}
		processedAdminKeys.push(adminKey);

		return {
			value,
			key   : adminKey,
			intlID: PERMISSIONS_LANG[adminKey],
		};

	}).filter(item => item !== null);

	const unprocessedAdminKeys = Object.keys(PERMISSIONS).filter(key => !processedAdminKeys.includes(key));
	unprocessedAdminKeys.forEach(adminKey => {
		result.push({
			value : PERMISSIONS[adminKey],
			key   : adminKey,
			intlID: PERMISSIONS_LANG[adminKey],
		});
	});

	return result;
}

// Adapters ---------------------------------------------------------------------------------------
function createRolesListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.arrayString, 'permissions', fields.permissions);
	adapter.addField(rules.bool, 'canChange', fields.canChange);

	return adapter;
}

// Service ----------------------------------------------------------------------------------------
function reconcilatePermissions(rawPermissions = []) {
	if (!isArray(rawPermissions) || isEmpty(rawPermissions)) {
		// console.error('Permissions list is empty. Reconsilation is cancelled');
		return;
	}

	const missedInConstant = [];
	const missedInBackend  = [];

	const adminValues = values(PERMISSIONS);

	rawPermissions.forEach(value => {
		if (!adminValues.includes(value)) {
			missedInConstant.push(value);
		}
	});

	adminValues.forEach(value => {
		if (!rawPermissions.includes(value)) {
			missedInBackend.push(value);
		}
	});
}


export function getListParams(filter) {
	const data = {
		name: filter.roleName,
	};

	switch (String(filter.roleType)) {
		case ROLE_TYPE.partner: {
			data.partner = true;
			break;
		}
		case ROLE_TYPE.platform: {
			data.partner = false;
			break;
		}
		default: {
			break;
		}
	}


	return data;
}
