import { Map } from 'immutable';

import { fill } from '../../../../helpers/utils';
import { SENDING_TYPES } from '../../../../constants/pushNotifications';

import actions from './actions';

const initState = new Map({

	baseData: {},

	UI: {
		loading    : false,
		visible    : false,
		userID     : '',
		sendingType: SENDING_TYPES.group,
	},
});

export default function pushNotificationReducer(state = initState, action) {

	switch (action.type) {

		case actions.BASE_DATA_REFRESH: {
			const { baseData } = action.data;
			return state.set('baseData', baseData);
		}
		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}
		case actions.DATA_RESET: {
			return initState;
		}
		default:
			return state;
	}
}
