import toInteger from 'lodash/toInteger';
import { isID } from '../../../../../helpers/utils';
import { fields, createListAdapter } from '../list/utils';

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptNewsArticle(rawData = {}) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareNewsArticle(baseData, itemName, langID) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('mainTabID');
	listAdapter.addExcludeField('name');
	listAdapter.addExcludeField('url');
	listAdapter.addExcludeField('date');
	listAdapter.addExcludeField('imageURL');
	listAdapter.addExcludeField('youTubeURL');
	listAdapter.addExcludeField('langID');
	listAdapter.addExcludeField('contentShort');
	listAdapter.addExcludeField('contentFull');

	const mainData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		mainData.id = toInteger(baseData.id);
	}

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('active');
	listAdapter.addExcludeField('mainNews');
	listAdapter.addExcludeField('general');
	listAdapter.addExcludeField('position');

	const detailData = listAdapter.prepare(baseData);
	detailData[fields.langID] = langID;

	return {
		mainData,
		detailData,
	};
}
