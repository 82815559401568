import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	placedBetsData: [],
});

export default function DashboardPlacedBetsReducer(state = initState, action) {
	switch (action.type) {
		case actions.DASHBOARD_PLACED_BETS_DATA_REFRESH: {
			return state.set('placedBetsData', action.data.placedBetsData);
		}
		default:
			return state;
	}
}
