import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import Card from '../../../../../components/uielements/card';
import FormInput from '../../../../../components/FormControls/FormInput';
import FormSelect from '../../../../../components/FormControls/FormSelect';
import PersonalLink from '../../../../AppTabsManager/components/PersonalLink';

import profileActions from '../../../../../redux/profile/actions';
import { lang } from '../../../lang';

import { LinkWrapper } from './CardMain.style';
import { Button } from 'antd';
import { ADDITIONAL_TABS } from '../../../../../constants/appTabs';
import appTabsActions from '../../../../../redux/appTabs/actions';
import { addNewTab } from '../../../../../helpers/addNewTab';

class CardMain extends Component {

	static propTypes = {
		baseData       : PropTypes.object.isRequired,
		userID         : PropTypes.number.isRequired,
		loading        : PropTypes.bool.isRequired,
		baseDataRefresh: PropTypes.func.isRequired,
		baseDataReload : PropTypes.func.isRequired,
		dataReset      : PropTypes.func.isRequired,
		currencyList   : PropTypes.array.isRequired,
		openTab        : PropTypes.func.isRequired,
		uiRefresh      : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.onChangeField = this.onChangeField.bind(this);
		this.onChangeCurrencyField = this.onChangeCurrencyField.bind(this);
		this.onAddTab = this.onAddTab.bind(this);
	}

	componentDidMount() {
		const { userID, baseDataReload } = this.props;
		baseDataReload(userID);
	}

	onChangeField(value, dataName) {
		const { baseDataRefresh } = this.props;
		if (!dataName) {
			return;
		}

		baseDataRefresh({
			[dataName]: value,
		});
	}

	onChangeCurrencyField(value, dataName) {
		const { baseDataRefresh, baseData } = this.props;
		const updatedBaseData = cloneDeep(baseData);
		updatedBaseData[dataName] = Number(value);
		baseDataRefresh(updatedBaseData);
	}

	onAddTab(userID){
		const { openTab, uiRefresh } = this.props;
		addNewTab(userID, ADDITIONAL_TABS.personalInfo, openTab);
		uiRefresh({ visible: false });
	}

	render() {
		const { baseData, loading, dataReset, currencyList } = this.props;
		const { id, userName, email } = baseData;
		return (
			<Card title={lang.userData}>
				<FormInput
					key={`${id}_userName`}
					label={lang.userName}
					value={userName}
					disabled={loading}
					onChange={({ target }) => this.onChangeField(target.value, 'userName')}
				/>
				<FormInput
					readOnly
					key={`${id}_email`}
					label={lang.email}
					value={email}
					disabled={loading}
					onChange={({ target }) => this.onChangeField(target.value, 'email')}
				/>
				<FormSelect
					label={lang.currency}
					optionsList={currencyList}
					value={baseData.currencyID || 'Select'}
					disabled={loading}
					onChange={value => this.onChangeCurrencyField(value, 'currencyID')}
				/>
				<LinkWrapper>
					<Button disabled={loading} onClick={() => this.onAddTab(id)} type="link">
						<PersonalLink userID={id} onClick={dataReset}>{lang.showUserPage}</PersonalLink>
					</Button>
				</LinkWrapper>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { Profile } = state;
	const UI = Profile.get('UI');
	const { loading, userID } = UI;

	return {
		baseData    : Profile.get('baseData'),
		currencyList: Profile.get('currencyList'),
		loading,
		userID,
	};
}

export default connect(mapStateToProps, {
	baseDataRefresh: profileActions.baseDataRefresh,
	baseDataReload : profileActions.baseDataReload,
	dataReset      : profileActions.dataReset,
	openTab        : appTabsActions.openTab,
	uiRefresh      : profileActions.uiRefresh,
})(CardMain);
