import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import notificationsActions from '../../../../redux/notifications/actions';
import { deriveHasMoreByType } from '../../../../selectors/notifications';
import { ListItem, ListItemBody, ListItemHeader, LayoutContentStyle } from '../NotificationBadge.style';
import { lang, itemComponents } from '../assets';

class MessagesList extends Component {

	constructor(props) {
		super(props);

		this.onLoadMore  = this.onLoadMore.bind(this);
		this.renderItems = this.renderItems.bind(this);

		this.state = {
			localLoading: true,
		};
	}
	shouldComponentUpdate(nextProps) {

		if (this.props.loading !== nextProps.loading || this.props.typeID !== nextProps.typeID) {

			this.setState({ localLoading: false });
			return true;
		}
	}

	// Events ---------------------------------------------------------------------------------------
	onLoadMore() {
		const { typeID, pageChange, loading, uiRefresh, listLength, totalCount, messagesPage } = this.props;
		if (!loading && (listLength < totalCount)) {
			pageChange(typeID, messagesPage);
			uiRefresh({ messagesPage: messagesPage + 1 });
		}
		
	}

	// Renders --------------------------------------------------------------------------------------
	renderItems() {
		const { typeID, notificationsList, onClickItem } = this.props;
		return notificationsList.map(item => {
			const Component = itemComponents[typeID];
			const className = !item.seen ? 'not-read' : '';
			return (
				<Component
					key={item.id}
					className={className}
					onClick={onClickItem}
					documentType={item.typeID}
					docID={item.docID}
					itemIdByType={item.itemIdByType}
					{...item}
				/>
			);
		});
	}

	render() {
		const { localLoading } = this.state;
		const { containerID, hasMore, loading, listLength } = this.props;
		const load = localLoading || loading;

		if (!listLength) {
			return (
				<ListItem key={0}>
					<ListItemHeader>
						{
							load
								? (
									<LayoutContentStyle>
										<Spin size="small" />
									</LayoutContentStyle>
								)
								: lang.noData
						}
					</ListItemHeader>
					<ListItemBody />
				</ListItem>
			);
		}
		const items = this.renderItems();

		return (
			<InfiniteScroll
				useWindow={false}
				initialLoad={false}
				hasMore={hasMore}
				loadMore={this.onLoadMore}
				threshold={50}
				getScrollParent={() => document.getElementById(containerID)}
			>
				{items}
			</InfiniteScroll>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { typeID } = props;
	const { loading, totalCount, messagesPage } = state.Notifications.get('UI');
	return {
		hasMore: deriveHasMoreByType(typeID)(state),
		loading,
		messagesPage,
		totalCount,
	};
};

const mapDispatchToprops = {
	pageChange: notificationsActions.pageChange,
	uiRefresh : notificationsActions.uiRefresh,
};

export default connect(
	mapStateToProps,
	mapDispatchToprops
)(MessagesList);
