import { getView } from './utils';

const actions = {
	COLLPSE_CHANGE     : 'COLLPSE_CHANGE',
	COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
	CHANGE_OPEN_KEYS   : 'CHANGE_OPEN_KEYS',
	TOGGLE_ALL         : 'TOGGLE_ALL',
	CHANGE_CURRENT     : 'CHANGE_CURRENT',
	// APP_MAIN_LANGUAGES_LIST_REFRESH            : 'APP_MAIN_LANGUAGES_LIST_REFRESH',

	APP_PARTNER_RESTORE                        : 'APP_PARTNER_RESTORE',
	APP_PARTNER_CHANGE                         : 'APP_PARTNER_CHANGE',
	APP_PARTNER_REFRESH                        : 'APP_PARTNER_REFRESH',
	APP_PARTNER_LIST_REFRESH                   : 'APP_PARTNER_LIST_REFRESH',
	APP_PARTNER_ATTACHED_LANGUAGES_LIST_REFRESH: 'APP_PARTNER_ATTACHED_LANGUAGES_LIST_REFRESH',

	APP_WEBSITE_CHANGE      : 'APP_WEBSITE_CHANGE',
	APP_WEBSITE_RESTORE     : 'APP_WEBSITE_RESTORE',
	APP_WEBSITE_REFRESH     : 'APP_WEBSITE_REFRESH',
	APP_WEBSITE_LIST_REFRESH: 'APP_WEBSITE_LIST_REFRESH',

	APP_WEBSITE_SETTINGS_RELOAD                : 'APP_WEBSITE_SETTINGS_RELOAD',
	APP_WEBSITE_SETTINGS_REFRESH               : 'APP_WEBSITE_SETTINGS_REFRESH',
	APP_WEBSITE_ATTACHED_LANGUAGES_LIST_REFRESH: 'APP_WEBSITE_ATTACHED_LANGUAGES_LIST_REFRESH',

	PASSWORD_EXPIRED_MODAL_REFRESH: 'PASSWORD_EXPIRED_MODAL_REFRESH',
	MODAL_BASE_DATA_REFRESH       : 'MODAL_BASE_DATA_REFRESH',
	NEW_PASSWORD_RELOAD           : 'NEW_PASSWORD_RELOAD',
	APP_DATA_RESET                : 'APP_DATA_RESET',
	APP_MODAL_RESET               : 'APP_MODAL_RESET',

	toggleCollapsed: () => (
		{
			type: actions.COLLPSE_CHANGE,
		}
	),
	toggleAll: (width, height) => {
		const view      = getView(width);
		const collapsed = view !== 'DesktopView';
		return {
			type: actions.TOGGLE_ALL,
			collapsed,
			view,
			height,
		};
	},
	toggleOpenDrawer: () => (
		{
			type: actions.COLLPSE_OPEN_DRAWER,
		}
	),

	changeOpenKeys: openKeys => (
		{
			type: actions.CHANGE_OPEN_KEYS,
			openKeys,
		}
	),

	websiteRestore: () => (
		{
			type: actions.APP_WEBSITE_RESTORE,
		}
	),
	partnerRestore: () => (
		{
			type: actions.APP_PARTNER_RESTORE,
		}
	),

	websiteChange: (websiteID) => (
		{
			type: actions.APP_WEBSITE_CHANGE,
			data: {
				websiteID,
			},
		}
	),
	partnerChange: (partnerID) => (
		{
			type: actions.APP_PARTNER_CHANGE,
			data: {
				partnerID,
			},
		}
	),

	websiteRefresh: (websiteID) => (
		{
			type: actions.APP_WEBSITE_REFRESH,
			data: {
				websiteID,
			},
		}
	),
	partnerRefresh: (partnerID) => (
		{
			type: actions.APP_PARTNER_REFRESH,
			data: {
				partnerID,
			},
		}
	),

	websiteSettingsReload: () => (
		{
			type: actions.APP_WEBSITE_SETTINGS_RELOAD,
		}
	),
	websiteSettingsRefresh: (websiteSettings) => (
		{
			type: actions.APP_WEBSITE_SETTINGS_REFRESH,
			data: {
				websiteSettings,
			},
		}
	),

	passwordExpiredModalRefresh: (data) => (
		{
			type: actions.PASSWORD_EXPIRED_MODAL_REFRESH,
			data,
		}
	),

	modalBaseDataRefresh: (data) => (
		{
			type: actions.MODAL_BASE_DATA_REFRESH,
			data,
		}
	),
	newPasswordReload: (data) => {
		return {
			type: actions.NEW_PASSWORD_RELOAD,
			data,
		};
	},

	websiteListRefresh: (data) => {
		return {
			type: actions.APP_WEBSITE_LIST_REFRESH,
			data,
		};
	},
	// mainLanguagesRefresh: (data) => {
	//   return {
	//     type: actions.APP_MAIN_LANGUAGES_LIST_REFRESH,
	//     data,
	//   };
	// },
	partnerAttachedLanguagesRefresh: (data) => {
		return {
			type: actions.APP_PARTNER_ATTACHED_LANGUAGES_LIST_REFRESH,
			data,
		};
	},
	websiteAttachedLanguagesRefresh: (data) => {
		return {
			type: actions.APP_WEBSITE_ATTACHED_LANGUAGES_LIST_REFRESH,
			data,
		};
	},
	partnerListRefresh: (data) => {
		return {
			type: actions.APP_PARTNER_LIST_REFRESH,
			data,
		};
	},
	appDataReset: (data) => {
		return {
			type: actions.APP_DATA_RESET,
			data,
		};
	},
	appModalReset: (data) => {
		return {
			type: actions.APP_MODAL_RESET,
			data,
		};
	},


};

export default actions;
