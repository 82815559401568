import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import notifications from '../../../../helpers/notifications';
import { categoriesAPI } from '../../../../helpers/api/categories';
import { CATEGORIES_TYPES, DISPLAY_STATUS } from '../../../../helpers/commonConstants';

import actions from './actions';
import { adaptLeagueList, adaptRestrictionList } from './utils';
import { logger } from '../../../../helpers/logger';

export const prefix = 'sport.leagueRestrict';

export const messages = {
	loadLeagueList        : `${prefix}.loadLeagueList`,
	loadRestrictionList   : `${prefix}.loadRestrictionList`,
	successRestrictionList: `${prefix}.successRestrictionList`,
	errorRestrictionList  : `${prefix}.errorRestrictionList`,
};

function getStoreData({ Sport: { Categories } }) {

	return {
		restrictionIDs: Categories.LeagueRestriction.get('restrictionIDs'),
		modalUI       : Categories.LeagueRestriction.get('modalUI'),
	};
}

function* dataReload() {

	let errorMessage = '';

	yield takeEvery(actions.CATEGORY_RESTRICTION_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { sportID, categoryID } = action.data;

		let leagueIDs = [];
		let restrictionIDs = [];
		let leagueEntities = {};
		try {
			// league list
			errorMessage = messages.loadLeagueList;
			const params = {
				model_type       : 1,
				display_status_id: DISPLAY_STATUS.visible,
			};
			const resLeague = yield call(
				categoriesAPI.getCategoriesOfSport,
				sportID,
				CATEGORIES_TYPES.league,
				params
			);
			if (resLeague && resLeague.status === 200) {
				const result   = adaptLeagueList(resLeague.data.data);
				leagueIDs      = result.leagueIDs;
				leagueEntities = result.leagueEntities;
			}

			// restriction list
			errorMessage = messages.loadRestrictionList;
			const resRestriction = yield call(
				categoriesAPI.leagueRestrictionsList,
				categoryID
			);
			if (resRestriction && resRestriction.status === 200) {
				restrictionIDs = adaptRestrictionList(resRestriction.data.data);
			}
		} catch (error) {
			notifications.showError(errorMessage);
			logger.log(error);
		}

		yield put(
			actions.dataRefresh({
				leagueIDs,
				leagueEntities,
				restrictionIDs,
				selectedLeagueIDs     : [],
				selectedRestrictionIDs: [],
			})
		);

		yield put(actions.modalUIRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

function* dataSave() {

	let errorMessage = '';

	yield takeEvery(actions.CATEGORY_RESTRICTION_DATA_SAVE, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { restrictionIDs, modalUI } = yield select(getStoreData);
		const { sportID, categoryID, closeModal } = modalUI;

		let isError = false;
		try {
			errorMessage = messages.errorRestrictionList;
			const preparedData = restrictionIDs.map(item => toInteger(item));
			const response = yield call(categoriesAPI.leagueRestrictionsUpdate, categoryID, preparedData);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successRestrictionList);
				yield put(actions.modalUIRefresh({ isChanged: false }));
			}
		} catch (error) {
			isError = true;
			notifications.showError(errorMessage);
			logger.dir(error);
		}

		// reload data
		if (!closeModal) {
			yield put(actions.dataReload(sportID, categoryID));
		}

		yield put(
			actions.modalUIRefresh({
				loading: false,
				visible: !(closeModal && !isError),
			})
		);
	});
}

export default function* categorySaga() {
	yield all([
		fork(dataReload),
		fork(dataSave),
	]);
}
