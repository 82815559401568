import { sortBy, toInteger, values } from 'lodash';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { isID } from '../../../../../helpers/utils';
import cloneDeep from 'lodash/cloneDeep';

export const UserLimitsPaymentType = {
	DEPOSIT   : 'Deposit',
	WITHDRAWAL: 'Withdraw',
};

export const fields = {

	userId: 'user_id',

	minDeposit    : 'min_deposit',
	maxDeposit    : 'max_deposit',
	depositBlocked: 'deposit_blocked',

	minWithdraw    : 'min_withdraw',
	maxWithdraw    : 'max_withdraw',
	withdrawBlocked: 'withdraw_blocked',

};
export const createLimitsPaymentsListAdapter = () => {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number,    'userId', fields.userId);

	adapter.addField(rules.nullOrNumber,    'minDeposit', fields.minDeposit);
	adapter.addField(rules.nullOrNumber,    'maxDeposit', fields.maxDeposit);
	adapter.addField(rules.bool,      'depositBlocked', fields.depositBlocked);

	adapter.addField(rules.nullOrNumber,    'minWithdraw', fields.minWithdraw);
	adapter.addField(rules.nullOrNumber,    'maxWithdraw', fields.maxWithdraw);
	adapter.addField(rules.bool,      'withdrawBlocked', fields.withdrawBlocked);

	return adapter;
};
export  const prepareLimitsPayments = (sendData = {}, userId, type) => {
	sendData.userId = userId;
	const listAdapter = createLimitsPaymentsListAdapter();
	const preparedData = listAdapter.prepare(sendData);

	if (isID(sendData.payment_id)) {
		preparedData.payment_id = toInteger(sendData.payment_id);
	}

	if (type === UserLimitsPaymentType.DEPOSIT) {
		delete preparedData.withdraw_blocked;
		delete preparedData.min_withdraw;
		delete preparedData.max_withdraw;
	}

	if (type === UserLimitsPaymentType.WITHDRAWAL) {
		delete preparedData.deposit_blocked;
		delete preparedData.min_deposit;
		delete preparedData.max_deposit;
	}

	return preparedData;
};

export const adaptBody = (payments, resLimits ) => {
	return payments.map(payment => {
		let currentObj = {
			...payment,
			minDeposit     : null,
			minWithdraw    : null,
			maxDeposit     : null,
			maxWithdraw    : null,
			depositBlocked : false,
			withdrawBlocked: false,
			disable        : true,
		};
		if (resLimits.length) {
			resLimits.forEach(limit => {
				if (payment.payment_id === limit.payment_id) {
					currentObj = {
						...currentObj,
						limitId        : limit.id,
						maxDeposit     : limit.max_deposit,
						minDeposit     : limit.min_deposit,
						minWithdraw    : limit.min_withdraw,
						maxWithdraw    : limit.max_withdraw,
						depositBlocked : limit.deposit_blocked,
						withdrawBlocked: limit.withdraw_blocked,
					};
				}
			});
		}
		return currentObj;
	});
};
export const  createList = (entities) => {
	const sortedArr = sortBy(values(entities), ['name']);

	const sortedObj = sortedArr.reduce((next, payment) => {
		const { id } = payment;
		return { ...next, [id]: payment  };
	}, {});
	return {
		sortedArr,
		sortedObj,
	};
};

export const updateUserLimitsPaymentItem = (list = [], baseData = {}, providerId, disable = false, loading, type) => {
	const clonedList = cloneDeep(list);
	const index = clonedList.findIndex(item => item.id === providerId);

	if (index > -1) {
		const item = clonedList[index];

		if (type === UserLimitsPaymentType.DEPOSIT) {
			const { minDeposit, maxDeposit, depositBlocked } = baseData[providerId];

			item.loading = loading;
			item.disable = disable;
			item.depositBlocked = depositBlocked;
			item.minDeposit = minDeposit;
			item.maxDeposit = maxDeposit;
		}

		if (type === UserLimitsPaymentType.WITHDRAWAL) {
			const { minWithdraw, maxWithdraw, withdrawBlocked } = baseData[providerId];

			item.loading = loading;
			item.disable = disable;
			item.withdrawBlocked = withdrawBlocked;
			item.minWithdraw = minWithdraw;
			item.maxWithdraw = maxWithdraw;
		}

		return clonedList;
	}

	return clonedList;
};
