import { Map } from 'immutable';
import actions from './actions';
import { createFilter, fill, filtersURL } from '../../../../../helpers/utils';
import { initFilterTypes } from './utils';

const initialState = new Map({
	filter: createFilter(filtersURL.matchesOfTheDay, initFilterTypes),
});

export default (state = initialState, { type, data }) => {
	switch (type) {
		case actions.DAY_MATCHES_FILTER_SET_VALUE:
			return state.set(data.field, data.value);


		case actions.DAY_MATCHES_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(data, target, true);
			return state.set('filter', result);
		}
		case actions.DAY_MATCHES_FILTER_RESET_VALUES:
			return state.set('filter', {
				name     : '',
				dateRange: [],
			});
		default:
			return state;
	}
};
