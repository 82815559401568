import { Map } from 'immutable';
import { fill } from '../../../../../../helpers/utils';
import actions from './actions';
import { LIMITS_CASINO_OPTIONS_TYPES } from '../../../../../../constants/users';

const initState = new Map({

	searchToolbar: {
		selectedOption: String(LIMITS_CASINO_OPTIONS_TYPES.game),
		searchValue   : '',
		isSearching   : false,
	},

	UI: {
		loading: false,
	},
});

export default function casino(state = initState, { data, type }) {

	switch (type) {

		case actions.LIMIT_AND_RESTRICTION_CASINO_SEARCH_REFRESH: {
			const target = state.get('searchToolbar');
			const result = fill(data, target);
			return state.set('searchToolbar', result);
		}


		default:
			return state;
	}

}
