import { combineReducers } from 'redux';
import Banners from './banners/reducer';
import Menu from './menu/reducer';
import Pages from './pages/reducer';
import LandingPage from './landingPage/reducer';

export default combineReducers({
	Banners,
	LandingPage,
	Menu,
	Pages,
});
