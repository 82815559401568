import { all, takeLatest, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { showError, showSuccess } from '../../helpers/notifications';
import { affiliatesAPI } from '../../helpers/api/affiliates';
import { getHeadersTotalCount } from '../../helpers/utils';

import { getListParams, adaptList } from './utils';

const messages = {
	errorListLoad: 'Loading Affiliates list failed',
	errorResend  : 'Sending Affiliate data failed',
};

function getStoreData({ Affiliates }) {
	return {
		filter    : Affiliates.get('filter'),
		pagination: Affiliates.get('pagination'),
	};
}

function* listReload() {

	yield takeLatest(actions.LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, pagination } = yield select(getStoreData);
		const params = getListParams(filter, pagination);

		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(affiliatesAPI.affiliatesList, params);
			if (res && res.status === 200) {
				entities   = adaptList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
			}

		} catch (error) {
			showError(messages.errorListLoad, error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.paginationRefresh({ totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeLatest(actions.FILTER_APPLY, function* () {
		yield put(actions.paginationRefresh({ currentPage: 1 }));
		yield put(actions.listReload());
	});
}

function* dataResend() {

	yield takeLatest(actions.DATA_RESEND, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter } = yield select(getStoreData);
		const params = getListParams(filter);

		try {
			yield call(affiliatesAPI.affiliatesResend, params);

			showSuccess(messages.successSave);
			yield put(actions.filterApply());

		} catch (error) {
			showError(messages.errorSave, error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* affiliatesSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(dataResend),
	]);
}
