const actions = {
	OFFERS_FETCH_OFFERS        : 'OFFERS_FETCH_OFFERS',
	OFFERS_FETCH_OFFERS_SUCCESS: 'OFFERS_FETCH_OFFERS_SUCCESS',

	OFFERS_SET_ACTIVE_OFFER: 'OFFERS_SET_ACTIVE_OFFER',

	OFFERS_ADD_OFFER        : 'OFFERS_ADD_OFFER',
	OFFERS_ADD_OFFER_SUCCESS: 'OFFERS_ADD_OFFER_SUCCESS',

	OFFERS_UPDATE_OFFER        : 'OFFERS_UPDATE_OFFER',
	OFFERS_UPDATE_OFFER_SUCCESS: 'OFFERS_UPDATE_OFFER_SUCCESS',

	OFFERS_REMOVE_OFFER        : 'OFFERS_REMOVE_OFFER',
	OFFERS_REMOVE_OFFER_SUCCESS: 'OFFERS_REMOVE_OFFER_SUCCESS',

	OFFERS_SET_SPORT_ID: 'OFFERS_SET_SPORT_ID',

	fetchOffers: sportId => ({
		type: actions.OFFERS_FETCH_OFFERS,
		data: sportId,
	}),

	fetchOffersSuccess: offers => ({
		type: actions.OFFERS_FETCH_OFFERS_SUCCESS,
		data: offers,
	}),

	setActiveOffer: offerId => ({
		type: actions.OFFERS_SET_ACTIVE_OFFER,
		data: offerId,
	}),

	addOffer: () => ({
		type: actions.OFFERS_ADD_OFFER,
	}),

	addOfferSuccess: offer => ({
		type: actions.OFFERS_ADD_OFFER_SUCCESS,
		data: offer,
	}),

	updateOffer: () => ({
		type: actions.OFFERS_UPDATE_OFFER,
	}),

	updateOfferSuccess: offer => ({
		type: actions.OFFERS_UPDATE_OFFER_SUCCESS,
		data: offer,
	}),

	removeOffer: id => ({
		type: actions.OFFERS_REMOVE_OFFER,
		data: id,
	}),

	removeOfferSuccess: id => ({
		type: actions.OFFERS_REMOVE_OFFER_SUCCESS,
		data: id,
	}),

	setDate: date => ({
		type: actions.OFFERS_SET_DATE,
		data: date,
	}),

	setSportId: id => ({
		type: actions.OFFERS_SET_SPORT_ID,
		data: id,
	}),
};

export default actions;
