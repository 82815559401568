import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';

import { emailTemplatesAPI } from '../../../../../helpers/api/emailTemplates';
import notifications from '../../../../../helpers/notifications';

import { adaptTemplate, prepareTemplate } from './utils';
import { logger } from '../../../../../helpers/logger';

const prefix = 'notifcenter.emails.templates';

const messages = {
	errorBaseDataReload: `${prefix}.errorBaseDataReload`,
	errorItemSave      : `${prefix}.errorItemSave`,
	successItemSave    : `${prefix}.successItemSave`,
};

function getStoreData({ EmailTemplates }) {

	return {
		baseData: EmailTemplates.Template.get('baseData'),
		UI      : EmailTemplates.Template.get('UI'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.EMAIL_TEMPLATE_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { UI } = yield select(getStoreData);
		const { templateID } = action.data;

		let baseData = {};
		const params = { lang_id: UI.langID };
		try {
			const res = yield call(emailTemplatesAPI.templateInfo, templateID, params);
			if (res && res.status === 200) {
				baseData = adaptTemplate(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorBaseDataReload, error);
			logger.log(error);
		}

		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.uiRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

function* dataSave() {

	yield takeEvery(actions.EMAIL_TEMPLATE_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, UI } = yield select(getStoreData);
		const { closeModal, editMode, langID } = UI;
		let { templateID } = UI;

		const templateBaseData = cloneDeep(baseData);
		let isError = false;

		try {
			// base data
			const preparedData = prepareTemplate(templateBaseData, langID);
			if (editMode) {
				const res = yield call(emailTemplatesAPI.templateUpdate, templateID, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successItemSave);
				}
			} else {
				const res = yield call(emailTemplatesAPI.templateAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successItemSave);
					templateID = toInteger(res.data.data.id);
					yield put(actions.uiRefresh({ editMode: true, templateID }));
				}
			}

			yield put(actions.uiRefresh({ isChanged: false }));

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorItemSave, error);
		}

		if (!isError && !closeModal) {
			yield put(actions.baseDataReload(templateID));
		}

		yield put(listActions.listReload());
		yield put(
			actions.uiRefresh({
				loading: false,
				visible: !(closeModal && !isError),
			})
		);
	});
}

export default function* emailTemplateModalSaga() {
	yield all([
		fork(baseDataReload),
		fork(dataSave),
	]);
}
