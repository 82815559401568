import EntityAdapter from '../../../../helpers/entityAdapter';
import { createEntities } from '../../../utility';

const fields = {
	id               : 'id',
	name             : 'name',
	categoryID       : 'category_id',
	restrictedLeagues: 'restricted_leagues',
};

// Adapting ---------------------------------------------------------------------------------------

export function adaptLeagueList(rawData = []) {
	const adapter = createLeagueListAdapter();
	const adaptedList = adapter.adaptList(rawData);
	
	const leagueEntities	= createEntities(adaptedList);
	const leagueIDs			= Object.keys(leagueEntities);

	return {
		leagueIDs,
		leagueEntities,
	};
}

export function adaptRestrictionList(rawData = {}) {
	const adapter = createRestrictionListAdapter();
	const adaptedData = adapter.adapt(rawData);

	return adaptedData.restrictedLeagues;
}

// Adapters ---------------------------------------------------------------------------------------

function createLeagueListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

function createRestrictionListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(
		rules.arrayID,
		'restrictedLeagues',
		fields.restrictedLeagues
	);

	return adapter;
}
