const prefix = 'USER_LIMITS_RESTRICT_BY_TAGS_';

export const actions = {
	RESTRICT_BY_TAGS_REFRESH           : `${prefix}REFRESH`,
	RESTRICT_BY_TAGS_ASSOC_LIST_REFRESH: `${prefix}ASSOC_LIST_REFRESH`,
	RESTRICT_BY_TAGS_RELOAD            : `${prefix}RELOAD`,
	RESTRICT_BY_TAGS_SAVE              : `${prefix}SAVE`,
	RESTRICT_BY_TAGS_UI_REFRESH        : `${prefix}UI_REFRESH`,

	dataReload: data => (
		{
			type: actions.RESTRICT_BY_TAGS_RELOAD,
			data,
		}
	),

	dataSave: data => (
		{
			type: actions.RESTRICT_BY_TAGS_SAVE,
			data,
		}
	),

	uiRefresh: data => (
		{
			type: actions.RESTRICT_BY_TAGS_UI_REFRESH,
			data,
		}
	),


	tagsListRefresh: data => (
		{
			type: actions.RESTRICT_BY_TAGS_REFRESH,
			data,
		}
	),
};
