import { all, call, put, takeEvery, fork, select } from 'redux-saga/effects';
import flatten from 'lodash/flatten';
import actions from './actions';
import { apiRequest } from '../../../../../helpers/api';
import notification from '../../../../../helpers/notifications';
import { getSelectionsFromMarket } from './utils';

function getStoreData({ CMS }) {
	const activeOffer = CMS.LandingPage.SpecialOffers.List.get('activeOffer');

	return {
		items     : activeOffer.items,
		offerPrice: activeOffer.offer_price,
	};
}

const prefix = 'specialoffers.offer';

const messages = {
	errorLoadEventMarket: `${prefix}.loading.event.markets.failed`,
	errorFetchMarkets   : `${prefix}.fetching.markets.failed`,
	errorLoadMarkets    : `${prefix}.loading.markets.failed`,
};

function* fetchEventMarketsSaga() {
	yield takeEvery(actions.OFFERS_FETCH_EVENT_MARKETS, function* ({ data }) {
		try {
			const response = yield call(apiRequest, {
				method: 'GET',
				url   : '/eventMarket',
				params: {
					event_id          : data,
					include_selections: true,
					lang_id           : 1,
					status_id         : 1,
				},
			});
			if (response && response.status === 200) {
				const list = response.data.data;
				const sortedList = [...list];
				sortedList.map(item => {
					return item.selections.sort((a, b) => {
						if (a.row_index === b.row_index) {
							if (a.column_index < b.column_index) {
								return -1;
							}
							if (a.column_index > b.column_index) {
								return 1;
							}
							return 0;
						}
						return (a.row_index < b.row_index) ? -1 : 1;
					});
				});
				yield put(actions.fetchEventMarketsSuccess(sortedList));
			}
		} catch (e) {
			notification.showError(messages.errorLoadEventMarket);
		}
	});
}

function* getMarketsByEventIds(eventIds) {
	try {
		const response = yield call(apiRequest, {
			method: 'GET',
			url   : '/eventMarket',
			params: {
				include_selections: true,
				event_ids         : eventIds,
			},
		});
		if (response && response.status === 200) {
			return response.data.data;
		}
	} catch (e) {
		notification.showError(messages.errorFetchMarkets);
	}

	return [];
}

function* setSelectionsSaga() {
	yield takeEvery(actions.OFFERS_SET_SELECTIONS, function* () {
		try {
			const { items, offerPrice } = yield select(getStoreData);
			const marketIds = items.map(item => item.event_id);
			const markets = yield call(getMarketsByEventIds, marketIds);
			const availableMarkets = markets.filter(
				(market) => Number(market.event_id) === marketIds[0]
			);
			yield put(actions.fetchEventMarketsSuccess(availableMarkets));
			const selections = flatten(
				markets.map(({ selections }) => getSelectionsFromMarket(items, selections))
			);
			yield put(actions.setSelectionsSuccess(selections));
			yield put(actions.setCustomPrice(offerPrice));

		} catch (e) {
			notification.showError(messages.errorLoadMarkets);
		}
	});
}

export default function* () {
	yield all([fork(fetchEventMarketsSaga), fork(setSelectionsSaga)]);
}
