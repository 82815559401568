const actions = {
	PRIOR_GET_SPORTS_LIST_SUCCESS: 'PRIOR_GET_SPORTS_LIST_SUCCESS',
	PRIOR_REORDER_SPORT          : 'PRIOR_REORDER_SPORT',
	PRIOR_REORDER_SPORT_SUCCESS  : 'PRIOR_REORDER_SPORT_SUCCESS',

	getSportsListSuccess: list => ({
		type: actions.PRIOR_GET_SPORTS_LIST_SUCCESS,
		data: list,
	}),

	reorderSport: (oldIndex, newIndex) => ({
		type: actions.PRIOR_REORDER_SPORT,
		data: {
			oldIndex,
			newIndex,
		},
	}),

	reorderSportSuccess: sports => ({
		type: actions.PRIOR_REORDER_SPORT_SUCCESS,
		data: sports,
	}),
};

export default actions;
