const prefix = 'PARTNER_MODAL_PAYMENTS_METHODS_';

export const actions = {

	STORE_RESET: `${prefix}STORE_RESET`,

	BASE_DATA_REFRESH       : `${prefix}BASE_DATA_REFRESH`,
	UI_REFRESH              : `${prefix}UI_REFRESH`,
	DATA_RELOAD             : `${prefix}DATA_RELOAD`,
	PARTNER_PAYMENTS_REFRESH: `${prefix}PARTNER_PAYMENTS`,
    
	baseDataRefresh: data => (
		{
			type: actions.BASE_DATA_REFRESH,
			data,
		}
	),

	uiRefresh: data => (
		{
			type: actions.UI_REFRESH, data,
		}
	),

	paymentsMethodsDataReload: data => {
		return {
			type: actions.DATA_RELOAD,
			data,
		};
	},
	partnerPaymentsRefresh: data => {
		return {
			type: actions.PARTNER_PAYMENTS_REFRESH,
			data,
		};
	},

	resetStore: () => (
		{
			type: actions.STORE_RESET,

		}
	),


};
