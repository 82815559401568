import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	bets       : {},
	selectedIDs: [],

	UI: {
		loading: false,
	},

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},
});

export default function selectionBetHistoryReducer(state = initState, action) {

	switch (action.type) {

		case actions.DATA_REFRESH: {
			const { bets } = action.data;
			return state.set('bets', bets);
		}

		case actions.SELECTED_IDS_REFRESH: {
			const { selectedIDs } = action.data;
			return state.set('selectedIDs', selectedIDs);
		}

		case actions.UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}

		case actions.PAGINATION_REFRESH: {
			const { pagination } = action.data;
			const target = state.get('pagination');
			const result = fill(pagination, target);
			return state.set('pagination', result);
		}

		default:
			return state;
	}
}
