import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Popover from '../../../components/uielements/popover';
import userpic from '../../../image/user1.png';

import authActions from '../../../redux/auth/actions';
import profileActions from '../../../redux/profile/actions';

import ProfileModal from '../modals/Profile';
import ChangePassword from '../modals/ChangePassword';

import { lang } from '../lang';
import { Wrapper, DropdownWrapper } from './TopbarUser.style';

class TopbarUser extends Component {

	static propTypes = {
		userID              : PropTypes.number,
		avatar              : PropTypes.string,
		modalVisible        : PropTypes.bool,
		passwordModalVisible: PropTypes.bool,
		logout              : PropTypes.func.isRequired,
		uiRefresh           : PropTypes.func.isRequired,
	};

	static defaultProps = {
		userID              : 0,
		avatar              : '',
		modalVisible        : false,
		passwordModalVisible: false,
	};

	constructor(props) {
		super(props);
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.hide                = this.hide.bind(this);
		this.onClickProfile      = this.onClickProfile.bind(this);
		this.onClickChangePassword = this.onClickChangePassword.bind(this);

		this.state = {
			visible: false,
		};
	}

	hide() {
		this.setState({ visible: false });
	}

	handleVisibleChange() {
		const { visible } = this.state;
		this.setState({ visible: !visible });
	}

	onClickProfile() {
		const { userID, uiRefresh } = this.props;
		this.hide();
		uiRefresh({
			userID,
			visible: true,
		});
	}

	onClickChangePassword() {
		const { userID, uiRefresh } = this.props;
		this.hide();
		uiRefresh({
			userID,
			passwordModalVisible: true,
		});
	}

	render() {
		const { avatar, logout, modalVisible, passwordModalVisible, profileAvatar } = this.props;
		const { visible } = this.state;
		const isNotSavedPic = profileAvatar && profileAvatar.includes('/tmp/');
		const userAvatar = (!isNotSavedPic && profileAvatar) || avatar || userpic;

		const content = (
			<DropdownWrapper className="isoUserDropdown">
				<div className="isoDropdownLink" onClick={this.onClickProfile}>{lang.profile}</div>
				<div className="isoDropdownLink" onClick={this.onClickChangePassword}>{lang.changePassword}</div>
				<div className="isoDropdownLink" onClick={logout}>{lang.logout}</div>
			</DropdownWrapper>
		);

		return (
			<Wrapper>
				<Popover
					content={content}
					trigger="click"
					open={visible}
					onOpenChange={this.handleVisibleChange}
					// arrow={{ pointAtCenter: true }}
					placement="bottomLeft"
					overlayInnerStyle={{ background: 'none', boxShadow: 'none' }}
				>
					<div className="isoImgWrapper">
						<img alt="user" src={userAvatar} />
					</div>
				</Popover>
				{modalVisible && <ProfileModal />}
				{passwordModalVisible && <ChangePassword />}
			</Wrapper>
		);
	}
}

function mapStateToProps(state) {
	const { Auth, Profile } = state;
	const user = Auth.get('user') || {};
	const UI = Profile.get('UI');
	return {
		userID              : user.id,
		avatar              : user.avatar,
		profileAvatar       : Profile.get('baseData').avatar,
		modalVisible        : UI.visible,
		passwordModalVisible: UI.passwordModalVisible,
	};
}

export default connect(mapStateToProps, {
	logout   : authActions.logout,
	uiRefresh: profileActions.uiRefresh,
})(TopbarUser);
