import { createIntl } from 'react-intl';
import Enlang from './entries/en-US';
import Rulang from './entries/ru_RU';
import Ualang from './entries/ua_UA';
import Trlang from './entries/tr-TR';
import Delang from './entries/de_DE';
import { restoreLanguage } from '../helpers/utility';
import enMessages from './locales/en_US.json';
import ruMessages from './locales/ru_RU.json';

//import Zhlang from './entries/zh-Hans-CN';
//import Salang from './entries/ar_SA';
//import Itlang from './entries/it_IT';
//import Eslang from './entries/es_ES';
//import Frlang from './entries/fr_FR';


const AppLocale = {
	en: Enlang,
	ru: Rulang,
	ua: Ualang,
	tr: Trlang,
	de: Delang,

	//zh: Zhlang,
	//sa: Salang,
	//it: Itlang,
	//es: Eslang,
	//fr: Frlang,
};

// addLocaleData(AppLocale.en.data);
// addLocaleData(AppLocale.ru.data);
// addLocaleData(AppLocale.ua.data);
// addLocaleData(AppLocale.tr.data);

//addLocaleData(AppLocale.zh.data);
//addLocaleData(AppLocale.sa.data);
//addLocaleData(AppLocale.it.data);
//addLocaleData(AppLocale.es.data);
//addLocaleData(AppLocale.fr.data);

export const langToString = (id) => {
	const locale = restoreLanguage() ? restoreLanguage().locale : 'en';
	const messages = {
		en: enMessages,
		ru: ruMessages,
		//... add language messages here
	};
	const intl = createIntl({ locale, messages: messages[locale], key: locale });
	// const intlProvider = new IntlProvider({ locale, messages: messages[locale], key: locale });
	return intl.formatMessage({
		id,
	});
};

export default AppLocale;
