import { FuseResult } from 'fuse.js';
import { IFuseItem, IListItem } from './types';

export const fuseOptions = {
	fieldNormWeight: 1,
	keys           : [
		'name',
	],
};
function madeOptions(list: Array<FuseResult<IFuseItem>>) {
	if (!list && !list.length) {
		return [
			{
				label: '',
				value: '',
			},
		];
	}
	return list.reduce((acc: IListItem[], elem: FuseResult<IFuseItem>) => {
		acc.push({
			label: elem.item.name,
			value: elem.item.name,
		});
		return acc;
	}, []);
}

export {
	madeOptions,
};
