import { createSelector } from 'reselect';

const menu     = (state) => state.Sidebar.get('menu');
const editMenu = (state) => state.Sidebar.get('editMenu');
const UI       = (state) => state.Sidebar.get('UI');

export const deriveMenu = createSelector(
	[menu],
	(menu) => menu
);

export const deriveEditMenu = createSelector(
	[editMenu],
	(editMenu) => editMenu
);

export const deriveUI = createSelector(
	[UI],
	(UI) => UI
);
