import { apiRequest } from './index';

function reportsListByProvider(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/casinoReports/listByProvider',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

export const reportsListByProviderAPI = {
	reportsListByProvider,
};
