import { isEmpty, toInteger } from 'lodash';
import formatter from '../../../helpers/formatter';
import { DiffMapper } from '../../../helpers/diff';
import { HistoryEnums } from '../../sport/results/enums';

export const fields = {
	id          : 'id',
	objectBefore: 'object_before',
	objectAfter : 'object_after',
	date        : 'date',
	userID      : 'user_id', // or source_id
	sourceTypeID: 'source_type',
	actionTypeID: 'action_type',
	sourceIp    : 'source_ip',
};

export function adaptHistory(rawData) {

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

function adaptHistoryList(rawData, diffOptions, arrayFields = []) {
	const historyEnums = new HistoryEnums();

	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter = historyItem[fields.objectAfter] || {};

		// main data
		const diffMap = mapper.map(objectBefore, objectAfter);
		const diffList = mapper.toList(diffMap);

		itemDiffList = itemDiffList.concat(diffList);

		// arrays
		arrayFields.forEach(fieldName => {
			const diffList = mapper.mapList(
				objectBefore,
				objectAfter,
				fieldName,
				true
			);
			itemDiffList = itemDiffList.concat(diffList);
		});

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID = toInteger(historyItem[fields.userID]);
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);

		const dataItem = {
			id         : historyItemIndex,
			userID,
			date       : formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			detailsList: itemDiffList,
		};

		dataList.push(dataItem);
	});

	return dataList;
}
