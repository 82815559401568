import { all, call, takeEvery, put, fork, select } from 'redux-saga/effects';
import { casinoAPI } from '../../../../../../helpers/api/casino';
import { usersAPI } from '../../../../../../helpers/api/users';
import notifications, { showError } from '../../../../../../helpers/notifications';
import actions from './actions';
import { adaptBody, prepareCustomProvider, updateCustomProviderItem } from './utils';
import { logger } from '../../../../../../helpers/logger';

const prefix = 'users.limits.customProviders';

const messages = {
	errorCustomProviderListReload: `${prefix}.errorCustomProviderListReload`,
	errorCustomProviderSave      : `${prefix}.errorCustomProviderSave`,
	errorSmaller                 : `${prefix}.errorSmaller`,
	successCustomProviderSave    : `${prefix}.successCustomProviderSave`,
};
const getBaseData = ({ Users }) => {
	return {
		baseData          : Users.UserInfo.UserLimitsAndRestrictions.Casino.CustomProvider.get('baseData'),
		customProviderList: Users.UserInfo.UserLimitsAndRestrictions.Casino.CustomProvider.get('customProviderList'),
	};
};

function* customProviderListReload() {

	const takeAction = actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.customProviderUiRefresh({ loading: true }));
		const { data } = action;

		let customProviders;
		let providersLimits;
		let adaptProviders;
		try {

			const [resProviders, resProvidersLimits] = yield all([
				call(casinoAPI.customProvidersList),
				call(usersAPI.userLimitsCasinoSelect, data),
			]);
			if (resProviders && resProviders.status === 200 && resProvidersLimits && resProvidersLimits.status === 200) {
				customProviders = resProviders.data.data;
				providersLimits = resProvidersLimits.data.data;

				adaptProviders                       = adaptBody(customProviders, providersLimits, data.userID);
				const { providersArr, providersObj } = adaptProviders;

				yield put(actions.customProviderRefresh(providersArr));
				yield put(actions.baseDataRefresh(providersObj));
				yield put(actions.customProviderUiRefresh({ loading: false }));

			}
		} catch (e) {
			showError(messages.errorCustomProviderListReload, e);
			yield put(actions.customProviderUiRefresh({ loading: false }));
			logger.log(e);
		}
	});
}

function* customProviderSave() {

	yield takeEvery(actions.USER_LIMITS_CASINO_CUSTOM_PROVIDER_SAVE, function* ({ providerId }) {
		const { baseData, customProviderList } = yield select(getBaseData);
		const { minStake, maxStake } = baseData[providerId];

		if ((minStake && maxStake) && (minStake > maxStake)) {
			showError(messages.errorSmaller);
			return;
		}

		try {
			const updatedCustomProviderList = updateCustomProviderItem(customProviderList, baseData, providerId, true, true);
			yield put(actions.customProviderRefresh(updatedCustomProviderList));
			const preparedData              = prepareCustomProvider(baseData[providerId]);
			const res                       = yield call(usersAPI.userLimitsCasinoUpdate, preparedData, baseData[providerId]);

			if (res && res.status === 200) {
				const updatedCustomProviderList = updateCustomProviderItem(customProviderList, baseData, providerId, false, true);
				yield put(actions.customProviderRefresh(updatedCustomProviderList));
				notifications.showSuccess(messages.successCustomProviderSave);
			}
		} catch (e) {
			const updatedCustomProviderList = updateCustomProviderItem(customProviderList, baseData, providerId, false,false);
			yield put(actions.customProviderRefresh(updatedCustomProviderList));

			notifications.showError(messages.errorCustomProviderSave, e);
			logger.log(e);
		}
	});
}

export default function* userLimitsCasinoCustomProviderGroupSaga() {
	yield all([
		fork(customProviderListReload),
		fork(customProviderSave),
	]);
}
