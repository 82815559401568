import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({

	filter: createFilter(filtersURL.roles, initFilterTypes),
});

const clonedState = cloneDeep(initState);
export default function personalFilterRolesReducer(state = initState, action) {

	switch (action.type) {

		case actions.FILTER_ROLE_REFRESH: {
			const { filterData } = action.data;
			const target = state.get('filter');
			const result = fill(filterData, target, true);

			return state.set('filter', result);
		}

		case actions.RESET: {
			return  clonedState;
		}

		default:
			return state;
	}
}
