import { FC } from 'react';
import { IPropsIcons } from './types';
import { defaultPropsIcons } from './utils';

export const RiskManagementIcon: FC<IPropsIcons> = (props) => {
	const { width, height, viewBox, color } = props;
	return (
		<svg
			{...props}  style={{ rotate: '180deg', marginRight: '10px' }} width={width} height={height} viewBox={viewBox}>
			<g style={{ scale: '0.03' }} fill={color}>
				<path
					fillRule="evenodd" clipRule="evenodd"
					fill={color}
					d="M234 462 c-6 -4 -59 -92 -118 -197 -80 -140 -105 -194 -98 -205 8 -13 45 -15 232 -15 187 0 224 2 232 15 7 11 -19 65 -98 205 -98 173 -119 205 -134 205 -3 0 -10 -4 -16 -8z m130 -200 c58 -104 106 -192 106 -195 0 -4 -99 -7 -220 -7 -121 0 -220 3 -220 7 0 12 213 383 220 383 4 0 55 -85 114 -188z" />
				<path d="M230 250 c0 -73 2 -80 20 -80 18 0 20 7 20 80 0 73 -2 80 -20 80 -18 0 -20 -7 -20 -80z" />
				<path d="M230 120 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20 -13 0 -20 -7 -20 -20z" />
				<path d="M80 443 c1 -15 49 -67 55 -61 8 7 -35 68 -47 68 -4 0 -8 -3 -8 -7z" />
				<path d="M381 419 c-13 -17 -20 -33 -16 -37 6 -6 54 46 55 61 0 16 -18 5 -39 -24z" />
				<path d="M20 372 c0 -7 63 -42 75 -42 17 0 -1 19 -30 33 -41 19 -45 20 -45 9z" />
				<path d="M433 363 c-28 -14 -44 -33 -28 -33 12 0 75 35 75 42 0 11 -10 9 -47 -9z" />
				<path d="M0 280 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10z" />
				<path d="M420 280 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10z" />
			</g>
		</svg>
	);
};

RiskManagementIcon.defaultProps = defaultPropsIcons;