import { all, takeLatest, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { showError } from '../../../../helpers/notifications';
import { pushNotificationsAPI } from '../../../../helpers/api/pushNotifications';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';

import { getListParams, adaptList, checkSortNotifications } from '../utils';

const tableType       = TABLE_TYPES.notification;

const prefix = 'push.userslist';

const messages = {
	errorListLoad: `${prefix}.errorListLoad`,
};

function getStoreData({ NotificationsCenter: { Notifications }, Tables }) {
	return {
		filter    : Notifications.UsersList.get('filter'),
		pagination: Notifications.UsersList.get('pagination'),
		sorting   : checkSortNotifications(Tables.get(tableType).sorting),
	};
}

function* listReload() {

	yield takeLatest(actions.LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, pagination, sorting } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(pushNotificationsAPI.usersList, params);
			if (res && res.status === 200) {
				entities   = adaptList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
			}

		} catch (error) {
			showError(messages.errorListLoad);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.paginationRefresh({ totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeLatest(actions.FILTER_APPLY, function* () {
		yield put(actions.paginationRefresh({ currentPage: 1 }));
		yield put(actions.listReload());
	});
}

export default function* pushNotificationsListSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
	]);
}
