import { apiRequest } from './index';

function emailsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/emails',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function emailInfo(emailID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/email/${emailID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function emailResend(emailID, params = {}) {

	const req = {
		method: 'POST',
		url   : `/email/${emailID}/resend`,
		params,
	};

	return apiRequest(req);
}

export const emailsAPI = {
	emailsList,
	emailInfo,
	emailResend,
};
