import EntityAdapter from '../../helpers/entityAdapter';
import { PERMISSIONS } from '../../helpers/permissions/constants';

const userFields = {
	id        : 'id',
	userName  : 'username',
	firstName : 'first_name',
	lastName  : 'last_name',
	email     : 'email',
	statusID  : 'status_id',
	userRoles : 'user_roles',
	roles     : 'roles',
	adminType : 'admin_type',
	avatar    : 'avatar',
	langID    : 'language_id',
	websiteID : 'website_id',
	websiteIDs: 'website_ids',
	partnerID : 'partner_id',
	partnerIDs: 'partner_ids',
};

const userAdapter = createUserAdapter();

// Adapt ------------------------------------------------------------------------------------------


export function adaptUser(rawData = {}) {
	const adaptedData = userAdapter.adapt(rawData);

	const regex = /^admin.*(create|read|update|delete)$/;
	const rootSidebar = {};
	const projectPermissions = {};

	adaptedData.userRoles.length && adaptedData.userRoles.forEach(item => {
		if (item === PERMISSIONS.superAdmin || item === PERMISSIONS.platformAdmin) {
			rootSidebar[item] = item;
			projectPermissions[item] = item;
		}
		if (regex.test(item)) {
			const department        = item.replace(/_(update|read|create|delete)$/i, '');
			const lastPart          = item.match(/[^_]+$/)[0];
			const rootMenu          = item.split('_').slice(0, 2).join('_');
			rootSidebar[rootMenu]   = rootMenu;
			rootSidebar[department] = department;

			if (!projectPermissions[department]) {
				projectPermissions[department] = {
					department,
					read  : false,
					create: false,
					update: false,
					delete: false,
				};
			}
			projectPermissions[department][lastPart] = true;
		}
		// TODO: refactor this if statement.
		if (item.startsWith('admin_loyalty_bonus-engine_')) {
			rootSidebar['admin_loyalty_bonus-engine_bonuses'] = 'admin_loyalty_bonus-engine_bonuses';
		}
	});
	const permissions = {
		rootSidebar,
		projectPermissions,
	};

	if (!adaptedData.userName) {
		adaptedData.userName = adaptedData.email;
	}
	adaptedData.name = adaptedData.userName;
	adaptedData.fullName = `${adaptedData.firstName} ${adaptedData.lastName}`;

	return {
		adaptedData,
		permissions,
	};
}

// Adapters ---------------------------------------------------------------------------------------

function createUserAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', userFields.id);
	adapter.addField(rules.id, 'statusID', userFields.statusID);
	adapter.addField(rules.id, 'langID', userFields.langID);
	adapter.addField(rules.id, 'websiteID', userFields.websiteID);
	adapter.addField(rules.id, 'partnerID', userFields.partnerID);
	adapter.addField(rules.id, 'adminType', userFields.adminType);

	adapter.addField(rules.string, 'userName', userFields.userName);
	adapter.addField(rules.string, 'firstName', userFields.firstName);
	adapter.addField(rules.string, 'lastName', userFields.lastName);
	adapter.addField(rules.string, 'email', userFields.email);
	adapter.addField(rules.string, 'avatar', userFields.avatar);

	adapter.addField(rules.arrayString, 'userRoles', userFields.userRoles);
	adapter.addField(rules.arrayID, 'roles', userFields.roles);
	adapter.addField(rules.arrayID, 'websiteIDs', userFields.websiteIDs);
	adapter.addField(rules.arrayID, 'partnerIDs', userFields.partnerIDs);

	return adapter;
}
