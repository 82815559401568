import toInteger from 'lodash/toInteger';
import { isID } from '../../../../../helpers/utils';
import { createListAdapter, fields } from '../list/utils';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../utility';

const listAdapter = createListAdapter();
const revisionAdapter = createRevisionAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptPromotion(rawData = {}) {
	listAdapter.clearExcludes();
	if (!rawData.promotion) {
		rawData.promotion = {};
	}
	rawData.promotion.id = rawData.id;
	rawData.promotion.lang_id = rawData.lang_id;
	rawData.promotion.channel_id = rawData.channel_id;
	rawData.promotion.website_id = rawData.website_id;
	rawData.promotion.status_id = rawData.status_id;
	rawData.promotion.alias = rawData.alias;
	return listAdapter.adapt(rawData.promotion);
}

export function adaptRevisionsList(rawData = []) {
	let activeRevisionID = null;
	revisionAdapter.clearExcludes();
	const adaptedData = revisionAdapter.adaptList(rawData);

	adaptedData.forEach(revision => {
		revision.promotion.id = rawData.promotionID;
		revision.promotion.lang_id = rawData.langID;
		revision.promotion.channel_id = rawData.channelID;
		revision.promotion.website_id = rawData.websiteID;
		revision.promotion.alias = revision.alias;
		revision.promotion =  listAdapter.adapt(revision.promotion);
		revision.promotion.games = revision.promotion.games?.map(item => item.id);
		if (revision.active) {
			activeRevisionID = revision.id;
		}
	});

	const revisionsEntities = createEntities(adaptedData);

	return {
		revisionsEntities,
		activeRevisionID,
	};
}

// Prepare ----------------------------------------------------------------------------------------

export function preparePromotion(baseData, langID, channelID) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('createdAt');
	listAdapter.addExcludeField('updatedAt');

	const preparedData = listAdapter.prepare(baseData);

	preparedData[fields.langID] = langID;
	preparedData[fields.channelID] = channelID;
	if (isID(baseData.id)) {
		preparedData[fields.id] = toInteger(baseData.id);
	}

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

function createRevisionAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'promotionID', fields.promotionID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.string, 'alias', fields.alias);

	adapter.addField(rules.bool, 'active', fields.active);
	adapter.addField(rules.fullDate, 'date', fields.date);
	adapter.addField(rules.noCondition, 'promotion', fields.promotion);

	return adapter;
}
