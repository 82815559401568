import toInteger from 'lodash/toInteger';
import { apiRequest } from './index';

function resultsList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/events',
		params,
	};

	return apiRequest(req);
}

function resultInfo(eventID) {

	const req = {
		method: 'GET',
		url   : '/results/selections',
		params: {
			event_id: toInteger(eventID),
		},
	};

	return apiRequest(req);
}

function resultsMarketsListUpdate(eventID, resultsList, params = {}) {

	const req = {
		method: 'POST',
		url   : `/results/events/${eventID}/markets`,
		data  : resultsList,
		params,
	};

	return apiRequest(req);
}

function statisticsList(eventID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/statistics/events/${eventID}`,
		params,
	};

	return apiRequest(req);
}

function statisticsListUpdate(eventID, statisticsData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/statistics/events/${eventID}`,
		data  : statisticsData,
		params,
	};

	return apiRequest(req);
}

export const resultsAPI = {
	resultsList,
	resultInfo,
	resultsMarketsListUpdate,

	statisticsList,
	statisticsListUpdate,
};
