const actions = {

	PRIOR_EVENTS_FILTER_SET_VALUE           : 'PRIOR_EVENTS_FILTER_SET_VALUE',
	PRIOR_EVENTS_FILTER_REFRESH             : 'PRIOR_EVENTS_FILTER_REFRESH',
	PRIOR_EVENTS_FILTER_COUNTRY_LIST_RELOAD : 'PRIOR_EVENTS_FILTER_COUNTRY_LIST_RELOAD',
	PRIOR_EVENTS_FILTER_COUNTRY_LIST_REFRESH: 'PRIOR_EVENTS_FILTER_COUNTRY_LIST_REFRESH',
	PRIOR_EVENTS_FILTER_LEAGUE_LIST_RELOAD  : 'PRIOR_EVENTS_FILTER_LEAGUE_LIST_RELOAD',
	PRIOR_EVENTS_FILTER_LEAGUE_LIST_REFRESH : 'PRIOR_EVENTS_FILTER_LEAGUE_LIST_REFRESH',

	filterSetValue: (valueName, value) => ({
		type: actions.PRIOR_EVENTS_FILTER_SET_VALUE,
		data: {
			valueName,
			value,
		},
	}),
	filterRefresh: data => ({
		type: actions.PRIOR_EVENTS_FILTER_REFRESH,
		data,
	}),
	countryListReload: sportID => ({
		type: actions.PRIOR_EVENTS_FILTER_COUNTRY_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	countryListRefresh: list => ({
		type: actions.PRIOR_EVENTS_FILTER_COUNTRY_LIST_REFRESH,
		data: list,
	}),
	leagueListReload: countryID => ({
		type: actions.PRIOR_EVENTS_FILTER_LEAGUE_LIST_RELOAD,
		data: {
			countryID,
		},
	}),
	leagueListRefresh: list => ({
		type: actions.PRIOR_EVENTS_FILTER_LEAGUE_LIST_REFRESH,
		data: list,
	}),
};

export default actions;
