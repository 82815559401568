const prefix = 'SET_PARTNER_PASSWORD_';

export const actions = {

	RELOAD: `${prefix}RELOAD`,

	BASE_DATA_REFRESH: `${prefix}BASE_DATA_REFRESH`,
	UI_REFRESH       : `${prefix}UI_REFRESH`,

	DATA_SAVE : `${prefix}DATA_SAVE`,
	DATA_RESET: `${prefix}DATA_RESET`,

	reload: data => ({
		type: actions.RELOAD,
		data,
	}),

	baseDataRefresh: data => ({
		type: actions.BASE_DATA_REFRESH,
		data,
	}),

	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),

	dataSave: data => ({
		type: actions.DATA_SAVE,
		data,
	}),

	dataReset: () => ({
		type: actions.DATA_RESET,
	}),

};
