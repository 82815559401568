const actions = {

	CASINO_CUSTOM_PROVIDER_DATA_RESET       : 'CASINO_CUSTOM_PROVIDER_DATA_RESET',
	CASINO_CUSTOM_PROVIDER_BASE_DATA_RELOAD : 'CASINO_CUSTOM_PROVIDER_BASE_DATA_RELOAD',
	CASINO_CUSTOM_PROVIDER_BASE_DATA_REFRESH: 'CASINO_CUSTOM_PROVIDER_BASE_DATA_REFRESH',

	CASINO_CUSTOM_PROVIDER_NAMES_DATA_RELOAD : 'CASINO_CUSTOM_PROVIDER_NAMES_DATA_RELOAD',
	CASINO_CUSTOM_PROVIDER_NAMES_DATA_REFRESH: 'CASINO_CUSTOM_PROVIDER_NAMES_DATA_REFRESH',

	CASINO_CUSTOM_PROVIDER_GAMES_DATA_REFRESH: 'CASINO_CUSTOM_PROVIDER_GAMES_DATA_REFRESH',

	CASINO_CUSTOM_PROVIDER_SAVE      : 'CASINO_CUSTOM_PROVIDER_SAVE',
	CASINO_CUSTOM_PROVIDER_UI_REFRESH: 'CASINO_CUSTOM_PROVIDER_UI_REFRESH',

	CASINO_CUSTOM_PROVIDER_SEO_DATA_RELOAD	: 'CASINO_CUSTOM_PROVIDER_SEO_DATA_RELOAD',
	CASINO_CUSTOM_PROVIDER_SEO_DATA_REFRESH: 'CASINO_CUSTOM_PROVIDER_SEO_DATA_REFRESH',
	CASINO_CUSTOM_PROVIDER_SEO_DATA_SAVE 		: 'CASINO_CUSTOM_PROVIDER_SEO_DATA_SAVE',

	dataReset: () => ({
		type: actions.CASINO_CUSTOM_PROVIDER_DATA_RESET,
	}),

	baseDataReload: (customProviderID, forced = false) => ({
		type: actions.CASINO_CUSTOM_PROVIDER_BASE_DATA_RELOAD,
		data: {
			customProviderID,
			forced,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.CASINO_CUSTOM_PROVIDER_BASE_DATA_REFRESH,
		data,
	}),

	namesDataReload: customProviderID => ({
		type: actions.CASINO_CUSTOM_PROVIDER_NAMES_DATA_RELOAD,
		data: {
			customProviderID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.CASINO_CUSTOM_PROVIDER_NAMES_DATA_REFRESH,
		data,
	}),

	gamesDataRefresh: data => ({
		type: actions.CASINO_CUSTOM_PROVIDER_GAMES_DATA_REFRESH,
		data,
	}),

	customProviderSave: (closeModal) => ({
		type: actions.CASINO_CUSTOM_PROVIDER_SAVE,
		data: { closeModal },
	}),

	uiRefresh: data => ({
		type: actions.CASINO_CUSTOM_PROVIDER_UI_REFRESH,
		data,
	}),

};

export default actions;
