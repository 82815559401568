import React from 'react';
import IntlMessages from '../components/utility/intlMessages';

export const USER_STATUS = {
	all        : 0,
	active     : 1,
	blocked    : 2,
	deleted    : 3,
	deactivated: 4,
	pending    : 5,
};

export const USER_DOCUMENT_STATUS_TYPE = {
	awaitingRevew: 1,
	accepted     : 2,
	declined     : 3,
};

export const USER_ROLE = {
	all       : 'all',
	admin     : 'admin',
	superAdmin: 'super_admin',
};

export const USER_REGISTER_STATUS = {
	all          : 0,
	registered   : 1,
	notRegistered: 2,
};


const LANG_BY_KEYS = {
	INTEGRATOR     : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.integrator" />,
	PROVIDER       : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.provider" />,
	CUSTOM_PROVIDER: <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.customProvider" />,
	TAG            : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.tags" />,
	CATEGORY       : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.category" />,
	GAME           : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.game" />,
};

export const LIMITS_CASINO_OPTIONS       = [
	{ id: 3, name: LANG_BY_KEYS.CUSTOM_PROVIDER, key: 'Custom Provider' },
	{ id: 4, name: LANG_BY_KEYS.TAG, key: 'Tag' },
	{ id: 5, name: LANG_BY_KEYS.CATEGORY, key: 'Category' },
	{ id: 6, name: LANG_BY_KEYS.GAME, key: 'Game' },
];

export const LIMITS_CASINO_FOR_KEYS       = [
	{ id: 1,  key: 'Integrator' },
	{ id: 2,  key: 'Provider' },
	{ id: 6,  key: 'Game' },
];


export const LIMITS_CASINO_OPTIONS_TYPES = {
	integrator    : 1,
	provider      : 2,
	customProvider: 3,
	tag           : 4,
	category      : 5,
	game          : 6,
};
