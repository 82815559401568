import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import { usersAPI } from '../../../helpers/api/users';
import notifications from '../../../helpers/notifications';
import ParamsBuilder from '../../../helpers/paramsBuilder';

import actions from './actions';
import { adaptActivityList } from './utils';
import { logger } from '../../../helpers/logger';

const prefix = 'users.activity';

const messages = {
	errorLoadActions: `${prefix}.errorLoadActions`,
};


function getStoreData({ Users }) {

	return {
		pagination: Users.Activity.get('pagination'),
		UI        : Users.Activity.get('UI'),
	};
}

function getParams(userID, pagination) {
	const builder = new ParamsBuilder();

	builder.addValue('id', userID);
	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	const params = builder.biuldParams({});

	return params;
}

function* listReload() {

	const takeAction = actions.USER_ACTIVITY_LIST_RELOAD;

	yield takeEvery(takeAction, function* ({ data }) {
		yield put(actions.setValueUI('loading', true));

		const { userID, userOrAdmin } = data;
		const typeUrl = userOrAdmin === 'users' ? 'users' : 'admins';

		let list = [];
		let totalCount = 0;

		const storeData = yield select(getStoreData);

		const params = getParams(userID, storeData.pagination);
		try {
			const response = yield call(usersAPI.getActionsList, typeUrl, params);
			if (response && response.status === 200) {
				list = adaptActivityList(response.data.data.data);
				totalCount = toInteger(response.data.data.count);
			}
		} catch (error) {
			notifications.showError(messages.errorLoadActions, error);
			logger.log(error);
		}

		yield put(actions.listRefresh(list));
		yield put(actions.paginationRefresh({ totalCount }));
		yield put(actions.setValueUI('loading', false));
	});
}

export default function* activitySaga() {
	yield all([
		fork(listReload),
	]);
}
