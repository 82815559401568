const actions = {
	DAY_MATCHES_FETCH_MATCHES        : 'DAY_MATCHES_FETCH_MATCHES',
	DAY_MATCHES_FETCH_MATCHES_SUCCESS: 'DAY_MATCHES_FETCH_MATCHES_SUCCESS',

	DAY_MATCHES_ADD_MATCH        : 'DAY_MATCHES_ADD_MATCH',
	DAY_MATCHES_ADD_MATCH_SUCCESS: 'DAY_MATCHES_ADD_MATCH_SUCCESS',

	DAY_MATCHES_UPDATE_MATCH        : 'DAY_MATCHES_UPDATE_MATCH',
	DAY_MATCHES_UPDATE_MATCH_SUCCESS: 'DAY_MATCHES_UPDATE_MATCH_SUCCESS',

	DAY_MATCHES_SET_ACTIVE_MATCH: 'DAY_MATCHES_SET_ACTIVE_MATCH',
	DAY_MATCHES_REMOVE_MATCH    : 'DAY_MATCHES_REMOVE_MATCH',

	DAY_MATCHES_REMOVE_MATCH_SUCCESS: 'DAY_MATCHES_REMOVE_MATCH_SUCCESS',
	DAY_MATCHES_SET_EVENT           : 'DAY_MATCHES_SET_EVENT',

	DAY_MATCHES_SET_EVENT_MARKET   : 'DAY_MATCHES_SET_EVENT_MARKET',
	DAY_MATCHES_FETCH_EVENT_MARKETS: 'DAY_MATCHES_FETCH_EVENT_MARKETS',

	DAY_MATCHES_FETCH_EVENT_MARKETS_SUCCESS: 'DAY_MATCHES_FETCH_EVENT_MARKETS_SUCCESS',
	DAY_MATCHES_TOGGLE_MODAL               : 'DAY_MATCHES_TOGGLE_MODAL',

	DAY_MATCHES_TOGGLE_UPDATING: 'DAY_MATCHES_TOGGLE_UPDATING',
	DAY_MATCHES_SET_START_DATE : 'DAY_MATCHES_SET_START_DATE',

	DAY_MATCHES_SET_IMAGE_URL       : 'DAY_MATCHES_SET_IMAGE_URL',
	DAY_MATCHES_TOGGLE_BUTTONS_STATE: 'DAY_MATCHES_TOGGLE_BUTTONS_STATE',

	fetchMatches: () => ({
		type: actions.DAY_MATCHES_FETCH_MATCHES,
	}),

	fetchMatchesSuccess: matches => ({
		type: actions.DAY_MATCHES_FETCH_MATCHES_SUCCESS,
		data: matches,
	}),

	addMatch: () => ({
		type: actions.DAY_MATCHES_ADD_MATCH,
	}),

	addMatchSuccess: match => ({
		type: actions.DAY_MATCHES_ADD_MATCH_SUCCESS,
		data: match,
	}),

	updateMatch: () => ({
		type: actions.DAY_MATCHES_UPDATE_MATCH,
	}),

	updateMatchSuccess: match => ({
		type: actions.DAY_MATCHES_UPDATE_MATCH_SUCCESS,
		data: match,
	}),

	removeMatch: id => ({
		type: actions.DAY_MATCHES_REMOVE_MATCH,
		data: id,
	}),

	removeMatchSuccess: id => ({
		type: actions.DAY_MATCHES_REMOVE_MATCH_SUCCESS,
		data: id,
	}),

	setActiveMatch: match => ({
		type: actions.DAY_MATCHES_SET_ACTIVE_MATCH,
		data: match,
	}),

	setEvent: id => ({
		type: actions.DAY_MATCHES_SET_EVENT,
		data: id,
	}),

	setEventMarket: id => ({
		type: actions.DAY_MATCHES_SET_EVENT_MARKET,
		data: id,
	}),

	fetchEventMarkets: eventId => ({
		type: actions.DAY_MATCHES_FETCH_EVENT_MARKETS,
		data: eventId,
	}),

	fetchEventMarketsSuccess: markets => ({
		type: actions.DAY_MATCHES_FETCH_EVENT_MARKETS_SUCCESS,
		data: markets,
	}),

	toggleModal: (isModalOpen, isUpdating) => ({
		type: actions.DAY_MATCHES_TOGGLE_MODAL,
		data: {
			value: isModalOpen,
			isUpdating,
		},
	}),

	toggleButtonsState: isChanged => ({
		type: actions.DAY_MATCHES_TOGGLE_BUTTONS_STATE,
		data: isChanged,
	}),

	toggleUpdating: isUpdating => ({
		type: actions.DAY_MATCHES_TOGGLE_UPDATING,
		data: isUpdating,
	}),

	setStartDate: date => ({
		type: actions.DAY_MATCHES_SET_START_DATE,
		data: date,
	}),

	setImageURL: (imageName, url) => ({
		type: actions.DAY_MATCHES_SET_IMAGE_URL,
		data: {
			imageName,
			url,
		},
	}),
};

export default actions;
