import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import EntityAdapter from '../../../helpers/entityAdapter';
import { CHANNEL_TYPES, IMAGE_LINK_TYPES } from '../../../helpers/commonConstants';
import { channelTypeList } from '../../../helpers/commonEnums';
import { createListAdapter, fields } from '../list/utils';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';

const listAdapter  = createListAdapter();
const namesAdapter = createNamesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptCasino(rawData = []) {
	listAdapter.clearExcludes();

	if (!isArray(rawData) || isEmpty(rawData)) {
		const adaptedData = listAdapter.adapt(rawData);
		return adaptedData;
	}

	const adaptedData = listAdapter.adapt(rawData[0]);

	adaptedData.type = adaptedData.logo.includes('http')
		? IMAGE_LINK_TYPES.external
		: IMAGE_LINK_TYPES.internal;

	return adaptedData;
}


export function adaptNamesData(rawData = [], defaultName = null) {

	namesAdapter.clearExcludes();

	const result = [];
	const adaptedList = namesAdapter.adaptList(rawData);

	channelTypeList.forEach( channelItem => {

		const channelID   = channelItem.id;

		const namesItem = find(adaptedList, { channelID }) || {};

		const record = {
			channelID,
			name: namesItem.name,
		};

		if (channelID === CHANNEL_TYPES.backend && !record.name && defaultName) {
			record.name = defaultName;
		}

		result.push(record);
	});

	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareCasino(baseData) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('integrator_name');
	listAdapter.addExcludeField('alias');
	listAdapter.addExcludeField('integratorName');
	listAdapter.addExcludeField('logo');
	listAdapter.addExcludeField('orderNumber');
	listAdapter.addExcludeField('type');
	listAdapter.addExcludeField('websiteID');

	return listAdapter.prepare(baseData);
}

export function prepareNamesData(rawData = [], casinoID, langID) {

	namesAdapter.clearExcludes();

	const preparedData = rawData.map( rawItem => {
		const item = namesAdapter.prepare(rawItem);

		item[fields.casinoID] = casinoID;
		item[fields.langID]   = langID;

		return item;
	});

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'casinoID', fields.casinoID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'langID', fields.langID);

	adapter.addField(rules.stringOrNull, 'name', fields.name);

	return adapter;
}

export function updateCasinoList(list = [], baseData, id, loading) {
	const clonedList = cloneDeep(list);

	const index = findIndex(clonedList, { id });

	if (index> -1) {
		const item = baseData || list[index];

		clonedList[index] = {
			...item,
			loading,
		};

		return clonedList;
	}

	return clonedList;
}
