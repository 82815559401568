import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';

export const fields = {
	id       : 'id',
	ip       : 'ip',
	date     : 'date',
	reason   : 'reason',
	userId   : 'user_id',
	websiteID: 'website_id',

	// filter
	dateFrom: 'date_from',
	dateTo  : 'date_to',
	email   : 'email',
};

const listAdapter = createListAdapter();

export function getListParams(filter, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isString, 'ip', fields.ip);
	builder.addField(rules.isID, 'userId', fields.userId);
	builder.addField(rules.isString, 'email', fields.email);

	builder.addRangeField(rules.isDateTimeRange, filter.dateRange, [
		fields.dateFrom,
		fields.dateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.isString, 'ip', fields.ip);
	adapter.addField(rules.isDate, 'data', fields.date);
	adapter.addField(rules.isString, 'reason', fields.reason);
	adapter.addField(rules.userId, 'userId', fields.userId);
	adapter.addField(rules.string, 'websiteID', fields.websiteID);

	return adapter;
}

export function prepareBlockedIp(blockedIp) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	return listAdapter.prepare(blockedIp);
}
