import EntityAdapter from '../../../helpers/entityAdapter';

const fields = {
	amount      : 'amount',
	count       : 'count',
	players     : 'players',
	statusMethod: 'status_method',
};

const arrAdapter = createArrAdapter();

export function adaptWithdrawalsData(rawData = []) {
	arrAdapter.clearExcludes();
	const adaptedData = arrAdapter.adaptList(rawData);
	return adaptedData;
}

export function createArrAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'amount', fields.amount);
	adapter.addField(rules.number, 'count', fields.count);
	adapter.addField(rules.number, 'players', fields.players);
	adapter.addField(rules.string, 'statusMethod', fields.statusMethod);

	return adapter;
}
