import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData   : {},
	namesData  : [],
	checkedList: [],

	UI: {
		visible         : false,
		editMode        : false,
		customProviderID: null,
		loading         : false,
		closeModal      : false,
		isBaseChanged   : false,
		isNamesChanged  : false,
		isAliasChanged  : false,
		isSeoChanged    : false,
		isGamesChanged  : false,
		langID          : null,
		activeTab     		: 'main',
	},
});

export default function casinoCustomProviderModalReducer(state = initState, action) {

	switch (action.type) {

		case actions.CASINO_CUSTOM_PROVIDER_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}

		case actions.CASINO_CUSTOM_PROVIDER_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.CASINO_CUSTOM_PROVIDER_GAMES_DATA_REFRESH: {
			return state.set('checkedList', action.data);
		}

		case actions.CASINO_CUSTOM_PROVIDER_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.CASINO_CUSTOM_PROVIDER_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
