const actions = {
	OFFERS_SHOW_ADDING_MODAL: 'OFFERS_SHOW_ADDING_MODAL',
	OFFERS_HIDE_ADDING_MODAL: 'OFFERS_HIDE_ADDING_MODAL',

	OFFERS_SHOW_UPDATING_MODAL: 'OFFERS_SHOW_UPDATING_MODAL',
	OFFERS_HIDE_UPDATING_MODAL: 'OFFERS_HIDE_UPDATING_MODAL',

	OFFERS_FETCH_EVENT_MARKETS        : 'OFFERS_FETCH_EVENT_MARKETS',
	OFFERS_FETCH_EVENT_MARKETS_SUCCESS: 'OFFERS_FETCH_EVENT_MARKETS_SUCCESS',
	OFFERS_SET_MARKETS_PAGE           : 'OFFERS_SET_MARKETS_PAGE',

	OFFERS_ADD_SELECTION   : 'OFFERS_ADD_SELECTION',
	OFFERS_REMOVE_SELECTION: 'OFFERS_REMOVE_SELECTION',
	OFFERS_REMOVE_EVENT    : 'OFFERS_REMOVE_EVENT',

	OFFERS_SET_CUSTOM_PRICE : 'OFFERS_SET_CUSTOM_PRICE',
	OFFERS_SET_REGULAR_PRICE: 'OFFERS_SET_REGULAR_PRICE',

	OFFERS_RESET_OFFER: 'OFFERS_RESET_OFFER',

	OFFERS_SET_SELECTIONS        : 'OFFERS_SET_SELECTIONS',
	OFFERS_SET_SELECTIONS_SUCCESS: 'OFFERS_SET_SELECTION_SUCCESS',

	OFFERS_SET_OFFER_DATE            : 'OFFERS_SET_OFFER_DATE',
	OFFERS_CHANGE_EVENT_QUERY        : 'OFFERS_CHANGE_EVENT_QUERY',
	OFFERS_CHANGE_EVENT_QUERY_BY_DATE: 'OFFERS_CHANGE_EVENT_QUERY_BY_DATE',

	OFFERS_CHANGE_CURRENT_EVENT_NAME: 'OFFERS_CHANGE_CURRENT_EVENT_NAME',

	showAddingModal: () => ({
		type: actions.OFFERS_SHOW_ADDING_MODAL,
	}),

	hideAddingModal: () => ({
		type: actions.OFFERS_HIDE_ADDING_MODAL,
	}),

	showUpdatingModal: () => ({
		type: actions.OFFERS_SHOW_UPDATING_MODAL,
	}),

	hideUpdatingModal: () => ({
		type: actions.OFFERS_HIDE_UPDATING_MODAL,
	}),

	fetchEventMarkets: eventId => ({
		type: actions.OFFERS_FETCH_EVENT_MARKETS,
		data: eventId,
	}),

	fetchEventMarketsSuccess: markets => ({
		type: actions.OFFERS_FETCH_EVENT_MARKETS_SUCCESS,
		data: markets,
	}),

	setMarketsPage: index => ({
		type: actions.OFFERS_SET_MARKETS_PAGE,
		data: index,
	}),

	addSelection: selection => ({
		type: actions.OFFERS_ADD_SELECTION,
		data: selection,
	}),

	removeSelection: id => ({
		type: actions.OFFERS_REMOVE_SELECTION,
		data: id,
	}),

	removeEvent: eventId => ({
		type: actions.OFFERS_REMOVE_EVENT,
		data: eventId,
	}),

	setCustomPrice: price => ({
		type: actions.OFFERS_SET_CUSTOM_PRICE,
		data: price,
	}),

	setSelections: offerId => ({
		type: actions.OFFERS_SET_SELECTIONS,
		data: offerId,
	}),

	setSelectionsSuccess: selections => ({
		type: actions.OFFERS_SET_SELECTIONS_SUCCESS,
		data: selections,
	}),

	resetOffer: () => ({
		type: actions.OFFERS_RESET_OFFER,
	}),

	setOfferDate: date => ({
		type: actions.OFFERS_SET_OFFER_DATE,
		data: date,
	}),

	changeEventQuery: query => ({
		type: actions.OFFERS_CHANGE_EVENT_QUERY,
		data: query,
	}),

	changeEventQueryByDate: query => ({
		type: actions.OFFERS_CHANGE_EVENT_QUERY_BY_DATE,
		data: query,
	}),

	changeCurrentEventName: eventName => ({
		type: actions.OFFERS_CHANGE_CURRENT_EVENT_NAME,
		data: {
			eventName,
		},
	}),
};

export default actions;
