import { apiRequest } from './index';

// Free Spins -------------------------------------------------------------------------------------
function freeSpinsList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/bonus/freespin',
		params,
	};

	return apiRequest(req);
}

function freeSpinCreate(userID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/users/${userID}/freespin`,
		data,
		params,
	};

	return apiRequest(req);
}

function massFreeSpinCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/users/freespin',
		data,
		params,
	};

	return apiRequest(req);
}
function freeBetCreate(userID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/users/${userID}/freebet`,
		data,
		params,
	};

	return apiRequest(req);
}
function manualBonusCreate(userID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/users/${userID}/manual_bonus`,
		data,
		params,
	};

	return apiRequest(req);
}

// BonusingSystem List --------------------------------------------------------------------
function bonusesList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/bonuses',
		params,
	};

	return apiRequest(req);
}

// Free Spins for Registartion --------------------------------------------------------------------

function registrationFreeSpinCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/bonus/registrationFreeSpin',
		data,
		params,
	};

	return apiRequest(req);
}

function registrationFreeSpinUpdate(id, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/bonus/registrationFreeSpin/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}

// deposit Free Spin

function depositFreeSpinCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/bonus/depositFreeSpin',
		data,
		params,
	};

	return apiRequest(req);
}

function depositFreeSpinUpdate(id, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/bonus/depositFreeSpin/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}


// Deposit ----------------------------------------------------------------------------------

function depositCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/bonus/deposit',
		data,
		params,
	};

	return apiRequest(req);
}

function depositUpdate(id, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/bonus/deposit/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}
// NextDeposit Free Spin---------------------------------------------------------------------

function nextDepositFreeSpinCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/bonus/nextDepositFreeSpin',
		data,
		params,
	};

	return apiRequest(req);
}

function nextDepositFreeSpinUpdate(id, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/bonus/nextDepositFreeSpin/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}

// NextDeposit ------------------------------------------------------------------------------
function nextDepositCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/bonus/nextDepositBonus',
		data,
		params,
	};

	return apiRequest(req);
}
function nextDepositUpdate(id, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/bonus/nextDepositBonus/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}


// Pack ----------------------------------------------------------------------------------
function packCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/bonus/pack',
		data,
		params,
	};

	return apiRequest(req);
}

function packUpdate(id, data, params = {}) {
	const req = {
		method: 'PUT',
		url   : `/bonus/pack/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}

// Welcome Bonus ----------------------------------------------------------------------------------
function welcomeBonusList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/bonus/welcome',
		params,
	};

	return apiRequest(req);
}

function welcomeBonusCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/bonus/welcome',
		data,
		params,
	};

	return apiRequest(req);
}

function welcomeBonusUpdate(id, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/bonus/welcome/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}

// CasinoCashback ----------------------------------------------------------------------------------
function cashbackList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/casino/cashBack',
		params,
	};

	return apiRequest(req);
}

function cashbackCreate(data) {
	const req = {
		method: 'POST',
		url   : '/casino/cashBack',
		data,
	};

	return apiRequest(req);
}

function cashbackUpdate(id, data) {
	const req = {
		method: 'PUT',
		url   : `/casino/cashBack/${id}`,
		data,
	};

	return apiRequest(req);
}

function subCashbackList(id) {
	const req = {
		method: 'GET',
		url   : `/casino/cashBack/${id}/subs`,
		params: {
			id,
		},
	};

	return apiRequest(req);
}

function cashbackDelete(id) {
	const req = {
		method: 'DELETE',
		url   : `/casino/cashBack/${id}`,
		params: {
			id,
		},
	};

	return apiRequest(req);
}

function cashbackUsersList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/casino/cashBack/users',
		params,
	};

	return apiRequest(req);
}

function cashbackUsersUpdate(id, data) {
	const req = {
		method: 'POST',
		url   : `/casino/cashBack/${id}/users`,
		data,
	};

	return apiRequest(req);
}

// ----------------------------------------------------------------------------------

function casinoBonusWallets(userID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/users/${userID}/casino/bonus/wallets`,
		params,
	};

	return apiRequest(req);
}

function casinoBonusSpins(userID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/users/${userID}/casino/bonus/spins`,
		params,
	};

	return apiRequest(req);
}

// Free Bets
function freeBetsList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/freebet',
		params,
	};

	return apiRequest(req);
}

function freeSpinCancel(data) {
	const req = {
		method: 'PUT',
		url   : '/cancel_freespin',
		data,
	};

	return apiRequest(req);
}

export const bonusesAPI = {
	freeSpinsList,
	freeSpinCreate,
	freeBetCreate,
	massFreeSpinCreate,
	manualBonusCreate,

	bonusesList,

	registrationFreeSpinCreate,
	registrationFreeSpinUpdate,

	depositFreeSpinCreate,
	depositFreeSpinUpdate,

	depositCreate,
	depositUpdate,

	nextDepositFreeSpinCreate,
	nextDepositFreeSpinUpdate,

	nextDepositCreate,
	nextDepositUpdate,

	packCreate,
	packUpdate,

	welcomeBonusList,
	welcomeBonusCreate,
	welcomeBonusUpdate,

	cashbackList,
	cashbackCreate,
	cashbackUpdate,
	subCashbackList,
	cashbackDelete,
	cashbackUsersList,
	cashbackUsersUpdate,

	casinoBonusWallets,
	casinoBonusSpins,

	freeBetsList,
	freeSpinCancel,
};
