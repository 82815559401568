import { Map } from 'immutable';
import { APP_TABS, DEFAULT_TABS } from '../../constants/appTabs';
import actions from './actions';
import { getPermissions } from '../../helpers/utility';
import { SIDEBAR_PERMISSIONS } from '../../helpers/permissions/constants';
import { isNotAnyPermissions, superAdminPermissions } from '../../helpers/permissions/utils';

const initState = new Map({
	tabs                  : DEFAULT_TABS,
	activeTabID           : APP_TABS.dashboard,
	activeUserID          : null,
	currentPagePermissions: null,
	activeTabTitle        : '',
	isSearch              : false,
});

export default function appTabsReducer(state = initState, action) {

	switch (action.type) {

		case actions.APP_TABS_REFRESH: {
			const { tabs } = action.data;
			return state.set('tabs', tabs);
		}

		case actions.APP_TABS_ACTIVE_TAB_SET: {

			const { activeTabID, activeTabTitle, isSearch } = action.data;
			const permissions = getPermissions();
			const { projectPermissions } = permissions;

			if (projectPermissions.super_admin || projectPermissions.platform_admin) {
				return state.set('activeTabID', activeTabID)
					.set('activeTabTitle', activeTabTitle)
					.set('currentPagePermissions', superAdminPermissions)
					.set('isSearch', isSearch);
			}

			const sidebarItem = SIDEBAR_PERMISSIONS[activeTabID];
			const currentPagePermissions = projectPermissions[sidebarItem];

			return state.set('activeTabID', activeTabID)
				.set('activeTabTitle', activeTabTitle)
				.set('currentPagePermissions', currentPagePermissions || isNotAnyPermissions);
		}

		case actions.APP_TABS_ACTIVE_USER_ID_SET: {
			const { userID } = action.data;
			return state.set('activeUserID', userID);
		}

		default:
			return state;
	}
}
