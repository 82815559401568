const actions = {

	DASHBOARD_DEPOSITS_DATA_REFRESH: 'DASHBOARD_DEPOSITS_DATA_REFRESH',
	DASHBOARD_DEPOSITS_DATA_RELOAD : 'DASHBOARD_DEPOSITS_DATA_RELOAD',
	DASHBOARD_DEPOSITS_UI_REFRESH  : 'DASHBOARD_DEPOSITS_UI_REFRESH',

	depositsDataRefresh: data => ({
		type: actions.DASHBOARD_DEPOSITS_DATA_REFRESH,
		data,
	}),
	depositsDataReload: () => ({
		type: actions.DASHBOARD_DEPOSITS_DATA_RELOAD,
	}),
	depositsUIRefresh: data => ({
		type: actions.DASHBOARD_DEPOSITS_UI_REFRESH,
		data,
	}),

};

export default actions;
