import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import { adaptRisk } from './utils';

function getStoreData({ AppTabs, RTM }) {
	return {
		isRTMRisks     : Boolean( find(AppTabs.get('tabs'), { id: 'rtm/risk-alerts' }) ),
		riskIDs        : RTM.RiskManagement.get('riskIDs'),
		entities       : RTM.RiskManagement.get('entities'),
		newRiskIDs     : RTM.RiskManagement.get('newRiskIDs'),
		newRiskEntities: RTM.RiskManagement.get('newRiskEntities'),
	};
}

function* onNotifyCreateRisk() {

	yield takeEvery(actions.RTM_RISK_MANAGEMENT_SOCKET_NOTIFY_CREATE_RISK, function* (action) {

		const { data : rawRisk  } = action.data;

		const riskID = toInteger(rawRisk.id);
		if (!riskID) {
			return;
		}

		const storeData = yield select(getStoreData);

		const { isRTMRisks }  = storeData;
		const riskIDs         = cloneDeep(storeData.riskIDs);
		const entities        = cloneDeep(storeData.entities);
		const newRiskIDs      = cloneDeep(storeData.newRiskIDs);
		const newRiskEntities = cloneDeep(storeData.newRiskEntities);

		const adaptedRisk     = adaptRisk(rawRisk);
		newRiskEntities[riskID] = adaptedRisk;
		newRiskIDs.unshift(riskID);

		if (isRTMRisks) {
			if (!adaptedRisk) {
				return false;
			}
			entities[riskID] = adaptedRisk;
			riskIDs.unshift(riskID);
		}

		yield put(actions.dataRefresh(riskIDs, entities));
		yield put(actions.newRiskListRefresh(newRiskIDs, newRiskEntities));

		// let toSubscribeIDs = cloneDeep(riskIDs);
		// toSubscribeIDs.push(riskID);
		// toSubscribeIDs = uniq(toSubscribeIDs);

		// yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}

function* onNotifyUpdateRisk() {

	yield takeEvery(actions.RTM_RISK_MANAGEMENT_SOCKET_NOTIFY_UPDATE_RISK, function* (action) {

		const rawRisk = action.data;
		const riskID = toInteger(rawRisk.data.id);
		if (!riskID) {
			return;
		}
		const storeData     = yield select(getStoreData);
		const riskIDs       = cloneDeep(storeData.riskIDs);
		const entities      = cloneDeep(storeData.entities);
		const riskEntity    = entities[riskID];

		if (!riskEntity) {
			return;
		}
		riskEntity.statusId = rawRisk.data.resolution_status;
		entities[riskID] = riskEntity;

		yield put(actions.dataRefresh(riskIDs, entities));
	});
}

export default function* rtmRisksExternalSaga() {
	yield all([
		fork(onNotifyCreateRisk),
		fork(onNotifyUpdateRisk),
	]);
}
