const prefix = 'SMS_TEMPLATES_';

export const actions = {

	RELOAD              : `${prefix}RELOAD`,
	SMS_VARIABLES_RELOAD: `${prefix}SMS_VARIABLES_RELOAD`,
	LIST_REFRESH        : `${prefix}LIST_REFRESH`,
	VARIABLES_REFRESH   : `${prefix}VARIABLES_REFRESH`,
	ENTITY_REFRESH      : `${prefix}ENTITY_REFRESH`,

	GET_BY_ID: `${prefix}GET_BY_ID`,
	UPDATE   : `${prefix}UPDATE`,

	DELETE_BY_ID: `${prefix}DELETE_BY_ID`,

	BASE_DATA_REFRESH: `${prefix}BASE_DATA_REFRESH`,
	UI_REFRESH       : `${prefix}UI_REFRESH`,

	DATA_SAVE      : `${prefix}DATA_SAVE`,
	DATA_UPDATE    : `${prefix}DATA_UPDATE`,
	DATA_RESET     : `${prefix}DATA_RESET`,
	BASE_DATA_RESET: `${prefix}BASE_DATA_RESET`,

	SEND_TEST_MASSAGE: `${prefix}SEND_TEST_MASSAGE`,

	listReload: data => ({
		type: actions.RELOAD,
		data,
	}),

	smsVariablesReload: data => ({
		type: actions.SMS_VARIABLES_RELOAD,
		data,
	}),

	getTemplateByID: data => ({
		type: actions.GET_BY_ID,
		data,
	}),

	deleteById: data => ({
		type: actions.DELETE_BY_ID,
		data,
	}),

	listRefresh: data => ({
		type: actions.LIST_REFRESH,
		data,
	}),

	variablesRefresh: data => ({
		type: actions.VARIABLES_REFRESH,
		data,
	}),

	entityRefresh: data => ({
		type: actions.ENTITY_REFRESH,
		data,
	}),

	baseDataRefresh: data => ({
		type: actions.BASE_DATA_REFRESH,
		data,
	}),

	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),

	dataSave: (closeModal, cb ) => ({
		type: actions.DATA_SAVE,
		data: {
			closeModal,
			cb,
		},
	}),

	dataUpdate: (closeModal, cb) => ({
		type: actions.DATA_UPDATE,
		data: {
			closeModal,
			cb,
		},
	}),

	dataReset: () => ({
		type: actions.DATA_RESET,
	}),
	baseDataReset: () => ({
		type: actions.BASE_DATA_RESET,
	}),

	testMessageActon: data => ({
		type: actions.SEND_TEST_MASSAGE,
		data,
	}),

};
