import { fork, all } from 'redux-saga/effects';
import tablesSaga from './tables/saga';
import headerSaga from './header/saga';
import footerSaga from './footer/saga';
import articlesSaga from './articles/saga';
// import promotions from '../pages/promotions/saga';
// import helpCenter from '../pages/helpCenter/saga';
// import landingPageGameGrid from '../landingPage/gameGrid/saga';

export default function* contentSaga() {
	yield all([
		fork(tablesSaga),
		fork(headerSaga),
		fork(footerSaga),
		fork(articlesSaga),
		// fork(promotions),
		// fork(helpCenter),
		// fork(landingPageGameGrid),
	]);
}
