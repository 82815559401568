const actions = {

	LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_INTEGRATOR_PROVIDER_RELOAD : 'LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_INTEGRATOR_PROVIDER_RELOAD',
	LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_INTEGRATOR_PROVIDER_REFRESH: 'LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_INTEGRATOR_PROVIDER_REFRESH',

	LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_GAMES_RELOAD : 'LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_GAMES_RELOAD',
	LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_GAMES_REFRESH: 'LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_GAMES_REFRESH',

	LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_PROVIDER_REFRESH: 'LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_PROVIDER_REFRESH',

	LIMIT_AND_RESTRICTION_CASINO_LIMITS_REFRESH: 'LIMIT_AND_RESTRICTION_CASINO_LIMITS_REFRESH',

	LIMIT_AND_RESTRICTION_CASINO_LIMIT_SAVE_RELOAD: 'LIMIT_AND_RESTRICTION_CASINO_LIMIT_SAVE_RELOAD',

	LIMIT_AND_RESTRICTION_CASINO_UI_REFRESH: 'LIMIT_AND_RESTRICTION_CASINO_UI_REFRESH',

	treeIntProvGamLimitsReload: ({ userID }) => ({
		type: actions.LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_INTEGRATOR_PROVIDER_RELOAD,
		data: {
			userID,
		},
	}),

	treeIntProvGamLimitsRefresh: data => ({
		type: actions.LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_INTEGRATOR_PROVIDER_REFRESH,
		data,
	}),

	providersWlimitsObjectRefresh: data => ({
		type: actions.LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_PROVIDER_REFRESH,
		data,
	}),

	gamesLimitsRefresh: data => ({
		type: actions.LIMIT_AND_RESTRICTION_CASINO_LIMITS_REFRESH,
		data,
	}),

	treeGamesReload: providerID => ({
		type: actions.LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_GAMES_RELOAD,
		data: {
			providerID,
		},
	}),

	saveLimits: (userID, typeID, limitsObject, serviceID, providerID) => ({
		type: actions.LIMIT_AND_RESTRICTION_CASINO_LIMIT_SAVE_RELOAD,
		data: {
			userID,
			typeID,
			limitsObject,
			serviceID,
			providerID,
		},
	}),

	uiRefresh: data => ({
		type: actions.LIMIT_AND_RESTRICTION_CASINO_UI_REFRESH,
		data,
	}),
};

export default actions;
