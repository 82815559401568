import EntityAdapter from '../../../../helpers/entityAdapter';
import { IResponsibleAdapted, IResponsibleEntities, IResponsibleResponse } from './types';

export const fields = {
	limitationID: 'id',
	userID      : 'user_id',
	statusID    : 'status_id',
	type        : 'type_id',
	duration    : 'period',
	endDate     : 'end',
	startDate   : 'start',
	value       : 'value',
	categoryName: 'category_name',
	loading     : 'loading',
};

const limitsAdapter = createResponsibleGamblingAdapter();

export const adaptResponsibleGambling = (data: IResponsibleResponse[] = []): { list: IResponsibleAdapted[]; entities: IResponsibleEntities} => {
	const list = limitsAdapter.adaptList(data) as IResponsibleAdapted[];
	const entities = list.reduce<IResponsibleEntities>((acc, entity) => {
		acc[entity.limitationID] = entity;
		return acc;
	}, {});

	return  {
		list,
		entities,
	};
};

export function createResponsibleGamblingAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES as { [key: string]: string };

	adapter.addField(rules.id, 'limitationID', fields.limitationID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'type', fields.type);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.string, 'duration', fields.duration);
	adapter.addField(rules.string, 'startDate', fields.startDate);
	adapter.addField(rules.string, 'endDate', fields.endDate);
	adapter.addField(rules.string, 'value', fields.value);
	adapter.addField(rules.bool, 'loading', fields.loading);
	adapter.addField(rules.string, 'categoryName', fields.categoryName);

	return adapter;
}


export const toEntity = <T>(list: T[], key: string) => {
	return list.reduce((acc,item) => {
		acc[item[key]] = item;
		return acc;
	}, {});
};
