
const actions = {
	WEBSITE_DATA_RESET				: 'WEBSITE_DATA_RESET',
	RESET_WEBSITE_DATA_GET: 'RESET_WEBSITE_DATA_GET',

	WEBSITE_BASE_DATA_RELOAD	: 'WEBSITE_BASE_DATA_RELOAD',
	WEBSITE_BASE_DATA_REFRESH: 'WEBSITE_BASE_DATA_REFRESH',

	WEBSITE_BASE_DATA_CDN_ID: 'WEBSITE_BASE_DATA_CDN_ID',
	SET_ACTIVE_TAB   							: 'SET_ACTIVE_TAB',

	WEBSITE_ADDITIONAL_DOMAINS_RELOAD      		: 'WEBSITE_ADDITIONAL_DOMAINS_RELOAD',
	WEBSITE_ADDITIONAL_DOMAINS_REFRESH     		: 'WEBSITE_ADDITIONAL_DOMAINS_REFRESH',
	WEBSITE_NEW_ADDITIONAL_DOMAINS_CREATE   	: 'WEBSITE_NEW_ADDITIONAL_DOMAINS_CREATE',
	WEBSITE_ADDITIONAL_DOMAIN_SAVE_AND_DELETE: 'WEBSITE_ADDITIONAL_DOMAIN_SAVE_AND_DELETE',

	WEBSITE_MAILING_LIST_RELOAD: 'WEBSITE_MAILING_LIST_RELOAD',
	WEBSITE_MAILING_LIST  					: 'WEBSITE_MAILING_LIST',

	WEBSITE_CASINO_DATA_RELOAD	: 'WEBSITE_CASINO_DATA_RELOAD',
	WEBSITE_CASINO_DATA_REFRESH: 'WEBSITE_CASINO_DATA_REFRESH',

	WEBSITE_CASINO_SAVE		: 'WEBSITE_CASINO_SAVE',
	WEBSITE_CASINO_DELETE: 'WEBSITE_CASINO_DELETE',

	WEBSITE_CURRENCIES_RELOAD	: 'WEBSITE_CURRENCIES_RELOAD',
	WEBSITE_CURRENCIES_REFRESH: 'WEBSITE_CURRENCIES_REFRESH',
	WEBSITE_CURRENCY_ADD  				: 'WEBSITE_CURRENCY_ADD',
	WEBSITE_CURRENCY_SAVE 				: 'WEBSITE_CURRENCY_SAVE',
	WEBSITE_CURRENCY_DELETE			: 'WEBSITE_CURRENCY_DELETE',

	WEBSITE_SAVE      					: 'WEBSITE_SAVE',
	WEBSITE_UI_REFRESH					: 'WEBSITE_UI_REFRESH',
	WEBSITE_SUSPEND_BETTING: 'WEBSITE_SUSPEND_BETTING',

	WEBSITE_SEND_TEST_EMAIL: 'WEBSITE_SEND_TEST_EMAIL',

	WEBSITE_ALL_COUNTRY_LIST_RELOAD	: 'WEBSITE_ALL_COUNTRY_LIST_RELOAD',
	WEBSITE_ALL_COUNTRY_LIST_REFRESH: 'WEBSITE_ALL_COUNTRY_LIST_REFRESH',
	WEBSITE_COUNTRY_LIST_RELOAD  			: 'WEBSITE_COUNTRY_LIST_RELOAD',
	WEBSITE_COUNTRY_LIST_REFRESH 			: 'WEBSITE_COUNTRY_LIST_REFRESH',
	WEBSITE_COUNTRY_SAVE       					: 'WEBSITE_COUNTRY_SAVE',
	WEBSITE_COUNTRY_DELETE     					: 'WEBSITE_COUNTRY_DELETE',

	WEBSITE_ALL_LANGUAGES_LIST_RELOAD	: 'WEBSITE_ALL_LANGUAGES_LIST_RELOAD',
	WEBSITE_ALL_LANGUAGES_LIST_REFRESH: 'WEBSITE_ALL_LANGUAGES_LIST_REFRESH',
	WEBSITE_LANGUAGE_LIST_RELOAD   			: 'WEBSITE_LANGUAGE_LIST_RELOAD',
	WEBSITE_LANGUAGE_LIST_REFRESH  			: 'WEBSITE_LANGUAGE_LIST_REFRESH',
	WEBSITE_LANGUAGE_SAVE        					: 'WEBSITE_LANGUAGE_SAVE',
	WEBSITE_LANGUAGE_DELETE      					: 'WEBSITE_LANGUAGE_DELETE',

	WEBSITE_CHANGED_FIELDS_REFRESH  : 'WEBSITE_CHANGED_FIELDS_REFRESH',
	WEBSITE_GET_SEO          							: 'WEBSITE_GET_SEO',
	WEBSITE_SEO_DATA_REFRESH    				: 'WEBSITE_SEO_DATA_REFRESH',
	WEBSITE_SEO_DATA_SAVE      					: 'WEBSITE_SEO_DATA_SAVE',
	PARTNER_SCOPE_CURRENCIES_REFRESH: 'PARTNER_SCOPE_CURRENCIES_REFRESH',
	
	dataReset: () => ({
		type: actions.WEBSITE_DATA_RESET,
	}),

	getDataReset: () => ({
		type: actions.RESET_WEBSITE_DATA_GET,
	}),

	baseDataReload: websiteID => ({
		type: actions.WEBSITE_BASE_DATA_RELOAD,
		data: {
			websiteID,
		},
	}),
	baseDataRefresh: (data, currentTab) => ({
		type: actions.WEBSITE_BASE_DATA_REFRESH,
		data,
		currentTab,
	}),


	mailingListSet: mailingList => {
		return {
			type: actions.WEBSITE_MAILING_LIST,
			data: {
				mailingList,
			},
		};
	},
	mailingListReload: websiteID => ({
		type: actions.WEBSITE_MAILING_LIST_RELOAD,
		data: {
			websiteID,
		},
	}),
	sendTestEmail: data => ({
		type: actions.WEBSITE_SEND_TEST_EMAIL,
		data,
	}),

	casinoReload: websiteID => ({
		type: actions.WEBSITE_CASINO_DATA_RELOAD,
		data: {
			websiteID,
		},
	}),
	casinoRefresh: data => ({
		type: actions.WEBSITE_CASINO_DATA_REFRESH,
		data,
	}),
	casinoSave: (recordID, casinoID) => ({
		type: actions.WEBSITE_CASINO_SAVE,
		data: {
			recordID,
			casinoID,
		},
	}),
	casinoDelete: casinoID => ({
		type: actions.WEBSITE_CASINO_DELETE,
		data: {
			casinoID,
		},
	}),

	currenciesReload: websiteID => ({
		type: actions.WEBSITE_CURRENCIES_RELOAD,
		data: {
			websiteID,
		},
	}),
	currenciesResresh: entities => ({
		type: actions.WEBSITE_CURRENCIES_REFRESH,
		data: entities,
	}),
	currencyAdd: data => ({
		type: actions.WEBSITE_CURRENCY_ADD,
		data,
	}),
	currencySave: data => ({
		type: actions.WEBSITE_CURRENCY_SAVE,
		data,
	}),
	currencyDelete: (websiteID, currencyID) => ({
		type: actions.WEBSITE_CURRENCY_DELETE,
		data: {
			websiteID,
			currencyID,
		},
	}),
	dataSave: (data, activeTab) => ({
		type: actions.WEBSITE_SAVE,
		data,
		activeTab,
	}),
	uiRefresh: data => ({
		type: actions.WEBSITE_UI_REFRESH,
		data,
	}),
	changedFields: data => ({
		type: actions.WEBSITE_CHANGED_FIELDS_REFRESH,
		data,
	}),
	setActiveTab: data => ({
		type: actions.SET_ACTIVE_TAB,
		data,
	}),
	setCdnId: data => ({
		type: actions.WEBSITE_BASE_DATA_CDN_ID,
		data,
	}),
	additionalDomainsReload: websiteID => ({
		type: actions.WEBSITE_ADDITIONAL_DOMAINS_RELOAD,
		data: {
			websiteID,
		},
	}),
	additionalDomainsRefresh: data => ({
		type: actions.WEBSITE_ADDITIONAL_DOMAINS_REFRESH,
		data,
	}),
	additionalDomainsCreate: websiteID => ({
		type: actions.WEBSITE_NEW_ADDITIONAL_DOMAINS_CREATE,
		data: {
			websiteID,
		},
	}),
	additionalDomainSaveDelete: (websiteID, domainInfo, save) => ({
		type: actions.WEBSITE_ADDITIONAL_DOMAIN_SAVE_AND_DELETE,
		data: {
			websiteID,
			domainInfo,
			save,
		},
	}),
	countryListReload: data => ({
		type: actions.WEBSITE_COUNTRY_LIST_RELOAD,
		data,
	}),
	saveCountry: data => ({
		type: actions.WEBSITE_COUNTRY_SAVE,
		data,
	}),
	countryListRefresh: data => ({
		type: actions.WEBSITE_COUNTRY_LIST_REFRESH,
		data,
	}),
	languageListReload: data => ({
		type: actions.WEBSITE_LANGUAGE_LIST_RELOAD,
		data,
	}),
	saveLanguage: data => {
		return {
			type: actions.WEBSITE_LANGUAGE_SAVE,
			data,
		};
	},
	languageDelete: data => {
		return {
			type: actions.WEBSITE_LANGUAGE_DELETE,
			data,
		};
	},
	countryDelete: data => {
		return {
			type: actions.WEBSITE_COUNTRY_DELETE,
			data,
		};
	},
	languageListRefresh: data => ({
		type: actions.WEBSITE_LANGUAGE_LIST_REFRESH,
		data,
	}),
	getAllLanguages: data => ({
		type: actions.WEBSITE_ALL_LANGUAGES_LIST_RELOAD,
		data,
	}),
	getAllCountries: data => ({
		type: actions.WEBSITE_ALL_COUNTRY_LIST_RELOAD,
		data,
	}),
	allLanguageListRefresh: data => ({
		type: actions.WEBSITE_ALL_LANGUAGES_LIST_REFRESH,
		data,
	}),

	allCountriesListRefresh: data => ({
		type: actions.WEBSITE_ALL_COUNTRY_LIST_REFRESH,
		data,
	}),
	getSeo: websiteID => ({
		type: actions.WEBSITE_GET_SEO,
		websiteID,
	}),
	seoDataRefresh: data => ({
		type: actions.WEBSITE_SEO_DATA_REFRESH,
		data,
	}),
	seoDataSave: websiteID => ({
		type: actions.WEBSITE_SEO_DATA_SAVE,
		websiteID,
	}),
	partnerScopeCurrenciesRefresh: data => ({
		type: actions.PARTNER_SCOPE_CURRENCIES_REFRESH,
		data,
	}),
};

export default actions;
