import { all, takeEvery, call, put, select, fork } from 'redux-saga/effects';
import actions from './actions';
import { blockedIpsAPI } from '../../../../../helpers/api/blockedIps';
import notifications from '../../../../../helpers/notifications';
import { getListParams } from '../utils';
import { getListParamsDelete } from './utils';
import { getHeadersTotalCount } from '../../../../../helpers/utils';
import { logger } from '../../../../../helpers/logger';

const prefix = 'security.blockedIps.list';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
	successDelete  : `${prefix}.successDelete`,
	errorDelete    : `${prefix}.errorDelete`,
};

function getStoreData({ Settings, App }) {
	const  websiteID  = App.get('websiteID');

	return {
		filter    : Settings.Security.BlockedIPs.List.get('filter'),
		pagination: Settings.Security.BlockedIPs.List.get('pagination'),
		websiteID,
	};
}

function* listReload() {

	yield takeEvery(actions.BLOCKED_IPS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, pagination } = yield select(getStoreData);
		const params = getListParams(filter, pagination);

		try {
			const res = yield call(blockedIpsAPI.blockedIpsList, params);
			if (res && res.status === 200) {
				const list = res.data.data;
				const totalCount = getHeadersTotalCount(res.headers) || list.length;

				yield put(actions.listRefresh(list));
				yield put(actions.paginationRefresh({ totalCount }));
			}
		} catch (error) {
			notifications.showError(messages.errorListReload);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* deleteItem() {

	yield takeEvery(actions.BLOCKED_IPS_LIST_DELETE_ITEM, function* (action) {

		const { websiteID } = yield select(getStoreData);

		const { blockedIp } = action.data;
		const params = {
			websiteID,
		};
		const preparedParams = getListParamsDelete(params);
		try {
			const res = yield call(blockedIpsAPI.blockedIpDelete, blockedIp, preparedParams);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successDelete);
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorDelete);
			logger.log(error);
		}
	});
}

export default function* listSaga() {
	yield all([
		fork(listReload),
		fork(deleteItem),
	]);
}
