import { combineReducers } from 'redux';

import List from './list/reducer';
import Banner from './banner/reducer';
import Groups from './groups/reducer';
import BannerGroup from './bannerGroup/reducer';

export default combineReducers({
	List,
	Banner,
	Groups,
	BannerGroup,
});
