import styled from 'styled-components';

export const Wrapper = styled.div`
  display     : flex;
  align-items : center;
`;

export const IconHolder = styled.span`
  margin-top: -2px;
  margin-right: 4px;
`;
