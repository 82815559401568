import { fork, all } from 'redux-saga/effects';
import modalLSTSaga from './modal/saga';
import filterLSTSaga from './filter/saga';

export default function* liveStatisticTypesRootSaga() {
	yield all([
		fork(filterLSTSaga),
		fork(modalLSTSaga),
	]);
}
