import { apiRequest } from './index';

function transactionsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/transactions',
		withoutLangID: true,
		params,
	};

	return apiRequest(req );
}

function reportsListByCSV(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/transactions/csv',
		withoutLangID: true,
		params,
	};

	return apiRequest(req );
}

export const transactionsAPI = {
	transactionsList,
	reportsListByCSV,
};
