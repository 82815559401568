import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData: {},

	UI: {
		visible   : false,
		groupID   : null,
		loading   : false,
		loadEntity: false,
		closeModal: false,
		editMode  : false,
		isChanged : false,
	},
});

export default function bannerGroupModalReducer(state = initState, action) {

	switch (action.type) {

		case actions.BANNER_GROUP_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}

		case actions.BANNER_GROUP_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.BANNER_GROUP_DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
