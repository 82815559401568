import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../helpers/utils';

const initState = new Map({
	list      : [],
	editedDate: {
		eventID: null,
		date   : null,
	},
	UI: {
		loading: false,
	},
});

export default function eventsListReducer(state = initState, { type, data }) {

	switch (type) {

		case actions.EVENTS_LIST_REFRESH: {
			return state.set('list', data);
		}

		case actions.EVENTS_LIST_EDITED_DATE_REFRESH: {
			return state.set('editedDate', data);
		}

		case actions.EVENT_UPDATE_EVENT_PRIORITY_SUCCESS: {
			const events = state.get('list');
			const index = events.findIndex(event => event.id === data.id);
			events[index].home_priority_type_id = data.priority;

			return state.set('list', events);
		}

		case actions.EVENTS_LIST_EVENT_STATUS_UPDATE_SUCCESS: {
			const events = [...state.get('list')];
			const index = events.findIndex(event => event.id === data.eventID);
			events[index] = { ...events[index], statusID: data.statusID };

			return state.set('list', events);
		}

		case actions.UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
