import isArray from 'lodash/isArray';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';

export const fields = {
	id        : 'id',
	providerID: 'provider_id',
	name      : 'provider_participant_name',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareList(rawList = []) {
	if (!isArray(rawList)) {
		return [];
	}

	const result = rawList.map(item => {
		const resItem = {
			provider_id: item.providerID,
		};
		if (isID(item.id)) {
			resItem.id = item.id;
		}

		return resItem;
	});

	return {
		maps: result,
	};
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'providerID', fields.providerID);

	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}
