import { combineReducers } from 'redux';

import Sport from './sport/reducer';
import Casino from './casino/reducer';
import LimitsPayment from './userLimitsPayment/reducer';

export default combineReducers({
	Sport,
	Casino,
	LimitsPayment,
});
