export const activeTabIDs = [
	'rtm/bets',
	'rtm/deposits',
	'rtm/withdrawals',
	'rtm/casino',
	'rtm/documents',
	'rtm/risk-alerts',
	'riskManagement/bets',
];

export const initTabWidths = {
	min: 50,
	max: 100,
};
