import { all, fork } from 'redux-saga/effects';

import paywaysSaga from './payways/saga';
import paywaySaga from './payway/saga';

export default function* combinePaywaysSaga() {
	yield all([
		fork(paywaysSaga),
		fork(paywaySaga),
	]);
}
