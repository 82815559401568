import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({

	list  : [],
	filter: createFilter(filtersURL.freeSpins, initFilterTypes),

	UI: { loading: false },
});

export default function freeSpinsListReducer(state = initState, action) {

	switch (action.type) {

		case actions.BONUSES_FREE_SPINS_LIST_REFRESH: {
			const { list } = action.data;
			return state.set('list', list);
		}

		case actions.BONUSES_FREE_SPINS_LIST_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.BONUSES_FREE_SPINS_UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}

		case actions.BONUSES_FREE_SPINS_LIST_FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
