export const userAllTransactionsActions = {
	USER_ALL_TRANSACTION_LIST_RELOAD: 'USER_ALL_TRANSACTION_LIST_RELOAD',
	OPEN_MODAL                      : 'OPEN_MODAL',

	USER_ALL_TRANSACTIONS_REPORT_DATA_RELOAD: 'USER_ALL_TRANSACTIONS_REPORT_DATA_RELOAD',

	userAllTransactionsListReload: () => (
		{
			type: userAllTransactionsActions.USER_ALL_TRANSACTION_LIST_RELOAD,
		}
	),
	userAllTransactionsOpenModal: (id:number) => (
		{
			type: userAllTransactionsActions.OPEN_MODAL,
			id,
		}
	),
	userReportDataReload: (userID: number | string) => ({
		type: userAllTransactionsActions.USER_ALL_TRANSACTIONS_REPORT_DATA_RELOAD,
		userID,
	}),

};
