import { makeActionCreator } from '../../../helpers';

const prefix = 'PARTICIPANT_MAPPING';

const TYPES = {
	INNER_LIST_RELOAD : `${prefix}_INNER_LIST_RELOAD`,
	INNER_LIST_REFRESH: `${prefix}_INNER_LIST_REFRESH`,
	INNER_LIST_SAVE   : `${prefix}_INNER_LIST_SAVE`,
	OUTER_LIST_RELOAD : `${prefix}_OUTER_LIST_RELOAD`,
	OUTER_LIST_REFRESH: `${prefix}_OUTER_LIST_REFRESH`,
	UI_REFRESH        : `${prefix}_UI_REFRESH`,
	DATA_RESET        : `${prefix}_DATA_RESET`,
};

const actions = {
	...TYPES,

	innerListReload : makeActionCreator(TYPES.INNER_LIST_RELOAD, 'participantID'),
	innerListRefresh: makeActionCreator(TYPES.INNER_LIST_REFRESH, 'innerList'),
	innerListSave   : makeActionCreator(TYPES.INNER_LIST_SAVE),
	outerListReload : makeActionCreator(TYPES.OUTER_LIST_RELOAD, 'sportID', 'providerID'),
	outerListRefresh: makeActionCreator(TYPES.OUTER_LIST_REFRESH, 'outerList'),
	uiRefresh       : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
	dataReset       : makeActionCreator(TYPES.DATA_RESET),
};

export default actions;
