import { all, call, takeEvery, put, fork, select } from 'redux-saga/effects';
import { actions } from './actions';
import { showError, showSuccess } from '../../../../../../helpers/notifications';
import { usersAPI } from '../../../../../../helpers/api/users';
import { adaptCategories, updateCategoryItem } from './utils';
import { casinoAPI } from '../../../../../../helpers/api/casino';
import { prepareCategoryData } from '../customProvider/utils';
import { LIMITS_CASINO_OPTIONS_TYPES } from '../../../../../../constants/users';
import { logger } from '../../../../../../helpers/logger';

const prefix = 'users.limits.category';

const messages = {
	listReload    : `${prefix}.listReload`,
	dataSaveFailed: `${prefix}.dataSaveFailed`,
	dataSaved     : `${prefix}.dataSaved`,
};

const getStore = ({ Users }) => {
	const { associativeList, list } = Users.UserInfo.UserLimitsAndRestrictions.Casino.Categories.get('baseData');
	return {
		associativeList,
		list,
	};
};

function* categoryListReload() {
	yield takeEvery(actions.RESTRICT_BY_CATEGORY_RELOAD, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield all([
				call(casinoAPI.gameCategoryList),
				call(usersAPI.userLimitsCasinoSelect, data),
			]);

			const successStatus = res.every(item => item && item.status === 200);

			const [categoriesResponse, categoryLimitsResponse] = res;

			if (successStatus) {
				const {
					list,
					associativeList,
				} = adaptCategories(categoriesResponse.data.data, categoryLimitsResponse.data.data, data);
				yield put(actions.categoriesListRefresh({ list, associativeList }));
			}
			yield put(actions.uiRefresh({ loading: false }));

		} catch (e) {
			showError(messages.listReload, e);
			yield put(actions.uiRefresh({ loading: false }));
			showError(messages.listReload);
			logger.log(e);
		}
	});
}


function* dataSave() {
	yield takeEvery(actions.RESTRICT_BY_CATEGORY_SAVE, function* ({ data }) {
		const typeID                    = LIMITS_CASINO_OPTIONS_TYPES.category;
		data.typeID                     = typeID;
		const { categoryID, userID }    = data;
		const { associativeList, list } = yield select(getStore);
		const row                       = associativeList[categoryID];

		const updatedList = updateCategoryItem(list, categoryID, true, false);
		yield put(actions.categoriesListRefresh({ list: updatedList }));

		const preparedData = prepareCategoryData(row);

		try {
			const res = yield call(usersAPI.userLimitsCasinoUpdate, preparedData, { userID, type: typeID });

			if (res && res.status === 200) {
				const updatedList = updateCategoryItem(list, categoryID, false, false);
				yield put(actions.categoriesListRefresh({ list: updatedList }));
				showSuccess(messages.dataSaved);
			}
		} catch (e) {
			showError(messages.dataSaveFailed);
			logger.log(e);
			const updatedList = updateCategoryItem(list, categoryID, false, true);
			yield put(actions.categoriesListRefresh({ list: updatedList }));
		}
	});
}


export default function* restrictByCategorySaga() {
	yield all([
		fork(categoryListReload),
		fork(dataSave),
	]);
}
