import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { showError } from '../../../../helpers/notifications';
import { historyAPI } from '../../../../helpers/api/history';
import { PARTICIPANT_LOG_MODELS } from '../../../../constants/participants';

import actions from './actions';
import { adaptData, adaptAllData } from './utils';

const prefix = 'participants.participantHistory';

const messages = {
	loadData: `${prefix}.loadData`,
};

function* dataReload() {

	yield takeEvery(actions.DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		let data = [];
		const { participantID, logModel } = action.data;

		if (logModel === PARTICIPANT_LOG_MODELS.all) {
			data = yield allDataReload(participantID);
		} else {
			data = yield modelDataReload(participantID, logModel);
		}

		yield put(actions.dataRefresh(data, logModel));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* modelDataReload(participantID, logModel) {
	let data = [];
	let res  = null;

	try {
		if (logModel === PARTICIPANT_LOG_MODELS.translation) {
			res = yield call(historyAPI.historyParticipantTranslations, participantID);
		}

		if (res && res.status === 200) {
			data = adaptData(res.data.data, logModel);
		}
	} catch (error) {
		showError(messages.loadData, error);
	}

	return data;
}

function* allDataReload(participantID) {

	const translation = yield modelDataReload(participantID, PARTICIPANT_LOG_MODELS.translation);

	const allData = adaptAllData({
		translation,
	});

	return allData;
}

export default function* participantHistorySaga() {
	yield all([
		fork(dataReload),
	]);
}
