import isArray from 'lodash/isArray';

import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { createEntities } from '../../../utility';

export const fields = {
	id                        : 'id',                            // number
	expressMaxCombinationCount: 'express_max_combination_count', // number
	systemMaxCombinationCount : 'system_max_combination_count',  // number
	systemStakePerCombination : 'system_stake_per_combination',  // number
	minStake                  : 'min_stake',                     // number
	maxStake                  : 'max_stake',                     // number
	currencyID                : 'currency_id',                   // number
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------
export function adaptRulesList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return createEntities(adaptedData);
}

export function adaptRule(rawData = {}) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareRule(baseData) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		preparedData.id = baseData.id;
	}

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);

	adapter.addField(rules.positiveNumber, 'expressMaxCombinationCount', fields.expressMaxCombinationCount);
	adapter.addField(rules.positiveNumber, 'systemMaxCombinationCount', fields.systemMaxCombinationCount);
	adapter.addField(rules.positiveNumber, 'systemStakePerCombination', fields.systemStakePerCombination);
	adapter.addField(rules.positiveNumber, 'minStake', fields.minStake);
	adapter.addField(rules.positiveNumber, 'maxStake', fields.maxStake);

	return adapter;
}
