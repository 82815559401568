import { createSlice, Draft, PayloadAction, createSelector } from '@reduxjs/toolkit';
import {
	IProviderListEntities,
	IProviderMainEntities,
	IProviderMainTabEntities,
	IProviderNamesEntities,
	IProviderSeoEntities,
	IProvidersInitState,
	IProvidersList,
	TProviderUIPartial,
} from './types';
import { RootState } from '../../../store';
import { values } from 'lodash';
import { fill } from '../../../../helpers/utils';


const initialState: IProvidersInitState = {
	providers: [],
	entities : {},
	tabMain  : {},
	namesData: {},
	seoData  : {},
	mainData : {},
	UI       : {
		loading         : false,
		listLoading     : false,
		baseChanged     : [],
		seoBaseChanged  : [],
		namesBaseChanged: [],
	},
};

const providerSlice = createSlice({
	name    : 'provider',
	initialState,
	reducers: {
		providersListRefresh: (state: Draft<IProvidersInitState>, action: PayloadAction<IProvidersList[]>) => {
			state.providers = action.payload;
		},
		providersEntitiesRefresh: (state: Draft<IProvidersInitState>, action: PayloadAction<IProviderListEntities>) => {
			state.entities = action.payload;
		},
		mainDataRefresh: (state: Draft<IProvidersInitState>, action: PayloadAction<IProviderMainTabEntities>) => {
			state.mainData = action.payload;
		},
		namesDataRefresh: (state: Draft<IProvidersInitState>, action: PayloadAction<IProviderNamesEntities>) => {
			state.namesData = action.payload;
		},
		seoDataRefresh: (state: Draft<IProvidersInitState>, action: PayloadAction<IProviderSeoEntities>) => {
			state.seoData = action.payload;
		},
		providersMainRefresh: (state: Draft<IProvidersInitState>, action: PayloadAction<Partial<IProviderMainEntities>>) => {
			const target = state.tabMain;
			const source = action.payload;
			const result: Partial<IProviderMainEntities> = fill(source, target);
			state.tabMain = result;
		},

		uiRefresh: (state: Draft<IProvidersInitState>, action: PayloadAction<TProviderUIPartial>) => {
			const target = state.UI;
			const source = action.payload;
			const result: TProviderUIPartial = fill(source, target);
			state.UI = result;
		},

	},
});


export const  websiteProviderReducers = {
	providersListRefresh    : providerSlice.actions.providersListRefresh,
	providersEntitiesRefresh: providerSlice.actions.providersEntitiesRefresh,
	providersMainRefresh    : providerSlice.actions.providersMainRefresh,
	namesDataRefresh        : providerSlice.actions.namesDataRefresh,
	mainDataRefresh         : providerSlice.actions.mainDataRefresh,
	seoDataRefresh          : providerSlice.actions.seoDataRefresh,
	uiRefresh               : providerSlice.actions.uiRefresh,
};

export const websiteProviderSelectors = {
	list          : (store: RootState): IProvidersList[] => store.Partner.Websites.Providers.providers,
	entities      : (store: RootState): IProviderListEntities => store.Partner.Websites.Providers.entities,
	tabMain       : (store: RootState): IProviderMainEntities => store.Partner.Websites.Providers.tabMain,
	main          : (store: RootState): IProviderMainTabEntities => store.Partner.Websites.Providers.mainData,
	entitiesToList: createSelector([(store: RootState): IProviderListEntities => store.Partner.Websites.Providers.entities], data => values(data)),
	UI            : (store: RootState): TProviderUIPartial => store.Partner.Websites.Providers.UI,
	names         : (store: RootState): IProviderNamesEntities => store.Partner.Websites.Providers.namesData,
	seo           : (store: RootState): IProviderSeoEntities => store.Partner.Websites.Providers.seoData,
};


export default providerSlice.reducer;
