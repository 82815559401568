import isArray from 'lodash/isArray';
import EntityAdapter from '../../../helpers/entityAdapter';

const fields = {
	id                     : 'id',
	name                   : 'name',
	reason                 : 'reason',
	bonusesID              : 'bonuses',
	betFactor              : 'bet_factor',
	inPlayBetDelay         : 'in_play_bet_delay',
	minComb                : 'min_comb',
	maxComb                : 'max_comb',
	maxStake               : 'max_stake',
	maxWon                 : 'max_won',
	overask                : 'overask',
	default                : 'default',
	color                  : 'color',
	note                   : 'note',
	websiteID              : 'website_id',
	autoWithdrawalSettingID: 'auto_withdrawal_setting_id',
};

export function adaptGroupsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}
	const adapter = createBaseDataAdapter();

	const result = rawData.map(item => {
		return adapter.adapt(item);
	});

	return result;
}

export function adaptBaseData(rawData = {}) {
	const adapter = createBaseDataAdapter();
	const result = adapter.adapt(rawData);

	return result;
}

export function prepareBaseData(rawData = {}, websiteID = null) {
	const adapter = createBaseDataAdapter();
	const result = adapter.prepare(rawData);

	result[fields.websiteID] = websiteID;

	return result;
}

function createBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'bonusesID', fields.bonusesID);
	adapter.addField(rules.id, 'autoWithdrawalSettingID', fields.autoWithdrawalSettingID);

	adapter.addField(rules.bool, 'overask', fields.overask);
	adapter.addField(rules.bool, 'default', fields.default);

	adapter.addField(rules.positiveNumber, 'betFactor', fields.betFactor);
	adapter.addField(rules.positiveNumber, 'inPlayBetDelay', fields.inPlayBetDelay);
	adapter.addField(rules.positiveNumber, 'minComb', fields.minComb);
	adapter.addField(rules.positiveNumber, 'maxComb', fields.maxComb);
	adapter.addField(rules.positiveNumber, 'maxStake', fields.maxStake);
	adapter.addField(rules.positiveNumber, 'maxWon', fields.maxWon);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'reason', fields.reason);
	adapter.addField(rules.string, 'color', fields.color);
	adapter.addField(rules.string, 'note', fields.note);

	return adapter;
}
