const actions = {

	MARKETS_DISPLAY_TYPES_LIST_RELOAD : 'MARKETS_DISPLAY_TYPES_LIST_RELOAD',
	MARKETS_DISPLAY_TYPES_LIST_REFRESH: 'MARKETS_DISPLAY_TYPES_LIST_REFRESH',
	MARKETS_DISPLAY_TYPES_LIST_REORDER: 'MARKETS_DISPLAY_TYPES_LIST_REORDER',

	MARKETS_DISPLAY_TYPES_SAVE_TYPE  : 'MARKETS_DISPLAY_TYPES_SAVE_TYPE',
	MARKETS_DISPLAY_TYPES_DELETE_TYPE: 'MARKETS_DISPLAY_TYPES_DELETE_TYPE',

	MARKETS_DISPLAY_TYPES_FILTER_REFRESH: 'MARKETS_DISPLAY_TYPES_FILTER_REFRESH',
	MARKETS_DISPLAY_TYPES_UI_REFRESH    : 'MARKETS_DISPLAY_TYPES_UI_REFRESH',

	MARKETS_DISPLAY_TYPES_CHANGED_ID_ADD   : 'MARKETS_DISPLAY_TYPES_CHANGED_ID_ADD',
	MARKETS_DISPLAY_TYPES_CHANGED_IDS_RESET: 'MARKETS_DISPLAY_TYPES_CHANGED_IDS_RESET',

	MARKETS_DISPLAY_TYPES_MODAL_REFRESH       : 'MARKETS_DISPLAY_TYPES_MODAL_REFRESH',
	MARKETS_DISPLAY_TYPES_REMOVE_CANCELED_ITEM: 'MARKETS_DISPLAY_TYPES_REMOVE_CANCELED_ITEM',
	MARKETS_DISPLAY_TYPES_FILTER_RESET        : 'MARKETS_DISPLAY_TYPES_FILTER_RESET',
	FILTER_RESET                              : 'MARKETS_DISPLAY_TYPES_FILTER_RESET',
	
	filterReset: () => ({
		type: actions.FILTER_RESET,
	}),
	listReload: () => ({
		type: actions.MARKETS_DISPLAY_TYPES_LIST_RELOAD,
	}),
	typesModalRefresh: (data) => ({
		type: actions.MARKETS_DISPLAY_TYPES_MODAL_REFRESH,
		data,
	}),
	listRefresh: list => ({
		type: actions.MARKETS_DISPLAY_TYPES_LIST_REFRESH,
		data: list,
	}),
	listReorder: list => ({
		type: actions.MARKETS_DISPLAY_TYPES_LIST_REORDER,
		data: list,
	}),

	saveType: typeData => ({
		type: actions.MARKETS_DISPLAY_TYPES_SAVE_TYPE,
		data: typeData,
	}),
	deleteType: typeID => ({
		type: actions.MARKETS_DISPLAY_TYPES_DELETE_TYPE,
		data: {
			typeID,
		},
	}),

	filterRefresh: data => ({
		type: actions.MARKETS_DISPLAY_TYPES_FILTER_REFRESH,
		data,
	}),

	uiRefresh: data => ({
		type: actions.MARKETS_DISPLAY_TYPES_UI_REFRESH,
		data,
	}),

	changedIDAdd: typeID => ({
		type: actions.MARKETS_DISPLAY_TYPES_CHANGED_ID_ADD,
		data: {
			typeID,
		},
	}),
	changedIDsReset: () => ({
		type: actions.MARKETS_DISPLAY_TYPES_CHANGED_IDS_RESET,
	}),
	resetFilter: () => ({
		type: actions.MARKETS_DISPLAY_TYPES_FILTER_RESET,
	}),
	removeCenceledItemFromList: () => ({
		type: actions.MARKETS_DISPLAY_TYPES_REMOVE_CANCELED_ITEM,
	}),
};

export default actions;
