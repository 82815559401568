import toInteger from 'lodash/toInteger';
import { isID } from '../../../../helpers/utils';

const fields = {
	// base data
	id            : 'id',
	pictureURL    : 'picture_url',
	birthday      : 'birthday',
	participantIDs: 'participant_ids',
	roleID        : 'role_id',
	// translate
	name          : 'name',
	surname       : 'surname',
	bio           : 'bio',
	altName       : 'alt_name',
	altSurname    : 'alt_surname',
	langID        : 'lang_id',
	memberID      : 'participant_member_id',
};

export function adaptBaseData(rawBaseData = {}, rawTranslate = {}) {
	const result = {
		// base data
		id            : toInteger(rawBaseData[fields.id]),
		pictureURL    : rawBaseData[fields.pictureURL],
		birthday      : rawBaseData[fields.birthday],
		participantIDs: rawBaseData[fields.participantIDs],
		roleID        : rawBaseData[fields.roleID],
		// translate
		name          : rawTranslate[fields.name],
		surname       : rawTranslate[fields.surname],
		bio           : rawTranslate[fields.bio],
		altName       : rawTranslate[fields.altName],
		altSurname    : rawTranslate[fields.altSurname],
		langID        : toInteger(rawTranslate[fields.langID]),
		memberID      : toInteger(rawTranslate[fields.memberID]),
	};

	return result;
}

export function prepareBaseData(rawData, memberID) {
	const result = {
		[fields.pictureURL]    : rawData.pictureURL,
		[fields.birthday]      : rawData.birthday,
		[fields.participantIDs]: rawData.participantIDs,
		[fields.roleID]        : rawData.roleID,
	};

	if (isID(memberID)) {
		result.id = toInteger(memberID);
	}

	return result;
}

export function prepareTranslate(rawData, memberID, langID) {
	const result = {
		[fields.name]      : rawData.name,
		[fields.surname]   : rawData.surname,
		[fields.bio]       : rawData.bio,
		[fields.altName]   : rawData.altName,
		[fields.altSurname]: rawData.altSurname,
		[fields.langID]    : langID,
		[fields.memberID]  : memberID,
	};

	return result;
}
