import EntityAdapter from '../../../helpers/entityAdapter';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import QueryToFilterValidaion from '../../../helpers/queryToFilterValidaion';

const { isValidID, isValidArray, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	userID             : isValidID,
	riskType           : isValidArray,
	riskGroup          : isValidArray,
	comment            : isValidArray,
	registrationCountry: isValidArray,
	priority           : isValidArray,
	riskTime           : isValidDateRange,
};

const fields = {
	userId          : 'user_id',
	websiteId       : 'website_id',
	riskID          : 'id',
	resolutionStatus: 'resolution_status',

	created            : 'created',
	priority           : 'priority',
	riskType           : 'risk_type',
	riskGroup          : 'risk_group',
	riskValue          : 'risk_value',
	currentValue       : 'current_value',
	status             : 'resolution_status',
	comment            : 'comment',
	registrationCountry: 'registration_country',
	sourceCountry      : 'source_country',
	sourceIp           : 'source_ip',
	country            : 'country',
	email              : 'email',
	riskName           : 'risk_name',

	dateFrom: 'date_from',
	dateTo  : 'date_to',
};

export function getListParams(pagination = null, sorting, filter) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'userID', fields.userId);
	builder.addField(rules.isID, 'riskID', fields.riskID);
	builder.addField(rules.isArrayID, 'comment', fields.status);

	builder.addField(rules.isArrayID, 'riskType', fields.riskType);
	builder.addField(rules.isArrayID, 'riskGroup', fields.riskGroup);
	builder.addField(rules.isArrayID, 'priority', fields.priority);
	builder.addField(rules.isArrayString, 'registrationCountry', fields.country);
	builder.addField(rules.isString, 'riskName', fields.riskName);

	builder.addRangeField(rules.isDateTimeRange, filter.riskTime, [
		fields.dateFrom,
		fields.dateTo,
	]);

	const params = builder.biuldParams(filter);
	return params;
}
const riskListAdapter = createListAdapter();

export const adaptRiskManagement = (data = []) => {
	const adaptedData = riskListAdapter.adaptList(data);
	const risksIDs = [];
	const entities = adaptedData.reduce((next, risk) => {
		const { riskID } = risk;
		risksIDs.push(riskID);
		return { ...next, [riskID]: risk };
	}, {});
	return {
		risksIDs,
		entities,
	};
};
export const adaptRisk = (rawData = {}) => (riskListAdapter.adapt(rawData));

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'userId', fields.userId);
	adapter.addField(rules.id, 'riskID', fields.riskID);
	adapter.addField(rules.id, 'websiteId', fields.websiteId);

	adapter.addField(rules.number, 'resolutionStatus', fields.resolutionStatus);
	adapter.addField(rules.number, 'priority', fields.priority);
	adapter.addField(rules.number, 'riskType', fields.riskType);
	adapter.addField(rules.number, 'riskValue', fields.riskValue);
	adapter.addField(rules.number, 'currentValue', fields.currentValue);

	adapter.addField(rules.string, 'created', fields.created);
	adapter.addField(rules.string, 'comment', fields.comment);
	adapter.addField(rules.string, 'registrationCountry', fields.registrationCountry);
	adapter.addField(rules.string, 'sourceCountry', fields.sourceCountry);
	adapter.addField(rules.string, 'sourceIp', fields.sourceIp);
	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.string, 'riskName', fields.riskName);

	adapter.addField(rules.arrayNumber, 'riskGroup', fields.riskGroup);

	return adapter;
}
