import { restoreLanguage } from './helpers/utility';
import config, { getCurrentLanguage } from './containers/Topbar/LanguageSwitcher/config';
import AppLocale from './languageProvider';


export function getCurrentAppLocale() {
	const storedLanguage = restoreLanguage();
	const appLanguage = storedLanguage ? storedLanguage.dataBaseLangID : config.defaultLanguage;

	return AppLocale[getCurrentLanguage(appLanguage).locale];
}

