import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import paymentSaga from './payment/saga';
export default function* paymentsSaga() {
	yield all([
		fork(listSaga),
		fork(paymentSaga),
	]);
}
