const actions = {

	MARKETS_LADDERS_LIST_RELOAD : 'MARKETS_LADDERS_LIST_RELOAD',
	MARKETS_LADDERS_LIST_REFRESH: 'MARKETS_LADDERS_LIST_REFRESH',

	MARKETS_LADDERS_UPDATE_ALL   : 'MARKETS_LADDERS_UPDATE_ALL',
	MARKETS_LADDERS_DELETE_LADDER: 'MARKETS_LADDERS_DELETE_LADDER',

	MARKETS_LADDERS_FILTER_REFRESH: 'MARKETS_LADDERS_FILTER_REFRESH',
	MARKETS_LADDERS_UI_REFRESH    : 'MARKETS_LADDERS_UI_REFRESH',

	MARKETS_LADDERS_CHANGED_ID_ADD   : 'MARKETS_LADDERS_CHANGED_ID_ADD',
	MARKETS_LADDERS_CHANGED_IDS_RESET: 'MARKETS_LADDERS_CHANGED_IDS_RESET',
	FILTER_RESET                     : 'MARKETS_LADDERS_FILTER_RESET',
	
	filterReset: () => ({
		type: actions.FILTER_RESET,
	}),
	listReload: () => ({
		type: actions.MARKETS_LADDERS_LIST_RELOAD,
	}),
	listRefresh: list => ({
		type: actions.MARKETS_LADDERS_LIST_REFRESH,
		data: list,
	}),

	updateAll: () => ({
		type: actions.MARKETS_LADDERS_UPDATE_ALL,
	}),
	deleteLadder: ladderID => ({
		type: actions.MARKETS_LADDERS_DELETE_LADDER,
		data: {
			ladderID,
		},
	}),

	filterRefresh: data => ({
		type: actions.MARKETS_LADDERS_FILTER_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.MARKETS_LADDERS_UI_REFRESH,
		data,
	}),

	changedIDAdd: ladderID => ({
		type: actions.MARKETS_LADDERS_CHANGED_ID_ADD,
		data: {
			ladderID,
		},
	}),
	changedIDsReset: () => ({
		type: actions.MARKETS_LADDERS_CHANGED_IDS_RESET,
	}),
};

export default actions;
