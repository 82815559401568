
import React from 'react';

export const DocumnetsIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M20.2002 4.90002L16.5002 1.20002C16.3002 1.10002 16.2002 1.00002 16.0002 1.10002C15.8002 1.20002 15.7002 1.30002 15.7002 1.50002V5.20002C15.7002 5.40001 15.9002 5.60002 16.1002 5.60002H19.8002C20.0002 5.60002 20.1002 5.50002 20.2002 5.30002C20.4002 5.20002 20.3002 5.10002 20.2002 4.90002Z" fill="#323332" />
			<path d="M18.1001 23.3H5.9001C4.6001 23.3 3.6001 22.2 3.6001 21V3.00001C3.6001 1.70001 4.6001 0.700012 5.9001 0.700012H14.1001C14.5001 0.700012 14.7001 1.00001 14.7001 1.30001V5.70001C14.7001 6.10001 15.0001 6.50001 15.5001 6.50001H19.8001C20.2001 6.50001 20.4001 6.80001 20.4001 7.10001V21C20.4001 22.2 19.4001 23.3 18.1001 23.3ZM5.9001 2.00001C5.3001 2.00001 4.9001 2.50001 4.9001 3.10001V21C4.9001 21.6 5.4001 22.1 5.9001 22.1H18.2001C18.8001 22.1 19.2001 21.6 19.2001 21V7.80001H15.5001C14.4001 7.80001 13.5001 6.90001 13.5001 5.70001V2.00001H5.9001Z" fill="#323332" />
			<path d="M14.2 10.9H6.7C6.3 10.9 6 10.6 6 10.2C6 9.8 6.3 9.5 6.7 9.5H14.2C14.6 9.5 14.9 9.8 14.9 10.2C14.9 10.6 14.6 10.9 14.2 10.9Z" fill="#323332" />
			<path d="M12.4 13.6H6.7C6.3 13.6 6 13.3 6 12.9C6 12.5 6.3 12.2 6.7 12.2H12.4C12.8 12.2 13.1 12.5 13.1 12.9C13.1 13.3 12.8 13.6 12.4 13.6Z" fill="#323332" />
			<path d="M12.4 16.3H6.7C6.3 16.3 6 16 6 15.6C6 15.2 6.3 14.9 6.7 14.9H12.4C12.8 14.9 13.1 15.2 13.1 15.6C13.1 16 12.8 16.3 12.4 16.3Z" fill="#323332" />
			<path d="M12.4 18.9H6.7C6.3 18.9 6 18.6 6 18.2C6 17.8 6.3 17.5 6.7 17.5H12.4C12.8 17.5 13.1 17.8 13.1 18.2C13.1 18.6 12.8 18.9 12.4 18.9Z" fill="#323332" />
		</svg>

	);
};
