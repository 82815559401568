import React from 'react';
import PropTypes from 'prop-types';
import { sidebarIcons } from '../../../config/defaultSidebar.icons';

const SidebarIcon = ({ sidebarKey, iconName }) => {

	const customIcon = sidebarIcons[sidebarKey];
	if (customIcon) {
		return customIcon;
	}

	return (
		<i className={iconName} />
	);
};

SidebarIcon.propTypes = {
	sidebarKey: PropTypes.string.isRequired,
	iconName  : PropTypes.string,
};

SidebarIcon.defaultProps = {
	iconName: 'ion-navicon-round',
};

export default SidebarIcon;
