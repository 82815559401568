const actions = {

	USER_STATISTIC_DATA_RELOAD           : 'USER_STATISTIC_DATA_RELOAD',
	USER_STATISTIC_BETSLIPS_REFRESH      : 'USER_STATISTIC_BETSLIPS_REFRESH',
	USER_STATISTIC_MARKETS_REFRESH       : 'USER_STATISTIC_MARKETS_REFRESH',
	USER_STATISTIC_COUNTRIES_REFRESH     : 'USER_STATISTIC_COUNTRIES_REFRESH',
	USER_STATISTIC_LEAGUES_REFRESH       : 'USER_STATISTIC_LEAGUES_REFRESH',
	USER_STATISTIC_SPORTS_REFRESH        : 'USER_STATISTIC_SPORTS_REFRESH',
	USER_STATISTIC_PLACED_AMOUNTS_REFRESH: 'USER_STATISTIC_PLACED_AMOUNTS_REFRESH',
	USER_STATISTIC_SET_VALUE_UI          : 'USER_STATISTIC_SET_VALUE_UI',

	dataReload: (userID) => ({
		type: actions.USER_STATISTIC_DATA_RELOAD,
		data: {
			userID,
		},
	}),
	betslipTypesRefresh: list => ({
		type: actions.USER_STATISTIC_BETSLIPS_REFRESH,
		data: list,
	}),
	marketTypesRefresh: list => ({
		type: actions.USER_STATISTIC_MARKETS_REFRESH,
		data: list,
	}),
	countriesListRefresh: list => ({
		type: actions.USER_STATISTIC_COUNTRIES_REFRESH,
		data: list,
	}),
	leaguesListRefresh: list => ({
		type: actions.USER_STATISTIC_LEAGUES_REFRESH,
		data: list,
	}),
	sportsListRefresh: list => ({
		type: actions.USER_STATISTIC_SPORTS_REFRESH,
		data: list,
	}),
	placedAmountListRefresh: list => ({
		type: actions.USER_STATISTIC_PLACED_AMOUNTS_REFRESH,
		data: list,
	}),
	setValueUI: (valueName, value) => ({
		type: actions.USER_STATISTIC_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
};

export default actions;
