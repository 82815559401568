const actions = {

	CASINO_GAME_CATEGORY_DATA_RESET       : 'CASINO_GAME_CATEGORY_DATA_RESET',
	CASINO_GAME_CATEGORY_BASE_DATA_RELOAD : 'CASINO_GAME_CATEGORY_BASE_DATA_RELOAD',
	CASINO_GAME_CATEGORY_BASE_DATA_REFRESH: 'CASINO_GAME_CATEGORY_BASE_DATA_REFRESH',

	CASINO_GAME_CATEGORY_NAMES_DATA_RELOAD : 'CASINO_GAME_CATEGORY_NAMES_DATA_RELOAD',
	CASINO_GAME_CATEGORY_NAMES_DATA_REFRESH: 'CASINO_GAME_CATEGORY_NAMES_DATA_REFRESH',

	CASINO_GAME_CATEGORY_SIMILARS_LIST_REFRESH: 'CASINO_GAME_CATEGORY_SIMILARS_LIST_REFRESH',
	CASINO_GAME_CATEGORY_SIMILARS_LIST_RELOAD : 'CASINO_GAME_CATEGORY_SIMILARS_LIST_RELOAD',
	CASINO_CATEGORY_LIST_FOR_SIMILAR_RELOAD   : 'CASINO_CATEGORY_LIST_FOR_SIMILAR_RELOAD',
	CASINO_CATEGORY_LIST_FOR_SIMILAR_REFRASH  : 'CASINO_CATEGORY_LIST_FOR_SIMILAR_REFRASH',
	CASINO_GAME_CATEGORY_SIMILARS_DATA_DELETE : 'CASINO_GAME_CATEGORY_SIMILARS_DATA_DELETE',
	CASINO_GAME_CATEGORY_SIMILARS_DATA_UPDATE : 'CASINO_GAME_CATEGORY_SIMILARS_DATA_UPDATE',

	CASINO_GAME_CATEGORY_SAVE      : 'CASINO_GAME_CATEGORY_SAVE',
	CASINO_GAME_CATEGORY_UI_REFRESH: 'CASINO_GAME_CATEGORY_UI_REFRESH',


	dataReset: () => ({
		type: actions.CASINO_GAME_CATEGORY_DATA_RESET,
	}),
	baseDataReload: (gameCategoryID, forced = false) => ({
		type: actions.CASINO_GAME_CATEGORY_BASE_DATA_RELOAD,
		data: {
			gameCategoryID,
			forced,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.CASINO_GAME_CATEGORY_BASE_DATA_REFRESH,
		data,
	}),

	namesDataReload: gameCategoryID => ({
		type: actions.CASINO_GAME_CATEGORY_NAMES_DATA_RELOAD,
		data: {
			gameCategoryID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.CASINO_GAME_CATEGORY_NAMES_DATA_REFRESH,
		data,
	}),
	similarsListReload: gameCategoryID => ({
		type: actions.CASINO_GAME_CATEGORY_SIMILARS_LIST_RELOAD,
		data: {
			gameCategoryID,
		},
	}),
	similarsDataRefresh: data => ({
		type: actions.CASINO_GAME_CATEGORY_SIMILARS_LIST_REFRESH,
		data,
	}),
	categoriesForSimilarListReload: gameCategoryID => ({
		type: actions.CASINO_CATEGORY_LIST_FOR_SIMILAR_RELOAD,
		data: {
			gameCategoryID,
		},
	}),
	categoryListForSimilarListRefrash: (data) => ({
		type: actions.CASINO_CATEGORY_LIST_FOR_SIMILAR_REFRASH,
		data,
	}),
	similarsDataDelete: gameCategoryID => ({
		type: actions.CASINO_GAME_CATEGORY_SIMILARS_DATA_DELETE,
		data: {
			gameCategoryID,
		},
	}),
	similarsDataUpdaate: gameCategoryID => ({
		type: actions.CASINO_GAME_CATEGORY_SIMILARS_DATA_UPDATE,
		data: {
			gameCategoryID,
		},
	}),

	gameCategorySave: () => ({
		type: actions.CASINO_GAME_CATEGORY_SAVE,
	}),
	uiRefresh: data => ({
		type: actions.CASINO_GAME_CATEGORY_UI_REFRESH,
		data,
	}),
};

export default actions;
