import { values } from 'lodash';
import { partnerWebsiteCount } from '../../../containers/CustomerManagement/UserInfo/UserPartnerWebsite/utils';
import EntityAdapter from '../../../helpers/entityAdapter';
import { getTimeZonesCustom } from '../../../helpers/timezone';


const fields = {
	// base data
	id                  : 'id',
	customerID          : 'customer_id',
	nationalID          : 'national_id',
	userName            : 'username',
	email               : 'email',
	firstName           : 'first_name',
	lastName            : 'last_name',
	countryID           : 'country',
	city                : 'city',
	phone               : 'phone',
	avatarURL           : 'avatar',
	verificationPhotoURL: 'verification_photo',
	birthday            : 'birth',
	genderID            : 'gender',
	created             : 'created',
	currencyID          : 'currency_id',
	statusID            : 'status_id',
	gamingStatusID      : 'gaming_status',
	langID              : 'language_id',
	stateID             : 'state_id',
	stateIDissueDate    : 'state_id_issue_date',
	balance             : 'balance',
	description         : 'description',
	oddsFormat          : 'odds_format',
	emailContact        : 'email_contact',
	phoneContact        : 'phone_contact',
	receiveNewsletters  : 'receive_newsletters',
	isOnline            : 'is_online',
	isVerified          : 'is_verified',
	excludeMassBonus    : 'exclude_mass_bonus',
	referrer            : 'referrer',
	timeZone            : 'time_zone',
	utm                 : 'utm',
	roles               : 'roles',
	userRoles           : 'user_roles',
	address1            : 'address_1',
	address2            : 'address_2',
	riskGroupID         : 'risk_group_id',
	adminType           : 'admin_type',
	maxPasswordAge      : 'password_expiration_period',
	restrictWithdrawal  : 'withdrawal_blocked',
	restrictDeposit     : 'deposit_blocked',
	enableTwoFASms      : 'enable_2fa_sms',
	enableTwoFAApp      : 'enable_2fa_app',

	// admin data
	affiliateReference       : 'affiliate_reference',
	age                      : 'age',
	ageVerified              : 'age_verified',
	maxStake                 : 'max_stake',
	maxActiveStake           : 'max_active_stake',
	maxActivePayout          : 'max_active_won',
	countryRegistrationIP    : 'country_of_registration_ip',
	betslipTypesList         : 'betslipTypesList',
	creditCardVerified       : 'credit_card_verified',
	domain                   : 'domain',
	emailVerified            : 'email_verified',
	lastDepositDate          : 'last_deposit_date',
	lastLogin                : 'last_login',
	lastWithdrawalDate       : 'last_withdrawal_date',
	phoneVerified            : 'phone_verified',
	passportVerified         : 'passport_verified',
	totalDepositsAmount      : 'total_deposits_amount',
	totalDepositsSum         : 'total_deposits_sum',
	totalWithdrawalAmount    : 'total_withdrawal_amount',
	totalWithdrawalSum       : 'total_withdrawal_sum',
	validationStatus         : 'validation_status',
	verificationStatusID     : 'verification_status',
	voidedCancelledBetsAmount: 'voided_or_cancelled_bets_amount',
	limitationReason         : 'limitation_reason',
	// maxStake                  : 'max_stake_',
	// maxPayout                 :  'max_payout',
	websiteID                : 'website_id',
	// finance data
	// balance                : 'balance', // presented in base fields
	avgDepositSum            : 'avg_deposit_sum',
	avgWithdrawalSum         : 'avg_withdrawal_sum',
	betsAmount               : 'bets_amount',
	firstDepositSum          : 'first_deposit_sum',
	firstDepositDate         : 'first_deposit_date',
	firstWithdrawalSum       : 'first_withdrawal_sum',
	firstWithdrawalDate      : 'first_withdrawal_date',
	lastDepositSum           : 'last_deposit_sum',
	lastWithdrawalSum        : 'last_withdrawal_sum',
	maximumStake             : 'maximum_stake',
	minimumStake             : 'minimum_stake',
	totalDepositAmount       : 'total_deposit_amount',
	totalDepositSum          : 'total_deposit_sum',
	totalWithdrawAmount      : 'total_withdraw_amount',
	totalWithdrawSum         : 'total_withdraw_sum',

	socials   : 'socials',
	facebookID: 'facebook_id',
	linkedinID: 'linkedin_id',
	googleID  : 'google_id',
	vkID      : 'vk_id',
	okID      : 'ok_id',
	maxPayout : 'max_won',

	ageVerifySourceId  : 'age_verify_source_id',
	ageVerifySourceType: 'age_verify_source_type',
	ageVerifyDate      : 'age_verify_date',

	creditCardVerifySourceId  : 'credit_card_verify_source_id',
	creditCardVerifySourceType: 'credit_card_verify_source_type',
	creditCardVerifyDate      : 'credit_card_verify_date',

	emailVerifySourceId  : 'email_verify_source_id',
	emailVerifySourceType: 'email_verify_source_type',
	emailVerifyDate      : 'email_verify_date',

	passportVerifySourceId  : 'passport_verify_source_id',
	passportVerifySourceType: 'passport_verify_source_type',
	passportVerifyDate      : 'passport_verify_date',

	phoneVerifySourceId  : 'phone_verify_source_id',
	phoneVerifySourceType: 'phone_verify_source_type',
	phoneVerifyDate      : 'phone_verify_date',

	// Website currency
	code              : 'code',
	websiteIDs        : 'website_ids',
	partnerIDs        : 'partner_ids',
	selectedPartnerIDs: 'selected_partner_ids',
	count             : 'count',

	// notes
	personalID: 'personal_id',
	adminID   : 'admin_id',
	text      : 'text',
	date      : 'date',
};

const baseDataAdapter = createBaseDataAdapter();
const adminDataAdapter = createAdminDataAdapter();
const socialsDataAdapter = createSocialsDataAdapter();
const currencyDataAdapter = createCurrencyDataAdapter();
const userCurrenciesDataAdapter = createUserCurrenciesDataAdapter();
const financeDataAdapter = createFinanceDataAdapter();
const notesDataAdapter = createNotesDataAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptBaseData(rawData = {}, partnerEntities) {
	baseDataAdapter.clearExcludes();
	const adaptedData = baseDataAdapter.adapt(rawData);
	const timezoneList = getTimeZonesCustom();
	let timeZoneID = null;
	const timezone = adaptedData.timeZone ? timezoneList.find(item => item.name.split(' ')[1] === adaptedData.timeZone || item.name.split(' ')[1] === adaptedData.timeZone.split(' ')[1]) : null;

	if (timezone) {
		timeZoneID = timezone.id;
	}

	const { websiteIDs, selectedPartnerIDs } = adaptedData;

	const flatWebsites = values(partnerEntities).map(partner => partner.websites).flat();

	adaptedData.partnersWebsites = partnerWebsiteIDGenerator(websiteIDs, selectedPartnerIDs, flatWebsites);
	adaptedData.count = partnerWebsiteCount(adaptedData.partnersWebsites);
	adaptedData.timeZoneID = timeZoneID;
	return adaptedData;
}


function partnerWebsiteIDGenerator(websiteIDs, selectedPartnerIDs, flatWebsites) {
	const res = [];
	const partnerPrefix = 'partner';
	const websitePrefix = 'website';
	const parentPrefix = 'parent';
	const websiteEntity = flatWebsites.reduce((acc, website) => {
		acc[website.id] = website;
		return acc;
	}, {});

	websiteIDs.forEach(websiteID => {
		if (websiteEntity[websiteID]) {
			const { parentID } = websiteEntity[websiteID];
			res.push(`${websitePrefix}_${websiteID}_${parentPrefix}_${parentID}`);
		}

	});

	selectedPartnerIDs.forEach(partnerID => {
		res.push(`${partnerPrefix}_${partnerID}`);
	});

	return res;

}

export function adaptAdminData(rawData = {}) {

	adminDataAdapter.clearExcludes();
	socialsDataAdapter.clearExcludes();

	const adaptedData = adminDataAdapter.adapt(rawData);
	adaptedData.socials = adaptedData.socials ? socialsDataAdapter.adapt(adaptedData.socials) : socialsDataAdapter.adapt({});

	return adaptedData;
}

export function adaptCurrencyList(rawData = []) {

	currencyDataAdapter.clearExcludes();

	const adaptedData = currencyDataAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.name = `${item.description} (${item.code})`;
	});

	return adaptedData;
}

export function adaptUserCurrenciesList(rawData = []) {

	userCurrenciesDataAdapter.clearExcludes();

	const adaptedData = userCurrenciesDataAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.name = `${item.description} (${item.code})`;
	});

	return adaptedData;
}

export function adaptFinance(rawData = {}) {

	financeDataAdapter.clearExcludes();
	const adaptedData = financeDataAdapter.adapt(rawData);

	return adaptedData;
}


export function adaptNotesData(rawData = []) {
	notesDataAdapter.clearExcludes();
	const adaptedData = notesDataAdapter.adaptList(rawData);

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareBaseData(baseData, adminData) {
	const timezoneList = getTimeZonesCustom();

	baseDataAdapter.clearExcludes();
	baseDataAdapter.addExcludeField('roles');
	baseDataAdapter.addExcludeField('userRoles');
	baseDataAdapter.addExcludeField('maxPasswordAge');
	const preparedData = baseDataAdapter.prepare(baseData);

	const timezone = baseData.timeZoneID ? timezoneList.find(item => item.code === baseData.timeZoneID) : null;
	if (timezone) {
		[, preparedData.time_zone] = timezone.name.split(' ');
	}
	const adaptedAdminData = adminDataAdapter.prepare(adminData);

	return { ...preparedData, ...adaptedAdminData };
}

// Adapters ---------------------------------------------------------------------------------------

export function createBaseDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'gamingStatusID', fields.gamingStatusID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'gamingStatusID', fields.gamingStatusID);
	adapter.addField(rules.id, 'adminType', fields.adminType);

	adapter.addField(rules.number, 'adminType', fields.adminType);
	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.number, 'maxPasswordAge', fields.maxPasswordAge);

	adapter.addField(rules.numberOrNull, 'maxStake', fields.maxStake);
	adapter.addField(rules.numberOrNull, 'maxPayout', fields.maxPayout);
	adapter.addField(rules.numberOrNull, 'maxActiveStake', fields.maxActiveStake);
	adapter.addField(rules.numberOrNull, 'maxActivePayout', fields.maxActivePayout);

	adapter.addField(rules.intOrNull, 'genderID', fields.genderID);
	adapter.addField(rules.intOrNull, 'currencyID', fields.currencyID);

	adapter.addField(rules.bool, 'emailContact', fields.emailContact);
	adapter.addField(rules.bool, 'phoneContact', fields.phoneContact);
	adapter.addField(rules.bool, 'receiveNewsletters', fields.receiveNewsletters);
	adapter.addField(rules.bool, 'isOnline', fields.isOnline);
	adapter.addField(rules.bool, 'isVerified', fields.isVerified);
	adapter.addField(rules.bool, 'excludeMassBonus', fields.excludeMassBonus);

	adapter.addField(rules.string, 'nationalID', fields.nationalID);
	adapter.addField(rules.string, 'customerID', fields.customerID);
	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.string, 'firstName', fields.firstName);
	adapter.addField(rules.string, 'lastName', fields.lastName);
	adapter.addField(rules.intOrNull, 'genderID', fields.genderID);
	adapter.addField(rules.intOrNull, 'currencyID', fields.currencyID);
	adapter.addField(rules.ISOString, 'birthday', fields.birthday);
	adapter.addField(rules.string, 'phone', fields.phone);
	adapter.addField(rules.string, 'city', fields.city);

	adapter.addField(rules.string, 'countryID', fields.countryID);
	adapter.addField(rules.string, 'avatarURL', fields.avatarURL);
	adapter.addField(rules.string, 'verificationPhotoURL', fields.verificationPhotoURL);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'oddsFormat', fields.oddsFormat);
	adapter.addField(rules.string, 'referrer', fields.referrer);
	adapter.addField(rules.string, 'timeZone', fields.timeZone);
	adapter.addField(rules.string, 'utm', fields.utm);
	adapter.addField(rules.string, 'limitationReason', fields.limitationReason);
	adapter.addField(rules.string, 'address1', fields.address1);
	adapter.addField(rules.string, 'address2', fields.address2);

	adapter.addField(rules.ISOString, 'birthday', fields.birthday);

	adapter.addField(rules.fullDate, 'created', fields.created);
	adapter.addField(rules.fullDate, 'stateIDissueDate', fields.stateIDissueDate);

	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.numberOrNull, 'maxStake', fields.maxStake);
	adapter.addField(rules.numberOrNull, 'maxPayout', fields.maxPayout);
	adapter.addField(rules.numberOrNull, 'maxActiveStake', fields.maxActiveStake);
	adapter.addField(rules.numberOrNull, 'maxActivePayout', fields.maxActivePayout);

	adapter.addField(rules.bool, 'emailContact', fields.emailContact);
	adapter.addField(rules.bool, 'phoneContact', fields.phoneContact);
	adapter.addField(rules.bool, 'receiveNewsletters', fields.receiveNewsletters);
	adapter.addField(rules.bool, 'isOnline', fields.isOnline);
	adapter.addField(rules.bool, 'isVerified', fields.isVerified);
	adapter.addField(rules.bool, 'excludeMassBonus', fields.excludeMassBonus);

	adapter.addField(rules.arrayString, 'userRoles', fields.userRoles);
	adapter.addField(rules.arrayID, 'roles', fields.roles);
	adapter.addField(rules.arrayID, 'websiteIDs', fields.websiteIDs);
	adapter.addField(rules.arrayID, 'partnerIDs', fields.partnerIDs);
	adapter.addField(rules.arrayID, 'selectedPartnerIDs', fields.selectedPartnerIDs);

	adapter.addField(rules.bool, 'ageVerified', fields.ageVerified);

	adapter.addField(rules.string, 'ageVerifyDate', fields.ageVerifyDate);
	adapter.addField(rules.id, 'ageVerifySourceId', fields.ageVerifySourceId);
	adapter.addField(rules.number, 'ageVerifySourceType', fields.ageVerifySourceType);

	adapter.addField(rules.string, 'creditCardVerifyDate', fields.creditCardVerifyDate);
	adapter.addField(rules.id, 'creditCardVerifySourceId', fields.creditCardVerifySourceId);
	adapter.addField(rules.number, 'creditCardVerifySourceType', fields.creditCardVerifySourceType);

	adapter.addField(rules.string, 'emailVerifyDate', fields.emailVerifyDate);
	adapter.addField(rules.id, 'emailVerifySourceId', fields.emailVerifySourceId);
	adapter.addField(rules.number, 'emailVerifySourceType', fields.emailVerifySourceType);

	adapter.addField(rules.string, 'passportVerifyDate', fields.passportVerifyDate);
	adapter.addField(rules.id, 'passportVerifySourceId', fields.passportVerifySourceId);
	adapter.addField(rules.number, 'passportVerifySourceType', fields.passportVerifySourceType);

	adapter.addField(rules.string, 'phoneVerifyDate', fields.phoneVerifyDate);
	adapter.addField(rules.id, 'phoneVerifySourceId', fields.phoneVerifySourceId);
	adapter.addField(rules.number, 'phoneVerifySourceType', fields.phoneVerifySourceType);
	adapter.addField(rules.object, 'count', fields.count);

	adapter.addField(rules.bool, 'restrictWithdrawal', fields.restrictWithdrawal);
	adapter.addField(rules.bool, 'restrictDeposit', fields.restrictDeposit);

	adapter.addField(rules.bool, 'enableTwoFAApp', fields.enableTwoFAApp);
	adapter.addField(rules.bool, 'enableTwoFASms', fields.enableTwoFASms);

	return adapter;
}

export function createAdminDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'profitabilityGradeID', fields.profitabilityGradeID);
	adapter.addField(rules.id, 'verificationStatusID', fields.verificationStatusID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.string, 'limitationReason', fields.limitationReason);

	adapter.addField(rules.fullDate, 'lastDepositDate', fields.lastDepositDate);
	adapter.addField(rules.fullDate, 'lastWithdrawalDate', fields.lastWithdrawalDate);

	adapter.addField(rules.id, 'riskGroupID', fields.riskGroupID);

	adapter.addField(rules.numberOrNull, 'maxPayout', fields.maxPayout);

	adapter.addField(rules.positiveNumber, 'age', fields.age);
	adapter.addField(rules.string, 'countryRegistrationIP', fields.countryRegistrationIP);
	adapter.addField(rules.string, 'domain', fields.domain);
	adapter.addField(rules.string, 'affiliateReference', fields.affiliateReference);
	adapter.addField(rules.arrayNumber, 'betslipTypesList', fields.betslipTypesList);
	adapter.addField(rules.fullDate, 'lastLogin', fields.lastLogin);

	adapter.addField(rules.bool, 'validationStatus', fields.validationStatus);
	adapter.addField(rules.bool, 'ageVerified', fields.ageVerified);
	adapter.addField(rules.bool, 'creditCardVerified', fields.creditCardVerified);
	adapter.addField(rules.bool, 'emailVerified', fields.emailVerified);
	adapter.addField(rules.bool, 'phoneVerified', fields.phoneVerified);
	adapter.addField(rules.bool, 'passportVerified', fields.passportVerified);
	adapter.addField(rules.bool, 'passportVerified', fields.passportVerified);
	adapter.addField(rules.bool, 'passportVerified', fields.passportVerified);
	adapter.addField(rules.noCondition, 'socials', fields.socials);

	return adapter;
}

export function createSocialsDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'facebookID', fields.facebookID);
	adapter.addField(rules.string, 'linkedinID', fields.linkedinID);
	adapter.addField(rules.string, 'googleID', fields.googleID);
	adapter.addField(rules.string, 'vkID', fields.vkID);
	adapter.addField(rules.string, 'okID', fields.okID);

	return adapter;
}

export function createCurrencyDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'code', fields.code);

	return adapter;
}

export function createUserCurrenciesDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.currencyID);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'code', fields.code);

	return adapter;
}

export function createFinanceDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.number, 'avgDepositSum', fields.avgDepositSum);
	adapter.addField(rules.number, 'avgWithdrawalSum', fields.avgWithdrawalSum);
	adapter.addField(rules.number, 'betsAmount', fields.betsAmount);
	adapter.addField(rules.number, 'firstDepositSum', fields.firstDepositSum);
	adapter.addField(rules.fullDate, 'firstDepositDate', fields.firstDepositDate);
	adapter.addField(rules.number, 'firstWithdrawalSum', fields.firstWithdrawalSum);
	adapter.addField(rules.fullDate, 'lastDepositDate', fields.lastDepositDate);
	adapter.addField(rules.fullDate, 'firstWithdrawalDate', fields.firstWithdrawalDate);
	adapter.addField(rules.number, 'lastDepositSum', fields.lastDepositSum);
	adapter.addField(rules.number, 'lastWithdrawalSum', fields.lastWithdrawalSum);
	adapter.addField(rules.number, 'maximumStake', fields.maximumStake);
	adapter.addField(rules.number, 'minimumStake', fields.minimumStake);
	adapter.addField(rules.number, 'totalDepositAmount', fields.totalDepositAmount);
	adapter.addField(rules.number, 'totalDepositSum', fields.totalDepositSum);
	adapter.addField(rules.number, 'totalWithdrawAmount', fields.totalWithdrawAmount);
	adapter.addField(rules.number, 'totalWithdrawSum', fields.totalWithdrawSum);

	return adapter;
}

function createNotesDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'personalID', fields.personalID);
	adapter.addField(rules.id, 'adminID', fields.adminID);
	adapter.addField(rules.string, 'text', fields.text);
	adapter.addField(rules.dateTime, 'date', fields.date);

	return adapter;
}
