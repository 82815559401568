const actions = {

	PARTICIPANTS_FILTER_SET_VALUE           : 'PARTICIPANTS_FILTER_SET_VALUE',
	PARTICIPANTS_FILTER_REFRESH             : 'PARTICIPANTS_FILTER_REFRESH',
	PARTICIPANTS_FILTER_COUNTRY_LIST_RELOAD : 'PARTICIPANTS_FILTER_COUNTRY_LIST_RELOAD',
	PARTICIPANTS_FILTER_COUNTRY_LIST_REFRESH: 'PARTICIPANTS_FILTER_COUNTRY_LIST_REFRESH',
	PARTICIPANTS_FILTER_LEAGUE_LIST_RELOAD  : 'PARTICIPANTS_FILTER_LEAGUE_LIST_RELOAD',
	PARTICIPANTS_FILTER_LEAGUE_LIST_REFRESH : 'PARTICIPANTS_FILTER_LEAGUE_LIST_REFRESH',
	PARTICIPANTS_FILTER_AGE_LIST_RELOAD     : 'PARTICIPANTS_FILTER_AGE_LIST_RELOAD',
	PARTICIPANTS_FILTER_AGE_LIST_REFRESH    : 'PARTICIPANTS_FILTER_AGE_LIST_REFRESH',
	FILTER_RESET                            : 'PARTICIPANTS_FILTER_FILTER_RESET',
	
	filterReset: () => ({
		type: actions.FILTER_RESET,
	}),
	filterSetValue: (valueName, value) => ({
		type: actions.PARTICIPANTS_FILTER_SET_VALUE,
		data: {
			valueName,
			value,
		},
	}),
	filterRefresh: data => ({
		type: actions.PARTICIPANTS_FILTER_REFRESH,
		data,
	}),
	countryListReload: sportID => ({
		type: actions.PARTICIPANTS_FILTER_COUNTRY_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	countryListRefresh: list => ({
		type: actions.PARTICIPANTS_FILTER_COUNTRY_LIST_REFRESH,
		data: list,
	}),
	leagueListReload: sportID => ({
		type: actions.PARTICIPANTS_FILTER_LEAGUE_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	leagueListRefresh: list => ({
		type: actions.PARTICIPANTS_FILTER_LEAGUE_LIST_REFRESH,
		data: list,
	}),
	ageListReload: () => ({
		type: actions.PARTICIPANTS_FILTER_AGE_LIST_RELOAD,
	}),
	ageListRefresh: list => ({
		type: actions.PARTICIPANTS_FILTER_AGE_LIST_REFRESH,
		data: list,
	}),
};

export default actions;
