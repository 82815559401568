import { all, put, fork, call, select, takeEvery } from 'redux-saga/effects';
import notifications from '../../../helpers/notifications';
import actions from './actions';
import { dashboardAPI } from '../../../helpers/api/dashboard';
import { createCommonParams } from '../utils';
import { adaptDepositsData } from './utils';

const prefix = 'dashboard.deposits';

const messages = {
	errorDepositsData: `${prefix}.errorDepositsData`,
};

function getStoreData({ Dashboard, App }) {
	return {
		period   : Dashboard.Common.get('period'),
		websiteID: App.get('websiteID'),
	};
}

function* depositsDataReload() {

	yield takeEvery(actions.DASHBOARD_DEPOSITS_DATA_RELOAD, function* () {
		yield put(actions.depositsUIRefresh({ loading: true, visible: false }));
		const { period, websiteID } = yield select(getStoreData);

		const params = createCommonParams(period, websiteID);
		let dataToStore = [];
		try {
			const response = yield call(dashboardAPI.playersDepositReq, params);
			if (response && response.status === 200) {
				dataToStore = adaptDepositsData(response.data.data);
			}

		} catch (error) {
			notifications.showError(messages.errorDepositsData, error);
		}
		yield put(actions.depositsDataRefresh(dataToStore));
		yield put(actions.depositsUIRefresh({ loading: false, visible: true }));
	});

}
export default function* depositsDataSaga() {
	yield all([
		fork(depositsDataReload),
	]);
}
