import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../gameCategories/actions';

import { casinoAPI } from '../../../helpers/api/casino';
import { showError, showSuccess } from '../../../helpers/notifications';
import { isID } from '../../../helpers/utils';

import {
	adaptGameCategory,
	prepareGameCategory,
	adaptNamesData,
	prepareNamesData,
	updateGameCategoryEntities,
} from './utils';
import { restoreWebsite } from '../../../helpers/utility';
import { logger } from '../../../helpers/logger';

const messages = {
	errorBaseDataReload : 'gameCategory.error.baseData.reload',
	errorBaseDataSave   : 'gameCategory.error.baseData.save',
	errorNamesReload    : 'gameCategory.error.names.reload',
	errorNamesUpdate    : 'gameCategory.error.names.update',
	errorCategories     : 'gameCategory.error.categories',
	errorSimilarsList   : 'gameCategory.error.similarsList',
	errorDelateSimilar  : 'gameCategory.error.deleteSimilar',
	errorAliasUpdate 	  : 'casino.customProvider.error.alias.update',
	successSimilarsList : 'gameCategory.success.similarsList',
	successDelateSimilar: 'gameCategory.success.deleteSimilar',
	successBaseDataSave : 'gameCategory.success.baseData.save',
	successNamesUpdate  : 'gameCategory.success.names.update',
};

const getStoreData = ({ Casino, App }) => ({
	entities           : Casino.GameCategories.get('entities'),
	baseData         	 : Casino.GameCategory.get('baseData'),
	namesData        	 : Casino.GameCategory.get('namesData'),
	UI               	 : Casino.GameCategory.get('UI'),
	similars         	 : Casino.GameCategory.get('similars'),
	websiteID        	 : App.get('websiteID'),
	contentLanguageList: App.get('websiteAttachedLanguages'),
});

function* baseDataReload() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { gameCategoryID, forced } = action.data;

		// data from list
		let baseData = cloneDeep(storeData.baseData) || {};
		if (isID(baseData.id) && !forced) {
			yield put(actions.uiRefresh({
				loading      : false,
				isBaseChanged: false,
			}));

			return;
		}

		// loading data
		try {
			const res = yield call(casinoAPI.gameCategoryInfo, gameCategoryID);
			if (res && res.status === 200) {
				baseData = adaptGameCategory(res.data.data);
			}
		} catch (error) {
			showError(messages.errorBaseDataReload);
		}

		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.uiRefresh({
			loading      : false,
			isBaseChanged: false,
		}));
	});
}

function* gameCategorySave() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_SAVE, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, namesData, UI, websiteID, similars, contentLanguageList, entities } = yield select(getStoreData);
		const clonedSimilars = cloneDeep(similars);
		clonedSimilars.forEach(item => {
			if (!+item.id) {
				delete item.id;
			}
		});
		const { editMode, closeModal, isNamesChanged, isAliasChanged, isSeoChanged } = UI;
		let   { gameCategoryID } = UI;

		const gameCategoryBaseData = cloneDeep(baseData);
		const gameCategoryNamesData = cloneDeep(namesData);

		gameCategoryBaseData.websiteID = websiteID;
		let isError                = false;
		let errorMessage           = '';

		try {
			// base data
			errorMessage = messages.errorBaseDataSave;
			const preparedData = prepareGameCategory(gameCategoryBaseData);
			preparedData.similar_ids = clonedSimilars;
			if (editMode) {
				const updatedEntities = updateGameCategoryEntities(entities, null, gameCategoryID,true);
				yield put(listActions.listRefresh(updatedEntities));

				const res = yield call(casinoAPI.gameCategoryUpdate, gameCategoryID, preparedData);

				if (res && res.status === 200) {
					const defaultName = gameCategoryNamesData[0].name;
					const updatedEntities = updateGameCategoryEntities(entities, { ...baseData, name: defaultName }, gameCategoryID,false);
					yield put(listActions.listRefresh(updatedEntities));
					const adaptedData = adaptGameCategory(res.data.data);
					yield put(actions.baseDataRefresh({ logoURL: adaptedData.logoURL }));
				}
			} else {
				const res = yield call(casinoAPI.gameCategoryCreate, preparedData);

				if (res && res.status === 200) {
					const adaptedData = adaptGameCategory(res.data.data);
					yield put(actions.baseDataRefresh({ logoURL: adaptedData.logoURL }));

					gameCategoryID = toInteger(res.data.data.id);
					yield put(actions.uiRefresh({
						gameCategoryID,
						editMode: true,
					}));
				}
			}
			yield put(actions.uiRefresh({ isBaseChanged: false }));
			yield put(actions.categoriesForSimilarListReload(gameCategoryID));

			const params = {
				website_id: restoreWebsite(),
			};

			let nameDataResponse = null;
			if (isNamesChanged && isAliasChanged) { //both

				errorMessage = messages.errorBaseDataSave;
				const preparedList = prepareNamesData(gameCategoryNamesData, gameCategoryID);
				nameDataResponse = yield call(casinoAPI.gameCategoryNamesUpdate, gameCategoryID, preparedList, params);
				yield put(actions.uiRefresh({ isNamesChanged: false, isAliasChanged: false }));

			} else if (isNamesChanged || isSeoChanged) { // names

				errorMessage = messages.errorNamesUpdate;
				const preparedList = prepareNamesData(namesData, gameCategoryID);
				nameDataResponse = yield call(casinoAPI.gameCategoryNamesUpdate, gameCategoryID, preparedList, params);
				yield put(actions.uiRefresh({ isNamesChanged: false }));

			} else if (isAliasChanged) { //alias

				errorMessage = messages.errorAliasUpdate;
				const preparedList = prepareNamesData(gameCategoryNamesData, gameCategoryID);
				nameDataResponse = yield call(casinoAPI.gameCategoryNamesUpdate, gameCategoryID, preparedList, params);
				yield put(actions.uiRefresh({ isAliasChanged: false }));

			} else {
				errorMessage = messages.errorBaseDataSave;
				const preparedList = prepareNamesData(gameCategoryNamesData, gameCategoryID);
				nameDataResponse = yield call(casinoAPI.gameCategoryNamesUpdate, gameCategoryID, preparedList, params);
				yield put(actions.uiRefresh({ isAliasChanged: false }));
			}

			const adaptedNameData = adaptNamesData(nameDataResponse.data.data, baseData.name, contentLanguageList);
			yield put(actions.namesDataRefresh(adaptedNameData));
			showSuccess(messages.successBaseDataSave);

		} catch (error) {
			const updatedEntities = updateGameCategoryEntities(entities, null, gameCategoryID,false);
			yield put(listActions.listRefresh(updatedEntities));

			isError = true;
			showError(errorMessage);
		}

		// if (!isError && !closeModal) {
		// 		yield put(actions.baseDataReload(gameCategoryID, true));
		// 	if (isNamesChanged) {
		// 		yield put(actions.namesDataReload(gameCategoryID));
		// 	}
		// }

		const visible = !((closeModal && !isError));

		yield put(actions.uiRefresh({
			loading: false,
			visible,
		}));
		if (!visible) {
			yield put(actions.dataReset());
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* namesDataReload() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { baseData, contentLanguageList	} = yield select(getStoreData);

		const { gameCategoryID } = action.data;
		let namesData = adaptNamesData([], baseData.name, contentLanguageList	);
		try {
			const res = yield call(casinoAPI.gameCategoryNamesList, gameCategoryID);
			if (res && res.status === 200) {
				namesData = adaptNamesData(res.data.data, baseData.name, contentLanguageList	);
			}

		} catch (error) {
			showError(messages.errorNamesReload);
			logger.log(error);
		}

		yield put(actions.namesDataRefresh(namesData));
		yield put(actions.uiRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

function* similarCategoryListReload() {

	yield takeEvery(actions.CASINO_CATEGORY_LIST_FOR_SIMILAR_RELOAD, function* (action) {

		const { gameCategoryID } = action.data;
		const storeData = yield select(getStoreData);
		const { websiteID } = storeData;
		const params = {
			website_id: websiteID,
		};
		try {
			const res = yield call(casinoAPI.categoriesForSimilarListReload, gameCategoryID, params);
			if (res && res.status === 200) {
				// const adaptedList = adaptGameCategoryListForSimilar(res.data.data);
				yield put(actions.categoryListForSimilarListRefrash(res.data.data));
			}

		} catch (error) {
			showError(messages.errorCategories);
			logger.log(error);
		}

		yield put(actions.uiRefresh({
			loading: false,
		}));
	});
}
function* categorySimilarListReload() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_SIMILARS_LIST_RELOAD, function* (action) {

		const { gameCategoryID } = action.data;
		const storeData = yield select(getStoreData);
		const { websiteID } = storeData;
		const params = {
			website_id: websiteID,
		};
		try {
			const res = yield call(casinoAPI.categorySimilarListReload, gameCategoryID, params);
			if (res && res.status === 200) {
				yield put(actions.similarsDataRefresh(res.data.data));
			}

		} catch (error) {
			showError(messages.errorSimilarsList);
			logger.log(error);
		}

		yield put(actions.uiRefresh({
			loading: false,
		}));
	});
}

function* categorySimilarDataDelete() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_SIMILARS_DATA_DELETE, function* (action) {

		const { gameCategoryID } = action.data;
		const storeData = yield select(getStoreData);
		const { websiteID } = storeData;
		const params = {
			website_id: websiteID,
		};
		try {
			const res = yield call(casinoAPI.categorySimilarDataDelete, gameCategoryID, params);
			if (res && res.status === 200) {
				showSuccess(messages.successDelateSimilar);
				// yield put(actions.similarsListReload(gameCategoryID));
			}

		} catch (error) {
			showError(messages.errorDelateSimilar);
			logger.log(error);
		}

		yield put(actions.uiRefresh({
			loading: false,
		}));
	});
}

function* categorySimilarDataUpdate() {

	yield takeEvery(actions.CASINO_GAME_CATEGORY_SIMILARS_DATA_UPDATE, function* (action) {
		const storeData = yield select(getStoreData);
		const { similars, websiteID } = storeData;
		const { gameCategoryID } = action.data;
		const params = {
			website_id: websiteID,
		};
		try {
			const res = yield call(casinoAPI.categorySimilarDataUpdate, gameCategoryID, similars, params);
			if (res && res.status === 200) {
				yield put(actions.similarsDataRefresh(res.data.data));
			}

		} catch (error) {
			showError(messages.errorDelateSimilar);
			logger.log(error);
		}

		yield put(actions.uiRefresh({
			loading: false,
		}));
	});
}

export default function* casinoGameCategoryModalSaga() {
	yield all([
		fork(baseDataReload),
		fork(gameCategorySave),
		fork(namesDataReload),
		fork(similarCategoryListReload),
		fork(categorySimilarListReload),
		fork(categorySimilarDataDelete),
		fork(categorySimilarDataUpdate),
	]);
}
