import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';

import { showError, showSuccess } from '../../../../helpers/notifications';
import { betsAPI } from '../../../../helpers/api/bets';

import { getHistoryTypeName, prepareVoidBetsData } from '../betHistoryUtils/utils';

const prefix = 'sport.events.betToolbar';

const messages = {
	errorVoid  : `${prefix}.errorVoid`,
	successVoid: `${prefix}.successVoid`,
};

function getStoreData({ Sport: { Events } }) {
	const { BetHistoryToolbar, EventBetHistory, MarketBetHistory, SelectionBetHistory } = Events;
	const toolbarData = BetHistoryToolbar.get('toolbarData');

	return {
		toolbarData,
		eventSelectedIDs    : EventBetHistory.get('selectedIDs'),
		marketSelectedIDs   : MarketBetHistory.get('selectedIDs'),
		selectionSelectedIDs: SelectionBetHistory.get('selectedIDs'),
	};
}

function* voidBets() {

	yield takeEvery(actions.VOID_BETS, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { betHistoryType, onSuccess } = action.data;
		const { toolbarData } = yield select(getStoreData);

		const sectionName = getHistoryTypeName(betHistoryType);
		const sectionData = toolbarData[sectionName];

		const preparedData = prepareVoidBetsData(betHistoryType, sectionData);

		try {
			const res = yield call(betsAPI.voidBets, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successVoid);
				onSuccess();
			}

		} catch (error) {
			showError(messages.errorVoid, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* voidSelectedBets() {

	yield takeEvery(actions.VOID_SELECTED_BETS, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { betHistoryType, onSuccess } = action.data;
		const storeData = yield select(getStoreData);

		const sectionName = getHistoryTypeName(betHistoryType);
		const selectedIDs = storeData[`${sectionName}SelectedIDs`];

		const preparedData = {
			ids: selectedIDs,
		};

		try {
			const res = yield call(betsAPI.voidBets, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successVoid);
				onSuccess();
			}

		} catch (error) {
			showError(messages.errorVoid, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* betHistoryToolbarSaga() {
	yield all([
		fork(voidBets),
		fork(voidSelectedBets),
	]);
}
