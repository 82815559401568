const actions = {

	REPORT_USERS_DATA_RELOAD   : 'REPORT_USERS_DATA_RELOAD',
	REPORT_USERS_DATA_REFRESH  : 'REPORT_USERS_DATA_REFRESH',
	REPORT_USERS_FIELDS_REFRESH: 'REPORT_USERS_FIELDS_REFRESH',
	REPORT_USERS_UI_REFRESH    : 'REPORT_USERS_UI_REFRESH',

	dataReload: () => ({
		type: actions.REPORT_USERS_DATA_RELOAD,
	}),
	dataRefresh: data => ({
		type: actions.REPORT_USERS_DATA_REFRESH,
		data,
	}),
	fieldsRefresh: data => ({
		type: actions.REPORT_USERS_FIELDS_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.REPORT_USERS_UI_REFRESH,
		data,
	}),
};

export default actions;
