import { Map } from 'immutable';
import { fill } from '../../helpers/utils';
import actions from './actions';
import { defaultSportList } from './utils';

const initState = new Map({
	sportList    : defaultSportList,
	providerList : [],
	riskGroupList: [],
	adminList    : [],

	UI: { loading: false },
});

export default function commonReducer(state = initState, action) {

	switch (action.type) {
	//for all UI
		case actions.COMMON_UI_REFRESH: {
			const UI  = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}
		// sport
		case actions.COMMON_SPORT_LIST_SUCCESS:
			return state.set('sportList', action.data);

		case actions.COMMON_SPORT_LIST_ERROR:
			return state.set('sportList', []);

		// providers
		case actions.COMMON_PROVIDER_LIST_SUCCESS:
			return state.set('providerList', action.data);

		case actions.COMMON_PROVIDER_LIST_ERROR:
			return state.set('providerList', []);

		// risk groups
		case actions.COMMON_RISK_GROUP_LIST_SUCCESS:
			return state.set('riskGroupList', action.data);

		case actions.COMMON_RISK_GROUP_LIST_ERROR:
			return state.set('riskGroupList', []);

		// admins
		case actions.COMMON_ADMIN_LIST_SUCCESS:
			return state.set('adminList', action.data);

		case actions.COMMON_ADMIN_LIST_ERROR:
			return state.set('adminList', []);

		default:
			return state;
	}
}
