const actions = {
	BLOCKED_IPS_LIST_RELOAD : 'BLOCKED_IPS_LIST_RELOAD',
	BLOCKED_IPS_LIST_REFRESH: 'BLOCKED_IPS_LIST_REFRESH',

	BLOCKED_IPS_LIST_FILTER_REFRESH    : 'BLOCKED_IPS_LIST_FILTER_REFRESH',
	BLOCKED_IPS_LIST_FILTER_APPLY      : 'BLOCKED_IPS_LIST_FILTER_APPLY',
	BLOCKED_IPS_LIST_PAGINATION_REFRESH: 'BLOCKED_IPS_LIST_PAGINATION_REFRESH',
	BLOCKED_IPS_LIST_UI_REFRESH        : 'BLOCKED_IPS_LIST_UI_REFRESH',
	BLOCKED_IPS_LIST_DELETE_ITEM       : 'BLOCKED_IPS_LIST_DELETE_ITEM',
	FILTER_RESET                       : 'BLOCKED_IPS_LIST_FILTER_RESET',
	
	filterReset: () => ({
		type: actions.FILTER_RESET,
	}),
	listReload: () => ({
		type: actions.BLOCKED_IPS_LIST_RELOAD,
	}),

	listRefresh: entities => ({
		type: actions.BLOCKED_IPS_LIST_REFRESH,
		data: {
			entities,
		},
	}),

	filterRefresh: data => ({
		type: actions.BLOCKED_IPS_LIST_FILTER_REFRESH,
		data,
	}),

	filterApply: () => ({
		type: actions.BLOCKED_IPS_LIST_FILTER_APPLY,
	}),

	paginationRefresh: data => ({
		type: actions.BLOCKED_IPS_LIST_PAGINATION_REFRESH,
		data,
	}),

	deleteItem: blockedIp => ({
		type: actions.BLOCKED_IPS_LIST_DELETE_ITEM,
		data: {
			blockedIp,
		},
	}),

	uiRefresh: data => ({
		type: actions.BLOCKED_IPS_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
