import { Map } from 'immutable';
import { fill } from '../../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	intProvGamLimits: {},
	providersWlimits: {},
	gamesLimits     : [],

	limitRowLoading: {
		1: {},
		2: {},
		6: {},
	},

	UI: {
		loading      : false,
		limitsLoading: false,
	},
});

export default function casino(state = initState, action) {

	switch (action.type) {

		case actions.LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_INTEGRATOR_PROVIDER_REFRESH: {
			return state.set('intProvGamLimits', action.data);
		}

		case actions.LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_PROVIDER_REFRESH: {
			return state.set('providersWlimits', action.data);
		}

		case actions.LIMIT_AND_RESTRICTION_CASINO_LIMITS_REFRESH: {
			return state.set('gamesLimits', action.data);
		}

		case actions.LIMIT_AND_RESTRICTION_CASINO_UPDATED_ROW_REFRESH: {
			const target = state.get('gameListByprovider');
			const result = fill(action.data, target);
			return state.set('gameListByprovider', result);
		}

		case actions.LIMIT_AND_RESTRICTION_CASINO_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;

	}

}
