import validate from 'validate.js';

validate.options = { format: 'flat' };
const prefix = 'profile.modal';

const messages = {
	errorMatchPass: `${prefix}.errorMatchPass`,
};
const userConstrants = {
	userName: {
		presence: true,
		length  : {
			minimum: 3,
			message: '^profile.username.userMin3',

		},
	},
	email: {
		email: {
			message: '^profile.email.invalid',
		},
	},
};

export function validateBaseData(baseData) {

	let errors = [];
	const commonErrors = validate(baseData, userConstrants);
	if (commonErrors) {
		errors = errors.concat(commonErrors);
	}

	if (baseData.password) {
		if (baseData.password !== baseData.confirmPassword) {
			errors.push(messages.errorMatchPass);
		}
	}

	return errors.length > 0 ? errors : null;
}
