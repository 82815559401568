import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../utility';

export const fields = {
	id         : 'payway_id',
	name       : 'name',
	logo       : 'logo_url',
	statusID   : 'status_id',
	orderID    : 'order_id',
	currencyIDs: 'currency_id',
	channelID  : 'channel_id',
	langID     : 'lang_id',
	max        : 'max',
	min        : 'min',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptPaywaysList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	adaptedData.forEach(item => {
		if (!item.name) {
			item.name = item.id;
		}
	});

	return createEntities(adaptedData);
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'orderID', fields.orderID);

	adapter.addField(rules.string, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'logo', fields.logo);

	adapter.addField(rules.arrayID, 'currencyIDs', fields.currencyIDs);

	return adapter;
}
