import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Formatter from '../../../helpers/formatter';

const fields = {
	id           : 'id',
	loginDate    : 'login_date',
	logoutDate   : 'logout_date',
	sessionLength: 'session_length',
	ip           : 'ip',
	ipInfo       : 'ip_info',
	country      : 'country',
	region       : 'region',
	city         : 'city',
	zip          : 'zip',
	fullCount    : 'full_count',
};

export function adaptLoginsList(rawList) {
	if (!isArray(rawList) || isEmpty(rawList)) {
		return [];
	}

	const list = rawList.map(item => {
		const newItem = {
			id           : item[fields.id],
			ip           : item[fields.ip],
			loginDate    : Formatter.fullDateTime(item[fields.loginDate]),
			logoutDate   : Formatter.fullDateTime(item[fields.logoutDate]),
			sessionLength: Formatter.humanDuration(item[fields.sessionLength]),
			fullCount    : item[fields.fullCount],
		};

		const ipInfo = item[fields.ipInfo];
		if (ipInfo) {
			newItem.country = ipInfo[fields.country];
			newItem.region  = ipInfo[fields.region];
			newItem.city    = ipInfo[fields.city];
			newItem.zip     = ipInfo[fields.zip];
		}

		return newItem;
	});

	return list;
}
