import { apiRequest } from './index';

function usersList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/firebase/subscribe',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function sendNotification(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/firebase/publish',
		data,
		params,
	};

	return apiRequest(req);
}

export const pushNotificationsAPI = {
	usersList,
	sendNotification,
};
