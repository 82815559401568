const actions = {
	UPCOMING_EVENTS_LIST_RELOAD : 'UPCOMING_EVENTS_LIST_RELOAD',
	UPCOMING_EVENTS_LIST_REFRESH: 'UPCOMING_EVENTS_LIST_REFRESH',
	UPCOMING_EVENTS_LIST_SAVE   : 'UPCOMING_EVENTS_LIST_SAVE',

	UPCOMING_EVENTS_UI_REFRESH: 'UPCOMING_EVENTS_UI_REFRESH',

	listReload: () => ({
		type: actions.UPCOMING_EVENTS_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.UPCOMING_EVENTS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	listSave: () => ({
		type: actions.UPCOMING_EVENTS_LIST_SAVE,
	}),

	uiRefresh: data => ({
		type: actions.UPCOMING_EVENTS_UI_REFRESH,
		data,
	}),
};

export default actions;
