import isArray from 'lodash/isArray';
import EntityAdapter from '../../../../helpers/entityAdapter';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';

const { isValidArray, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	maxBonusAmount: isValidArray,
	created       : isValidDateRange,
};

export const fields = {
	ID              : 'id',
	userID          : 'user_id',
	marketName      : 'market_name',
	maxBonusAmount  : 'amount',
	created         : 'start_date',
	minWageringOdd  : 'min_wagering_odd',
	wageringTurnover: 'wagering_turnover',
	currencyID      : 'currency_id',
	// filter
	startDateFrom   : 'start_date_from', // Date
	startDateTo     : 'start_date_to',   // Date
	amountFrom      : 'amountFrom',      // Amount
	amountTo        : 'amountTo',        // Amount
	// for certain user
	givenDate       : 'start_date',
	expireDate      : 'end_date',
};

const listAdapter = createListAdapter();

/**
 * *Adapt
 */
export function adaptList(rawData = []) {
	if (!isArray(rawData)) return {};

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return adaptedData;
}

export function getListParams(filter, sorting, pagination) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'marketName',       fields.marketName);
	builder.addField(rules.isID, 'maxBonusAmount',   fields.maxBonusAmount);
	builder.addField(rules.isID, 'minWageringOdd',   fields.minWageringOdd);
	builder.addField(rules.isID, 'wageringTurnover', fields.wageringTurnover);
	builder.addField(rules.isID, 'currencyID',      fields.currencyID);

	builder.addRangeField(rules.isDateTimeRange, filter.created, [
		fields.startDateFrom,
		fields.startDateTo,
	]);

	builder.addRangeField(rules.isNumberRange, filter.maxBonusAmount, [
		fields.amountFrom,
		fields.amountTo,
	]);

	builder.addField(rules.isNumber, 'userID', fields.userID);

	const params = builder.biuldParams(filter);

	return params;
}

/**
 * *Adapter
 */
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'marketName', fields.marketName);

	adapter.addField(rules.id, 'userID',     fields.userID);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.id, 'ID', fields.ID);

	adapter.addField(rules.intOrNull, 'maxBonusAmount',   fields.maxBonusAmount);

	adapter.addField(rules.fullDate, 'created',    fields.created);
	adapter.addField(rules.fullDate, 'givenDate',  fields.givenDate);
	adapter.addField(rules.fullDate, 'expireDate', fields.expireDate);

	adapter.addField(rules.numberOrNull, 'minWageringOdd',   fields.minWageringOdd);
	adapter.addField(rules.numberOrNull, 'wageringTurnover', fields.wageringTurnover);

	return  adapter;
}
