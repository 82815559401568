import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IntlMessage from '../../utility/intlMessages';
import FormSingleUploader from '../../FormControls/FormSingleUploader';
import Card from '../card';
import { Wrapper, ImageHolder } from './ImageUploadCard.style';

const lang = {
	image  : <IntlMessage id = "common.image" />,
	noImage: <IntlMessage id = "common.noImage" />,
};

class ImageUploadCard extends Component {

	static propTypes = {
		id       : PropTypes.string.isRequired,
		fileLink : PropTypes.string,
		disabled : PropTypes.bool,
		height   : PropTypes.string,
		className: PropTypes.string,
		title    : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

		onUploadSuccess: PropTypes.func,
		onUploadError  : PropTypes.func,
		onClear        : PropTypes.func,
	};

	static defaultProps = {
		fileLink : '',
		disabled : false,
		title    : '',
		className: '',

		onUploadSuccess: () => {},
		onUploadError  : () => {},
		onClear        : () => {},
	};

	render() {
		const { id, height, title, disabled, fileLink, className, onUploadSuccess, onUploadError, onClear, ...rest } = this.props;
		const cardProps = title ? { title } : {};
		const isImage   = Boolean(fileLink);

		return (
			<Wrapper height={height} className={className}>
				<Card {...cardProps}>
					<FormSingleUploader
						label={lang.image}
						labelWidth={20}
						id={id}
						defaultFileLink={fileLink}
						disabled={disabled}
						onUploadSuccess={onUploadSuccess}
						onUploadError={onUploadError}
						onClear={onClear}
						{...rest}
					/>
					<ImageHolder>
						{isImage && (<img src={fileLink} alt="" />)}
						{!isImage && (<p>{lang.noImage}</p>)}
					</ImageHolder>
				</Card>
			</Wrapper>
		);
	}
}

export default ImageUploadCard;
