import EntityAdapter from '../../../helpers/entityAdapter';
import { createEntities } from '../../utility';

export const fields = {
	id   : 'id',
	name : 'name',
	games: 'games',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------
export function adaptTagsList(rawData = []) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return createEntities(adaptedData);
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.arrayObject, 'games', fields.games);

	return adapter;
}
