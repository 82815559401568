import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import notificationActions from '../../notifications/actions';
import { adaptMessage } from './utils';
import { NOTIFICATION_TYPES } from '../../../constants/notifications';

function getStoreData({ Messages, Notifications }) {

	return {
		messagesIDs   : Messages.get('messagesIDs'),
		entities      : Messages.get('entities'),
		newMessagesIDs: Messages.get('newMessagesIDs'),
		newEntities   : Messages.get('newMessagesEntities'),
		messages      : Notifications.get('messages'),
	};
}

function* notifyCreateMessages() {

	yield takeEvery(actions.MESSAGES_SOCKET_NOTIFY_CREATE_MESSAGES, function* (action) {

		const { entities, messagesIDs, newMessagesIDs, newEntities, messages } = yield select(getStoreData);
		const { request } = action.data;
		const latestList = cloneDeep(messages.latestList);
		latestList.push(request);
		const adaptedRequest = adaptMessage(request);

		entities[adaptedRequest.requestID] = adaptedRequest;
		messagesIDs.unshift(adaptedRequest.requestID);

		adaptedRequest.messages.forEach(message => {
			message = merge(message, adaptedRequest, adaptedRequest.author);
			delete message.messages;
			delete message.author;
			message.name = `${message.firstName} ${message.lastName}`;
			message.bucket = message.bucket ? message.bucket : 'Inbox';
			if (message.bucket !== 'Sent' && !message.seen) {
				newEntities[message.messageID] = message;
				newMessagesIDs.unshift(message.messageID);
			}
		});

		const notification = {
			count: newMessagesIDs.length,
			latestList,
		};

		yield put(notificationActions.dataRefresh(NOTIFICATION_TYPES.messages, notification));
		yield put(actions.dataRefresh(cloneDeep(messagesIDs), cloneDeep(entities)));
		yield put(actions.newMessagesListRefresh(cloneDeep(newMessagesIDs), cloneDeep(newEntities)));
	});
}

function* notifyUpdateMessages() {

	yield takeEvery(actions.MESSAGES_SOCKET_NOTIFY_UPDATE_MESSAGES, function* (action) {

		const { entities, messagesIDs, newMessagesIDs, newEntities, messages } = yield select(getStoreData);

		const rawMessage = action.data;
		const latestList = cloneDeep(messages.latestList);
		const notificationRequest = latestList.find(request => request.id === rawMessage.request_id);
		notificationRequest.messages.push(rawMessage);

		const message = adaptMessage(rawMessage);
		message.requestID = toInteger(rawMessage.request_id);
		const request = cloneDeep(entities[message.requestID]);
		const result = merge(request.messages[0], message);
		result.bucket = 'Inbox';
		entities[message.requestID].messages.push(result);
		newEntities[result.messageID] = result;
		newMessagesIDs.unshift(result.messageID);

		const notification = {
			count: newMessagesIDs.length,
			latestList,
		};

		yield put(notificationActions.dataRefresh(NOTIFICATION_TYPES.messages, notification));
		yield put(actions.dataRefresh(cloneDeep(messagesIDs), cloneDeep(entities)));
		yield put(actions.newMessagesListRefresh(cloneDeep(newMessagesIDs), cloneDeep(newEntities)));
	});
}

export default function* messagesExternalSaga() {
	yield all([
		fork(notifyCreateMessages),
		fork(notifyUpdateMessages),
	]);
}
