import { fork, all, takeEvery, select, put, call } from 'redux-saga/effects';
import { checkMultiply, gameToPage, generateIDs, mergeArrays, prepareBulkData } from './utils';
import { fieldNames } from '../../../containers/Casino/modals/BulkModal/utils';
import { casinoAPI } from '../../../helpers/api/casino';
import notifications from '../../../helpers/notifications';
import { resetTheSameValue, theSameValue } from '../../../helpers/utils';
import actionsGames from '../games/actions';
import actions from './actions';
import { logger } from '../../../helpers/logger';

const messages = {
	errorTagsReload: 'casino.game.error.tags.reload',
	errorBulk      : 'casinoGames.tabGames.error.bulk',
	successBulk    : 'casinoGames.tabGames.bulk.succsess',
};

function getStoreData({ Casino, App, Tables }) {
	const baseData = Casino.CasinoGameBulk.get( 'baseData' );
	const bulkGames = Casino.CasinoGameBulk.get( 'bulkGames' );
	const tags = Casino.CasinoGameBulk.get( 'tags' );
	const gamePages = Casino.CasinoGameBulk.get( 'gamesListWithPage' );
	const { bulkGameIDs } = baseData;
	const gameList = Casino.Games.get( 'gamesList' );
	const gameEntities = Casino.Games.get( 'entities' );
	const { langID } = Casino.Game.get( 'UI' );
	const baseDataGame = Casino.Game.get( 'baseData' );
	const websiteID = App.get( 'websiteID' );
	const { pagination } = Tables.get( 'GAME' );
	return {
		bulkGameIDs,
		gameList,
		gameEntities,
		baseData,
		bulkGames,
		tags,
		websiteID,
		langID,
		pagination,
		gamePages,
		baseDataGame,
	};
}

function* gamesBulkRefresh() {

	yield takeEvery(actions.CASINO_GAME_BULK_DATA_REFRESH, function* (action) {
		yield put(actions.bulkUIRefresh({ loading: true }));
		const { bulkGames, pagination, gamePages } = yield select(getStoreData);
		const { currentPage } = pagination;
		const { game, checked, all } = action.data;

		let games = [];
		let gamesInPage = 0;
		if (!all) {
			const countInPage = gamePages[currentPage] ? gamePages[currentPage] : 0;
			games = checked ? [...bulkGames, game] : bulkGames.filter(item => item.id !== game.id);
			gamesInPage = checked ? countInPage + 1 : countInPage - 1;
		} else {
			games = checked ? [...resetTheSameValue(bulkGames, game), ...theSameValue(bulkGames, game)] : resetTheSameValue(bulkGames, game);
			gamesInPage = checked ? game.length : 0;
		}

		yield call(putDataRefreshBulk, games, gamesInPage);
	});
}

function* refreshBaseData() {
	yield takeEvery(actions.BASE_DATA_REFRESH, function* (data) {
		const { baseData, tags } = yield select(getStoreData);
		const { newIDs, fieldName, remove, tegID } = data.data;

		switch (fieldName) {
			case fieldNames.STATUS: {
				yield put(actions.bulkData({ ...baseData, statusIDs: newIDs }));
				break;
			}
			case fieldNames.CATEGORY: {
				yield put(actions.bulkData({ ...baseData, categoryIDs: newIDs }));
				break;
			}
			case fieldNames.CUSTOM_PROVIDER: {
				yield put(actions.bulkData({ ...baseData, customProviderIDs: newIDs }));
				break;
			}
			case fieldNames.TAGS: {
				yield put(actions.bulkData({ ...baseData, tagIDs: newIDs }));
				if (remove) {
					yield put(actions.putTagsCase(tags.filter(item => item.id !== tegID)));
				}
				break;
			}
			case fieldNames.COUNTRY: {
				yield put(actions.bulkData({ ...baseData, restrictedCountries: newIDs }));
				break;
			}
			case fieldNames.CURRENCY: {
				yield put(actions.bulkData({ ...baseData, currencyIDs: newIDs }));
				break;
			}
			default:
				break;
		}
	});
}

function* getTags() {
	yield takeEvery(actions.GET_SEARCH_TAG, function* ({ data }) {
		const { langID, websiteID } = yield select(getStoreData);
		const params = { lang_id: langID, website_id: websiteID, name: data };
		try {
			const res = yield call(casinoAPI.getTagNames, null, params);
			if (res && res.status === 200) {
				yield put(actions.putTagsCase(res.data.data));
			}

		} catch (error) {
			notifications.showError(messages.errorTagsReload, error);
			logger.log(error);
		}
	});
}

function* gamesBulkReset() {

	yield takeEvery(actions.UNSELECT_BULK_DATA, function* () {
		const obj = {
			tagIDs             : [],
			currencyIDs        : [],
			customProviderIDs  : [],
			categoryIDs        : [],
			statusIDs          : [],
			bulkGameIDs        : [],
			restrictedCountries: [],
		};
		yield put(actions.bulkDataGames([]));
		yield put(actions.bulkData(obj));
		yield put(actions.putTagsCase([]));
		yield put(actions.bulkUIRefresh({ checked: false }));

	});
}

function* gamesBulkUpdate() {
	yield takeEvery(actions.BULK_UPDATE, function* (data) {
		yield put(actions.bulkUIRefresh({ loading: true }));
		const { baseData } = yield select(getStoreData);
		const { dataRequest: changedData, close } = data.data;

		const prepareData = prepareBulkData(baseData, changedData);
		try {
			const res = yield call(casinoAPI.gamesBulkEdit, prepareData);
			if (res.data.status === 200) {
				yield put(actionsGames.casinoGameListReload({ }));
				notifications.showSuccess(messages.successBulk);
				yield put(actions.bulkUIRefresh({ loading: false, closeModal: !close || false }));
			}
		} catch (e) {
			notifications.showError(messages.errorBulk, e);
		}
	});

}

function* bulkRefreshAfterEditGame() {

	yield takeEvery(actions.CASINO_GAME_BULK_DATA_REFRESH_EDIT_GAME, function* () {
		yield put(actions.bulkUIRefresh({ loading: true }));
		const { bulkGames, pagination, baseDataGame } = yield select(getStoreData);
		const { currentPage } = pagination;
		let games = mergeArrays(bulkGames, baseDataGame);

		yield call(putDataRefreshBulk, games, currentPage);
	});
}

function* putDataRefreshBulk(games, gamesInPage) {
	yield put(actions.bulkUIRefresh({ loading: true }));
	const { gameList, gameEntities, bulkGameIDs } = yield select(getStoreData);
	const gamesLength = gameList.length;
	const pageData = gameToPage(bulkGameIDs, gameEntities );
	const multiplyCustomProvider = checkMultiply(games, 'customProviderIDs');
	const multiplyCurrency = checkMultiply(games, 'currencyIDs');
	const multiplyCountry = checkMultiply(games, 'restrictedCountries');
	const IDs = generateIDs(games);
	yield put(actions.bulkDataGames(games));
	yield put(actions.currentPageIDsRefresh(pageData));
	yield put(actions.bulkData(IDs));
	yield put(actions.bulkUIRefresh({
		checked              : gamesInPage === gamesLength,
		indeterminate        : gamesInPage >= 1 ? gamesInPage < gamesLength : false,
		multipleCustomProvide: multiplyCustomProvider,
		multipleCurrency     : multiplyCurrency,
		loading              : false,
		multiplyCountry,
	}));
}

export default function* casinoGamesBulkSaga() {
	yield all([
		fork(gamesBulkRefresh),
		fork(gamesBulkReset),
		fork(refreshBaseData),
		fork(getTags),
		fork(gamesBulkUpdate),
		fork(bulkRefreshAfterEditGame),
	]);
}
