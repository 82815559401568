import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../utility';

export const fields = {
	id          : 'id',
	mainTabID   : 'main_tab_id',
	name        : 'name',
	active      : 'active',
	mainNews    : 'main_news',
	general     : 'general',
	position    : 'position',
	url         : 'url',
	date        : 'date',
	imageURL    : 'image',
	youTubeURL  : 'youtube_id',
	langID      : 'lang_id',
	contentShort: 'content_short',
	contentFull : 'content_full',
};

const listAdapter = createListAdapter();

export function getListParams(pagination, sorting = null) {

	const builder = new ParamsBuilder();
	//const rules   = builder.RULES;

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('limit', pagination.itemsPerPage);
	builder.addValue('page', pagination.currentPage);

	const params = builder.biuldParams({});

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptNewsList(rawData = []) {

	const adaptedData = listAdapter.adaptList(rawData);
	const entities = createEntities(adaptedData);
	const listIDs = Object.keys(entities);

	const result = {
		listIDs,
		entities,
	};

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'mainTabID', fields.mainTabID);
	adapter.addField(rules.id, 'active', fields.active);
	adapter.addField(rules.id, 'mainNews', fields.mainNews);
	adapter.addField(rules.id, 'general', fields.general);
	adapter.addField(rules.id, 'langID', fields.langID);

	adapter.addField(rules.positiveNumber, 'position', fields.position);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'url', fields.url);
	adapter.addField(rules.string, 'imageURL', fields.imageURL);
	adapter.addField(rules.string, 'youTubeURL', fields.youTubeURL);
	adapter.addField(rules.string, 'contentShort', fields.contentShort);
	adapter.addField(rules.string, 'contentFull', fields.contentFull);

	adapter.addField(rules.fullDate, 'date', fields.date);

	return adapter;
}
