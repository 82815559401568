const actions = {

	TOPICS_LIST_RELOAD      : 'TOPICS_LIST_RELOAD',
	TOPICS_LIST_REFRESH     : 'TOPICS_LIST_REFRESH',
	TOPICS_LIST_DELETE_TOPIC: 'TOPICS_LIST_DELETE_TOPIC',
	TOPICS_LIST_REFRASH   		: 'TOPICS_LIST_REFRASH',
	TOPICS_LIST_UI_REFRESH		: 'TOPICS_LIST_UI_REFRESH',

	uiRefresh: data => ({
		type: actions.TOPICS_LIST_UI_REFRESH,
		data,
	}),
	listReload: () => ({
		type: actions.TOPICS_LIST_RELOAD,
	}),

	listRefresh: (entities) => ({
		type: actions.TOPICS_LIST_REFRASH,
		data: {
			entities,
		},
	}),

	deleteTopic: topicID => ({
		type: actions.TOPICS_LIST_DELETE_TOPIC,
		data: {
			topicID,
		},
	}),
};

export default actions;
