const actions = {

	CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_RELOAD: 'CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_RELOAD',
	CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_SET   : 'CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_SET',
	CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_SAVE  : 'CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_SAVE',

	ctsReload: userID => ({
		type: actions.CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_RELOAD,
		data: {
			userID,
		},
	}),
	ctsSave: () => ({
		type: actions.CONTENT_CUSTOMIZABLE_TABLES_SETTINGS_SAVE,
	}),
};
export default actions;
