import { apiRequest } from './index';
/* ------------------------------------------------------------------------------------------------
    Main
------------------------------------------------------------------------------------------------ */

// get list of events
function eventList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/events',
		params,
	};
	return apiRequest(req);
}

// get event
function eventGet(eventID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/events/getEvent/${eventID}`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// update event
function eventUpdate(eventID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/events/${eventID}`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
    Additional
------------------------------------------------------------------------------------------------ */

/* --------------------------------------------------------------------------------------- Names */
// GET: event names list
function eventNamesList(eventID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/events/${eventID}/names`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update event names list
function eventNamesUpdate(eventID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/events/${eventID}/names`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}
function eventsNamesAutocomplete(params = {}) {
	const req = {
		method: 'GET',
		url   : '/getEventsNamesAutocomplete',
		params: { ...params },
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------------------- Orders */
// GET: event orders list
function eventOrdersList(eventID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/events/${eventID}/orders`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update event orders list
function eventOrdersUpdate(eventID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/events/${eventID}/orders`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* -------------------------------------------------------------------------- Descriptions (CMS) */
// GET: event descriptions list
function eventDecriptionsList(eventID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/events/${eventID}/cms`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update event orders list
function eventDecriptionsUpdate(eventID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/events/${eventID}/cms`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------ Settings */
// GET: event settings list
function eventSettingsList(eventID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/events/${eventID}/settings`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update event settings list
function eventSettingsUpdate(eventID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/events/${eventID}/settings`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------ Dynamic Limits */
// GET: event Dynamic Limits list
function eventDynamicLimitsList(eventID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/events/${eventID}/dynamicLimits`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update event Dynamic Limits list
function eventDynamicLimitsUpdate(eventID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/events/${eventID}/dynamicLimits`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
    Event Market
------------------------------------------------------------------------------------------------ */

// get markets of event
function eventMarketList(eventID, includeSelections = true, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/eventMarket',
		params: {
			...additionalParams,
			event_id          : eventID,
			include_selections: includeSelections,
		},
	};

	return apiRequest(req);
}

// cancel event market
function eventMarketCancel(eventMarketID, data = {}, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/eventMarket/${eventMarketID}/cancel`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

// update event market
function eventMarketUpdate(eventMarketID, data = {}, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/eventMarket/${eventMarketID}`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

// get event market settings
function eventMarketSettingsList(eventMarketID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/eventMarket/${eventMarketID}/settings`,
		params: { ...additionalParams },
	};

	return apiRequest(req);
}

// update event market settings
function eventMarketSettingsUpdate(
	eventMarketID,
	data = [],
	additionalParams = {}
) {
	const req = {
		method: 'POST',
		url   : `/eventMarket/${eventMarketID}/settings`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

// get event market dynamic limits
function eventMarketDynamicLimitsList(eventMarketID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/eventMarket/${eventMarketID}/dynamicLimits`,
		params: { ...additionalParams },
	};

	return apiRequest(req);
}

// update event market dynamic limits
function eventMarketDynamicLimitsUpdate(
	eventMarketID,
	data = [],
	additionalParams = {}
) {
	const req = {
		method: 'POST',
		url   : `/eventMarket/${eventMarketID}/dynamicLimits`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

/* ------------------------------------------------------------------------------------------------
    Event Market Selection
------------------------------------------------------------------------------------------------ */

// cancel event market selection
function eventMarketSelectionCancel(
	eventMarketSelectionID,
	data = {},
	additionalParams = {}
) {
	const req = {
		method: 'POST',
		url   : `/eventSelection/${eventMarketSelectionID}/cancel`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

// update event market selection
function eventMarketSelectionUpdate(
	eventMarketSelectionID,
	data = {},
	additionalParams = {}
) {
	const req = {
		method: 'POST',
		url   : `/eventSelection/${eventMarketSelectionID}`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

// get event market selection settings
function eventMarketSelectionSettingsList(
	eventMarketSelectionID,
	additionalParams = {}
) {
	const req = {
		method: 'GET',
		url   : `/eventSelection/${eventMarketSelectionID}/settings`,
		params: { ...additionalParams },
	};

	return apiRequest(req);
}

// update event market selection settings
function eventMarketSelectionSettingsUpdate(
	eventMarketSelectionID,
	data = [],
	additionalParams = {}
) {
	const req = {
		method: 'POST',
		url   : `/eventSelection/${eventMarketSelectionID}/settings`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

// get event market selection dynamic limits
function eventMarketSelectionDynamicLimitsList(
	eventMarketSelectionID,
	additionalParams = {}
) {
	const req = {
		method: 'GET',
		url   : `/eventSelection/${eventMarketSelectionID}/dynamicLimits`,
		params: { ...additionalParams },
	};

	return apiRequest(req);
}

// update event market selection dynamic limits
function eventMarketSelectionDynamicLimitsUpdate(
	eventMarketSelectionID,
	data = [],
	additionalParams = {}
) {
	const req = {
		method: 'POST',
		url   : `/eventSelection/${eventMarketSelectionID}/dynamicLimits`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}

//get event market selection change history
function eventMarketSelectionHistory(
	eventMarketSelectionID
) {
	const req = {
		method: 'GET',
		url   : `/eventSelection/${eventMarketSelectionID}/odds_history`,
	};

	return apiRequest(req);
}
/* ------------------------------------------------------------------------------------------------
    Others
------------------------------------------------------------------------------------------------ */

// get event provider odds list
function eventProviderOddsList(eventID) {
	const req = {
		method: 'GET',
		url   : '/getEventProviderOdds',
		params: {
			event_id: eventID,
		},
	};

	return apiRequest(req);
}

function eventProviderOddsUpdate(eventID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/updateEventProviderOdds',
		data,
		params: {
			...params,
			event_id: eventID,
		},
	};

	return apiRequest(req);
}

function doUnsettle( params = {}) {
	const req = {
		method: 'POST',
		url   : '/results/unsettle/eventOrEventsMarket',
		data  : {
			...params,
		},
	};

	return apiRequest(req);
}

function fetchEventData(eventID) {
	const req = {
		method: 'GET',
		url   : `/eventSelectionStatistic/${eventID}`,
	};
	return apiRequest(req);
}

function fetchEventStatisticData(eventID) {
	const req = {
		method: 'GET',
		url   : `/eventRtmStatistic/${eventID}`,
	};
	return apiRequest(req);
}

function fetchEventBySportID(params = {}) {
	const req = {
		method: 'GET',
		url   : '/events',
		params: {
			...params,
		},
	};
	return apiRequest(req);
}

function seoData(eventID) {
	const req = {
		method: 'GET',
		url   : `/events/${eventID}/seo`,
	};
	return apiRequest(req);
}


function seoDataSave(eventID, data = {}, params = {}) {
	const req = {
		method: 'POST',
		url   : `/events/${eventID}/seo`,
		data,
		params,
	};
	return apiRequest(req);
}


/* ------------------------------------------------------------------------------------------------
    Exports
------------------------------------------------------------------------------------------------ */

export const eventsAPI = {
	// Main
	eventList,
	eventGet,
	eventUpdate,
	fetchEventBySportID,

	// Additional
	eventNamesList,
	eventNamesUpdate,
	eventOrdersList,
	eventOrdersUpdate,
	eventDecriptionsList,
	eventDecriptionsUpdate,
	eventSettingsList,
	eventSettingsUpdate,
	eventDynamicLimitsList,
	eventDynamicLimitsUpdate,

	// Event Market
	eventMarketList,
	eventMarketCancel,
	eventMarketUpdate,
	eventMarketSettingsList,
	eventMarketSettingsUpdate,
	eventMarketDynamicLimitsList,
	eventMarketDynamicLimitsUpdate,

	// Event Market Selection
	eventMarketSelectionCancel,
	eventMarketSelectionUpdate,
	eventMarketSelectionSettingsList,
	eventMarketSelectionSettingsUpdate,
	eventMarketSelectionDynamicLimitsList,
	eventMarketSelectionDynamicLimitsUpdate,
	eventMarketSelectionHistory,

	// Others
	eventProviderOddsList,
	eventProviderOddsUpdate,
	eventsNamesAutocomplete,

	// Unsettle Event ore Event market
	doUnsettle,
	//Fetch event data
	fetchEventData,
	fetchEventStatisticData,

	seoData,
	seoDataSave,
};
