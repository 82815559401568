import React from 'react';

export const BetsIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_189_59" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_189_59)">
				<path d="M12 1C5.92971 1 1 5.92971 1 12C1 18.0703 5.92971 23 12 23C18.0703 23 23 18.0703 23 12C23 5.92971 18.0703 1 12 1ZM12.2737 1.81864C15.0287 1.89108 17.5089 3.05504 19.2994 4.89408C17.162 6.59949 15.8713 8.9891 15.7326 11.5351C15.4394 11.5392 15.1463 11.5415 14.8532 11.5428C14.8153 9.05184 14.4676 6.75626 13.8957 4.94517C13.4748 3.61205 12.9484 2.51841 12.2737 1.81864ZM10.9513 1.8681L10.9479 1.87731C11.6844 2.14237 12.5344 3.33958 13.1188 5.19035C13.6586 6.89975 13.9997 9.12452 14.0382 11.5424C13.47 11.5398 12.9035 11.5321 12.3399 11.519C12.0852 8.26407 9.25742 5.49167 5.24486 4.37488C6.79651 2.99956 8.7713 2.09063 10.9513 1.8681ZM4.56131 5.04002C8.54364 5.98993 11.2399 8.58144 11.5197 11.4964C7.99095 11.3815 4.63171 11.0594 1.91805 10.5479C2.21945 8.43209 3.16969 6.52697 4.56131 5.04002ZM19.8453 5.50071C21.1479 7.07184 21.9871 9.04141 22.1543 11.2011C20.3502 11.3699 18.4581 11.4753 16.5488 11.5195C16.6913 9.22515 17.8666 7.06247 19.8453 5.50071ZM1.83535 11.3617C4.60564 11.8791 7.98973 12.2016 11.5345 12.3153C11.3544 15.3061 8.6283 17.9889 4.56033 18.9588C2.85737 17.1387 1.81481 14.6925 1.81481 12C1.81481 11.7855 1.82231 11.5729 1.83535 11.3617ZM22.185 12.0167C22.1811 14.478 21.3067 16.7331 19.8526 18.4908C17.8329 16.8996 16.6484 14.6825 16.5426 12.3368C18.4616 12.2921 20.365 12.186 22.185 12.0167ZM12.3498 12.3374C12.9104 12.3503 13.4741 12.3578 14.0391 12.3601C14.0069 14.9262 13.6348 17.2701 13.0492 19.0235C12.4638 20.7767 11.6395 21.8819 10.9279 22.1293C8.75663 21.9027 6.78983 20.9951 5.24356 19.6241C9.31527 18.4913 12.1674 15.6539 12.3498 12.3374ZM15.7278 12.3523C15.8383 14.939 17.1381 17.3707 19.3073 19.0981C17.5165 20.9414 15.0336 22.1084 12.2752 22.1814C12.908 21.5216 13.4122 20.5092 13.8222 19.2816C14.4443 17.4185 14.8219 15.0022 14.8539 12.3603C15.1452 12.3589 15.4366 12.3565 15.7278 12.3523Z" fill="#323232" stroke="#323232" strokeWidth="0.4" />
			</g>
		</svg>

	);
};
