import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	entities: {},

	UI: {
		questionID: null,
		names     : [],
		visible   : false,
		loading   : false,
		loadEntity: false,
		editMode  : false,
		closeModal: false,
		isChanged : false,
	},
});

export default function securityQuestionsListReducer(state = initState, action) {

	switch (action.type) {

		case actions.SECURITY_QUESTIONS_LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.SECURITY_QUESTIONS_LIST_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.SECURITY_QUESTIONS_LIST_UI_RESET: {
			const UI = initState.get('UI');
			return state.set('UI', UI);
		}

		default:
			return state;
	}
}
