
import React from 'react';

export const MessagesIcon = () => {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_189_34" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
				<path d="M22 0H0V22H22V0Z" fill="white" />
			</mask>
			<g mask="url(#mask0_189_34)">
				<path d="M15.3002 19.9C13.9002 20.5 12.4002 20.9 10.9002 20.9C8.2002 20.9 5.7002 19.9 3.7002 18C1.8002 16.1 0.700195 13.7 0.700195 11C0.700195 8.19999 1.8002 5.59999 3.7002 3.69999C5.7002 1.79999 8.2002 0.799988 11.0002 0.799988C13.6002 0.799988 16.0002 1.99999 17.9002 3.89999C19.8002 5.79999 20.8002 8.29999 20.8002 11C20.8002 12.5 20.5002 14 19.8002 15.4L19.7002 15.6L19.8002 15.8L21.3002 20.1C21.4002 20.5 21.3002 20.9 21.0002 21.2C20.8002 21.4 20.6002 21.5 20.3002 21.5C20.2002 21.5 20.1002 21.4 20.0002 21.4L15.7002 19.9L15.5002 19.8L15.3002 19.9Z" stroke="#323232" strokeWidth="1.2" />
				<path d="M11.5 13.7H6.7C6.3 13.7 6 13.4 6 13C6 12.6 6.3 12.3 6.7 12.3H11.5C11.9 12.3 12.2 12.6 12.2 13C12.2 13.4 11.8 13.7 11.5 13.7ZM14.5 11H6.7C6.3 11 6 10.7 6 10.3C6 9.9 6.3 9.6 6.7 9.6H14.5C14.9 9.6 15.2 9.9 15.2 10.3C15.1 10.7 14.8 11 14.5 11ZM14.5 8.3H6.7C6.3 8.3 6 8 6 7.7C6 7.3 6.3 7 6.7 7H14.5C14.9 7 15.2 7.3 15.2 7.7C15.2 8.1 14.8 8.3 14.5 8.3Z" fill="#323232" />
			</g>
		</svg>

	);
};
