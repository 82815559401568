import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	list  : [],
	limits: {},
	UI    : {
		loading: false,
	},
});

export default function autoWithdrawSettingsReducer(state = initState, action) {
	switch (action.type) {
		case actions.WEBSITE_AUTOWITHDRAW_LIST_REFRESH: {
			return state.set('list', action.data);
		}
		case actions.WEBSITE_AUTOWITHDRAW_LIMITS_REFRESH: {
			return state.set('limits', action.data);
		}
		case actions.WEBSITE_AUTOWITHDRAW_ADD: {
			const newItems = [...state.get('list'), action.data];
			return state.set('list', newItems);
		}
		case actions.AUTOWITHDROWAL_LIMIT_ADD: {
			const newLimits = { ...state.get('limits'), ...action.data };
			return state.set('limits', newLimits);
		}
		case actions.AUTOWITHDROWAL_UI_REFRESH: {
			return state.set('UI', action.data);
		}
		case actions.AUTO_WITHDRAWAL_RESET: {
			return  initState;
		}
		default:
			return state;
	}
}
