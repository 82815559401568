import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';

import { bettingRulesAPI } from '../../../../../helpers/api/bettingRules';
import { showError, showSuccess } from '../../../../../helpers/notifications';

import { adaptRule, prepareRule } from '../utils';

const prefix = 'sport.settings.bettingRules.rules';

const messages = {
	errorBaseDataLoad: `${prefix}.errorBaseDataLoad`,
	errorRuleCreate  : `${prefix}.errorRuleCreate`,
	errorRuleUpdate  : `${prefix}.errorRuleUpdate`,
	successRuleCreate: `${prefix}.successRuleCreate`,
	successRuleUpdate: `${prefix}.successRuleUpdate`,
};

function getStoreData({ Sport, App }) {
	const { Rule } = Sport.Settings.BettingRules;

	return {
		baseData : Rule.get('baseData'),
		UI       : Rule.get('UI'),
		websiteID: App.get('websiteID'),
	};
}

function* dataReload() {

	yield takeEvery(actions.DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { ruleID } = action.data;

		try {
			const res = yield call(bettingRulesAPI.ruleInfo, ruleID);
			const baseData = adaptRule(res.data.data);
			yield put(actions.dataRefresh(baseData));

		} catch (error) {
			showError(messages.errorBaseDataLoad, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* dataSave() {

	yield takeEvery(actions.DATA_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, UI, websiteID } = yield select(getStoreData);
		const preparedData = prepareRule(baseData);
		let { ruleID } = UI;

		let errorMessage = '';
		let isError = false;
		preparedData.website_id = Number(websiteID);
		try {
			if (UI.editMode) {
				errorMessage = messages.errorRuleUpdate;
				yield call(bettingRulesAPI.ruleUpdate, ruleID, preparedData);
				showSuccess(messages.successRuleUpdate);

			} else {
				errorMessage = messages.errorRuleCreate;
				const res = yield call(bettingRulesAPI.ruleCreate, preparedData);
				ruleID = toInteger(res.data.data.id);
				showSuccess(messages.successRuleCreate);
				yield put(actions.uiRefresh({
					ruleID,
					editMode: true,
				}));
			}

		} catch (error) {
			isError = true;
			showError(errorMessage, error);
		}

		if (!isError && UI.closeModal) {
			yield put(actions.dataReset());
			yield put(listActions.dataReload());

		} else if (!isError && !UI.closeModal) {
			yield put(actions.dataReload(ruleID));
			yield put(listActions.dataReload());

		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* bettingRuleSaga() {
	yield all([
		fork(dataReload),
		fork(dataSave),
	]);
}
