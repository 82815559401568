import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const PartnersIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (

		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_376)">
				<path {...props} d="M15.9355 6.32534L13.0917 1.47965C12.9613 1.2575 12.6761 1.18215 12.453 1.31097L10.7487 2.29493L9.21362 1.73028C9.15712 1.7095 9.0999 1.69206 9.04224 1.6774C8.61002 1.56753 8.15187 1.62918 7.76093 1.8549L5.22334 3.32C4.66284 3.64359 4.47081 4.36031 4.7944 4.92081C5.11799 5.48131 5.83471 5.67334 6.39521 5.34975L9.17981 3.74203C9.46987 3.57456 9.82724 3.57456 10.1173 3.74203C10.1173 3.74203 15.7275 6.98218 15.7455 6.99331L15.8291 6.9245C16.007 6.77806 16.0521 6.52406 15.9355 6.32534Z" fill={color} />
				<path {...props} d="M15.2226 7.77206L9.64859 4.55394L6.86399 6.16163C6.53899 6.34928 6.17824 6.44513 5.81274 6.44513C5.62949 6.44513 5.44509 6.42103 5.26337 6.37238C4.71912 6.22653 4.26421 5.8775 3.98252 5.38953C3.70081 4.90163 3.62599 4.33316 3.77184 3.78891C3.89221 3.3396 4.1514 2.9515 4.51262 2.67116L3.36331 2.00759C3.13415 1.87528 2.84093 1.95878 2.71584 2.19197L0.0557429 7.15141C-0.0639133 7.3745 0.0151804 7.65235 0.234462 7.77891L0.478118 7.91956L0.905774 7.17881C1.26056 6.56428 1.92202 6.18253 2.63202 6.18253C2.9804 6.18253 3.32452 6.27488 3.62715 6.44963C3.80124 6.55013 3.95652 6.674 4.08987 6.81691C4.3054 6.73822 4.53562 6.69594 4.77131 6.69594C5.11968 6.69594 5.46377 6.78828 5.76643 6.96303C6.22724 7.22906 6.5569 7.65869 6.69462 8.17269C6.70977 8.22922 6.72193 8.28603 6.73206 8.34297C6.92374 8.38725 7.1089 8.45981 7.28068 8.559C7.74152 8.82503 8.07118 9.25466 8.2089 9.76866C8.22406 9.82519 8.23621 9.882 8.24634 9.93894C8.43802 9.98322 8.62315 10.0558 8.79493 10.1549C9.74624 10.7042 10.0734 11.925 9.52418 12.8763L8.94021 13.8878L10.1919 14.6104C10.6964 14.9017 11.3414 14.7288 11.6326 14.2244C11.9239 13.7199 11.7511 13.0749 11.2466 12.7837L12.0585 13.2524C12.563 13.5437 13.208 13.3708 13.4992 12.8664C13.7905 12.3619 13.6176 11.7169 13.1132 11.4256L13.6545 11.7381C14.1589 12.0294 14.804 11.8565 15.0952 11.3521C15.3865 10.8476 15.2136 10.2026 14.7091 9.91135L14.1679 9.59885C14.6723 9.89009 15.3174 9.71725 15.6086 9.21278C15.8999 8.70834 15.727 8.06331 15.2226 7.77206Z" fill={color} />
				<path {...props} d="M8.32615 10.9669C7.82171 10.6756 7.17668 10.8485 6.88543 11.3529L7.19793 10.8117C7.48918 10.3072 7.31634 9.66219 6.8119 9.37094C6.30746 9.07969 5.66243 9.25254 5.37118 9.75697L5.68368 9.21569C5.97493 8.71126 5.80209 8.06622 5.29765 7.77497C4.79321 7.48372 4.14818 7.65657 3.85693 8.16101L3.54443 8.70225C3.83565 8.19782 3.66284 7.55279 3.15837 7.26154C2.65393 6.97029 2.0089 7.14313 1.71765 7.64757L0.58484 9.60966C0.29359 10.1141 0.466434 10.7592 0.970871 11.0504C1.47531 11.3416 2.12037 11.1688 2.41159 10.6643C2.12034 11.1688 2.29318 11.8138 2.79762 12.105C3.30206 12.3963 3.94712 12.2234 4.23834 11.719C3.94709 12.2234 4.11993 12.8685 4.6244 13.1597C5.12884 13.4509 5.77387 13.2781 6.06512 12.7736C5.77387 13.2781 5.94671 13.9231 6.45115 14.2143C6.95559 14.5056 7.60065 14.3328 7.89187 13.8283L8.71218 12.4075C9.00346 11.9032 8.83062 11.2581 8.32615 10.9669Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_376">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>

	);
};
PartnersIcon.propTypes = propTypesIcons;

PartnersIcon.defaultProps = defaultPropsIcons;
