
import cloneDeep from 'lodash/cloneDeep';
import { actions } from './actions';
import { fill } from '../../../../../helpers/utils';
import { partnerInit } from './utils';

const initState = cloneDeep(partnerInit);

const clonedState = cloneDeep(initState);

export default (state = clonedState, { type, data }) => {
	switch (type) {
		case actions.BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(data, target, true);
			return state.set('baseData', result);
		}

		case actions.UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(data, target, true);
			return state.set('UI', result);
		}
		case actions.INTEGRATORS_WITH_PROVIDERS_REFRESH: {
			return state.set('integratorsWithProviders', data);
		}

		case actions.PROVIDERS_WITH_GAMES_REFRESH: {
			const target                                = state.get('providersWithGames');
			const { id, gameDataByProvider, isLoading } = data;
			const result = cloneDeep(target);
			if (Object.keys(data).length && id) {
				result[id] = {
					gameDataByProvider,
					isLoading,
				};
			}
			return state.set('providersWithGames', result);
		}
		case actions.STORE_RESET: {
			return cloneDeep(partnerInit);
		}
		default: {
			return state;
		}
	}
};
