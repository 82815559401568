import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData : {},
	authData : [],
	namesData: [],
	seoData		: {},


	UI: {
		visible      	: false,
		editMode     	: false,
		casinoID     	: null,
		loading      	: false,
		closeModal   	: false,
		langID       	: null,
		isBaseChanged	: false,
		isAuthChanged	: false,
		isNamesChanged: false,
		isAliasChanged: false,
		activeTab   		: 'main',
	},
});

export default function casinoModalReducer(state = initState, action) {

	switch (action.type) {

		case actions.CASINO_BASE_DATA_REFRESH: {
			const target = state.get('baseData');
			const result = fill(action.data, target, true);
			return state.set('baseData', result);
		}

		case actions.CASINO_AUTH_DATA_REFRESH: {
			return state.set('authData', action.data);
		}

		case actions.CASINO_NAMES_DATA_REFRESH: {
			return state.set('namesData', action.data);
		}

		case actions.CASINO_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.CASINO_DATA_RESET: {
			return initState;
		}

		case actions.CASINO_SEO_DATA_REFRESH: {
			return state.set('seoData', action.data);
		}

		default:
			return state;
	}
}
