const actions = {

	USER_LOGINS_LIST_RELOAD            : 'USER_LOGINS_LIST_RELOAD',
	USER_LOGINS_LIST_REFRESH           : 'USER_LOGINS_LIST_REFRESH',
	USER_LOGINS_LIST_PAGINATION_REFRESH: 'USER_LOGINS_LIST_PAGINATION_REFRESH',
	USER_LOGINS_LIST_SET_VALUE_UI      : 'USER_LOGINS_LIST_SET_VALUE_UI',

	listReload: (userID, userOrAdmin) => ({
		type: actions.USER_LOGINS_LIST_RELOAD,
		data: {
			userID,
			userOrAdmin,
		},
	}),
	listRefresh: list => ({
		type: actions.USER_LOGINS_LIST_REFRESH,
		data: list,
	}),
	paginationRefresh: data => ({
		type: actions.USER_LOGINS_LIST_PAGINATION_REFRESH,
		data,
	}),
	setValueUI: (valueName, value) => ({
		type: actions.USER_LOGINS_LIST_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
};

export default actions;
