import { EVENT_TYPES, CommunicationCodes } from '../codes';
import { emitEvent } from '../socket';

function subscribeToUpdate(betIDs) {

	const event = {
		subjectType: EVENT_TYPES.betslip,
		ids        : betIDs,
	};

	return emitEvent('subscribe', event);
}

function updateBetReview(betData) {

	const event = {
		code: CommunicationCodes.BET_REVIEW_UPDATE,
		body: betData,
	};

	return emitEvent('post', event);
}

export const betsAPI = {
	subscribeToUpdate,
	updateBetReview,
};
