import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import PasswordExpired from '../containers/App/modal/PasswordExpired';

export const AppModals: React.FC = () => {
	const { passwordExpiredModal: { visible } } = useSelector((state: RootState) => state.App.get('modals'));

	return visible && <PasswordExpired visible={visible} />;
};
