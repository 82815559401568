const actions = {

	RESULTS_FILTER_REFRESH             : 'RESULTS_FILTER_REFRESH',
	RESULTS_FILTER_COUNTRY_LIST_RELOAD : 'RESULTS_FILTER_COUNTRY_LIST_RELOAD',
	RESULTS_FILTER_COUNTRY_LIST_REFRESH: 'RESULTS_FILTER_COUNTRY_LIST_REFRESH',
	RESULTS_FILTER_LEAGUE_LIST_RELOAD  : 'RESULTS_FILTER_LEAGUE_LIST_RELOAD',
	RESULTS_FILTER_LEAGUE_LIST_REFRESH : 'RESULTS_FILTER_LEAGUE_LIST_REFRESH',
	FILTER_RESET                       : 'RESULTS_FILTER_FILTER_RESET',
	
	filterRefresh: data => ({
		type: actions.RESULTS_FILTER_REFRESH,
		data,
	}),
	filterReset: () => ({
		type: actions.FILTER_RESET,
	}),
	countryListReload: sportID => ({
		type: actions.RESULTS_FILTER_COUNTRY_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	countryListRefresh: list => ({
		type: actions.RESULTS_FILTER_COUNTRY_LIST_REFRESH,
		data: list,
	}),
	leagueListReload: countryID => ({
		type: actions.RESULTS_FILTER_LEAGUE_LIST_RELOAD,
		data: {
			countryID,
		},
	}),
	leagueListRefresh: list => ({
		type: actions.RESULTS_FILTER_LEAGUE_LIST_REFRESH,
		data: list,
	}),
};

export default actions;
