import { call } from 'redux-saga/effects';
import { casinoAPI } from '../../../../helpers/api/casino';
import { contentAPI } from '../../../../helpers/api/content';

import {
	CASINO_GAMES_STATUS,
	CHANNEL_TYPES,
} from '../../../../helpers/commonConstants';
import { adaptGamesList } from '../../../casino/games/utils';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { adaptDefaultGrid, gridListLimit } from './utils';
import { messages } from './saga';
import { logger } from '../../../../helpers/logger';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { restoreWebsiteID } from '../../../../helpers/utility';

export function* makeRequestDefaultGrid(channelID = CHANNEL_TYPES.backend) {

	let defaultGrid = [];
	const params = {
		casino_game_id: -1,
		channel_id    : channelID,
	};

	try {
		const res   = yield call(contentAPI.gamesDefaultGridList, params);
		defaultGrid = yield call(adaptDefaultGrid, res.data.data);

	} catch (error) {
		logger.log('error ', error);

	}

	return defaultGrid;
}

export function* makeRequestGamesList(websiteID, params) {

	let resualt = [];
	let totalCount;

	const reqParams = {
		limit    : params.limit || gridListLimit,
		page     : params.page,
		status_id: CASINO_GAMES_STATUS.active,
	};

	const { lastPage } = params;

	if (params.channelID) {
		reqParams.channel_id = params.channelID;
	}
	if (params.categoryID) {
		reqParams.category = params.categoryID;
	}

	try {
		const res = yield call(casinoAPI.gamesList, websiteID, reqParams);
		totalCount = getHeadersTotalCount(res.headers);
		resualt = yield call(adaptGamesList, res.data.data, lastPage);
	} catch (error) {
		logger.log('error ', error);
	}

	return {
		resualt,
		totalCount,
	};
}


export function* makeRequestGridsSave(grids) {

	try {
		const websiteID = restoreWebsiteID();
		const res = yield call(contentAPI.gamesDefaultGridUpdate, grids, { website_id: websiteID });
		if (res.status === 200) {
			showSuccess(messages.successGridSave);
			return true;
		}
	} catch (error) {
		showError(messages.errorGridsSave);
		logger.log(error);
	}
}
