import { combineReducers } from 'redux';

import Bets from './bets/reducer';
import Deposits from './deposits/reducer';
import Withdrawals from './withdrawals/reducer';
import Casino from './casino/reducer';
import Documents from './documents/reducer';
import RiskManagement from './riskManagement/reducer';

import ReportBets from './reportBets/reducer';
import ReportDeposits from './reportDeposits/reducer';
import ReportWithdrawals from './reportWithdrawals/reducer';
import ReportCasino from './reportCasino/reducer';

import UserBets from './userBets/reducer';
import UserDeposits from './userDeposits/reducer';
import UserWithdrawals from './userWithdrawals/reducer';
import UserCasino from './userCasino/reducer';

export default combineReducers({
	Bets,
	Deposits,
	Withdrawals,
	Casino,
	Documents,
	RiskManagement,

	ReportBets,
	ReportDeposits,
	ReportWithdrawals,
	ReportCasino,

	UserBets,
	UserDeposits,
	UserWithdrawals,
	UserCasino,
});
