import { createSelector } from 'reselect';

const changeThemes = (state) => state.ThemeSwitcher.get('changeThemes');
const topbarTheme  = (state) => state.ThemeSwitcher.get('topbarTheme');
const sidebarTheme = (state) => state.ThemeSwitcher.get('sidebarTheme');
const layoutTheme  = (state) => state.ThemeSwitcher.get('layoutTheme');

export const deriveTheme = createSelector(
	[changeThemes],
	(changeThemes) => changeThemes
);

export const deriveTopbarTheme = createSelector(
	[topbarTheme],
	(topbarTheme) => topbarTheme
);

export const deriveSidebarTheme = createSelector(
	[sidebarTheme],
	(sidebarTheme) => sidebarTheme
);

export const deriveLayoutTheme = createSelector(
	[layoutTheme],
	(layoutTheme) => layoutTheme
);
