const prefix = 'SMS_LISTING_';

const actions = {

	LIST_RELOAD : `${prefix}LIST_RELOAD`,
	LIST_REFRESH: `${prefix}LIST_REFRESH`,
	UI_REFRESH  : `${prefix}UI_REFRESH`,

	DATA_RESET: `${prefix}DATA_RESET`,

	LIST_FILTER_REFRESH: `${prefix}LIST_FILTER_REFRESH`,
	LIST_FILTER_APPLY  : `${prefix}LIST_FILTER_APPLY`,
	FILTER_RESET       : `${prefix}FILTER_RESET`,
	
	filterReset: () => ({
		type: actions.FILTER_RESET,
	}),
	listReload: () => ({
		type: actions.LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.LIST_REFRESH,
		data: {
			entities,
		},
	}),
	uiRefresh: (data) => ({
		type: actions.UI_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.DATA_RESET,
	}),
	filterRefresh: data => ({
		type: actions.LIST_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.LIST_FILTER_APPLY,
	}),
};

export default actions;
