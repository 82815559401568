import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

		
		.ant-select-selector {
        overflow-y: auto;
    }
		
		.ant-select-selector::-webkit-scrollbar {
        width: 4px;
				//background: #F5F5F5;
				border-radius: 50px;
    }
		
		.ant-select-selector::-webkit-scrollbar-thumb {
        background: #C1C1C1;
				border-radius: 50px;
    } 
  &.bold {
    font-weight: bold;
  }
`;
