export const PARTICIPANT_LOG_MODELS = {
	all        : 'all',
	translation: 'translation',
	names      : 'names',
};

export const VERIFIED_STATUS = {
	all        : 1,
	verified   : 2,
	notVerified: 3,
};

export const FILTER_TYPES = {
	PLAYER: 1,
	TEAM  : 2,
};

export const ROLES = {
	player : 1,
	referee: 2,
	manager: 3,
	coach  : 4,
	other  : 5,
};
