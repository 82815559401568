import { combineReducers } from 'redux';

import Emails from './emails/reducer';
import Messages from './messages/reducer';
import Notifications from './pushNotifications/reducer';
import SMS from './sms/reducer';

export default combineReducers({
	Emails,
	Messages,
	Notifications,
	SMS,
});
