const prefix = 'USER_CASINO_BONUSES';

const actions = {

	FILTER_REFRESH: `${prefix}_FILTER_REFRESH`,
	FILTER_RESET 	: `${prefix}_FILTER_RESET`,

	filterRefresh: filterData => ({
		type: actions.FILTER_REFRESH,
		data: {
			filterData,
		},
	}),

	dataReset: () => ({
		type: actions.FILTER_RESET,
	}),

};

export default actions;
