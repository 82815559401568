import dayjs from 'dayjs';
import { Map } from 'immutable';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import { FORMATS } from '../../../../../helpers/commonConstants';

const initialState = new Map({
	matches        : [],
	activeMatch    : {},
	isModalOpen    : false,
	isUpdatingMatch: false,
	eventMarkets   : [],
	startDate      : dayjs(new Date()).format(FORMATS.date),
	isChanged      : false,
});

export default (state = initialState, { type, data }) => {
	switch (type) {
		case actions.DAY_MATCHES_SET_START_DATE: {
			return state.set('startDate', data.format(FORMATS.date));
		}
		case actions.DAY_MATCHES_FETCH_MATCHES_SUCCESS: {
			return state.set('matches', data);
		}
		case actions.DAY_MATCHES_ADD_MATCH_SUCCESS: {
			return state
				.update('matches', matches => matches.concat(data))
				.set('activeMatch', data);
		}
		case actions.DAY_MATCHES_UPDATE_MATCH_SUCCESS: {
			return state
				.update('matches', matches => {
					const updatedMatches = [...matches];
					const index = updatedMatches.findIndex(({ id }) => id === data.id);
					updatedMatches[index] = data;
					return updatedMatches;
				})
				.set('activeMatch', data);
		}
		case actions.DAY_MATCHES_REMOVE_MATCH_SUCCESS: {
			return state.update('matches', matches => matches.filter(({ id }) => id !== data));
		}
		case actions.DAY_MATCHES_SET_EVENT: {
			const activeMatch = state.get('activeMatch');
			return state.set('activeMatch', {
				...activeMatch,
				eventID: toInteger(data),
			});
		}
		case actions.DAY_MATCHES_SET_EVENT_MARKET: {
			const activeMatch = state.get('activeMatch');
			return state.set('activeMatch', {
				...activeMatch,
				eventMarketID: toInteger(data),
			});
		}
		case actions.DAY_MATCHES_TOGGLE_MODAL: {
			const { value, isUpdating } = data;
			if (!isUpdating || !value) {
				return state.set('activeMatch', {}).set('isModalOpen', value);
			}
			return state.set('isModalOpen',  value);
		}
		case actions.DAY_MATCHES_TOGGLE_BUTTONS_STATE: {
			return state.set('isChanged', data);
		}
		case actions.DAY_MATCHES_TOGGLE_UPDATING: {
			return state.set('isUpdatingMatch', data);
		}
		case actions.DAY_MATCHES_FETCH_EVENT_MARKETS_SUCCESS: {
			return state.set('eventMarkets', data);
		}
		case actions.DAY_MATCHES_SET_ACTIVE_MATCH: {
			return state.set('activeMatch', data);
		}
		case actions.DAY_MATCHES_SET_IMAGE_URL: {
			const activeMatch = state.get('activeMatch');
			const { imageName, url } = data;
			return state.set('activeMatch', {
				...activeMatch,
				[imageName]: url,
			});
		}
		default:
			return state;
	}
};
