import { makeActionCreator } from '../../../../helpers';

const prefix = 'BETTING_RULE';

const TYPES = {
	DATA_RELOAD : `${prefix}_DATA_RELOAD`,
	DATA_REFRESH: `${prefix}_DATA_REFRESH`,
	DATA_SAVE   : `${prefix}_DATA_SAVE`,
	DATA_RESET  : `${prefix}_DATA_RESET`,
	UI_REFRESH  : `${prefix}_UI_REFRESH`,
};

const actions = {
	...TYPES,

	dataReload : makeActionCreator(TYPES.DATA_RELOAD, 'ruleID'),
	dataRefresh: makeActionCreator(TYPES.DATA_REFRESH, 'baseData'),
	dataSave   : makeActionCreator(TYPES.DATA_SAVE),
	dataReset  : makeActionCreator(TYPES.DATA_RESET),
	uiRefresh  : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
};

export default actions;
