import { availableFields } from '../../../redux/reports/reportsByIntegrator/utils';


const lang                       = {
	wonAmountTotal: 'reportByIntegrator.wonAmountTotal',
	ggr           : 'reportByIntegrator.ggr',
	spinsCount    : 'reportByIntegrator.spinsCount',
	stakeTotal    : 'reportByIntegrator.stakeTotal',
	integrator    : 'reportByIntegrator.integrator',
	profitability : 'reportByIntegrator.profitability',
};
export const defaultReportFields = availableFields.map(fieldKey => {
	return {
		id     : fieldKey,
		intlID : lang[fieldKey],
		checked: true,
	};
});
