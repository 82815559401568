import { Map } from 'immutable';
import { cloneDeep } from 'lodash';
import { sendingTypes } from '../../../containers/LoyaltySystem/Tournaments/modals/utils';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import actions from './actions';
import { POINT_GENERATION_LOGIC_TYPE, POINT_GENERATION_RULE_TYPE } from '../../../helpers/commonConstants';
import { initFilterTypes } from './utils';

const initState = new Map({
	filter  : createFilter(filtersURL.tournaments, initFilterTypes),
	list    : [],
	entities: {},
	baseData: {
		settings: {
			languageIDs: [],
		},
		main      : {},
		games     : {},
		prizePool : {},
		qualifying: {},
		partners  : {
			sendingType: sendingTypes.all,
			list       : [],
			selected   : [],
		},
		terms: {},
	},

	integrators    : [],
	integratorsBase: {},
	prizePool      : {
		places        : [],
		expandedRows  : [],
		prizesPerPlace: {},
		baseData      : {},
	},

	partners: {
		sendingType: sendingTypes.all,
		list       : [],
		selected   : [],
	},

	activePrizes: {},

	pointGeneration: {
		143: {
			id              : 143,
			pointsRuleType  : POINT_GENERATION_RULE_TYPE.allStakes,
			pointsLogicType : POINT_GENERATION_LOGIC_TYPE.totalSpins,
			frequencyAmount : '11',
			stakeSpentAmount: '5',
			pointsEarned    : '3',
			stopFurtherRules: false,
			priority        : 1,
		},
		144: {
			id              : 144,
			pointsRuleType  : POINT_GENERATION_RULE_TYPE.allStakes,
			pointsLogicType : POINT_GENERATION_LOGIC_TYPE.totalSpins,
			frequencyAmount : '',
			stakeSpentAmount: '',
			pointsEarned    : '',
			stopFurtherRules: true,
			priority        : 2,
		},
	},

	UI: {
		loading          : false,
		tournamentLoading: false,
		partnersLoading  : false,
		visible          : false,
		editMode         : false,
	},
});

export default function bonusesReducer(state = initState, action) {

	switch (action.type) {

		case actions.LIST_REFRESH: {
			const { data } = action;
			return state.set('list', data);
		}

		case actions.FILTER_REFRESH: {
			const { data } = action;
			const target = state.get('filter');
			const result = fill(data, target, true);
			return state.set('filter', result);
		}

		case actions.BASE_DATA_REFRESH: {
			const { data: { data, section } } = action;
			const target = state.get('baseData');
			const clonedTarget = cloneDeep(target);
			const result = fill(data, clonedTarget[section], true);
			// clonedTarget[section] = data;
			clonedTarget[section] = result;
			return state.set('baseData', clonedTarget);
		}

		case actions.PRIZE_POOL_BASE_REFRESH: {
			const { data } = action;
			const target = cloneDeep(state.get('prizePool'));
			const result = fill(data, target.baseData, true);
			target.baseData = result;
			return state.set('prizePool', target);
		}

		case actions.PRIZE_POOL_REFRESH: {
			const { data } = action;
			return state.set('prizePool', data);
		}

		case actions.REFRESH_PARTNER: {
			const { data } = action;
			const target = state.get('partners');
			const result = fill(data, target, true);
			return state.set('partners', result);
		}

		case actions.ACTIVE_PRIZE_REFRESH: {
			const { data } = action;
			return state.set('activePrizes', data);
		}
		case actions.INTEGRATORS_LIST_REFRESH: {
			const { data } = action;
			return state.set('integrators', data);
		}

		case actions.INTEGRATORS_BASE_REFRESH: {
			const { data } = action;
			const target = state.get('integratorsBase');
			const result = fill(data, target, true);
			return state.set('integratorsBase', result);
		}

		case actions.REFRESH_POINT_GENERATION: {
			return state.set('pointGeneration', action.data);
		}

		case actions.UI_REFRESH: {
			const { data } = action;
			const target = state.get('UI');
			const result = fill(data, target, true);
			return state.set('UI', result);
		}

		case actions.MODAL_DATA_RESET: {
			const clonedInit = cloneDeep(initState);
			const baseData = clonedInit.get('baseData');
			const prizePool = clonedInit.get('prizePool');
			const partners = clonedInit.get('partners');
			const activePrizes = clonedInit.get('activePrizes');

			return state.set('baseData', baseData)
				.set('prizePool', prizePool)
				.set('partners', partners)
				.set('activePrizes', activePrizes);
		}

		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}


		default:
			return state;
	}
}
