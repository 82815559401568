export const userStatusList = [
	{ id: 0, name: 'All statuses' },
	{ id: 1, name: 'Active' },
	{ id: 2, name: 'Blocked' },
	{ id: 3, name: 'Waiting for deactivation' },
	{ id: 4, name: 'Deactivated' },
	{ id: 5, name: 'Pending' },
];

export const statusIDs = {
	ALL_STATUSES            : 0,
	ACTIVE                  : 1,
	BLOCKED                 : 2,
	WAITING_FOR_DEACTIVATION: 3,
	DEACTIVATED             : 4,
	PENDING                 : 5,
};

export const gamingStatusList = [
	{ id: 0, name: 'All gaming status' },
	{ id: 1, name: 'Active' },
	{ id: 2, name: 'Inactive' },
	{ id: 3, name: 'Limited' },
	{ id: 4, name: 'PRO Active' },
	{ id: 5, name: 'Blocked' },
	{ id: 6, name: 'Undeposit Active' },
];

export const verificationStatusList = [
	{ id: 0, name: 'All verification statuses' },
	{ id: 1, name: 'Verified' },
	{ id: 2, name: 'Partly verified' },
	{ id: 3, name: 'Not verified' },
	{ id: 4, name: 'Not required to be verified' },
	{ id: 5, name: 'Awaiting verification' },
];

export const validationWayList = [
	{ id: 1, name: 'Phone' },
	{ id: 2, name: 'E-mail' },
	{ id: 3, name: 'Age' },
	{ id: 4, name: 'Credit card' },
	{ id: 5, name: 'Passport' },
];

export const profitabilityGradeList = [
	{ id: 0, name: 'All Grades' },
	{ id: 1, name: 'Negative' },
	{ id: 2, name: 'Profitable' },
	{ id: 3, name: 'Unprofitable' },
	{ id: 4, name: 'Neutral' },
];

export const eventTypesList = [
	{ id: 0, name: 'None' },
	{ id: 1, name: 'Single' },
	{ id: 2, name: 'Multibet' },
	{ id: 3, name: 'System' },
];

export const betStatusList = [
	{ id: 0, name: 'None' },
	{ id: 1, name: 'Open' },
	{ id: 2, name: 'Lost' },
	{ id: 3, name: 'Partial lost' },
	{ id: 4, name: 'Win' },
	{ id: 5, name: 'Partial win' },
	{ id: 6, name: 'Cancelled' },
	{ id: 7, name: 'Void' },
];

export const bonusTypesList = [
	{ id: 1, name: 'All' },
	{ id: 2, name: 'Deposit bonus' },
	{ id: 3, name: 'Sportsbook bonus' },
	{ id: 4, name: 'None' },
];


const userStatus = {
	'0': 'All statuses',
	'1': 'Active',
	'2': 'Blocked',
};

const verificationStatus = {
	'0': 'All verification statuses',
	'1': 'Verified',
	'2': 'Partly verified',
	'3': 'Not veryfied',
	'4': 'Not required to be verified',
	'5': 'Awaiting verification',
};

const gamingStatus = {
	'0': 'All gaming status',
	'1': 'Active',
	'2': 'Inactive',
	'3': 'Limited',
	'4': 'PRO Active',
	'5': 'Blocked',
	'6': 'Undeposit Active',
};

export const genders = {
	'0': 'All genders',
	'1': 'Male',
	'2': 'Female',
};

const currencies = {
	'0': 'None',
	'1': 'USD',
	'2': 'EUR',
	'3': 'GBP',
	'4': 'RUB',
	'5': 'CNY',
};

const profitabilityGrades = {
	'0': 'None',
	'1': 'Negative',
	'2': 'Profitable',
	'3': 'Unprofitable',
};

const eventTypes = {
	'0': 'None',
	'1': 'Single',
	'2': 'Multibet',
	'3': 'System',
};

const betStatuses = {
	'0': 'None',
	'1': 'Open',
	'2': 'Lost',
	'3': 'Partial lost',
	'4': 'Win',
	'5': 'Ppartial win',
	'6': 'Cancelled',
	'7': 'Void',
};

const businessChannels = {
	'0': 'None',
	'1': 'Web',
	'2': 'Mobile App',
	'3': 'Mobile Web',
	'4': 'Desktop',
};

const bonusTypes = {
	'0': 'Not exists',
	'1': 'All',
	'2': 'Deposit bonus',
	'3': 'Sportsbook bonus',
	'4': 'None',
};

const userEnums = {
	userStatusList,
	statusIDs,
	gamingStatusList,
	verificationStatusList,
	validationWayList,
	profitabilityGradeList,
	eventTypesList,
	betStatusList,
	bonusTypesList,

	userStatus,
	verificationStatus,
	gamingStatus,
	genders,
	currencies,
	profitabilityGrades,
	eventTypes,
	betStatuses,
	businessChannels,
	bonusTypes,
};
export default userEnums;

export function mapEnum(enumName) {
	const enumValue = userEnums[enumName];
	const enumMap   = {};

	enumValue.forEach( item => {
		enumMap[item.id] = item.name;
	});

	return enumMap;
}
