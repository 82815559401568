import { Map } from 'immutable';
import { fill } from '../../helpers/utils';
import actions from './actions';

const initState = new Map({

	menu       : [],
	searchMenu : [],
	editMenu   : [],
	sidebarKeys: [],

	UI: {
		loading      : false,
		isDataChanged: false,
	},
});

export default function sidebarReducer(state = initState, action) {

	switch (action.type) {

		case actions.SIDEBAR_SETTINGS_REFRESH: {
			return state.set('menu', action.data);
		}

		case actions.SEARCH_MENU: {
			return state.set('searchMenu', action.data);
		}

		case actions.SIDEBAR_KEYS_REFRESH: {
			return state.set('sidebarKeys', action.data);
		}

		case actions.SIDEBAR_SETTINGS_EDIT_REFRESH: {
			return state.set('editMenu', action.data);
		}

		case actions.SIDEBAR_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
