const actions = {

	CATEGORY_REGULATION_TYPES_DATA_RESET      : 'CATEGORY_REGULATION_TYPES_DATA_RESET',
	CATEGORY_REGULATION_TYPES_DATA_SAVE_CLOSE : 'CATEGORY_REGULATION_TYPES_DATA_SAVE_CLOSE',
	CATEGORY_REGULATION_TYPES_DATA_DELETE     : 'CATEGORY_REGULATION_TYPES_DATA_DELETE',
	CATEGORY_REGULATION_TYPES_DATA_RELOAD     : 'CATEGORY_REGULATION_TYPES_DATA_RELOAD',
	CATEGORY_REGULATION_TYPES_DATA_REFRESH    : 'CATEGORY_REGULATION_TYPES_DATA_REFRESH',
	CATEGORY_REGULATION_TYPES_MODAL_UI_REFRESH: 'CATEGORY_REGULATION_TYPES_MODAL_UI_REFRESH',

	dataReset: () => ({
		type: actions.CATEGORY_REGULATION_TYPES_DATA_RESET,
	}),
	dataSaveClose: (typeData) => ({
		type: actions.CATEGORY_REGULATION_TYPES_DATA_SAVE_CLOSE,
		data: {
			typeData,
		},
	}),
	dataDelete: typeID => ({
		type: actions.CATEGORY_REGULATION_TYPES_DATA_DELETE,
		data: {
			typeID,
		},
	}),
	dataReload: categoryID => ({
		type: actions.CATEGORY_REGULATION_TYPES_DATA_RELOAD,
		data: {
			categoryID,
		},
	}),
	dataRefresh: data => ({
		type: actions.CATEGORY_REGULATION_TYPES_DATA_REFRESH,
		data,
	}),

	modalUIRefresh: data => ({
		type: actions.CATEGORY_REGULATION_TYPES_MODAL_UI_REFRESH,
		data,
	}),
};

export default actions;
