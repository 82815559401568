import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { casinoAPI } from '../../../helpers/api/casino';
import { showError, showSuccess } from '../../../helpers/notifications';

import { adaptCustomProvidersList } from './utils';
import { logger } from '../../../helpers/logger';

const messages = {
	errorListLoad    : 'casino.gameCategories.error.list.load',
	errorItemDelete  : 'casino.gameCategories.error.item.delete',
	successItemDelete: 'casino.gameCategories.success.item.delete',
};

function* listReload() {

	yield takeEvery(actions.CASINO_CUSTOM_PROVIDERS_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		let entities = {};
		try {
			const res = yield call(casinoAPI.customProvidersList);
			if (res && res.status === 200) {
				entities = adaptCustomProvidersList(res.data.data);
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* deleteItem() {

	yield takeEvery(actions.CASINO_CUSTOM_PROVIDERS_LIST_DELETE_ITEM, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { customProviderID } = action.data;
		try {
			const res = yield call(casinoAPI.customProvidersDelete, customProviderID);
			if (res && res.status === 200) {
				showSuccess(messages.successItemDelete);
				yield put(actions.listReload());
			}
		} catch (error) {
			showError(messages.errorItemDelete);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* customProvidersSaga() {
	yield all([
		fork(listReload),
		fork(deleteItem),
	]);
}
