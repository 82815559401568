import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;

  &.tab-link {
    color: #4482FF;
    text-decoration: none;
    outline: none;
    transition: color .3s ease;
  }  
`;
