import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { fill } from '../../../../../helpers/utils';
import {
	IInitUserAllTransaction,
	ITransactionsData,
	IInitialFilter,
	IUI,
	ITransactionsEntities,
	IModalData,
	IReportsFields,
} from './types';
import { defaultReportFields } from '../../../../../containers/CustomerManagement/UserInfo/UserTransactions/utils';

const initialState: IInitUserAllTransaction = {
	userAllTransactions         : [],
	userAllTransactionsEntities : {},
	userAllTransactionsFilter   : {},
	userAllTransactionsModalData: {},

	reportFields: defaultReportFields,
	
	UI: {
		loading      : false,
		error        : false,
		visibleModal : false,
		loadingModal : false,
		reportLoading: false,
	},
};

export const userAllTransactions = createSlice({
	name    : 'userAllTransaction',
	initialState,
	reducers: {
		setUserAllTransactionsData: (state: Draft<IInitUserAllTransaction>, action: PayloadAction<ITransactionsData[]>) => {
			state.userAllTransactions = action.payload;
		},
		setUserAllTransactionsEntities: (state: Draft<IInitUserAllTransaction>, action: PayloadAction<ITransactionsEntities>) => {
			state.userAllTransactionsEntities = action.payload;
		},
		setUserAllTransactionsFilter: (state: Draft<IInitUserAllTransaction>, action: PayloadAction<IInitialFilter>) => {
			const target = state.userAllTransactionsFilter;
			state.userAllTransactionsFilter = fill(action.payload, target, true);
		},
		resetUserAllTransactions: (state: Draft<IInitUserAllTransaction>) => {
			state.userAllTransactions = initialState.userAllTransactions;
		},
		setUserAllTransactionsModalData: (state: Draft<IInitUserAllTransaction>, action: PayloadAction<IModalData>) => {
			state.userAllTransactionsModalData = action.payload;
		},
		filterReset: (state: Draft<IInitUserAllTransaction>) => {
			state.userAllTransactionsFilter = initialState.userAllTransactionsFilter;
		},
		dataModalReset: (state: Draft<IInitUserAllTransaction>) => {
			state.userAllTransactionsModalData = initialState.userAllTransactionsModalData;
		},
		uiRefresh: (state: Draft<IInitUserAllTransaction>, action: PayloadAction<IUI>) => {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},

		userReportDataRefresh: (state: Draft<IInitUserAllTransaction>, action: PayloadAction<IReportsFields[]>) => {
			state.reportFields = action.payload;
		},

		
		// userReportFieldsRefresh
	},
});


export const actions = {
	...userAllTransactions.actions,
};

export default userAllTransactions.reducer;

