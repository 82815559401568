import { apiRequest } from './index';

// get list of bets
function rtmDepositsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/rtm/deposits',
		withoutLangID: true,
		params,
	};

	return apiRequest(req );
}

function rtmDepositInfo(depositID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/rtm/deposits/${depositID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function rtmCSVLoad(params = {}) {

	const req = {
		method: 'GET',
		url   : '/rtm/csv/deposits',
		params,
	};

	return apiRequest(req);
}

function rtmCSVRecalculate(params = {}) {

	const req = {
		method: 'GET',
		url   : '/rtm/csv/recalculate/deposits',
		params,
	};

	return apiRequest(req);
}

export const depositsAPI = {
	rtmDepositsList,
	rtmDepositInfo,

	rtmCSVLoad,
	rtmCSVRecalculate,
};
