import { apiRequest,
	// ApiRequestData, ApiResponse
} from './index';
import { RESPONSIBLE_STATUS_IDS } from '../../redux/users/userInfo/responsibleGambling/types';

export interface ApiResponse {

}
export interface LimitationData {
}

export interface LimitationParams {
}

export interface WebsiteSettings {
}

function activeLimitations(params: LimitationParams = {}): Promise<ApiResponse> {
	const req = {
		method: 'GET',
		url   : '/gamblings',
		params,
	};

	return apiRequest(req);
}

function removeLimitations(limitationID: number, statusID: RESPONSIBLE_STATUS_IDS): Promise<ApiResponse> {
	const req = {
		method: 'PUT',
		url   : `/gambling/${limitationID}`,
		data  : {
			status_id: statusID,
		},
	};

	return apiRequest(req);
}


function saveSettings(limitationID: string, data: WebsiteSettings = {}, params: LimitationParams = {}): Promise<ApiResponse> {
	const req = {
		method: 'POST',
		url   : `/gamblings/${limitationID}`, // TODO change this after BE implementation
		data,
		params,
	};
	return apiRequest(req);
}

export const responsibleGamblingAPI = {
	activeLimitations,
	removeLimitations,
	saveSettings,
};
