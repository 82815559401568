import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const NotificationCenterIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_162)">
				<path {...props} d="M8.00001 16C8.95913 16 9.78507 15.4207 10.1475 14.5938H5.85254C6.21495 15.4207 7.04091 16 8.00001 16Z" fill={color} />
				<path {...props} d="M12.8438 7.74569V6.71875C12.8438 4.53681 11.3934 2.68747 9.40629 2.08344V1.40625C9.40629 0.630844 8.77544 0 8.00004 0C7.22463 0 6.59379 0.630844 6.59379 1.40625V2.08344C4.6066 2.68747 3.15629 4.53678 3.15629 6.71875V7.74569C3.15629 9.66231 2.42572 11.4797 1.09919 12.8631C0.969193 12.9986 0.932661 13.1987 1.00635 13.3714C1.08004 13.5442 1.24972 13.6562 1.43754 13.6562H14.5625C14.7503 13.6562 14.92 13.5442 14.9937 13.3714C15.0674 13.1987 15.0308 12.9986 14.9009 12.8631C13.5743 11.4797 12.8438 9.66228 12.8438 7.74569ZM8.46879 1.89775C8.31451 1.88287 8.15816 1.875 8.00004 1.875C7.84191 1.875 7.68557 1.88287 7.53129 1.89775V1.40625C7.53129 1.14778 7.74157 0.9375 8.00004 0.9375C8.25851 0.9375 8.46879 1.14778 8.46879 1.40625V1.89775Z" fill={color} />
				<path {...props} d="M14.094 6.71875C14.094 6.97762 14.3039 7.1875 14.5627 7.1875C14.8216 7.1875 15.0315 6.97762 15.0315 6.71875C15.0315 4.84062 14.3001 3.07491 12.9721 1.74687C12.789 1.56384 12.4922 1.56381 12.3092 1.74687C12.1261 1.92994 12.1261 2.22672 12.3092 2.40978C13.4601 3.56075 14.094 5.09103 14.094 6.71875Z" fill={color} />
				<path {...props} d="M1.4375 7.18751C1.69638 7.18751 1.90625 6.97763 1.90625 6.71876C1.90625 5.09107 2.54012 3.56079 3.69106 2.40982C3.87413 2.22676 3.87413 1.92998 3.69106 1.74692C3.50803 1.56385 3.21122 1.56385 3.02816 1.74692C1.70012 3.07495 0.96875 4.84063 0.96875 6.71876C0.96875 6.97763 1.17862 7.18751 1.4375 7.18751Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_162">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
NotificationCenterIcon.propTypes = propTypesIcons;

NotificationCenterIcon.defaultProps = defaultPropsIcons;
