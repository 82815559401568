import { CASINO_BET_TYPES_NAMES } from '../../../helpers/commonConstants';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';
import FilterComparator from '../../../helpers/filterComparator';
import QueryToFilterValidaion from '../../../helpers/queryToFilterValidaion';
import { createEntities } from '../../utility';

const { isValidID, isValidString, isValidArray, isValidDateRange } = QueryToFilterValidaion;

export const initFilterTypes = {
	// primary
	transactionID : isValidID, // Number
	userID        : isValidID, // Number
	gameCurrencyID: isValidID, // Number
	gameID        : isValidID, // String
	gameProviderID: isValidID, // Number
	channelID     : isValidID,
	userName      : isValidString,   // String
	gameName      : isValidString,   // String
	
	gamePlacementTime : isValidDateRange, // Date[](2)
	// advanced
	gameStatusID      : isValidID, // Number
	gameIP            : isValidString,   // String
	gameIPCountry     : isValidString,   // String
	userRegistrationIP: isValidString,   // String

	gameBetNumber: isValidArray,   // Number[](2)
	userStatusIDs: isValidArray,   // Number[]
	balanceBefore: isValidArray,   // Number[]
	balanceAfter : isValidArray,   // Number[]
	betTypeIDs   : isValidArray, // Number[]
};

export const fields = {
	transactionID        : 'id',
	gameID               : 'game_id',
	gameName             : 'game_name',
	gameWebsiteCurrencyID: 'game_website_currency_id',
	gameCurrencyID       : 'game_currency_id',
	gameProviderID       : 'game_provider_id',
	gameProviderName     : 'game_provider_name',
	gameBetNumber        : 'game_bet_number',
	gameIP               : 'game_ip',
	gameIPCountry        : 'game_ip_country',
	GGR                  : 'ggr',
	GGRWebsiteCurrency   : 'ggr_website_currency',
	gameStake            : 'game_stake',
	gameWonAmount        : 'game_won_amount',
	gameStatusID         : 'game_status_id',
	gamePlacementTime    : 'game_placement_time',
	gamePlacementTimeFrom: 'game_placement_time_from',
	gamePlacementTimeTo  : 'game_placement_time_to',

	gameBetNumberFrom: 'game_bet_number_from',
	gameBetNumberTo  : 'game_bet_number_to',

	userID            : 'user_id',
	userCountryID     : 'user_country_id',
	userStatusID      : 'user_status_id',
	userGroupID       : 'user_group_id',
	userName          : 'user_name',
	userRegistrationIP: 'user_registration_ip',

	channelID: 'channel_id',
	websiteID: 'website_id',

	userBalanceBeforeFrom: 'user_balance_before_from',
	userBalanceBeforeTo  : 'user_balance_before_to',

	betType             : 'bet_type_id',
	userBalanceAfterFrom: 'user_balance_after_from',
	userBalanceAfterTo  : 'user_balance_after_to',
	userBalanceBeforeUSD: 'user_balance_before_usd',
	userBalanceAfterUSD : 'user_balance_after_usd',
	userBalanceBefore   : 'user_balance_before',
	userBalanceAfter    : 'user_balance_after',
	gameWonAmountReal   : 'game_won_amount_real',
	gameWonAmountBonus  : 'game_won_amount_bonus',
	gameStakeReal       : 'game_stake_real',
	gameStakeBonus      : 'game_stake_bonus',

	balanceBeforeResult   : 'user_balance_before_result',
	balanceBeforeResultUSD: 'user_balance_before_result_usd',
	balanceAfterResult    : 'user_balance_after_result',
	balanceAfterResultUSD : 'user_balance_after_result_usd',

	// Total
	gameStakeCount     : 'game_stake_count',
	gameStakeSum       : 'game_stake_sum',
	gameWonAmountCount : 'game_won_amount_count',
	gameWonAmountSum   : 'game_won_amount_sum',
	gameWonAmountSumUSD: 'game_won_amount_usd_sum',
	gameStakeSumUSD    : 'game_stake_usd_sum',

	currencyID: 'currency_id',
};

const listAdapter           = createListDataAdapter();
const totalAdapter          = createTotalDataAdapter();
const transactionComparator = createTransactionComparator();

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'transactionID', fields.transactionID);
	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'gameID', fields.gameID);
	builder.addField(rules.isID, 'gameCurrencyID', fields.gameCurrencyID);
	builder.addField(rules.isID, 'gameProviderID', fields.gameProviderID);
	builder.addField(rules.isID, 'gameStatusID', fields.gameStatusID);
	builder.addField(rules.isID, 'channelID', fields.channelID);
	builder.addField(rules.isID, 'websiteID', fields.websiteID);

	builder.addField(rules.isString, 'userName', fields.userName);
	builder.addField(rules.isString, 'userRegistrationIP', fields.userRegistrationIP);
	builder.addField(rules.isString, 'gameName', fields.gameName);
	builder.addField(rules.isString, 'gameIP', fields.gameIP);
	builder.addField(rules.isString, 'gameIPCountry', fields.gameIPCountry);
	builder.addField(rules.isString, 'betType', fields.betType);

	builder.addField(rules.isArrayID, 'userStatusIDs', fields.userStatusID);
	builder.addField(rules.isArrayID, 'betTypeIDs', fields.betType);

	builder.addRangeField(rules.isNumberRange, filter.balanceBefore, [fields.userBalanceBeforeFrom, fields.userBalanceBeforeTo]);
	builder.addRangeField(rules.isNumberRange, filter.balanceAfter, [fields.userBalanceAfterFrom, fields.userBalanceAfterTo]);

	builder.addRangeField(rules.isDateTimeRange, filter.gamePlacementTime, [fields.gamePlacementTimeFrom, fields.gamePlacementTimeTo]);
	builder.addRangeField(rules.isNumberRange, filter.gameBetNumber, [fields.gameBetNumberFrom, fields.gameBetNumberTo]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptTransactionList(rawData = []) {

	const adaptedData  = rawData.data  ? listAdapter.adaptList(rawData.data) : [];
	adaptedData.forEach(item => {
		item.betType = CASINO_BET_TYPES_NAMES[item.betType];
	});
	const casinoTotals		= rawData.total ? totalAdapter.adapt(rawData.total) : totalAdapter.adapt({});
	const entities			= createEntities(adaptedData, 'transactionID');
	const transactionIDs	= Object.keys(entities);

	const result = {
		casinoTotals,
		entities,
		transactionIDs,
	};

	return result;
}

export function adaptTransaction(rawData = {}) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

// Compare ----------------------------------------------------------------------------------------

export function compareTransactionWithFilter(transaction, filter) {
	if (!transaction) {
		return false;
	}
	const hasMatches = transactionComparator.compare(transaction, filter);

	return hasMatches;
}

// Adapters ---------------------------------------------------------------------------------------

function createListDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'transactionID', fields.transactionID);
	adapter.addField(rules.id, 'gameID', fields.gameID);
	adapter.addField(rules.id, 'gameWebsiteCurrencyID', fields.gameWebsiteCurrencyID);
	adapter.addField(rules.id, 'gameCurrencyID', fields.gameCurrencyID);
	adapter.addField(rules.id, 'gameProviderID', fields.gameProviderID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'userStatusID', fields.userStatusID);
	adapter.addField(rules.id, 'userGroupID', fields.userGroupID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'gameStatusID', fields.gameStatusID);
	adapter.addField(rules.id, 'betType', fields.betType);

	adapter.addField(rules.string, 'gameName', fields.gameName);
	adapter.addField(rules.string, 'gameProviderName', fields.gameProviderName);
	adapter.addField(rules.string, 'gameIP', fields.gameIP);
	adapter.addField(rules.string, 'gameIPCountry', fields.gameIPCountry);
	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'userCountryID', fields.userCountryID);
	adapter.addField(rules.string, 'userRegistrationIP', fields.userRegistrationIP);
	adapter.addField(rules.positiveNumber, 'gameBetNumber', fields.gameBetNumber);
	adapter.addField(rules.number, 'GGR', fields.GGR);
	adapter.addField(rules.number, 'GGRWebsiteCurrency', fields.GGRWebsiteCurrency);
	adapter.addField(rules.positiveNumber, 'gameStake', fields.gameStake);
	adapter.addField(rules.positiveNumber, 'stake', fields.gameStake);
	adapter.addField(rules.positiveNumber, 'gameWonAmount', fields.gameWonAmount);

	adapter.addField(rules.fullDate, 'gamePlacementTime', fields.gamePlacementTime);

	adapter.addField(rules.positiveNumber, 'userBalanceBeforeUSD', fields.userBalanceBeforeUSD);
	adapter.addField(rules.positiveNumber, 'userBalanceAfterUSD', fields.userBalanceAfterUSD);
	adapter.addField(rules.positiveNumber, 'userBalanceBefore', fields.userBalanceBefore);
	adapter.addField(rules.positiveNumber, 'userBalanceAfter', fields.userBalanceAfter);
	adapter.addField(rules.positiveNumber, 'gameWonAmountReal', fields.gameWonAmountReal);
	adapter.addField(rules.positiveNumber, 'gameWonAmountBonus', fields.gameWonAmountBonus);
	adapter.addField(rules.positiveNumber, 'gameStakeReal', fields.gameStakeReal);
	adapter.addField(rules.positiveNumber, 'gameStakeBonus', fields.gameStakeBonus);

	adapter.addField(rules.positiveNumber, 'balanceBeforeResult', fields.balanceBeforeResult);
	adapter.addField(rules.positiveNumber, 'balanceAfterResult',  fields.balanceAfterResult);
	adapter.addField(rules.positiveNumber, 'balanceBeforeResultUSD', fields.balanceBeforeResultUSD);
	adapter.addField(rules.positiveNumber, 'balanceAfterResultUSD',  fields.balanceAfterResultUSD);

	return adapter;
}

function createTotalDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'gameStakeCount', fields.gameStakeCount);
	adapter.addField(rules.number, 'gameStakeSum', fields.gameStakeSum);
	adapter.addField(rules.number, 'gameWonAmountCount', fields.gameWonAmountCount);
	adapter.addField(rules.number, 'gameWonAmountSum', fields.gameWonAmountSum);
	adapter.addField(rules.number, 'gameWonAmountSumUSD', fields.gameWonAmountSumUSD);
	adapter.addField(rules.number, 'gameStakeSumUSD', fields.gameStakeSumUSD);

	return adapter;
}

function createTransactionComparator() {

	const comparator = new FilterComparator();
	const rules = comparator.RULES;

	comparator.addField(rules.isEqual, 'gameID', 'gameID');
	comparator.addField(rules.isEqual, 'userID', 'userID');
	comparator.addField(rules.isEqual, 'gameCurrencyID', 'gameCurrencyID');
	comparator.addField(rules.isEqual, 'gameProviderID', 'gameProviderID');

	comparator.addField(rules.isEqual, 'userName', 'userName');
	comparator.addField(rules.isEqual, 'gameName', 'gameName');
	comparator.addField(rules.isEqual, 'gameIP', 'gameIP');
	comparator.addField(rules.isEqual, 'userRegistrationIP', 'userRegistrationIP');
	comparator.addField(rules.isEqual, 'gameIPCountry', 'gameIPCountry');

	comparator.addField(rules.inArrayID, 'userStatusID', 'userStatusIDs');

	comparator.addField(rules.inNumberRange, 'gameBetNumber', 'gameBetNumber');

	return comparator;
}
