import styled, { StyledComponent } from 'styled-components';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export const InputWrapper: StyledComponent<'input', DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = styled.input`
    width: 100%;
    border-radius: 6px;
    color: rgb(89, 89, 89);
    position: relative;
    border: 0;
    outline: 1px solid #d9d9d9;
    transition: all 0.2s linear;
    padding: 10px 10px;

    :focus, :hover {
        outline: 1px solid rgb(68, 130, 255);;
    }

    :read-only {
        outline: 1px solid #d9d9d9;
        pointer-events: none;
        background-color: transparent;
    }
`;
