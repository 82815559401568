const prefix = 'LIVE_STATISTIC_TYPES';
export const liveStatisticTypesActions = {
	LIVE_STATISTIC_TYPES_UI_REFRESH          : `${prefix}_UI_REFRESH`,
	LIVE_STATISTIC_TYPES_LIST_RELOAD_CASE    : `${prefix}_LIST_RELOAD_CASE`,
	LIVE_STATISTIC_TYPES_ENTITIES_RELOAD_CASE: `${prefix}_ENTITIES_RELOAD_CASE`,

	listReloadCase: data => ({
		type: liveStatisticTypesActions.LIVE_STATISTIC_TYPES_LIST_RELOAD_CASE,
		data,
	}),

	entitiesReloadCase: data => ({
		type: liveStatisticTypesActions.LIVE_STATISTIC_TYPES_ENTITIES_RELOAD_CASE,
		data,
	}),

	uiRefresh: data => ({
		type: liveStatisticTypesActions.LIVE_STATISTIC_TYPES_UI_REFRESH,
		data,
	}),
};
