import { EVENT_TYPES } from '../codes';
import { emitEvent } from '../socket';

function subscribeToUpdate(riskIDs) {

	const event = {
		subjectType: EVENT_TYPES.riskManagement,
		ids        : riskIDs,
	};

	return emitEvent('subscribe', event);
}

export const riskAPI = {
	subscribeToUpdate,
};
