import { createSelector } from 'reselect';

const sorting = (tableType, state) => state.Tables.get(tableType).sorting;

const pagination = (tableType, state) => {
	return state.Tables.get(tableType).pagination;
};

const loading = (tableType, state) => state.Tables.get(tableType).loading;

const savedColumns = (tableType, state) => state.Tables.get(tableType).savedColumns;

export const deriveTableSorting = createSelector(
	[sorting],
	(sorting) => sorting
);

export const deriveTablePagination = createSelector(
	[pagination],
	(pagination) => pagination
);

export const deriveTableLoading = createSelector(
	[loading],
	(loading) => loading
);

export const deriveTableSavedColumns = createSelector(
	[savedColumns],
	(savedColumns) => savedColumns
);
