import { all, fork } from 'redux-saga/effects';

import sportLimitSaga from './sport/saga';
import casinoSaga from './casino/saga';
import limitsPayment from './userLimitsPayment/saga';

export default function* usersRootSaga() {
	yield all([
		fork(sportLimitSaga),
		fork(casinoSaga),
		fork(limitsPayment),
	]);
}
