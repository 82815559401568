const actions = {

	SELECTIONS_LIST_RELOAD : 'SELECTIONS_LIST_RELOAD',
	SELECTIONS_LIST_REFRESH: 'SELECTIONS_LIST_REFRESH',

	listReload: marketID => ({
		type: actions.SELECTIONS_LIST_RELOAD,
		data: {
			marketID,
		},
	}),
	listRefresh: list => ({
		type: actions.SELECTIONS_LIST_REFRESH,
		data: list,
	}),
};

export default actions;
