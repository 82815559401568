import EntityAdapter from '../../../helpers/entityAdapter';

const fields = {
	casinoBonusRedemption: 'casino_bonus_redemption',
	sportBonusRedemption : 'sport_bonus_redemption',
	casinoMaxWin         : 'casino_max_win',
	depositTotal         : 'deposit_total',
	withdrawTotal        : 'withdraw_total',
	signups              : 'signups',
	bounceRate           : 'bounce_rate',
	visitors             : 'visitors',
	newVisitors          : 'new_visitors',
	returns              : 'returns',
	sessionAvgDuration   : 'session_avg_duration',
	sessions             : 'sessions',
	sportMaxWin          : 'sport_max_win',
	ggr                  : 'ggr',
	ggrCasino            : 'ggr_casino',
	ggrSport             : 'ggr_sport',

};
const objAdapter = createObjAdapter();
const objItemAdapter = createObjItemsAdapter();

export function adaptOverallData(rawData = {}) {
	objAdapter.clearExcludes();
	const adaptedData = objAdapter.adapt(rawData);
	return adaptedData;
}

export function createObjAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.noCondition, 'casinoBonusRedemption', fields.casinoBonusRedemption);
	adapter.addField(rules.noCondition, 'sportBonusRedemption', fields.sportBonusRedemption);
	adapter.addField(rules.noCondition, 'casinoMaxWin', fields.casinoMaxWin);
	adapter.addField(rules.noCondition, 'depositTotal', fields.depositTotal);
	adapter.addField(rules.noCondition, 'withdrawTotal', fields.withdrawTotal);
	adapter.addField(rules.noCondition, 'signups', fields.signups);
	adapter.addField(rules.noCondition, 'bounceRate', fields.bounceRate);
	adapter.addField(rules.noCondition, 'visitors', fields.visitors);
	adapter.addField(rules.noCondition, 'newVisitors', fields.newVisitors);
	adapter.addField(rules.noCondition, 'returns', fields.returns);
	adapter.addField(rules.noCondition, 'sessionAvgDuration', fields.sessionAvgDuration);
	adapter.addField(rules.noCondition, 'sessions', fields.sessions);
	adapter.addField(rules.noCondition, 'sportMaxWin', fields.sportMaxWin);
	adapter.addField(rules.noCondition, 'ggr', fields.ggr);
	adapter.addField(rules.noCondition, 'ggrCasino', fields.ggrCasino);
	adapter.addField(rules.noCondition, 'ggrSport', fields.ggrSport);

	return adapter;
}

export function adaptObjData(rawData = {}) {
	objAdapter.clearExcludes();
	const adaptedData = objItemAdapter.adapt(rawData);
	return adaptedData;
}

export function createObjItemsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'count', 'count');
	adapter.addField(rules.number, 'perc', 'perc');

	return adapter;
}
