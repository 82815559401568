import { combineReducers } from 'redux';

import UserLimitsAndRestrictions from './userLimitsAndRestrictions/reducer';
import userSMS from './sendSMS/reducer';
import ResponsibleGambling from './responsibleGambling/slice';
import UserAllTransactions from './transactions/slice';

export default combineReducers({
	UserLimitsAndRestrictions,
	userSMS,
	ResponsibleGambling,
	UserAllTransactions,
});
