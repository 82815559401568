const actions = {

	PLAYERS_OVERALL_DATA_RELOAD : 'PLAYERS_OVERALL_DATA_RELOAD',
	PLAYERS_OVERALL_DATA_REFRESH: 'PLAYERS_OVERALL_DATA_REFRESH',
	PLAYERS_OVERALL_UI_REFRESH  : 'PLAYERS_OVERALL_UI_REFRESH',

	overallDataRefresh: data => ({
		type: actions.PLAYERS_OVERALL_DATA_REFRESH,
		data: {
			overallData: data,
		},
	}),
	overallDataReload: () => ({
		type: actions.PLAYERS_OVERALL_DATA_RELOAD,
	}),
	overalUIRefresh: data => ({
		type: actions.PLAYERS_OVERALL_UI_REFRESH,
		data,
	}),
};


export default actions;
