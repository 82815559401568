import { Map } from 'immutable';
import invert from 'lodash/invert';
import { fill } from '../../helpers/utils';
import { ADMIN_TABLE_TYPES } from '../../helpers/commonConstants';
import actions from './actions';

const invertedTypes = invert(ADMIN_TABLE_TYPES);

export const template = {
	savedColumns: [],
	sorting     : {
		sortBy   : '',
		sortOrder: 'desc', // 'asc'
	},
	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},
	loading: false,
};

const initState = new Map({
	BET_RTM                       : { ...template },
	BET_PROFILE                   : { ...template },
	DEPOSIT_RTM                   : { ...template },
	DEPOSIT_PROFILE               : { ...template },
	WITHDRAW_RTM                  : { ...template },
	WITHDRAW_PROFILE              : { ...template },
	CASINO_RTM                    : { ...template },
	CASINO_PROFILE                : { ...template },
	EVENTS                        : { ...template },
	RESULTS                       : { ...template },
	CUSTOMERS_SEARCH              : { ...template },
	CUSTOMERS_GROUPS              : { ...template },
	TRANSACTIONS                  : { ...template },
	INFO_PAGES                    : { ...template },
	NEWS                          : { ...template },
	BANNERS                       : { ...template },
	BONUSES_FREE_SPINS            : { ...template },
	BONUSES_REGISTRATION_FREE_SPIN: { ...template },
	BONUSES_WELCOME_DEPOSIT       : { ...template },
	BONUSES                       : { ...template },
	PERSONAL                      : { ...template },
	CONTENT_INFO_PAGES            : { ...template },
	CONTENT_NEWS                  : { ...template },
	WEBSITES                      : { ...template },
	BANNERS_GROUPS                : { ...template },
	EMAILS_LIST                   : { ...template },
	EMAIL_TEMPLATES               : { ...template },
	HOMEPAGE_UPCOMING_EVENTS      : { ...template },
	CASHBACK                      : { ...template },
	CASHBACK_USERS                : { ...template },
	HOMEPAGE_SPORTS_PRIORITIES    : { ...template },
	HOMEPAGE_TOP_LIVE_EVENTS      : { ...template },
	HOMEPAGE_MATCH_OF_THE_DAY     : { ...template },
	BONUSES_FREE_BETS             : { ...template },
	REPORT_BY_PLAYER              : { ...template },
	REPORT_BY_PROVIDER            : { ...template },
	REPORT_BY_INTEGRATOR          : { ...template },
	PROMOTIONS                    : { ...template },
	HELP_CENTER                   : { ...template },
	HOMEPAGE_SPECIAL_OFFERS       : { ...template },
	REPORT_BY_GAME                : { ...template },
	PARTNERS                      : { ...template },
	NOTIFICATION                  : { ...template },
	GAME                          : { ...template },
	DOCUMENTS_RTM                 : { ...template },
	RISK_MANAGEMENT_RTM           : { ...template },
	SMS_LISTING                   : { ...template },
	TOURNAMENTS                   : { ...template },
	SPORT_RISK_MANAGEMENT         : { ...template },
	USER_ALL_TRANSACTION          : { ...template },
});

export default function tablesReducer(state = initState, action) {

	switch (action.type) {

		case actions.COLUMNS_REFRESH: {
			const { type, tableData } = action.data;
			const data                = {
				savedColumns: tableData,
			};

			const target = state.get(type);
			const result = fill(data, target);

			return state.set(type, result);
		}

		case actions.SORTING_REFRESH: {
			const { type, sorting } = action.data;
			const data              = { sorting };

			const target = state.get(type);
			const result = fill(data, target);
			return state.set(type, result);
		}

		case actions.PAGINATION_REFRESH: {
			const { pagination, type } = action.data;
			const paginationTarget     = state.get(type).pagination;
			const paginationResult     = fill(pagination, paginationTarget);
			const target               = state.get(type);
			const result               = fill({ pagination: paginationResult }, target);
			return state.set(type, result);
		}

		case actions.LOADING_REFRESH: {
			const { type, loading } = action.data;
			const data              = { loading };
			const target = state.get(type);
			const result = fill(data, target);

			return state.set(type, result);
		}

		case actions.SET_TABLES: {
			const { mapTable } = action.data;
			return state.merge(mapTable);
		}

		default:
			return state;
	}
}

export function getTablesTypeName(typeID) {
	return invertedTypes[typeID] || String(typeID);
}
