import { Map } from 'immutable';
import { countryActions } from './actions';


const initState = new Map({

	list    : [],
	entities: {},

	UI: { loading: false },
});

export default function countryReducer(state = initState, action) {

	switch (action.type) {
		case countryActions.GET_COUNTRY_ENTITIES_CASE: {
			return state.set('entities', action.data);
		}
		case countryActions.GET_COUNTRY_LIST_CASE: {
			return state.set('list', action.data);
		}
		default:
			return state;
	}
}
