import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	list         : [],
	risks        : {},
	riskTypesList: [],

	UI: { loading: false },
});

export default function riskManagementSettingsReducer(state = initState, action) {
	switch (action.type) {
		case actions.WEBSITE_RISK_MANAGEMENT_LIST_REFRESH: {
			return state.set('list', action.data);
		}
		case actions.WEBSITE_RISK_MANAGEMENT_RISKS_REFRESH: {
			return state.set('risks', action.data);
		}
		case actions.WEBSITE_RISK_MANAGEMENT_ADD: {
			const target = state.get('list') ? [...state.get('list')] : [];
			const newItems = [action.data, ...target];
			return state.set('list', newItems);
		}
		case actions.WEBSITE_RISK_MANAGEMENT_RISK_ADD: {
			const newLimits = { ...state.get('risks'), ...action.data };
			return state.set('risks', newLimits);
		}
		case actions.WEBSITE_RISK_MANAGEMENT_UI_REFRESH: {
			return state.set('UI', action.data);
		}
		case actions.WEBSITE_RISK_MANAGEMENT_DATA_RESET: {
			return initState;
		}
		default:
			return state;
	}
}
