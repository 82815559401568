import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import EntityAdapter from '../../../../helpers/entityAdapter';

const fields = {
	id        : 'id',
	iconMobile: 'icon_mobile',
	iconWeb   : 'icon_web',
	name      : 'name',
	sportID   : 'sport_id',
	websiteID : 'website_id',
};

const listAdapter = createListAdapter();

export function adaptList(rawData = {}) {
	const sortedData = rawData.sort((a, b) => b.id - a.id);
	listAdapter.clearExcludes();

	return listAdapter.adaptList(sortedData);
}

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'iconMobile', fields.iconMobile);
	adapter.addField(rules.string, 'iconWeb', fields.iconWeb);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	return adapter;
}

export function convertToEntities(data = []) {
	const entities = {};
	if (!isArray(data)) {
		return entities;
	}

	data.forEach(item => {
		const itemID = toInteger(item.id);
		entities[itemID] = convertToEntity(item);
	});
	return entities;
}

function convertToEntity(data) {
	return {
		id        : data.id,
		name      : data.name,
		sportID   : data.sportID,
		websiteID : data.websiteID,
		iconWeb   : data.iconWeb,
		iconMobile: data.iconMobile,
	};
}
