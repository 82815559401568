export enum ErrorCodes {
	ACCEPTED = 202,
	BAD_GATEWAY = 502,
	BAD_REQUEST = 400,
	CONFLICT = 409,
	CONTINUE = 100,
	CREATED = 201,
	EXPECTATION_FAILED = 417,
	FAILED_DEPENDENCY = 424,
	FORBIDDEN = 403,
	GATEWAY_TIMEOUT = 504,
	GONE = 410,
	HTTP_VERSION_NOT_SUPPORTED = 505,
	IM_A_TEAPOT = 418,
	INSUFFICIENT_SPACE_ON_RESOURCE = 419,
	INSUFFICIENT_STORAGE = 507,
	INTERNAL_SERVER_ERROR = 500,
	LENGTH_REQUIRED = 411,
	LOCKED = 423,
	METHOD_FAILURE = 420,
	METHOD_NOT_ALLOWED = 405,
	MOVED_PERMANENTLY = 301,
	MOVED_TEMPORARILY = 302,
	MULTI_STATUS = 207,
	MULTIPLE_CHOICES = 300,
	NETWORK_AUTHENTICATION_REQUIRED = 511,
	NO_CONTENT = 204,
	NON_AUTHORITATIVE_INFORMATION = 203,
	NOT_ACCEPTABLE = 406,
	NOT_FOUND = 404,
	NOT_IMPLEMENTED = 501,
	NOT_MODIFIED = 304,
	OK = 200,
	PARTIAL_CONTENT = 206,
	PAYMENT_REQUIRED = 402,
	PERMANENT_REDIRECT = 308,
	PRECONDITION_FAILED = 412,
	PRECONDITION_REQUIRED = 428,
	PROCESSING = 102,
	PROXY_AUTHENTICATION_REQUIRED = 407,
	REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
	REQUEST_TIMEOUT = 408,
	REQUEST_TOO_LONG = 413,
	REQUEST_URI_TOO_LONG = 414,
	REQUESTED_RANGE_NOT_SATISFIABLE = 416,
	RESET_CONTENT = 205,
	SEE_OTHER = 303,
	SERVICE_UNAVAILABLE = 503,
	SWITCHING_PROTOCOLS = 101,
	TEMPORARY_REDIRECT = 307,
	TOO_MANY_REQUESTS = 429,
	UNAUTHORIZED = 401,
	UNPROCESSABLE_ENTITY = 422,
	UNSUPPORTED_MEDIA_TYPE = 415,
	USE_PROXY = 305,
	CATEGORY_DOES_NOT_EXISTS = 917,
	EXCEEDED_WITHDRAWAL_LIMIT = 918,
	EXCEEDED_COMBINATIONS_MAXIMUM_LIMIT = 919,
	NOT_SATISFIED_MINIMUM_LIMIT = 920,
	EXCEEDED_MAXIMUM_LIMIT = 921,
	EXCEEDED_PER_COMBINATION_MAXIMUM_LIMIT = 922,
	CATEGORY_ALREADY_EXISTS = 923,
	MARKET_DOES_NOT_EXISTS = 924,
	NO_SUCH_CODE = 925,
	EVENT_DOES_NOT_EXISTS = 926,
	INCORRECT_MARKET_CODE = 927,
	NOT_SUPPORTED_EVENT_TYPE = 928,
	INCORRECT_SELECTION = 929,
	FOUND_MORE_THAN_ONE_EVENT = 930,
	EVENT_NOT_FOUND = 931,
	PARTICIPANT_NOT_MAPPED = 932,
	SPORT_NOT_MAPPED = 933,
	COUNTRY_NOT_MAPPED = 934,
	LEAGUE_NOT_MAPPED = 935,
	INCORRECT_SELECTION_TYPE = 936,
	MARKET_ID_IS_MISSING = 937,
	MAP_ID_IS_MISSING = 938,
	QUERY_ERROR = 939,
	SELECTIONS_MAPPINGS_MISSING = 940,
	SELECTION_MAP_ID_IS_MISSING = 941,
	SELECTION_SYSTEM_ID_IS_MISSING = 942,
	PROVIDER_ID_IS_MISSING = 943,
	MARKET_MAPPINGS_MISSING = 944,
	MARKET_NOT_MAPPED = 945,
	MARKET_NOT_FOUND = 946,
	EVENT_NOT_MAPPED = 947,
	SELECTION_NOT_MAPPED = 948,
	EVENT_MARKET_NOT_MAPPED = 949,
	UNKNOWN_PROVIDER = 950,
	PARTICIPANT_ID_MISSING = 951,
	INVALID_USER_ID = 952,
	WRONG_ENTITY = 953,
	USER_ID_MISSING = 954,
	USER_NOT_FOUND = 955,
	SPORT_NOT_FOUND = 956,
	COUNTRY_NOT_FOUND = 957,
	LEAGUE_NOT_FOUND = 958,
	SELECTION_DOES_NOT_EXIST = 959,
	EVENT_MARKET_NOT_FOUND = 960,
	EVENT_SELECTION_NOT_FOUND = 961,
	INCORRECT_AMOUNT = 962,
	ACCESS_DENIED = 963,
	EVENT_IS_NOT_ACTIVE = 964,
	EVENT_MARKET_IS_NOT_ACTIVE = 965,
	EVENT_SELECTION_IS_NOT_ACTIVE = 966,
	MARKET_IS_NOT_ACTIVE = 967,
	SPORT_IS_NOT_ACTIVE = 968,
	COUNTRY_IS_NOT_ACTIVE = 969,
	LEAGUE_IS_NOT_ACTIVE = 970,
	EVENT_SELECTION_ODD_RESTRICTION = 971,
	COMBINATION_GROUP_RESTRICTION = 972,
	BAD_QUERY = 973,
	CATEGORY_NOT_FOUND = 974,
	CATEGORY_CODE_UNIQUENESS_VIOLATION = 975,
	UPDATE_DEFAULT_MARKET = 976,
	CATEGORY_NOT_ACTIVE = 977,
	INACTIVE_USER = 978,
	INSUFFICIENT_BALANCE = 979,
	INVALID_BET_TYPE = 980,
	INVALID_BET_DETAILS = 981,
	INVALID_STAKE = 982,
	EMAIL_TEMPLATE_NOT_FOUND = 983,
	EMAIL_NOT_FOUND = 984,
	BLOCKED_USER = 985,
	MARKET_IS_NOT_READY_FOR_BETS = 986,
	EVENT_HISTORY_ID_DOES_NOT_EXIST = 987,
	INVALID_CATEGORY_ID = 988,
	INVALID_LEAGUE_ID = 989,
	LEAGUE_TYPE_NOT_FOUND = 990,
	MARKET_SELECTION_ALREADY_EXISTS = 991,
	PARTICIPANT_MEMBER_ID_MISSING = 992,
	PARTICIPANT_MEMBER_ALREADY_EXISTS = 993,
	PARTICIPANT_MEMBER_NOT_FOUND = 994,
	PARTICIPANT_NOT_FOUND = 995,
	WRONG_PARTICIPANT_TYPE = 996,
	MARKET_ALREADY_EXISTS = 997,
	INVALID_TRADING_MODE = 998,
	INVALID_SETTINGS = 999,
	INVALID_CODE = 1000,
	INVALID_CATEGORY_TYPE = 1001,
	MARKET_IS_NOT_IN_LEAGUE = 1002,
	INVALID_EMAIL = 1003,
	INVALID_USERNAME = 1004,
	WRONG_USERNAME_PASSWORD = 1005,
	HASH_IS_NOT_STRING = 1006,
	REQUEST_ALREADY_SENT = 1007,
	EXPIRED = 1008,
	INVALID_STRING = 1009,
	DUPLICATE = 1010,
	INVALID_AMOUNT = 1011,
	INVALID_PERMISSIONS = 1012,
	EVENT_MISSING_START_DATE = 1013,
	MORE_THAN_ONE_EVENT_FOUND = 1014,
	WRONG_PARTICIPANT = 1015,
	UNKNOWN_TABLE = 1016,
	INVALID_MAPPING = 1017,
	MAPPING_NOT_FOUND = 1018,
	LEAGUE_MAPPING_NOT_FOUND = 1019,
	NOT_MAPPED = 1020,
	PARTICIPANT_IS_NOT_IN_LEAGUE = 1021,
	SELECTION_FIXED_MIN_INVALID = 1022,
	SELECTION_FIXED_MAX_INVALID = 1023,
	SELECTION_FIXED_MIN_MAX_CONFLICT = 1024,
	INVALID_RULE = 1025,
	RESULT_NOT_FOUND = 1026,
	MARKET_COMBINATION_MAX_ERROR = 1027,
	MARKET_COMBINATION_MIN_ERROR = 1028,
	MARKET_SETTING_DOES_NOT_EXISTS = 1029,
	SPORT_COMBINATION_MAX_ERROR = 1030,
	SPORT_COMBINATION_MIN_ERROR = 1031,
	COUNTRY_COMBINATION_MAX_ERROR = 1032,
	COUNTRY_COMBINATION_MIN_ERROR = 1033,
	LEAGUE_COMBINATION_MAX_ERROR = 1034,
	LEAGUE_COMBINATION_MIN_ERROR = 1035,
	CHANNEL_MISSING = 1036,
	WRONG_CHANNEL = 1037,
	EVENT_RESTRICTED_BET = 1038,
	LEAGUE_RESTRICTED_BET = 1039,
	COUNTRY_RESTRICTED_BET = 1040,
	SPORT_RESTRICTED_BET = 1041,
	POSSIBLE_WIN_MORE_THAN_OVERASK = 1042,
	POSSIBLE_WIN_LESS_THAN_OVERASK = 1043,
	BET_IN_REVIEW = 1044,
	STAKE_MORE_THEN_OVERASK = 1045,
	STAKE_LESS_THEN_OVERASK = 1046,
	OVERASK_WRONG_SETTINGS = 1047,
	USER_STAKE_LIMIT_ERROR = 1048,
	USER_WON_LIMIT_ERROR = 1049,
	BET_WON_LIMIT_ERROR = 1050,
	BET_STAKE_LIMIT_ERROR = 1051,
	SELECTION_MIXED_MULTIPLIES_ERROR = 1052,
	MARKET_MIXED_MULTIPLIES_ERROR = 1053,
	LEAGUE_MIXED_MULTIPLIES_ERROR = 1054,
	COUNTRY_MIXED_MULTIPLIES_ERROR = 1055,
	SPORT_MIXED_MULTIPLIES_ERROR = 1056,
	BET_LIMIT_WRONG_SETTINGS = 1057,
	INVALID_DYNAMIC_LIMIT = 1058,
	CATEGORY_MAPPING_NOT_FOUND = 1059,
	SELECTION_SETTING_DOES_NOT_EXISTS = 1060,
	SELECTION_COMBINATION_MIN_ERROR = 1061,
	SELECTION_COMBINATION_MAX_ERROR = 1062,
	INVALID_CURRENCY = 1063,
	EVENT_SELECTION_SETTINGS_NOT_FOUND = 1064,
	EVENT_MARKET_SETTINGS_NOT_FOUND = 1065,
	INVALID_FIRST_NAME = 1066,
	INVALID_LAST_NAME = 1067,
	INVALID_BIRTHDAY = 1068,
	INVALID_COUNTRY = 1069,
	INVALID_ADDRESS = 1070,
	INVALID_CITY = 1071,
	INVALID_STATE = 1072,
	INVALID_ZIP_CODE = 1073,
	INVALID_PHONE = 1074,
	INVALID_PASSWORD = 1075,
	INVALID_ANSWER = 1076,
	INVALID_AGE_18 = 1077,
	INVALID_SECRET = 1078,
	USER_ALREADY_EXISTS = 1079,
	PHONE_ALREADY_EXISTS = 1080,
	USERNAME_ALREADY_EXISTS = 1081,
	EMAIL_ALREADY_EXISTS = 1082,
	INVALID_QUESTION = 1083,
	USER_HASH_IS_NOT_FOUND = 1084,
	USER_HASH_IS_EXPIRED = 1085,
	EVENT_HAVE_ACTIVE_BET = 1086,
	EVENT_MARKET_HAVE_ACTIVE_BET = 1087,
	EVENT_SELECTION_HAVE_ACTIVE_BET = 1088,
	SMS_CODE_IS_INVALID = 1089,
	SMS_CODE_IS_EXPIRED = 1090,
	SMS_CODE_SENT = 1091,
	SMS_CODE_IS_OFF = 1092,
	EVENT_SETTINGS_DOES_NOT_EXISTS = 1093,
	MARKET_HAVE_ACTIVE_BET = 1094,
	SELECTION_HAVE_ACTIVE_BET = 1095,
	USER_NOT_VERIFIED = 1096,
	CURRENCY_NOT_SUPPORTED = 1097,
	TRANSACTION_NOT_FOUND = 1098,
	WRONG_WEBSITE_ID = 1099,
	PAYMENT_METHOD_NOT_SUPPORTED_FOR_THIS_WEBSITE = 1100,
	UNKNOWN_FILTER = 1101,
	INVALID_DOMAIN = 1102,
	BET_IS_NOT_IN_REVIEW = 1103,
	INVALID_STATUS = 1104,
	SCOPE_OR_STAT_NOT_FOUND = 1105,
	PAYMENT_NOT_FOUND = 1107,
	WEBSITE_NOT_FOUND = 1108,
	PAYMENT_DOES_NOT_SUPPORT_SELECTED_CURRENCY = 1109,
	INVALID_IP = 1110,
	COMMUNICATION_ERROR = 1111,
	PURSE_NOT_FOUND = 1112,
	BANNER_ID_MISSING = 1113,
	INVALID_REGISTRATION = 1114,
	INVALID_PAYMENT_DETAILS = 1115,
	INSUFFICIENT_MERCHANT_FUNDS = 1116,
	SOME_ELSE_EVENT_AT_THIS_TIME_SELECTED = 1117,
	SESSION_NOT_FOUND = 1118,
	CASINO_GAME_NOT_FOUND = 1119,
	PAYMEGA_API_AUTH_ERROR = 1120,
	PAYMEGA_API_SETTLE_ERROR = 1121,
	TRANSACTION_AUTH_FAILED = 1122,
	TRANSACTION_SETTLE_FAILED = 1123,
	TRANSACTION_CHECK_FAILED = 1124,
	TRANSACTION_VOID_FAILED = 1125,
	UNKNOWN_DOMAIN = 1126,
	UNKNOWN_EMAIL_TEMPLATE = 1127,
	CASINO_ID_IS_INVALID = 1128,
	PASSWORD_LENGTH_6 = 1129,
	TRANSACTION_INVALID_DETAILS = 1130,
	PHONE_VERIFICATION_FAILED = 1131,
	WITHDRAW_MIN_LIMIT = 1132,
	WITHDRAW_MAX_LIMIT = 1133,
	DEPOSIT_MAX_LIMIT = 1134,
	DEPOSIT_MIN_LIMIT = 1135,
	REGISTRATION_EMAIL_SEND_BUT_NOT_VERIFIED = 1136,
	ACTIVE_BETS_ON_EVENT = 1137,
	VERIFIED_DOCUMENT_CANNOT_BE_DELETED = 1138,
	FIELD_IS_BLOCKED_TO_EDIT = 1139,
	INVALID_SOCIAL_ID = 1140,
	EVENT_MARKET_MAX_WINNERS = 1141,
	PARTICIPANT_BLOCKED_BY_EVENT = 1142,
	DOCUMENT_WAS_DELETED_BY_USER = 1143,
	AGE_FROM_IS_NOT_NUMBER = 1144,
	AGE_FROM_IS_LESS_THAN_18 = 1145,
	AGE_TO_IS_NOT_NUMBER = 1146,
	AGE_TO_IS_NOT_BETWEEN_18_AND_100 = 1147,
	AGE_TO_IS_LESS_THAN_AGE_FROM = 1148,
	AGE_FROM_IS_LESS_THAN_21 = 1149,
	INVALID_DATE = 1150,
	GAMING_BLOCKED = 1151,
	INVALID_TYPE_ID = 1152,
	BONUS_NOT_FOUND = 1153,
	WITHDRAW_FAILED = 1154,
	SUPPORT_REQUEST_NOT_FOUND = 1155,
	SUPPORT_REQUEST_MESSAGE_NOT_FOUND = 1156,
	BODY_LENGTH_EXCEED = 1157,
	WITHDRAWAL_DECISION_FAILED = 1158,
	DEPOSIT_FAILED = 1159,
	AMOUNT_IS_MORE_THAN_BALANCE = 1160,
	WEBSITE_PAYMENT_MODEL_IS_NOT_FOUND = 1161,
	WINPAY_RETURN_PAYMENT_REQUEST_IS_FAILED = 1162,
	APPROVE_FAILED = 1163,
	MARKET_BLOCKED_BY_EVENT = 1164,
	SELECTION_BLOCKED_BY_EVENT = 1165,
	FREE_SPINS_NOT_AVAILABLE_FOR_THIS_GAME = 1166,
	INVALID_CUSTOMER_ID = 1167,
	INVALID_SOURCE = 1168,
	EXCHANGE_RATE_NOT_FOUND = 1169,
	INVALID_CARD = 1170,
	MERCHANT_PASSWORD_IS_EMPTY = 1171,
	MERCHANT_ID_IS_EMPTY = 1172,
	CARD_NUMBER_NOT_FOUND = 1173,
	ROUND_NOT_FOUND = 1174,
	BONUS_WALLET_NOT_FOUND = 1175,
	FIRST_NAME_OR_LAST_NAME_MISSING = 1176,
	USER_NAME_NOT_FOUND = 1177,
	INVALID_DATE_FORMAT = 1178,
	ODD_CHANGED = 1179,
	SELECTION_IS_NOT_ACTIVE = 1180,
	INCOMPATIBLE_GROUPS = 1181,
	SELECTION_NAME_NOT_FOUND = 1182,
	MARKET_NAME_NOT_FOUND = 1183,
	INVALID_TOKEN = 1184,
	INCORRECT_SECURITY_SIGNATURE = 1185,
	AMOUNT_CHANGED = 1186,
	INVALID_ACCOUNT = 1187,
	EXCEEDED_ALLOWED_MAX_STAKE = 1188,
	INVALID_NOTIFICATION = 1189,
	EVENT_MARKET_HAVE_INACTIVE_SELECTION = 1190,
	BLOCKED_DOMAIN = 1191,
	THERE_ARE_FINISHED_OR_SETTLED_CASH_BACKS = 1192,
	WRONG_DATES_SET_IN_CASH_BACKS = 1193,
	CASINO_BET_NOT_FOUND = 1194,
	EXPRESS_STATISTIC_ERROR = 1195,
	NEW_MATCH_OLD_PASSWORD = 1196,
	WRONG_EVENT_MARKET_CREATED = 1197,
	WRONG_PERIOD_SET_IN_CASH_BACKS = 1198,
	SETTLEMENT_DATE_IS_LESSER_OR_EQUAL_TO_END_DATE = 1199,
	THERE_ARE_SUB_CASH_BACKS_IN_REQUEST_DATA = 1200,
	SENT_DATA_CASH_BACK_HAS_NO_SUB_BUT_IS_RECURRING = 1201,
	WRONG_SUB_CASH_BACKS_COUNT = 1202,
	CASH_BACK_ID_IS_MISSING = 1203,
	CUSTOM_COEFFICIENT_CANT_BE_LOWER_THAN_REGULAR = 1204,
	WAGERING_TURNOVER_IS_REQUIRED = 1205,
	DAYS_TO_EXPIRE_REQUIRED = 1206,
	BONUS_ALREADY_CLAIMED = 1207,
	INVALID_OLD_PASSWORD = 1208,
	INVALID_CATEGORY_CODE = 1209,
	SUSPENDED = 1210,
	NOT_EDITABLE = 1211,
	SPECIAL_OFFER_NOT_FOUND = 1212,
	BLOCKED_IP = 1213,
	ANOTHER_BET_IN_PROGRESS = 1214,
	COMBINATION_COUNT_ERROR = 1215,
	TRANSACTION_NOT_READY = 1216,
	SELECTION_NOT_FOUND = 1217,
	RISK_GROUP_NOT_FOUND = 1218,
	SCOPE_NOT_FOUND = 1219,
	RECURRING_OPTION_CANT_BE_CHANGED = 1220,
	INVALID_USER_LIMIT = 1221,
	INVALID_BET_LIMIT = 1222,
	INACTIVE_GAME = 1223,
	INVALID_INPUT_TYPE = 1224,
	INVALID_GGR_LIMITS = 1225,
	FOUND_OVERLAPPING_CASHBACKS = 1226,
	INVALID_IMAGE_SIZE = 1227,
	DOMAIN_ALREADY_EXISTS = 1228,
	INVALID_CATEGORY_ALIAS = 1229,
	DUPLICATE_CATEGORY_ALIAS = 1230,
	ROYAL_PAY_API_ERROR = 1231,
	WRONG_CURRENCY = 1232,
	DELETED_USER = 1233,
	SELF_DEACTIVATED_USER = 1234,
	DUPLICATE_ORDER_NUMBER = 1235,
	USER_MISSING_FIELDS = 1236,
	WEBSITE_MAILING_HOST_MISSING = 1237,
	WEBSITE_MAILING_PORT_MISSING = 1238,
	WEBSITE_MAILING_FROM_MISSING = 1239,
	WEBSITE_MAILING_PROTOCOL_MISSING = 1240,
	WEBSITE_MAILING_USERNAME_MISSING = 1241,
	WEBSITE_MAILING_PASSWORD_MISSING = 1242,
	WEBSITE_MAILING_API_ID_MISSING = 1243,
	WEBSITE_MAILING_API_KEY_MISSING = 1244,
	WEBSITE_MAILING_INVALID_PROTOCOL = 1245,
	WEBSITE_MAILING_INVALID_SERVICE = 1246,
	WEBSITE_CDN_API_KEY_MISSING = 1247,
	WEBSITE_CDN_INVALID_SERVICE_ID = 1248,
	WEBSITE_CRM_API_KEY_MISSING = 1249,
	WEBSITE_CRM_INVALID_SERVICE_ID = 1250,
	WEBSITE_CRM_CANT_SET_NONE_WITH_OTHER_SERVICE = 1251,
	WEBSITE_AFFILIATE_INVALID_SERVICE_ID = 1252,
	WEBSITE_AFFILIATE_API_KEY_MISSING = 1253,
	CANT_DELETE_CATEGORY_WITH_GAMES = 1254,
	BONUS_ALREADY_DECLINED = 1255,
	EXPIRED_BONUS = 1256,
	EMAIL_ADDRESS_CAN_NOT_BE_USED = 1257,
	USER_PAYMENT_LIMIT_ERROR = 1258,
	INVALID_USER_CASINO_LIMITS_TYPE = 1259,
	INVALID_USER_CASINO_LIMITS = 1260,
	GAME_IS_UNAVAILABLE = 1261,
	USER_GAME_BET_LIMIT_ERROR = 1262,
	DEPOSIT_BLOCKED = 1263,
	WITHDRAWAL_BLOCKED = 1264,
	INVALID_ADMIN_TYPE = 1265,
	CANT_CREATE_PARTNER_INVALID_ADMIN_TYPE = 1266,
	CANT_UPDATE_PARTNER_INVALID_ADMIN_TYPE = 1267,
	CANT_GET_PARTNER_INVALID_ADMIN_TYPE = 1268,
	SYSTEM_OPERATOR_MUST_HAVE_AT_LEAST_ONE_PARTNER_ID = 1269,
	PARTNER_OPERATOR_MUST_HAVE_AT_LEAST_ONE_WEBSITE_ID = 1270,
	PARTNER_NOT_FOUND = 1271,
	CANT_UPDATE_PARTNER_SETTINGS_INVALID_ADMIN_TYPE = 1272,
	CANT_GET_PARTNER_SETTINGS_INVALID_ADMIN_TYPE = 1273,
	CANT_DELETE_DEFAULT_SMS_GATEWAY = 1274,
	INVALID_SMS_GATEWAY_ID = 1275,
	INVALID_WEBSITE_SMS_GATEWAY = 1276,
	WAGERING_TURNOVER_SHOULD_NOT_BE_IN_BALANCE_ADJUSTMENT = 1277,
	EMAIL_VERIFICATION_REQUIRED = 1278,
	PHONE_VERIFICATION_REQUIRED = 1279,
	INVALID_TELEPHONE_PREFIXES = 1280,
	INVALID_TOURNAMENT = 1281,
	INVALID_RULE_TYPE = 1282,
	PASSWORD_EXPIRED = 1283,
	MAIN_DOMAIN_ALREADY_SETTED = 1284,
	CANT_DELETE_MAIN_DOMAIN = 1285,
	TOURNAMENT_ALREADY_STARTED = 1286,
	TOURNAMENT_ALREADY_ACCEPTED = 1287,
	TOURNAMENT_NOT_PUBLISHED = 1288,
	TOURNAMENT_NOT_ACCEPTED_WITH_PARTNER = 1289,
	DUPLICATE_NAME = 1290,
	DECLINE_REASON_IS_REQUIRED = 1291,
	SET_TOURNAMENT_AUTO_GRANTING_DATE = 1292,
	INVALID_TOURNAMENT_AUTO_GRANTING_DATE = 1293,
	INVALID_QUALIFY_DATE = 1294,
	QUALIFY_DATE_CANT_BE_MORE_THAN_1_YEAR = 1295,
	CANT_EDIT_TOURNAMENT_AFTER_PUBLISH = 1296,
	TOURNAMENT_TOTAL_PRIZE_IS_REQUIRED = 1297,
	DEFAULT_PARTNER_CANT_BE_DELETED = 1298,
	NAME_IS_REQUIRED = 1299,
	AMOUNT_IS_REQUIRED = 1300,
	CURRENCY_IS_REQUIRED = 1301,
	MAX_DEPOSIT_AMOUNT_IS_REQUIRED = 1302,
	DEPOSIT_NUMBER_IS_REQUIRED = 1303,
	START_DATE_IS_REQUIRED = 1304,
	END_DATE_IS_REQUIRED = 1305,
	TRONLINK_CREATE_ACCOUNT_FAILED = 1306,
	TRONLINK_WALLET_ID_IS_REQUIRE = 1307,
	PHONE_NUMBER_ALREADY_VERIFIED = 1308,
	WEBSITE_RISK_MANAGEMENT_NAME_REQUIRE = 1309,
	WEBSITE_RISK_MANAGEMENT_RISK_TYPE_REQUIRE = 1310,
	WEBSITE_RISK_MANAGEMENT_INVALID_RISK_TYPE = 1311,
	WEBSITE_RISK_MANAGEMENT_PRIORITY_REQUIRE = 1312,
	WEBSITE_RISK_MANAGEMENT_INVALID_PRIORITY = 1313,
	RISK_MANAGEMENT_CURRENCY_REQUIRED_IN_MAX_DEPOSIT = 1314,
	RISK_MANAGEMENT_INVALID_MAX_DEPOSIT_SETTINGS = 1315,
	CANT_UPDATE_RISK_TYPE = 1316,
	RISK_MANAGEMENT_INVALID_MAX_WIN_SETTINGS = 1317,
	INVALID_VALUE_LENGTH = 1318,
	CANT_BE_EMPTY = 1319,
	ALIAS_REQUIRED = 1320,
	INVALID_ALIAS = 1321,
	DUPLICATE_ALIAS = 1322,
	THERE_MUST_BE_ATTACHED_AT_LEAST_ONE_LANGUAGE = 1323,
	CANNOT_DELETE_DEFAULT_LANGUAGE = 1324,
	NATIONAL_ID_REQUIRED = 1325,
	DELETED_INTEGRATOR = 1326,
	TRANSLATION_TITLE_REQUIRED = 1327,
	DEFAULT_CURRENCY_REQUIRED = 1328,
	INVALID_PRIZE_DATA = 1329,
	INVALID_PRIZE_TRANSLATION_DATA = 1330,
	QUALIFY_CONDITION_REQUIRED = 1331,
	LANGUAGE_REQUIRED = 1332,
	WRONG_TOURNAMENT_TIME_RANGE = 1333,
	SPORT_PROVIDER_NOT_FOUND = 1334,
	INVALID_DOCUMENT = 1335,
	DEFAULT_REQUIRED = 1336,
	RG_STATUS_CANNOT_BE_DISABLED = 1337,
	CANT_CHANGE_TIME_OUT_LIMIT = 1338,
	INVALID_PERIOD = 1339,
	INVALID_DATE_RANGE = 1340,
	LIMITATION_ALREADY_EXISTS = 1341,
	RESPONSIBLE_GAMBLING_TIME_OUT_LIMIT = 1342,
	RESPONSIBLE_GAMBLING_AVAILABLE_TIME_LIMIT = 1343,
	RESPONSIBLE_GAMBLING_SELF_EXCLUSION_LIMIT = 1344,
	RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT_LIMIT = 1345,
	RESPONSIBLE_GAMBLING_ALREADY_CANCELED = 1347,
	RESPONSIBLE_GAMBLING_ALREADY_EXPIRED = 1348,
	EVENT_ID_IS_REQUIRED = 1349,
	TWO_FA_APP_ALREADY_SET = 1350,
	TWO_FA_APP_ALREADY_UNSET = 1351,
	TWO_FA_SMS_ALREADY_SET = 1352,
	TWO_FA_SMS_ALREADY_UNSET = 1353,
	SMS_2FA_BLOCK = 1354,
}
