import { combineReducers } from 'redux';

import List from './list/reducer';
import Filter from './filter/reducer';
import User from './user/reducer';
import Logins from './logins/reducer';
import Activity from './activity/reducer';
import Statistic from './statistic/reducer';
import SportStatistic from './sportStatistic/reducer';
import CasinoStatistic from './casinoStatistic/reducer';
import Groups from './groups/reducer';
import Documents from './documents/reducer';
import UserBalance from './userBalance/reducer';
import BalanceAdjustments from './balanceAdjustments/reducer';
import History from './history/reducer';
import ReportUsers from './reportUsers/reducer';
import Group from './userGroup/reducer';
import UserInfo from './userInfo/reducer';
import RresponsibleGambling from './responsibleGambling/reducer';

export default combineReducers({
	List,
	Filter,
	User,
	Logins,
	Activity,
	Statistic,
	Groups,
	Documents,
	UserBalance,
	BalanceAdjustments,
	History,
	ReportUsers,
	// Roles,
	SportStatistic,
	CasinoStatistic,
	Group,
	UserInfo,
	RresponsibleGambling,
});
