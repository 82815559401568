const actions = {

	CATEGORY_RESTRICTION_DATA_RESET  : 'CATEGORY_RESTRICTION_DATA_RESET',
	CATEGORY_RESTRICTION_DATA_SAVE   : 'CATEGORY_RESTRICTION_DATA_SAVE',
	CATEGORY_RESTRICTION_DATA_RELOAD : 'CATEGORY_RESTRICTION_DATA_RELOAD',
	CATEGORY_RESTRICTION_DATA_REFRESH: 'CATEGORY_RESTRICTION_DATA_REFRESH',

	CATEGORY_RESTRICTION_LEAGUE_IDS_REFRESH              : 'CATEGORY_RESTRICTION_LEAGUE_IDS_REFRESH',
	CATEGORY_RESTRICTION_LEAGUE_ENTITIES_REFRESH         : 'CATEGORY_RESTRICTION_LEAGUE_ENTITIES_REFRESH',
	CATEGORY_RESTRICTION_RESTRICTION_IDS_REFRESH         : 'CATEGORY_RESTRICTION_RESTRICTION_IDS_REFRESH',
	CATEGORY_RESTRICTION_SELECTED_LEAGUE_IDS_REFRESH     : 'CATEGORY_RESTRICTION_SELECTED_LEAGUE_IDS_REFRESH',
	CATEGORY_RESTRICTION_SELECTED_RESTRICTION_IDS_REFRESH: 'CATEGORY_RESTRICTION_SELECTED_RESTRICTION_IDS_REFRESH',

	CATEGORY_RESTRICTION_MODAL_UI_REFRESH: 'CATEGORY_RESTRICTION_MODAL_UI_REFRESH',

	dataReset: () => ({
		type: actions.CATEGORY_RESTRICTION_DATA_RESET,
	}),
	dataSave: () => ({
		type: actions.CATEGORY_RESTRICTION_DATA_SAVE,
	}),

	dataReload: (sportID, categoryID) => ({
		type: actions.CATEGORY_RESTRICTION_DATA_RELOAD,
		data: {
			sportID,
			categoryID,
		},
	}),
	dataRefresh: data => ({
		type: actions.CATEGORY_RESTRICTION_DATA_REFRESH,
		data,
	}),

	leagueIDsRefresh: list => ({
		type: actions.CATEGORY_RESTRICTION_LEAGUE_IDS_REFRESH,
		data: list,
	}),
	leagueEntitiesRefresh: list => ({
		type: actions.CATEGORY_RESTRICTION_LEAGUE_ENTITIES_REFRESH,
		data: list,
	}),
	restrictionIDsRefresh: list => ({
		type: actions.CATEGORY_RESTRICTION_RESTRICTION_IDS_REFRESH,
		data: list,
	}),
	selectedLeagueIDsRefresh: list => ({
		type: actions.CATEGORY_RESTRICTION_SELECTED_LEAGUE_IDS_REFRESH,
		data: list,
	}),
	selectedRestrictionIDsRefresh: list => ({
		type: actions.CATEGORY_RESTRICTION_SELECTED_RESTRICTION_IDS_REFRESH,
		data: list,
	}),

	modalUIRefresh: data => ({
		type: actions.CATEGORY_RESTRICTION_MODAL_UI_REFRESH,
		data,
	}),
};

export default actions;
