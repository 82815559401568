import React from 'react';
import IntlMessages from '../../../../components/utility/intlMessages';

export const lang = {
	restrictBy  : <IntlMessages id="userInfo.LimitsAndRestrictions.restrictBy" />,
	// tab name
	sportLimit  : <IntlMessages id="userInfo.LimitsAndRestrictions.sportLimit" />,
	casinoLimit : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit" />,
	paymentLimit: <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit" />,

	// payment
	deposit        : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.deposit" />,
	withdrawal     : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.withdrawal" />,
	paymentMethod  : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.paymentMethod" />,
	maxDeposit     : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.maxDeposit" />,
	minDeposit     : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.minDeposit" />,
	maxWithdraw    : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.maxWithdrawal" />,
	minWithdraw    : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.minWithdrawal" />,
	withdrawBlocked: <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.withdrawBlocked" />,
	depositBlocked : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.depositBlocked" />,
	restriction    : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.restriction" />,
	description    : <IntlMessages id="userInfo.LimitsAndRestrictions.paymentLimit.description" />,

	// casino
	hint       : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.hint" />,
	placeholder: <IntlMessages id="users.limits.search.input" />,


	// casino custom provider
	customProvider     : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.customProvider" />,
	maxStake           : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.maxStake" />,
	minStake           : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.minStake" />,
	customProviderBlock: <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.customProviderBlock" />,

	// categories

	limitName    : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.category.title" />,
	limitMaxStake: <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.category.max" />,
	limitMinStake: <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.category.min" />,
	limitBlock   : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.category.block" />,
	limitSave    : <IntlMessages id="userInfo.LimitsAndRestrictions.casinoLimit.category.save" />,

	otherProvidersTitle: <IntlMessages id="userInfo.LimitsAndRestrictions.gamesTable.otherProviders.title" />,
	noProviders        : <IntlMessages id="userInfo.LimitsAndRestrictions.gamesTable.noProviders" />,
	noGame             : <IntlMessages id="userInfo.LimitsAndRestrictions.gamesTable.noGame" />,
};

export const LIMITS_TABS = {
	sportLimit  : 'sportLimit',
	casinoLimit : 'casinoLimit',
	paymentLimit: 'paymentLimit',
};

export const LIMITS_PAYMENTS_TABS = {
	deposit   : 'deposit',
	withdrawal: 'withdrawal',
};

export const readOnly = false;


export const sortByNumber = (a, b, sortBy) => a[sortBy] - b[sortBy];

export const sortAlphabetically = (a, b) => a.name.localeCompare(b.name);

const prefix = 'users.userinfo.limits';

const errorMessages = {
	minMaxError                 : `${prefix}.minMaxError`,
	negativeNumberError         : `${prefix}.negativeNumberError`,
	deletedIntegratorMinMaxError: `${prefix}.deletedIntegratorMinMaxError`,
};

export const validateOnSave = (row = {}) => {

	const errors                 = [];
	const { minStake, maxStake, oldMinStake, oldMaxStake, integratorDeleted } = row;

	if (integratorDeleted && ((minStake > 0 && minStake !== oldMinStake) || (maxStake > 0 && maxStake !== oldMaxStake))) {
		errors.push(errorMessages.deletedIntegratorMinMaxError);
	}
	if ((minStake && maxStake) && (minStake > maxStake)) {
		errors.push(errorMessages.minMaxError);
	}
	if (minStake < 0 || maxStake < 0) {
		errors.push(errorMessages.negativeNumberError);
	}
	return errors.length ? errors : null;
};
