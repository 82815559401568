import { apiRequest } from './index';

function getSavedTables(params = {}) {
	const req = {
		method       : 'GET',
		url          : '/personal/table',
		withoutLangID: true,
		params,
	};
	return apiRequest(req);
}

function saveTables(settings) {
	const req = {
		method: 'POST',
		url   : '/personal/table',
		data  : settings,
	};
	return apiRequest(req);
}

export const savedTablesAPI = {
	getSavedTables,
	saveTables,
};
