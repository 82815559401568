const actions = {

	RESULT_STATISTICS_DATA_RELOAD : 'RESULT_STATISTICS_DATA_RELOAD',
	RESULT_STATISTICS_DATA_REFRESH: 'RESULT_STATISTICS_DATA_REFRESH',
	RESULT_STATISTICS_DATA_UPDATE : 'RESULT_STATISTICS_DATA_UPDATE',
	RESULT_STATISTICS_DATA_RESET  : 'RESULT_STATISTICS_DATA_RESET',
	RESULT_STATISTICS_UI_REFRESH  : 'RESULT_STATISTICS_UI_REFRESH',

	RESULT_STATISTICS_COMMON_DATA_REFRESH: 'RESULT_STATISTICS_COMMON_DATA_REFRESH',

	dataReload: (eventID, sportID) => ({
		type: actions.RESULT_STATISTICS_DATA_RELOAD,
		data: {
			eventID,
			sportID,
		},
	}),
	dataRefresh: data => ({
		type: actions.RESULT_STATISTICS_DATA_REFRESH,
		data,
	}),
	dataUpdate: () => ({
		type: actions.RESULT_STATISTICS_DATA_UPDATE,
	}),
	dataReset: () => ({
		type: actions.RESULT_STATISTICS_DATA_RESET,
	}),

	uiRefresh: data => ({
		type: actions.RESULT_STATISTICS_UI_REFRESH,
		data,
	}),

	commonDataRefresh: data => ({
		type: actions.RESULT_STATISTICS_COMMON_DATA_REFRESH,
		data,
	}),
};

export default actions;
