import EntityAdapter from '../../../../helpers/entityAdapter';
import { createEntities } from '../../../utility';

export const fields = {
	id               : 'id',
	name             : 'name',
	logo             : 'logo_url',
	description      : 'description',
	langID           : 'lang_id',
	payments         : 'payments',
	depositAllowed   : 'deposit_allowed',
	withdrawalAllowed: 'withdrawal_allowed',
	depositName      : 'deposit_name',
	withdrawalName   : 'withdrawal_name',
	depositCount     : 'deposit_count',
	currencyID       : 'currency_id',
	paymentID        : 'payment_id',
	websiteID        : 'website_id',

	depositAllowedByPlatform   : 'deposit_allowed_by_platform',
	withdrawalAllowedByPlatform: 'withdrawal_allowed_by_platform',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptPaymentsList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return createEntities(adaptedData);
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'paymentID', fields.paymentID);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'logo', fields.logo);
	adapter.addField(rules.string, 'description', fields.description);

	adapter.addField(rules.bool, 'depositAllowed', fields.depositAllowed);
	adapter.addField(rules.bool, 'depositAllowedByPlatform', fields.depositAllowedByPlatform);
	adapter.addField(rules.bool, 'withdrawalAllowed', fields.withdrawalAllowed);
	adapter.addField(rules.bool, 'withdrawalAllowedByPlatform', fields.withdrawalAllowedByPlatform);
	adapter.addField(rules.number, 'depositCount', fields.depositCount );

	return adapter;
}
