import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';

const { isValidID, isValidString, isValidDateRange, isValidArray } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	userID     : isValidID,  // number;
	userIP     : isValidString,    // string;
	userCountry: isValidArray,     // number[];
	balance    : isValidArray,    // number[]
	language   : isValidArray,     // number[];
	created    : isValidDateRange,    // date[];
};
