const actions = {

	CASINO_GAME_CATEGORY_LIST_RELOAD     : 'CASINO_GAME_CATEGORY_LIST_RELOAD',
	CASINO_GAME_CATEGORY_LIST_REFRESH    : 'CASINO_GAME_CATEGORY_LIST_REFRESH',
	CASINO_GAME_CATEGORY_LIST_DELETE_ITEM: 'CASINO_GAME_CATEGORY_LIST_DELETE_ITEM',

	CASINO_GAME_CATEGORY_LIST_UI_REFRESH: 'CASINO_GAME_CATEGORY_LIST_UI_REFRESH',

	CASINO_GAME_CATEGORY_LIST_RELOAD_GAMES : 'CASINO_GAME_CATEGORY_LIST_RELOAD_GAMES',
	CASINO_GAME_CATEGORY_LIST_REFRESH_GAMES: 'CASINO_GAME_CATEGORY_LIST_REFRESH_GAMES',

	CHECKED_CATEGORY_GAMES       : 'CHECKED_CATEGORY_GAMES',
	CHECKED_ALL_GAMES            : 'CHECKED_ALL_GAMES',
	SHOW_CHECK_BOX_CHECK_ALL_INTO: 'SHOW_CHECK_BOX_CHECK_ALL_INTO',
	OLD_DATA_CHECKED             : 'OLD_DATA_CHECKED',
	OLD_DATA_CHECKED_LIST        : 'OLD_DATA_CHECKED_LIST',

	CASINO_GAME_CATEGORIES_DATA_RESET: 'CASINO_GAME_CATEGORIES_DATA_RESET',


	listReload: () => ({
		type: actions.CASINO_GAME_CATEGORY_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.CASINO_GAME_CATEGORY_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	listReloadGames: (data) => ({
		type: actions.CASINO_GAME_CATEGORY_LIST_RELOAD_GAMES,
		data,
	}),
	listRefreshGames: entities => ({
		type: actions.CASINO_GAME_CATEGORY_LIST_REFRESH_GAMES,
		data: {
			entities,
		},
	}),
	deleteItem: gameCategoryID => ({
		type: actions.CASINO_GAME_CATEGORY_LIST_DELETE_ITEM,
		data: {
			gameCategoryID,
		},
	}),
	checkedCategoryGamesActon: ({ categoryID, games, checkedAll, into, checked }) => {
		return  {
			type: actions.CHECKED_CATEGORY_GAMES,
			data: {
				games,
				checkedAll,
				categoryID,
				into,
				checked,
			},
		};
	},
	checkedAll: data => ({
		type: actions.CHECKED_ALL_GAMES,
		data,
	}),

	showCheckboxAllInto: (data) =>  ({
		type: actions.SHOW_CHECK_BOX_CHECK_ALL_INTO,
		data,
	}),

	oldCheckedData: (data) => ({
		type: actions.OLD_DATA_CHECKED,
		data,
	}),

	oldCheckedDataList: (data, gameID) => ({
		type: actions.OLD_DATA_CHECKED_LIST,
		data,
		gameID,
	}),

	uiRefresh: data => ({
		type: actions.CASINO_GAME_CATEGORY_LIST_UI_REFRESH,
		data,
	}),

	dataReset: () => ({
		type: actions.CASINO_GAME_CATEGORIES_DATA_RESET,
	}),
};

export default actions;
