import { combineReducers } from 'redux';

import Categories from './categories/reducer';
import Events from './events/reducer';
import Markets from './markets/reducer';
import Participants from './participants/reducer';
import Results from './results/reducer';
import Settings from './settings/reducer';
import LiveStatisticTypes from './liveStatisticTypes/reducer';


export default combineReducers({
	Categories,
	Events,
	Markets,
	Participants,
	Results,
	Settings,
	LiveStatisticTypes,
});
