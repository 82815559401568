import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import filterSaga from './filter/saga';
import marketSaga from './market/saga';
import mappingSaga from './mapping/saga';

import groupsSaga from './groups/saga';
import laddersSaga from '../settings/ladders/saga';
import displayTypesSaga from './displayTypes/saga';

import historySaga from './history/saga';

export default function* marketsSaga() {
	yield all([
		fork(listSaga),
		fork(filterSaga),
		fork(marketSaga),
		fork(mappingSaga),
		fork(groupsSaga),
		fork(laddersSaga),
		fork(displayTypesSaga),
		fork(historySaga),
	]);
}
