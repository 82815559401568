const actions = {

	RESULTS_LIST_RELOAD : 'RESULTS_LIST_RELOAD',
	RESULTS_LIST_REFRESH: 'RESULTS_LIST_REFRESH',

	UI_REFRESH: 'RESULTS_UI_REFRESH',
	listReload: () => ({
		type: actions.RESULTS_LIST_RELOAD,
	}),
	listRefresh: (listIDs, entities) => ({
		type: actions.RESULTS_LIST_REFRESH,
		data: {
			listIDs,
			entities,
		},
	}),
	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),
};

export default actions;
