import dayjs from 'dayjs';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import toNumber from 'lodash/toNumber';

import Formatter from './formatter';
import { FORMATS } from './commonConstants';
import { isID } from './utils';

const formatDate     = FORMATS.date;
const formatOnlyTime = FORMATS.time;
const formatTime     = FORMATS.fullDateTime;

export default class ParamsBuilder {
	RULES = {
		noCondition     : 'noCondition',
		isExist         : 'isExist',
		isID            : 'isID',
		isBoolean       : 'isBoolean',
		isNumber        : 'isNumber',
		isNullOrNumber 	: 'isNullOrNumber',
		isDate          : 'isDate',
		isDateTime      : 'isDateTime',
		isDateUTC       : 'isDateUTC',
		isString        : 'isString',
		isDateRange     : 'isDateRange',
		isDateTimeRange : 'isDateTimeRange',
		isArrayID       : 'isArrayID',
		isArrayString   : 'isArrayString',
		isNumberRange   : 'isNumberRange',
		isTimeRange     : 'isTimeRange',
		isPositiveNumber: 'isPositiveNumber',
		isTrue          : 'isTrue',
	};

	fields = [];

	values = [];

	addValue(paramsName, value) {
		this.values.push({
			paramsName,
			value,
		});
	}

	addField(rule, objectName, paramsName = null) {
		this.fields.push({
			rule,
			objectName,
			paramsName: paramsName || objectName,
		});
	}

	addRangeField(rule, valueRange, paramsNames = []) {
		this.fields.push({
			rule,
			valueRange,
			paramsNames,
		});
	}

	biuldParams(initObject) {
		const params = {};

		this.values.forEach(item => {
			params[item.paramsName] = item.value;
		});

		this.fields.forEach(field => {
			const { rule, objectName, paramsName } = field;
			const value = initObject[objectName];

			switch (rule) {
				case this.RULES.isExist: {
					if (value !== undefined && value !== null) {
						params[paramsName] = value;
					}
					break;
				}
				case this.RULES.isID: {
					if (isID(value)) {
						params[paramsName] = toInteger(value);
					}
					break;
				}
				case this.RULES.isBoolean: {
					if (isBoolean(value)) {
						params[paramsName] = Boolean(value);
					}
					break;
				}
				case this.RULES.isNumber: {
					if (!Number.isNaN(toNumber(value))) {
						params[paramsName] = toNumber(value);
					}
					break;
				}
				case this.RULES.isNullOrNumber:
					params[paramsName] = value > 0 ? toNumber(value) : null;
					break;
				case this.RULES.isString: {
					if (value !== undefined && value !== null && value !== '') {
						params[paramsName] = value;
					}
					break;
				}
				case this.RULES.isDate: {
					const valueDate = value ? dayjs(value) : null;
					if (valueDate) {
						params[paramsName] = valueDate.format(formatDate);
					}
					break;
				}
				case this.RULES.isDateTime: {
					const valueDateTime = value ? dayjs(value) : null;
					if (valueDateTime) {
						params[paramsName] = valueDateTime.format(formatTime);
					}
					break;
				}
				case this.RULES.isDateUTC: {
					const valueDate = value ? dayjs(value) : null;
					if (valueDate) {
						params[paramsName] = Formatter.dateUTC(valueDate);
					}
					break;
				}
				case this.RULES.isDateRange: {
					this.checkDateRange(params, field);
					break;
				}
				case this.RULES.isDateTimeRange: {
					this.checkDateTimeRange(params, field);
					break;
				}
				case this.RULES.isTimeRange: {
					this.checkTimeRange(params, field);
					break;
				}
				case this.RULES.isArrayID: {
					const arrayID = this.getArrayID(value);
					if (arrayID.length > 0) {
						params[paramsName] = arrayID;
					}
					break;
				}
				case this.RULES.isArrayString: {
					const arrayString = this.getArrayString(value);
					if (arrayString.length > 0) {
						params[paramsName] = arrayString;
					}
					break;
				}
				case this.RULES.isNumberRange: {
					this.checkNumberRange(params, field);
					break;
				}
				case this.RULES.isPositiveNumber: {
					const numValue = toNumber(value);
					// eslint-disable-next-line no-restricted-globals
					if (!isNaN(numValue) && numValue > 0) {
						params[paramsName] = numValue;
					}
					break;
				}
				case this.RULES.isTrue: {
					if (isBoolean(value) && value) {
						params[paramsName] = Boolean(value);
					}
					break;
				}
				default: {
					params[paramsName] = value;
				}
			}
		});

		return params;
	}

	checkDateRange(params, field) {
		const { valueRange, paramsNames } = field;
		if (!valueRange) {
			return;
		}

		valueRange.forEach((value, index) => {
			const paramsName = paramsNames[index];
			if (!value || !paramsName) {
				return;
			}
			const valueDate = dayjs(value);
			if (valueDate) {
				params[paramsName] = valueDate.format(formatDate);
			}
		});
	}

	checkDateTimeRange(params, field) {
		const { valueRange, paramsNames } = field;
		if (!valueRange) {
			return;
		}
		valueRange.forEach((value, index) => {
			const paramsName = paramsNames[index];
			if (!value || !paramsName) {
				return;
			}
			const valueDate = dayjs(value);
			if (valueDate && paramsName.includes('to')) {
				params[paramsName] = valueDate.toISOString();
			}
			if (valueDate && paramsName.includes('from')) {
				params[paramsName] = valueDate.toISOString();
			}
		});
	}

	checkTimeRange(params, field) {
		const { valueRange, paramsNames } = field;
		if (!valueRange) {
			return;
		}
		valueRange.forEach((value, index) => {
			const paramsName = paramsNames[index];
			if (!value || !paramsName) {
				return;
			}
			const valueDate = dayjs(value, formatOnlyTime);
			if (valueDate) {
				params[paramsName] = valueDate.format(formatOnlyTime);
			}
		});
	}

	checkNumberRange(params, field) {
		const { valueRange, paramsNames } = field;
		if (!valueRange) {
			return;
		}

		valueRange.forEach((value, index) => {
			const paramsName = paramsNames[index];
			if (!value || !paramsName) {
				return;
			}
			const valueNumber = toNumber(value);
			if (valueNumber) {
				params[paramsName] = valueNumber;
			}
		});
	}

	getArrayID(rawArrayID) {
		if (!isArray(rawArrayID)) {
			return [];
		}

		const arrayID = rawArrayID.filter(item => {
			return isID(item);
		});

		return arrayID;
	}

	getArrayString(rawArrayString) {
		if (!isArray(rawArrayString)) {
			return [];
		}

		const arrayString = rawArrayString.filter(item => {
			return Boolean(item);
		});

		return arrayString;
	}
}
