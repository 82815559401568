const prefix = 'USER_LIMITS_RESTRICT_SPORT_';

export const actions = {
	BASE_DATA_REFRESH: `${prefix}REFRESH`,
	DATA_RELOAD      : `${prefix}RELOAD`,
	DATA_SAVE        : `${prefix}SAVE`,
	UI_REFRESH       : `${prefix}UI_REFRESH`,

	dataReload: data => (
		{
			type: actions.DATA_RELOAD,
			data,
		}
	),

	dataSave: data => (
		{
			type: actions.DATA_SAVE,
			data,
		}
	),

	uiRefresh: data => (
		{
			type: actions.UI_REFRESH,
			data,
		}
	),


	baseDataRefresh: data => (
		{
			type: actions.BASE_DATA_REFRESH,
			data,
		}
	),
};
