import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import notifications from '../../../../helpers/notifications';
import { marketsAPI } from '../../../../helpers/api/markets';
import { settingsAPI } from '../../../../helpers/api/settings';
import { isID } from '../../../../helpers/utils';

import actions from './actions';
import { logger } from '../../../../helpers/logger';

const prefix = 'sport.markets.filter';

const messages = {
	errorLoadGroupList      : `${prefix}.errorLoadGroupList`,
	errorLoadScopeList      : `${prefix}.errorLoadScopeList`,
	errorLoadStatisticTypes : `${prefix}.errorLoadStatisticTypes`,
	errorLoadDisplayTypeList: `${prefix}.errorLoadDisplayTypeList`,
};
function* groupListReload() {

	const takeAction   = actions.MARKETS_FILTER_GROUP_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {

		const { sportID } = action.data;
		try {
			const response = yield call(marketsAPI.combinationGroupList, sportID);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.groupListRefresh(list));
			}

		} catch (error) {
			notifications.showError(messages.errorLoadGroupList, error);
			logger.log(error);
		}
	});
}

function* scopeListReload() {

	const takeAction   = actions.MARKETS_FILTER_SCOPE_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {

		const { sportID } = action.data;
		try {
			const response = yield call(settingsAPI.scopeList, sportID);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.scopeListRefresh(list));
			}

		} catch (error) {
			notifications.showError(messages.errorLoadScopeList, error);
			logger.log(error);
		}
	});
}

function* statisticTypeListReload() {

	const takeAction   = actions.MARKETS_FILTER_STATISTIC_TYPE_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {

		const { sportID } = action.data;
		try {
			const response = yield call(settingsAPI.statisticTypeList, sportID);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.statisticTypeListRefresh(list));
			}

		} catch (error) {
			notifications.showError(messages.errorLoadStatisticTypes, error);
			logger.log(error);
		}
	});
}

function* displayTypeListReload() {

	const takeAction   = actions.MARKETS_FILTER_DISPLAY_TYPE_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {

		const { sportID } = action.data;
		const params = {};
		if (isID(sportID)) { params.sport_id = sportID; }

		try {
			const response = yield call(marketsAPI.displayTypeList, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.displayTypeListRefresh(list));
			}

		} catch (error) {
			notifications.showError(messages.errorLoadDisplayTypeList, error);
			logger.log(error);
		}
	});
}

export default function* marketsFilterSaga() {
	yield all([
		fork(groupListReload),
		fork(scopeListReload),
		fork(statisticTypeListReload),
		fork(displayTypeListReload),
	]);
}
