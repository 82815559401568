import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({

	entities: {},
	riskIDs : [],

	newRiskIDs     : [],
	newRiskEntities: {},

	filter: createFilter(filtersURL.risk, initFilterTypes),

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	UI: {
		loading: false,
		visible: false,
	},

});

export default (state = initState, action) => {

	switch (action.type) {

		case actions.RTM_RISK_MANAGEMENT_DATA_REFRESH: {
			const { riskIDs, entities } = action.data;
			return state
				.set('riskIDs', riskIDs)
				.set('entities', entities);
		}

		case actions.RTM_RISK_MANAGEMENT_NEW_RISK_LIST_REFRESH: {
			const { newRiskIDs, newRiskEntities } = action.data;
			return state
				.set('newRiskIDs', newRiskIDs)
				.set('newRiskEntities', newRiskEntities);
		}

		case actions.RTM_RISK_MANAGEMENT_PAGINATION_REFRESH: {
			const { pagination } = action.data;
			const target = state.get('pagination');
			const result = fill(pagination, target);
			return state.set('pagination', result);
		}

		case actions.BASE_DATA_REFRESH: {
			return state.set('baseData', action.data);
		}

		case actions.UI_REFRESH: {
			const UI  = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}

		case actions.RTM_RISK_MANAGEMENT_FILTER_RESET: {
			const target = state.get('pagination');
			const result = fill({ currentPage: 1 }, target);
			return state
				.set('filter', {})
				.set('pagination', result);
		}

		case actions.RTM_RISK_MANAGEMENT_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.DATA_RESET: {
			return initState;
		}

		default:
			return state;

	}
};
