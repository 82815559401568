const actions = {

	PROMOTION_SAVE             : 'PROMOTION_SAVE',
	PROMOTION_DATA_RESET       : 'PROMOTION_DATA_RESET',
	PROMOTION_BASE_DATA_RELOAD : 'PROMOTION_BASE_DATA_RELOAD',
	PROMOTION_BASE_DATA_REFRESH: 'PROMOTION_BASE_DATA_REFRESH',
	PROMOTION_BASE_DATA_RESET  : 'PROMOTION_BASE_DATA_RESET',

	PROMOTION_UI_REFRESH: 'PROMOTION_UI_REFRESH',

	PROMOTIONS_REVISIONS_LIST_RELOAD : 'PROMOTIONS_REVISIONS_LIST_RELOAD',
	PROMOTIONS_REVISIONS_LIST_REFRESH: 'PROMOTIONS_REVISIONS_LIST_REFRESH',
	PROMOTIONS_REVISION_SAVE         : 'PROMOTIONS_REVISION_SAVE',
	PROMOTIONS_REVISION_APPLY        : 'PROMOTIONS_REVISION_APPLY',


	promotionSave: promotionID => ({
		type: actions.PROMOTION_SAVE,
		data: {
			promotionID,
		},
	}),

	dataReset: () => ({
		type: actions.PROMOTION_DATA_RESET,
	}),

	baseDataReload: promotionID => ({
		type: actions.PROMOTION_BASE_DATA_RELOAD,
		data: {
			promotionID,
		},
	}),

	baseDataRefresh: data => ({
		type: actions.PROMOTION_BASE_DATA_REFRESH,
		data,
	}),

	uiRefresh: data => ({
		type: actions.PROMOTION_UI_REFRESH,
		data,
	}),

	revisionsListReload: promotionID => ({
		type: actions.PROMOTIONS_REVISIONS_LIST_RELOAD,
		data: {
			promotionID,
		},
	}),

	revisionsListRefresh: revisionsList => ({
		type: actions.PROMOTIONS_REVISIONS_LIST_REFRESH,
		data: revisionsList,
	}),

	// revisionSave: (promotionID, revisionData) => ({
	// 		type: actions.PROMOTIONS_REVISION_SAVE,
	// 		data: {
	// 			promotionID,
	// 			revisionData,
	// 		},
	// }),

	revisionApply: (promotionID, revisionID) => ({
		type: actions.PROMOTIONS_REVISION_APPLY,
		data: {
			promotionID,
			revisionID,
		},
	}),

	baseDataReset: () => ({
		type: actions.PROMOTION_BASE_DATA_RESET,
	}),
};

export default actions;
