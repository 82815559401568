const prefix = 'RTM_RISK_MANAGEMENT_';

const actions = {

	RELOAD            : `${prefix}RELOAD`,
	BASE_DATA_REFRESH : `${prefix}BASE_DATA_REFRESH`,
	ITEM_STATUS_UPDATE: `${prefix}ITEM_STATUS_UPDATE`,

	UI_REFRESH: `${prefix}UI_REFRESH`,
	DATA_RESET: `${prefix}RESET`,

	RTM_RISK_MANAGEMENT_DATA_REFRESH         : `${prefix}DATA_REFRESH`,
	RTM_RISK_MANAGEMENT_NEW_RISK_LIST_REFRESH: `${prefix}NEW_RISK_LIST_REFRESH`,
	RTM_RISK_MANAGEMENT_PAGINATION_REFRESH   : `${prefix}PAGINATION_REFRESH`,
	RTM_RISK_MANAGEMENT_SAVE_COMMENT     				: `${prefix}SAVE_COMMENT`,

	RTM_RISK_MANAGEMENT_FILTER_RESET  : `${prefix}FILTER_RESET`,
	RTM_RISK_MANAGEMENT_FILTER_REFRESH: `${prefix}FILTER_REFRESH`,
	RTM_RISK_MANAGEMENT_FILTER_APPLY  : `${prefix}FILTER_APPLY`,

	RTM_RISK_MANAGEMENT_SOCKET_NOTIFY_CREATE_RISK : `${prefix}SOCKET_NOTIFY_CREATE_RISK`,
	RTM_RISK_MANAGEMENT_SOCKET_NOTIFY_UPDATE_RISK : `${prefix}SOCKET_NOTIFY_UPDATE_RISK`,
	RTM_RISK_MANAGEMENT_SOCKET_SUBSCRIBE_TO_UPDATE: `${prefix}SOCKET_SUBSCRIBE_TO_UPDATE`,

	listReload: (data) => ({
		type: actions.RELOAD,
		data,
	}),

	dataRefresh: (riskIDs, entities) => ({
		type: actions.RTM_RISK_MANAGEMENT_DATA_REFRESH,
		data: {
			riskIDs,
			entities,
		},
	}),
	saveRisk: (userID, riskID, commented, comment) => ({
		type: actions.RTM_RISK_MANAGEMENT_SAVE_COMMENT,
		data: {
			userID,
			riskID,
			commented,
			comment,
		},
	}),

	newRiskListRefresh: (newRiskIDs, newRiskEntities) => ({
		type: actions.RTM_RISK_MANAGEMENT_NEW_RISK_LIST_REFRESH,
		data: {
			newRiskIDs,
			newRiskEntities,
		},
	}),

	baseDataRefresh: data => ({
		type: actions.BASE_DATA_REFRESH,
		data,
	}),

	filterReset: () => ({
		type: actions.RTM_RISK_MANAGEMENT_FILTER_RESET,
	}),

	filterRefresh: data => ({
		type: actions.RTM_RISK_MANAGEMENT_FILTER_REFRESH,
		data,
	}),

	filterApply: () => ({
		type: actions.RTM_RISK_MANAGEMENT_FILTER_APPLY,
	}),

	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),

	paginationRefresh: data => ({
		type: actions.RTM_RISK_MANAGEMENT_PAGINATION_REFRESH,
		data,
	}),

	statusUpdate: (userId, riskID, statusId) => ({
		type: actions.ITEM_STATUS_UPDATE,
		data: {
			userId,
			riskID,
			statusId,
		},
	}),

	dataReset: () => ({
		type: actions.DATA_RESET,
	}),

	notifyCreateRisk: data => ({
		type: actions.RTM_RISK_MANAGEMENT_SOCKET_NOTIFY_CREATE_RISK,
		data,
	}),

	notifyUpdateRisk: data => ({
		type: actions.RTM_RISK_MANAGEMENT_SOCKET_NOTIFY_UPDATE_RISK,
		data,
	}),

	subscribeToUpdate: riskIDs => ({
		type: actions.RTM_RISK_MANAGEMENT_SOCKET_SUBSCRIBE_TO_UPDATE,
		data: {
			riskIDs,
		},
	}),
};

export default actions;
