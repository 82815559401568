import { apiRequest } from './index';

function newsList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/content/news',
		params,
	};

	return apiRequest(req);
}

function newsItemInfo(itemID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/news/${itemID}`,
		params,
	};

	return apiRequest(req);
}

function newsItemAdd(itemData, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/news',
		data  : itemData,
		params,
	};

	return apiRequest(req);
}

function newsItemUpdate(itemID, itemData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/news/${itemID}`,
		data  : itemData,
		params,
	};

	return apiRequest(req);
}

function newsItemDelete(itemID, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/content/news/${itemID}`,
		params,
	};

	return apiRequest(req);
}

function newsItemDetailsUpdate(itemID, detailsData, editMode, params = {}) {

	const req = {
		method: editMode ? 'PUT' : 'POST',
		url   : `/content/news/${itemID}/detail`,
		data  : detailsData,
		params,
	};

	return apiRequest(req);
}

export const newsAPI = {
	newsList,
	newsItemInfo,
	newsItemAdd,
	newsItemUpdate,
	newsItemDelete,
	newsItemDetailsUpdate,
};
