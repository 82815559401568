
import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import { adaptRisk } from '../rtm/riskManagement/utils';
import { getCurrencyCode, getNotificationTypeName, getRiskTypeName } from './utils';
import { adaptDeposit } from '../rtm/deposits/utils';
import { adaptTransaction } from '../rtm/casino/utils';
import { adaptWithdrawal } from '../rtm/withdrawals/utils';
import formatter from '../../helpers/formatter';
// import { adaptRisk } from './utils';

function getStoreData({ Settings, Notifications }) {

	return {
		currencyEntities: Settings.CurrencyModule.get('entities'),
		notifIDs        : Notifications.get('notifIDs'),
		entities        : Notifications.get('entities'),
		newNotifIDs     : Notifications.get('newNotifIDs'),
		newNotifEntities: Notifications.get('newNotifEntities'),
		riskAlertLow    : Notifications.get('riskAlertLow'),
		riskAlertMedium : Notifications.get('riskAlertMedium'),
		riskAlertHigh   : Notifications.get('riskAlertHigh'),
		deposits        : Notifications.get('deposits'),
		withdrawal      : Notifications.get('withdrawal'),
		casino          : Notifications.get('casino'),
		visible         : Notifications.get('UI'),
	};
}

function* onNotifyCreateNotificationDeposit() {

	yield takeEvery(actions.SOCKET_NOTIFY_CREATE_DEPOSIT, function* (action) {
		const { data } = action.data;
		const { id : notifID, type_id : typeID, seen, created } = data;
		const depositData = JSON.parse(data.notification).data;

		const depositID = toInteger(depositData.id);
		if (!depositID) {
			return;
		}

		const storeData             = yield select(getStoreData);
		const storeDepositData      = cloneDeep(storeData.deposits);
		const notifIDs              = cloneDeep(storeData.notifIDs);
		const entities              = cloneDeep(storeData.entities);
		const newNotifIDs           = cloneDeep(storeData.newNotifIDs);
		const newNotifEntities      = cloneDeep(storeData.newNotifEntities);

		const adaptedDeposit        = adaptDeposit(depositData);
		adaptedDeposit.id           = notifID;
		adaptedDeposit.seen         = seen;
		adaptedDeposit.date         = formatter.dateTime(created);
		adaptedDeposit.currencyCode = getCurrencyCode(adaptedDeposit.currencyID, storeData.currencyEntities);

		newNotifEntities[depositID] = adaptedDeposit;
		newNotifIDs.unshift(depositID);

		entities[depositID] = adaptedDeposit;
		notifIDs.unshift(depositID);
		storeDepositData.unshift(adaptedDeposit);

		yield put(actions.dataRefresh(typeID, storeDepositData, depositID));
		yield put(actions.notifDataRefresh(notifIDs, entities));
		yield put(actions.newNotifListRefresh(newNotifIDs, newNotifEntities));

		let toSubscribeIDs = cloneDeep(notifIDs);
		toSubscribeIDs.push(depositID);
		toSubscribeIDs = uniq(toSubscribeIDs);

		yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}

function* onNotifyCreateNotificationWithdrawal() {

	yield takeEvery(actions.SOCKET_NOTIFY_CREATE_WITHDRAWAL, function* (action) {
		const { data } = action.data;
		const { id : notifID, type_id : typeID, seen, created } = data;
		const withdrawData = JSON.parse(data.notification).data;

		const withdrawID = toInteger(withdrawData.id);
		if (!withdrawID) {
			return;
		}

		const storeData               = yield select(getStoreData);
		const storeWithdrawData       = cloneDeep(storeData.withdrawal);
		const notifIDs                = cloneDeep(storeData.notifIDs);
		const entities                = cloneDeep(storeData.entities);
		const newNotifIDs             = cloneDeep(storeData.newNotifIDs);
		const newNotifEntities        = cloneDeep(storeData.newNotifEntities);

		const adaptedWithdraw         = adaptWithdrawal(withdrawData);
		adaptedWithdraw.id            = notifID;
		adaptedWithdraw.seen          = seen;
		adaptedWithdraw.date          = formatter.dateTime(created);
		adaptedWithdraw.currencyCode  = getCurrencyCode(adaptedWithdraw.wdCurrencyID, storeData.currencyEntities);

		newNotifEntities[withdrawID]  = adaptedWithdraw;
		newNotifIDs.unshift(withdrawID);

		entities[withdrawID] = adaptedWithdraw;
		notifIDs.unshift(withdrawID);
		storeWithdrawData.unshift(adaptedWithdraw);

		yield put(actions.dataRefresh(typeID, storeWithdrawData, withdrawID));
		yield put(actions.notifDataRefresh(notifIDs, entities));
		yield put(actions.newNotifListRefresh(newNotifIDs, newNotifEntities));

		let toSubscribeIDs = cloneDeep(notifIDs);
		toSubscribeIDs.push(withdrawID);
		toSubscribeIDs = uniq(toSubscribeIDs);

		yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}
function* onNotifyCreateNotificationCasino() {

	yield takeEvery(actions.SOCKET_NOTIFY_CREATE_CASINO, function* (action) {
		const { data } = action.data;
		const { id : notifID, type_id : typeID, seen, created } = data;
		const casinoData = JSON.parse(data.notification).data;

		const casinoID = toInteger(casinoData.id);
		if (!casinoID) {
			return;
		}

		const storeData             = yield select(getStoreData);
		const storeCasinoData       = cloneDeep(storeData.casino);
		const notifIDs              = cloneDeep(storeData.notifIDs);
		const entities              = cloneDeep(storeData.entities);
		const newNotifIDs           = cloneDeep(storeData.newNotifIDs);
		const newNotifEntities      = cloneDeep(storeData.newNotifEntities);

		const adaptedCasino         = adaptTransaction(casinoData);
		adaptedCasino.id            = notifID;
		adaptedCasino.seen          = seen;
		adaptedCasino.created       = formatter.dateTime(created);
		adaptedCasino.currencyCode  = getCurrencyCode(adaptedCasino.gameCurrencyID, storeData.currencyEntities);

		newNotifEntities[casinoID]  = adaptedCasino;
		newNotifIDs.unshift(casinoID);

		entities[casinoID] = adaptedCasino;
		notifIDs.unshift(casinoID);
		storeCasinoData.unshift(adaptedCasino);

		yield put(actions.dataRefresh(typeID, storeCasinoData, casinoID));
		yield put(actions.notifDataRefresh(notifIDs, entities));
		yield put(actions.newNotifListRefresh(newNotifIDs, newNotifEntities));

		let toSubscribeIDs = cloneDeep(notifIDs);
		toSubscribeIDs.push(casinoID);
		toSubscribeIDs = uniq(toSubscribeIDs);

		yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}

function* onNotifyCreateNotificationRisk() {

	yield takeEvery(actions.SOCKET_NOTIFY_CREATE_RISK, function* (action) {
		const { data } = action.data;
		const { type, id : notifID, seen } = data;
		const rawData = JSON.parse(data.notification);
		const { data : riskAlertData } = rawData;
		const { data : tempData } = riskAlertData;
		const { id : rawRiskID } = tempData;

		const riskID = toInteger(rawRiskID);
		if (!riskID) {
			return;
		}

		const storeData         = yield select(getStoreData);
		const sectionName       = getRiskTypeName(type);

		const { visible }       = storeData;
		const currentRiskData   = cloneDeep(storeData[sectionName]);
		const notifIDs          = cloneDeep(storeData.notifIDs);
		const entities          = cloneDeep(storeData.entities);
		const newNotifIDs       = cloneDeep(storeData.newNotifIDs);
		const newNotifEntities  = cloneDeep(storeData.newNotifEntities);

		const adaptedRisk       = adaptRisk(tempData);
		adaptedRisk.id          = notifID;
		adaptedRisk.seen        = seen;

		newNotifEntities[riskID] = adaptedRisk;
		newNotifIDs.unshift(riskID);

		if (visible) {
			if (!adaptedRisk) {
				return false;
			}
			entities[riskID] = adaptedRisk;
			notifIDs.unshift(riskID);
		}
		currentRiskData.unshift(adaptedRisk);

		yield put(actions.socetDataRefresh(type, currentRiskData));
		yield put(actions.notifDataRefresh(notifIDs, entities));
		yield put(actions.newNotifListRefresh(newNotifIDs, newNotifEntities));

		let toSubscribeIDs = cloneDeep(notifIDs);
		toSubscribeIDs.push(riskID);
		toSubscribeIDs = uniq(toSubscribeIDs);

		yield put(actions.subscribeToUpdate(toSubscribeIDs));
	});
}

function* onNotifyUpdateNotificationWithdrawal() {

	yield takeEvery(actions.SOCKET_NOTIFY_UPDATE_WITHDRAWAL, function* (action) {

		const rawWithdrawal     = action.data;
		const withdrawalID      = toInteger(rawWithdrawal.data.id);
		const typeID            = toInteger(rawWithdrawal.data.type_id);

		if (!withdrawalID) {
			return;
		}

		const storeData         = yield select(getStoreData);
		const withdrawals       = cloneDeep(storeData.withdrawal);
		const withdrawalEntity  = cloneDeep(withdrawals.find(doc => doc.id === withdrawalID));

		if (!withdrawalEntity) {
			return;
		}

		withdrawalEntity.seen = rawWithdrawal.data.seen;
		withdrawals.forEach((doc, i, array) => {
			if (doc.id === withdrawalID) {
				array.splice(i, 1, withdrawalEntity);
			}
		});
		yield put(actions.dataRefresh(typeID, withdrawals, withdrawalID));
	});
}
function* onNotifyUpdateNotificationRisk() {

	yield takeEvery(actions.SOCKET_NOTIFY_UPDATE_RISK, function* (action) {

		const rawRisk     = action.data;
		const riskID      = toInteger(rawRisk.data.id);
		const notifTypeID = toInteger(rawRisk.data.type_id);
		const eventType   = toInteger(rawRisk.data.type);

		if (!riskID) {
			return;
		}
		const storeData     = yield select(getStoreData);
		const sectionName   = getNotificationTypeName(notifTypeID);
		const riskList      = cloneDeep(storeData[sectionName]);
		const riskEntity    = riskList.find(risk => risk.id === riskID);

		if (!riskEntity) {
			return;
		}
		riskEntity.seen             = rawRisk.data.seen;
		riskList.forEach((risk, i, array) => {
			if (risk.id === riskID) {
				array.splice(i, 1, riskEntity);
			}
		});
		yield put(actions.socetDataRefresh(eventType, riskList));
	});
}

export default function* notificationRiskExternalSaga() {
	yield all([
		fork(onNotifyCreateNotificationRisk),
		fork(onNotifyUpdateNotificationRisk),

		fork(onNotifyCreateNotificationDeposit),
		fork(onNotifyCreateNotificationCasino),

		fork(onNotifyCreateNotificationWithdrawal),
		fork(onNotifyUpdateNotificationWithdrawal),
	]);
}
