import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../../tables/actions';

import { infoPagesAPI } from '../../../../../helpers/api/infoPages';
import notifications from '../../../../../helpers/notifications';
import { getHeadersTotalCount } from '../../../../../helpers/utils';
import { deriveTablePagination } from '../../../../../selectors/tables';

import { getListParams, adaptInfoPagesList } from './utils';
import { TABLE_TYPES } from '../../../../../constants/tableTypes';
import { logger } from '../../../../../helpers/logger';

const prefix = 'infopages.list';

const messages = {
	errorListLoad    : `${prefix}.errorListLoad`,
	errorPageDelete  : `${prefix}.errorPageDelete`,
	successPageDelete: `${prefix}.successPageDelete`,
};


const tableType     = TABLE_TYPES.infoPages;

function getStoreData(state) {
	const { Tables, App } = state;

	return {
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
		websiteID : App.get('websiteID'),
	};
}

function* listReload() {

	yield takeEvery(actions.INFO_PAGES_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { pagination, sorting, websiteID } = yield select(getStoreData);
		const params = getListParams(pagination, sorting, websiteID);

		let listIDs    = [];
		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(infoPagesAPI.infoPagesList, params);
			if (res && res.status === 200) {
				const result = adaptInfoPagesList(res.data.data);
				listIDs      = result.listIDs;  // eslint-disable-line prefer-destructuring
				entities     = result.entities; // eslint-disable-line prefer-destructuring
				totalCount   = getHeadersTotalCount(res.headers) || listIDs.length;
			}

		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}

		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.listRefresh(listIDs, entities));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* deletePage() {

	yield takeEvery(actions.INFO_PAGES_LIST_DELETE_PAGE, function* (action) {

		const { pageID } = action.data;
		try {
			const res = yield call(infoPagesAPI.infoPageDelete, pageID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successPageDelete);
			}
		} catch (error) {
			notifications.showError(messages.errorPageDelete, error);
			logger.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* infoPagesListSaga() {
	yield all([
		fork(listReload),
		fork(deletePage),
	]);
}
