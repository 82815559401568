import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormControlWrapper, FormControlWrapperColumn, Label, ControlInput } from './formControl.style';

class FormControl extends Component {

	static propTypes = {
		control: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.string,
			PropTypes.number,
		]).isRequired,
		label                  : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		height                 : PropTypes.string,
		labelWidth             : PropTypes.number,
		marginBottom           : PropTypes.number,
		labelRight             : PropTypes.bool,
		controlRight           : PropTypes.bool,
		controlBlock           : PropTypes.bool,
		direction              : PropTypes.string,
		widthInput             : PropTypes.number,
		styleFormControl       : PropTypes.object,
		styleFormControlWrapper: PropTypes.object,
		styleLabelControl      : PropTypes.object,
	};

	static defaultProps = {
		label                  : '',
		height                 : '36px',
		labelWidth             : 25, // in percents
		marginBottom           : 8,  // in pixels
		labelRight             : false,
		controlRight           : false,
		controlBlock           : false,
		direction              : null,
		widthInput             : 0,
		styleFormControl       : {},
		styleFormControlWrapper: {},
		styleLabelControl      : {},
	};

	render() {
		const {
			label,
			control,
			labelWidth,
			marginBottom,
			labelRight,
			controlRight,
			height,
			direction,
			widthInput,
			styleFormControl,
			styleLabelControl,
			styleFormControlWrapper,
			controlBlock,
		} = this.props;

		const labelClassName = classnames({
			'label-right': labelRight,
			'label'      : !labelRight,
			'no-label'   : (!labelRight && labelWidth === 0),
		});
		const controlClassName = classnames({
			'control-right': controlRight,
			'control'      : !controlRight,
			'no-label'     : (!labelRight && labelWidth === 0),
			'control_block': controlBlock,
		});
		const labelPostFix = labelRight ? ':' : '';

		if ( direction !== 'column' ) {
			return (
				<FormControlWrapper  widthInput={widthInput} style={styleFormControlWrapper} labelWidth={labelWidth} marginBottom={marginBottom} minHeight={height} className="form-control">
					<div className={labelClassName}>
						{label}
						{labelPostFix}
					</div>
					<div className={controlClassName}>{control}</div>
				</FormControlWrapper>
			);
		}

		return (
			<FormControlWrapperColumn style={styleFormControl} labelWidth={labelWidth} marginBottom={marginBottom} minHeight={height} className="form-control">
				<Label style={styleLabelControl}>
					{label}
					{labelPostFix}
				</Label>
				<ControlInput style={styleLabelControl}>{control}</ControlInput>
			</FormControlWrapperColumn>
		);

	}
}

export default FormControl;
