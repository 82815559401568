import { apiRequest } from './index';

// Banner -----------------------------------------------------------------------------------------

function bannersList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/content/banner',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function bannerInfo(bannerID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/content/banner/${bannerID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function bannerAdd(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/banner',
		data,
		params,
	};

	return apiRequest(req);
}

function bannerUpdate(bannerID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/banner/${bannerID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function bannerCopy(bannerID, params) {

	const req = {
		method: 'POST',
		url   : `/content/banner/${bannerID}/copy`,
		data  : {},
		params,
	};

	return apiRequest(req);
}

function bannerDelete(bannerID) {

	const req = {
		method: 'DELETE',
		url   : `/content/banner/${bannerID}`,
	};

	return apiRequest(req);
}

// Banner description -----------------------------------------------------------------------------

function bannerDescInfo(bannerID, websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : '/content/banner_description',
		withoutLangID: true,
		params       : {
			...params,
			banner_id : bannerID,
			website_id: websiteID,
		},
	};

	return apiRequest(req);
}

function bannerDescUpdate(bannerID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/banner/${bannerID}/description`,
		data,
		params,
	};

	return apiRequest(req);
}

// Banner Group -----------------------------------------------------------------------------------

function bannerGroupsList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/content/banner_group',
		params,
	};

	return apiRequest(req);
}

function bannerGroupInfo(bannerGroupID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/banner_group/${bannerGroupID}`,
		params,
	};

	return apiRequest(req);
}

function bannerGroupAdd(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/banner_group',
		data,
		params,
	};

	return apiRequest(req);
}

function bannerGroupUpdate(bannerGroupID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/banner_group/${bannerGroupID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function bannerGroupDelete(bannerGroupID) {

	const req = {
		method: 'DELETE',
		url   : `/content/banner_group/${bannerGroupID}`,
	};

	return apiRequest(req);
}

export const bannersAPI = {
	bannersList,
	bannerInfo,
	bannerAdd,
	bannerCopy,
	bannerUpdate,
	bannerDelete,

	bannerDescInfo,
	bannerDescUpdate,

	bannerGroupsList,
	bannerGroupInfo,
	bannerGroupAdd,
	bannerGroupUpdate,
	bannerGroupDelete,
};
