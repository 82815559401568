const actions = {

	PAYWAY_DATA_RESET       : 'PAYWAY_DATA_RESET',
	PAYWAY_BASE_DATA_REFRESH: 'PAYWAY_BASE_DATA_REFRESH',

	PAYWAY_NAMES_DATA_RELOAD : 'PAYWAY_NAMES_DATA_RELOAD',
	PAYWAY_NAMES_DATA_REFRESH: 'PAYWAY_NAMES_DATA_REFRESH',

	PAYWAY_SAVE      : 'PAYWAY_SAVE',
	PAYWAY_UI_REFRESH: 'PAYWAY_UI_REFRESH',

	dataReset: () => ({
		type: actions.PAYWAY_DATA_RESET,
	}),
	baseDataRefresh: data => ({
		type: actions.PAYWAY_BASE_DATA_REFRESH,
		data,
	}),

	namesDataReload: (paymentID, paywayID) => ({
		type: actions.PAYWAY_NAMES_DATA_RELOAD,
		data: {
			paymentID,
			paywayID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.PAYWAY_NAMES_DATA_REFRESH,
		data,
	}),

	paywaySave: () => ({
		type: actions.PAYWAY_SAVE,
	}),
	uiRefresh: data => ({
		type: actions.PAYWAY_UI_REFRESH,
		data,
	}),
};

export default actions;
