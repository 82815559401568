import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../helpers/utils';
import { yearAgo, yearNow } from '../statistic/utils';

const initState = new Map({
	sportStatistic: {},

	UI: {
		dateFrom: yearAgo,
		dateTo  : yearNow,
		loading : false,
	},
});

export default function statisticReducer(state = initState, action) {
	switch (action.type) {
		case actions.USER_SPORT_STATISTIC_REFRESH: {
			return state.set('sportStatistic', action.data);
		}
		case actions.USER_SPORT_STATISTIC_SET_VALUE_UI: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
