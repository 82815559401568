import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';

import { casinoAPI } from '../../../helpers/api/casino';
import notifications from '../../../helpers/notifications';

import {
	adaptCasino,
	prepareCasino,
	adaptNamesData,
	updateCasinoList,
} from './utils';
import { adaptSeoData, prepareSeoData } from '../game/utils';
import { restoreWebsite } from '../../../helpers/utility';
import { logger } from '../../../helpers/logger';

const messages = {
	errorCasinoReload: 'casino.casino.error.reload',
	errorCasinoSave  : 'casino.casino.error.save',
	errorImageUpload : 'casino.casino.error.image.upload',
	errorAuthReload  : 'casino.casino.error.auth.reload',
	errorAuthUpdate  : 'casino.casino.error.auth.update',
	errorNamesReload : 'casino.casino.error.names.reload',
	errorNamesUpdate : 'casino.casino.error.names.update',

	errorSeoReload: 'casino.casino.error.seo.reload',
	errorSeoSave		: 'casino.casino.error.seo.save',

	successImageUpload: 'casino.casino.success.image.upload',
	successCasinoSave : 'casino.casino.success.save',
	successAuthUpdate : 'casino.casino.success.auth.update',
};

function getStoreData({ Casino, App }) {

	return {
		casinoList: Casino.List.get('list'),
		seoData   : Casino.Casino.get('seoData'),
		baseData  : Casino.Casino.get('baseData'),
		authData  : Casino.Casino.get('authData'),
		namesData : Casino.Casino.get('namesData'),
		UI        : Casino.Casino.get('UI'),
		websiteID : App.get('websiteID'),
	};
}

function* casinoSave() {

	yield takeEvery(actions.CASINO_SAVE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, UI, websiteID, casinoList } = yield select(getStoreData);
		const { editMode } = UI;
		let   { casinoID } = UI;

		const casinoBaseData = cloneDeep(baseData);
		casinoBaseData.websiteID = websiteID;
		let isError           = false;
		let modalVisible		= !action.closeModal;
		let errorMessage      = '';

		try {

			// base data
			errorMessage = messages.errorCasinoSave;
			const preparedData = prepareCasino(casinoBaseData);

			if (editMode) {
				const updatedList = updateCasinoList(casinoList, null, casinoID,true);
				yield put(listActions.listRefresh(updatedList));

				const res = yield call(casinoAPI.platformCasinoUpdate, casinoID, preparedData);
				if (res && res.status === 200) {
					const updatedList = updateCasinoList(casinoList, baseData, casinoID,false);
					yield put(listActions.listRefresh(updatedList));

					const adapted = adaptCasino(res.data.data);
					yield put(actions.baseDataRefresh(adapted));
				}

			} else {
				const res = yield call(casinoAPI.casinoCreate, preparedData);
				casinoID = toInteger(res.data.data.id);

				yield put(actions.uiRefresh({
					casinoID,
					editMode: true,
				}));
			}

			yield put(actions.uiRefresh({ isBaseChanged: false }));
			notifications.showSuccess(messages.successCasinoSave);

		} catch (error) {
			const updatedList = updateCasinoList(casinoList, null, casinoID,false);
			yield put(listActions.listRefresh(updatedList));

			isError = true;
			modalVisible = true;
			notifications.showError(errorMessage);
		}

		if (!isError && !action.closeModal) {
			yield put(actions.baseDataReload(casinoID, true));
		}

		yield put(actions.uiRefresh({
			loading: false,
			visible: modalVisible,
		}));
	});
}

function* namesDataReload() {

	yield takeEvery(actions.CASINO_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { baseData, UI, websiteID } = yield select(getStoreData);
		const { langID } = UI;

		const { casinoID } = action.data;
		let namesData = adaptNamesData([], baseData.name);
		const params = {
			lang_id   : langID,
			website_id: websiteID,
		};
		try {
			const res = yield call(casinoAPI.casinoNamesList, casinoID, params);
			if (res && res.status === 200) {
				namesData = adaptNamesData(res.data.data, baseData.name);
			}

		} catch (error) {
			notifications.showError(messages.errorNamesReload);
			logger.log(error);
		}

		yield put(actions.namesDataRefresh(namesData));
		yield put(actions.uiRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

function* seoDataReload() {

	yield takeEvery(actions.CASINO_SEO_DATA_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { UI : { casinoID } } = yield select(getStoreData);

		try {
			const res = yield call(casinoAPI.casinoSeoGet, casinoID);
			if (res && res.status === 200) {
				const adapted = adaptSeoData(res.data.data);
				yield put(actions.seoDataRefresh(adapted));
			}

		} catch (error) {
			notifications.showError(messages.errorSeoReload);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* seoDataSave() {

	yield takeEvery(actions.CASINO_SEO_DATA_SAVE, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { seoData,  UI : { casinoID } } = yield select(getStoreData);
		const params = {
			website_id: restoreWebsite(),
		};
		try {
			const preparedData = prepareSeoData(seoData);
			const res = yield call(casinoAPI.casinoSeoSave, preparedData, casinoID, params);
			if (res && res.status === 200) {
				const adapted = adaptSeoData(res.data.data);
				yield put(actions.seoDataRefresh(adapted));
			}
		} catch (e) {
			notifications.showError(messages.errorSeoSave);
		}


		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* casinoModalSaga() {
	yield all([
		fork(casinoSave),
		fork(namesDataReload),

		fork(seoDataReload),
		fork(seoDataSave),
	]);
}
