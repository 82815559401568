const prefix = 'PARTNER_';

export const actions = {
	PARTNER_UI_REFRESH: `${prefix}UI_REFRESH`,

	FILTER_UI_REFRESH: `${prefix}UI_RELOAD`,
	FILTER_UI_RESET  : `${prefix}UI_RESET`,

	PARTNER_DATA_RELOAD: `${prefix}DATA_RELOAD`,
	FILTER_APPLY       : `${prefix}FILTER_APPLY`,


	uiRefresh: (data) => {
		return {
			type: actions.PARTNER_UI_REFRESH,
			data,
		};
	},

	partnerListReload: (data) => (
		{
			type: actions.PARTNER_DATA_RELOAD,
			data,
		}
	),

	filterRefresh: data => (
		{
			type: actions.FILTER_UI_REFRESH,
			data,
		}
	),

	filterReset: () => (
		{
			type: actions.FILTER_UI_RESET,
		}
	),

	filterApply: (data) => (
		{
			type: actions.FILTER_APPLY,
			data,
		}
	),


};
