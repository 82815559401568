const actions = {

	USERS_LIST_RELOAD         : 'USERS_LIST_RELOAD',
	USERS_LIST_REFRESH        : 'USERS_LIST_REFRESH',
	USERS_LIST_SET_VALUE_UI   : 'USERS_LIST_SET_VALUE_UI',
	USERS_LIST_SORTING_REFRESH: 'USERS_LIST_SORTING_REFRESH',
	USERS_LIST_UI_REFRESH   		: 'USERS_LIST_UI_REFRESH',

	listReload: data => ({
		type: actions.USERS_LIST_RELOAD,
		data,
	}),
	listRefresh: list => ({
		type: actions.USERS_LIST_REFRESH,
		data: list,
	}),
	setValueUI: (valueName, value) => ({
		type: actions.USERS_LIST_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	sortingRefresh: data => ({
		type: actions.USERS_LIST_SORTING_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.USERS_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
