import EntityAdapter from '../../../../../helpers/entityAdapter';
import { Map } from 'immutable';

export const partnerInit = new Map({
	baseData: {
		partnerName    : '',
		name           : '',
		email          : '',
		phoneUnmasked  : '',
		phoneMasked    : '',
		status         : 1,
		suspendPreMatch: false,
		suspendLive    : false,
		maxPasswordAge : 0,
		currencyIDs    : [],
		providerIDs    : [],
		smsGateWayIDs  : [],
		paymentIDs     : [],
		gameIDs        : [],
		languageIDs    : [],
		languages      : [],
		websiteID      : 1,
	},

	providersWithGames: {},

	integratorsWithProviders: [],

	UI: {
		loading      : false,
		visible      : false,
		editMode     : false,
		langID       : null,
		isBaseChanged: false,
		checkAll     : false,
	},
});
const fields = {
	gameID    : 'id',
	providerID: 'provider_id',
	name      : 'name',
};


const createListAdapter = () => {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'gameID', fields.gameID);
	adapter.addField(rules.id, 'providerID', fields.providerID);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.bool, 'isChecked', 'isChecked');

	return adapter;
};

const listAdapter = createListAdapter();


export const adaptProvidersWithGames = (data = [], checked, gameIDs) => {
	const adapted = listAdapter.adaptList(data);
	return adapted.map(game => {
		game.isChecked = gameIDs.includes(game.gameID);
		return game;
	});
};
