import React from 'react';
import { createRoot } from 'react-dom/client';
import { unregister } from './registerServiceWorker';
import DashApp from './dashApp';
import store from './redux/store';
import { Provider } from 'react-redux';

//import registerServiceWorker from './registerServiceWorker';
// import DashApp from './dashApp';
// When the application will be stable (not in active developing), we will need to return to using service workers for faster loading
unregister();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<DashApp />
	</Provider>
);

// Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./dashApp.js', () => {
		const NextApp = DashApp;
		root.render(<NextApp />);
	});
}

//registerServiceWorker();
