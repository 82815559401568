import { takeEvery, put, all, fork, select } from 'redux-saga/effects';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import { adaptTransaction, compareTransactionWithFilter } from './utils';

function getStoreData({ AppTabs, RTM }) {

	return {
		isRTMCasino           : Boolean( find(AppTabs.get('tabs'), { id: 'rtm/casino' }) ),
		filter                : RTM.Casino.get('filter'),
		pagination            : RTM.Casino.get('pagination'),
		transactionIDs        : RTM.Casino.get('transactionIDs'),
		entities              : RTM.Casino.get('entities'),
		newTransactionIDs     : RTM.Casino.get('newTransactionIDs'),
		newTransactionEntities: RTM.Casino.get('newTransactionEntities'),
		casinoTotals          : RTM.Casino.get('casinoTotals'),
	};
}

function* onNotifyCreateTransaction() {

	yield takeEvery(actions.RTM_CASINO_SOCKET_NOTIFY_CREATE_TRANSACTION, function* (action) {

		const rawTransaction = action.data;
		const transactionID  = toInteger(rawTransaction.id);
		if (!transactionID) {
			return;
		}

		const storeData = yield select(getStoreData);
		const { isRTMCasino, filter, pagination } = storeData;

		const transactionIDs         = cloneDeep(storeData.transactionIDs);
		const entities               = cloneDeep(storeData.entities);
		const newTransactionIDs      = cloneDeep(storeData.newTransactionIDs);
		const newTransactionEntities = cloneDeep(storeData.newTransactionEntities);
		const casinoTotals           = cloneDeep(storeData.casinoTotals);

		const adaptedTransaction     = adaptTransaction(rawTransaction);

		newTransactionEntities[transactionID] = adaptedTransaction;
		newTransactionIDs.unshift(transactionID);

		if (isRTMCasino && pagination.currentPage === 1) {
			const hasMatches = compareTransactionWithFilter(adaptedTransaction, filter);
			if (hasMatches) {
				entities[transactionID] = adaptedTransaction;
				transactionIDs.unshift(transactionID);
			}
		}

		yield put(actions.dataRefresh(transactionIDs, entities, casinoTotals));
		yield put(actions.newTransactionListRefresh(newTransactionIDs, newTransactionEntities));
		yield put(actions.subscribeToUpdate(transactionIDs));
	});
}

function* onNotifyUpdateTransaction() {

	yield takeEvery(actions.RTM_CASINO_SOCKET_NOTIFY_UPDATE_TRANSACTION, function* (action) {

		const rawTransaction = action.data;
		const transactionID  = toInteger(rawTransaction.id);
		if (!transactionID) {
			return;
		}

		const storeData      = yield select(getStoreData);

		const transactionIDs = cloneDeep(storeData.transactionIDs);
		const entities       = cloneDeep(storeData.entities);
		const casinoTotals   = cloneDeep(storeData.casinoTotals);

		const transactionEntity = entities[transactionID];
		if (!transactionEntity) {
			return;
		}

		const adaptedTransaction = adaptTransaction(rawTransaction);
		entities[transactionID] = adaptedTransaction;

		yield put(actions.dataRefresh(transactionIDs, entities, casinoTotals));
	});
}

export default function* rtmCasinoExternalSaga() {
	yield all([
		fork(onNotifyCreateTransaction),
		fork(onNotifyUpdateTransaction),
	]);
}
