import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../../tables/actions';

import { promotionsAPI } from '../../../../../helpers/api/promotions';
import notifications from '../../../../../helpers/notifications';
import { getHeadersTotalCount } from '../../../../../helpers/utils';
import { deriveTablePagination } from '../../../../../selectors/tables';

import { getListParams, adaptPromotionsList } from './utils';
import { logger } from '../../../../../helpers/logger';

const prefix = 'promotion.list';

const messages = {
	errorListLoad         : `${prefix}.errorListLoad`,
	errorpromotionDelete  : `${prefix}.errorpromotionDelete`,
	successpromotionDelete: `${prefix}.successpromotionDelete`,
};

const tableType     = 'PROMOTIONS';

function getStoreData(state) {
	const { Tables, App, CMS } = state;

	return {
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
		websiteID : App.get('websiteID'),
		filter    : CMS.Pages.Promotions.List.get('filter'),
	};
}

function* listReload() {

	yield takeEvery(actions.PROMOTIONS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { pagination, sorting, websiteID, filter } = yield select(getStoreData);
		const params = getListParams(pagination, sorting, filter, websiteID);

		let listIDs    = [];
		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(promotionsAPI.promotionsList, params);
			if (res && res.status === 200) {
				const result = adaptPromotionsList(res.data.data);
				listIDs      = result.listIDs;  // eslint-disable-line prefer-destructuring
				entities     = result.entities; // eslint-disable-line prefer-destructuring
				totalCount   = getHeadersTotalCount(res.headers) || listIDs.length;
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.listRefresh(listIDs, entities));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* deletePromotion() {

	yield takeEvery(actions.PROMOTIONS_LIST_DELETE_PROMOTION, function* (action) {

		const { promotionID } = action.data;
		try {
			const res = yield call(promotionsAPI.promotionDelete, promotionID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successpromotionDelete);

			}
		} catch (error) {
			notifications.showError(messages.errorpromotionDelete, error);
			logger.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* promotionsListSaga() {
	yield all([
		fork(listReload),
		fork(deletePromotion),
	]);
}
