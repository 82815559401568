import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { websitesAPI } from '../../../../helpers/api/websites';
import notifications, { showError, showSuccess } from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';
import { IApiResponse, ISagaActionType } from '../../../types';
import { websiteProviderReducers } from './slice';
import {
	adaptMainData,
	adaptNamesData, 
	adaptProviders, 
	mergeResToBase,
	prepareNames,
	prepareProviders,
	prepareProvidersMain,
	prepareSeoData,
} from './utils';
import {
	IGetStoreReturn, IProviderListEntities, IProviderMainResponse, IProviderNamesResponse, IProviders,
	IProvidersListResponse,
	TProviderEntity,
} from './types';
import { isZeroOrID } from '../../../../helpers/utils';
import { RootState } from '../../../store';
import { websiteProviderActions } from './actions';
import { TStringOrNumber } from '../../../../containers/Partner/Websites/Inner/tabs/TabProvider/types';
import { casinoAPI } from '../../../../helpers/api/casino';
import cloneDeep from 'lodash/cloneDeep';
import { adaptSeoData } from '../../../casino/game/utils';
import { toInteger } from 'lodash';

const prefix = 'partner.websites.website';

const messages = {
	errorCasinoReload  : `${prefix}.errorCasinoReload`,
	successCasinoSave  : `${prefix}.successCasinoSave`,
	errorCasinoSave    : `${prefix}.errorCasinoSave`,
	errorCasinoDelete  : `${prefix}.errorCasinoDelete`,
	successCasinoDelete: `${prefix}.successCasinoDelete`,
	successListRefresh : `${prefix}.successListRefresh`,
	errorListRefresh   : `${prefix}.errorListRefresh`,
};


function getStoreData(state: RootState): IGetStoreReturn {
	const { Partner: { Websites },  App } = state;
	return {
		providers        : Websites.Providers.entities,
		UI               : Websites.Website.get('UI'),
		websiteID        : Websites.Website.get('baseData').id,
		namesEntities    : Websites.Providers.namesData,
		mainEntities     : Websites.Providers.mainData,
		seoEntities      : Websites.Providers.seoData,
		casinoList       : Websites.Website.get('casino'),
		languageList     : App.get('websiteAttachedLanguages'),
		websiteProviderUI: Websites.Providers.UI,
	};
}

function* providersReload() {

	yield takeEvery(websiteProviderActions.WEBSITE_PROVIDER_DATA_RELOAD, function* (action: ISagaActionType<{ websiteID: number }>) {
		yield put(websiteProviderReducers.uiRefresh({ listLoading: true }));
		const { casinoList }: IGetStoreReturn = yield select(getStoreData);
		const { websiteID } = action.data;
		const params = {
			unlimited: true,
		};

		try {
			const res: IApiResponse<IProvidersListResponse[]> = yield call(casinoAPI.casinoList, params, websiteID );
			if (res && res.status === 200) {
				const { list, entities } = adaptProviders(res.data.data, casinoList);
				yield put(websiteProviderReducers.providersEntitiesRefresh(entities));
				yield put(websiteProviderReducers.providersListRefresh(list));
			}
		} catch (error) {
			showError(messages.errorCasinoReload, error);
			logger.log(error);
		}
		yield put(websiteProviderReducers.uiRefresh({ loading: false, listLoading: false }));
	});
}

function* providerSave() {
	yield takeEvery(websiteProviderActions.WEBSITE_PROVIDER_SAVE, function* (action: ISagaActionType<{  providerID: number; recordID: TStringOrNumber }>) {
		yield put(websiteProviderReducers.uiRefresh({ loading: true }));
		const { providers, namesEntities, seoEntities, websiteID, mainEntities, websiteProviderUI }: IGetStoreReturn = yield select(getStoreData);
		const clonedProviders: IProviderListEntities = cloneDeep(providers);
		const clonedMainEntities = cloneDeep(mainEntities);
		const { recordID, providerID  } = action.data;
		const casinoItem: TProviderEntity = clonedProviders[recordID];
		const editMode: boolean = isZeroOrID(recordID);

		const params = {
			website_id: websiteID,
		};
		try {
			const preparedData	= prepareProviders(casinoItem);
			const preparedNames	= prepareNames(namesEntities[recordID]);
			const preparedSeo	= prepareSeoData(seoEntities[recordID]);

			const namesChanged = websiteProviderUI.namesBaseChanged;
			const seoChanged = websiteProviderUI.seoBaseChanged;
			const baseChanged = websiteProviderUI.baseChanged;

			
			if (editMode) {
				const preparedMain	= prepareProvidersMain(mainEntities[recordID] || {}, websiteID);
				yield call(websitesAPI.casinoUpdate, websiteID, providerID, preparedData);

				if (namesChanged.includes(Number(recordID))) {
					yield call(casinoAPI.casinoNamesUpdate, providerID, preparedNames, params);
				}
				if (seoChanged.includes(Number(recordID))) {
					yield call(casinoAPI.casinoSeoSave, preparedSeo, providerID, params);
				}

				if (baseChanged.includes(Number(recordID))) {
					const mainRes = yield call(casinoAPI.casinoUpdate, providerID, preparedMain, params);
					const mergedMain: IProviderMainResponse = mergeResToBase(mainRes.data.data, mainEntities[recordID]);
					clonedMainEntities[recordID] = mergedMain;
					yield put(websiteProviderReducers.mainDataRefresh(clonedMainEntities));

				}
			} else {
				const res: IApiResponse<IProviders> = yield call(websitesAPI.casinoAdd, websiteID, preparedData);

				const newEntityID			= toInteger(res.data.data.id);
				casinoItem.id = newEntityID;
				clonedProviders[newEntityID] = casinoItem;
				delete clonedProviders[recordID];
				yield put(websiteProviderReducers.providersEntitiesRefresh(clonedProviders));
				
			}
			showSuccess(messages.successCasinoSave);
			yield put(websiteProviderReducers.uiRefresh({ baseChanged: [], namesBaseChanged: [], seoBaseChanged: [] }));
		} catch (error) {
			showError(messages.errorCasinoSave, error);
			logger.log(error);
		}

		yield put(websiteProviderReducers.uiRefresh({ loading: false }));
	});
}


function* providerDelete() {

	yield takeEvery(websiteProviderActions.WEBSITE_PROVIDER_DELETE, function* (action: ISagaActionType<{ providerID: number}>) {
		yield put(websiteProviderReducers.uiRefresh({ loading: true }));

		const { UI, providers, mainEntities, namesEntities, seoEntities }: IGetStoreReturn = yield select(getStoreData);
		const { websiteID } = UI;
		const { providerID } = action.data;
		const clonedProviders		= cloneDeep(providers);
		const clonedMainEntities	= cloneDeep(mainEntities);
		const clonedNamesEntities	= cloneDeep(namesEntities);
		const clonedSeoEntities		= cloneDeep(seoEntities);

		delete clonedProviders[providerID];
		delete clonedMainEntities[providerID];
		delete clonedNamesEntities[providerID];
		delete clonedSeoEntities[providerID];

		try {
			const res = yield call(websitesAPI.casinoDelete, websiteID, providerID);
			if (res && res.status === 200) {
				showSuccess(messages.successCasinoDelete);
			}
		} catch (error) {
			showError(messages.errorCasinoDelete, error);
			logger.log(error);
		}
		yield put(websiteProviderReducers.providersEntitiesRefresh(clonedProviders));
		yield put(websiteProviderReducers.mainDataRefresh(clonedMainEntities));
		yield put(websiteProviderReducers.namesDataRefresh(clonedNamesEntities));
		yield put(websiteProviderReducers.seoDataRefresh(clonedSeoEntities));
		yield put(websiteProviderReducers.uiRefresh({ loading: false }));
	});
}

function* mainDataReload() {
	yield takeEvery(websiteProviderActions.WEBSITE_PROVIDER_MAIN_DATA_RELOAD, function* (action: ISagaActionType<{ providerID: TStringOrNumber; rowID: TStringOrNumber }>) {
		yield put(websiteProviderReducers.uiRefresh({ loading: true, mainLoading: true }));

		const { websiteID, mainEntities }: IGetStoreReturn = yield select(getStoreData);
		const { providerID, rowID } = action.data;
		
		let mainData = {};
		const params = {
			website_id: websiteID,
		};
		try {
			const res: IApiResponse<IProviderMainResponse> = yield call(casinoAPI.casinoMainDataGet, providerID, websiteID, params);
			if (res && res.status === 200) {
				mainData = adaptMainData(res.data.data);
			}

		} catch (error) {
			logger.log(error);
		}
		const clonedEntities = cloneDeep(mainEntities);
		clonedEntities[rowID] = mainData;

		yield put(websiteProviderReducers.mainDataRefresh(clonedEntities));
		yield put(websiteProviderReducers.uiRefresh({ loading: false, mainLoading: false }));

	});
}


function* namesDataReload() {
	yield takeEvery(websiteProviderActions.WEBSITE_PROVIDER_NAMES_DATA_RELOAD, function* (action: ISagaActionType<{ providerID: TStringOrNumber; rowID: TStringOrNumber }>) {
		yield put(websiteProviderReducers.uiRefresh({ loading: true, namesLoading: true }));

		const { websiteID, namesEntities }: IGetStoreReturn = yield select(getStoreData);

		const { providerID, rowID } = action.data;
		let namesData = adaptNamesData([]);

		const params = {
			website_id: websiteID,
		};
		try {
			const res: IApiResponse<IProviderNamesResponse[]> = yield call(casinoAPI.casinoNamesList, providerID, params);
			if (res && res.status === 200) {
				namesData = adaptNamesData(res.data.data);
			}

		} catch (error) {
			logger.log(error);
		}

		const clonedEntities = cloneDeep(namesEntities);
		clonedEntities[rowID] = namesData;
		yield put(websiteProviderReducers.namesDataRefresh(clonedEntities));
		yield put(websiteProviderReducers.uiRefresh({ loading: false, namesLoading: false }));

	});
}


function* seoDataReload() {
	yield takeEvery(websiteProviderActions.WEBSITE_PROVIDER_SEO_DATA_RELOAD, function* (action: ISagaActionType<{ providerID: TStringOrNumber; rowID: TStringOrNumber }>) {
		yield put(websiteProviderReducers.uiRefresh({ loading: true, seoLoading: true }));
		const {  providerID, rowID } = action.data;
		const { seoEntities, websiteID, languageList }: IGetStoreReturn = yield select(getStoreData);
		const params = {
			website_id: websiteID,
		};
		try {
			const res = yield call(casinoAPI.casinoSeoGet, providerID, params);
			if (res && res.status === 200) {
				const adapted = adaptSeoData(res.data.data, languageList);
				const clonedSeo = cloneDeep(seoEntities);
				clonedSeo[rowID] = adapted;
				yield put(websiteProviderReducers.seoDataRefresh(clonedSeo));
			}

		} catch (error) {
			logger.log(error);
		}
		yield put(websiteProviderReducers.uiRefresh({ loading: false, seoLoading: false }));
	});
}


function* gamesListRefreshFromAPI() {

	yield takeEvery(websiteProviderActions.WEBSITE_PROVIDER_CASINO_GAMES_LIST_REFRESH_FROM_API, function* (action: ISagaActionType<{ providerID: number}>) {

		yield put(websiteProviderReducers.uiRefresh({ loading: true }));

		const { providerID } = action.data;
		const { websiteID }: IGetStoreReturn = yield select(getStoreData);
		try {
			const res = yield call(casinoAPI.gamesListRefresh, { providerID, websiteID });
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successListRefresh);
			}
		} catch (error) {
			notifications.showError(messages.errorListRefresh, error);
			logger.log(error);
		}
		yield put(websiteProviderReducers.uiRefresh({ loading: false }));

	});
}

export function* websiteProvidersSaga () {
	yield all([
		fork(providersReload),
		fork(providerSave),
		fork(providerDelete),
		fork(namesDataReload),
		fork(seoDataReload),
		fork(gamesListRefreshFromAPI),
		fork(mainDataReload),
	]);
}
