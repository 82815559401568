const actions = {

	EMAIL_TEMPLATE_DATA_RESET       : 'EMAIL_TEMPLATE_DATA_RESET',
	EMAIL_TEMPLATE_BASE_DATA_RELOAD : 'EMAIL_TEMPLATE_BASE_DATA_RELOAD',
	EMAIL_TEMPLATE_BASE_DATA_REFRESH: 'EMAIL_TEMPLATE_BASE_DATA_REFRESH',

	EMAIL_TEMPLATE_SAVE      : 'EMAIL_TEMPLATE_SAVE',
	EMAIL_TEMPLATE_UI_REFRESH: 'EMAIL_TEMPLATE_UI_REFRESH',

	dataReset: () => ({
		type: actions.EMAIL_TEMPLATE_DATA_RESET,
	}),
	baseDataReload: templateID => ({
		type: actions.EMAIL_TEMPLATE_BASE_DATA_RELOAD,
		data: {
			templateID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.EMAIL_TEMPLATE_BASE_DATA_REFRESH,
		data,
	}),

	dataSave: () => ({
		type: actions.EMAIL_TEMPLATE_SAVE,
	}),
	uiRefresh: data => ({
		type: actions.EMAIL_TEMPLATE_UI_REFRESH,
		data,
	}),
};

export default actions;
