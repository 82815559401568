import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import filterSaga from './filter/saga';
import resultSaga from './result/saga';
import statisticsSaga from './statistics/saga';
import resultHistorySaga from './resultHistory/saga';

export default function* resultsRootSaga() {
	yield all([
		fork(listSaga),
		fork(filterSaga),
		fork(resultSaga),
		fork(statisticsSaga),
		fork(resultHistorySaga),
	]);
}
