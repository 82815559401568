import { NotificationRootNode } from './notifications.style';
import { DestroyAll } from './DestroyAll';

export const NotificationsRoot =() => {
	return <NotificationRootNode id="notifications-root-node" >
		{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
		{/*@ts-expect-error*/}
		<DestroyAll className="clear_all" />
	</NotificationRootNode>;
};
