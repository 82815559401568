import React from 'react';
import { Icon } from './index.style';

const SelectIcon = (iconSrc) => {
	const { icon } = iconSrc;

	return (
		<Icon src={icon} alt="icon" />
	);
};

export default SelectIcon;
