const actions = {

	CONTENT_ARTICLES_SETTINGS_RELOAD : 'CONTENT_ARTICLES_SETTINGS_RELOAD',
	CONTENT_ARTICLES_SETTINGS_REFRESH: 'CONTENT_ARTICLES_SETTINGS_REFRESH',

	CONTENT_ARTICLES_REVISIONS_LIST_RELOAD : 'CONTENT_ARTICLES_REVISIONS_LIST_RELOAD',
	CONTENT_ARTICLES_REVISIONS_LIST_REFRESH: 'CONTENT_ARTICLES_REVISIONS_LIST_REFRESH',
	CONTENT_ARTICLES_REVISION_SAVE         : 'CONTENT_ARTICLES_REVISION_SAVE',
	CONTENT_ARTICLES_REVISION_APPLY        : 'CONTENT_ARTICLES_REVISION_APPLY',

	CONTENT_ARTICLES_COLUMNS_LIST_REFRESH: 'CONTENT_ARTICLES_COLUMNS_LIST_REFRESH',
	CONTENT_ARTICLES_ITEMS_LIST_REFRESH  : 'CONTENT_ARTICLES_ITEMS_LIST_REFRESH',

	CONTENT_ARTICLES_COLUMN_SAVE  : 'CONTENT_ARTICLES_COLUMN_SAVE',
	CONTENT_ARTICLES_COLUMN_RELOAD: 'CONTENT_ARTICLES_COLUMN_RELOAD',
	CONTENT_ARTICLES_ITEM_SAVE    : 'CONTENT_ARTICLES_ITEM_SAVE',
	CONTENT_ARTICLES_ITEM_RELOAD  : 'CONTENT_ARTICLES_ITEM_RELOAD',

	CONTENT_ARTICLES_COLUMNS_ORDER_UPDATE: 'CONTENT_ARTICLES_COLUMNS_ORDER_UPDATE',
	CONTENT_ARTICLES_ITEMS_ORDER_UPDATE  : 'CONTENT_ARTICLES_ITEMS_ORDER_UPDATE',

	CONTENT_ARTICLES_UI_REFRESH             : 'CONTENT_ARTICLES_UI_REFRESH',
	CONTENT_ARTICLES_UI_MODAL_COLUMN_REFRESH: 'CONTENT_ARTICLES_UI_MODAL_COLUMN_REFRESH',
	CONTENT_ARTICLES_UI_MODAL_ITEM_REFRESH  : 'CONTENT_ARTICLES_UI_MODAL_ITEM_REFRESH',

	settingsReload: () => ({
		type: actions.CONTENT_ARTICLES_SETTINGS_RELOAD,
	}),
	settingsRefresh: (columnsIDs, columnsEntities, itemsIDs, itemsEntities) => ({
		type: actions.CONTENT_ARTICLES_SETTINGS_REFRESH,
		data: {
			columnsIDs,
			columnsEntities,
			itemsIDs,
			itemsEntities,
		},
	}),
	revisionsListReload: () => ({
		type: actions.CONTENT_ARTICLES_REVISIONS_LIST_RELOAD,
	}),
	revisionsListRefresh: revisionsList => ({
		type: actions.CONTENT_ARTICLES_REVISIONS_LIST_REFRESH,
		data: revisionsList,
	}),
	revisionSave: () => ({
		type: actions.CONTENT_ARTICLES_REVISION_SAVE,
	}),
	revisionApply: revisionID => ({
		type: actions.CONTENT_ARTICLES_REVISION_APPLY,
		data: {
			revisionID,
		},
	}),

	columnsListRefresh: (columnsIDs, columnsEntities) => ({
		type: actions.CONTENT_ARTICLES_COLUMNS_LIST_REFRESH,
		data: {
			columnsIDs,
			columnsEntities,
		},
	}),
	itemsListRefresh: (itemsIDs, itemsEntities) => ({
		type: actions.CONTENT_ARTICLES_ITEMS_LIST_REFRESH,
		data: {
			itemsIDs,
			itemsEntities,
		},
	}),
	columnSave: () => ({
		type: actions.CONTENT_ARTICLES_COLUMN_SAVE,
	}),
	columnReload: columnID => ({
		type: actions.CONTENT_ARTICLES_COLUMN_RELOAD,
		data: {
			columnID,
		},
	}),
	itemSave: () => ({
		type: actions.CONTENT_ARTICLES_ITEM_SAVE,
	}),
	itemReload: itemID => ({
		type: actions.CONTENT_ARTICLES_ITEM_RELOAD,
		data: {
			itemID,
		},
	}),
	columnsOrdersUpdate: columnsList => ({
		type: actions.CONTENT_ARTICLES_COLUMNS_ORDER_UPDATE,
		data: {
			columnsList,
		},
	}),
	itemsOrdersUpdate: (removedItem, movingSameList, movedColumnID, movedIndex) => ({
		type: actions.CONTENT_ARTICLES_ITEMS_ORDER_UPDATE,
		data: {
			removedItem,
			movingSameList,
			movedColumnID,
			movedIndex,
		},
	}),
	uiRefresh: data => ({
		type: actions.CONTENT_ARTICLES_UI_REFRESH,
		data,
	}),
	uiColumnRefresh: data => ({
		type: actions.CONTENT_ARTICLES_UI_MODAL_COLUMN_REFRESH,
		data,
	}),
	uiItemRefresh: data => ({
		type: actions.CONTENT_ARTICLES_UI_MODAL_ITEM_REFRESH,
		data,
	}),
};
export default actions;
