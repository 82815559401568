import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { statusCode } from '../../../casino/integrators/utils';
import { uniqBy } from 'lodash';


const fields = {
	id             : 'id',
	mainUserID     : 'main_user',
	partnerName    : 'name',
	status         : 'status_id',
	currencyIDs    : 'currency_ids',
	languageIDs    : 'language_ids',
	paymentIDs     : 'payment_ids',
	gameIDs        : 'game_ids',
	providerIDs    : 'provider_ids',
	integratorsIDs : 'integrator_ids',
	suspendLive    : 'suspend_live',
	suspendPreMatch: 'suspend_prematch',
	smsGateWayIDs  : 'sms_gateway_ids',
	contactEmail   : 'contact_email',
	contactName    : 'contact_name',
	contactPhone   : 'contact_phone',
	name           : 'name',
	websites       : 'websites',
	count          : 'count',
	maxPasswordAge : 'password_expiration_period',

};

const partnerListAdapter = createListAdapter();
const partnerWebsiteAdapter = createPartnerWebsiteAdapter();


export const adaptPartnerList = (data = []) => {

	const adaptedData = partnerListAdapter.adaptList(data);
	return adaptedData || [];
};
export const adaptPartner = (data = {}) => {

	const adaptedData = partnerListAdapter.adapt(data);
	return adaptedData || {};
};

export const adaptPartnerWebsiteList = (data = []) => {

	const adaptedData = partnerWebsiteAdapter.adaptList(data).map(partner => {
		partner.websites.map(website => {
			website.parentID = partner.id;
			return website;
		});
		return partner;
	});
	return adaptedData || [];
};


function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'mainUserID', fields.mainUserID);
	adapter.addField(rules.id, 'status', fields.status);
	adapter.addField(rules.string, 'partnerName', fields.partnerName);
	adapter.addField(rules.string, 'name', fields.contactName);
	adapter.addField(rules.string, 'phoneUnmasked', fields.contactPhone);
	adapter.addField(rules.string, 'email', fields.contactEmail);
	adapter.addField(rules.arrayID, 'currencyIDs', fields.currencyIDs);
	adapter.addField(rules.arrayID, 'paymentIDs', fields.paymentIDs);
	adapter.addField(rules.arrayID, 'gameIDs', fields.gameIDs);
	adapter.addField(rules.arrayID, 'providerIDs', fields.providerIDs);
	adapter.addField(rules.arrayID, 'integratorsIDs', fields.integratorsIDs);
	adapter.addField(rules.arrayID, 'smsGateWayIDs', fields.smsGateWayIDs);
	adapter.addField(rules.arrayID, 'languageIDs', fields.languageIDs);
	adapter.addField(rules.bool, 'suspendLive', fields.suspendLive);
	adapter.addField(rules.bool, 'suspendPreMatch', fields.suspendPreMatch);
	adapter.addField(rules.object, 'count', fields.count);
	adapter.addField(rules.number, 'maxPasswordAge', fields.maxPasswordAge);


	return adapter;
}


function createPartnerWebsiteAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	adapter.addField(rules.string, 'partnerName', fields.name);

	adapter.addField(rules.arrayObject, 'websites', fields.websites);


	return adapter;
}

export const partnerAdapter = (combinedResponse) => {
	const combinedData = combinedResponse.reduce((acc, item) => {
		return Object.assign(acc, item.data.data);
	}, {});
	return partnerListAdapter.adapt(combinedData);

};

export const prepareSettings = (settings) => {
	const {
		paymentMethodIDs,
		languageIDs,
		smsGateWayIDs,
		suspendLive,
		suspendPreMatch,
		currencyIDs,
		providerIDs,
		gameIDs,
		integratorsIDs,
		countryIDs,
		baseDataPartnerGameIDs,
	} = settings;
	return {
		preparedPaymentIDs: {
			payment_ids: paymentMethodIDs,
		},
		preparedLanguageIDs: {
			language_ids: languageIDs,
		},
		preparedSMSGateWayIDs: {
			sms_gateway_ids: smsGateWayIDs,
		},
		preparedSettings: {
			suspend_live    : suspendLive,
			suspend_prematch: suspendPreMatch,
		},
		preparedCurrencyIDs: {
			currency_ids: currencyIDs,
		},
		preparedProviderIDs: {
			game_ids      : uniqBy([...gameIDs, ...baseDataPartnerGameIDs]),
			provider_ids  : providerIDs,
			integrator_ids: integratorsIDs,
		},
		preparedCountryIDs: {
			country_ids: countryIDs,
		},
	};
};

export const preparePartnerData = (partnerData, operatorData) => {
	const preparedData = {
		name                      : '',
		status_id                 : 0,
		contact_email             : '',
		contact_name              : '',
		contact_phone             : '',
		password_expiration_period: 0,
		admin                     : {
			email    : '',
			password : '',
			firstname: '',
			lastname : '',
			roles    : [],
		},
	};

	if (partnerData.partnerName) {
		preparedData[fields.partnerName] = partnerData.partnerName;
	}


	if (partnerData.email) {
		preparedData[fields.contactEmail] = partnerData.email;
	}

	if (partnerData.name) {
		preparedData[fields.contactName] = partnerData.name;
	}

	if (partnerData.phoneUnmasked) {
		preparedData[fields.contactPhone] = partnerData.phoneUnmasked;
	}


	if (isID(partnerData.status)) {
		preparedData[fields.status] = partnerData.status;
	}

	if (operatorData.firstName) {
		preparedData.admin.firstname = operatorData.firstName;
	}

	if (operatorData.lastName) {
		preparedData.admin.lastname = operatorData.lastName;
	}

	if (operatorData.email) {
		preparedData.admin.email = operatorData.email;
	}

	if (operatorData.password) {
		preparedData.admin.password = operatorData.firstName;
	}

	if (operatorData.roles && isArray(operatorData.roles)) {
		preparedData.admin.roles = [...operatorData.roles];
	}
	if (partnerData.maxPasswordAge) {
		preparedData.password_expiration_period = partnerData.maxPasswordAge;
	}

	return preparedData;
};

export const prepareIDs = (data = [], listID, id) => data[listID].filter(item => item.selected).map(item => item[id]);

export const checkFetchedEntities = (integrators, providersWithGames, adapted) => {
	const { providerIDs: checkedProviderIDs = [], gameIDs: checkedGameIDs = [], integratorsIDs: checkedIntegratorsIDs = [], count } = adapted;

	const clonedIntegratorsData = cloneDeep(integrators);
	const clonedProvidersData   = cloneDeep(providersWithGames);
	clonedIntegratorsData.forEach(integrator => {
		if (checkedIntegratorsIDs.includes(integrator.id)) {
			integrator.checked = true;
		}
		let integratorGameCount = 0;
		integrator.providers.forEach(provider => {
			const providerGames = clonedProvidersData[provider.providerID];
			if (providerGames) {
				clonedProvidersData[provider.providerID].gameDataByProvider.forEach(item => {
					item.checked = checkedGameIDs.includes(item.gameID);
				});
			}
			provider.checked = checkedProviderIDs.includes(provider.providerID);
			if (count[provider.providerID]) {
				provider.gameCount = count[provider.providerID];
				integratorGameCount += provider.gameCount;
			} else {
				provider.gameCount = 0;
			}
		});
		integrator.gameCount = integratorGameCount;
	});

	const filteredClonedIntegratorsData = filterIntegratorsList(clonedIntegratorsData);

	return { clonedIntegratorsData: filteredClonedIntegratorsData, clonedProvidersData };
};


export const adaptProviderIDs = (integratorsList, integratorsIDs = []) => {
	const providerIDs = integratorsList.reduce((acc, item) => {
		const checkedProviders = [];
		item.checked && integratorsIDs.push(item.id);
		item.providers.forEach(prov => {
			if (prov.checked) {
				checkedProviders.push(prov.providerID);
			}
		});

		return [...acc, ...checkedProviders];
	}, []);
	return {
		integratorsIDs,
		providerIDs,
	};
};


export const adaptGameIDs = (providersWithGames) => {
	return Object.keys(providersWithGames).reduce((acc, item) => {
		const checkedGames = [];
		if (providersWithGames[item] ) {
			providersWithGames[item].gameDataByProvider.forEach(game => {
				if (game.isChecked) {
					checkedGames.push(game.gameID);
				}
			});
		}
		return [...acc, ...checkedGames];
	}, []);
};

export function filterIntegratorsList(integrators) {
	return integrators.filter(integrator => {
		const { checked, status, gameCount } = integrator;

		if (+status === statusCode.deleted) {
			if (checked || gameCount > 0) {
				return true;
			}

			return Object.values(integrator.providers).some(({ checked: providerChecked }) => providerChecked);
		}

		return true;
	});
}


export const updatePartnerList = (list = [], data = {}) => {
	return list.map(item => {
		if (item.id === data.id){
			return data;
		}
		return item;
	});
};

export const mergePartnerList = (list = [], data) => {
	return [ data, ...list];
};
