const actions = {

	CATEGORY_DATA_RESET: 'CATEGORY_DATA_RESET',
	CATEGORY_SAVE      : 'CATEGORY_SAVE',

	CATEGORY_BASE_DATA_RELOAD     : 'CATEGORY_BASE_DATA_RELOAD',
	CATEGORY_BASE_DATA_REFRESH    : 'CATEGORY_BASE_DATA_REFRESH',
	CATEGORY_SETTINGS_DATA_RELOAD : 'CATEGORY_SETTINGS_DATA_RELOAD',
	CATEGORY_SETTINGS_DATA_REFRESH: 'CATEGORY_SETTINGS_DATA_REFRESH',
	CATEGORY_DYNAMIC_DATA_RELOAD  : 'CATEGORY_DYNAMIC_DATA_RELOAD',
	CATEGORY_DYNAMIC_DATA_REFRESH : 'CATEGORY_DYNAMIC_DATA_REFRESH',
	CATEGORY_NAMES_DATA_RELOAD    : 'CATEGORY_NAMES_DATA_RELOAD',
	CATEGORY_NAMES_DATA_REFRESH   : 'CATEGORY_NAMES_DATA_REFRESH',
	CATEGORY_CMS_DATA_RELOAD      : 'CATEGORY_CMS_DATA_RELOAD',
	CATEGORY_CMS_DATA_REFRESH     : 'CATEGORY_CMS_DATA_REFRESH',
	CATEGORY_HISTORY_DATA_RELOAD  : 'CATEGORY_HISTORY_DATA_RELOAD',
	CATEGORY_HISTORY_DATA_REFRESH : 'CATEGORY_HISTORY_DATA_REFRESH',

	CATEGORY_SPORT_REGULATION_TYPE_LIST_RELOAD  : 'CATEGORY_SPORT_REGULATION_TYPE_LIST_RELOAD',
	CATEGORY_SPORT_REGULATION_TYPE_LIST_REFRESH : 'CATEGORY_SPORT_REGULATION_TYPE_LIST_REFRESH',
	CATEGORY_LEAGUE_REGULATION_TYPE_LIST_RELOAD : 'CATEGORY_LEAGUE_REGULATION_TYPE_LIST_RELOAD',
	CATEGORY_LEAGUE_REGULATION_TYPE_LIST_REFRESH: 'CATEGORY_LEAGUE_REGULATION_TYPE_LIST_REFRESH',

	CATEGORY_MODAL_UI_REFRESH  : 'CATEGORY_MODAL_UI_REFRESH',
	CATEGORY_IS_CHANGED_REFRESH: 'CATEGORY_IS_CHANGED_REFRESH',

	CATEGORY_MARKETS_UI_REFRESH : 'CATEGORY_MARKETS_UI_REFRESH',
	CATEGORY_MARKETS_DATA_RELOAD: 'CATEGORY_MARKETS_DATA_RELOAD',

	SPORT_CATEGORY_SEO_DATA_REFRESH: 'SPORT_CATEGORY_SEO_DATA_REFRESH',
	SPORT_CATEGORY_SEO_DATA_RELOAD : 'SPORT_CATEGORY_SEO_DATA_RELOAD',
	SPORT_CATEGORY_SEO_DATA_SAVE   : 'SPORT_CATEGORY_SEO_DATA_SAVE',

	dataReset: () => ({
		type: actions.CATEGORY_DATA_RESET,
	}),
	saveCategory: () => ({
		type: actions.CATEGORY_SAVE,
	}),

	baseDataReload: categoryID => ({
		type: actions.CATEGORY_BASE_DATA_RELOAD,
		data: {
			categoryID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.CATEGORY_BASE_DATA_REFRESH,
		data,
	}),
	settingsDataReload: categoryID => ({
		type: actions.CATEGORY_SETTINGS_DATA_RELOAD,
		data: {
			categoryID,
		},
	}),
	settingsDataRefresh: data => ({
		type: actions.CATEGORY_SETTINGS_DATA_REFRESH,
		data,
	}),
	dynamicDataReload: categoryID => ({
		type: actions.CATEGORY_DYNAMIC_DATA_RELOAD,
		data: {
			categoryID,
		},
	}),
	dynamicDataRefresh: data => ({
		type: actions.CATEGORY_DYNAMIC_DATA_REFRESH,
		data,
	}),
	namesDataReload: categoryID => ({
		type: actions.CATEGORY_NAMES_DATA_RELOAD,
		data: {
			categoryID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.CATEGORY_NAMES_DATA_REFRESH,
		data,
	}),
	cmsDataReload: categoryID => ({
		type: actions.CATEGORY_CMS_DATA_RELOAD,
		data: {
			categoryID,
		},
	}),
	cmsDataRefresh: data => ({
		type: actions.CATEGORY_CMS_DATA_REFRESH,
		data,
	}),
	historyDataReload: categoryID => ({
		type: actions.CATEGORY_HISTORY_DATA_RELOAD,
		data: {
			categoryID,
		},
	}),
	historyDataRefresh: data => ({
		type: actions.CATEGORY_HISTORY_DATA_REFRESH,
		data,
	}),

	sportRegulationTypeListReload: sportID => ({
		type: actions.CATEGORY_SPORT_REGULATION_TYPE_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	sportRegulationTypeListRefresh: list => ({
		type: actions.CATEGORY_SPORT_REGULATION_TYPE_LIST_REFRESH,
		data: list,
	}),
	leagueRegulationTypeListReload: leagueID => ({
		type: actions.CATEGORY_LEAGUE_REGULATION_TYPE_LIST_RELOAD,
		data: {
			leagueID,
		},
	}),
	leagueRegulationTypeListRefresh: list => ({
		type: actions.CATEGORY_LEAGUE_REGULATION_TYPE_LIST_REFRESH,
		data: list,
	}),

	modalUIRefresh: data => ({
		type: actions.CATEGORY_MODAL_UI_REFRESH,
		data,
	}),
	isChangedRefresh: data => ({
		type: actions.CATEGORY_IS_CHANGED_REFRESH,
		data,
	}),

	marketsUIRefresh: data => ({
		type: actions.CATEGORY_MARKETS_UI_REFRESH,
		data,
	}),
	marketsDataReload: (sportID, leagueID) => ({
		type: actions.CATEGORY_MARKETS_DATA_RELOAD,
		data: {
			sportID,
			leagueID,
		},
	}),

	seoDataReload: categoryID => ({
		type: actions.SPORT_CATEGORY_SEO_DATA_RELOAD,
		data: {
			categoryID,
		},
	}),

	seoDataRefresh: data => ({
		type: actions.SPORT_CATEGORY_SEO_DATA_REFRESH,
		data,
	}),

	seoDataSave: categoryID => ({
		type: actions.SPORT_CATEGORY_SEO_DATA_SAVE,
		data: {
			categoryID,
		},
	}),
};

export default actions;
