import { isNull } from 'lodash';

export function adaptSMSLsit(rawData = []) {

	const result = rawData.map( message => {

		message.subject = isNull(message.subject) ? 'No subject' : message.subject;

		message.messageID = message.id;
		message.userID    = message.user_id;
		message.issueDate = message.created;
		message.senderID  = message.sender_id;

		['id', 'user_id', 'created', 'sender_id'].forEach( key => delete message[key] );

		return message;

	});

	return result;

}

export function getDay(date) {
	return new Date(date).toLocaleDateString('en-US');
}

export function getTime(date) {
	return new Date(date).toTimeString().split(' ')[0];
}

export const normalizeDate = (date) => {
	if (date) {
		return  new Date(date);
	}
	return null;

};
