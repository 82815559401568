import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import { calculateLimit } from '../../../../helpers/utils';
import { createSelectionsListAdapter } from '../event/utils';
import EntityAdapter from '../../../../helpers/entityAdapter';

const fields = {
	odd         : 'odd',
	date        : 'stamp',
	sourceTypeId: 'source_type_id',
	sourceId    : 'source_id',
};

const selectionAdapter = createSelectionsListAdapter();
const historyDataAdapter = createHistoryDataAdapter();
// Adapting ---------------------------------------------------------------------------------------

export function adaptHistoryData(historyData = []) {
	let arrayData = cloneDeep(historyData);
	const dataList = [];
	if (!isArray(arrayData)) {
		arrayData = [];
	}
	historyDataAdapter.clearExcludes();
	for (let i = 0; i < arrayData.length; i++) {
		const adaptedData = historyDataAdapter.adapt(arrayData[i]);
		dataList.push(adaptedData);
	}
	return dataList;
}
// Preparing --------------------------------------------------------------------------------------

export function prepareBaseData(rawData = {}) {
	selectionAdapter.clearExcludes();
	selectionAdapter.addExcludeField('name');

	// calculate real absolute limits
	rawData.userStakeLimitParent = calculateLimit({
		override      : rawData.userStakeLimitOverride,
		limitLocal    : rawData.userStakeLimit,
		limitTypeID   : rawData.userStakeLimitTypeID,
		parentAbsolute: rawData.userStakeLimitParentAbsolute,
	});
	rawData.userWonLimitParent = calculateLimit({
		override      : rawData.userWonLimitOverride,
		limitLocal    : rawData.userWonLimit,
		limitTypeID   : rawData.userWonLimitTypeID,
		parentAbsolute: rawData.userWonLimitParentAbsolute,
	});

	const preparedData = selectionAdapter.prepare(rawData);

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
function createHistoryDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'odd', fields.odd);
	adapter.addField(rules.fullMsDate, 'date', fields.date);
	adapter.addField(rules.id, 'sourceTypeId', fields.sourceTypeId);
	adapter.addField(rules.id, 'sourceId', fields.sourceId);
	return adapter;
}
