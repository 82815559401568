import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({

	entities: {},
	filter  : createFilter(filtersURL.welcome, initFilterTypes),

	bonusData: {
		count: { 
			providers : {}, 
			categories: {}, 
		},
		titles       : [],
		integratorIDs: [],
		providerIDs  : [],
		games        : [],
		categoryIDs  : [],
	},
	historyLogs: [],

	UI: {
		bonusID   : null,
		visible   : false,
		loading   : false,
		editMode  : false,
		closeModal: false,
		isChanged : false,
		langID    : false,
	},
});

export default function welcomeBonusReducer(state = initState, action) {

	switch (action.type) {

		case actions.LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.FILTER_REFRESH: {
			const { filter } = action.data;
			const target = state.get('filter');
			const result = fill(filter, target, true);
			return state.set('filter', result);
		}


		case actions.BONUS_DATA_REFRESH: {
			const { bonusData } = action.data;
			const target = state.get('bonusData');
			const result = fill(bonusData, target, true);
			return state.set('bonusData', result);
		}

		case actions.BONUS_UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}

		case actions.HISTORY_LOGS_REFRESH: {
			const { historyLogs } = action.data;
			return state.set('historyLogs', historyLogs);
		}

		case actions.BONUS_DATA_RESET: {
			const bonusData = initState.get('bonusData');
			const UI = initState.get('UI');

			return state
				.set('bonusData', bonusData)
				.set('UI', UI);
		}

		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
