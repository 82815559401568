import { Map } from 'immutable';
import uniq from 'lodash/uniq';
import { createFilter, fill, filtersURL, isID } from '../../../../helpers/utils';
import actions from './actions';
import { defaultValuesDisplayTypes, initFilterTypes } from './utils';

const initState = new Map({

	list      : [],
	changedIDs: [],
	filter    : createFilter(filtersURL.displayTypes, initFilterTypes),

	UI: {
		loading  : false,
		showModal: false,
		id       : null,
	},
});

export default function marketsDisplayTypesReducer(state = initState, action) {

	switch (action.type) {

		case actions.MARKETS_DISPLAY_TYPES_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.MARKETS_DISPLAY_TYPES_FILTER_REFRESH: {
			const target = state.get('filter');
			const result = fill(action.data, target, true);
			return state.set('filter', result);
		}

		case actions.MARKETS_DISPLAY_TYPES_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		case actions.MARKETS_DISPLAY_TYPES_CHANGED_ID_ADD: {
			const { typeID } = action.data;
			const changedIDs = state.get('changedIDs');
			const result = uniq([...changedIDs, typeID]);

			return state.set('changedIDs', result);
		}

		case actions.MARKETS_DISPLAY_TYPES_CHANGED_IDS_RESET: {
			return state.set('changedIDs', []);
		}
		case actions.MARKETS_DISPLAY_TYPES_MODAL_REFRESH: {
			return state.set('UI', action.data);
		}
		case actions.MARKETS_DISPLAY_TYPES_REMOVE_CANCELED_ITEM: {
			const newList = state.get('list').filter(item => isID(item.id));
			return state.set('list', newList);
		}

		case actions.MARKETS_DISPLAY_TYPES_FILTER_RESET: {
			return state.set('filter', createFilter(filtersURL.displayTypes, initFilterTypes, defaultValuesDisplayTypes));
		}
		case actions.FILTER_RESET: {
			return state.set('filter', { sportID: 150592 });
		}

		default:
			return state;
	}
}
