import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	reportFields: [],

	UI: {
		loading       : false,
		isRecalculated: false,
	},
});

export default function reportWithdrawalsReducer(state = initState, action) {

	switch (action.type) {

		case actions.REPORT_WITHDRAWALS_DATA_REFRESH: {
			return state.set('reportData', action.data);
		}

		case actions.REPORT_WITHDRAWALS_FIELDS_REFRESH: {
			return state.set('reportFields', action.data);
		}

		case actions.REPORT_WITHDRAWALS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
