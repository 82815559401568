export const calcRegularPrice = selections => {
	return selections.reduce((price, select) => {
		return price * select.odd;
	}, 1);
};

export const getSelectionsFromMarket = (items, selections) => {
	return items.reduce((array, selection) => {
		const foundSelection = selections.find(
			({ id }) => selection.event_selection_id === Number(id)
		);
		if (foundSelection) {
			array.push(foundSelection);
		}
		return array;
	}, []);
};
