export const actions = {

	SECURITY_WHITELIST_RELOAD     : 'SECURITY_WHITELIST_RELOAD',
	SECURITY_WHITELIST_REFRESH    : 'SECURITY_WHITELIST_REFRESH',
	SECURITY_WHITELIST_SAVE_ITEM  : 'SECURITY_WHITELIST_SAVE_ITEM',
	SECURITY_WHITELIST_DELETE_ITEM: 'SECURITY_WHITELIST_DELETE_ITEM',
	SECURITY_WHITELIST_UI_REFRESH : 'SECURITY_WHITELIST_UI_REFRESH',
	SECURITY_WHITELIST_UI_RESET   : 'SECURITY_WHITELIST_UI_RESET',

	listReload: () => ({
		type: actions.SECURITY_WHITELIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.SECURITY_WHITELIST_REFRESH,
		data: { entities },
	}),
	saveDomain: domainID => ({
		type: actions.SECURITY_WHITELIST_SAVE_ITEM,
		data: { domainID },
	}),
	deleteDomain: domainID => ({
		type: actions.SECURITY_WHITELIST_DELETE_ITEM,
		data: { domainID },
	}),
	uiRefresh: data => ({
		type: actions.SECURITY_WHITELIST_UI_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.SECURITY_WHITELIST_UI_RESET,
	}),
};

export default actions;
