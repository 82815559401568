const prefix = 'PARTNER_OPERATOR_';

export const actions = {
	OPERATOR_UI_REFRESH       : `${prefix}UI_REFRESH`,
	OPERATOR_BASE_DATA_REFRESH: `${prefix}BASE_DATA_REFRESH`,

	RESET_STORE: `${prefix}RESET_STORE`,


	uiRefresh: data => ({
		type: actions.OPERATOR_UI_REFRESH,
		data,
	}),

	baseDataRefresh: data => ({
		type: actions.OPERATOR_BASE_DATA_REFRESH,
		data,
	}),

	resetStore: () => ({
		type: actions.RESET_STORE,
	}),

};
