import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../helpers/utils';

const initState = new Map({
	entities        : {},
	gamesByProviders: {},

	UI: {
		loading: false,
	},
});

export default function casinoProvidersListReducer(state = initState, action) {

	switch (action.type) {

		case actions.CASINO_PROVIDERS_LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.CASINO_INTEGRATORS_PROVIDERS_LIST_REFRESH_GAMES: {
			const target = state.get('gamesByProviders');
			const result = { ...target, ...action.data };
			return state.set('gamesByProviders', result);
		}

		case actions.CASINO_PROVIDERS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
