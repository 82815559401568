const commonActions = {

	COMMON_SPORT_LIST_RELOAD : 'COMMON_SPORT_LIST_RELOAD',
	COMMON_SPORT_LIST_SUCCESS: 'COMMON_SPORT_LIST_SUCCESS',
	COMMON_SPORT_LIST_ERROR  : 'COMMON_SPORT_LIST_ERROR',
	commonSportListReload    : () => ({
		type: commonActions.COMMON_SPORT_LIST_RELOAD,
	}),

	COMMON_PROVIDER_LIST_RELOAD : 'COMMON_PROVIDER_LIST_RELOAD',
	COMMON_PROVIDER_LIST_SUCCESS: 'COMMON_PROVIDER_LIST_SUCCESS',
	COMMON_PROVIDER_LIST_ERROR  : 'COMMON_PROVIDER_LIST_ERROR',
	commonProviderListReload    : () => ({
		type: commonActions.COMMON_PROVIDER_LIST_RELOAD,
	}),

	COMMON_RISK_GROUP_LIST_RELOAD : 'COMMON_RISK_GROUP_LIST_RELOAD',
	COMMON_RISK_GROUP_LIST_SUCCESS: 'COMMON_RISK_GROUP_LIST_SUCCESS',
	COMMON_RISK_GROUP_LIST_ERROR  : 'COMMON_RISK_GROUP_LIST_ERROR',
	COMMON_UI_REFRESH             : 'COMMON_UI_REFRESH',

	commonRiskGroupListReload: () => ({
		type: commonActions.COMMON_RISK_GROUP_LIST_RELOAD,
	}),
	uiRefresh: data => ({
		type: commonActions.COMMON_UI_REFRESH,
		data,
	}),

	COMMON_ADMIN_LIST_RELOAD : 'COMMON_ADMIN_LIST_RELOAD',
	COMMON_ADMIN_LIST_SUCCESS: 'COMMON_ADMIN_LIST_SUCCESS',
	COMMON_ADMIN_LIST_ERROR  : 'COMMON_ADMIN_LIST_ERROR',
	commonAdminListReload    : () => ({
		type: commonActions.COMMON_ADMIN_LIST_RELOAD,
	}),
};
export default commonActions;
