const actions = {

	BLOCKED_IP_DATA_RESET       : 'BLOCKED_IP_DATA_RESET',
	BLOCKED_IP_BASE_DATA_RELOAD : 'BLOCKED_IP_BASE_DATA_RELOAD',
	BLOCKED_IP_BASE_DATA_REFRESH: 'BLOCKED_IP_BASE_DATA_REFRESH',

	BLOCKED_IP_SAVE      : 'BLOCKED_IP_SAVE',
	BLOCKED_IP_UI_REFRESH: 'BLOCKED_IP_UI_REFRESH',

	dataReset: () => ({
		type: actions.BLOCKED_IP_DATA_RESET,
	}),

	baseDataReload: blockedIpID => ({
		type: actions.BLOCKED_IP_BASE_DATA_RELOAD,
		data: {
			blockedIpID,
		},
	}),

	baseDataRefresh: data => ({
		type: actions.BLOCKED_IP_BASE_DATA_REFRESH,
		data,
	}),

	dataSave: (closeModal) => ({
		type: actions.BLOCKED_IP_SAVE,
		data: {
			closeModal,
		},
	}),

	uiRefresh: data => ({
		type: actions.BLOCKED_IP_UI_REFRESH,
		data,
	}),
};

export default actions;
