import { cloneDeep, isUndefined } from 'lodash';
import { LIMITS_CASINO_OPTIONS_TYPES } from '../../../../../../constants/users';
import { statusCode } from '../../../../../casino/integrators/utils';

function makeObjectWithIdAsKey(array) {
	const firstLevelObject = {};
	let secondLevelObject  = {};
	let isSecondLevel      = false;
	const cloneArray       = cloneDeep(array);

	cloneArray.forEach(arrayItem => {
		if (isUndefined(arrayItem.providers)) {
			firstLevelObject[arrayItem.provider_id] = {
				name        : arrayItem.name,
				id          : arrayItem.provider_id,
				integratorID: arrayItem.integrator_id,
				games       : null,
			};
		} else {
			firstLevelObject[arrayItem.id] = { name: arrayItem.name, id: arrayItem.id, status: arrayItem.status };
			secondLevelObject              = { ...secondLevelObject, ...makeObjectWithIdAsKey(arrayItem.providers) };
			isSecondLevel                  = true;
		}
	});

	return isSecondLevel ? { integrators: firstLevelObject, providers: secondLevelObject } : firstLevelObject;
}

function makeObjectWithIdAsKeyGames(gameListByProvider, providerID) {
	const gameListObject = {};
	gameListByProvider.forEach(game => {
		gameListObject[game.id] = {
			id  : game.id,
			name: game.name,
			providerID,
		};
	});
	return gameListObject;
}

function mergeLimitWithListFirstAndSecondLevelTogether(integratorList, providerList, limitsObject, integratorType, providerType, gameType) {

	function findInListAndMerge(list, limitItem) {
		if (!isUndefined(list[limitItem.service_id])) {
			list[limitItem.service_id] = {
				...list[limitItem.service_id],
				serviceID: limitItem.service_id,
				minStake : limitItem.min_stake,
				maxStake : limitItem.max_stake,
				block    : limitItem.block,
			};
		}
	}

	const gamesLimits = [];

	limitsObject.forEach(limitItem => {

		switch (limitItem.type) {
			case integratorType:
				findInListAndMerge(integratorList, limitItem);
				break;
			case providerType:
				findInListAndMerge(providerList, limitItem);
				break;
			case gameType:
				gamesLimits.push(limitItem);
				break;
			default:
				gamesLimits.push(limitItem);
				break;
		}
	});

	return gamesLimits;

}

function mergeLimitWithList(list, limitObject) {
	limitObject.forEach(limitItem => {
		if (!isUndefined(list[limitItem.service_id])) {
			list[limitItem.service_id] = {
				...list[limitItem.service_id],
				serviceID: limitItem.service_id,
				minStake : limitItem.min_stake,
				maxStake : limitItem.max_stake,
				block    : limitItem.block,
			};
		}
	});
}

function mergeProviderToIntegrator(integrators, providers) {
	Object.values(providers).forEach(provider => {

		if (!isUndefined(provider.id)) {
			const integratorID = provider.integratorID || 0;
			integrators[integratorID] = {
				...integrators[integratorID],
				providers: {
					...(
						integrators[integratorID].providers ? integrators[integratorID].providers : {}
					),
					[provider.id]: provider,
				},
			};
		}
	});
}

function mergeGameToProvider(providers, games) {
	Object.values(games).forEach(game => {
		if (!isUndefined(game.id)) {
			providers[game.providerID] = {
				...providers[game.providerID],
				games: {
					...(
						providers[game.providerID] && providers[game.providerID].games ? providers[game.providerID].games : {}
					),
					[game.id]: game,
				},
			};
		}
	});
}

export function adaptIntegratorWProviderListLimits(response) {
	const { integrators, providers } = makeObjectWithIdAsKey(response[0].data.data);

	const integratorType = LIMITS_CASINO_OPTIONS_TYPES.integrator;
	const providerType   = LIMITS_CASINO_OPTIONS_TYPES.provider;
	const gameType       = LIMITS_CASINO_OPTIONS_TYPES.game;

	const gamesLimits = mergeLimitWithListFirstAndSecondLevelTogether(integrators, providers, response[1].data.data, integratorType, providerType, gameType);

	const providersWlimitsASobject = cloneDeep(providers);

	mergeProviderToIntegrator(integrators, providers);

	const filteredEntities = filterIntegratorWProviderListLimits(integrators);

	const filteredProviders = filterIntegratorProviders(filteredEntities, providersWlimitsASobject);

	return { integrators: filteredEntities, providers: filteredProviders, gamesLimits };
}

export function adaptGameListLimits(gamesListByProvider, gamesLimits, integrators, providerWlimits, providerID) {

	const games = makeObjectWithIdAsKeyGames(gamesListByProvider, providerID);

	mergeLimitWithList(games, gamesLimits);

	mergeGameToProvider(providerWlimits, games);

	mergeProviderToIntegrator(integrators, providerWlimits);

	return cloneDeep(integrators);

}

export function filterIntegratorWProviderListLimits(integrators) {
	return Object.values(integrators).filter(integrator => {
		const { block, minStake, maxStake, status } = integrator;

		if (status === statusCode.deleted) {
			if (block || minStake || maxStake) {
				return true;
			}

			return Object.values(integrator.providers).some(
				({
					block: providerBlock,
					minStake: providerMinStake,
					maxStake: providerMaxStake,
				}) => providerBlock || providerMinStake || providerMaxStake
			);
		}

		return true;
	}).reduce((result, obj) => ({ ...result, [obj.id]: obj }), {});
}

export function filterIntegratorProviders(integrators, providers) {
	return Object.values(providers)
		.filter(provider => (provider.integratorID && integrators[provider.integratorID]?.id) || !provider.integratorID)
		.reduce((result, obj) => ({ ...result, [obj.id]: obj }), {});
}

export function updateTreeProviderLoaderState(tree, providersWlimits, providerID, loading) {
	const clonedTree = cloneDeep(tree);
	const integratorID = providersWlimits[providerID]?.integratorID || 0;

	clonedTree[integratorID] = {
		...clonedTree[integratorID],
		providers: {
			...(
				clonedTree[integratorID].providers ? clonedTree[integratorID].providers : {}
			),
			[providerID]: {
				...clonedTree[integratorID].providers[providerID],
				loading,
			},
		},
	};

	return clonedTree;
}

export function updateEntityLimits(tree, providersWlimits, serviceID, providerID, { type, block, max_stake: maxStake, min_stake: minStake }) {
	const integratorID = providerID ? (providersWlimits[providerID]?.integratorID || 0) : serviceID;
	const clonedTree = cloneDeep(tree);

	if (type === LIMITS_CASINO_OPTIONS_TYPES.integrator) {
		// Update limits for integrator
		clonedTree[integratorID] = {
			...clonedTree[integratorID],
			block,
			maxStake,
			minStake,
		};
	}

	if (type === LIMITS_CASINO_OPTIONS_TYPES.provider) {
		// Update limits for provider
		clonedTree[integratorID] = {
			...clonedTree[integratorID],
			providers: {
				...(
					clonedTree[integratorID].providers ? clonedTree[integratorID].providers : {}
				),
				[serviceID]: {
					...clonedTree[integratorID].providers[serviceID],
					block,
					maxStake,
					minStake,
				},
			},
		};
	}

	if (type === LIMITS_CASINO_OPTIONS_TYPES.game) {
		// Update limits for game
		clonedTree[integratorID] = {
			...clonedTree[integratorID],
			providers: {
				...clonedTree[integratorID].providers,
				[providerID]: {
					...clonedTree[integratorID].providers[providerID],
					games: {
						...clonedTree[integratorID].providers[providerID].games,
						[serviceID]: {
							...clonedTree[integratorID].providers[providerID].games[serviceID],
							block,
							maxStake,
							minStake,
						},
					},
				},
			},
		};
	}

	return clonedTree;
}

