import keys from 'lodash/keys';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import EntityAdapter from '../../../../helpers/entityAdapter';
import Formatter from '../../../../helpers/formatter';
import { CATEGORIES_TYPES, TRADING_MODE } from '../../../../helpers/commonConstants';
import { getTargetNode } from '../../../../containers/Sport/Categories/Sports/Tree/utils';

const fields = {
	id             : 'id',
	name           : 'name',
	orderID        : 'order_id',
	parentID       : 'parent_id',
	typeID         : 'type_id',
	statusID       : 'status_id',
	websiteID      : 'website_id',
	displayStatusID: 'display_status_id',
	tradingModeID  : 'trading_mode',
	iconSmallURL   : 'icon_small_url',
	iconURL        : 'icon_url',
	code           : 'code',
	// Orders
	categoryID     : 'category_id',
	channelID      : 'channel_id',
	eventsCount    : 'events_count',
};

const listAdapter   = createListDataAdapter();
const ordersAdapter = createOrdersAdapter();

// Adapt ------------------------------------------------------------------------------------------
export function adaptCategoryList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedList = listAdapter.adaptList(rawData);

	const result = adaptedList.map(item => {
		return {
			...item,
			key: `category-${item.id}`,
		};
	});

	return result;
}

export function adaptEventsList(rawData = [], leagueID) {
	const adapter = createEventListDataAdapter();
	const adaptedList = adapter.adaptList(rawData);

	const result = adaptedList.map((item, index) => {
		const startDate = Formatter.dateTime(item.startDate);

		return {
			...item,
			key         : `event-${item.id}`,
			name        : `${startDate} ${item.name}`,
			orderID     : index,
			parentID    : leagueID,
			typeID      : CATEGORIES_TYPES.event,
			statusID    : 1,
			iconSmallURL: '',
		};
	});

	result.sort((itemA, itemB) => {
		return itemA.name > itemB.name ? 1 : -1;
	});

	return result;
}

export function adaptMarketsList(rawData = [], eventID) {
	const marketAdapter = createMarketListDataAdapter();
	const selectionAdapter = createSelectionListDataAdapter();
	// eslint-disable-next-line no-return-assign
	rawData.forEach(elm => elm.name = `${elm.name} ${elm.argument || ''}` );
	const marketList = marketAdapter.adaptList(rawData);
	const result = marketList.map((item, index) => {
		const marketID = item.id;

		const adaptedSelectionList = selectionAdapter.adaptList(item.selections);
		const selectionList = adaptedSelectionList.map((item, index) => {
			const odd = Formatter.sum(item.odd);

			return {
				...item,
				key         : `selection-${item.id}`,
				name        : `${item.name}, odd : ${odd}`,
				orderID     : index,
				parentID    : marketID,
				typeID      : CATEGORIES_TYPES.selection,
				statusID    : 1,
				iconSmallURL: '',
			};
		});

		const marketItem = {
			...item,
			key         : `market-${marketID}`,
			orderID     : index,
			parentID    : eventID,
			typeID      : CATEGORIES_TYPES.market,
			statusID    : 1,
			iconSmallURL: '',
			children    : selectionList,
		};

		return marketItem;
	});

	return result;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareOrdersList(rawData = [], channelID, websiteID) {

	ordersAdapter.clearExcludes();
	const preparedData = ordersAdapter.prepareList(rawData);
	preparedData.forEach(item => {
		item[fields.websiteID] = websiteID;
		item[fields.channelID] = channelID;
		if (!item[fields.tradingModeID]) {
			item[fields.tradingModeID] = TRADING_MODE.prematch;
		}
	});

	return preparedData;
}

// Service ----------------------------------------------------------------------------------------
export function appendSubNodes(treeDataSource, childrenList, parentNodePos) {
	const targetTree = cloneDeep(treeDataSource);
	const isLeaf = isEmpty(childrenList);
	const targetNode = getTargetNode(targetTree, parentNodePos);

	targetNode.isLeaf = isLeaf;
	if (!isLeaf) {
		targetNode.children = childrenList;
	}

	return targetTree;
}

export function setDisplayStatusID(treeDataSource, nodePos, displayStatusID) {
	const targetTree = cloneDeep(treeDataSource);
	const targetNode = getTargetNode(targetTree, nodePos);

	targetNode.displayStatusID = displayStatusID;

	return {
		treeDataSource: targetTree,
		node          : targetNode,
	};
}

export function updateNodeInTree(treeDataSource, nodePos, nodeData) {

	const targetTree = cloneDeep(treeDataSource);
	const targetNode = getTargetNode(targetTree, nodePos);

	const nodeKeys = keys(nodeData);
	nodeKeys.forEach(key => {
		targetNode[key] = nodeData[key];
	});

	return {
		treeDataSource: targetTree,
		node          : targetNode,
	};
}

// Adapters ---------------------------------------------------------------------------------------
function createListDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'parentID', fields.parentID);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'displayStatusID', fields.displayStatusID);
	adapter.addField(rules.id, 'tradingModeID', fields.tradingModeID);
	adapter.addField(rules.id, 'eventsCount', fields.eventsCount);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'iconSmallURL', fields.iconSmallURL);
	adapter.addField(rules.string, 'iconURL', fields.iconURL);
	adapter.addField(rules.string, 'code', fields.code);

	return adapter;
}

function createEventListDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', 'id');
	adapter.addField(rules.id, 'tradingModeID', 'trading_mode');
	adapter.addField(rules.id, 'displayStatusID', 'display_status_id');

	adapter.addField(rules.string, 'name', 'name');
	adapter.addField(rules.dateTime, 'startDate', 'start_date');

	return adapter;
}

function createMarketListDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', 'id');
	adapter.addField(rules.id, 'tradingModeID', 'trading_mode');
	adapter.addField(rules.id, 'displayStatusID', 'display_status_id');

	adapter.addField(rules.string, 'name', 'name');
	adapter.addField(rules.arrayObject, 'selections', 'selections');

	return adapter;
}

function createSelectionListDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', 'id');
	adapter.addField(rules.id, 'tradingModeID', 'trading_mode');
	adapter.addField(rules.id, 'displayStatusID', 'display_status_id');

	adapter.addField(rules.string, 'name', 'name');
	adapter.addField(rules.positiveNumber, 'odd', 'odd');

	return adapter;
}

function createOrdersAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.categoryID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'tradingModeID', fields.tradingModeID);
	adapter.addField(rules.id, 'channelID', fields.channelID);

	return adapter;
}
