import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';

const { isValidID, isValidString, isValidArray } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	sportID       : isValidID,
	tradingModeID : isValidID,
	name          : isValidString,
	groupIDs      : isValidArray,
	// advanced
	marketID      : isValidID,
	marginPrematch: isValidID,
	marginLive    : isValidID,
	statusID      : isValidID,
    
	periodName      : isValidString,
	code            : isValidString,
	altName1        : isValidString,
	altName2        : isValidString,
	selectionName   : isValidString,
	altSelectionName: isValidString,

	restrictionsGroupIDs: isValidArray,
	combinationsMinMax  : isValidArray,
	periodMinute        : isValidArray,
};

export const defaultValuesMarketTemplatesFilter = {
	sportID: 150592,
};