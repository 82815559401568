const actions = {

	DASHBOARD_MARKETING_DATA_RELOAD     : 'DASHBOARD_MARKETING_DATA_RELOAD',
	DASHBOARD_MARKETING_DATA_REFRESH    : 'DASHBOARD_CHART_DATA_REFRESH',
	DASHBOARD_MARKETING_CHART_UI_REFRESH: 'DASHBOARD_MARKETING_CHART_UI_REFRESH',

	chartDataRefresh: data => ({
		type: actions.DASHBOARD_MARKETING_DATA_REFRESH,
		data,
	}),
	chartDataReload: () => ({
		type: actions.DASHBOARD_MARKETING_DATA_RELOAD,
	}),
	chartUIRefresh: data => ({
		type: actions.DASHBOARD_MARKETING_CHART_UI_REFRESH,
		data,
	}),
};


export default actions;
