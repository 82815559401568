import styled from 'styled-components';

const Wrapper = styled.div`
    & { 
        display: flex;
        align-items: center;
    }

    .control {
        width: 120px;
        & {
          .ant-select-disabled {
            & {
              .ant-select-selection {
                cursor: not-allowed;
                background: #f5f5f5;
              }
              .ant-select-selection:hover {
                border : 1px solid #e9e9e9;
                
              }
          }
        }
    }
`;

export const LangOption = styled.div`
  & {
    display: flex;
    align-items: center;
  }
  
  & img {
    margin-right: 10px;
  }
`;

export const TextLang = styled.span`
   & {
     width: 80%;
     overflow: hidden;
     text-overflow: ellipsis;
   }
`;

export default Wrapper;
