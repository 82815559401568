import React from 'react';
import { defaultPropsIcons, propTypesIcons } from './utils';

export const BonusIcon = (props) => {
	const { color, width,  height, viewBox } = props;
	return (
		<svg {...props} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_61)">
				<path {...props} fillRule="evenodd" clipRule="evenodd" d="M12.714 7.99998C12.714 5.40055 10.5992 3.2858 7.99982 3.2858C1.75441 3.52276 1.75611 12.4781 7.99988 12.7142C10.5992 12.7142 12.714 10.5994 12.714 7.99998ZM6.02421 11.3333H9.97518C10.2496 11.3333 10.472 11.1109 10.472 10.8365V8.35218H5.52738V10.8365C5.52738 11.1109 5.74984 11.3333 6.02421 11.3333ZM5.21859 8.0978V7.43092C5.21859 7.15654 5.44105 6.93408 5.71543 6.93408H7.19103C6.50006 6.93408 5.50676 6.26372 5.7245 5.55287C5.90621 4.95903 6.45149 4.65869 7.00894 4.66217C7.79593 4.66664 7.99976 6.31626 7.99976 6.31626H8.02088C8.02088 6.31626 8.22483 4.66664 9.01169 4.66217C9.56902 4.65869 10.1144 4.95903 10.2961 5.55287C10.5138 6.26372 9.52058 6.93408 8.82985 6.93408H10.2842C10.5586 6.93408 10.7811 7.15654 10.7811 7.43092V8.0978H5.21859ZM9.59038 5.26173C9.59038 5.26173 9.56604 6.07356 8.47511 6.67982C8.47511 6.67982 8.6813 5.63758 9.59038 5.26173ZM6.43944 5.26173C7.34828 5.63746 7.55435 6.67982 7.55435 6.67982C6.46367 6.07356 6.43944 5.26173 6.43944 5.26173Z" fill={color} />
				<path {...props} d="M7.52839 2.36278V0C5.72141 0.104089 4.02764 0.805717 2.67627 2.00978L4.34899 3.6825C5.2224 2.94278 6.32222 2.46275 7.52839 2.36278Z" fill={color} />
				<path {...props} d="M2.36278 8.47141H0C0.104089 10.2784 0.805717 11.9721 2.00978 13.3235L3.6825 11.6508C2.94278 10.7774 2.46275 9.67758 2.36278 8.47141Z" fill={color} />
				<path {...props} d="M3.6825 4.34917L2.00978 2.67645C0.805717 4.02782 0.104121 5.72159 0 7.52857H2.36278C2.46275 6.3224 2.94278 5.22258 3.6825 4.34917Z" fill={color} />
				<path {...props} d="M13.3233 2.00978C11.9719 0.805717 10.2782 0.104121 8.47119 0V2.36278C9.67736 2.46278 10.7771 2.94278 11.6506 3.68247L13.3233 2.00978Z" fill={color} />
				<path {...props} d="M13.6371 7.52857H15.9999C15.8957 5.72159 15.1942 4.02782 13.9901 2.67645L12.3174 4.34914C13.0571 5.22258 13.5371 6.3224 13.6371 7.52857Z" fill={color} />
				<path {...props} d="M8.47119 13.6372V16C10.2781 15.8959 11.9719 15.1943 13.3233 13.9902L11.6506 12.3175C10.7771 13.0572 9.67736 13.5372 8.47119 13.6372Z" fill={color} />
				<path {...props} d="M2.67627 13.9902C4.02764 15.1943 5.72141 15.8959 7.52839 16V13.6372C6.32222 13.5372 5.2224 13.0572 4.34899 12.3175L2.67627 13.9902Z" fill={color} />
				<path {...props} d="M12.3174 11.6508L13.9901 13.3235C15.1942 11.9721 15.8958 10.2784 15.9999 8.47141H13.6371C13.5371 9.67758 13.0571 10.7774 12.3174 11.6508Z" fill={color} />
			</g>
			<defs>
				<clipPath id="clip0_103_61">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
BonusIcon.propTypes = propTypesIcons;

BonusIcon.defaultProps = defaultPropsIcons;
