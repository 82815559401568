import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';

const { isValidID, isValidArray, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	id          : isValidID,
	typeID      : isValidID,
	personalID  : isValidID,
	userID      : isValidID,
	bonusTypeID : isValidID,
	currencyID  : isValidID,
	casinoGameID: isValidID,
	created     : isValidDateRange,
	claimDate   : isValidDateRange,
	betAmount   : isValidArray,	
};


export const fields = {
	id          : 'id',               // * number;
	typeID      : 'type_id',          // * CasinoExtraSpinType;
	personalID  : 'personal_id',      // * number;
	created     : 'created',          // Date;
	claimDate   : 'claim_date',          // Date;
	userID      : 'user_id',          // * number;
	bonusTypeID : 'bonus_type_id',    // * BonusType;
	betAmount   : 'bet_amount',       // number;
	currencyCode: 'currency_code',    // string;

	spinsTotal    : 'spins_total',      // number;
	leftSpins     : 'left_spins',       // number;
	casinoGameName: 'casino_game_name', // string;
	//filter

	claimDateFrom: 'claim_date_from',	// Date;
	claimDateTo  : 'claim_date_to',		// Date;
	dateFrom     : 'date_from',        // Date;
	dateTo       : 'date_to',          // Date;
	betAmountFrom: 'bet_amount_from',  // number;
	betAmountTo  : 'bet_amount_to',    // number;
	currencyID   : 'currency_id',      // number;
	casinoGameID : 'casino_game_id',   // number;
	statusID     : 'status_id',
	adminID      : 'admin_id',
	cancelDate   : 'cancel_date',
};

const listAdapter = createListAdapter();

export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'typeID', fields.typeID);
	builder.addField(rules.isID, 'personalID', fields.personalID);
	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'bonusTypeID', fields.bonusTypeID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);
	builder.addField(rules.isID, 'casinoGameID', fields.casinoGameID);
	
	builder.addRangeField(rules.isDateTimeRange, filter.created, [
		fields.dateFrom,
		fields.dateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.claimDate, [
		fields.claimDateFrom,
		fields.claimDateTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.betAmount, [
		fields.betAmountFrom,
		fields.betAmountTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptFreeSpinsList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return adaptedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'personalID', fields.personalID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'bonusTypeID', fields.bonusTypeID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'adminID', fields.adminID);

	adapter.addField(rules.string, 'currencyCode', fields.currencyCode);
	adapter.addField(rules.string, 'casinoGameName', fields.casinoGameName);

	adapter.addField(rules.number, 'betAmount', fields.betAmount);
	adapter.addField(rules.number, 'spinsTotal', fields.spinsTotal);
	adapter.addField(rules.number, 'leftSpins', fields.leftSpins);

	adapter.addField(rules.fullDate, 'created', fields.created);
	adapter.addField(rules.fullDate, 'claimDate', fields.claimDate);
	adapter.addField(rules.dateTime, 'cancelDate', fields.cancelDate);
	
	return adapter;
}

export function updateBonusFreeSpinList({ list = {}, spinID, loading, statusID, adminID, cancelDate }) {
	const clonedList = cloneDeep(list);

	const index = findIndex(clonedList, { id: spinID });

	if (index > -1) {
		const item = clonedList[index];

		clonedList[index] = {
			...item,
			loading,
			...(statusID && { statusID }),
			...(adminID && { adminID }),
			...(cancelDate && { cancelDate }),
		};

		return clonedList;
	}

	return clonedList;
}
