const prefix = 'PARTNER_MODAL_COUNTRIES_';

export const actions = {

	STORE_RESET: `${prefix}STORE_RESET`,

	BASE_DATA_REFRESH: `${prefix}BASE_DATA_REFRESH`,
	UI_REFRESH       : `${prefix}UI_REFRESH`,
	DATA_RELOAD      : `${prefix}DATA_RELOAD`,

	baseDataReload: () => ({
		type: actions.DATA_RELOAD,
		data: {},
	}),

	baseDataRefresh: data => (
		{
			type: actions.BASE_DATA_REFRESH,
			data,
		}
	),

	uiRefresh: data => (
		{
			type: actions.UI_REFRESH, data,
		}
	),
	resetStore: () => (
		{
			type: actions.STORE_RESET,

		}
	),


};
