import { combineReducers } from 'redux';

import FreeSpins from './freeSpins/reducer';
import CashbackUsers from './cashbackUsers/reducer';
import FreeBets from './freeBets/reducer';

export default combineReducers({
	FreeSpins,
	CashbackUsers,
	FreeBets,
});
