import {
	CHANNEL_TYPES,
	SOURCE_TYPES,
	YES_NO,
	TRADING_MODE,
	BET_TYPES,
	DEPOSIT_BONUS_TYPES,
	DEPOSIT_TYPES,
	DEPOSIT_STATUS,
	WITHDRAWAL_STATUS,
	WITHDRAWAL_TYPES,
	WITHDRAWAL_ELIGIBILITY,
	WITHDRAWAL_DECISION,
	ACTIVE_STATUS,
	INFO_PAGE_TYPES,
	LINK_ACTION_TYPES,
	BET_STATUS,
	WEBSITE_STATUS,
	WEBSITE_PAYMENTS_STATUS,
	LANGUAGES,
	BANNER_POSITIONS,
	PRODUCTS,
	EMAIL_ACTIONS,
	CASINO_API_VERSIONS,
	CASINO_API_HOSTS,
	WEBSITE_CASINO_STATUS,
	CASINO_GAMES_CATEGORY,
	CASINO_GAMES_WEIGHT,
	TRANSACTION_TYPES,
	PAYWAY_STATUS,
	EMAIL_CATEGORY,
	EMAIL_STATUS,
	USER_DOCUMENT_TYPES,
	USER_DOCUMENT_REVIEW_STATUS,
	WEBSITE_SCRIPT_POSITION,
	MONEY_TYPES,
	CASINO_GAMES_STATUS,
	CASINO_PROVIDERS,
	BET_PROVIDERS,
	TRANSACTION_STATUSES,
	DASHBOARD_PERIOD_MODE,
	CASINO_BET_STATUS,
	CASINO_BET_TYPES,
	TRANSACTION_SOURCES,
	BANNER_USER_STATUS,
	PROMOTION_STATUS,
	WEBSITE_MAILING_PROTOCOL,
	WEBSITE_VERIFICATION,
	SUB_CATEGORY_STATUS,
	RISK_MANAGEMENT_PRIORITY_TYPES,
	RISK_MANAGEMENT_RISK_TYPES,
	RISK_MANAGEMENT_COMMENT,
	GAME_LIST_LOAD_TYPES,
	PARTNER_FILTER_STATUS,
	SMS_STATUS,
	WEBSITE_SCRIPT_COOKIE_TYPE, POINT_GENERATION_RULE_TYPE, POINT_GENERATION_LOGIC_TYPE,
} from './commonConstants';
import { BONUS_TYPES } from '../constants/bonuses';
import { NOTIFICATION_SEND_TYPES } from '../constants/pushNotifications';
import config from '../containers/Topbar/LanguageSwitcher/config';

// languages used for user interface of Admin site
export const languagesList = [
	{ id: LANGUAGES.en, name: 'English' },
	{ id: LANGUAGES.ru, name: 'Russian' },
	{ id: LANGUAGES.ua, name: 'Ukrainian' },
];

export const gameListLoadTypes = [
	{ id: GAME_LIST_LOAD_TYPES.infinitiveScroll,  name: 'Infinitive scroll' },
	{ id: GAME_LIST_LOAD_TYPES.loadMore,          name: 'Load more' },
];

// languages used for menu in database (lang_id)
export const contentLanguageList = [
	{ id: LANGUAGES.en, name: 'English' },
	{ id: LANGUAGES.ru, name: 'Russian' },
	{ id: LANGUAGES.ua, name: 'Ukrainian' },
	{ id: LANGUAGES.tr, name: 'Turkish' },
	{ id: LANGUAGES.de, name: 'German' },
];
export const langList = config.options.map(item => ({ id: item.dataBaseLangID, name: item.text }));
// user statuses for banners
export const bannerUserStatusList = [
	{ id: BANNER_USER_STATUS.LOGGEDIN, name: 'Logged In' },
	{ id: BANNER_USER_STATUS.FIRST_TIME_VISITOR, name: 'First Time Visitor' },
	{ id: BANNER_USER_STATUS.RETURNING_VISITOR, name: 'Returning Visitor' },
	{ id: BANNER_USER_STATUS.LOGGEDIN_RETURNING_VISITOR, name: 'Logged in Returning Visitor' },
];

export const channelTypeList = [
	{ id: CHANNEL_TYPES.web, name: 'Web' },
	{ id: CHANNEL_TYPES.mobile, name: 'Mobile' },
	{ id: CHANNEL_TYPES.tablet, name: 'Tablet' },
	{ id: CHANNEL_TYPES.desktop, name: 'Desktop' },
	{ id: CHANNEL_TYPES.backend, name: 'Backend' },
];

export const channelTypeListByID = [
	{ channelID: CHANNEL_TYPES.web },
	{ channelID: CHANNEL_TYPES.mobile },
	{ channelID: CHANNEL_TYPES.tablet },
	{ channelID: CHANNEL_TYPES.desktop },
	{ channelID: CHANNEL_TYPES.backend },
];

export const tradingModeList = [
	{ id: TRADING_MODE.both, name: 'Both' },
	{ id: TRADING_MODE.prematch, name: 'Prematch' },
	{ id: TRADING_MODE.live, name: 'In-Play' },
];

export const tradingModeSportRiskList = [
	{ id: TRADING_MODE.both, name: 'All' },
	{ id: TRADING_MODE.prematch, name: 'Prematch' },
	{ id: TRADING_MODE.live, name: 'In-Play' },
];


export const sourceTypeList = [
	{ id: SOURCE_TYPES.feed,  name: 'Feed' },
	{ id: SOURCE_TYPES.admin, name: 'Admin' },
	{ id: SOURCE_TYPES.casino,  name: 'Casino' },
	{ id: SOURCE_TYPES.paymentSystem, name: 'Payment System' },
	{ id: SOURCE_TYPES.system, name: 'System' },
];

export const betsSourceTypeList = [
	{ id: SOURCE_TYPES.feed,  name: 'Feed' },
	{ id: SOURCE_TYPES.admin, name: 'Admin' },
];

export const generalStatusList = [
	{ id: 1, name: 'Active' },
	{ id: 2, name: 'Inactive' },
	{ id: 3, name: 'Archived' },
];

export const displayStatusList = [
	{ id: 1, name: 'Visible' },
	{ id: 2, name: 'Invisible' },
	{ id: 3, name: 'Partly visible' },
];

export const tradingStatusList = [
	{ id: 1, name: 'Active' },
	{ id: 2, name: 'Suspended' },
];

export const genderList = [{ id: 1, name: 'Male' }, { id: 2, name: 'Female' }];

export const limitTypesList = [
	{ id: 1, name: 'Absolute' },
	{ id: 2, name: 'Percent' },
];

export const promotionStatuses = [
	{ id: PROMOTION_STATUS.active, name: 'Active' },
	{ id: PROMOTION_STATUS.inactive, name: 'Inacive' },
];

export const timesAcceptBetsList = [
	{ id: 1, name: 'minutes' },
	{ id: 2, name: 'hours' },
	{ id: 3, name: 'days' },
	{ id: 4, name: 'weeks' },
	{ id: 6, name: 'always' },
];

export const timesDinamicLimitsList = [
	{ id: 1, name: 'minutes' },
	{ id: 2, name: 'hours' },
	{ id: 3, name: 'days' },
];

export const tournamentTypesList = [
	{ id: 1, name: 'Classic Tournament' },
	{ id: 2, name: 'Cup Tournament' },
	{ id: 3, name: 'Race' },
	{ id: 4, name: 'One event' },
	{ id: 5, name: 'Other (input box)' },
];

export const yesNoList = [
	{ id: YES_NO.yes, name: 'Yes' },
	{ id: YES_NO.no, name: 'No' },
];

export const betTypesList = [
	{ id: BET_TYPES.single,  name: 'Single' },
	{ id: BET_TYPES.express, name: 'Multibet' },
	{ id: BET_TYPES.system,  name: 'System' },
	{ id: BET_TYPES.special_offer, name: 'Special Offer' },
];

export const betSettlementStatusList = [
	{ id: 1, name: 'Win' },
	{ id: 2, name: 'Lost' },
	{ id: 3, name: 'Cancel' },
	{ id: 4, name: 'Void' },
	{ id: 5, name: 'Partial win' },
	{ id: 6, name: 'Partial lost' },
];

export const depositBonusTypeList = [
	{ id: DEPOSIT_BONUS_TYPES.registrationBonus, name: 'Registration Bonus' },
	{ id: DEPOSIT_BONUS_TYPES.depositBonus, name: 'Deposit Bonus' },
	{ id: DEPOSIT_BONUS_TYPES.gamingBonus, name: 'Gaming Bonus' },
	{ id: DEPOSIT_BONUS_TYPES.specialOfferBonus, name: 'Special Offer Bonus' },
	{ id: DEPOSIT_BONUS_TYPES.manualAdjustment, name: 'Manual Adjustment' },
	{ id: DEPOSIT_BONUS_TYPES.other, name: 'Other' },
];

export const depositTypeList = [
	{ id: DEPOSIT_TYPES.paymentSystem, name: 'Payment System' },
	{ id: DEPOSIT_TYPES.manual, name: 'Manual' },
	{ id: DEPOSIT_TYPES.bonus, name: 'Bonus' },
	{ id: DEPOSIT_TYPES.transferFromOtherUser, name: 'Transferred from other customer' },
	{ id: DEPOSIT_TYPES.cashback, name: 'Cashback' },
];

export const depositStatusList = [
	{ id: DEPOSIT_STATUS.success, name: 'Success' },
	{ id: DEPOSIT_STATUS.pending, name: 'Pending' },
	{ id: DEPOSIT_STATUS.failed, name: 'Failed' },
	{ id: DEPOSIT_STATUS.inReview, name: 'In Admin Review' },
	{ id: DEPOSIT_STATUS.cancelled, name: 'Cancelled' },
	{ id: DEPOSIT_STATUS.cancelledByAdmin, name: 'Cancelled by Admin' },
	{ id: DEPOSIT_STATUS.refund, name: 'Refund' },
	{ id: DEPOSIT_STATUS.refunding, name: 'Refunding' },
	{ id: DEPOSIT_STATUS.expired, name: 'Expired' },
	{ id: DEPOSIT_STATUS.processing, name: 'Processing' },
	{ id: DEPOSIT_STATUS.partialRefund, name: 'Partial Refund' },
	{ id: DEPOSIT_STATUS.approvedByAdmin, name: 'Approved by Admin' },
];

export const withdrawalTypeList = [
	{ id: WITHDRAWAL_TYPES.paymentSystem, name: 'Payment System' },
	{ id: WITHDRAWAL_TYPES.manual, name: 'Manual' },
	{ id: WITHDRAWAL_TYPES.bonus, name: 'Bonus' },
	{
		id  : WITHDRAWAL_TYPES.transferFromOtherUser,
		name: 'Transferred to other customer',
	},
];

export const withdrawalStatusList = [
	{ id: WITHDRAWAL_STATUS.success, name: 'Success' },
	{ id: WITHDRAWAL_STATUS.pending, name: 'Pending' },
	{ id: WITHDRAWAL_STATUS.failed, name: 'Failed' },
	{ id: WITHDRAWAL_STATUS.inReview, name: 'In Admin Review' },
	{ id: WITHDRAWAL_STATUS.cancelled, name: 'Cancelled' },
	{ id: WITHDRAWAL_STATUS.cancelledByAdmin, name: 'Cancelled by Admin' },
	{ id: WITHDRAWAL_STATUS.refund, name: 'Refund' },
	{ id: WITHDRAWAL_STATUS.refunding, name: 'Refunding' },
	{ id: WITHDRAWAL_STATUS.expired, name: 'Expired' },
	{ id: WITHDRAWAL_STATUS.processing, name: 'Processing' },
	{ id: WITHDRAWAL_STATUS.partialRefund, name: 'Partial Refund' },
	{ id: WITHDRAWAL_STATUS.approvedByAdmin, name: 'Approved by Admin' },
];

export const withdrawalEligibilityList = [
	{ id: WITHDRAWAL_ELIGIBILITY.yes, name: 'Yes' },
	{ id: WITHDRAWAL_ELIGIBILITY.no, name: 'No' },
	{ id: WITHDRAWAL_ELIGIBILITY.auto, name: 'Auto' },
];

export const withdrawalDecisionList = [
	{ id: WITHDRAWAL_DECISION.approved, name: 'Approved' },
	{ id: WITHDRAWAL_DECISION.rejected, name: 'Rejected' },
];

export const activeStatusList = [
	{ id: ACTIVE_STATUS.active, name: 'Active' },
	{ id: ACTIVE_STATUS.inactive, name: 'Inactive' },
];

export const infoPageTypeList = [
	{ id: INFO_PAGE_TYPES.internal, name: 'Internal' },
	{ id: INFO_PAGE_TYPES.external, name: 'External' },
];

export const betStatusList = [
	{ id: BET_STATUS.open, name: 'Open' },
	{ id: BET_STATUS.lost, name: 'Lost' },
	{ id: BET_STATUS.partialLost, name: 'Partial lost' },
	{ id: BET_STATUS.win, name: 'Win' },
	{ id: BET_STATUS.partialWin, name: 'Partial win' },
	{ id: BET_STATUS.cancelled, name: 'Cancelled' },
	{ id: BET_STATUS.void, name: 'Void' },
	{ id: BET_STATUS.inAdminReview, name: 'In Admin review' },
	{ id: BET_STATUS.inUserReview, name: 'In User review' },
	{ id: BET_STATUS.adminRejected, name: 'Admin rejected' },
	{ id: BET_STATUS.userRejected, name: 'User rejected' },
	{ id: BET_STATUS.autoRejected, name: 'Auto rejected' },
];

export const linkActionTypeList = [
	{ id: LINK_ACTION_TYPES.self, name: 'Self' },
	{ id: LINK_ACTION_TYPES.blank, name: 'Blank' },
];

export const websiteStatusList = [
	{ id: WEBSITE_STATUS.active, name: 'Active' },
	{ id: WEBSITE_STATUS.blocked, name: 'Blocked' },
];

export const subCategoryList = [
	{ id: SUB_CATEGORY_STATUS.both, name: 'Both' },
	{ id: SUB_CATEGORY_STATUS.provider, name: 'Provider' },
	{ id: SUB_CATEGORY_STATUS.customProvider, name: 'Custom Provider' },
];


export const websiteVerificationList = [
	{ id: WEBSITE_VERIFICATION.none,        name: 'None' },
	{ id: WEBSITE_VERIFICATION.deposit,     name: 'Deposit' },
	{ id: WEBSITE_VERIFICATION.withdrawal,  name: 'Withdrawal' },
];

export const websiteProtocolList = [
	{ id: WEBSITE_MAILING_PROTOCOL.none, name: 'None' },
	{ id: WEBSITE_MAILING_PROTOCOL.ssl, name: 'SSL' },
	{ id: WEBSITE_MAILING_PROTOCOL.tls, name: 'TLS' },
];

export const websitePaymentsStatusList = [
	{ id: WEBSITE_PAYMENTS_STATUS.active, name: 'Active' },
	{ id: WEBSITE_PAYMENTS_STATUS.blocked, name: 'Blocked' },
];

export const bannerPositionsList = [
	{ id: BANNER_POSITIONS.top, name: 'Top' },
	{ id: BANNER_POSITIONS.bottom, name: 'Bottom' },
	{ id: BANNER_POSITIONS.left, name: 'Left' },
	{ id: BANNER_POSITIONS.right, name: 'Right' },
	{ id: BANNER_POSITIONS.center, name: 'Center' },
];

export const productsList = [
	{ id: PRODUCTS.sport, name: 'Sport' },
	{ id: PRODUCTS.system, name: 'System' },
	{ id: PRODUCTS.casino, name: 'Casino' },
];

export const emailActionsList = [
	{ id: EMAIL_ACTIONS.verifyEmail, name: 'Verify Email' },
	{ id: EMAIL_ACTIONS.emailVerified, name: 'Email Verified' },
	{ id: EMAIL_ACTIONS.forgetPasswordRequest, name: 'Forget Password Request' },
	{ id: EMAIL_ACTIONS.forgetPasswordComplete, name: 'Forget Password Complete' },
	{ id: EMAIL_ACTIONS.changePasswordComplete, name: 'Change Password Complete' },
	{ id: EMAIL_ACTIONS.newIPLogin, name: 'New IP Login' },
	{ id: EMAIL_ACTIONS.withdrawNotApproved, name: 'Withdraw Not Approved' },
	{ id: EMAIL_ACTIONS.pendingWithdraw, name: 'Pending Withdraw' },
	{ id: EMAIL_ACTIONS.approvedWithdraw, name: 'Approved Withdraw' },
	{ id: EMAIL_ACTIONS.withdrawCancellation, name: 'Withdraw Cancellation' },
	{ id: EMAIL_ACTIONS.bonus, name: 'Bonus' },
	{ id: EMAIL_ACTIONS.promoCode, name: 'Promo Code' },
	{ id: EMAIL_ACTIONS.verificationKey, name: 'Verification Key' },
	{ id: EMAIL_ACTIONS.welcomeMessage, name: 'Welcome Message' },
	{ id: EMAIL_ACTIONS.changeEmail, name: 'Change Email' },
	{ id: EMAIL_ACTIONS.registrationFreeSpinBonus, name: 'Registration Free Spin Bonus' },
	{ id: EMAIL_ACTIONS.freeSpin, name: '${freeSpin_count} Free Spins' },
	{ id: EMAIL_ACTIONS.percentFirstDepositBonus, name: '${percent}% First deposit bonus' },
	{ id: EMAIL_ACTIONS.setPartnerPassword, name: 'SET PARTNER PASSWORD' },
	{ id: EMAIL_ACTIONS.checkEmailTest, name: 'CHECK EMAIL TEST' },
	{ id: EMAIL_ACTIONS.suspendPartner, name: 'SUSPEND PARTNER' },
];

export const casinoApiVersionList = [
	{ id: CASINO_API_VERSIONS.one, name: 'Version 1' },
	{ id: CASINO_API_VERSIONS.two, name: 'Version 2' },
];

export const casinoApiHostList = [
	{ id: CASINO_API_HOSTS.europe, name: 'Europe' },
	{ id: CASINO_API_HOSTS.asia, name: 'Asia' },
];

export const websiteCasinoStatusList = [
	{ id: WEBSITE_CASINO_STATUS.inactive, name: 'Inactive' },
	{ id: WEBSITE_CASINO_STATUS.active, name: 'Active' },
];

export const casinoGameCategoryList = [
	{ id: CASINO_GAMES_CATEGORY.all, name: 'All' },
	{ id: CASINO_GAMES_CATEGORY.slots, name: 'Slots' },
	{ id: CASINO_GAMES_CATEGORY.tableGames, name: 'Table Games' },
];

export const casinoGameWeightsList = [
	{ id: CASINO_GAMES_WEIGHT.small, name: 'Small (1 x 1)' },
	{ id: CASINO_GAMES_WEIGHT.medium, name: 'Medium (1 x 2)' },
	{ id: CASINO_GAMES_WEIGHT.big, name: 'Big (4 x 4)' },
];

export const transactionTypeList = [
	{ id: TRANSACTION_TYPES.all,               name: 'All' },
	{ id: TRANSACTION_TYPES.bet,               name: 'Bet' },
	{ id: TRANSACTION_TYPES.withdraw,          name: 'Withdraw' },
	{ id: TRANSACTION_TYPES.manual,            name: 'Manual' },
	{ id: TRANSACTION_TYPES.deposit,           name: 'Deposit' },
	{ id: TRANSACTION_TYPES.userToUser,        name: 'User-to-User' },
	{ id: TRANSACTION_TYPES.bonus,             name: 'Bonus' },
	{ id: TRANSACTION_TYPES.betReverse,        name: 'Bet Reverse' },
	{ id: TRANSACTION_TYPES.wonReverse,        name: 'Won Reverse' },
	{ id: TRANSACTION_TYPES.won,               name: 'Won' },
	{ id: TRANSACTION_TYPES.balanceAdjustment, name: 'Balance Adjustment' },
	{ id: TRANSACTION_TYPES.cashback,          name: 'Cashback' },
];

export const transactionStatusList = [
	{ id: TRANSACTION_STATUSES.success,        name: 'Success' },
	{ id: TRANSACTION_STATUSES.pending,        name: 'Pending' },
	{ id: TRANSACTION_STATUSES.failed,         name: 'Failed' },
	{ id: TRANSACTION_STATUSES.inReview,       name: 'In Review' },
	{ id: TRANSACTION_STATUSES.cancelled,      name: 'Cancelled' },
	{ id: TRANSACTION_STATUSES.cancelledByAdmin, name: 'Cancelled By Admin' },
	{ id: TRANSACTION_STATUSES.refund,         name: 'Refund' },
	{ id: TRANSACTION_STATUSES.refunding,      name: 'Refunding' },
	{ id: TRANSACTION_STATUSES.expired,        name: 'Expired' },
	{ id: TRANSACTION_STATUSES.processing,     name: 'Processing' },
	{ id: TRANSACTION_STATUSES.partialRefund,  name: 'Partial Refund' },
	{ id: TRANSACTION_STATUSES.approvedByAdmin, name: 'Approved by Admin' },
];

export const paywayStatusList = [
	{ id: PAYWAY_STATUS.visible,  name: 'Visible' },
	{ id: PAYWAY_STATUS.hidden,   name: 'Hidden' },
];

export const emailCategoryList = [
	{ id: EMAIL_CATEGORY.inbox,     name: 'Inbox' },
	{ id: EMAIL_CATEGORY.draft,     name: 'Draft' },
	{ id: EMAIL_CATEGORY.spam,      name: 'Spam' },
	{ id: EMAIL_CATEGORY.sent,      name: 'Sent' },
	{ id: EMAIL_CATEGORY.trash,     name: 'Trash' },
	{ id: EMAIL_CATEGORY.important, name: 'Important' },
];

export const emailStatusList = [
	{ id: EMAIL_STATUS.inProcess, name: 'In Process' },
	{ id: EMAIL_STATUS.success,   name: 'Success' },
	{ id: EMAIL_STATUS.error,     name: 'Error' },
];

export const SMSStatusList = [
	{ id: SMS_STATUS.error, name: 'Failed' },
	{ id: SMS_STATUS.success, name: 'Success' },
];

export const invertedSMSStatus = {
	[SMS_STATUS.error]  : 'common.failed',
	[SMS_STATUS.success]: 'common.success',
	[SMS_STATUS.pending]: 'common.pending',
};

export const userDocumentTypesList = [
	{ id: USER_DOCUMENT_TYPES.internationalPassport, name: 'International Passport' },
	{ id: USER_DOCUMENT_TYPES.nationalPassport,      name: 'National Passport' },
	{ id: USER_DOCUMENT_TYPES.drivingLicense,        name: 'Driving License' },
	{ id: USER_DOCUMENT_TYPES.bill,                  name: 'Bill' },
	{ id: USER_DOCUMENT_TYPES.other,                 name: 'Other' },
	{ id: USER_DOCUMENT_TYPES.selfie,                name: 'Selfie' },
];

export const userDocumentStatusList = [
	{ id: USER_DOCUMENT_REVIEW_STATUS.awaitingReview, name: 'Awaiting Review' },
	{ id: USER_DOCUMENT_REVIEW_STATUS.accepted,       name: 'Accepted' },
	{ id: USER_DOCUMENT_REVIEW_STATUS.declined,       name: 'Declined' },
];

export const websiteScriptPositionList = [
	{ id: WEBSITE_SCRIPT_POSITION.head, name: 'Head' },
	{ id: WEBSITE_SCRIPT_POSITION.body, name: 'Body' },
];

export const websiteScriptCookiesTypeList = [
	{ id: WEBSITE_SCRIPT_COOKIE_TYPE.NONE, name: 'None' },
	{ id: WEBSITE_SCRIPT_COOKIE_TYPE.STRICTLY_NECESSARY, name: 'Strictly Necessary' },
	{ id: WEBSITE_SCRIPT_COOKIE_TYPE.PERFORMANCE_COOKIE, name: 'Performance Cookies' },
	{ id: WEBSITE_SCRIPT_COOKIE_TYPE.FUNCTIONAL_COOKIES, name: 'Functional Cookies' },
	{ id: WEBSITE_SCRIPT_COOKIE_TYPE.TARGETING_COOKIES, name: 'Targeting Cookies' },
];

export const moneyTypesList = [
	{ id: MONEY_TYPES.real,    name: 'Real' },
	{ id: MONEY_TYPES.virtual, name: 'Virtual' },
];

export const casinoGamesStatusList = [
	{ id: CASINO_GAMES_STATUS.active,   name: 'Active' },
	{ id: CASINO_GAMES_STATUS.inactive, name: 'Inactive' },
];

export const casinoGamesStatusListEntities = {
	[CASINO_GAMES_STATUS.active]  : 'Active',
	[CASINO_GAMES_STATUS.inactive]: 'Inactive',
};


export const casinoProvidersList = [
	{ id: CASINO_PROVIDERS.EVOPLAY,         name: 'EvoPlay' },
	{ id: CASINO_PROVIDERS.BETGAMES,        name: 'BetGames' },
	{ id: CASINO_PROVIDERS.BETSOFT,         name: 'BetSoft' },
	{ id: CASINO_PROVIDERS.PLAYSON,         name: 'PlaySon' },
	{ id: CASINO_PROVIDERS.SPINOMENAL,      name: 'Spinomenal' },
	{ id: CASINO_PROVIDERS.IGROSOFT,        name: 'IgroSoft' },
	{ id: CASINO_PROVIDERS.GAMEART,         name: 'GameArt' },
	{ id: CASINO_PROVIDERS.HABANERO,        name: 'HabaNero' },
	{ id: CASINO_PROVIDERS.PLATIPUS,        name: 'Platipus' },
	{ id: CASINO_PROVIDERS.VIVOGAMING,      name: 'VivoGaming' },
	{ id: CASINO_PROVIDERS.TOMHORN,         name: 'Tomhorn' },
	{ id: CASINO_PROVIDERS.ENDORPHINA,      name: 'Endorphina' },
	{ id: CASINO_PROVIDERS.AMATIC,          name: 'Amatic' },
	{ id: CASINO_PROVIDERS.BOOONGO,         name: 'Booongo' },
	{ id: CASINO_PROVIDERS.MICROGAMING,     name: 'MicroGaming' },
	{ id: CASINO_PROVIDERS.BIG_TIME_GAMING, name: 'BigTimeGaming' },
	{ id: CASINO_PROVIDERS.GAMSHY,          name: 'Gamshy' },
	{ id: CASINO_PROVIDERS.MASCOT,          name: 'Mascot' },
	{ id: CASINO_PROVIDERS.PRAGMATIC,       name: 'Pragmatic' },
	{ id: CASINO_PROVIDERS.DLV,             name: 'DLV' },
	{ id: CASINO_PROVIDERS.B2BSlots,        name: 'B2B Slots' },
	{ id: CASINO_PROVIDERS.IGSlots,         name: 'IG Slots' },
	{ id: CASINO_PROVIDERS.AlgNet,          name: 'AlgNet' },
];

export const betProvidersList = [
	{ id: BET_PROVIDERS.all,          name: 'All providers' },
	{ id: BET_PROVIDERS.ladBrokers,   name: 'Lad Brokers' },
	{ id: BET_PROVIDERS.redKings,     name: 'Red Kings' },
	{ id: BET_PROVIDERS.myBet,        name: 'My Bet' },
	{ id: BET_PROVIDERS.sbTech,       name: 'Sb Tech' },
	{ id: BET_PROVIDERS.williamHills, name: 'William Hills' },
	{ id: BET_PROVIDERS.betFair,      name: 'Bet Fair' },
	{ id: BET_PROVIDERS.betWay,       name: 'Bet Way' },
	{ id: BET_PROVIDERS.coral,        name: 'Coral' },
	{ id: BET_PROVIDERS.pinnacle,     name: 'Pinnancle' },
	{ id: BET_PROVIDERS.bet365,       name: 'Bet 365' },
	{ id: BET_PROVIDERS.betSafe,      name: 'Bet Safe' },
	{ id: BET_PROVIDERS.bWin,         name: 'Bwin' },
	{ id: BET_PROVIDERS.sports888,    name: 'Sports 888' },
	{ id: BET_PROVIDERS.betAtHome,    name: 'Bet At Home' },
	{ id: BET_PROVIDERS.intertops,    name: 'Intertops' },
	{ id: BET_PROVIDERS.tonyBet,      name: 'Tony Bet' },
	{ id: BET_PROVIDERS.tipico,       name: 'Tipico' },
	{ id: BET_PROVIDERS.tenBet,       name: '10 Bet' },
	{ id: BET_PROVIDERS.arcaneBet,    name: 'Arcane Bet' },
	{ id: BET_PROVIDERS.tipBet,       name: 'Tip Bet' },
	{ id: BET_PROVIDERS.nordicBet,    name: 'Nordic Bet' },
	{ id: BET_PROVIDERS.betsson,      name: 'Betsson' },
	{ id: BET_PROVIDERS.betOnline,    name: 'Bet Online' },
	{ id: BET_PROVIDERS.oneXBet,      name: '1XBet' },
	{ id: BET_PROVIDERS.lsports,      name: 'LSports' },
];

export const dashboardPeriodModeList = [
	{ id: DASHBOARD_PERIOD_MODE.arbitrary,          name: 'Arbitrary' },
	{ id: DASHBOARD_PERIOD_MODE.lastWeek,           name: 'Last week' },
	{ id: DASHBOARD_PERIOD_MODE.lastMonth,          name: 'Last month' },
	{ id: DASHBOARD_PERIOD_MODE.lastThreeMonth,     name: 'Last 3 months' },
	{ id: DASHBOARD_PERIOD_MODE.lastYear,           name: 'Last year' },
	{ id: DASHBOARD_PERIOD_MODE.previousWeek,       name: 'Previous week' },
	{ id: DASHBOARD_PERIOD_MODE.previousMonth,      name: 'Previous month' },
	{ id: DASHBOARD_PERIOD_MODE.previousThreeMonth, name: 'Previous 3 months' },
	{ id: DASHBOARD_PERIOD_MODE.previousYear,       name: 'Previous year' },
];

export const casinoBetStatusList = [
	{ id: CASINO_BET_STATUS.active,     name: 'Active' },
	{ id: CASINO_BET_STATUS.won,        name: 'Won' },
	{ id: CASINO_BET_STATUS.lost,       name: 'Lost' },
	{ id: CASINO_BET_STATUS.betReverse, name: 'Bet Reverse' },
];

export const casinoBetTypeList = [
	{ id: CASINO_BET_TYPES.spin, name: 'Spin' },
	{ id: CASINO_BET_TYPES.freeSpin, name: 'Free Spin' },
	{ id: CASINO_BET_TYPES.reSpin, name: 'Re Spin' },
	{ id: CASINO_BET_TYPES.bonusSpin, name: 'Bonus Spin' },
	{ id: CASINO_BET_TYPES.tip, name: 'Tip' },
	{ id: CASINO_BET_TYPES.promo, name: 'Promo' },
];

export const PartnerFilterStatusList = [
	{ id: PARTNER_FILTER_STATUS.active, name: 'Active' },
	{ id: PARTNER_FILTER_STATUS.block,  name: 'Block' },
	{ id: PARTNER_FILTER_STATUS.delete, name: 'Deleted' },
];

export const bonusTypesList = [
	{ id: BONUS_TYPES.all,                  name: 'All' },
	{ id: BONUS_TYPES.depositBonus,         name: 'Deposit Bonus' },
	{ id: BONUS_TYPES.sportsBookBonus,      name: 'Sports Book Bonus' },
	{ id: BONUS_TYPES.welcome,              name: 'Welcome Bonus' },
	{ id: BONUS_TYPES.firstDeposit,         name: 'First Deposit' },
	{ id: BONUS_TYPES.freeSpin,             name: 'Free Spin' },
	{ id: BONUS_TYPES.birthday,             name: 'Birthday' },
	{ id: BONUS_TYPES.bonusSpin,            name: 'Bonus Spin' },
	{ id: BONUS_TYPES.registrationFreeSpin, name: 'Registration Free Spin' },
	{ id: BONUS_TYPES.balanceAdjustment,    name: 'Balance Adjustment' },
];

export const transactionSourceList = Object.keys(TRANSACTION_SOURCES).map(id => (
	{ id, name: TRANSACTION_SOURCES[id] }
));

export const verifiedList = [
	{ id: 1,  name: 'All' },
	{ id: 2,  name: 'Verified' },
	{ id: 3,  name: 'Not Verified' },
];

export const notificationSentTypeList = [
	{ id: NOTIFICATION_SEND_TYPES.generalNotifications,  name: 'General Notifications' },
	{ id: NOTIFICATION_SEND_TYPES.pushNotification,  name: 'Push notification' },
	{ id: NOTIFICATION_SEND_TYPES.messages,  name: 'Messages' },
	{ id: NOTIFICATION_SEND_TYPES.sendMessage,  name: 'SMS' },

];

export const riskManagementPriorityTypeList = [
	{ id: RISK_MANAGEMENT_PRIORITY_TYPES.low,    name: 'Low' },
	{ id: RISK_MANAGEMENT_PRIORITY_TYPES.medium, name: 'Medium' },
	{ id: RISK_MANAGEMENT_PRIORITY_TYPES.high,   name: 'High' },
];

export const riskManagementRiskTypeList = [
	{ id: RISK_MANAGEMENT_RISK_TYPES.maxDeposit,   name: 'Max Deposit' },
	{ id: RISK_MANAGEMENT_RISK_TYPES.maxWin,       name: 'Max Win' },
];

export const riskManagementCommentList = [
	{ id: RISK_MANAGEMENT_COMMENT.resolved,     name: 'Resolved' },
	{ id: RISK_MANAGEMENT_COMMENT.unResolved,   name: 'Unresolved' },
];

export const loyaltyTournamentsPointsRuleTypes	 = [
	{ id: POINT_GENERATION_RULE_TYPE.allStakes, name: 'All Stakes' },
	{ id: POINT_GENERATION_RULE_TYPE.winStakes, name: 'Win Stakes' },
];

export const loyaltyTournamentsPointsLogicTypes	 = [
	{ id: POINT_GENERATION_LOGIC_TYPE.totalSpins, name: 'Total Spins' },
	{ id: POINT_GENERATION_LOGIC_TYPE.totalSpent, name: 'Total Spent' },
];
