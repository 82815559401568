import { all, fork } from 'redux-saga/effects';

import banners from './banners/saga';
import landingPage from './landingPage/saga';
import menu from './menu/saga';
import pages from './pages/saga';

export default function* rootSaga() {
	yield all([
		fork(banners),
		fork(landingPage),
		fork(menu),
		fork(pages),
	]);
}
