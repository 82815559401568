import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { Table } from 'antd';
import { Wrapper, Empty } from './Table.style';
import Spinner from '../../Spinner/Spinner';


class CustomTable extends Component {

	static propTypes = {
		sortableColumns : PropTypes.arrayOf(PropTypes.string), // array of dataIndex of columns
		columns         : PropTypes.array,
		dataSource      : PropTypes.array,
		emptyText       : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		loading         : PropTypes.bool,
		SpinnerIndicator: PropTypes.object,
	};

	static defaultProps = {
		sortableColumns : [],
		columns         : [],
		dataSource      : [],
		emptyText       : '',
		loading         : false,
		SpinnerIndicator: null,
	};

	constructor(props) {
		super(props);
		this.makeSortableColumns = this.makeSortableColumns.bind(this);
	}

	makeSortableColumns(columns, sortableColumns) {

		sortableColumns.forEach(dataIndex => {
			const column = find(columns, { dataIndex });
			if (!column || column.sorter) {
				return;
			}
			column.sorter = (a, b) => sorter(a, b, dataIndex);
		});

		return columns;
	}

	render() {
		const { columns, sortableColumns, dataSource, emptyText, loading, SpinnerIndicator, ...restProps } = this.props;
		const showEmpty = !loading && (dataSource && !dataSource.length) && emptyText;

		const SpinnerIcon = SpinnerIndicator || <Spinner size="medium" />;
		if (showEmpty) {
			return (
				<Wrapper>
					<Empty>{emptyText}</Empty>
				</Wrapper>
			);
		}

		const actualColumns = (!sortableColumns.length)
			? this.makeSortableColumns(columns, sortableColumns)
			: columns;
		return (
			<Wrapper>
				<Table
					columns={actualColumns}
					dataSource={dataSource}
					// scroll={scroll}
					loading={{
						spinning : loading,
						indicator: SpinnerIcon,
					}}
					{...restProps}
				/>
			</Wrapper>
		);
	}
}

export default CustomTable;

function sorter(a, b, dataIndex) {

	const valueA = a[dataIndex];
	const valueB = b[dataIndex];

	if (valueA === valueB) {
		return 0;
	}

	return valueA > valueB ? 1 : -1;
}
