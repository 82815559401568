import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import EntityAdapter from '../../../helpers/entityAdapter';

const adapter = new EntityAdapter();

const fields = {
	id         : 'id',
	ip         : 'ip',
	action     : 'action',
	date       : 'date',
	type       : 'action_type',
	page       : 'page',
	description: 'action_description',
	result     : 'action_result',
	locationIP : 'location_ip',
};

export function adaptActivityList(rawList) {
	if (!isArray(rawList) || isEmpty(rawList)) {
		return [];
	}

	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'ip', fields.ip);
	adapter.addField(rules.string, 'action', fields.action);
	adapter.addField(rules.dateTime, 'date', fields.date);
	adapter.addField(rules.string, 'type', fields.type);
	adapter.addField(rules.string, 'page', fields.page);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'result', fields.result);
	adapter.addField(rules.string, 'locationIP', fields.locationIP);

	const adaptedList = adapter.adaptList(rawList);

	return adaptedList;
}
