const actions = {

	PARTICIPANT_BASE_DATA_RELOAD : 'PARTICIPANT_BASE_DATA_RELOAD',
	PARTICIPANT_BASE_DATA_REFRESH: 'PARTICIPANT_BASE_DATA_REFRESH',

	PARTICIPANT_NAMES_DATA_RELOAD : 'PARTICIPANT_NAMES_DATA_RELOAD',
	PARTICIPANT_NAMES_DATA_REFRESH: 'PARTICIPANT_NAMES_DATA_REFRESH',
	PARTICIPANT_CMS_DATA_RELOAD   : 'PARTICIPANT_CMS_DATA_RELOAD',
	PARTICIPANT_CMS_DATA_REFRESH  : 'PARTICIPANT_CMS_DATA_REFRESH',

	PARTICIPANT_MEMBERS_LIST_RELOAD         : 'PARTICIPANT_MEMBERS_LIST_RELOAD',
	PARTICIPANT_MEMBERS_LIST_REFRESH        : 'PARTICIPANT_MEMBERS_LIST_REFRESH',
	PARTICIPANT_MEMBERS_SELECTED_IDS_RELOAD : 'PARTICIPANT_MEMBERS_SELECTED_IDS_RELOAD',
	PARTICIPANT_MEMBERS_SELECTED_IDS_REFRESH: 'PARTICIPANT_MEMBERS_SELECTED_IDS_REFRESH',

	PARTICIPANT_UPDATE       : 'PARTICIPANT_UPDATE',
	PARTICIPANTS_LIST_REFRESH: 'PARTICIPANTS_LIST_REFRESH',

	PARTICIPANT_SET_MODAL_VALUE_UI: 'PARTICIPANT_SET_MODAL_VALUE_UI',
	PARTICIPANT_MODAL_UI_REFRESH  : 'PARTICIPANT_MODAL_UI_REFRESH',

	PARTICIPANT_COUNTRY_LIST_RELOAD : 'PARTICIPANT_COUNTRY_LIST_RELOAD',
	PARTICIPANT_COUNTRY_LIST_REFRESH: 'PARTICIPANT_COUNTRY_LIST_REFRESH',
	PARTICIPANT_LEAGUE_LIST_RELOAD  : 'PARTICIPANT_LEAGUE_LIST_RELOAD',
	PARTICIPANT_LEAGUE_LIST_REFRESH : 'PARTICIPANT_LEAGUE_LIST_REFRESH',
	PARTICIPANT_AGE_LIST_RELOAD     : 'PARTICIPANT_AGE_LIST_RELOAD',
	PARTICIPANT_AGE_LIST_REFRESH    : 'PARTICIPANT_AGE_LIST_REFRESH',

	PARTICIPANT_HISTORY_NAMES_DATA_RELOAD : 'PARTICIPANT_HISTORY_NAMES_DATA_RELOAD',
	PARTICIPANT_HISTORY_NAMES_DATA_REFRESH: 'PARTICIPANT_HISTORY_NAMES_DATA_REFRESH',

	baseDataReload: participantID => ({
		type: actions.PARTICIPANT_BASE_DATA_RELOAD,
		data: {
			participantID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.PARTICIPANT_BASE_DATA_REFRESH,
		data,
	}),
	listSortingRefresh: list => ({
		type: actions.PARTICIPANTS_LIST_REFRESH,
		data: list,
	}),
	historyNamesDataReload: (participantID) => ({
		type: actions.PARTICIPANT_HISTORY_NAMES_DATA_RELOAD,
		data: { participantID },
	}),
	historyNamesDataRefresh: data => ({
		type: actions.PARTICIPANT_HISTORY_NAMES_DATA_REFRESH,
		data,
	}),
	namesDataReload: (participantID) => ({
		type: actions.PARTICIPANT_NAMES_DATA_RELOAD,
		data: { participantID },
	}),
	namesDataRefresh: data => ({
		type: actions.PARTICIPANT_NAMES_DATA_REFRESH,
		data,
	}),
	cmsDataReload: participantID => ({
		type: actions.PARTICIPANT_CMS_DATA_RELOAD,
		data: {
			participantID,
		},
	}),
	cmsDataRefresh: data => ({
		type: actions.PARTICIPANT_CMS_DATA_REFRESH,
		data,
	}),

	membersListReload: () => ({
		type: actions.PARTICIPANT_MEMBERS_LIST_RELOAD,
	}),
	membersListRefresh: data => ({
		type: actions.PARTICIPANT_MEMBERS_LIST_REFRESH,
		data,
	}),
	membersSelectedIDsReload: participantID => ({
		type: actions.PARTICIPANT_MEMBERS_SELECTED_IDS_RELOAD,
		data: {
			participantID,
		},
	}),
	membersSelectedIDsRefresh: data => ({
		type: actions.PARTICIPANT_MEMBERS_SELECTED_IDS_REFRESH,
		data,
	}),

	participantUpdate: () => ({
		type: actions.PARTICIPANT_UPDATE,
	}),
	modalUIRefresh: data => ({
		type: actions.PARTICIPANT_MODAL_UI_REFRESH,
		data,
	}),

	countryListReload: sportID => ({
		type: actions.PARTICIPANT_COUNTRY_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	countryListRefresh: list => ({
		type: actions.PARTICIPANT_COUNTRY_LIST_REFRESH,
		data: list,
	}),
	leagueListReload: countryID => ({
		type: actions.PARTICIPANT_LEAGUE_LIST_RELOAD,
		data: {
			countryID,
		},
	}),
	leagueListRefresh: list => ({
		type: actions.PARTICIPANT_LEAGUE_LIST_REFRESH,
		data: list,
	}),
	ageListReload: () => ({
		type: actions.PARTICIPANT_AGE_LIST_RELOAD,
	}),
	ageListRefresh: list => ({
		type: actions.PARTICIPANT_AGE_LIST_REFRESH,
		data: list,
	}),
};

export default actions;
