import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { storeLanguage } from '../../helpers/utility';
import { usersAPI } from '../../helpers/api/users';
import { adaptBaseData, adaptAdminData } from '../users/user/utils';
import userActions from '../users/user/actions';
import { showError, showSuccess } from '../../helpers/notifications';

import actions from './actions';
import { logger } from '../../helpers/logger';

const prefix = 'langSwitcher';

const messages = {
	errorBaseDataLoad: `${prefix}.errorBaseDataLoad`,
	errorUserUpdate  : `${prefix}.errorUserUpdate`,
	successUserUpdate: `${prefix}.successUserUpdate`,
};

function getStoreData({ Auth, App }) {

	return {
		user           : Auth.get('user'),
		partnerEntities: App.get('partnerEntity'),
	};
}
export function* changeLanguage() {

	yield takeEvery(actions.CHANGE_LANGUAGE, function* (action) {
		const { language } = action;
		const { user, partnerEntities } = yield select(getStoreData);
		const adminID = user.id;
		const { dataBaseLangID } = language;
		storeLanguage(language);
		const params = {
			language_id: dataBaseLangID,
		};
		let rawBaseData  = {};
		let rawAdminData = {};
		try {
			const response = yield call(usersAPI.updateAdminData, adminID, params);
			if (response && response.status === 200) {
				showSuccess(messages.successUserUpdate);
				rawBaseData = response.data.data;
				rawAdminData = response.data.data;
				const updatedBaseData  = adaptBaseData(rawBaseData, partnerEntities); // ToDO pass partner entities
				const updatedAdminData = adaptAdminData(rawAdminData);
				updatedBaseData.roles.concat();
				yield put(userActions.baseDataRefresh(updatedBaseData));
				yield put(userActions.adminDataRefresh(updatedAdminData));
				yield put(userActions.setValueUI('isBaseDataChanged', false));
			}
			yield put({ type: actions.CHANGE_LANGUAGE_SUCCESS, language });
		} catch (error) {
			showError(messages.errorUserUpdate, error);
			logger.log(error);
		}

	});
}

export default function* langusgeSwitcherSaga() {
	yield all([
		fork(changeLanguage),
	]);
}
