import React from 'react';
import { Spin } from 'antd';
import { SpinnerContainer } from './Spinner.style';

const Spinner = (props) => {
	const { size = 'small', height = '100%' } = props;
	return (
		<SpinnerContainer className={'spinner'} height={height}>
			<Spin size={size} />
		</SpinnerContainer>
	);
};

export default Spinner;
