import { cloneDeep } from 'lodash';
import { all, call, takeEvery, put, fork, select } from 'redux-saga/effects';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { RiskManagementSettingsApi } from '../../../helpers/api/riskManagement';
import { showError, showSuccess } from '../../../helpers/notifications';
import { deriveTablePagination } from '../../../selectors/tables';
import actions from './actions';
import { adaptRiskManagement, getListParams } from './utils';
import commonActions from '../../common/actions';
import { createError } from '../../../helpers/sockets/socket';
import tableActions from '../../tables/actions';
import socetActions from '../../socket/actions';
import externalSaga from './externalSaga';
import { getHeadersTotalCount } from '../../../helpers/utils';
import { riskAPI } from '../../../helpers/sockets/api/riskManagement';
import { logger } from '../../../helpers/logger';

const prefix = 'rtm.risk';

const messages = {
	errorListReload       : `${prefix}.errorListReload`,
	errorStatusUpdate     : `${prefix}.errorStatusUpdate`,
	errorRiskSave         : `${prefix}.errorRiskSave`,
	errorSubscribeUpdate  : `${prefix}.errorSubscribeUpdate`,
	successRiskSave       : `${prefix}.successRiskSave`,
	successStatusUpdate   : `${prefix}.successStatusUpdate`,
	successSubscribeUpdate: `${prefix}.successSubscribeUpdate`,
};

const tableType = TABLE_TYPES.riskManagementRTM;

const getBaseData = (state) => {
	const userID = state.Users.User.get('baseData').id;
	return {
		baseData   : state.RTM.RiskManagement.get('baseData'),
		pagination : deriveTablePagination(tableType, state),
		sorting    : state.Tables.get(tableType).sorting,
		filter     : state.RTM.RiskManagement.get('filter'),
		activeTabID: state.AppTabs.get('activeTabID'),
		riskGroupUI: state.Common.get('UI'),
		userID,

	};
};

function* riskListReload() {
	yield takeEvery( actions.RELOAD, function* (action) {
		yield put(commonActions.commonRiskGroupListReload());
		yield put(actions.uiRefresh({ loading: true }));

		const { pagination, sorting, filter, userID, activeTabID } = yield select(getBaseData);
		const clonedFilter = cloneDeep(filter);
		if (typeof action.data === 'boolean') {
			delete clonedFilter.userID;
			delete clonedFilter.riskID;
		} else if (!clonedFilter.userID) {
			clonedFilter.userID = userID;
		}

		const params = getListParams(pagination, sorting, clonedFilter);
		let totalCount = 0;
		try {
			const res = yield call(RiskManagementSettingsApi.riskList, params);

			if ( res && res.status === 200) {
				totalCount = getHeadersTotalCount(res.headers);
				const result = adaptRiskManagement(res.data.data);
				const { risksIDs, entities } = result;
				yield put(actions.dataRefresh(risksIDs, entities));
				yield put(socetActions.rtmSubscribe(activeTabID, risksIDs));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (e) {
			showError(messages.errorListReload, e);
			logger.log(e);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}
function* saveRisk() {

	yield takeEvery(actions.RTM_RISK_MANAGEMENT_SAVE_COMMENT, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { userID, riskID, statusID, comment } = action.data;

		const preparedData = {
			id       : riskID,
			status_id: statusID,
			comment,
		};

		try {
			const res = yield call(RiskManagementSettingsApi.riskCommentCreate, riskID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successRiskSave);
				yield put(actions.listReload(userID));
				return;
			}

		} catch (error) {
			showError(messages.errorRiskSave, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {

	yield takeEvery(actions.RTM_RISK_MANAGEMENT_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}


function* subscribeToUpdate() {
	yield takeEvery(actions.RTM_RISK_MANAGEMENT_SOCKET_SUBSCRIBE_TO_UPDATE, function* (action) {
		const { riskIDs } = action.data;
		try {
			const res = yield call(riskAPI.subscribeToUpdate, riskIDs);
			if (res) {
				if (res.isSuccess) {
					// showSuccess(messages.successSubscribeUpdate);
				} else {
					const error = createError(res);
					throw error;
				}
			}
		} catch (error) {
			showError(messages.errorSubscribeUpdate, error);
			logger.log(error);
		}
	});
}


export default function* rtmRiskManagementSaga() {
	yield all([
		fork(riskListReload),
		fork(filterApply),
		fork(saveRisk),
		fork(subscribeToUpdate),

		fork(externalSaga),
	]);
}
