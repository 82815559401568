import EntityAdapter from '../../../../helpers/entityAdapter';
import {
	ICasino, IProviderListEntities, IProviderNames,
	IProviderNamesEntities, IProviderNamesResponse,
	IProviders,
	IProvidersEntities,
	IProviderSeo, IProvidersList, IProvidersListResponse,
	IProvidersResponse,
} from './types';
import { createEntities } from '../../../../helpers/utils';
import { cloneDeep, values } from 'lodash';
import {
	PROVIDER_EXTRA_TABS,
} from '../../../../containers/Partner/Websites/Inner/tabs/TabProvider/ProviderExtra/types';


const fields = {
	id          : 'id',
	casinoID    : 'casino_id',
	statusID    : 'status_id',
	websiteID   : 'website_id',
	apiProject  : 'api_project',
	apiVersion  : 'api_version',
	apiHost     : 'api_host',
	apiID       : 'api_id',
	apiKey      : 'api_key',
	test        : 'test',
	apiUrl      : 'api_url',
	apiSecondUrl: 'api_second_url',
	sandBoxUrl  : 'sandbox_url',
	logo        : 'logo_url',
	order       : 'order_number',

	channelID     : 'channel_id',
	langID        : 'lang_id',
	name          : 'name',
	providerID    : 'provider_id',
	integratorID  : 'integrator_id',
	integratorName: 'integrator_name',

	seoKeywords   : 'seo_keywords',
	seoTitle      : 'seo_title',
	seoDescription: 'seo_description',
	OGDescription : 'og_description',
	OGImage       : 'og_image',
	OGTitle       : 'og_title',
	alias         : 'alias',
};

const casinoListAdapter = createCasinoListAdapter();
const providerListAdapter = createProviderListAdapter();
const namesAdapter = createNamesAdapter();
const seoAdapter = createSeoAdapter();
const providerMainAdapter = createCasinoMainAdapter();

export function mergeResToBase(response, base) {
	const clonedBase = cloneDeep(base);
	clonedBase.logo = response.logo_url;

	return clonedBase;
}


export const adaptProviders = (response: IProvidersListResponse[], casinoSettingsList: {
	[providerID: number]: IProviders;
}) => {
	const settingsList : IProviders[] = Object.values(cloneDeep(casinoSettingsList));
	const adaptedData: IProvidersList[] = providerListAdapter.adaptList(response) as IProvidersList[]; // right types
	const mergedData = settingsList.map((setting) => {
		const foundProvider = adaptedData.find(item => item.providerID === setting.casinoID);
		if (foundProvider) {
			setting.name = foundProvider.name;
			setting.logo = foundProvider.logo;
			setting.alias = foundProvider.alias;
			setting.order = foundProvider.order;
		}
		return setting;
	});
	const entities: IProviderListEntities = createEntities(mergedData);

	return {
		list: adaptedData,
		entities,
	};
};
export function adaptCasino(rawData: IProvidersResponse[] = [], casinoList: ICasino[]): { entities: IProvidersEntities; list: IProviders[]} {
	casinoListAdapter.clearExcludes();
	const adaptedData: IProviders[] = casinoListAdapter.adaptList(rawData) as IProviders[];
	adaptedData.forEach(provider => {
		provider.tabID = PROVIDER_EXTRA_TABS.SETTINGS; // default tab ID is settings
		const foundProvider = casinoList.find(item => item.id === provider.casinoID );
		if (foundProvider) {
			provider.name = foundProvider.name;
			provider.logo = foundProvider.logo;
			provider.alias = foundProvider.alias;
			provider.order = foundProvider.orderNumber;
		}
	});
	const entities =  createEntities(adaptedData);
	return {
		entities,
		list: adaptedData,
	};
}
export function prepareProviders(rawData: IProviders ): IProvidersResponse {

	casinoListAdapter.clearExcludes();
	casinoListAdapter.addExcludeField('alias');
	casinoListAdapter.addExcludeField('logo');
	casinoListAdapter.addExcludeField('name');
	return casinoListAdapter.prepare(rawData) as IProvidersResponse;
}

export function prepareProvidersMain(rawData: IProviders, websiteID: number ): IProvidersResponse & { website_id: number } {
	providerMainAdapter.clearExcludes();
	const prepared: IProvidersResponse = providerMainAdapter.prepare(rawData) as IProvidersResponse;
	prepared.website_id = websiteID;
	return prepared;
}


export const prepareSeoData = (seoData: { [langID: number]: IProviderSeo}) => {
	const seoList = values(seoData);
	const prepared = seoAdapter.prepareList(seoList);
	return prepared;
};
export const prepareNames = (namesData : IProviderNamesEntities = {}): IProviderNamesResponse[] => {
	const names: {
		[langID: number]: IProviderNames[];
	}[] = values(namesData);
	const namesList: IProviderNames[] = [];
	names.forEach(item => {
		const nameValues: IProviderNames[][] = values(item);
		namesList.push(...nameValues.flat());
	});
	return namesAdapter.prepareList(namesList) as IProviderNamesResponse[];
};

export function adaptMainData(rawData) {
	providerMainAdapter.clearExcludes();

	return providerMainAdapter.adapt(rawData);
}
export function adaptNamesData(rawData = []) {

	namesAdapter.clearExcludes();

	const adaptedList = namesAdapter.adaptList(rawData);

	const result = adaptedList.reduce((acc, item) => {
		if (!acc[item.langID]) {
			acc[item.langID] = [];
		}
		acc[item.langID].push(item);
		return acc;
	}, {});

	return result;
}


export function createCasinoListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'casinoID', fields.casinoID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.id, 'apiProject', fields.apiProject);
	adapter.addField(rules.id, 'apiVersion', fields.apiVersion);
	adapter.addField(rules.id, 'apiHost', fields.apiHost);

	adapter.addField(rules.string, 'apiID', fields.apiID);
	adapter.addField(rules.string, 'apiKey', fields.apiKey);
	adapter.addField(rules.bool, 'test', fields.test);
	adapter.addField(rules.string, 'apiUrl', fields.apiUrl);
	adapter.addField(rules.string, 'apiSecondUrl', fields.apiSecondUrl);
	adapter.addField(rules.string, 'sandBoxUrl', fields.sandBoxUrl);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'logo', fields.logo);

	return adapter;
}
export function createProviderListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id,'id', fields.providerID);
	adapter.addField(rules.id,'providerID', fields.providerID);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.id, 'integratorID', fields.integratorID);
	adapter.addField(rules.id,'order', fields.order );
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'integratorName', fields.integratorName);

	return adapter;
}

export function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'providerID', fields.providerID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.stringOrNull, 'name', fields.name);

	return adapter;
}

function createSeoAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.stringOrNull, 'SEOTitle', fields.seoTitle);
	adapter.addField(rules.stringOrNull, 'SEODescription', fields.seoDescription);
	adapter.addField(rules.stringOrNull, 'SEOKeywords', fields.seoKeywords);

	adapter.addField(rules.stringOrNull, 'OGTitle', fields.OGTitle);
	adapter.addField(rules.stringOrNull, 'OGDescription', fields.OGDescription);
	adapter.addField(rules.stringOrNull, 'OGImage', fields.OGImage);
	adapter.addField(rules.stringOrNull, 'alias', fields.alias);
	adapter.addField(rules.stringOrNull, 'langID', fields.langID);

	return adapter;
}
function createCasinoMainAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.stringOrNull, 'name', fields.name);
	adapter.addField(rules.stringOrNull, 'alias', fields.alias);
	adapter.addField(rules.stringOrNull, 'logo', fields.logo);
	adapter.addField(rules.number, 'order', fields.order);


	return adapter;
}
