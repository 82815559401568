const actions = {

	FILTER_ROLE_REFRESH                 : 'PERSONAL_FILTER_FILTER_ROLE_REFRESH',
	RESET                               : 'PERSONAL_FILTER_FILTER_ROLE_RESET',
	AUTOCOMPLETE_DATA_SOURCE_ROLE_RELOAD: 'PERSONAL_FILTER_AUTOCOMPLETE_DATA_SOURCE_ROLE_RELOAD',

	filterRoleRefresh: (filterData) => ({
		type: actions.FILTER_ROLE_REFRESH,
		data: {
			filterData,
		},
	}),

	dataReset: () => ({
		type: actions.RESET,
	}),

	dataSourceRoleReload: (data) => ({
		type: actions.AUTOCOMPLETE_DATA_SOURCE_ROLE_RELOAD,
		data,
	}),
};

export default actions;
