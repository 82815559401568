import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import templateSaga from './templateModal/saga';

export default function* rootSaga() {
	yield all([
		fork(listSaga),
		fork(templateSaga),
	]);
}
