import { apiRequest } from './index';

function blockedIpsList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/blockedIp',
		params,
	};

	return apiRequest(req);
}

function blockedIpInfo(ipID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/blockedIp/${ipID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function blockedIpAdd(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/blockedIp',
		data,
		params,
	};

	return apiRequest(req);
}

function blockedIpUpdate(ipID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/blockedIp/${ipID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function blockedIpDelete(ipID, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/blockedIp/${ipID}`,
		params,
	};

	return apiRequest(req);
}

export const blockedIpsAPI = {
	blockedIpsList,
	blockedIpInfo,
	blockedIpAdd,
	blockedIpUpdate,
	blockedIpDelete,
};
