import { Map } from 'immutable';
import store from './store';

import messagesEn from '../languageProvider/locales/en_US.json';
import messagesRu from '../languageProvider/locales/ru_RU.json';

const localeMessages = {
	en: { ...messagesEn },
	ru: { ...messagesRu },
};

// Common -----------------------------------------------------------------------------------------

export function select(sectionName = null) {
	const currentStore = store.getState();
	if (!sectionName) {
		return currentStore;
	}

	return currentStore[sectionName] || new Map({});
}

// Language ---------------------------------------------------------------------------------------

export function intlMessageValue(messageID) {
	if (!messageID) {
		return '';
	}

	const LanguageSwitcher = select('LanguageSwitcher');
	const { locale } = LanguageSwitcher.get('language');
	if (!locale) {
		return '';
	}

	const messages = localeMessages[locale] || {};

	return messages[messageID];
}

/**
 * Converts a list of objects into an entity map where each object is
 * indexed by a unique key.
 *
 * @param {Array} list - An array of objects to convert into entities.
 * @param {string} keyName - The unique identifier key for each object in the
 * list.
 * @throws {Error} Throws an error if any item in the list is not an object.
 * @returns {Object} An object where each key corresponds to the value of the
 * 'keyName' property of an object in the list,
 *                  and the value is the entire object.
 *
 * @example
 * const inputList = [{ id: 1, name: 'Avet' }, { id: 2, name: 'Vardan' }];
 * const entityMap = toEntities(inputList, 'id');
 *
 * // Output: { '1': { id: 1, name: 'Alice' }, '2': { id: 2, name: 'Bob' } }
 */
export const toEntities = (list = [], keyName) => {
	return list.reduce((acc, item) => {
		if (typeof item !== 'object' || Array.isArray(item)) {
			throw new Error('List items are not objects');
		}
		acc[item[keyName]] = item;
		return acc;
	}, {});
};
