import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	entities: {},
});

export default function casinoTagsListReducer(state = initState, action) {

	switch (action.type) {

		case actions.CASINO_TAGS_LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		default:
			return state;
	}
}
