import { EVENT_TYPES } from '../codes';
import { emitEvent } from '../socket';

function subscribeToUpdate(documentsIDs) {

	const event = {
		subjectType: EVENT_TYPES.document,
		ids        : documentsIDs,
	};

	return emitEvent('subscribe', event);
}

export const documentAPI = {
	subscribeToUpdate,
};
