import uniqBy from 'lodash/uniqBy';
import EntityAdapter from '../../../helpers/entityAdapter';
import { createEntities } from '../../utility';

export const fields = {
	id   : 'provider_id',
	name : 'name',
	games: 'games',
};

const listAdapter = createListAdapter();
const listAdapterGames = createListAdapter(true);

// Adapt ------------------------------------------------------------------------------------------
export function adaptProvidersList(rawData = [], games) {
	listAdapter.clearExcludes();
	let adaptedData;
	if (games) {
		adaptedData =  listAdapterGames.adaptList(rawData);
	} else {
		adaptedData =  listAdapter.adaptList(rawData);
	}

	return createEntities(adaptedData);
}

export function adaptGamesListByProvider(rawData = [], providerID) {
	const uniqueGames = uniqBy(rawData, 'id');  // TODO temporary iteration, waiting BE to fix duplicate game issue. after the fix this line should be removed
	const games = uniqueGames.map( ({ id, name, category }) => ({ id, name, categoryID: category }));

	return {
		games  : games.length > 0 ? games : null,
		providerID,
		loading: false,
	};
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter(games) {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	if (games) {
		adapter.addField(rules.arrayObject, 'games', fields.games);
	}

	return adapter;
}
