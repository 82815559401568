const actions = {

	IMAGES_SINGLE_INIT      : 'IMAGES_SINGLE_INIT',
	IMAGES_SINGLE_UI_REFRESH: 'IMAGES_SINGLE_UI_REFRESH',

	IMAGES_SINGLE_SET_FILE_LINK: 'IMAGES_SINGLE_SET_FILE_LINK',
	IMAGES_SINGLE_UPLOAD_FILE  : 'IMAGES_SINGLE_UPLOAD_FILE',

	IMAGES_SINGLE_UPLOAD_FILE_SUCCESS: 'IMAGES_SINGLE_UPLOAD_FILE_SUCCESS',
	IMAGES_SINGLE_UPLOAD_FILE_ERROR  : 'IMAGES_SINGLE_UPLOAD_FILE_ERROR',

	init: (id, fileLink = '') => ({
		type: actions.IMAGES_SINGLE_INIT,
		data: {
			id,
			fileLink,
		},
	}),
	setFileLink: (id, fileLink = '') => ({
		type: actions.IMAGES_SINGLE_SET_FILE_LINK,
		data: {
			id,
			fileLink,
		},
	}),
	uploadFile: (id, file, cbSuccess = null, cbError = null, oldFileLink = null, onProgress = null, minImageHeight = null, minImageWidth = null, profileAvatar = null) => ({
		type: actions.IMAGES_SINGLE_UPLOAD_FILE,
		data: {
			id,
			file,
			cbSuccess,
			cbError,
			oldFileLink,
			onProgress,
			minImageHeight,
			minImageWidth,
			profileAvatar,
		},
	}),
	uploadFileSuccess: (id, fileLink) => ({
		type: actions.IMAGES_SINGLE_UPLOAD_FILE_SUCCESS,
		data: {
			id,
			fileLink,
		},
	}),
	uploadFileError: (id) => ({
		type: actions.IMAGES_SINGLE_UPLOAD_FILE_ERROR,
		data: {
			id,
		},
	}),

	uiRefresh: (id, uiData) => ({
		type: actions.IMAGES_SINGLE_UI_REFRESH,
		data: {
			id,
			uiData,
		},
	}),

};

export default actions;
