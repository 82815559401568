import EntityAdapter from '../../../helpers/entityAdapter';
import { capitalizeFirstLetter } from '../../../helpers/utils';


const fields = {
	id       : 'id',
	code     : 'code',
	isDefault: 'is_default',
	name     : 'name',
};


const listAdapter        = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------
export function adaptLanguages(rawData = []) {

	listAdapter.clearExcludes();

	return listAdapter.adaptList(rawData)
		.map(item => {
			item.name = capitalizeFirstLetter(item.name);
			return item;
		});
}


function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.bool, 'isDefault', fields.isDefault);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}
