import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

export const historyActionTypeList = [
	{ id: 1, name: 'Create' },
	{ id: 2, name: 'Update' },
	{ id: 3, name: 'Delete' },
];

export const historySourceTypeList = [
	{ id: 1, name: 'Feed' },
	{ id: 2, name: 'Admin' },
];

export class HistoryEnums {

	actionTypeList = () => {
		return cloneDeep(historyActionTypeList);
	};

	sourceTypeList = () => {
		return cloneDeep(historySourceTypeList);
	};

	actionType = typeID => {
		let result = null;
		const item = historyActionTypeList.find( item => item.id === toInteger(typeID) );
		if (item) {
			result = item.name;
		}
		return result;
	};

	sourceType = typeID => {
		let result = null;
		const item = historySourceTypeList.find( item => item.id === toInteger(typeID) );
		if (item) {
			result = item.name;
		}
		return result;
	};
}
