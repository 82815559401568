import { IPermissions } from '../../../../../../../types/types';
import {
	IProviders,
	TProviderEntity,
} from '../../../../../../../redux/partner/websites/providers/types';


export interface ITabSettingsProps {
  tabPermissions: IPermissions;
  record        : IProviders;
  loading       : boolean;
  readOnly      : boolean;
}

export interface IProviderExtraProps {
  tabPermissions: IPermissions;
  record        : TProviderEntity;
}

export enum STATUS_CODES {
  ACTIVE = 1,
  DELETED = 2,
}

export enum PROVIDER_EXTRA_TABS {
  SETTINGS = 1,
  MAIN = 2,
  NAMES = 3,
  SEO = 4,
  ALIAS = 5,
}

export const DISABLED_TABS = [PROVIDER_EXTRA_TABS.SETTINGS, PROVIDER_EXTRA_TABS.MAIN];


export enum PROVIDER_EXTRA_FIELDS {
  ALIAS= 'alias',
  NAME = 'name',
  ORDER = 'order',
}