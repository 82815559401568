export const countryActions = {
	GET_COUNTRY              : 'GET_COUNTRY',
	GET_COUNTRY_ENTITIES_CASE: 'GET_COUNTRY_ENTITIES_CASE',
	GET_COUNTRY_LIST_CASE    : 'GET_COUNTRY_LIST_CASE',

	getCountry: data => ({
		type: countryActions.GET_COUNTRY,
		data,
	}),

	countryEntitiesCase: data => ({
		type: countryActions.GET_COUNTRY_ENTITIES_CASE,
		data,
	}),
	countryListCase: data => ({
		type: countryActions.GET_COUNTRY_LIST_CASE,
		data,
	}),
};
